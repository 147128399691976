import PropTypes from 'prop-types'
import React from 'react'
import { useDebounce, buildJsonApiOrdered } from 'utils-em'
import { useSelector } from 'react-redux'
import { JsonAPI } from 'store'

import {
  TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

const TopicAutocomplete = ({
  label,
  onChange,
  initialTopics,
  multiple,
  helperText
}) => {
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const [selectedTopic, setSelectedTopic] = React.useState(initialTopics)
  const [searchText, setSearchText] = React.useState('')

  const topics = useSelector(({ data }) => buildJsonApiOrdered(data, 'topics', 'searchTopics'))
  const debouncedSearchText = useDebounce(searchText, 150)

  const topicSearch = () => JsonAPI.getAll({
    type: 'topics',
    orderKey: 'searchTopics',
    queryStringParams: {
      filter: [{ name: 'name', op: 'like', val: `%${searchText}%` }],
      limit: 10
    },
    sort: 'name'
  })

  React.useEffect(() => {
    setSelectedTopic(initialTopics)
  }, [initialTopics])
  React.useEffect(() => { searchText?.length > 0 && topicSearch() }, [debouncedSearchText])

  return (
    <Autocomplete
      multiple={multiple}
      id={`${label}-multiselect`}
      open={autocompleteOpen}
      onOpen={() => setAutocompleteOpen(true)}
      onInputChange={(event, text) => {
        setSearchText(text)
      }}
      onChange={onChange}
      onClose={() => setAutocompleteOpen(false)}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}
      options={topics}
      value={multiple ? selectedTopic : selectedTopic[0]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
          helperText={helperText}
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

TopicAutocomplete.defaultProps = {
  initialTopics: [],
  multiple: false,
  label: '',
  helperText: ''
}

TopicAutocomplete.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialTopics: PropTypes.array,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
}

export default TopicAutocomplete
