import React from 'react'
import { useParams, useHistory, Prompt } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Box, Button, Grid, } from '@mui/material'
import { ArrowLeft, Share } from 'icons'
import { useJsonAPIGetOne, useJsonAPIUpsert } from 'hooks'
import { FormToggle, FormTextField } from 'components'
import { constants, navigateTo, simpleHttpFetch, errorAlert, customAlert, isFormDirty, navigateToNewTab } from 'utils-em'
import SurveyQuestionsTable from './components/SurveyQuestionsTable'

const SurveyBuilder = () => {
  const params = useParams()
  const history = useHistory()
  const form = useForm()

  const isNew = Number.isNaN(parseInt(params?.surveyId || '', 10))
  const surveyId = isNew ? null : params?.surveyId
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const { object: survey, loaded, reload } = useJsonAPIGetOne(
    surveyId,
    'surveys',
    ['questions'],
    { 'lazy[surveys]': 'hasSurveyAssignments' },
  )
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const defaultValues = {
    title: survey?.title || '',
    typeformTitle: survey?.typeformTitle || '',
    typeformFormId: survey?.typeformFormId || '',
    header: survey?.header || '',
    payment: survey?.payment === 0 ? 0 : survey?.payment || '',
    remindersEnabled: survey?.remindersEnabled || false,
    isInseat: survey?.isInseat || false,
    isOnboarding: survey?.isOnboarding || false,
    isExpertise: survey?.isExpertise || false,
  }
  const isLoaded = isNew || loaded
  const isDirty = isFormDirty(form, defaultValues)
  const isExpertise = form.getValues('isExpertise')

  React.useEffect(() => { loaded && form.reset(defaultValues) }, [loaded])

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    try {
      // validate typeform
      const typeformFormId = data?.typeformFormId
      const typeformValidation = await simpleHttpFetch(`${__API_HOST__}/typeform/validate/${typeformFormId}`)
      if (!typeformValidation.valid) {
        throw new Error(`Invalid form: ${typeformValidation?.message}. Please edit this directly in Typeform.`)
      }

      // validate survey
      const validationRes = await simpleHttpFetch(
        `${__API_HOST__}/v1/surveys/validate`,
        {
          method: 'POST',
          body: JSON.stringify({ surveyId: isNew ? null : surveyId, patchData: data })
        }
      )
      const surveyValidation = await validationRes.json()
      if (!surveyValidation.valid) {
        throw new Error(`Invalid survey: ${surveyValidation?.message}`)
      }

      // upsert and update route if new survey
      const { typeformTitle, ...updateData } = data
      const res = await upsert({
        type: 'surveys',
        id: surveyId || undefined,
        ...updateData
      })
      const upsertedSurveyId = res?.data?.data?.id
      history.push({ pathname: `/admin/surveys/${upsertedSurveyId}` })
      reload()
      customAlert('Survey saved and synced successfully!')
    } catch (error) {
      errorAlert(error)
    }
    setIsSubmitting(false)
  }

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-leaked-render */}
      {!isSubmitting && isDirty && <Prompt message="Are you sure you would like to leave this page? You will lose any changes you made." />}
      <Box sx={(theme) => ({ ...theme.pageContentSx, width: '100%' })}>
        <Box sx={{ py: 4 }}>
          <Button
            variant="text"
            startIcon={<ArrowLeft />}
            onClick={() => navigateTo('/admin/surveys#build')}
          >
            Back
          </Button>
          <Box typography="h1" variant="h1" sx={{ mb: 2 }}>
            {isNew ? 'New survey' : 'Edit survey'}
          </Box>
          <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormTextField
                  form={form}
                  name="title"
                  label="Title of Survey"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  form={form}
                  name="typeformTitle"
                  label="Typeform Title"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  form={form}
                  name="typeformFormId"
                  label="Typeform ID"
                  disabled={survey?.hasSurveyAssignments}
                  helperText={survey?.hasSurveyAssignments ? 'Cannot be edited after assignment' : null}
                  required
                />
              </Grid>
              <Grid item xs={8}>
                <FormTextField
                  form={form}
                  name="header"
                  label="Header text"
                  required
                  tooltip="Options: {{ advisor_first_name }} {{ account }}"
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  form={form}
                  name="payment"
                  label="Payment amount"
                  type="currency"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <FormToggle
                  form={form}
                  name="remindersEnabled"
                  label="Email campaign with follow-ups"
                  typography="caption"
                />
              </Grid>
              <Grid item xs={3}>
                <FormToggle
                  form={form}
                  name="isInseat"
                  label="Use for in-seat role onboarding"
                  typography="caption"
                />
              </Grid>
              <Grid item xs={3}>
                <FormToggle
                  form={form}
                  name="isOnboarding"
                  label="Use for departed role onboarding"
                  typography="caption"
                />
              </Grid>
              <Grid item xs={3}>
                <FormToggle
                  form={form}
                  name="isExpertise"
                  label="Use for areas of expertise"
                  typography="caption"
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={!isLoaded || isSubmitting}>
                  Save & Sync
                </Button>
                {survey?.typeformFormId ? (
                  <Button
                    onClick={() => navigateToNewTab(`${constants.TYPEFORM_ADMIN_URL}/form/${survey.typeformFormId}/create`)}
                    color="primary"
                    startIcon={<Share />}
                  >
                    Open in Typeform
                  </Button>
                ) : null}
              </Grid>
              {survey?.id ? (
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <SurveyQuestionsTable
                    surveyId={survey.id}
                    typeformFormId={survey.typeformFormId}
                    expertiseEnabled={isExpertise}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SurveyBuilder
