import { connect } from 'react-redux'

import CustomerLayout from '../layouts/CustomerLayout'

import EnumAPI from '../../../../../../../store/EnumAPI'

const mapStateToProps = (_, ownProps) => ({
  customerId: ownProps.match ? parseInt(ownProps.match.params.id, 10) : null
})

const mapDispatchToProps = {
  getEnum: EnumAPI.get
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLayout)
