import React from 'react'
import PropTypes from 'prop-types'
import { ConfirmDialog, JsonApiTable } from 'components'

const TaggedQuestionsDialog = ({ topic, taggedQuestions }) => (
  <ConfirmDialog
    title={`Questions Tagged to ${topic.name}`}
    description={(
      <JsonApiTable
        columns={[
          {
            name: 'questionText',
            header: 'Question Text',
            value: (question) => question.questionText
          },
          {
            name: 'questionType',
            header: 'Question Type',
            value: (question) => question.questionType
          },
        ]}
        type="questions"
        queryStringParams={{
          filter: [{ name: 'id', op: 'in', val: taggedQuestions.map((question) => question.id) }],
        }}
        dependencies={[topic.id]}
        isFiltered
        noSearchResultsText="No questions found"
      />
    )}
  />
)

TaggedQuestionsDialog.propTypes = {
  topic: PropTypes.object.isRequired,
  taggedQuestions: PropTypes.array.isRequired,
}

export default TaggedQuestionsDialog
