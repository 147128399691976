import React from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography
} from '@mui/material'

const SellerActivityStatusFilter = ({ onChange, currentState }) => {
  const options = [
    { value: 'pending', name: 'Pending' },
    { value: 'upcoming', name: 'Upcoming' },
    { value: 'complete', name: 'Complete' },
    { value: 'closed', name: 'Closed' },
  ]
  const [chosenOptions, setChosenOptions] = React.useState(currentState || [])

  const filterResults = (currentChosenOptions) => {
    const stateStatusCombo = currentChosenOptions.map((option) => option.value)
    onChange('sellerActivityStatuses', stateStatusCombo, currentChosenOptions)
  }

  const onOptionSelected = (option) => {
    // add if not in array, remove if in array
    const currentChosenOptions = chosenOptions.some((item) => item.value === option.value)
      ? chosenOptions.filter((item) => item.value !== option.value)
      : [...chosenOptions, { ...option }]

    setChosenOptions(currentChosenOptions)
    filterResults(currentChosenOptions)
  }

  return (
    <>
      <Typography variant="captionBold">
        Status
      </Typography>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            control={(
              <Checkbox
                checked={chosenOptions.some((selected) => selected.value === option.value)}
                onChange={() => onOptionSelected(option)}
              />
            )}
            label={option.name}
          />
        ))}
      </FormGroup>
    </>
  )
}

SellerActivityStatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.array.isRequired
}

export default SellerActivityStatusFilter
