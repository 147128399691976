import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material'

const TeamDeleteDialog = ({
  closeDialog,
  deleteDivision,
  teamToDelete,
  getContractAssociatedWithDivision,
  showSuccessMessage,
  showErrorMessage
}) => (
  <Dialog open fullWidth size="small">
    <DialogContent>
      <p>Are you sure you want to delete this team?</p>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => closeDialog()}>
        Close
      </Button>
      <Button
        onClick={() => {
          deleteDivision(teamToDelete).then((res) => {
            closeDialog()
            if (res.ok) {
              showSuccessMessage({ text: 'Team deleted', timeout: 1000 })
            } else {
              let errorDetails = res.error.message
              getContractAssociatedWithDivision(teamToDelete.id).then((response) => {
                if (response.data.included && response.data.included[0]) {
                  errorDetails = `Team is associated with an SKU under contract: ${response.data.included[0].attributes.name}`
                }
              }).finally(() => {
                showErrorMessage({ text: `Unable to delete product "${teamToDelete.name}". ${errorDetails}`, timeout: 4000 })
              })
            }
          })
        }}
        color="primary"
        variant="contained"
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

TeamDeleteDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  deleteDivision: PropTypes.func.isRequired,
  getContractAssociatedWithDivision: PropTypes.func.isRequired,
  teamToDelete: PropTypes.object.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default TeamDeleteDialog
