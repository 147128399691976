import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Logo (props) {
  return (
    <SvgIcon {...props}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.96081 12.7434C7.96081 16.0968 9.57256 19.1314 12.1611 21.3138C7.98244 23.9249 5.25 28.1033 5.25 32.8703V32.9112C5.25 40.8242 12.7489 47.2615 21.9668 47.2615C31.1847 47.2615 38.6836 40.8242 38.6836 32.9112C38.6836 30.1357 37.7602 27.4859 36.0579 25.2029L41.6914 20.3668C43.1029 19.1557 43.1029 17.1907 41.6914 15.9794C40.2795 14.7675 37.9914 14.7675 36.5795 15.9794L19.4109 30.7175C17.9994 31.9288 17.9994 33.8937 19.4109 35.1049C20.1167 35.711 21.0416 36.014 21.9668 36.014C22.8921 36.014 23.8169 35.711 24.5228 35.1049L30.7285 29.7782C31.2046 30.7576 31.4547 31.8173 31.4547 32.9112C31.4547 37.4025 27.1984 41.056 21.9668 41.056C17.0559 41.056 13.0052 37.8363 12.5271 33.7266C12.5272 33.713 12.5317 33.7005 12.5317 33.6869C12.5317 33.6458 12.5195 33.6077 12.5176 33.5672C12.4973 33.3502 12.4789 33.1549 12.4789 32.9335L12.4789 32.9112C12.4789 28.42 16.7354 24.7665 21.9668 24.7665C23.9628 24.7665 25.5812 23.3772 25.5812 21.6638C25.5812 19.9502 23.9628 18.561 21.9668 18.561C18.2297 18.561 15.1897 15.9513 15.1897 12.7434C15.1897 9.53529 18.2297 6.92572 21.9668 6.92572C23.6032 6.92572 25.1468 7.42391 26.3736 8.32979C27.0286 9.12072 28.0992 9.6406 29.3157 9.6406C31.3118 9.6406 32.9301 8.25135 32.9301 6.53785C32.9301 6.52141 32.9248 6.50623 32.9246 6.48979C32.9405 5.67867 32.5933 4.86322 31.8726 4.24416C29.228 1.97166 25.7101 0.720226 21.9668 0.720226C14.2437 0.720226 7.96081 6.1136 7.96081 12.7434Z" fill="url(#paint0_linear_311_10513)" />
        <defs>
          <linearGradient id="paint0_linear_311_10513" x1="5.24999" y1="23.9908" x2="42.75" y2="23.9908" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CE8069" />
            <stop offset="0.487863" stopColor="#C0745B" />
            <stop offset="1" stopColor="#B16749" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
