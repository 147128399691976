import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'

import { getComponentDisplayName } from 'utils-em'

export default function withAuthorization (Component) {
  const AuthControlledComponent = (props) => {
    const { isLoggedIn, currentPath } = props
    if (!isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { referrer: currentPath }
          }}
        />
      )
    }

    const otherProps = { ...props }
    delete otherProps.isLoggedIn
    delete otherProps.userType
    delete otherProps.currentPath
    delete otherProps.dispatch

    return <Component {...otherProps} />
  }

  AuthControlledComponent.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
    currentPath: PropTypes.string.isRequired,
    session: PropTypes.object.isRequired
  }

  AuthControlledComponent.displayName = `AuthControlledComponent(${getComponentDisplayName(Component)})`

  const mapStateToProps = ({ router, session }) => {
    const isLoggedIn = session && !!session.id

    const userType = session.userType || ''
    const currentPath = router.location.pathname + router.location.search + router.location.hash

    return { isLoggedIn, userType, currentPath, session }
  }

  const applyEnhancements = compose(withRouter, connect(mapStateToProps))

  return applyEnhancements(AuthControlledComponent)
}
