import * as ACTION_TYPE from './constants'

export default function alertReducer (state = [], action) {
  switch (action.type) {
    case ACTION_TYPE.SHOW:
      return state.concat([action.alert])
    case ACTION_TYPE.HIDE_ALL:
      return []
    default:
      return state
  }
}
