import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid
} from '@mui/material'
import {
  closeDialog,
  customAlert
} from 'utils-em'
import { useJsonAPIGetOne } from 'hooks'
import { JsonAPI } from 'store'

import DivisionAutocomplete from '../../../../../../../../components/DivisionAutocomplete'

const EditContractDialog = ({ contractId, customerId }) => {
  const dispatch = useDispatch()

  const { object: contract, loaded } = useJsonAPIGetOne(contractId, 'contracts')

  const [editContract, setEditContract] = useState(contract || {
    customerId,
    dateSigned: null,
    startDate: null,
    endDate: null,
    extensionDate: null,
    agreementTermInMonths: 1 // required until column removal
  })

  useEffect(() => {
    loaded && contract?.id && setEditContract(contract)
  }, [loaded])

  const saveContract = async () => {
    const method = editContract.id ? JsonAPI.save : JsonAPI.create
    return dispatch(method(Object.assign(editContract, {
      type: 'contracts',
      queryStringParams: {
        include: 'contractSkuAllotments,contractSkus,divisions'
      },
      relationshipNames: ['divisions']
    })))
  }

  const handleSubmit = async () => {
    try {
      const saveRes = await saveContract()
      if (saveRes.error) throw new Error(saveRes.error.message)
      customAlert('Contract saved!', false)
      closeDialog()
    } catch (error) {
      customAlert(`Save contract failed. ${error.message}`, true)
    }
  }

  const setContractState = (field) => (event) => {
    const { value } = event.target
    setEditContract({
      ...editContract,
      [field]: value
    })
  }

  const setContractDate = (name) => (timestamp) => {
    const date = timestamp ? timestamp.clone().local().startOf('day') : null
    setEditContract({
      ...editContract,
      [name]: date ? date.toISOString() : null
    })
  }

  const setDivisions = (e, divisions) => {
    setEditContract({
      ...editContract,
      divisions
    })
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      scroll="paper"
      fullWidth
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>{`${editContract.id ? 'Edit' : 'Create'} Contract`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Name"
                value={editContract.name || ''}
                onChange={setContractState('name')}
                validators={['required']}
                errorMessages={['Required Field']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Salesforce Id"
                value={editContract.sfId || ''}
                onChange={setContractState('sfId')}
                validators={['required']}
                errorMessages={['Required Field']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Amount"
                value={editContract.amount || ''}
                onChange={setContractState('amount')}
                validators={['required', 'isNumber']}
                errorMessages={['Required Field', 'Must be a number']}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                sx={{ width: '100%' }}
                views={['day', 'month', 'year']}
                label="Date Signed"
                value={moment(editContract.dateSigned)}
                onChange={setContractDate('dateSigned')}
                renderInput={(params) => (
                  <TextValidator
                    value={editContract.dateSigned}
                    margin="normal"
                    validators={['required']}
                    errorMessages={['Required Field']}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                sx={{ width: '100%' }}
                views={['day', 'month', 'year']}
                label="Contract Start"
                value={moment(editContract.startDate)}
                onChange={setContractDate('startDate')}
                renderInput={(params) => (
                  <TextValidator
                    value={editContract.startDate}
                    margin="normal"
                    validators={['required']}
                    errorMessages={['Required Field']}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                sx={{ width: '100%' }}
                views={['day', 'month', 'year']}
                label="Contract End"
                value={moment(editContract.endDate)}
                onChange={setContractDate('endDate')}
                renderInput={(params) => (
                  <TextValidator
                    // endDate is stored on backend with a forced EST time zone; adjust rendering of date
                    value={moment.tz(editContract.endDate, 'UTC')}
                    margin="normal"
                    validators={['required']}
                    errorMessages={['Required Field']}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                sx={{ width: '100%' }}
                views={['day', 'month', 'year']}
                label="Contract Extension Date"
                value={moment(editContract.extensionDate)}
                onChange={setContractDate('extensionDate')}
                renderInput={(params) => (
                  <TextValidator
                    // extensionDate is stored on backend with a forced EST time zone; adjust rendering of date
                    value={moment.tz(editContract.extensionDate, 'UTC')}
                    margin="normal"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Number of Credits (Old)"
                value={editContract.numCredits || ''}
                onChange={setContractState('numCredits')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <DivisionAutocomplete
                label="Divisions"
                helperText="Contract terms will apply to all sub-divisions of the selected divisions"
                multiple
                customerId={customerId}
                onChange={setDivisions}
                initDivisions={editContract.divisions}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Notes"
                value={editContract.notes || ''}
                onChange={setContractState('notes')}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditContractDialog.defaultProps = {
  contractId: null,
  customerId: null
}

EditContractDialog.propTypes = {
  contractId: PropTypes.number,
  customerId: PropTypes.number
}

export default EditContractDialog
