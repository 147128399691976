import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { JsonAPI } from 'store'
import {
  useJsonAPIDelete,
  useJsonAPIGetAll,
  useJsonAPIUpsert,
} from 'hooks'

export const KnowledgePageBuilderContext = React.createContext({})

export const KnowledgePageBuilderContextProvider = ({ children }) => {
  const {
    objects: knowledgePages,
    loaded,
    reload: reloadKnowledgePages
  } = useJsonAPIGetAll('knowledgePages', { include: ['sections', 'sections.topics'] })

  const dispatch = useDispatch()
  const { deleteOne } = useJsonAPIDelete()
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })

  const savePage = async (knowledgePage) => {
    await upsert({
      type: 'knowledgePages',
      ...knowledgePage
    })
    reloadKnowledgePages()
  }

  const deletePage = async (knowledgePage) => {
    await deleteOne({
      type: 'knowledgePages',
      id: knowledgePage.id
    })
    reloadKnowledgePages()
  }

  const reloadPage = async (pageId) => {
    await dispatch(JsonAPI.getOne({
      type: 'knowledgePages',
      id: pageId,
      queryStringParams: {
        include: 'sections',
      }
    }))
  }

  const saveSection = async (section) => {
    await upsert({
      type: 'knowledgePageSections',
      ...section
    })
    await reloadPage(section.pageId)
  }

  const deleteSection = async (section) => {
    await deleteOne({
      type: 'knowledgePageSections',
      id: section.id,
    })
    await reloadPage(section.pageId)
  }

  const sortedKnowledgePages = knowledgePages.sort((a, b) => a.order - b.order)

  const contextValue = React.useMemo(() => ({
    knowledgePages: sortedKnowledgePages,
    loaded,
    reloadKnowledgePages,
    savePage,
    deletePage,
    saveSection,
    deleteSection
  }), [knowledgePages, loaded])

  return (
    <KnowledgePageBuilderContext.Provider value={contextValue}>
      {children}
    </KnowledgePageBuilderContext.Provider>
  )
}

KnowledgePageBuilderContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
