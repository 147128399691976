import { store, EnumAPI } from 'store'

export function getEnums (type, params = {}) {
  return store.dispatch(EnumAPI.get({ type, ...params }))
}

export function getRejectReasons (type) {
  getEnums('reject_reason', { queryStringParams: { object_type: type } })
}

export function getTypeOfPurposeForCall (type) {
  getEnums('type_of_purpose_for_call')
}
