import React from 'react'
import PropTypes from 'prop-types'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'
import { Prompt } from 'react-router-dom'

import {
  Divider,
  Link,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useIsAdmin } from 'hooks'
import { FeatureAccessControl, TipaltiInfo } from 'components'
import { JsonAPI } from 'store'
import { httpResponseAlert } from 'utils-em'

const PaymentInfo = ({ user }) => {
  const isAdmin = useIsAdmin()
  const dispatch = useDispatch()

  const [nameOrCompany, setNameOrCompany] = React.useState(user.paymentName)
  const [singleCallRate, setSingleCallRate] = React.useState(user.singleCallRate)

  const [hasEdited, setHasEdited] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const submitForm = () => {
    setIsSubmitting(true)
    dispatch(JsonAPI.save({
      type: 'advisors',
      id: user.id,
      paymentName: nameOrCompany,
      singleCallRate
    })).then((resp) => {
      httpResponseAlert(resp)
      if (resp.ok) setHasEdited(false)
    }).finally(() => setIsSubmitting(false))
  }

  const isFormValid = hasEdited && nameOrCompany

  return (
    <ValidatorForm onSubmit={submitForm}>
      {hasEdited && (<Prompt message="Are you sure you would like to leave this page? You will lose any changes you made." />)}
      <Typography variant="h2" color="neutral.black" sx={{ mb: 3 }}>Payment information</Typography>
      {!isAdmin && (
        <Typography sx={{ mt: 3 }}>
          More details about the payment process can be found on the Payment page in your Resource Center.
          If you need to discuss your rate, contact&nbsp;
          <Link href="mailto:advisor-support@emissary.io" target="_blank">advisor-support@emissary.io</Link>
          .
        </Typography>
      )}
      <Divider sx={{ mt: 3 }} />
      {isAdmin && (
        <>
          <TextValidator
            label="Engagement rate"
            variant="outlined"
            fullWidth
            value={singleCallRate}
            onChange={(event) => {
              setSingleCallRate(event.target.value)
              setHasEdited(true)
            }}
            sx={{ mt: 3 }}
          />
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3, display: 'block' }}
            type="submit"
          >
            Save
          </LoadingButton>
        </>
      )}
      <FeatureAccessControl feature="tipalti_integration">
        <TipaltiInfo />
      </FeatureAccessControl>
      <FeatureAccessControl feature="tipalti_integration" hideIf>
        <TextValidator
          label="Your name or company name"
          variant="outlined"
          fullWidth
          value={nameOrCompany}
          onChange={(event) => {
            setNameOrCompany(event.target.value)
            setHasEdited(true)
          }}
          sx={{ mt: 3 }}
        />
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          disabled={!isFormValid}
          sx={{ mt: 3, display: 'block' }}
          type="submit"
        >
          Save
        </LoadingButton>
      </FeatureAccessControl>
    </ValidatorForm>
  )
}

PaymentInfo.propTypes = {
  user: PropTypes.object.isRequired
}

export default PaymentInfo
