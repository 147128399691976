import * as ACTION_TYPE from './constants'

export default function dialogReducer (state = [], action) {
  switch (action.type) {
    case ACTION_TYPE.OPEN_DIALOG: {
      const dialogToAdd = { ...action.dialog }
      if (action.key) {
        dialogToAdd.key = action.key
      }

      const newDialogs = Object.assign([], state)
      if (dialogToAdd.type?.name && newDialogs.find((dialog) => dialog.type.name === dialogToAdd.type.name)) {
        console.warn(`Cannot open two of the same dialogs of the same type "${dialogToAdd.type.name}"`)
      } else {
        newDialogs.push(dialogToAdd)
      }
      return newDialogs
    }
    case ACTION_TYPE.CLOSE_DIALOG: {
      let newState = Object.assign([], state)
      if (action.key && newState.find((d) => d.key === action.key)) {
        newState = newState.filter((d) => d.key !== action.key)
      } else {
        newState.pop()
      }
      return newState
    }
    case ACTION_TYPE.CLOSE_ALL_DIALOGS:
      return []
    default:
      return state
  }
}
