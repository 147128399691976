import React from 'react'
import PropTypes from 'prop-types'

import Autocomplete from '@mui/material/Autocomplete'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import Place from '@mui/icons-material/Place'
import Work from '@mui/icons-material/Work'

import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'

import CustomerUserAutocomplete from '../../../CustomerUserAutocomplete'

const TeamEditDialog = ({
  customerId,
  closeDialog,
  saveDivision,
  customerTeams,
  teamToEdit,
  showSuccessMessage,
  showErrorMessage
}) => {
  const [teamData, setTeamData] = React.useState(
    teamToEdit || { customerId, parentId: null, name: '', divisionType: '', customerUsers: [] }
  )

  const updateFormData = (newValue) => {
    setTeamData({ ...teamData, ...newValue })
  }

  const teamsWithNull = [null].concat(customerTeams.sort((a, b) => a.name.localeCompare(b.name)))

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={closeDialog}>
      <DialogContent>
        <ValidatorForm
          id="edit-team-form"
          onSubmit={() => {
            saveDivision(teamData).then((res) => {
              closeDialog()
              if (res.ok) {
                showSuccessMessage({ text: 'Team saved', timeout: 1000 })
              } else {
                showErrorMessage({ text: 'Failed to save team', timeout: 1000 })
              }
            })
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                required
                size="small"
                variant="outlined"
                name="name"
                validators={['required']}
                errorMessages={['Required']}
                label="Team Name"
                value={teamData.name}
                onChange={(e) => updateFormData({ name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectValidator
                fullWidth
                required
                variant="outlined"
                validators={['required']}
                errorMessages={['Required']}
                size="small"
                label="Type"
                value={teamData.divisionType}
                onChange={(e) => updateFormData({ divisionType: e.target.value })}
              >
                <MenuItem value="region">
                  <Chip size="small" label="Region" icon={<Place />} variant="outlined" />
                </MenuItem>
                <MenuItem value="vertical">
                  <Chip size="small" label="Vertical" icon={<Work />} variant="outlined" />
                </MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                options={teamsWithNull}
                onChange={(_, value) => {
                  if (value === null) {
                    updateFormData({ parentId: null })
                    return
                  }
                  updateFormData({ parentId: parseInt(value.id, 10) })
                }}
                getOptionLabel={(teamIdentifierOrObject) => {
                  if (teamIdentifierOrObject === null) {
                    return 'None'
                  }

                  if (Number.isInteger(teamIdentifierOrObject)) {
                    const matchingTeam = customerTeams.find(
                      (team) => parseInt(team.id, 10) === parseInt(teamIdentifierOrObject, 10)
                    )
                    return matchingTeam.name
                  }

                  return teamIdentifierOrObject.name
                }}
                isOptionEqualToValue={(option, value) => (
                  (option === null && value === null) || (option != null && parseInt(option.id, 10) === parseInt(value, 10))
                )}
                filterOptions={(options, state) => (
                  options.filter((option) => {
                    const isNoneOption = option === null

                    if (isNoneOption) {
                      // NOTE: "None" is always a valid option to select for team parent
                      return true
                    }

                    const isNewTeam = teamToEdit === null
                    const isNotTheSameTeam = !isNewTeam && parseInt(option.id, 10) !== parseInt(teamToEdit.id, 10)
                    const nameMatchesSearch = option.name.toLowerCase().includes(state.inputValue.toLowerCase())

                    return (isNewTeam || isNotTheSameTeam) && nameMatchesSearch && !option.isArchived
                  })
                )}
                filterSelectedOptions
                value={teamData.parentId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Parent Team"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomerUserAutocomplete
                customerId={customerId}
                label="Team Members"
                onChange={((_, users) => {
                  updateFormData({ customerUsers: users })
                })}
                initUsers={teamData.customerUsers}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => closeDialog()}>
          Close
        </Button>
        <Button
          form="edit-team-form"
          type="submit"
          color="primary"
          variant="contained"
        >
          {teamToEdit === null ? 'Create' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TeamEditDialog.defaultProps = {
  teamToEdit: null
}

TeamEditDialog.propTypes = {
  customerId: PropTypes.number.isRequired,
  closeDialog: PropTypes.func.isRequired,
  saveDivision: PropTypes.func.isRequired,
  customerTeams: PropTypes.array.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  teamToEdit: PropTypes.object
}

export default TeamEditDialog
