import React from 'react'

import AdvisorOffPlatformRecorder from '../components/AdvisorOffPlatformRecorder'

const AdvisorOffPlatformRecorderLayout = () => (
  <section>
    <AdvisorOffPlatformRecorder />
  </section>
)

export default AdvisorOffPlatformRecorderLayout
