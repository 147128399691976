import React from 'react'
import {
  Box,
  Divider,
  IconButton,
  Typography
} from '@mui/material'
import {
  ACCOUNT_PAGE_SECTION_DATA,
  SURVEY_QUESTIONS,
  openDialog,
  accountPageSectionHasData
} from 'utils-em'
import {
  ConfirmDialog,
  SanitizedHTML,
  WhiskerCard
} from 'components'
import {
  HelpCircle
} from 'icons'
import {
  useFeatureFlag
} from 'hooks'

import AvatarWithTextCombo from '../AvatarWithTextCombo'
import { AccountPageContext } from '../AccountPageContext'
import AdvisorContext from '../AdvisorContext'
import SectionNotFound from '../SectionNotFound'
import AdvisorQuote from '../AdvisorQuote'
import DirectToPage from '../DirectToPage'

const WhiskerSection = () => {
  const directToPageEnabled = useFeatureFlag('direct_to_page_insights')
  const { accountPage, activeSectionKey, responses, responsesLoaded } = React.useContext(AccountPageContext)
  const section = accountPage.data[activeSectionKey]
  const sectionDefinition = ACCOUNT_PAGE_SECTION_DATA.find((sectionDef) => sectionDef.key === activeSectionKey)
  const renderedSectionModules = sectionDefinition.modules.filter((module) => module.sectionType === 'string' || (module.sectionType === 'whisker' && (accountPage.surveyResults.results[module.key] || section[module.key]?.quote)))

  const renderWhiskerCard = (module) => {
    if (!accountPage.surveyResults.results[module.key]) return null
    const whiskerCardProps = {
      chartLow: accountPage.surveyResults.results[module.key].min,
      chartMid: accountPage.surveyResults.results[module.key].avg,
      chartHigh: accountPage.surveyResults.results[module.key].max,
      textMin: module.textMin,
      textMax: module.textMax,
      title: SURVEY_QUESTIONS['account-profile'].find((question) => question.id === module.key)?.text
    }

    return <WhiskerCard {...whiskerCardProps} sx={{ mb: 3 }} />
  }

  if (!accountPageSectionHasData(section, activeSectionKey)) {
    return <SectionNotFound />
  }
  return (
    <>
      {renderedSectionModules.map((module, i) => {
        const moduleKey = String(module.key)
        const moduleData = section[module.key] // this may be undefined if working with old data
        const moduleTitle = moduleData?.title || module.bigTitle
        const moduleContext = moduleData?.text || moduleData?.paragraph
        const moduleQuote = moduleData?.quote

        return (
          <Box key={module.key}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AvatarWithTextCombo
                sx={{ px: 3, py: 1.5, pr: 2 }}
                backgroundColor="brand.paleSlate"
                icon={(<Typography variant="bodyBold" color="brand.darkNavy">{i + 1}</Typography>)}
                text={module.title}
                variant="tiny"
              />
              <IconButton
                size="small"
                onClick={() => openDialog(
                  <ConfirmDialog
                    title={module.title}
                    description={(
                      <>
                        <Typography>How to use this section:</Typography>
                        <br />
                        <SanitizedHTML
                          html={module.help}
                          sx={{ mx: 1, '& ul': { listStylePosition: 'outside', pl: 3, my: 0 } }}
                        />
                      </>
                    )}
                    size="sm"
                    cancelName="Close"
                  />
                )}
              >
                <HelpCircle sx={{ color: 'neutral.darkGrey' }} />
              </IconButton>
            </Box>
            {moduleTitle && <Typography variant="h2" color="neutral.black" sx={{ px: 3, py: 1.5 }}>{moduleTitle}</Typography>}
            <Box sx={{ mb: 3 }} />
            <Box sx={{ display: 'flex', columnGap: 6 }}>
              {module.sectionType === 'whisker' && (
                <Box sx={{ flexBasis: '50%' }}>
                  {module.sectionType === 'whisker' && renderWhiskerCard(module)}
                  {((!directToPageEnabled || (responsesLoaded && !responses.find((response) => response.question?.topics[0]?.key === moduleKey))) && moduleQuote) ? <AdvisorQuote quote={moduleQuote} /> : null}
                </Box>
              )}
              {moduleContext && (
                <AdvisorContext context={moduleContext} fullWidth={module.fullWidth} />
              )}
            </Box>
            {directToPageEnabled && <DirectToPage organizationId={accountPage.organizationId} topicKey={moduleKey} />}
            {i !== renderedSectionModules.length - 1 ? <Divider sx={{ mt: 5, mb: 5 }} /> : null}
          </Box>
        )
      })}
    </>
  )
}

export default WhiskerSection
