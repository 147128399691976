import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { ValidatorForm } from 'react-material-ui-form-validator'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from '@mui/material'

import { OrganizationSelector } from 'components'

const EditCoverageItemDialog = ({
  coverageItemIdx,
  coverageItemSet,
  handleEdit,
  handleClose,
  getOrganization,
  showSuccessMessage,
  showErrorMessage,
  saveOrg,
  handleDiscard
}) => {
  const [coverageItemToEdit, setCoverageItemToEdit] = useState(coverageItemSet)
  const [updating, setUpdating] = useState(false)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    if (!coverageItemToEdit.given) {
      setCoverageItemToEdit((prevState) => ({
        ...prevState,
        given: prevState.name
      }))
    }
  }, [coverageItemToEdit.given])

  const handleOrgChange = (newOrg) => {
    setCoverageItemToEdit((prevState) => ({
      ...prevState,
      selectedOrg: newOrg || {}
    }))
  }

  const handleUpdateMatch = (newMatches) => {
    setCoverageItemToEdit((prevState) => ({
      ...prevState,
      selectedOrg: newMatches[0] || {},
      matches: newMatches
    }))
  }

  const handleUpdating = (val) => {
    setUpdating(val)
  }

  const handleSubmit = () => {
    handleEdit(coverageItemIdx, coverageItemToEdit)
  }

  const noteNewPrimaryName = () => {
    if (!coverageItemToEdit.selectedOrg.id) return
    getOrganization(coverageItemToEdit.selectedOrg.id).then((res) => {
      const orgAttributes = { ...res.data.data.attributes }
      orgAttributes.id = res.data.data.id
      if (orgAttributes.aliases === '' || !orgAttributes.aliases) {
        orgAttributes.aliases = coverageItemToEdit.selectedOrg.name
      } else {
        orgAttributes.aliases += `|${coverageItemToEdit.selectedOrg.name}`
      }
      orgAttributes.name = coverageItemToEdit.given

      saveOrg(orgAttributes).then(() => {
        showSuccessMessage({ text: 'Successfully shifted alias and name!', timeout: 2000 })
        setRefresh(refresh + 1)
      })
    })
  }

  const noteNewAlias = () => {
    if (!coverageItemToEdit.selectedOrg.id) return
    getOrganization(coverageItemToEdit.selectedOrg.id).then((res) => {
      const orgAttributes = { ...res.data.data.attributes }
      orgAttributes.id = res.data.data.id
      if (orgAttributes.aliases === '' || !orgAttributes.aliases) {
        orgAttributes.aliases = coverageItemToEdit.given
      } else {
        orgAttributes.aliases += `|${coverageItemToEdit.given}`
      }

      saveOrg(orgAttributes).then(() => {
        showSuccessMessage({ text: 'Successfully created alias!', timeout: 2000 })
        setRefresh(refresh + 1)
      })
    })
  }

  const goWithSuggestion = () => {
    setCoverageItemToEdit({
      ...coverageItemToEdit,
      given: coverageItemToEdit.selectedOrg.name
    })
  }

  const noteNewOrganization = () => {
    const newOrgBody = {
      name: coverageItemToEdit.given,
      source: 'coverage'
    }

    saveOrg(newOrgBody).then((res) => {
      if (!res.error) {
        showSuccessMessage({ text: 'Successfully created new org!', timeout: 2000 })
        setRefresh(refresh + 1)
      } else {
        showErrorMessage({ text: 'Org creation failed', timeout: 2000 })
      }
    })
  }

  return (
    <Dialog open maxWidth="lg" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
      <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrganizationSelector
                organizationName={coverageItemToEdit.given}
                updateMatches={handleUpdateMatch}
                handleChange={(newOrg, matches) => {
                  handleOrgChange(newOrg, matches)
                }}
                handleUpdating={handleUpdating}
                dependencies={[refresh]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Name Given by Report:
                {' '}
                {coverageItemSet.given}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Current Selection Name:
                {' '}
                {coverageItemToEdit.selectedOrg.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Current Selection Aliases:
                {' '}
                {coverageItemToEdit.selectedOrg.aliases}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Current Score:
                {' '}
                {coverageItemToEdit.selectedOrg.score}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup orientation="vertical" color="primary" aria-label="vertical outlined primary button group">
                <Button onClick={noteNewPrimaryName} disabled={updating}>
                  Note Better Primary Name (with new Alias)
                </Button>
                <Button onClick={noteNewAlias} disabled={updating}>
                  Note New Alias
                </Button>
                <Button onClick={noteNewOrganization} disabled={updating}>
                  Note New Organization
                </Button>
                <Button onClick={goWithSuggestion} disabled={updating}>
                  Go with suggestion
                </Button>
                {handleDiscard ? (
                  <Button onClick={handleDiscard}>Discard Entirely</Button>
                ) : null}
              </ButtonGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Update
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditCoverageItemDialog.defaultProps = {
  handleDiscard: null
}

EditCoverageItemDialog.propTypes = {
  coverageItemIdx: PropTypes.number.isRequired,
  coverageItemSet: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDiscard: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  saveOrg: PropTypes.func.isRequired
}

export default EditCoverageItemDialog
