import React from 'react'

import { Box, Button, TextField } from '@mui/material'
import { OrganizationSelectorTwo } from 'components'
import { simpleHttpFetch, copyToClipboardWithAlert } from 'utils-em'

const SurveyData = () => {
  const [org, setOrg] = React.useState(null)
  const [questionResponses, setQuestionResponses] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const getQuestionResponses = async () => {
    if (!org) {
      setQuestionResponses('')
      return
    }
    setLoading(true)
    const { data } = await simpleHttpFetch(
      `${__API_HOST__}/v1/chatbot/survey_data?orgId=${org.id}`,
    )
    setQuestionResponses(data || '')
    setLoading(false)
  }

  React.useEffect(() => { getQuestionResponses() }, [org?.id])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <OrganizationSelectorTwo
          textFieldParams={{
            variant: 'outlined',
            label: 'Account',
            placeholder: 'Account',
          }}
          defaultValue={org}
          onSelected={(o) => setOrg(o)}
          noOptionsText="Enter account name"
        />
      </Box>
      <Box>
        <TextField
          label="Survey data"
          multiline
          rows={40}
          variant="outlined"
          fullWidth
          value={questionResponses}
          disabled={loading}
        />
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={() => copyToClipboardWithAlert(questionResponses)}
          disabled={!questionResponses}
        >
          Copy
        </Button>
      </Box>
    </Box>
  )
}

export default SurveyData
