import * as ACTION_TYPE from './constants'

export function openDialog (dialog, key = undefined) {
  return {
    type: ACTION_TYPE.OPEN_DIALOG,
    dialog,
    key
  }
}

export function closeDialog (key = undefined) {
  return {
    type: ACTION_TYPE.CLOSE_DIALOG,
    key
  }
}

export function closeAllDialogs () {
  return {
    type: ACTION_TYPE.CLOSE_ALL_DIALOGS
  }
}
