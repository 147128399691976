import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'

import { useSessionUser } from 'hooks'
import {
  closeDialog,
  copyToClipboard,
  customAlert
} from 'utils-em'

const RelayMessageDialog = ({ relayLink }) => {
  const { isAdvisor } = useSessionUser()
  return (
    <Dialog
      open
      maxWidth="sm"
      scroll="paper"
      onClose={closeDialog}
    >
      <DialogTitle>Send message</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 3 }}>
          Send email to:
        </Typography>
        <Button
          variant="text"
          sx={{ textTransform: 'none', color: 'neutral.black', minHeight: 0, minWidth: 0, padding: 0 }}
          onClick={() => {
            copyToClipboard(relayLink, () => customAlert('Message relay email copied to clipboard.'))
          }}
        >
          {relayLink}
        </Button>
        <Typography color="neutral.black" sx={{ mt: 3 }}>
          When you email to this address, all participants
          {isAdvisor ? ' ' : ' (including the advisor) '}
          will receive your message.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>
          Cancel
        </Button>
        <Button sx={{ ml: 2 }} variant="contained" href={`mailto:${relayLink}`}>
          Open in email client
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RelayMessageDialog.propTypes = {
  relayLink: PropTypes.string.isRequired,
}

export default RelayMessageDialog
