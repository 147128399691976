import PropTypes from 'prop-types'

const AdminAccessControl = ({ children }) => children

AdminAccessControl.defaultProps = {
  children: null
}

AdminAccessControl.propTypes = {
  children: PropTypes.node
}

export default AdminAccessControl
