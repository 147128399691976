import React from 'react'
import PropTypes from 'prop-types'

import {
  formatDateTime
} from 'utils-em'

import ActivityItem from '../ActivityItem'

const FeedbackSubmitted = ({ activity, ...props }) => (
  <ActivityItem
    title="Feedback submitted"
    subtitle={formatDateTime(activity.dateCreated)}
    {...props}
  />
)

FeedbackSubmitted.propTypes = {
  activity: PropTypes.object.isRequired
}

export default FeedbackSubmitted
