import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import EngagementsTable from '../components/EngagementTable'
import Filters from '../components/EngagementTable/Filters'

const EngagementsLayout = () => {
  const [searchFilters, setFilters] = useState([])

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Filters
          onFilterUpdate={(filterList) => { setFilters(filterList) }}
        />
      </Grid>
      <Grid item xs={9}>
        <EngagementsTable searchFilters={searchFilters} />
      </Grid>
    </Grid>
  )
}

export default EngagementsLayout
