import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Toolbar,
  Typography
} from '@mui/material'
import AccountTree from '@mui/icons-material/AccountTree'

import TeamEditDialog from './components/TeamEditDialog'
import TreeLevel from './components/TreeLevel'

const styles = {
  treeToolbar: {
    marginBottom: '0.5em'
  },
  treeViewport: {
    border: '1px solid #ddd',
    overflowX: 'scroll'
  }
}

const maxChildWidth = (divisions) => {
  const numChildrenByParent = divisions.reduce(
    (childCounts, team) => (
      {

        ...childCounts,
        [team.parentId]: team.parentId in childCounts ? childCounts[team.parentId] + 1 : 1
      }
    ),
    {}
  )

  return Math.max(...Object.values(numChildrenByParent))
}

const transformIntoTree = (divisions, showArchivedTeams = false) => {
  const tree = []
  const lookup = {}
  const nestedDivisions = []

  divisions.filter((d) => showArchivedTeams || !d.isArchived).forEach((div, idx) => {
    lookup[div.id] = idx
    nestedDivisions.push({ ...div, children: [] })
  })

  for (let i = 0; i < nestedDivisions.length; i++) {
    const div = nestedDivisions[i]
    if (!div.parentId) {
      if (showArchivedTeams || !div.isArchived) tree.push(div)
    } else {
      const parentNode = nestedDivisions[lookup[div.parentId]]
      div.parent = parentNode
      if (parentNode && (showArchivedTeams || !parentNode.isArchived)) {
        if (showArchivedTeams || !div.isArchived) parentNode.children.push(div)
      } else {
        // NOTE: these are newly orphaned nodes from deletions, they go back to root
        div.parentId = null
        div.parent = null
        tree.push(div)
      }
    }
  }
  return tree
}

const TeamTree = ({
  customerId,
  divisions,
  getDivisions,
  openDialog
}) => {
  const customerTeams = divisions.filter((team) => team.id !== undefined && team.parentId !== undefined)

  const [editMode, setEditMode] = React.useState(false)
  const [showInactive, setShowInactive] = React.useState(false)
  const [showArchivedTeams, setShowArchivedTeams] = React.useState(false)
  const [maxChildren, setMaxChildren] = React.useState(0)

  React.useEffect(() => { getDivisions(customerId) }, [])
  React.useEffect(() => { setMaxChildren(maxChildWidth(customerTeams)) }, [divisions])

  const openTeamDialog = (team = null) => (openDialog(
    <TeamEditDialog
      customerId={customerId}
      customerTeams={customerTeams}
      teamToEdit={team}
    />
  ))

  return (
    <div>
      <Paper sx={{ ...styles.treeToolbar }}>
        <Toolbar style={{ backgroundColor: editMode ? '#e3f2fd' : 'inherit' }}>
          <AccountTree style={{ flex: '1 1 auto' }} />
          <Typography style={{ flex: '2 1 70%' }} variant="h6" component="div">
            {editMode ? 'Editing Teams...' : 'Team Hierarchy'}
          </Typography>
          {!editMode && (
            <FormControlLabel
              label="Show deactivated users"
              sx={{ minWidth: '250px' }}
              control={(
                <Switch
                  checked={showInactive}
                  onChange={() => setShowInactive(!showInactive)}
                  sx={{ mr: 1 }}
                />
              )}
            />
          )}
          <FormControlLabel
            label="Show archived teams"
            sx={{ minWidth: '250px' }}
            control={(
              <Switch
                checked={showArchivedTeams}
                onChange={() => setShowArchivedTeams(!showArchivedTeams)}
                sx={{ mr: 1 }}
              />
            )}
          />
          <Button
            onClick={() => setEditMode(!editMode)}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            {editMode ? 'Done' : 'Edit'}
          </Button>
          {!editMode && (
            <Button
              onClick={() => openTeamDialog()}
              style={{ flex: '1 1 auto' }}
              color="primary"
              variant="contained"
            >
              New
            </Button>
          )}
        </Toolbar>
      </Paper>
      <div style={{ ...styles.treeViewport }}>
        <TreeLevel
          compact={maxChildren > 4}
          divisions={transformIntoTree(customerTeams, showArchivedTeams)}
          editTeam={editMode ? (team) => openTeamDialog(team) : null}
          showInactive={showInactive}
        />
      </div>
    </div>
  )
}

TeamTree.propTypes = {
  divisions: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired,
  getDivisions: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default TeamTree
