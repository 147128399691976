import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import {
  Box,
  Button,
  Typography
} from '@mui/material'
import {
  confirmDialog,
  getEngagementMostRelevantTimeslot,
  formatTimeslotForHumans,
  openDialog
} from 'utils-em'
import {
  AddToCalendarButton,
  AddParticipantsSection,
  CallSchedulingDialog,
  ConnectByEmailSection,
  EngagementAccessControl
} from 'components'

const EngagementCallPrepStep = ({ engagement, isAdvisor, context }) => {
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  const isCallTimeInThirtyMinutes = moment(relevantTimeslot.startTime).diff(moment(), 'minutes') <= 30

  const rescheduleCall = () => {
    confirmDialog({
      title: 'Reschedule',
      description: 'Can’t make the upcoming call? You will be able to propose new times and all participants will be notified.',
      actions: [
        {
          name: 'Propose New Times',
          action: () => {
            openDialog(
              <CallSchedulingDialog
                engagementId={parseInt(engagement.id, 10)}
              />
            )
          }
        }
      ]
    })
  }

  return (
    <>
      <Box sx={{ bgcolor: 'brand.lightNavy', p: 3, borderRadius: 1 }}>
        <Typography variant="h4" color="neutral.black">
          {`Call scheduled for ${formatTimeslotForHumans(relevantTimeslot)}`}
        </Typography>
        <Typography variant="body1" sx={{ mt: 3, mb: 2 }}>
          A link to the Zoom call will be available 30 minutes before your scheduled call time.
        </Typography>
        <Box sx={{ pt: 2, display: 'flex' }}>
          <Button
            sx={{ mr: 2 }}
            target="_blank"
            href={engagement.zoomMeetingJoinLink}
            variant="outlined"
            color="primary"
            disabled={!isCallTimeInThirtyMinutes}
          >
            <Box textAlign="center">Join Zoom Meeting</Box>
          </Button>
          <AddToCalendarButton timeslotId={relevantTimeslot.id} />
          <EngagementAccessControl>
            <Button
              variant="text"
              sx={{ mr: 2, textTransform: 'none' }}
              onClick={rescheduleCall}
            >
              Reschedule call
            </Button>
          </EngagementAccessControl>
        </Box>
      </Box>
      {!isAdvisor && <AddParticipantsSection engagementId={engagement.id} context={context} />}
      <ConnectByEmailSection engagementId={engagement.id} />
    </>
  )
}

EngagementCallPrepStep.defaultProps = {
  context: {}
}

EngagementCallPrepStep.propTypes = {
  engagement: PropTypes.object.isRequired,
  context: PropTypes.object,
  isAdvisor: PropTypes.bool.isRequired
}

export default EngagementCallPrepStep
