import {
  setGtag,
  initializePendo
} from 'utils-em'
import { ACTIONS } from './actions'

const INITIAL_STATE = {}

const stealthPrefix = (stealth) => (stealth ? '_stealth_' : '')

/* eslint-disable camelcase */
export default function gtagReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS.INITIALIZE_USER:
    case ACTIONS.INITIALIZE_ADVISOR_USER: {
      const { id, email, user_type, cloaked } = action.user
      const newState = {
        ...state,
        user_id: stealthPrefix(cloaked) + id,
        user_email: stealthPrefix(cloaked) + email,
        user_type: stealthPrefix(cloaked) + user_type,
        stealth: cloaked === true
      }
      setGtag(newState)
      initializePendo({ ...newState, ...action.user })
      return newState
    }
    case ACTIONS.INITIALIZE_CUSTOMER_USER: {
      const { id, email, user_type, cloaked } = action.user
      const newState = {
        ...state,
        user_id: stealthPrefix(cloaked) + id,
        user_email: stealthPrefix(cloaked) + email,
        user_type: stealthPrefix(cloaked) + user_type,
        stealth: cloaked === true,
        customer_user_role_type: stealthPrefix(cloaked) + action.user.role_type
      }
      setGtag(newState)
      initializePendo({ ...newState, ...action.user })
      return newState
    }
    case ACTIONS.INITIALIZE_GTAG_CUSTOMER: {
      const { customerName } = action.customer
      const newState = {
        ...state,
        customer_name: stealthPrefix(state.stealth) + customerName,
      }
      setGtag(newState)
      initializePendo(newState)
      return newState
    }
    case ACTIONS.LOCATION_CHANGE: {
      const { pathname } = action.payload.location
      const newState = {
        ...state,
        page_path: pathname,
        page_title: pathname
      }
      setGtag(newState)
      return newState
    }
    default: {
      return state
    }
  }
}
