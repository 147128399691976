import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  IconButton,
  Link,
  Skeleton,
  Popover,
  Tooltip,
  Typography
} from '@mui/material'
import {
  useJsonAPIGetAll,
  useJsonAPIUpsert
} from 'hooks'
import {
  Flag
} from 'icons'
import {
  JsonApiDataGrid,
  OrganizationMultiSelector,
  QuestionMultiSelector,
  SurveySelector,
  SurveyAssignmentDialog
} from 'components'
import {
  formatDate,
  buildJsonApiFilter,
  openDialog,
  SURVEY_ASSIGNMENT_STATUS_MAP
} from 'utils-em'
import EditAnswerDialog from './EditAnswerDialog'

const QuestionResponsesTable = ({ assignmentId, organizationId, initialColumns, initialSortBy, excludeViewAction, ...rest }) => {
  const { data: surveys, loaded: surveysLoaded } = useJsonAPIGetAll('surveys')
  const { upsert, isLoading: isSaving } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const [survey, setSurvey] = React.useState(null)
  const [orgs, setOrgs] = React.useState([])
  const [questions, setQuestions] = React.useState([])
  const [updatedAssignmentId, setUpdatedAssignmentId] = React.useState([])
  const [restoringId, setRestoringId] = React.useState(null)
  const [anchorElRestore, setAnchorElRestore] = React.useState(null)

  const orgIds = orgs.map((o) => o.id)
  const questionIds = questions.map((q) => q.id)
  const hideBigFilters = assignmentId || organizationId

  const handleClearFilters = () => {
    setSurvey(null)
    setOrgs([])
    setQuestions([])
  }

  const handleSurveyChange = (s) => {
    setSurvey(s)
    setQuestions([])
  }

  const handleSave = async (responseId, field, value) => {
    await upsert({
      type: 'questionResponses',
      id: responseId,
      [field]: value
    })
  }

  const handleSaveAction = async (responseId, action) => {
    const patchData = {
      restore: { adminDisqualifiedDate: null },
      omit: { adminDisqualifiedDate: new Date().toISOString() },
    }
    await upsert({
      type: 'questionResponses',
      id: responseId,
      ...patchData?.[action]
    })
  }

  const buildFilters = () => {
    const filters = []
    if (survey) filters.push(buildJsonApiFilter('surveyAssignment__has.surveyId__eq', survey.id))
    if (orgIds.length) filters.push({ name: 'organizationId', op: 'in_', val: orgIds })
    if (questionIds.length) filters.push({ name: 'questionId', op: 'in_', val: questionIds })
    if (assignmentId) filters.push({ name: 'surveyAssignmentId', op: 'eq', val: assignmentId })
    if (organizationId) filters.push({ name: 'organizationId', op: 'eq', val: organizationId })
    return filters
  }

  const getRowHeight = ({ model: response }) => {
    const { questionText, textAnswer } = response
    const lineHeight = 36 // Adjust as needed
    const charsPerLine = 56
    const textLength = Math.max(questionText?.length || 1, textAnswer?.length || 1)
    const numLines = Math.ceil(textLength / charsPerLine)
    return lineHeight * numLines
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Response ID',
      width: 75,
      valueGetter: ({ row }) => row.id,
      hidden: true
    },
    {
      field: 'dateCreated',
      headerName: 'Date assigned',
      type: 'date',
      valueGetter: ({ row }) => row.surveyAssignment?.dateCreated && new Date(row.surveyAssignment?.dateCreated),
      renderCell: ({ row }) => (
        <>
          {formatDate(row.surveyAssignment?.dateCreated, { includeYear: true })}
        </>
      )
    },
    {
      field: 'organization.name',
      width: 150,
      headerName: 'Account',
      valueGetter: ({ row }) => row.organization.name,
    },
    {
      field: 'surveyTitle',
      headerName: 'Survey',
      width: 150,
      type: 'singleSelect',
      valueOptions: surveys?.map((s) => s.title) || [],
      valueGetter: ({ row }) => row.surveyAssignment.survey.title,
    },
    {
      field: 'surveyAssignmentId',
      headerName: 'Assignment ID',
      hidden: true,
      valueGetter: ({ row }) => row.surveyAssignmentId,
    },
    {
      field: 'isAssignmentArchived',
      headerName: 'Assignment archived',
      hidden: true,
      type: 'boolean',
      valueGetter: ({ row }) => row.surveyAssignment.isArchived,
    },
    {
      field: 'organizationId',
      headerName: 'Org ID',
      width: 75,
      valueGetter: ({ row }) => row.organizationId,
      hidden: true
    },
    {
      field: 'advisor.id',
      headerName: 'Advisor ID',
      valueGetter: ({ row }) => row.advisor.id,
      hidden: true
    },
    {
      field: 'advisor.fullName',
      headerName: 'Advisor',
      width: 150,
      valueGetter: ({ row }) => row.advisor.fullName,
      renderCell: ({ row }) => (
        <Link
          target="_blank"
          href={`/admin/advisors/${row.advisor.id}`}
        >
          {row.advisor.fullName}
        </Link>
      )
    },
    {
      field: 'questionId',
      headerName: 'Question ID',
      hidden: true,
      width: 75,
      valueGetter: ({ row }) => row.questionId,
    },
    {
      field: 'questionText',
      headerName: 'Question text',
      flex: 1,
      valueGetter: ({ row }) => row.questionText,
      renderCell: ({ row }) => (
        <Box sx={{ whiteSpace: 'pre-wrap' }}>
          {row.questionText}
        </Box>
      )
    },
    {
      field: 'textAnswer',
      headerName: 'Answer',
      flex: 1,
      valueGetter: ({ row }) => row.editedAnswerText || row.textAnswer,
      renderCell: ({ row }) => (
        <>
          <Box sx={{ whiteSpace: 'pre-wrap' }}>
            {row.editedAnswerText || row.textAnswer}
          </Box>
          { row.editedAnswerText && (
            <Tooltip title="This answer has been edited">
              <IconButton
                onClick={(e) => {
                  setRestoringId(row.id)
                  setAnchorElRestore(e.currentTarget)
                }}
              >
                <Flag />
              </IconButton>
            </Tooltip>
          )}
          { restoringId === row.id && (
            <Popover
              anchorEl={anchorElRestore}
              open={restoringId === row.id}
              onClose={() => setRestoringId(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Typography sx={{ p: 2 }} variant="body1">
                Original Response:
              </Typography>
              <Typography sx={{ p: 2 }} variant="body2">
                {row.textAnswer}
              </Typography>
              <Box sx={{ p: 2 }}>
                <Link
                  onClick={() => {
                    setRestoringId(null)
                    handleSave(row.id, 'editedAnswerText', null)
                  }}
                >
                  Restore original response
                </Link>
              </Box>
            </Popover>
          )}
        </>
      )
    },
    {
      field: 'answerType',
      headerName: 'Answer type',
      valueGetter: ({ row }) => row.answerType,
      hidden: true
    },
    {
      field: 'surveyAssignment.status',
      headerName: 'Assignment status',
      width: 100,
      valueGetter: ({ row }) => row.surveyAssignment.status,
      renderCell: ({ row }) => (
        <Link
          onClick={(e) => openDialog(<SurveyAssignmentDialog onUpdate={() => setUpdatedAssignmentId([...updatedAssignmentId, row?.surveyAssignment.id])} assignmentId={row?.surveyAssignment.id} />)}
        >
          {SURVEY_ASSIGNMENT_STATUS_MAP?.[row.surveyAssignment.status] || SURVEY_ASSIGNMENT_STATUS_MAP.assigned}
        </Link>
      ),
      type: 'singleSelect',
      valueOptions: Object.keys(SURVEY_ASSIGNMENT_STATUS_MAP).map((k) => ({ value: k, label: SURVEY_ASSIGNMENT_STATUS_MAP[k] })),
    },
    {
      field: 'isAdminDisqualified',
      headerName: 'Omitted',
      type: 'boolean',
      hidden: true,
      valueGetter: ({ row }) => row.isAdminDisqualified,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      actions: true,
      disableExport: true,
      disableSort: true,
      disableFilter: true,
      valueGetter: ({ row }) => row.id,
      renderCell: ({ row }) => {
        const buttonText = row.adminDisqualifiedDate ? 'Restore' : 'Omit'
        const action = row.adminDisqualifiedDate ? 'restore' : 'omit'
        const color = row.adminDisqualifiedDate ? 'primary' : 'error'
        return (
          <>
            <Link
              disabled={isSaving}
              color="primary"
              onClick={(e) => {
                openDialog(<EditAnswerDialog questionResponseId={row.id} />)
              }}
              sx={{ mr: 2 }}
            >
              Edit
            </Link>
            <Link disabled={isSaving} color={color} onClick={() => handleSaveAction(row.id, action)}>{buttonText}</Link>
          </>
        )
      }
    },
  ]

  const visibleColumns = columns.map((c) => ({
    ...c,
    hidden: !initialColumns ? c.hidden : !initialColumns?.includes(c.field),
    cellClassName: ({ row }) => {
      if (c?.actions) return ''// actions are never grey
      if (row?.adminDisqualifiedDate) return 'discarded'
      if (row?.isAssignmentArchived) return 'discarded'
      return ''
    }
  }))

  if (!surveysLoaded) return <Skeleton variant="rounded" height="200px" />
  const extraDependencies = rest.dependencies ? [...rest.dependencies] : []
  return (
    <>
      {!hideBigFilters && (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', mb: 2 }}>
          <OrganizationMultiSelector
            initialSelectedOrgs={orgs}
            selectedOrgsCallback={setOrgs}
          />
          <SurveySelector initialSurvey={survey} onChange={handleSurveyChange} />
          <QuestionMultiSelector surveyId={survey?.id} initialQuestions={questions} onChange={setQuestions} />
          <Button onClick={handleClearFilters}>Clear</Button>
        </Box>
      )}
      <JsonApiDataGrid
        type="questionResponses"
        columns={visibleColumns}
        jsonApiOptions={{
          include: ['surveyAssignment', 'surveyAssignment.survey', 'advisor', 'organization'],
          sortBy: initialSortBy || '-surveyAssignment.dateCreated',
          filters: [
            ...buildFilters()
          ]
        }}
        mode="server"
        density="compact"
        dependencies={[survey, questions, orgs, updatedAssignmentId, ...extraDependencies]}
        getRowHeight={getRowHeight}
        rowsPerPageOptions={[50, 100, 250]}
      />
    </>

  )
}

QuestionResponsesTable.defaultProps = {
  assignmentId: null,
  organizationId: null,
  initialColumns: null,
  initialSortBy: '-dateCreated',
  excludeViewAction: false
}
QuestionResponsesTable.propTypes = {
  assignmentId: PropTypes.number,
  organizationId: PropTypes.number,
  initialColumns: PropTypes.arrayOf(PropTypes.string),
  initialSortBy: PropTypes.string,
  excludeViewAction: PropTypes.bool
}

export default QuestionResponsesTable
