import React from 'react'
import PropTypes from 'prop-types'

import {
  FormGroup,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material'

const ShowOnlyMyEngagementsFilter = ({ onChange, currentState }) => {
  const options = [
    { value: 'show_only_mine', name: 'Only my engagements' },
  ]
  const [chosenOptions, setChosenOptions] = React.useState(currentState || [])

  const filterResults = (currentChosenOptions) => {
    const stateStatusCombo = currentChosenOptions.map((option) => option.value)
    onChange('showOnlyMine', stateStatusCombo, currentChosenOptions)
  }

  const onOptionSelected = (option) => {
    // add if not in array, remove if in array
    const currentChosenOptions = chosenOptions.some((item) => item.value === option.value)
      ? chosenOptions.filter((item) => item.value !== option.value)
      : [...chosenOptions, { ...option }]

    setChosenOptions(currentChosenOptions)
    filterResults(currentChosenOptions)
  }

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={(
            <Switch
              checked={chosenOptions.some((selected) => selected.value === options[0].value)}
              onChange={() => onOptionSelected(options[0])}
            />
          )}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            ml: 0,
            mt: 1
          }}
          labelPlacement="start"
          label={(
            <>
              <Typography variant="body1">Only show my engagements</Typography>
              <Typography variant="tiny">Engagements you own or are a participant in</Typography>
            </>
          )}
        />
      </FormGroup>
    </>
  )
}

ShowOnlyMyEngagementsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.array.isRequired
}

export default ShowOnlyMyEngagementsFilter
