import { connect } from 'react-redux'
import { Alerts, Dialogs, JsonAPI } from 'store'

import TeamDeleteDialog from './TeamDeleteDialog'

const mapDispatchToProps = {
  deleteDivision: (division) => (
    JsonAPI.delete(Object.assign(division, { type: 'divisions' }))
  ),
  getContractAssociatedWithDivision: (divisionId) => JsonAPI.getAll({
    type: 'contractSkus',
    queryStringParams: {
      include: 'contract',
      filter: [
        {
          name: 'divisions',
          op: 'any',
          val: {
            name: 'id',
            op: 'eq',
            val: divisionId
          }
        }
      ]
    }
  }),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  closeDialog: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(null, mapDispatchToProps)(TeamDeleteDialog)
