import React, { useState } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { debounce, get } from 'lodash'
import {
  Button,
  ButtonGroup,
  Grid,
  Link,
  MenuItem,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Paper,
} from '@mui/material'
import {
  compileFilters,
  constants,
  formatDate,
  openDialog
} from 'utils-em'
import { useJsonAPIGetAll } from 'hooks'
import { StickyTextField, StickySelectField } from 'components'

import TablePaginationActions from '../../../../../../components/TablePaginationActions'
import EditCustomer from '../EditCustomer'

const CustomerList = () => {
  const [sortDirection, setSortDirection] = useState('asc')
  const [sortColumn, setSortColumn] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [searchTerms, setSearchTerms] = useState({ status__eq: 'Active' })

  const { objects: customers, totalCount: customersCount, reload } = useJsonAPIGetAll('customers', {
    pageSize: rowsPerPage,
    pageNumber: page + 1,
    sortBy: `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`,
    queryStringParams: {
      filter: compileFilters(searchTerms),
      include: 'demandOwner,supplyOwner,tags'
    }
  })

  const debouncedReload = debounce(reload, 250)

  React.useEffect(() => {
    debouncedReload()
  }, [sortDirection, sortColumn, rowsPerPage, page, searchTerms])

  const createNewCustomer = () => {
    openDialog(
      <EditCustomer asDialog />
    )
  }

  const editCustomer = (customerId) => {
    openDialog(
      <EditCustomer
        customerId={parseInt(customerId, 10)}
        asDialog
      />
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    updateSearchFilter(name, value)
  }

  const updateSearchFilter = (name, value) => {
    setSearchTerms({
      ...searchTerms,
      [name]: value
    })
  }

  const sortOnColumn = (column) => {
    const newSortDirection = sortColumn !== column || sortDirection === 'asc' ? 'desc' : 'asc'
    setSortColumn(column)
    setSortDirection(newSortDirection)
  }

  return (
    <ValidatorForm onSubmit={reload}>
      <Paper>
        <Stack direction="row" spacing={2} sx={{ p: 2 }}>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginTop: '1em' }}
            onClick={createNewCustomer}
          >
            Create New Customer
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ marginTop: '1em' }}
            onClick={reload}
          >
            Apply Filters
          </Button>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    <TableSortLabel
                      column="name"
                      active={sortColumn === 'name'}
                      direction={sortDirection}
                      onClick={() => sortOnColumn('name')}
                    >
                      Name
                    </TableSortLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <StickyTextField
                      stickyKey="customer.name"
                      fullWidth
                      value={searchTerms.name || ''}
                      onChange={handleSearchFilter('name')}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    <TableSortLabel
                      column="status"
                      active={sortColumn === 'status'}
                      direction={sortDirection}
                      onClick={() => sortOnColumn('status')}
                    >
                      Status
                    </TableSortLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <StickySelectField
                      stickyKey="customer.status"
                      value={searchTerms.status__eq}
                      onChange={handleSearchFilter('status__eq')}
                    >
                      <MenuItem key="all" value="">All</MenuItem>
                      <MenuItem key="Active" value="Active">Active</MenuItem>
                      <MenuItem key="Inactive" value="Inactive">Inactive</MenuItem>
                      <MenuItem key="Internal" value="Internal">Internal</MenuItem>
                    </StickySelectField>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  column="dateCreated"
                  active={sortColumn === 'dateCreated'}
                  direction={sortDirection}
                  onClick={() => sortOnColumn('dateCreated')}
                >
                  Date Created
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    Account Manager
                  </Grid>
                  <Grid item xs={12}>
                    <StickyTextField
                      stickyKey="customer.demandOwner"
                      fullWidth
                      value={searchTerms['demandOwner.fullName'] || ''}
                      onChange={handleSearchFilter('demandOwner.fullName')}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    Client Engagement Manager
                  </Grid>
                  <Grid item xs={12}>
                    <StickyTextField
                      stickyKey="customer.supplyOwner"
                      fullWidth
                      value={searchTerms['supplyOwner.fullName'] || ''}
                      onChange={handleSearchFilter('supplyOwner.fullName')}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>
                  <Link
                    target="_blank"
                    href={`/admin/customers/${customer.id}`}
                  >
                    {customer.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {customer.status}
                </TableCell>
                <TableCell>
                  {formatDate(customer.dateCreated)}
                </TableCell>
                <TableCell>
                  {get(customer, 'demandOwner.fullName', 'None')}
                </TableCell>
                <TableCell>
                  {get(customer, 'supplyOwner.fullName', 'None')}
                </TableCell>
                <TableCell>
                  <ButtonGroup variant="contained">
                    <Button
                      onClick={() => { editCustomer(customer.id) }}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                count={customersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </ValidatorForm>
  )
}

export default CustomerList
