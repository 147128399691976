import PropTypes from 'prop-types'
import React from 'react'

import { ValidatorForm } from 'react-material-ui-form-validator'
import {
  Box,
  Button,
  Link,
} from '@mui/material'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { LoadingButton } from 'components'

import {
  customAlert,
  downloadAssetFromGcs,
  gcsFilenameFormatter,
  uploadFileDirectly
} from 'utils-em'

const INPUT_EXTENSIONS = {
  'engagement-transcript': '.txt,.pdf,.vtt',
  'engagement-recording': '.mp3,.wav,.m4a'
}

const UploadEngagementAssetForm = ({ engagementId, assetType, initialAsset, onUploadComplete }) => {
  const initialFilename = initialAsset?.documentGcsObjectName && gcsFilenameFormatter(initialAsset.documentGcsObjectName)?.[0]
  const [savedAssetId, setSavedAssetId] = React.useState(initialAsset?.id)
  const [savedFilename, setSavedFilename] = React.useState(initialFilename)
  const [fileToBeUploaded, setFileToBeUploaded] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const uploadRecording = async () => {
    if (!fileToBeUploaded) return
    const tempPath = `temp/engagement/${engagementId}`
    const tempName = `${tempPath}/${fileToBeUploaded.name}`
    const res = await uploadFileDirectly(fileToBeUploaded, tempPath)
    if (!res.ok) throw new Error('Error uploading recording')
    const endpoint = new URL(`${__API_HOST__}/v1/assets/upload-engagement-asset`)
    endpoint.searchParams.append('engagementId', engagementId)
    endpoint.searchParams.append('assetType', assetType)
    endpoint.searchParams.append('tempName', tempName)
    return fetch(endpoint.toString(), {
      credentials: 'include',
      method: 'POST',
    })
  }

  const uploadFile = async () => {
    if (!fileToBeUploaded) return
    const endpoint = new URL(`${__API_HOST__}/v1/assets/upload-engagement-asset`)
    endpoint.searchParams.append('engagementId', engagementId)
    endpoint.searchParams.append('assetType', assetType)
    const formData = new FormData()
    formData.append('file', fileToBeUploaded)
    return fetch(endpoint.toString(), {
      credentials: 'include',
      method: 'POST',
      body: formData
    })
  }

  const handleSubmit = async () => {
    if (!fileToBeUploaded) return
    setIsLoading(true)
    const uploadRes = assetType === 'engagement-recording' ? await uploadRecording() : await uploadFile()
    const body = await uploadRes.json()
    const fileName = body?.document_gcs_object_name?.split('/')
    if (fileName?.length) {
      setSavedFilename(fileName.pop())
    } else {
      setSavedFilename(fileToBeUploaded.name)
    }
    setSavedAssetId(body.id)
    if (uploadRes.error) throw new Error(uploadRes.error.message)
    customAlert(`${fileToBeUploaded.name} successfully uploaded`)
    onUploadComplete && onUploadComplete()
    setFileToBeUploaded(null)
    setIsLoading(false)
  }

  return (
    <ValidatorForm onSubmit={() => handleSubmit()}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          File:&nbsp;
          {!fileToBeUploaded && savedAssetId ? (
            <Link
              className={`${assetType}-link`}
              target="_blank"
              onClick={() => downloadAssetFromGcs(savedAssetId, savedFilename)}
              mr={2}
            >
              {savedFilename}
            </Link>
          ) : fileToBeUploaded?.name || 'None'}
        </Box>
        <Box>
          <label htmlFor={`${assetType}-input`}>
            {!fileToBeUploaded && (
              <LoadingButton
                size="small"
                color="primary"
                component="span"
                startIcon={<AttachmentIcon />}
                variant="outlined"
                loading={isLoading}
                disabled={isLoading}
              >
                Upload/replace
              </LoadingButton>
            )}
            <input
              id={`${assetType}-input`}
              type="file"
              accept={INPUT_EXTENSIONS[assetType]}
              onChange={(e) => {
                const selectedFile = e.target.files[0]
                if (!selectedFile) return
                const allowedExtensions = INPUT_EXTENSIONS[assetType].split(',')
                const fileExtension = `.${selectedFile.name.split('.').pop().toLowerCase()}`
                if (!allowedExtensions.includes(fileExtension)) {
                  customAlert('Incorrect file type', true)
                  e.preventDefault()
                  return
                }
                setFileToBeUploaded(e.target.files[0])
              }}
              style={{ display: 'none' }}
            />
          </label>
          {fileToBeUploaded && (
            <LoadingButton
              disabled={isLoading}
              loading={isLoading}
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              Save
            </LoadingButton>
          )}
          {fileToBeUploaded && (
            <Button
              disabled={isLoading}
              onClick={() => setFileToBeUploaded(null)}
              variant="contained"
              color="error"
              size="small"
            >
              Cancel
            </Button>
          )}

        </Box>
      </Box>
    </ValidatorForm>
  )
}

UploadEngagementAssetForm.defaultProps = {
  initialAsset: {},
  onUploadComplete: null
}

UploadEngagementAssetForm.propTypes = {
  engagementId: PropTypes.number.isRequired,
  assetType: PropTypes.string.isRequired,
  initialAsset: PropTypes.object,
  onUploadComplete: PropTypes.func
}

export default UploadEngagementAssetForm
