import React from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { emissaryTheme } from 'themes'
import { Box } from '@mui/material'
import { Header } from 'components'
import ForgotPassword from '../components/ForgotPassword'

const ForgotContainer = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={emissaryTheme}>
      <Box sx={{ display: 'flex', bgcolor: 'brand.lightNavy' }}>
        <Header
          menuItemsVisible={{
            stealth: false,
            notifications: false,
            menu: false
          }}
        />
        <Box sx={{
          flexDirection: 'column',
          display: 'flex',
          width: '100%'
        }}
        >
          <Box sx={{
            pt: (theme) => theme.header.height,
            pl: '0px',
            minHeight: (theme) => '100vh'
          }}
          >
            <ForgotPassword />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default ForgotContainer
