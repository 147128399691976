import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@mui/material'

const Spinner = ({ size, color, fixedCoords, centerRef }) => {
  const getSpinnerStyle = () => {
    // displays at a fixed location above components
    if (fixedCoords) {
      return {
        display: 'block',
        position: 'fixed',
        mt: `-${size / 2}px`,
        mr: `-${size / 2}px`,
        ...fixedCoords
      }
    }
    // displays above middle of ref component
    if (centerRef) {
      if (!centerRef.current) return null
      const { x, y, width, height } = centerRef.current.getBoundingClientRect()
      const left = x + width / 2 - size / 2
      const top = y + height / 2 - size / 2
      return { display: 'block', position: 'fixed', left, top }
    }
    // default is flex
    return { display: 'flex', justifyContent: 'center', width: '100%', p: 3 }
  }
  return (
    <Box sx={getSpinnerStyle()}>
      <CircularProgress sx={{ color, height: `${size}px`, width: `${size}px` }} />
    </Box>
  )
}

Spinner.defaultProps = {
  size: 40,
  color: 'brand.purple',
  fixedCoords: null,
  centerRef: null,
}

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  fixedCoords: PropTypes.object,
  centerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

export default Spinner
