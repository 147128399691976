import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Grid,
  Paper,
  Typography
} from '@mui/material'

import AccessTime from '@mui/icons-material/AccessTime'
import AttachMoney from '@mui/icons-material/AttachMoney'
import EventNote from '@mui/icons-material/EventNote'
import Public from '@mui/icons-material/Public'
import { calculateCompanyTenure, timeBetweenTwoDates } from 'utils-em'

const styles = (theme) => ({
  title: {
    paddingTop: '0.5em',
    marginLeft: '0.5em',
    fontWeight: 'bold'
  },
  grid: {
    border: '1px solid #ccc',
    padding: '0.6em',
    textAlign: 'center'
  },
  iconAligner: {
    verticalAlign: 'top',
    paddingRight: '0.25em',
    color: '#33BFC0',
    width: '20px',
    height: '20px'
  }
})

const AdvisorAtAGlance = ({ companyProposed, role }) => (
  <Paper>
    <Typography variant="h6" sx={{ ...styles.title }}>Advisor At-a-Glance</Typography>
    <Grid container direction="row">
      <Grid item xs={4} sx={{ ...styles.title }}>
        <Typography variant="body2">TENURE</Typography>
        <AccessTime sx={{ ...styles.title }} />
        <Typography variant="body1">{ calculateCompanyTenure(companyProposed) }</Typography>
      </Grid>
      <Grid item xs={4} sx={{ ...styles.title }}>
        <Typography variant="body2">BUDGET</Typography>
        <AttachMoney sx={{ ...styles.title }} />
        <Typography variant="body1">{role.budgetOwnership}</Typography>
        <Typography variant="body1">USD</Typography>
      </Grid>

      <Grid item xs={4} sx={{ ...styles.title }}>
        <Typography variant="body2">GEOGRAPHIC RESPONSIBILITY</Typography>
        <Public sx={{ ...styles.title }} />
        <Typography variant="body2" style={{ overflow: 'auto', height: '3.5em' }}>
          {role.tags && role.tags.find((t) => t.category === 'region') ? role.tags.find((t) => t.category === 'region').name : null}
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{ ...styles.title }}>
        <Typography variant="body2">TIME SINCE DEPARTURE</Typography>
        <EventNote sx={{ ...styles.title }} />
        <Typography variant="body1">{ timeBetweenTwoDates(role.dateEnded, moment()) }</Typography>
      </Grid>
    </Grid>
  </Paper>
)

AdvisorAtAGlance.propTypes = {
  companyProposed: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired
}

export default AdvisorAtAGlance
