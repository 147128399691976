import React from 'react'
import { Switch, Route } from 'react-router'

import UserSearch from './UserSearch'

export default () => (
  <Switch>
    <Route exact path="/admin/users" component={UserSearch} />
  </Switch>
)
