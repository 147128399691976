import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { EmissaryKnowledge } from 'icons'

const GreyEmissaryBox = ({ children, headerIcon, ...rest }) => (
  <Box {...rest}>
    <Box sx={{ p: 5, pl: 8, pr: 2, pt: 5, bgcolor: 'brand.lightNavy', borderRadius: '16px', display: 'flex', flexDirection: 'column' }}>
      {headerIcon}
      {children}
    </Box>
  </Box>
)

GreyEmissaryBox.defaultProps = {
  headerIcon: <EmissaryKnowledge style={{ height: 40, width: 180 }} sx={{ mt: 1.5, mb: 1.5 }} />
}

GreyEmissaryBox.propTypes = {
  children: PropTypes.node.isRequired,
  headerIcon: PropTypes.node,
}

export default GreyEmissaryBox
