import React from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography
} from '@mui/material'

const CallStatusFilter = ({ onChange, currentState }) => {
  let options = ['Pending', 'Upcoming', 'Complete', 'Closed']
  options = options.map((option) => ({ value: option, name: option }))
  const [chosenOptions, setChosenOptions] = React.useState(currentState || [])

  const filterResults = (currentChosenOptions) => {
    onChange('callStatusCombos', currentChosenOptions, currentChosenOptions)
  }

  const onOptionSelected = (option) => {
    // add if not in array, remove if in array
    const currentChosenOptions = chosenOptions.some((item) => item.value === option) ? chosenOptions.filter((item) => item.value !== option)
      : [...chosenOptions, { value: option, name: option }]

    setChosenOptions(currentChosenOptions)
    filterResults(currentChosenOptions)
  }

  return (
    <>
      <Typography variant="captionBold">
        Call status
      </Typography>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            control={(
              <Checkbox
                checked={chosenOptions.some((selected) => selected.value === option.value)}
                onChange={(event) => onOptionSelected(option.value)}
              />
            )}
            label={option.name}
          />
        ))}
      </FormGroup>
    </>
  )
}

CallStatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.array.isRequired
}

export default CallStatusFilter
