import { connect } from 'react-redux'

import { buildJsonApiOne, buildJsonApiAll } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'
import ChargeSkuDialog from './ChargeSkuDialog'

const mapStateToProps = ({ data }, ownProps) => ({
  contract: buildJsonApiOne(data, 'contracts', ownProps.contractId),
  contractSku: buildJsonApiOne(data, 'contractSkus', ownProps.contractSkuId) || {},
  skus: buildJsonApiAll(data, 'skus')
})

const mapDispatchToProps = {
  saveContractSku: (contractSku) => {
    const method = contractSku.id ? JsonAPI.save : JsonAPI.create
    return method({
      ...contractSku,
      type: 'contractSkus',
      relationshipNames: ['advisors', 'customerUsers', 'tags', 'divisions', 'account', 'organization'],
      queryStringParams: {
        include: [
          'contract.contractSkus',
          'advisors',
          'customerUsers',
          'tags',
          'divisions',
          'account',
          'organization'
        ].join(',')
      }
    })
  },
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargeSkuDialog)
