import React from 'react'
import {
  Box,
  Divider,
  Typography
} from '@mui/material'
import { accountPageSectionHasData } from 'utils-em'
import SectionNotFound from '../SectionNotFound'
import AvatarWithTextCombo from '../AvatarWithTextCombo'
import { AccountPageContext } from '../AccountPageContext'
import AdvisorContext from '../AdvisorContext'
import AdvisorQuote from '../AdvisorQuote'

const SellingTips = () => {
  const { accountPage } = React.useContext(AccountPageContext)
  if (!accountPageSectionHasData(accountPage?.data?.sellingTips, 'sellingTips')) {
    return <SectionNotFound />
  }
  return (
    <>
      {accountPage.data.sellingTips.map((tip, i) => (
        <Box key={tip.title}>
          <AvatarWithTextCombo
            sx={{ px: 3, py: 1.5 }}
            backgroundColor="brand.paleSlate"
            icon={(<Typography variant="bodyBold" color="brand.darkNavy">{i + 1}</Typography>)}
          />
          <Typography variant="h2" color="neutral.black" sx={{ px: 3, py: 1.5 }}>{tip.title}</Typography>

          <Box sx={{ display: 'flex', columnGap: 6, mt: 3 }}>
            {tip.quote ? <AdvisorQuote quote={tip.quote} /> : null}
            <AdvisorContext context={tip.paragraph} fullWidth={!tip.quote} sx={{ mx: 3 }} />
          </Box>
          {i !== accountPage.data.sellingTips.length - 1 ? <Divider sx={{ mt: 5, mb: 5 }} /> : null}
        </Box>
      ))}
    </>
  )
}

export default SellingTips
