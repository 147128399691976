import { INITIALIZE_USER, INITIALIZE_ADVISOR_USER, INITIALIZE_CUSTOMER_USER } from '../SessionAPI/constants'

export const ACTIONS = {
  INITIALIZE_USER,
  INITIALIZE_ADVISOR_USER,
  INITIALIZE_CUSTOMER_USER,
  INITIALIZE_GTAG_CUSTOMER: 'INITIALIZE_GTAG_CUSTOMER',
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE'
}

export function initializeGtagCustomer (customerData) {
  const customer = {
    customerId: customerData.id,
    customerName: customerData.attributes.name,
  }
  return {
    type: ACTIONS.INITIALIZE_GTAG_CUSTOMER,
    customer
  }
}
