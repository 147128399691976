import React from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material'
import DangerousIcon from '@mui/icons-material/Dangerous'
import Favorite from '@mui/icons-material/Favorite'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import Star from '@mui/icons-material/Star'
import StarBorder from '@mui/icons-material/StarBorder'
import {
  ConfirmDialog,
  JsonApiDataGrid
} from 'components'
import {
  Edit,
  Trash
} from 'icons'
import { JsonAPI } from 'store'
import {
  formatDate,
  httpResponseAlert,
  navigateTo,
  openDialog
} from 'utils-em'

const AccountPageTab = () => {
  const dispatch = useDispatch()

  const deleteAccountPage = async (accountPageId) => {
    const resp = await dispatch(JsonAPI.delete({
      type: 'accountPages',
      id: accountPageId
    }))
    httpResponseAlert(resp)
  }

  const disableSurveysForOrg = async (orgId) => {
    const resp = await dispatch(JsonAPI.save({
      type: 'organizations',
      id: orgId,
      surveysEnabled: false
    }))
    httpResponseAlert(resp)
  }

  const initialState = {
    sorting: {
      sortModel: [{ field: 'dateUpdated', sort: 'desc' }]
    }
  }

  const getAllColumns = () => {
    const toggleField = async (accountPageId, field, previousValue) => {
      const resp = await dispatch(JsonAPI.save({
        type: 'accountPages',
        id: accountPageId,
        [field]: !previousValue
      }))
      httpResponseAlert(resp)
    }

    const columns = [
      {
        field: 'organization.name',
        headerName: 'Account',
        width: 250,
        valueGetter: ({ row }) => row.organization.name
      },
      {
        field: 'dateCreated',
        headerName: 'Date created',
        width: 130,
        valueGetter: ({ row }) => formatDate(row.dateCreated)
      },
      {
        field: 'dateUpdated',
        headerName: 'Date updated',
        width: 130,
        valueGetter: ({ row }) => formatDate(row.dateUpdated)
      },
      {
        field: 'organization.tags.name',
        sortable: false,
        filterable: false,
        headerName: 'Industries',
        width: 150,
        valueGetter: ({ row }) => {
          const orgTags = row.organization?.tags || []
          return orgTags.map((ind) => ind.name).join(', ')
        }
      },
      {
        field: 'isPublished',
        type: 'boolean',
        headerName: 'Published',
        width: 100,
        valueGetter: ({ row }) => row.isPublished,
        renderCell: ({ row }) => (row.isPublished ? 'Published' : '')
      },
      {
        field: 'publishDate',
        type: 'date',
        headerName: 'Last published',
        width: 120,
        valueGetter: ({ row }) => (row.publishDate ? formatDate(row.publishDate) : null)
      },
      {
        field: 'actions',
        disableExport: true,
        sortable: false,
        filterable: false,
        headerName: 'Actions',
        width: 350,
        renderCell: (params) => (
          <ButtonGroup>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigateTo(`/admin/account-page-management/edit/${params.row.id}`)}>
                <Edit sx={{ color: 'primary.main' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mark as featured">
              <IconButton onClick={() => toggleField(params.row.id, 'isFeatured', params.row.isFeatured)}>
                {params.row.isFeatured ? <Star sx={{ fill: '#E8AA4A' }} /> : <StarBorder />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Mark as suggested">
              <IconButton onClick={() => toggleField(params.row.id, 'isSuggested', params.row.isSuggested)}>
                {params.row.isSuggested ? <Favorite sx={{ fill: '#dd7a64' }} /> : <FavoriteBorder />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Stop surveys">
              <IconButton onClick={() => openDialog(
                <ConfirmDialog
                  size="sm"
                  title="Stop all surveys"
                  description="Are you sure you want to disable surveys for this organization?"
                  actions={[
                    {
                      name: 'Stop surveys',
                      isDelete: true,
                      action: async () => disableSurveysForOrg(params.row.organization.id)
                    }
                  ]}
                />
              )}
              >
                <DangerousIcon sx={{ color: 'error.main' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => openDialog(
                <ConfirmDialog
                  size="sm"
                  title="Delete account page"
                  description="Are you sure you want to delete this account page?"
                  actions={[
                    {
                      name: 'Delete',
                      isDelete: true,
                      action: async () => deleteAccountPage(params.row.id)
                    }
                  ]}
                />
              )}
              >
                <Trash sx={{ color: 'error.main' }} />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        )
      }
    ]
    return columns
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <Button variant="outlined" onClick={() => navigateTo('/admin/account-page-management/edit')}>Create account page</Button>
      </Stack>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', mt: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <JsonApiDataGrid
            type="accountPages"
            columns={getAllColumns()}
            jsonApiOptions={{
              include: ['organization', 'organization.tags'],
            }}
            mode="server"
            initialState={initialState}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AccountPageTab
