import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button } from '@mui/material'
import {
  customAlert
} from 'utils-em'
import {
  SectionHeaderWithDivider,
  Spinner
} from 'components'
import {
  JsonAPI
} from 'store'

import { isEqual, uniqWith } from 'lodash'

const JsonApiList = ({
  type,
  renderObject,
  getObjectsFromRes,
  queryStringParams,
  orderedType,
  dependencies,
  loadSize,
  header,
  noDataText,
  stateCallback,
  metaCallback,
  wrap,
  firstLoadSpinner,
  ...rest
}) => {
  const [loaded, setLoaded] = React.useState(false)
  const [firstLoaded, setFirstLoaded] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [objects, setObjects] = React.useState([])
  const [totalCount, setTotalCount] = React.useState(0)
  const [dependencyChanged, setDependencyChanged] = React.useState(false)

  const maxPages = Math.ceil(totalCount / loadSize)

  const dispatch = useDispatch()

  React.useEffect(() => { stateCallback && stateCallback({ loaded, firstLoaded, totalCount }) }, [loaded, firstLoaded, totalCount])
  React.useEffect(() => { setDependencyChanged(true) }, [...dependencies]) // resets to page 0 if results are filtered

  React.useEffect(() => {
    let isSubscribed = true
    const abortController = new AbortController()
    if (dependencyChanged) {
      setDependencyChanged(false)
      setFirstLoaded(false)
      setPage(1)
    } else {
      loadMore(abortController).then(() => {
        if (!isSubscribed) return
        setLoaded(true)
        setFirstLoaded(true)
        setDependencyChanged(false)
      })
    }
    return () => {
      isSubscribed = false
      abortController.abort()
    }
  }, [page, dependencyChanged])

  const loadMore = async (abortController) => {
    setLoaded(false)
    try {
      const res = await dispatch(JsonAPI.getAll({
        type: orderedType || type,
        queryStringParams: {
          ...queryStringParams,
          'page[size]': loadSize,
          'page[number]': page, // 1 based
        },
        abortController
      }))
      if (res.error) throw res
      if (res.data.meta && metaCallback) { metaCallback(res.data.meta) }
      setObjects((prevValue) => {
        let newObjects = getObjectsFromRes(res)
        if (page !== 1) newObjects = [...prevValue, ...newObjects]
        return uniqWith(newObjects, isEqual)
      })
      setTotalCount(res.data.meta.count)
    } catch (error) {
      if (!error.aborted) customAlert(`Data cannot be loaded. ${error.message}. Please contact support.`, true)
    }
  }

  if (firstLoadSpinner && !firstLoaded) return <Spinner />
  if (totalCount === 0 && !noDataText && firstLoaded) return null

  return (
    <Box {...rest}>
      {firstLoaded && header && <SectionHeaderWithDivider title={header} sx={{ mb: 3 }} variant="h3" />}
      {firstLoaded && totalCount === 0 && noDataText && (
        <Box typography="body1" color="neutral.black" sx={{ m: 2 }}>{noDataText}</Box>
      )}
      <Box sx={wrap ? { display: 'flex', flexWrap: 'wrap', rowGap: 2, columnGap: 3 } : {}}>
        {objects.map((props) => renderObject(props))}
      </Box>

      {loaded && page < maxPages && (
        <Button
          sx={{ mt: 1, mb: 3 }}
          onClick={() => setPage(page + 1)}
        >
          View more
        </Button>
      )}
      {firstLoaded && !loaded && <Spinner />}
    </Box>
  )
}

JsonApiList.defaultProps = {
  queryStringParams: {},
  orderedType: null,
  dependencies: [],
  loadSize: 5,
  header: null,
  noDataText: null,
  stateCallback: null,
  metaCallback: null,
  wrap: false,
  firstLoadSpinner: true,
}

JsonApiList.propTypes = {
  type: PropTypes.string.isRequired,
  renderObject: PropTypes.func.isRequired,
  getObjectsFromRes: PropTypes.func.isRequired,
  queryStringParams: PropTypes.object,
  orderedType: PropTypes.string,
  dependencies: PropTypes.array,
  loadSize: PropTypes.number,
  header: PropTypes.string,
  noDataText: PropTypes.string,
  stateCallback: PropTypes.func,
  metaCallback: PropTypes.func,
  wrap: PropTypes.bool,
  firstLoadSpinner: PropTypes.bool,
}

export default JsonApiList
