import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Prompt } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ValidatorForm } from 'react-material-ui-form-validator'
import {
  Box,
  Button,
  Divider,
  Tooltip,
  Typography
} from '@mui/material'
import {
  FormCountrySelector,
  FormTextField,
  InfoStrip,
  PhoneNumberValidator,
  TimezoneSelector
} from 'components'
import { useIsAdmin } from 'hooks'
import { HelpCircle } from 'icons'
import { JsonAPI } from 'store'
import {
  customAlert,
  getAdvisorMostRecentRelevantCompany,
  getDirtyFields,
  httpResponseAlert,
  isFormDirty,
  trimObjectStringFields,
  validateLinkedInURL,
  validateRequiredPhoneNumber
} from 'utils-em'

const PersonalInfo = ({ editingCallback, user }) => {
  const isAdmin = useIsAdmin()
  const dispatch = useDispatch()
  const [editingUser, setEditingUser] = React.useState(user)

  // statement is saved to company, not user
  const advisorCompany = getAdvisorMostRecentRelevantCompany(user)

  const personalInfo = editingUser ? [
    {
      key: 'location',
      name: editingUser.location,
      text: 'We\'re missing information about your location'
    },
    {
      key: 'timezone',
      name: editingUser.timezone,
      text: 'We\'re missing information about your time zone'
    },
    {
      key: 'statement',
      name: advisorCompany?.personalStatement,
      text: 'We\'re missing additional information about you which is helpful for clients'
    }
  ] : []

  const form = useForm()
  const defaultValues = {
    firstName: editingUser?.firstName,
    lastName: editingUser?.lastName,
    email: editingUser?.email,
    phoneNumber: editingUser?.phoneNumber || '',
    location: editingUser?.location,
    timezone: editingUser?.timezone,
    linkedinUrl: editingUser?.linkedinUrl,
    statement: advisorCompany?.personalStatement || '',
    personalInterests: editingUser?.personalInterests,
  }
  const isDirty = isFormDirty(form, defaultValues)

  const [phoneNumberError, setPhoneNumberError] = React.useState(false)

  const onSubmit = async () => {
    // Validate phone number, which must be handled separately because
    // it's entangled with the old ValidatorForm system
    setPhoneNumberError(false)

    if (!validateRequiredPhoneNumber(form.getValues('phoneNumber'))) {
      form.setError('phoneNumber', 'Enter a valid phone number')
      setPhoneNumberError(true)
      return
    }

    const dirtyFields = getDirtyFields(form, defaultValues)
    const trimmedDataToSave = trimObjectStringFields(dirtyFields)

    // "Statement" is a field that saves to the company, not the user
    const { statement } = trimmedDataToSave
    delete trimmedDataToSave.statement

    setEditingUser({
      ...editingUser,
      ...trimmedDataToSave
    })

    try {
      const saveUserResp = await dispatch(JsonAPI.save({
        type: 'advisors',
        id: user.id,
        ...trimmedDataToSave
      }))
      httpResponseAlert(saveUserResp)

      // Save statement to company if it was modified
      if (statement && advisorCompany) {
        const savePersonalStatementResp = await dispatch(JsonAPI.save({
          type: 'companies',
          id: advisorCompany.id,
          personalStatement: statement.trim()
        }))
        httpResponseAlert(savePersonalStatementResp)
      }

      if (saveUserResp.ok) {
        editingCallback(false)
      }
    } catch (error) {
      customAlert(`An error occurred saving the form: ${error.message}. Please contact support for assistance.`, true)
    }
  }

  React.useEffect(() => editingCallback(isDirty), [isDirty])

  React.useEffect(() => {
    form.reset(defaultValues)
  }, [])

  // Required fields: first name, last name, email, LinkedIn url
  return (
    <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
      <Typography variant="h2">{isAdmin ? 'Contact information' : 'Account information'}</Typography>
      {!isAdmin ? (
        <>
          <Typography sx={{ mt: 3 }}>Including details like a personal statement can go a long way toward personalizing your profile and bringing your insight to life. This information will be displayed to clients on your profile.</Typography>
          <Box sx={{ mt: 3 }}>
            {personalInfo.filter((p) => p.name === null || p.name === '').map((p) => (
              <InfoStrip key={p.key} text={p.text} />
            ))}
          </Box>
        </>
      ) : null}
      <Divider sx={{ mt: 3 }} />
      {editingUser && (
        <>
          <FormTextField
            name="firstName"
            label="First name"
            form={form}
            required
            sx={{ mt: 3 }}
          />
          <FormTextField
            name="lastName"
            label="Last name"
            form={form}
            required
            sx={{ mt: 3 }}
          />
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey">
              Only your first name is shared with clients
            </Typography>
          )}
          <FormTextField
            name="email"
            label="Email"
            form={form}
            type="email"
            required
            sx={{ mt: 3 }}
          />
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey">
              Your email address is not shared with clients
            </Typography>
          )}
          <ValidatorForm>
            {/* Wrapped in (unused) ValidatorForm since this component is currently
            designed to use the old validation system */}
            <PhoneNumberValidator
              fullWidth
              defaultCountry="us"
              label="Phone number"
              value={form.getValues('phoneNumber')}
              onChange={(value) => {
                setPhoneNumberError(false)
                form.clearErrors('phoneNumber')
                form.setValue('phoneNumber', value)
              }}
              helperText={phoneNumberError ? 'Enter a valid phone number' : ''}
              error={phoneNumberError}
              margin="normal"
              variant="outlined"
              sx={{ mt: 3, mb: 0 }}
            />
          </ValidatorForm>
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey">
              Why do we collect your phone number?
              <Tooltip
                title={(
                  <Typography variant="tiny">
                    This allows us to contact you for administrative help if needed.
                    You will not use your direct phone to communicate with Emissary
                    clients, and it will not be shared.
                  </Typography>
                )}
                placement="top"
              >
                <span>
                  <HelpCircle sx={{ ml: 1, position: 'relative', top: '6px', width: 24, height: 24, color: 'neutral.darkGrey' }} />
                </span>
              </Tooltip>
            </Typography>
          )}
          <FormCountrySelector
            id="location"
            name="location"
            label="Country"
            defaultValue={defaultValues.location}
            onChange={(option) => form.setValue('location', option?.code)}
            form={form}
            sx={{ mt: 3 }}
          />
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey">
              Where you are located
            </Typography>
          )}
          <TimezoneSelector
            initialTimezone={defaultValues.timezone}
            onChange={(e) => form.setValue('timezone', e.target.value)}
            sx={{ mt: 3 }}
          />
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey">
              Your time zone is helpful for clients to know when scheduling calls with you
            </Typography>
          )}
          <FormTextField
            name="linkedinUrl"
            label="LinkedIn URL"
            form={form}
            validator={validateLinkedInURL}
            invalidText="Enter a valid LinkedIn URL"
            required
            sx={{ mt: 3 }}
          />
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey">
              Why do we collect your LinkedIn?
              <Tooltip
                title={(
                  <Typography variant="tiny">
                    Your professional profile helps us ensure we’re matching you with the right opportunities.
                    It is not shared with Emissary clients without your express permission.
                  </Typography>
                )}
                placement="top"
              >
                <span>
                  <HelpCircle sx={{ ml: 1, position: 'relative', top: '6px', width: 24, height: 24, color: 'neutral.darkGrey' }} />
                </span>
              </Tooltip>
            </Typography>
          )}
          <FormTextField
            name="statement"
            label="Advisor statement"
            form={form}
            disabled={!advisorCompany}
            multiline
            rows={3}
            sx={{ mt: 3 }}
          />
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey" sx={{ display: 'block' }}>
              Add information about yourself that might be useful for a client to know
            </Typography>
          )}
          {!advisorCompany && (
            <Typography variant="caption" color="neutral.darkGrey" sx={{ display: 'block' }}>
              A company and role must be present to write an advisor statement
            </Typography>
          )}
          <FormTextField
            name="personalInterests"
            label="Personal Interests"
            form={form}
            multiline
            rows={3}
            sx={{ mt: 3 }}
          />
          {!isAdmin && (
            <Typography variant="caption" color="neutral.darkGrey" sx={{ display: 'block' }}>
              Include personal interests, hobbies, accomplishments, or other personal details that can help you connect with clients
            </Typography>
          )}

          <Button variant="contained" type="submit" color="primary" sx={{ mt: 3 }}>Save</Button>
          {isDirty && <Prompt message="Are you sure you would like to leave this page? You will lose any changes you made." />}
        </>
      )}
    </Box>
  )
}

PersonalInfo.propTypes = {
  editingCallback: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default PersonalInfo
