import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import React from 'react'
import { BadgeChip } from 'components'
import {
  buildJsonApiOne,
  USER_STATUS_DATA
} from 'utils-em'

const CustomerUserStatusChip = ({ customerUserId }) => {
  const customerUser = useSelector(({ data }) => buildJsonApiOne(data, 'customerUsers', customerUserId))

  const status = customerUser.isActive ? customerUser.status : 'deactivated'

  if (!status) return null
  const chipInfo = {
    text: USER_STATUS_DATA[status].text,
    chipColor: USER_STATUS_DATA[status].color
  }

  return (
    chipInfo.text && (
      <BadgeChip text={chipInfo.text} color={chipInfo.chipColor} />
    )
  )
}

CustomerUserStatusChip.propTypes = {
  customerUserId: PropTypes.string.isRequired
}

export default CustomerUserStatusChip
