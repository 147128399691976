import React from 'react'
import { Switch, Route } from 'react-router'

import AdvisorSearch from './AdvisorSearch'
import AdvisorEditor from './AdvisorEditor'
import AdvisorOffPlatformRecorder from './AdvisorOffPlatformRecorder'
import AdvisorProfilePreview from './AdvisorProfilePreview'

export default () => (
  <Switch>
    <Route exact path="/admin/advisors" component={AdvisorSearch} />
    <Route exact path="/admin/advisors/off-platform" component={AdvisorOffPlatformRecorder} />
    <Route exact path="/admin/advisors/:id" component={AdvisorEditor} />
    <Route exact path="/admin/advisors/:id/profile" component={AdvisorProfilePreview} />
  </Switch>
)
