import PropTypes from 'prop-types'
import React from 'react'
import { Alert, Box, IconButton, Snackbar, Typography } from '@mui/material'
import { Alert as AlertIcon, CheckCircle, Close, Information, WarningTriangle } from 'icons'
import {
  useEmissaryTheme
} from 'hooks'

const levelMap = {
  success: { icon: <CheckCircle />, bgcolor: 'success.main' },
  warning: { icon: <WarningTriangle />, bgcolor: 'warning.main' },
  error: { icon: <AlertIcon />, bgcolor: 'error.main' },
  info: { icon: <Information />, bgcolor: 'info.main' },
  purple: {
    icon: <Information />,
    fontVariant: 'bodyBold',
    bgcolor: 'brand.lightestPurple',
    color: 'brand.purple'
  }
}

const GlobalAlert = ({ timeout, text, level, vertical, horizontal }) => {
  const { icon, fontVariant, bgcolor, color } = levelMap[level]
  const [open, setOpen] = React.useState(true)

  const {
    isSmOrSmaller
  } = useEmissaryTheme()

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false)
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical,
          horizontal
        }}
        open={open}
        autoHideDuration={timeout}
        onClose={handleClose}
        sx={{
          width: isSmOrSmaller ? '90%' : 'inherit',
          maxWidth: isSmOrSmaller ? 'inherit' : '600px',
          minHeight: '40px'
        }}
      >
        <Alert
          sx={{
            mb: 1,
            pt: 0,
            pb: 0,
            borderRadius: '8px',
            bgcolor,
            '& path': { stroke: color || 'white' }
          }}
          icon={icon}
          action={(
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </Box>
          )}
        >
          <Typography variant={fontVariant || 'body1'} color={color || 'neutral.white'}>
            {text}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  )
}

GlobalAlert.defaultProps = {
  timeout: 3000,
  vertical: 'bottom',
  horizontal: 'left'
}

GlobalAlert.propTypes = {
  timeout: PropTypes.number,
  text: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['info', 'success', 'warning', 'error', 'purple']).isRequired,
  vertical: PropTypes.oneOf(['top', 'bottom']),
  horizontal: PropTypes.oneOf(['left', 'center', 'right'])
}

export default GlobalAlert
