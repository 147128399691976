import React from 'react'
import PropTypes from 'prop-types'
import { TextValidator } from 'react-material-ui-form-validator'

import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material'

import { Eye, EyeOff } from 'icons'

/*
 * A <TogglablePasswordInput> is an input field with
 * an eye icon floating to the right that allows the
 * user to toggle visibility of the password.
 *
 * It uses the same attributes that can be passed to a
 * <TextField> component.
 *
 * Pass the `asTextValidator` attribute to enable
 * form validation in a <ValidatorForm>.
 */
const TogglablePasswordInput = (props) => {
  const { asTextValidator, ...restOfProps } = props

  const [showPassword, updateShowPassword] = React.useState(false)

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <Tooltip title={showPassword ? 'Hide password' : 'Show password'}>
          <IconButton onClick={() => { updateShowPassword(!showPassword) }}>
            {showPassword ? (<Eye />) : (<EyeOff />)}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    )
  }

  return asTextValidator ? (
    <TextValidator
      {...restOfProps}
      type={showPassword ? 'text' : 'password'}
      InputProps={inputProps}
    />
  ) : (
    <TextField
      {...restOfProps}
      type={showPassword ? 'text' : 'password'}
      InputProps={inputProps}
    />
  )
}

TogglablePasswordInput.defaultProps = {
  asTextValidator: false
}

TogglablePasswordInput.propTypes = {
  asTextValidator: PropTypes.bool
}

export default TogglablePasswordInput
