import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Typography
} from '@mui/material'
import {
  BookmarkAccountButton,
  Chatbot
} from 'components'
import {
  ArrowLeft
} from 'icons'
import {
  useSessionUser
} from 'hooks'
import {
  ACCOUNT_PAGE_TEXT_DATA,
  formatDate,
  joinWithAnd
} from 'utils-em'

import { AccountPageContext } from './components/AccountPageContext'
import LandingPage from './components/LandingPage'
import CoachingSection from './components/CoachingSection'
import CopyToClipboard from './components/CopyToClipboard'
import SellingTips from './components/SellingTips'
import TechStack from './components/TechStack'
import WhiskerSection from './components/WhiskerSection'
import LastUpdatedTag from './components/LastUpdatedTag'
import SideNavigation from './components/SideNavigation'
import ArtificialIntelligenceSection from './components/ArtificialIntelligenceSection'
import StickyBar from './components/StickyBar'
import Logo from './components/Logo'
import TalkToSomeone from './components/TalkToSomeone'

const AccountPage = () => {
  const history = useHistory()
  const location = useSelector(({ router }) => router.location)
  const { isUnlimitedByAccount } = useSessionUser()
  const {
    activeSectionKey,
    accountPage,
    scrollableElement,
    loaded,
    accountSaved,
    savedListLoaded,
    checkIfAccountSaved,
  } = React.useContext(AccountPageContext)
  const organization = accountPage?.organization

  const talkToMeButtonRef = React.useRef()
  const [stickyHeaderVisible, setStickyHeaderVisible] = React.useState(false)

  const getActiveSection = () => {
    switch (activeSectionKey) {
      case 'sellingTips':
        return <SellingTips />
      case 'artificialIntelligence':
        return <ArtificialIntelligenceSection />
      case 'propensityToBuy':
      case 'techPreferences':
      case 'buyingProcess':
        return <WhiskerSection />
      case 'techStack':
        return <TechStack />
      case 'coaching':
        return isUnlimitedByAccount ? null : <CoachingSection />
      case 'chatbot':
        return <Chatbot filters={{ organizationId: accountPage.organization.id }} />
      default:
        return <LandingPage />
    }
  }
  const activeSection = getActiveSection()

  const renderHeaderBelowTitle = () => {
    if (activeSectionKey === 'coaching') {
      return (
        <Typography variant="h3" color="neutral.black" sx={{ ml: 3, mr: 3, mt: 1 }}>
          Here are the Emissary advisors from&nbsp;
          <strong>{accountPage.organization.name}</strong>
          . Schedule time with our experts and gain valuable deal guidance and selling recommendations.
        </Typography>
      )
    }
    if (activeSectionKey === 'landingPage') {
      return (
        <Typography variant="h3" color="neutral.black" sx={{ px: 3, py: 1.5 }}>
          {
            accountPage.contributingAdvisorSummary?.functionsOfRole?.length > 0
              ? `The advisors who provided the data in this report came from the
              ${joinWithAnd(accountPage.contributingAdvisorSummary?.functionsOfRole)} functional area${accountPage.contributingAdvisorSummary?.functionsOfRole.length === 1 ? '' : 's'}
              at `
              : 'This report contains insights gathered from advisors at '
          }
          <strong>{accountPage.organization.name}</strong>
          . Last updated on
          <strong>{` ${formatDate(accountPage.mostRecentUpdateDate, { includeYear: true })}`}</strong>
          .
        </Typography>
      )
    }
    return (
      <>
        <Typography variant="h2" color="neutral.black" sx={{ px: 3, py: 1.5 }}>
          {ACCOUNT_PAGE_TEXT_DATA[activeSectionKey].title}
        </Typography>
        {activeSectionKey !== 'chatbot' ? <LastUpdatedTag /> : null}
      </>
    )
  }

  const handleScroll = () => {
    setStickyHeaderVisible(window.scrollY > talkToMeButtonRef?.current?.offsetTop)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!loaded) { return <Skeleton /> }

  return (
    <Box
      id="account-page"
      sx={{ display: 'flex', flexDirection: 'column' }}
      ref={scrollableElement}
    >
      <StickyBar isOpen={stickyHeaderVisible} />
      {/* Header */}
      <Box sx={{ display: 'flex', px: 2, py: 1.5 }}>
        <Button
          variant="text"
          startIcon={<ArrowLeft />}
          onClick={() => history.goBack()}
        >
          {location?.state?.from === '' ? 'Back to account overview' : 'Back'}
        </Button>
        <Box sx={{ ml: 'auto', display: 'flex' }}>
          <BookmarkAccountButton orgId={organization.id} accountSaved={accountSaved} savedListLoaded={savedListLoaded} checkIfAccountSaved={checkIfAccountSaved} />
          <CopyToClipboard />
        </Box>
      </Box>
      <Box sx={{ px: 3, py: 1.5 }}>
        <Logo />
      </Box>
      <Box ref={talkToMeButtonRef} sx={{ ml: 3, mb: 3 }}>
        <TalkToSomeone organizationId={organization.id} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {renderHeaderBelowTitle()}
      </Box>

      <Divider sx={{ mt: 5, mb: 5, borderColor: 'brand.paleSlate', borderBottomWidth: '4px' }} />

      {/* Main content */}
      {activeSectionKey === 'landingPage' ? (
        <>
          {activeSection}
        </>
      ) : (
        <Box sx={{ flex: 1, display: 'flex', gap: 3 }}>
          <Box sx={{
            flex: 1,
            p: 1,
            position: 'relative',
            borderRightWidth: '1px',
            borderRightColor: 'neutral.lightGrey',
            borderRightStyle: 'solid'
          }}
          >
            <SideNavigation />
          </Box>
          <Box sx={{ flex: 2 }}>
            {activeSection}
          </Box>
        </Box>
      )}

      {/* Footer */}
      <Box sx={{ mb: ({ footerHeight }) => (activeSectionKey === 'landingPage' ? 0 : `${footerHeight}`) }}>
        <Divider sx={{ my: 5, borderBottomWidth: '1px' }} />
        <Typography variant="caption" color="neutral.black" sx={{ mt: 3, display: 'block' }}>
          {`This report is licensed to you under the Emissary Inc. customer agreement for your organization's
              internal use only - you may not redistribute, sell, license or otherwise transfer it without
              Emissary's prior written approval. Although the information in this report is believed to have been
              obtained from reliable sources, much of this information is subjective in nature and in all cases,
              Emissary makes no representation as to its accuracy or completeness.`}
        </Typography>
      </Box>
    </Box>
  )
}

export default AccountPage
