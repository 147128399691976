import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { startCase } from 'lodash'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
} from '@mui/material'

import {
  buildJsonApiFilter,
  constants,
  formatDateTime,
  compileFilters
} from 'utils-em'

import EditAccountDialog from '../EditAccountDialog'
import CustomerUserTooltips from '../../../../../../../../components/CustomerUserTooltips'
import TablePaginationActions from '../../../../../../../../components/TablePaginationActions'

const AccountsList = ({ advisorRequests, advisorRequestsCount, customerId, loadAdvisorRequests, showErrorMessage, goToBulkUploadUnlimited, openDialog }) => {
  const [sortDirection, setSortDirection] = useState('asc')
  const [sortColumn, setSortColumn] = useState('account.name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [searchTerms, setSearchTerms] = useState({
    'tags__any.name.region': [],
    'tags__any.name.business-unit': [],
    'tags__any.name.initiative': []
  })
  const [showArchived, setShowArchived] = useState(false)

  useEffect(() => {
    makeRequest()
  }, [sortDirection, sortColumn, page, rowsPerPage, searchTerms, showArchived])

  const getSortColumn = () => `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`

  const makeRequest = () => {
    const filters = Object.assign([], compileFilters(searchTerms))
    loadAdvisorRequests(
      customerId,
      rowsPerPage,
      page + 1,
      getSortColumn(),
      filters,
      showArchived
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    const filter = buildJsonApiFilter(name, value)
    updateStateForFilter(name, value, filter)
  }

  const updateStateForFilter = (name, value, filter) => {
    setSearchTerms({
      ...searchTerms,
      [name]: value
    })
  }

  const sortOnColumn = (column) => {
    setSortColumn(column)
    setSortDirection(sortColumn !== column || sortDirection === 'asc' ? 'desc' : 'asc')
  }

  return (
    <ValidatorForm onSubmit={makeRequest}>
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Button
              fullWidth
              sx={{ ml: '1rem', mt: '2rem' }}
              color="primary"
              variant="contained"
              onClick={() => { goToBulkUploadUnlimited(customerId) }}
            >
              Bulk Add Unlimited
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              sx={{ ml: '1rem', mt: '2rem' }}
              color="primary"
              variant="contained"
              onClick={() => {
                openDialog(
                  <EditAccountDialog
                    advisorRequestId={null}
                    customerId={customerId}
                  />
                )
              }}
            >
              Add Account
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              sx={{ ml: '1rem', mt: '2rem' }}
              color="primary"
              type="submit"
              variant="contained"
              onClick={() => { makeRequest() }}
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ ml: '1rem', mt: '2rem' }}
              control={(
                <Checkbox
                  label="Show Archived"
                  checked={showArchived}
                  onChange={() => { setShowArchived(!showArchived) }}
                />
              )}
              label="Show Archived"
            />
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    <TableSortLabel
                      column="name"
                      active={sortColumn === 'account.organization.name'}
                      direction={sortDirection}
                      onClick={() => sortOnColumn('account.organization.name')}
                    >
                      Name
                    </TableSortLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      value={searchTerms['account.organization.name'] || ''}
                      onChange={handleSearchFilter('account.organization.name')}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    Customer User
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      value={searchTerms['customerUsers__any.fullName'] || ''}
                      onChange={handleSearchFilter('customerUsers__any.fullName')}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    Division
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      value={searchTerms['customerUsers__any.divisions__any.name'] || ''}
                      onChange={handleSearchFilter('customerUsers__any.divisions__any.name')}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  column="status"
                  active={sortColumn === 'status'}
                  direction={sortDirection}
                  onClick={() => sortOnColumn('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    <TableSortLabel
                      column="organizationId"
                      active={sortColumn === 'account.organizationId'}
                      direction={sortDirection}
                      onClick={() => sortOnColumn('account.organizationId')}
                    >
                      Org ID
                    </TableSortLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      value={searchTerms['account.organizationId'] || ''}
                      onChange={handleSearchFilter('account.organizationId')}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  column="dateCreated"
                  active={sortColumn === 'dateCreated'}
                  direction={sortDirection}
                  onClick={() => sortOnColumn('dateCreated')}
                >
                  Date Created
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {advisorRequests.map((ar) => (
              <TableRow key={ar.id}>
                <TableCell>
                  {ar.account.name}
                </TableCell>
                <TableCell>
                  {ar.customerUsers && ar.customerUsers.map((cu, i) => (
                    <>
                      {i > 0 && ', '}
                      {cu.fullName}
                      <CustomerUserTooltips
                        customerUser={cu}
                      />
                    </>
                  ))}
                </TableCell>
                <TableCell>
                  { ar.customerUsers.length
                    ? (ar.customerUsers[0].divisions || []).map((d) => d.displayName).join(',')
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  { startCase(ar.status) }
                </TableCell>
                <TableCell>
                  {ar.account.organizationId}
                </TableCell>
                <TableCell>
                  {formatDateTime(ar.dateCreated)}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      openDialog(
                        <EditAccountDialog
                          advisorRequestId={ar.id}
                          customerId={customerId}
                        />
                      )
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 200]}
                colSpan={8}
                count={advisorRequestsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </ValidatorForm>
  )
}

AccountsList.defaultProps = {
  advisorRequests: [],
  advisorRequestsCount: 0
}

AccountsList.propTypes = {
  advisorRequests: PropTypes.array,
  advisorRequestsCount: PropTypes.number,
  customerId: PropTypes.number.isRequired,
  loadAdvisorRequests: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  goToBulkUploadUnlimited: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default AccountsList
