import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Divider,
  IconButton,
  Grid,
  Paper,
  Tooltip,
  Typography
}
  from '@mui/material'
import LocationOn from '@mui/icons-material/LocationOn'
import Link from '@mui/icons-material/Link'
import { calculateCompanyTenure, copyToClipboard } from 'utils-em'

import AdminAccessControl from '../../AdminAccessControl'

const styles = {
  paper: {
    padding: '1em'
  },
  iconAligner: {
    verticalAlign: 'top',
    paddingRight: '0.25em'
  },
  colorIcon: {
    color: '#33BFC0'
  },
  blueBox: {
    backgroundColor: '#33BFC0',
    color: 'white',
    width: '2em',
    padding: '0.2em'
  },
  alignText: {
    marginLeft: '0.2em'
  },
  advisorName: {
    marginLeft: '0.2em',
    color: '#7A7A7A'
  },
  gridMargin: {
    marginTop: '1.5em'
  },
  gridMarginCenter: {
    marginTop: '1.5em',
    textAlign: 'center'
  },
  boldFont: {
    fontWeight: 'bold'
  },
  roundedIcon: {
    borderRadius: '50%',
    backgroundColor: '#D7EEEE'
  }
}

const AdvisorExperienceInfo = ({ advisor, userIsAdmin, companyProposed, role, showSuccessMessage }) => (
  <Paper sx={{ p: '1em' }}>
    <Grid container direction="row">
      {advisor.isVip
        ? (
          <Box sx={{ ...styles.blueBox }}>
            <Typography sx={{ ...styles.alignText }}>VIP</Typography>
          </Box>
          )
        : null }
      <Grid item xs={6}>
        <Typography variant="h5" sx={{ ...styles.advisorName }}>
          { advisor.firstName }
          <AdminAccessControl>
            &nbsp;
            { advisor.lastName }
          </AdminAccessControl>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        { advisor.location
          ? (
            <Typography variant="body1" align="right" style={{ color: '#7A7A7A' }}>
              <LocationOn sx={{ ...styles.iconAligner }} />
              {' '}
              { advisor.location }
            </Typography>
            )
          : null}
      </Grid>
      <Grid item xs={12} sx={{ ...styles.gridMargin }}>
        <Typography variant="subtitle1" align="left" sx={{ ...styles.boldFont }}>
          { companyProposed.orgName }
          {' '}
          -
          { calculateCompanyTenure(companyProposed, 'calculateDecimalYears') || 0 }
          {' '}
          years
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="left" sx={{ ...styles.boldFont }}>
          { role.title }
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ ...styles.gridMargin }}>
        <Typography variant="subtitle1" align="left" sx={{ ...styles.boldFont }}>
          STATEMENT
        </Typography>
        <Typography style={{ overflow: 'auto', height: '4.7em', whiteSpace: 'pre-wrap' }}>
          { companyProposed.personalStatement }
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ ...styles.gridMarginCenter }}>
        <Tooltip title="Click to copy their profile link">
          <IconButton
            onClick={() => {
              copyToClipboard(
                `${__APP_HOST__}/${userIsAdmin ? 'admin' : 'customer'}/advisors/${advisor.id}/profile`,
                showSuccessMessage
              )
            }}
            size="large"
          >
            <Link sx={{ ...styles.colorIcon }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  </Paper>
)

AdvisorExperienceInfo.propTypes = {
  advisor: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  companyProposed: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  showSuccessMessage: PropTypes.func.isRequired
}

export default AdvisorExperienceInfo
