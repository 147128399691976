import React from 'react'
import PropTypes from 'prop-types'
import { Link, Skeleton } from '@mui/material'
import {
  useJsonAPIGetAll,
  useJsonAPIUpsert
} from 'hooks'
import {
  ApproveSurveyAssignmentQualityDialog,
  JsonApiDataGrid,
  SurveyAssignmentActionsButton
} from 'components'
import {
  formatDate,
  SURVEY_ASSIGNMENT_STATUS_MAP,
  SURVEY_ASSIGNMENT_RESPONSE_METHOD_MAP
} from 'utils-em'

const PAYMENT_STATUS_MAP = {
  complete: 'Complete',
  failed: 'Failed submission',
  deferred: 'Deferred submission',
  submitting: 'Submitting',
  pending: 'Submitted',
  awaiting_payment_info: 'Awaiting payment info',
}

const RESPONSE_QUALITY_STATUS_MAP = {
  assignable: 'Assign',
  strong: 'Strong',
  average: 'Average',
  weak: 'Weak',
  unassignable: '-',
}

const SurveyAssignmentsTable = ({ newAssignmentIds, organizationId }) => {
  const { data: surveys, loaded: surveysLoaded } = useJsonAPIGetAll('surveys')
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const [updatedAssignmentId, setUpdatedAssignmentId] = React.useState(null)
  const [quality, setQuality] = React.useState('')
  const [assignmentToUpdate, setAssignmentToUpdate] = React.useState(null)

  const buildFilters = () => {
    const filters = []
    if (organizationId) filters.push({ name: 'organizationId', op: 'eq', val: organizationId })
    return filters
  }

  const handleSaveQuality = async () => {
    await upsert({
      type: 'surveyAssignments',
      id: assignmentToUpdate.id,
      approvedDate: assignmentToUpdate.approvedDate || new Date().toISOString(),
      responseQuality: quality,
      publishedDate: quality !== 'weak' ? new Date().toISOString() : null
    })
    setAssignmentToUpdate(null)
    setQuality('')
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Assignment ID',
      width: 75,
      valueGetter: ({ row }) => row.id,
      hidden: true
    },
    {
      field: 'dateCreated',
      headerName: 'Date assigned',
      type: 'date',
      width: 125,
      valueGetter: ({ row }) => row.dateCreated && new Date(row.dateCreated),
      renderCell: ({ row }) => (
        <>
          {newAssignmentIds?.includes(parseInt(row.id, 10)) ? '✨ ' : null}
          {formatDate(row.dateCreated, { includeYear: true })}
        </>
      )
    },
    {
      field: 'surveyTitle',
      headerName: 'Survey',
      type: 'singleSelect',
      valueOptions: surveys?.map((s) => s.title) || [],
      flex: 1,
      valueGetter: ({ row }) => row.surveyTitle,
    },
    {
      field: 'advisor.id',
      headerName: 'Advisor ID',
      hidden: true,
      width: 75,
      valueGetter: ({ row }) => row.advisor.id,
    },
    {
      field: 'advisor.fullName',
      headerName: 'Advisor',
      flex: 1,
      valueGetter: ({ row }) => row.advisor.fullName,
      renderCell: ({ row }) => (
        <Link
          target="_blank"
          href={`/admin/advisors/${row.advisor.id}`}
        >
          {row.advisor.fullName}
        </Link>
      )
    },
    {
      field: 'advisor.email',
      headerName: 'Advisor email',
      width: 300,
      valueGetter: ({ row }) => row.advisor.email,
    },
    {
      field: 'organization.id',
      headerName: 'Org ID',
      hidden: true,
      width: 75,
      valueGetter: ({ row }) => row.organization.id,
    },
    {
      field: 'organization.name',
      headerName: 'Account',
      flex: 1,
      hidden: organizationId !== null,
      valueGetter: ({ row }) => row.organization.name,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: ({ row }) => row.status,
      renderCell: ({ row }) => SURVEY_ASSIGNMENT_STATUS_MAP?.[row.status] || SURVEY_ASSIGNMENT_STATUS_MAP.assigned,
      type: 'singleSelect',
      width: 150,
      valueOptions: Object.keys(SURVEY_ASSIGNMENT_STATUS_MAP).map((k) => ({ value: k, label: SURVEY_ASSIGNMENT_STATUS_MAP[k] })),
    },
    {
      field: 'responseQualityStatusSort',
      headerName: 'Quality marker',
      width: 150,
      valueGetter: ({ row }) => row.responseQualityStatusSort,
      renderCell: ({ row }) => {
        if (row.responseQualityStatus === 'assignable') {
          return (
            <Link
              onClick={() => setAssignmentToUpdate(row)}
            >
              {RESPONSE_QUALITY_STATUS_MAP[row.responseQualityStatus]}
            </Link>
          )
        }
        return (
          <>{RESPONSE_QUALITY_STATUS_MAP[row.responseQualityStatus]}</>
        )
      },
      type: 'singleSelect',
      valueOptions: Object.keys(RESPONSE_QUALITY_STATUS_MAP).map((k) => ({ value: k, label: RESPONSE_QUALITY_STATUS_MAP[k] })),
    },
    {
      field: 'completedDate',
      headerName: 'Date completed',
      width: 125,
      type: 'date',
      valueGetter: ({ row }) => row.completedDate && new Date(row.completedDate),
      renderCell: ({ row }) => <>{formatDate(row.completedDate, { includeYear: true })}</>
    },
    {
      field: 'isArchived',
      headerName: 'Archived',
      type: 'boolean',
      hidden: true,
      valueGetter: ({ row }) => row.isArchived,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment status',
      valueGetter: ({ row }) => row.paymentStatus,
      renderCell: ({ row }) => PAYMENT_STATUS_MAP?.[row.paymentStatus] || null,
      type: 'singleSelect',
      width: 150,
      valueOptions: Object.keys(PAYMENT_STATUS_MAP).map((k) => ({ value: k, label: PAYMENT_STATUS_MAP[k] })),
      hidden: true
    },
    {
      field: 'responseMethod',
      width: 125,
      disableExport: true,
      headerName: 'Method',
      type: 'singleSelect',
      hidden: true,
      valueOptions: Object.keys(SURVEY_ASSIGNMENT_RESPONSE_METHOD_MAP).map((k) => ({ value: k, label: SURVEY_ASSIGNMENT_RESPONSE_METHOD_MAP[k] })),
      valueGetter: ({ row }) => row.responseMethod,
      renderCell: ({ row }) => {
        const formUrl = row.survey?.typeformFormUrl
        const formResponsesLink = formUrl && `${formUrl}/results#responses`
        if (!row.responseMethod) return null

        const label = SURVEY_ASSIGNMENT_RESPONSE_METHOD_MAP?.[row.responseMethod] || ''
        if (row.responseMethod === 'in_app_v1') return <>{label}</>
        return (
          <>
            {formResponsesLink ? (
              <Link
                href={formResponsesLink}
                target="_blank"
              >
                {label}
              </Link>
            ) : null}
          </>

        )
      }
    },
    {
      field: 'isOnboarding',
      hidden: true,
      headerName: 'Is Onboarding',
      valueGetter: ({ row }) => row.isOnboarding,
    },
    {
      field: 'actions',
      actions: true,
      headerName: 'Actions',
      disableExport: true,
      disableSort: true,
      disableFilter: true,
      valueGetter: ({ row }) => row.id,
      renderCell: ({ row }) => <SurveyAssignmentActionsButton assignment={row} onUpdate={({ assignmentId }) => setUpdatedAssignmentId(assignmentId)} />
    },
  ]
  const renderedColumns = columns.map((column) => (
    {
      ...column,
      cellClassName: column.actions ? undefined : (params) => (params.row.archivedDate !== null ? 'discarded' : '')
    }))

  if (!surveysLoaded) return <Skeleton variant="rounded" height="200px" />

  return (
    <>
      {assignmentToUpdate && (
        <ApproveSurveyAssignmentQualityDialog
          quality={quality}
          setQuality={setQuality}
          handleSave={() => handleSaveQuality()}
          handleClose={() => setAssignmentToUpdate(null)}
        />
      )}
      <JsonApiDataGrid
        type="surveyAssignments"
        columns={renderedColumns}
        jsonApiOptions={{
          include: ['advisor', 'organization', 'survey', 'payment'],
          queryStringParams: { 'lazy[surveyAssignments]': 'paymentStatus' },
          sortBy: '-dateCreated',
          filters: [
            ...buildFilters()
          ]
        }}
        mode="server"
        density="compact"
        dependencies={[newAssignmentIds, updatedAssignmentId, organizationId]}
      />
    </>

  )
}

SurveyAssignmentsTable.defaultProps = {
  newAssignmentIds: [],
  organizationId: null,
}

SurveyAssignmentsTable.propTypes = {
  newAssignmentIds: PropTypes.array,
  organizationId: PropTypes.number,
}

export default SurveyAssignmentsTable
