import React, { lazy } from 'react'
import { Switch, Route } from 'react-router'
import { ScrollToTop, LazyLoadWithError } from 'components'
import { useUpcomingEngagementDialogInitiator, useFeatureFlag } from 'hooks'
import { useLocation } from 'react-router-dom'

const AccountPageWrapper = lazy(() => import('./AccountPageWrapper'))
const AccountSearch = lazy(() => import('./AccountSearch'))
const AccountsAdvisorsList = lazy(() => import('./AccountsAdvisorsList'))
const AccountsByAlpha = lazy(() => import('./AccountsByAlpha'))
const AccountsByIndustry = lazy(() => import('./AccountsByIndustry'))
const AdvisorProfile = lazy(() => import('./AdvisorProfile'))
const AdvisorsListByOrg = lazy(() => import('./AdvisorsListByOrg'))
const CallProposalFlow = lazy(() => import('./CallProposalFlow'))
const ChatbotPage = lazy(() => import('./ChatbotPage'))
const CustomerFeedback = lazy(() => import('./CustomerFeedback'))
const CustomerSelfOnboarding = lazy(() => import('./CustomerSelfOnboarding'))
const CustomerEngagement = lazy(() => import('./CustomerEngagement'))
const EngagementDetail = lazy(() => import('./EngagementDetail'))
const Industries = lazy(() => import('./Industries'))
const IndustryPage = lazy(() => import('./IndustryPage'))
const InsightsDetail = lazy(() => import('./InsightsDetail'))
const MyActivity = lazy(() => import('./MyActivity'))
const NotFound = lazy(() => import('../../../components/NotFound'))
const Preferences = lazy(() => import('./Preferences'))
const Profile = lazy(() => import('./Profile'))
const SavedLists = lazy(() => import('./SavedLists'))
const SellerActivity = lazy(() => import('./SellerActivity'))
const SubscriptionsAndPurchases = lazy(() => import('./SubscriptionsAndPurchases'))
const TeamActivity = lazy(() => import('./TeamActivity'))
const TeamInfo = lazy(() => import('./TeamInfo'))

export default () => {
  const { key } = useLocation() // key prop should be used for all routes with params
  useUpcomingEngagementDialogInitiator()
  const engagementEnhancementsEnabled = useFeatureFlag('engagement_enhancements')
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/c" render={() => LazyLoadWithError(AccountSearch)} />
        <Route exact path="/c/accounts/:orgId/advisor-list" render={() => LazyLoadWithError(AdvisorsListByOrg)} key={key} />
        <Route exact path="/c/accounts/browse/alphabetical" render={() => LazyLoadWithError(AccountsByAlpha)} />
        <Route exact path="/c/accounts/browse/by-industry" render={() => LazyLoadWithError(AccountsByIndustry)} />
        <Route exact path="/c/accounts/browse/advisors" render={() => LazyLoadWithError(AccountsAdvisorsList)} />
        <Route exact path="/c/activity" render={() => LazyLoadWithError(MyActivity)} />
        <Route exact path="/c/advisor/:advisorId" render={() => LazyLoadWithError(AdvisorProfile)} key={key} />
        <Route exact path="/c/talk-to-someone/:organizationId" render={() => LazyLoadWithError(CallProposalFlow)} key={key} />
        <Route exact path="/c/advisor/:advisorId/propose-call" render={() => LazyLoadWithError(CallProposalFlow)} key={key} />
        <Route exact path="/c/buying-profile/:accountPageId" render={() => LazyLoadWithError(AccountPageWrapper)} key={key} />
        <Route exact path="/c/chatbot" render={() => LazyLoadWithError(ChatbotPage)} />
        {engagementEnhancementsEnabled
          ? <Route exact path="/c/engagements/:engagementId" render={() => LazyLoadWithError(CustomerEngagement)} key={key} />
          : <Route exact path="/c/engagements/:engagementId" render={() => LazyLoadWithError(EngagementDetail)} key={key} />}
        <Route exact path="/c/engagements/:engagementId/post-call-feedback" render={() => LazyLoadWithError(CustomerFeedback)} key={key} />
        <Route exact path="/c/industries" render={() => LazyLoadWithError(Industries)} />
        <Route exact path="/c/industry/:industryId" render={() => LazyLoadWithError(IndustryPage)} />
        <Route exact path="/c/insights/:id" render={() => LazyLoadWithError(InsightsDetail)} key={key} />
        <Route exact path="/c/my-team" render={() => LazyLoadWithError(TeamInfo)} />
        <Route exact path="/c/onboarding" render={() => LazyLoadWithError(CustomerSelfOnboarding)} />
        <Route exact path="/c/preferences" render={() => LazyLoadWithError(Preferences)} />
        <Route exact path="/c/profile" render={() => LazyLoadWithError(Profile)} />
        <Route exact path="/c/saved-lists" render={() => LazyLoadWithError(SavedLists)} />
        <Route exact path="/c/search" render={() => LazyLoadWithError(AccountSearch)} />
        <Route exact path="/c/seller-activity/:userId" render={() => LazyLoadWithError(SellerActivity)} key={key} />
        <Route exact path="/c/subscriptions-and-purchases" render={() => LazyLoadWithError(SubscriptionsAndPurchases)} />
        <Route exact path="/c/team-activity" render={() => LazyLoadWithError(TeamActivity)} />
        <Route component={() => LazyLoadWithError(NotFound)} />
      </Switch>
    </>
  )
}
