import React from 'react'
import { SvgIcon } from '@mui/material'
import PropTypes from 'prop-types'

export default function Star (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00008 3.16699L9.16675 6.83366H12.8334L9.83341 9.16699L10.8334 12.8337L8.00008 10.5003L5.16675 12.8337L6.16675 9.16699L3.16675 6.83366H6.83341L8.00008 3.16699Z" stroke={props.stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}

Star.defaultProps = {
  stroke: '#5c5c61'
}

Star.propTypes = {
  stroke: PropTypes.string
}
