import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  Typography
} from '@mui/material'

const AdvisorQuote = ({ quote }) => (
  <Box sx={{ flexBasis: '50%' }}>
    <Card sx={{ p: 3, backgroundColor: 'brand.lightNavy', borderRadius: 1, border: 'none' }}>
      <Typography variant="tiny" color="neutral.black">
        Advisor quote:
      </Typography>
      <Typography variant="bodyBold" color="neutral.black" sx={{ mt: 1 }}>
        {`"${quote}"`}
      </Typography>
    </Card>
  </Box>
)

AdvisorQuote.propTypes = { quote: PropTypes.string.isRequired }

export default AdvisorQuote
