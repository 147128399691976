/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { cloneDeep, merge, omit } from 'lodash'

import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { formatAsMiddleOfMonth, areRoleDatesValid } from 'utils-em'

import { TagDropdown } from 'components'

import AdvisorAccessControl from '../../AdvisorAccessControl'
import AdminAccessControl from '../../AdminAccessControl'
import OrganizationSelector from '../../OrganizationSelector'

const EditExperienceDialog = (props) => {
  const [company, setCompany] = useState(props.company || {
    orgName: '',
    roles: [],
    advisorId: props.advisorId,
    dateStarted: null,
    dateEnded: null,
  })

  const [saving, setSaving] = useState(false)
  const [overTagLimit, setOverTagLimit] = useState(false)

  useEffect(() => {
    ValidatorForm.addValidationRule('requiredIfNotCurrent', (value, roleIdx) => {
      const role = company.roles[roleIdx]
      return company.isCurrent || role.dateEnded
    })

    ValidatorForm.addValidationRule('mustHaveRole', (value) => (
      company.roles.filter((role) => !role.deleted).length !== 0
    ))

    ValidatorForm.addValidationRule('requiredIfCompanyNotCurrent', (value) => company.isCurrent || company.dateEnded)

    return () => {
      ValidatorForm.removeValidationRule('requiredIfNotCurrent')
      ValidatorForm.removeValidationRule('mustHaveRole')
      ValidatorForm.removeValidationRule('requiredIfCompanyNotCurrent')
    }
  }, [company])

  useEffect(() => {
    setCompany((prevState) => merge({}, prevState, props))
  }, [props])

  const handleChange = (name, value) => {
    setCompany({
      ...company,
      [name]: value
    })
  }

  const handleOrgChange = (org) => {
    setCompany((prevState) => ({
      company: {
        ...prevState.company,
        organizationId: org ? org.id : null,
      },
    }))
  }

  const handleCompanyDateChange = (name, timestamp) => {
    setCompany({
      ...company,
      [name]: timestamp ? formatAsMiddleOfMonth(timestamp).toISOString() : null,
    })
  }

  const handleCheckboxChange = (stateObjectName, name) => (event) => {
    setCompany((prevState) => ({
      ...prevState,
      [stateObjectName]: {
        ...prevState[stateObjectName],
        [name]: !prevState[stateObjectName][name],
      },
    }))
  }

  const handleVerificationChange = () => {
    setCompany((prevState) => ({
      company: {
        ...prevState.company,
        dateStandardizationVerified: prevState.company.dateStandardizationVerified
          ? null
          : moment().toISOString(),
      },
    }))
  }

  const handleRoleChange = (roleIdx, name) => (event) => {
    const { value } = event.target
    const roles = cloneDeep(company.roles)
    roles[roleIdx][name] = value
    setRoleState(roles)
  }

  const handleRoleDateChange = (roleIdx, name) => (timestamp) => {
    const roles = cloneDeep(company.roles)
    roles[roleIdx][name] = timestamp ? formatAsMiddleOfMonth(timestamp).toISOString() : null
    setRoleState(roles)
  }

  const handleSave = (event) => {
    const rolesToSave = cloneDeep(company.roles.filter((role) => !role.deleted || role.newDelete))

    if (rolesToSave.some((r) => !areRoleDatesValid(r, company))) {
      props.showErrorMessage({ text: 'Error: Company and role start/end dates are inconsistent', timeout: 2000 })
      return
    }

    const alreadyExistingNonDeletedCompanyWithName = props.allAdvisorCompanies.find(
      (c) => c.orgName === company.orgName && c.id !== company.id && !c.deleted
    )

    if (alreadyExistingNonDeletedCompanyWithName) {
      props.showErrorMessage({ text: 'Error: Company already exists with name', timeout: 2000 })
      return
    }

    const companyToSave = {
      ...company,
      dateEnded: !company.isCurrent ? company.dateEnded : null,
    }

    setSaving(true)
    props.saveCompany(omit(companyToSave, ['roles'])).then((result) => {
      const rolesWithCompanyId = rolesToSave.map((role) => ({ ...role, companyId: result.data.data.id }))
      props.saveRoles(rolesWithCompanyId).then((roleResult) => {
        if (!result.ok) {
          props.showErrorMessage({ text: 'Error: Could not save company', timeout: 2000 })
        } else {
          props.showSuccessMessage({ text: 'Experience Saved!', timeout: 2000 })
          props.handleClose(result)
        }
        setSaving(false)
      })
    })
  }

  const setRoleState = (roles) => {
    setCompany((prevState) => ({
      ...prevState,
      company: { roles },
    }))
  }

  const setTags = (roleIdx, category, tags) => {
    setCompany((prevState) => {
      const roles = cloneDeep(prevState.company.roles)
      roles[roleIdx].tags = tags.length > 0 ? tags.concat(roles[roleIdx].tags.filter((tag) => tag && tag.category !== category)) : []
      return { company: { ...prevState.company, roles } }
    })
  }

  const addNewRole = () => {
    const newRoles = cloneDeep(company.roles)
    newRoles.push({
      title: '',
      description: '',
      dateStarted: null,
      dateEnded: null,
      isCurrent: false,
      tags: [],
      relationshipNames: ['tags'],
      type: 'roles',
    })
    setCompany((prevState) => ({
      company: { roles: newRoles },
      ...prevState,
    }))
  }

  const deleteRole = (idx) => (event) => {
    setCompany((prevState) => {
      const roles = cloneDeep(prevState.company.roles)
      if (roles[idx].id) {
        roles[idx].deleted = true
        roles[idx].newDelete = true
      } else {
        roles.splice(idx, 1)
      }

      const companyMinusRole = { ...prevState.company }
      companyMinusRole.roles = roles

      return { company: companyMinusRole }
    })
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm
        onSubmit={handleSave}
        id="experienceForm"
        instantValidate={false}
      >
        <DialogContent
          sx={{
            minHeight: '30em',
            overflowY: 'inherit',
          }}
        >
          <DialogContentText>
            Edit Experience
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextValidator
                autoFocus
                margin="dense"
                label="Company Name"
                validators={['required', 'mustHaveRole']}
                errorMessages={['Required Field', 'Companies must have at least one role']}
                onChange={(e) => handleChange('orgName', e.target?.value)}
                value={company.orgName}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={(
                  <Checkbox
                    label="Current Role"
                    checked={company.isCurrent}
                    onChange={handleCheckboxChange('company', 'isCurrent')}
                  />
                )}
                label="Is Current"
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                sx={{ width: '100%' }}
                views={['year', 'month']}
                label="Start Date"
                maxDate={moment(company.dateEnded || new Date())}
                value={moment(company.dateStarted)}
                onChange={(e) => handleCompanyDateChange('dateStarted', e)}
                disableFuture
                renderInput={(params) => (
                  <TextValidator
                    value={company.dateStarted}
                    fullWidth
                    margin="normal"
                    validators={['required']}
                    errorMessages={['Required Field']}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                sx={{ width: '100%' }}
                views={['year', 'month']}
                label="End Date"
                minDate={company.dateStarted ? moment(company.dateStarted) : null}
                value={moment(company.dateEnded)}
                onChange={(e) => handleCompanyDateChange('dateEnded', e)}
                disabled={company.isCurrent}
                disableFuture
                renderInput={(params) => (
                  <TextValidator
                    value={company.dateEnded}
                    fullWidth
                    margin="normal"
                    validators={['requiredIfCompanyNotCurrent']}
                    errorMessages={['Required Field']}
                    {...params}
                  />
                )}
              />
            </Grid>
            <AdminAccessControl>
              <Grid item xs={4} sx={{ mt: 2 }} />
            </AdminAccessControl>
            <AdminAccessControl>
              <Grid item xs={9}>
                <OrganizationSelector
                  organizationId={company.organizationId}
                  handleChange={(newOrg) => handleOrgChange(newOrg)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      label="Is Validated"
                      checked={!!company.dateStandardizationVerified}
                      onChange={handleVerificationChange}
                    />
                  )}
                  label="Is Validated"
                />
              </Grid>
            </AdminAccessControl>

            <AdvisorAccessControl>
              <Grid item xs={12}>
                <TextValidator
                  multiline
                  maxRows="4"
                  label="Personal Statement"
                  onChange={(e) => handleChange('personalStatement', e.target?.value)}
                  value={company.personalStatement || ''}
                  fullWidth
                />
              </Grid>
            </AdvisorAccessControl>
            <Grid item>
              {company.roles.map((role, idx) => (
                <React.Fragment key={`role_${idx}`}>
                  {role.deleted
                    ? null
                    : (
                      <React.Fragment key={`role_${idx}`}>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextValidator
                              autoFocus
                              margin="dense"
                              label="Title"
                              validators={['required']}
                              errorMessages={['Required Field']}
                              onChange={handleRoleChange(idx, 'title')}
                              value={role.title || ''}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextValidator
                              label="Description"
                              onChange={handleRoleChange(idx, 'description')}
                              value={role.description || ''}
                              fullWidth
                              multiline
                              maxRows="4"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <SelectValidator
                              required
                              label="Seniority"
                              value={role.seniority || ''}
                              validators={['required']}
                              errorMessages={['Required Field']}
                              onChange={handleRoleChange(idx, 'seniority')}
                              fullWidth
                            >
                              {Object.entries(props.seniorityEnums).map(([id, seniority]) => (
                                <MenuItem key={id} value={seniority.value}>{seniority.value}</MenuItem>
                              ))}
                            </SelectValidator>
                          </Grid>
                          <Grid item xs={4.01}>
                            <DatePicker
                              sx={{ width: '100%' }}
                              views={['year', 'month']}
                              label="Start Date in role"
                              minDate={moment(company.dateStarted).utc().startOf('month')}
                              maxDate={moment(role.dateEnded || company.dateEnded || new Date()).utc().endOf('month')}
                              value={moment(role.dateStarted)}
                              onChange={handleRoleDateChange(idx, 'dateStarted')}
                              disableFuture
                              renderInput={(params) => (
                                <TextValidator
                                  fullWidth
                                  value={role.dateStarted}
                                  margin="normal"
                                  validators={['required']}
                                  errorMessages={['Required Field']}
                                  {...params}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4.01}>
                            <DatePicker
                              sx={{ width: '100%' }}
                              views={['year', 'month']}
                              label="End Date in role"
                              minDate={moment(role.dateStarted || company.dateStarted).utc().startOf('month')}
                              maxDate={moment(company.isCurrent ? new Date() : company.dateEnded).utc().endOf('month')}
                              value={moment(role.dateEnded)}
                              onChange={handleRoleDateChange(idx, 'dateEnded')}
                              disableFuture
                              renderInput={(params) => (
                                <TextValidator
                                  fullWidth
                                  value={role.dateEnded}
                                  margin="normal"
                                  validators={[`requiredIfNotCurrent:${idx}`]}
                                  errorMessages={['Required Field or Current']}
                                  {...params}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <TagDropdown
                              multiple
                              multipleLimit={2}
                              multipleLimitError="Please select up to two tags"
                              label="Function of role"
                              tagCategory="function-of-role"
                              initialTags={role.tags.filter((tag) => tag && tag.category === 'function-of-role')}
                              onChange={(selectedTags) => {
                                setTags(idx, 'function-of-role', selectedTags)
                                setOverTagLimit(selectedTags.length > 2)
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TagDropdown
                              label="Region"
                              tagCategory="region"
                              initialTags={role.tags.filter((tag) => tag && tag.category === 'region')}
                              onChange={(selectedTags) => setTags(idx, 'region', selectedTags)}
                            />
                          </Grid>
                        </Grid>
                        <Button onClick={deleteRole(idx)} color="primary">
                          <DeleteIcon />
                          {' '}
                          Delete role
                        </Button>
                      </React.Fragment>
                      )}
                </React.Fragment>
              ))}
            </Grid>
            <Divider />
            <Button onClick={addNewRole} color="primary">
              <AddIcon />
              {' '}
              Add new role
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" disabled={saving || overTagLimit}>
            Save
          </Button>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditExperienceDialog.defaultProps = {
  company: null,
  isAdvisor: false,
  unnestedRoleId: null,
}

EditExperienceDialog.propTypes = {
  isAdvisor: PropTypes.bool,
  company: PropTypes.object,
  allAdvisorCompanies: PropTypes.array.isRequired,
  advisorId: PropTypes.number.isRequired,
  tenureEnums: PropTypes.object.isRequired,
  seniorityEnums: PropTypes.object.isRequired,
  budgetSizeEnums: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveCompany: PropTypes.func.isRequired,
  saveRoles: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  unnestedRoleId: PropTypes.string,
}

export default EditExperienceDialog
