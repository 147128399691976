import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Link,
  Tooltip,
  Typography
} from '@mui/material'
import { Link as LinkIcon } from 'icons'

import {
  closeDialog,
  constants,
  copyToClipboard,
  customAlert,
  navigateToNewTab,
  openDialog
} from 'utils-em'
import {
  ConfirmDialog,
  FilterGroup,
  JsonApiTable
} from 'components'
import { JsonAPI } from 'store'

const SavedInsights = ({ customerUserId }) => {
  const dispatch = useDispatch()

  const [forceRefresh, setForceRefresh] = React.useState(0)
  const [isCopied, setIsCopied] = React.useState(false)
  const isLoggedInUser = useSelector(({ session }) => session.id === customerUserId)

  // search filter
  const initialQueryStringParams = { include: 'insight' }
  const filterOptions = [
    { initialQueryFilters: { name: 'ownerId', op: 'eq', val: customerUserId } },
    { initialQueryFilters: { name: 'bookmarkType', op: 'eq', val: 'SAVED_INSIGHT' } },
  ]
  const [queryStringParams, setQueryStringParams] = React.useState(initialQueryStringParams)

  const removeBookmark = async (bookmark) => {
    const res = await dispatch(JsonAPI.delete({
      type: 'bookmarks',
      id: bookmark.id
    }))
    res.ok ? customAlert('Insight successfully removed from list', false) : customAlert('Error removing insight from list', true)
  }

  const getInsightLink = (bookmark) => {
    if (bookmark.bookmarkType === 'SAVED_INSIGHT') {
      return `${__APP_HOST__}/c/insights/${bookmark.bookmarkTarget}`
    }
  }

  const isUnavailable = ({ insight }) => insight?.isDeleted || insight?.isArchived

  return (
    <>
      <FilterGroup
        title="Saved Insights"
        filterOptions={filterOptions}
        textSearchPlaceholder="Search by name"
        initialQueryStringParams={initialQueryStringParams}
        onQueryStringParamsChange={setQueryStringParams}
        textSearchQueryFields={['insight.title']}
      />
      {isLoggedInUser && (
      <Typography variant="body" color="neutral.black" sx={{ mt: 2, mb: 2, ml: 2, display: 'inline-block' }}>
        Keep track of the insights you're interested in by saving them to your account, and we will notify you whenever they are updated.
      </Typography>
      )}
      <JsonApiTable
        type="bookmarks"
        queryStringParams={queryStringParams}
        pageSizeOptions={constants.TWO_OH_ROWS_PER_PAGE_OPTIONS}
        dependencies={[forceRefresh]}
        onRowClick={(bookmark) => {
          if (isUnavailable(bookmark)) return
          navigateToNewTab(getInsightLink(bookmark))
        }}
        noDataText="There are no insights to display."
        columns={[
          {
            name: 'INSIGHT_TITLE',
            sortKey: 'title',
            defaultSort: true,
            header: 'Insight name',
            value: (bookmark) => {
              const disabled = isUnavailable(bookmark)
              return (
                <Typography
                  variant="body"
                  color={disabled ? 'neutral.disabled' : 'neutral.black'}
                >
                  {`${bookmark.title} ${disabled ? '- No longer available' : ''}`}
                </Typography>
              )
            },
          },
          {
            name: 'ACTIONS',
            header: '',
            value: (bookmark) => (
              <Box sx={{ textAlign: 'right' }}>
                {(!bookmark.insight?.isDeleted && !bookmark.insight?.isArchived) && (
                <Tooltip title={isCopied ? 'Copied to clipboard!' : ''}>
                  <Link
                    variant="caption"
                    color="primary.main"
                    onMouseEnter={() => setIsCopied(false)}
                    onClick={(e) => {
                      e.stopPropagation() // prevent triggering row click
                      setIsCopied(true)
                      copyToClipboard(getInsightLink(bookmark))
                    }}
                  >
                    <LinkIcon style={{ position: 'relative', top: '7px', mr: 1 }} />
                    Copy link to insight
                  </Link>
                </Tooltip>
                )}
                <Link
                  variant="caption"
                  color="error.main"
                  sx={{ ml: 4 }}
                  onClick={(e) => {
                    e.stopPropagation() // prevent triggering row click
                    openDialog(
                      <ConfirmDialog
                        title="Remove insight from list?"
                        description={`Are you sure you would like to remove ${bookmark.title} from ${isLoggedInUser ? 'your' : 'their'} Saved Insights list?`}
                        cancelName={`Keep it on ${isLoggedInUser ? 'my' : 'their'}  list`}
                        cancelAction={closeDialog()}
                        size="sm"
                        actions={[
                          {
                            name: 'Remove from list',
                            isDelete: true,
                            action: () => {
                              removeBookmark(bookmark)
                              setForceRefresh(forceRefresh + 1)
                            }
                          }
                        ]}
                      />
                    )
                  }}
                >
                  Unsave
                </Link>
              </Box>
            )
          }
        ]}
      />
    </>
  )
}

SavedInsights.propTypes = {
  customerUserId: PropTypes.string.isRequired
}

export default SavedInsights
