import PropTypes from 'prop-types'
import React from 'react'

import Paper from '@mui/material/Paper'
import {
  Button,
  Divider,
  Typography,
  TextField
} from '@mui/material'

const AdvisorAdminNotesCard = ({ advisor, saveAdvisor, showErrorMessage, showSuccessMessage }) => {
  const [adminNotes, setAdminNotes] = React.useState(advisor.adminNotes || '')

  const handleSave = () => (
    new Promise((resolve, reject) => {
      saveAdvisor({ ...advisor, adminNotes }).then((res) => {
        if (!res.ok) {
          showErrorMessage({ text: 'Error: Could not save', timeout: 4000 })
        } else {
          showSuccessMessage({ text: 'Admin Notes updated!', timeout: 4000 })
        }
      })
    })
  )
  return (
    <Paper sx={{ mt: '0.5em', p: '1em' }}>
      <Typography variant="h6" align="left">
        Notes
      </Typography>
      <Divider />
      <Typography sx={{ ml: '2em' }} variant="subtitle1" align="left">
        <TextField
          multiline
          rows={21}
          label="Add/Edit Notes"
          value={adminNotes}
          onChange={(e) => { setAdminNotes(e.currentTarget.value) }}
          margin="dense"
        />
      </Typography>
      <Button color="primary" variant="contained" onClick={handleSave} style={{ marginTop: '0.7em' }}>
        Save
      </Button>
    </Paper>
  )
}

AdvisorAdminNotesCard.propTypes = {
  advisor: PropTypes.object.isRequired,
  saveAdvisor: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired
}

export default AdvisorAdminNotesCard
