import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Card,
  Chip,
  Divider,
  Typography
} from '@mui/material'

import {
  buildJsonApiOne,
  calculateCompanyTenure
} from 'utils-em'

import { RoleIconDetailsTray } from 'components'

const CompanyCard = ({ companyId }) => {
  const company = useSelector(({ data }) => buildJsonApiOne(data, 'companies', companyId))

  return (
    <Card sx={{ mb: 3, py: 1.5, bgcolor: 'brand.lightNavy', border: 'none', '&:last-child': { mb: 0 } }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1.5, px: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2">
            {company.displayOrgName}
          </Typography>
          { calculateCompanyTenure(company, true)?.length && (
            <Chip
              label={(
                <Typography variant="body1">
                  {`${calculateCompanyTenure(company, true)} years`}
                </Typography>
              )}
              size="large"
              sx={{ ml: 2, backgroundColor: 'neutral.white' }}
            />
          )}
          {!company.isCurrent && company.recentlyDeparted && (
            <Chip
              label={(
                <Typography variant="bodyBold" color="brand.darkOrange">
                  Coach
                </Typography>
              )}
              size="large"
              sx={{ ml: 2, backgroundColor: 'brand.paleOrange' }}
            />
          )}
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />
      {company.roles.filter((role) => !role.deleted).map((role, i) => (
        <Box key={role.id}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1, px: 3 }}>
            <Typography variant="h4">
              {role.title}
            </Typography>
          </Box>
          <Box sx={{ mx: 3 }}>
            <RoleIconDetailsTray role={role} sx={{ py: 1.5, px: 3 }} />
          </Box>
          {role.description && (
            <Typography variant="body1" sx={{ py: 1.5, px: 3 }}>
              {role.description}
            </Typography>
          )}
          {(i !== company.roles.length - 1) && <Divider sx={{ my: 1 }} />}
        </Box>
      ))}
    </Card>
  )
}

CompanyCard.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default CompanyCard
