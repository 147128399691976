import React from 'react'
import PropTypes from 'prop-types'

import Autocomplete from '@mui/material/Autocomplete'

import {
  Button,
  Chip,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'

import AccountCircle from '@mui/icons-material/AccountCircle'
import Cancel from '@mui/icons-material/Cancel'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Send from '@mui/icons-material/Send'
import SupervisedUserCircle from '@mui/icons-material/SupervisedUserCircle'
import Stars from '@mui/icons-material/Stars'
import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'

import { formatDateTime, validateRequiredPhoneNumber } from 'utils-em'

import TogglablePasswordInput from '../../TogglablePasswordInput'
import AdminAccessControl from '../../AdminAccessControl'
import CustomerAutocomplete from '../../CustomerAutocomplete'
import PhoneNumberValidator from '../../PhoneNumberValidator'
import AdminFormFields from './FormFields/AdminFormFields'

const displayUserStatusLabel = (u) => {
  if (u.onboardingCompleted != null) {
    return (<CheckCircle style={{ color: '#5aa469' }} fontSize="small" />)
  } if (u.onboardingStarted != null) {
    return (<PauseCircleFilled style={{ color: '#efb08c' }} fontSize="small" />)
  }
  return (<Cancel style={{ color: '#cd5d7d' }} fontSize="small" />)
}

const UserForm = ({
  customerDivisions,
  disabledFields,
  formData,
  originalUserData,
  visibleSections,
  updateFormData,
  userType
}) => {
  React.useEffect(() => {
    if (userType === 'customerUser') {
      ValidatorForm.addValidationRule('validPhoneNumber', validateRequiredPhoneNumber)
    }
  }, [])

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextValidator
            fullWidth
            required
            disabled={disabledFields.includes('firstName')}
            size="small"
            variant="outlined"
            name="firstName"
            validators={['required']}
            errorMessages={['Required']}
            label="First Name"
            value={formData.firstName}
            onChange={(e) => updateFormData({ firstName: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextValidator
            fullWidth
            required
            disabled={disabledFields.includes('lastName')}
            size="small"
            variant="outlined"
            validators={['required']}
            errorMessages={['Required']}
            label="Last Name"
            value={formData.lastName}
            onChange={(e) => updateFormData({ lastName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            fullWidth
            required
            disabled={disabledFields.includes('email')}
            type="email"
            size="small"
            variant="outlined"
            validators={disabledFields.includes('email') ? [] : ['required', 'isEmail']}
            errorMessages={['Required', 'Email not valid']}
            label="Email"
            value={formData.email}
            onChange={(e) => updateFormData({ email: e.target.value })}
          />
        </Grid>
        {userType === 'admin' && !originalUserData && (
          <Grid item xs={12}>
            <TogglablePasswordInput
              fullWidth
              name="initialPassword"
              required={false}
              size="small"
              variant="outlined"
              label="Initial password"
              value={formData.initialPassword}
              onChange={(e) => updateFormData({ initialPassword: e.target.value })}
            />
          </Grid>
        )}
        {userType === 'customerUser'
          ? (
            <>
              {!formData.id
                ? (
                  <Grid item xs={12}>
                    <AdminAccessControl>
                      <CustomerAutocomplete
                        required
                        label="Customer"
                        size="small"
                        variant="outlined"
                        multiple={false}
                        onChange={(_, customer) => updateFormData({ customerId: customer ? customer.id : null })}
                      />
                    </AdminAccessControl>
                  </Grid>
                  )
                : (
                    null
                  )}
              <Grid item xs={12}>
                <PhoneNumberValidator
                  fullWidth
                  defaultCountry="us"
                  disabled={disabledFields.includes('phoneNumber')}
                  size="small"
                  label="Phone Number"
                  variant="outlined"
                  value={formData.phoneNumber}
                  onChange={(value) => updateFormData({ phoneNumber: value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disabledFields.includes('location')}
                  size="small"
                  variant="outlined"
                  label="Location"
                  value={formData.location}
                  onChange={(e) => updateFormData({ location: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={disabledFields.includes('jobTitle')}
                  size="small"
                  variant="outlined"
                  label="Title"
                  value={formData.jobTitle}
                  onChange={(e) => updateFormData({ jobTitle: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectValidator
                  fullWidth
                  required
                  disabled={disabledFields.includes('roleType')}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Required']}
                  size="small"
                  label="Role"
                  value={formData.roleType}
                  onChange={
                  (e) => updateFormData(
                    {
                      roleType: e.target.value
                    }
                  )
                }
                >
                  {originalUserData !== null
                    ? (
                      <MenuItem value="–"><Chip size="small" label="–" /></MenuItem>
                      )
                    : null}
                  <MenuItem value="rep">
                    <Chip size="small" label="Rep" icon={<AccountCircle />} />
                  </MenuItem>
                  <MenuItem value="manager">
                    <Chip size="small" label="Manager" icon={<SupervisedUserCircle />} />
                  </MenuItem>
                  <MenuItem value="program_owner">
                    <Chip size="small" label="Program owner" icon={<Stars />} />
                  </MenuItem>
                </SelectValidator>
              </Grid>
              {visibleSections.includes('divisions')
                ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="user-divisions"
                      disabled={disabledFields.includes('divisions')}
                      size="small"
                      options={customerDivisions}
                      onChange={(_, value) => updateFormData({ divisions: value })}
                      getOptionLabel={(division) => division.displayName}
                      isOptionEqualToValue={(option, value) => option != null && value != null && (option.id === value.id)}
                      filterSelectedOptions
                      value={formData.divisions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Teams"
                          placeholder="Add Team..."
                        />
                      )}
                      renderTags={(value, getTagProps) => (
                        value.map((div, index) => (
                          <Chip
                            key={`div-chip-${div.id}`}
                            variant="outlined"
                            style={{ margin: '0.25rem' }}
                            size="small"
                            label={div.displayName}
                            {...getTagProps({ index })}
                          />
                        ))
                      )}
                    />
                  </Grid>
                  )
                : null}
              {originalUserData && visibleSections.includes('userActivity')
                ? (
                  <Grid container item xs={12}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Status</TableCell>
                            <TableCell>Onboarded</TableCell>
                            <TableCell>Last Login</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">{displayUserStatusLabel(originalUserData)}</TableCell>
                            <TableCell>
                              {
                            originalUserData.onboardingCompleted
                              ? formatDateTime(originalUserData.onboardingCompleted)
                              : 'N/A'
                          }
                            </TableCell>
                            <TableCell>{originalUserData.lastLogin ? formatDateTime(originalUserData.lastLogin) : 'N/A'}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  )
                : null}
            </>
            )
          : (
              null
            )}
        {userType === 'admin'
          ? (
            <AdminFormFields
              disabledFields={disabledFields}
              formData={formData}
              updateFormData={updateFormData}
            />
            )
          : (
              null
            )}
        {originalUserData
          ? (
            <Grid item xs={12}>
              <Button
                variant={formData.isActive === originalUserData.isActive ? 'outlined' : 'contained'}
                color="secondary"
                startIcon={originalUserData.isActive ? <VisibilityOff /> : <Visibility />}
                onClick={() => updateFormData({ isActive: !formData.isActive })}
              >
                {originalUserData.isActive ? 'De-Activate' : 'Activate'}
              </Button>
            &nbsp;&nbsp;
              {
              (userType === 'customerUser' || userType === 'advisor') && !originalUserData.onboardingCompleted
                ? (
                  <Button
                    variant={formData.sendInvite ? 'contained' : 'outlined'}
                    color="primary"
                    startIcon={<Send />}
                    onClick={() => updateFormData({ sendInvite: !formData.sendInvite })}
                  >
                    Resend invite
                  </Button>
                  )
                : (
                    null
                  )
            }
            </Grid>
            )
          : null}
      </Grid>
    </>
  )
}

UserForm.defaultProps = {
  disabledFields: [],
  originalUserData: null,
  visibleSections: []
}

UserForm.propTypes = {
  customerDivisions: PropTypes.array.isRequired,
  userType: PropTypes.oneOf(['admin', 'advisor', 'customerUser']).isRequired,
  disabledFields: PropTypes.array,
  formData: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    initialPassword: PropTypes.string,
    customerId: PropTypes.string,
    location: PropTypes.string,
    jobTitle: PropTypes.string,
    phoneNumber: PropTypes.string,
    roleType: PropTypes.string,
    divisions: PropTypes.array,
    isActive: PropTypes.bool,
    sendInvite: PropTypes.bool
  }).isRequired,
  originalUserData: PropTypes.object,
  updateFormData: PropTypes.func.isRequired,
  visibleSections: PropTypes.array
}

export default UserForm
