import API from '../_resources'
import * as ACTION from './actions'

const EnumAPI = new API('/enums/', {
  get: {
    method: 'GET',
    cache: true,
    params: { type: 'type', object_type: 'objectType' },
    onSuccess: ({ data }, dispatch) => {
      dispatch(ACTION.storeEnums(data))
    }
  }
})

export default EnumAPI
