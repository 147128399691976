import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Chip,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'

import {
  useJsonAPISelectOne
} from 'hooks'
import {
  ArrowLeft,
  Pencil,
  Plus
} from 'icons'
import {
  openDialog
} from 'utils-em'

import TopicsDialog from './TopicsDialog'
import { KnowledgePageBuilderContext } from '../KnowledgePageBuilderContext'

const SectionEntry = ({ sectionId, onEdit, isLastSection, topicCountsInKnowledgePage }) => {
  const section = useJsonAPISelectOne('knowledgePageSections', sectionId)
  const iconButtonSx = { '&:hover': { color: 'primary.main' } }

  const {
    saveSection
  } = React.useContext(KnowledgePageBuilderContext)

  const setNewOrder = (offset) => {
    saveSection({
      ...section,
      order: parseInt(section.order, 10) + offset
    })
  }

  // `section` can be null immediately after a deletion
  if (!section) return null

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover': { bgcolor: 'primary.lightest' } }}>
        <Typography variant="bodyBold" sx={{ ml: 8 }}>{section.name}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Move up">
            <span>
              <IconButton
                disabled={parseInt(section.order, 10) === 1}
                onClick={() => setNewOrder(-1)}
                sx={iconButtonSx}
              >
                <ArrowLeft sx={{ transform: 'rotate(90deg)' }} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Move down">
            <span>
              <IconButton
                disabled={isLastSection}
                onClick={() => setNewOrder(+1)}
                sx={iconButtonSx}
              >
                <ArrowLeft sx={{ transform: 'rotate(-90deg)' }} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => onEdit()} sx={iconButtonSx}>
              <Pencil />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Stack direction="row" spacing={1} sx={{ ml: 8, my: 1.5 }} flexWrap="wrap" useFlexGap>
        {section?.topics.length ? section.topics.map((topic) => {
          const topicInAnotherSection = topicCountsInKnowledgePage[topic.id] > 1
          return (
            <Chip
              key={topic.id}
              label={(
                <Typography
                  variant="body1"
                  color={topicInAnotherSection ? 'warning.dark' : 'neutral.black'}
                >
                  {topic.name}
                </Typography>
              )}
              variant="filled"
              sx={{ bgcolor: topicInAnotherSection ? 'warning.lightest' : 'neutral.offWhite' }}
            />
          )
        }) : null}
        <Link
          onClick={() => openDialog(
            <TopicsDialog
              sectionId={section.id}
              onSave={(updatedSection) => saveSection(updatedSection)}
            />
          )}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Plus sx={{ mr: 1 }} />
          Add
        </Link>
      </Stack>
    </Box>
  )
}

SectionEntry.propTypes = {
  sectionId: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  isLastSection: PropTypes.bool.isRequired,
  topicCountsInKnowledgePage: PropTypes.object.isRequired
}

export default SectionEntry
