import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Link,
  Typography,
} from '@mui/material'
import {
  ChevronDown,
  ChevronUp,
  Quotation
} from 'icons'
import {
  formatDate
} from 'utils-em'
import {
  AccountPageContext
} from '../AccountPageContext'

const DirectToPage = ({ topicKey, numberDefaultQuotesShown }) => {
  const { responses } = React.useContext(AccountPageContext)
  const [showAll, setShowAll] = useState(false)
  const displayedResponses = responses
    .filter((response) => response.question?.topics[0]?.key === topicKey)
    .filter((response) => response.answerType === 'text')
    .sort((a, b) => a.directToPageSort - b.directToPageSort)
    .slice(0, 10)

  const handleShowAllClick = () => {
    setShowAll(!showAll)
  }
  if (displayedResponses.length === 0) return
  return (
    <Box sx={{ backgroundColor: 'brand.lightNavy', borderRadius: 1, p: 1.5 }}>
      {displayedResponses.slice(0, showAll ? displayedResponses.length : numberDefaultQuotesShown).map((response, index) => (
        <Box sx={{ display: 'flex', my: 2 }}>
          <Quotation sx={{ ml: 1.5, color: 'neutral.darkGrey' }} />
          <Box sx={{ ml: 1, flexDirection: 'column' }}>
            <Typography variant="body">{`“${response.editedAnswerText || response.textAnswer}”`}</Typography>
            <Typography
              sx={{ display: 'block', color: 'neutral.darkGrey' }}
              variant="tiny"
            >
              {formatDate(response?.surveyAssignmentCompletedDate || response.dateUpdated, { includeYear: true })}
            </Typography>
          </Box>
        </Box>
      ))}
      {displayedResponses.length > numberDefaultQuotesShown && (
        <Box sx={{ pb: 1.5, pt: 1 }}>
          <Link sx={{ px: 2 }} onClick={handleShowAllClick} variant="caption">
            {showAll ? 'Collapse' : `Show ${displayedResponses.length - numberDefaultQuotesShown} more advisor quotes`}
            {showAll ? <ChevronUp sx={{ ml: 1.5, mb: -0.75 }} /> : <ChevronDown sx={{ ml: 1.5, mb: -0.75 }} />}
          </Link>
        </Box>
      )}
    </Box>
  )
}

DirectToPage.defaultProps = {
  numberDefaultQuotesShown: 1
}

DirectToPage.propTypes = {
  topicKey: PropTypes.string.isRequired,
  numberDefaultQuotesShown: PropTypes.number
}

export default DirectToPage
