import React from 'react'
import { Box, Typography } from '@mui/material'

import AccountPageTab from './components/AccountPageTab'

const AccountPageManagement = () => (
  <Box sx={{ width: '100%' }}>
    <Typography variant="h2">
      Account Page Management
    </Typography>
    <AccountPageTab />
  </Box>
)

export default AccountPageManagement
