/* eslint-disable no-param-reassign */
import { get } from 'lodash'

export function getDivisionDescendants (division) {
  const recurseDivisionChildren = (d, listOfDescendants) => {
    if (d === null) return
    listOfDescendants.push(d)
    const children = get(d, 'children', [])
    children.forEach((c) => recurseDivisionChildren(c, listOfDescendants))
  }

  const divisionDescendants = []
  recurseDivisionChildren(division, divisionDescendants)
  return divisionDescendants
}

export function getDivisionManagers (division, includePOs = false) {
  const managers = []
  division.customerUsers && division.customerUsers.forEach((customerUser) => {
    if (
      customerUser.roleType === 'manager' ||
      (includePOs && customerUser.roleType === 'program_owner')
    ) managers.push(customerUser)
  })
  return managers
}

export function createDivisionHierarchy (divisions, options = {}) {
  // hierarchically gets divisions (in tree or flattened format)
  // if excluding archived, this skips past children of archived parents
  const { includeArchivedTeams } = { includeArchivedTeams: false, ...options }
  /*
   * Calculate root-level teams
   * A root-level division is one that doesn't have a parent we can see in the
   * divisions we loaded from the API. Note this is slightly different than checking
   * if the division has no parent, since the current user might permissioned to
   * only see a sub-tree of the customer's divisions.
   */
  const topLevelDivisions = []
  const flattenedDivisions = []
  const flattenedDivisionIds = []
  const teamIds = {
    // id: [division]
  }
  // eslint-disable-next-line no-return-assign
  divisions.forEach((division) => teamIds[division.id] = division)
  Object.values(teamIds).forEach((division) => {
    if (!includeArchivedTeams && division.isArchived) return // skip
    division.id = parseInt(division.id, 10) // fix annoying case where `id` is a string, but `parentId` is an integer
    const parentDivision = teamIds[division.parentId]
    if (!parentDivision) {
      if (includeArchivedTeams || !division.isArchived) {
        topLevelDivisions.push(division)
        flattenedDivisions.push(division)
        flattenedDivisionIds.push(division.id)
      }
    } else {
      if (!parentDivision.children) parentDivision.children = []
      division.isArchived = parentDivision.isArchived
      if (includeArchivedTeams || !division.isArchived) {
        parentDivision.children.push(division)
        flattenedDivisions.push(division)
        flattenedDivisionIds.push(division.id)
      }
    }
  })
  return { topLevelDivisions, flattenedDivisions, flattenedDivisionIds }
}
