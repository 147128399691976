import {
  simpleHttpFetch,
} from 'utils-em'

export const SURVEY_ASSIGNMENT_STATUS_MAP = {
  admin_disqualified: '❌ Admin Disqualified',
  approved: '✅ Approved',
  assigned: '🔖 Assigned',
  completed: '✔️ Completed',
  expired: '⌛ Expired',
  started: '🚀 Started',
  user_disqualified: '❌ User Disqualified'
}

export const SURVEY_ASSIGNMENT_RESPONSE_METHOD_MAP = {
  in_app_v1: 'In-app (v1)',
  in_app: 'In-app',
  recruiter_onboarding: 'Recruiter onboarding',
  email: 'Email',
  dev_import: 'Dev import' // dev use only
}

export const validateSurveyIsAssignable = async (surveyId, advisorEmails, isInseat, embedInEmail = false) => {
  const res = await simpleHttpFetch(
    `${__API_HOST__}/v1/survey_assignments/validate`,
    {
      method: 'POST',
      body: JSON.stringify({ surveyId, emails: advisorEmails, isInseat, embedInEmail })
    }
  )
  const validationData = await res.json()
  return validationData
}

export const getActionKeysByAssignmentStatus = (assignment) => {
  let keys = []
  switch (assignment.status) {
    case 'admin_disqualified':
      keys = ['view', 'approve']
      break
    case 'user_disqualified':
      keys = ['view']
      break
    case 'approved':
      keys = ['view', 'disqualify']
      if (assignment.surveyPayment) keys.push('pay')
      break
    case 'completed':
      keys = ['view', 'approve', 'disqualify']
      break
    case 'expired':
      keys = []
      break
    case 'started':
      keys = []
      break
    case 'assigned':
      keys = []
      break
    default:
      keys = []
  }

  if (assignment.archivedDate) {
    keys.push('unarchive')
  } else {
    keys.push('archive')
  }

  return keys
}

// TODO delete this with the old account page
export const SURVEY_QUESTIONS = {
  'account-profile': [
    { id: 'techBudget', text: 'How would you describe your former organization\'s budget for technology investments?', type: 'scale', low: 'Significantly shrinking', high: 'Significantly Growing', canSkip: true, max: 7, order: 1, page: 1 },
    { id: 'techBudgetGrowth', text: 'In two or three sentences, please describe why you think the budget is growing or shrinking.', type: 'text', order: 2, page: 1 },
    { id: 'techChallenges', text: 'What challenges has your former organization experienced when trying to implement technology solutions?', type: 'text', order: 3, page: 2 },
    { id: 'riskTolerance', text: 'How would you describe the risk tolerance at your former organization when it comes to making technology investments?', type: 'scale', low: 'Extremely risk-averse', high: 'Extremely innovative', canSkip: true, max: 7, order: 4, page: 3 },
    { id: 'riskToleranceElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 5, page: 3 },
    { id: 'makeOrBuy', text: 'How would you describe your former organization\'s make vs. buy preference when it comes to technology solutions?', type: 'scale', low: 'Strong make in-house', high: 'Strong buy external', canSkip: true, max: 7, order: 6, page: 4 },
    { id: 'makeOrBuyElaborate', text: 'What (if any) kinds of solutions would they prefer to build in house versus purchase externally?', type: 'text', order: 7, page: 4 },
    { id: 'digitalTransformation', text: 'How mature is your former organization\'s approach to digital transformation?', type: 'scale', low: 'Minimal progress', high: 'Highly mature', canSkip: true, max: 7, order: 8, page: 5 },
    { id: 'digitalTransformationExample', text: 'Please provide two to three examples of current digital transformation initiatives being pursued.', type: 'text', order: 9, page: 5 },
    { id: 'techStrategy', text: 'How is the technology strategy developed and managed, siloed in the businesses or via central corporate IT?', type: 'scale', low: 'Siloed in business units', high: 'Completely centralized', canSkip: true, max: 7, order: 10, page: 6 },
    { id: 'techStrategyElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 11, page: 6 },
    { id: 'cloudApproach', text: 'How would you describe your former organization\'s approach to cloud migration?', type: 'scale', low: 'On-premise first', high: 'Cloud-first', canSkip: true, max: 7, order: 12, page: 7 },
    { id: 'cloudMigration', text: 'In two or three sentences, please clarify what drives decisions on cloud migration?', type: 'text', order: 13, page: 7 },
    { id: 'openSource', text: 'What is your former organization\'s perspective on open-source solutions?', type: 'scale', low: 'Not supportive', high: 'Prefer open-source', canSkip: true, max: 7, order: 14, page: 8 },
    { id: 'openSourceElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 15, page: 8 },
    { id: 'techApproach', text: 'Does your former organization prefer broad/all-encompassing technology or lean towards assembling best-of-breed/point solutions?', canSkip: true, type: 'scale', low: 'Favor broad solutions', high: 'Favor best-of-breed', max: 7, order: 16, page: 9 },
    { id: 'techApproachElaborate', text: 'Why is that?', type: 'text', order: 17, page: 9 },
    { id: 'cyberResiliency', text: 'How mature is your former organization\'s approach to cyber resiliency?', type: 'scale', low: 'Not at all mature', high: 'Very mature', canSkip: true, max: 7, order: 18, page: 10 },
    { id: 'cyberResiliencyElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 19, page: 10 },
    { id: 'budgetProcess', text: 'How flexible is the budgeting process when it comes to technology purchases (e.g. ability to fund projects out of cycle)?', type: 'scale', canSkip: true, low: 'Extremely rigid', high: 'Highly flexible', max: 7, order: 20, page: 11 },
    { id: 'budgetProcessElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 19, page: 11 },
    { id: 'influence', text: 'How much influence does procurement have in technology purchases?', type: 'scale', low: 'Minimal influence', high: 'Powerful influence', canSkip: true, max: 7, order: 21, page: 12 },
    { id: 'advice', text: 'What is the best tip you could give someone who is trying to sell a technology solution, specifically into your former organization?', type: 'text', order: 22, page: 13 }
  ],
  'account-interview': [
    {
      id: 'painPoints',
      text: 'What are 2-3 examples of organizational pain points that are driving technology investments at this time. Use the example response as a model.',
      examples: [`The organization is spending heavily on technologies to improve CX including customer-facing applications and back-end data work.
      They feel like they have fallen behind in relation to their competitors`, `They are also in the process of simplifying the tech stack after two high profile acquisitions.
      They are still merging everything together and the architecture is too complicated.`, 'They are in the process of retrofitting factories for smart manufacturing and are investing heavily in IoT etc.'],
      type: 'longText',
      order: 1,
      page: 1
    },
    {
      id: 'techPurchaseSteps',
      text: 'Please describe all of the steps in a typical technology purchase from problem clarification to final vendor selection. Use the example response as a model.',
      examples: [
        'Technology needs can arise from the business or IT.', `Once corporate IT signs off that there is a need to explore, a buying committee is formed and vendor exploration starts.
      The buying committee consists of business, IT, procurement and security`, `Any purchase over $250k and any purchase that impacts multiple teams or regions requires an RFP.
      Only vendors on the preferred vendor list are invited to respond to an RFP.  Getting on the preferred list takes several months and should be done before the buying process starts.`,
        'Proposals will go through an initial evaluation by a cross-functional team of stakeholders.', 'This is followed by a review by the Enterprise Architecture Review Board and then a Security review to meet corporate standards.',
        'One, or more, solutions are chosen to POC/pilot.', 'After a successful pilot, the committee can approve a limited release, if budget has been allocated.',
        'Procurement manages all contracting.'
      ],
      numberedList: true,
      type: 'longText',
      order: 2,
      page: 2
    },
    {
      id: 'processLength',
      text: 'How long does this process take?',
      examples: ['It usually takes 9-12 months to get to the pilot stage.'],
      type: 'longText',
      order: 3,
      page: 3
    },
    {
      id: 'projOrigination',
      text: 'How do technology projects typically originate - in the business or within corporate IT?  Please describe how collaboration occurs between the business and IT.',
      examples: [`Tech projects can be started at corporate or in a large business unit.
      IT strategy is pushed top-down by a centralized, corporate IT group. The largest business units have their own, large IT teams and can launch their own initiatives (but generally follow corporate strategy).
      Smaller business units do not have autonomy to select technologies.`],
      type: 'longText',
      order: 4,
      page: 4
    },
    {
      id: 'budgetingProcess',
      text: 'How does the budgeting process work? Please describe the process and timeframes for when a vendor should plan on engaging the organization. Use the example response as a model.',
      examples: [`The fiscal year is Feb 1- Jan 31. Budgeting begins in Fall and takes three months.
      Year-end sales significantly influences budget and will drive major cuts. The CEO has a contingency budget to selectively fund out-of-cycle projects and each department head will have some
      flexibility for small adjustments and re-allocations.`],
      type: 'longText',
      order: 5,
      page: 5
    },
    {
      id: 'spendingAuthority',
      text: 'Please describe the roles and spending authority for the different levels of the organization (CIO, SVP, VP....). Use the example response as a model.',
      examples: [`Some Directors in larger business units can approve pilots and projects up to $50-$100k.
      VPs usually authorize projects up to $500k. SVP and BU heads can sign off on $1M spends.
      Above $1M, the CIO and the management committee has to sign off. And anything 'strategic' or multiple millions is going to go to the Board.`],
      type: 'longText',
      order: 6,
      page: 6
    },
    {
      id: 'procurementInvolvement',
      text: 'What triggers procurement involvement in a tech purchase? Please describe the criteria including level of spend, scope of solution etc. Use the example response as a model. ',
      examples: [`Procurement will be involved in all decisions where spend is over $250k, where the resulting solution touches more than one geography,
      or when there are multiple potential solutions in the preferred vendor list.`],
      type: 'longText',
      order: 7,
      page: 7
    },
    {
      id: 'procurementRole',
      text: `What role does procurement play in the purchasing process? E.g. vendor identification, RFP issuance, short-listing, negotiation.
      Be sure to highlight anything that a vendor should be aware of, or cautious of. Use the example response as a model.`,
      examples: [`Procurement does not make the final decision. They do have significant influence and get involved as soon as IT decides they want to source solution.
      Procurement manages the preferred vendor list, facilitates the buying process and drives all negotiations. Not including procurement early will stall a buying process.`],
      type: 'longText',
      order: 8,
      page: 8
    },
    {
      id: 'sellerAdvice',
      text: 'What advice do you have for tech vendors for selling into the account? E.g. messaging / topics that resonate, best person/team to start with, specific pitch advice specific.',
      examples: [`Technical skills in the organization are really high. They prefer to build everything in-house. So go in prepared to show how you can partner with internal resources.
      Show you can get them where they are going faster and/or cheaper.  Make sure to impress tech experts, bring your best data scientists etc.`],
      type: 'longText',
      order: 9,
      page: 9
    }
  ],
  'in-seat-survey': [
    { id: 'techBudget', text: 'How would you describe your organization\'s budget for technology investments? (growing or shrinking). This helps vendors tailor messaging and pitches to your realities versus using generic material.', type: 'scale', low: 'Shrinking', high: 'Growing', canSkip: true, max: 7, order: 1, page: 1 },
    { id: 'techBudgetGrowth', text: 'In two or three sentences, please describe why you think the budget is growing or shrinking.', type: 'text', order: 2, page: 1 },
    { id: 'techChallenges', text: 'What are the technology pain points that a salesperson should be prepared to help your organization solve?', type: 'text', order: 3, page: 2 },
    { id: 'riskTolerance', text: 'How would you describe your organization\'s risk tolerance for making technology investments? This information helps vendors ensure their messaging and positioning is on target.', type: 'scale', low: 'Extremely risk-averse', high: 'Extremely innovative', canSkip: true, max: 7, order: 4, page: 3 },
    { id: 'riskToleranceElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 5, page: 3 },
    { id: 'makeOrBuy', text: 'Vendors can make better use of buyers\' time if they understand the potential for internal competition or partnerships. How would you describe your organization\'s make vs. buy preference for technology solutions?', type: 'scale', low: 'Strong make in-house', high: 'Strong buy external', canSkip: true, max: 7, order: 6, page: 4 },
    { id: 'makeOrBuyElaborate', text: 'What (if any) kinds of solutions would they prefer to build in house versus purchase externally?', type: 'text', order: 7, page: 4 },
    { id: 'digitalTransformation', text: 'Vendors can come to meetings better prepared when they understand your tech priorities. How would you describe your organization\'s approach to digital transformation?', type: 'scale', low: 'Minimal progress', high: 'Highly mature', canSkip: true, max: 7, order: 8, page: 5 },
    { id: 'digitalTransformationExample', text: 'Please provide two to three examples of current digital transformation initiatives being pursued.', type: 'text', order: 9, page: 5 },
    { id: 'techStrategy', text: 'Vendors need to understand where decisions get made so they don\'t waste buyer time with off-target outreach. In your organization, how is the technology strategy developed and managed, via central corporate IT or in the businesses?', type: 'scale', low: 'Siloed in business units', high: 'Completely centralized', canSkip: true, max: 7, order: 10, page: 6 },
    { id: 'techStrategyElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 11, page: 6 },
    { id: 'cloudApproach', text: 'How would you describe your organization\'s approach to cloud migration? This information helps vendors come to meetings with better aligned solutions.', type: 'scale', low: 'On-premise first', high: 'Cloud-first', canSkip: true, max: 7, order: 12, page: 7 },
    { id: 'cloudMigration', text: 'In two or three sentences, please clarify what drives decisions on cloud migration?', type: 'text', order: 13, page: 7 },
    { id: 'openSource', text: 'Many vendors have open-source foundations. How would you describe your organization\'s perspective on open-source solutions?', type: 'scale', low: 'Not supportive', high: 'Prefer open-source', canSkip: true, max: 7, order: 14, page: 8 },
    { id: 'openSourceElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 15, page: 8 },
    { id: 'techApproach', text: 'Does your organization prefer broad/all-encompassing technology or lean toward assembling narrow/point solutions? Vendors use this insight to configure solutions that are a better fit for your architecture.', canSkip: true, type: 'scale', low: 'Favor broad solutions', high: 'Favor best-of-breed', max: 7, order: 16, page: 9 },
    { id: 'techApproachElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 17, page: 9 },
    { id: 'cyberResiliency', text: 'How mature is your approach to cyber resiliency? Vendors know security is important.  This insight helps them determine how to highlight it in presentations.', type: 'scale', low: 'Not at all mature', high: 'Very mature', canSkip: true, max: 7, order: 18, page: 10 },
    { id: 'cyberResiliencyElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 19, page: 10 },
    { id: 'budgetProcess', text: 'Help vendors save your organization time by aligning their engagement to your planning cycles. In your estimation, how flexible is your organization’s budgeting process when it comes to technology purchases (e.g. ability to fund projects out of cycle)?', type: 'scale', canSkip: true, low: 'Extremely rigid', high: 'Highly flexible', max: 7, order: 20, page: 11 },
    { id: 'budgetProcessElaborate', text: 'Please elaborate on your rating in 2-3 complete sentences.', type: 'text', order: 19, page: 11 },
    { id: 'influence', text: 'Vendors can help buyers navigate internal procurement processes in order to make the overall buying process more smooth. In your organization, how much influence does procurement have in technology purchases?', type: 'scale', low: 'Minimal influence', high: 'Powerful influence', canSkip: true, max: 7, order: 21, page: 12 },
    { id: 'advice', text: 'What is the best tip you could give someone who is trying to sell a technology solution into your organization? What makes it different from other organizations that they may have experience with?', type: 'text', order: 22, page: 13 }
  ],
}

export default SURVEY_ASSIGNMENT_STATUS_MAP
