import {
  getAdvisorRelevantCompanies
} from 'utils-em'

export async function getRelevantContractId (customerUserId, organizationId) {
  const res = await fetch(
    `${__API_HOST__}/v1/contracts/relevant?userId=${customerUserId}${organizationId ? `&orgId=${organizationId}` : ''}`,
    { credentials: 'include', method: 'GET' }
  )
  if (res.status === 404) return null
  const body = await res.json()
  const contractId = parseInt(body.data.id, 10)
  return contractId
}

export async function getCompaniesToContracts (customerUserId, advisor) {
  const companies = getAdvisorRelevantCompanies(advisor)
  const orgIds = companies.filter((c) => c.organizationId).map((c) => c.organizationId)

  const url = new URL(`${__API_HOST__}/v1/contracts/org-to-contract`)
  url.searchParams.append('userId', customerUserId)
  orgIds?.length > 0 && url.searchParams.append('orgIds', `[${orgIds.join(',')}]`)

  const res = await fetch(url, { credentials: 'include', method: 'GET' })
  const orgToContract = await res.json()
  return companies.map((c) => ({ companyId: c.id, contractId: orgToContract[c.organizationId] }))
}
