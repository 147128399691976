import PropTypes from 'prop-types'
import React from 'react'

import { ValidatorForm } from 'react-material-ui-form-validator'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material'

import EditCustomerUser from '../EditCustomerUser'

const EditCustomerUserDialog = ({ initCustomerUser, handleClose, saveCustomerUser, showSuccessMessage, showErrorMessage }) => {
  const [customerUser, setCustomerUser] = React.useState(initCustomerUser)

  const handleSubmit = async () => {
    try {
      const saveRes = await saveCustomerUser(customerUser)
      if (saveRes.error) throw new Error(saveRes.error.message)
      showSuccessMessage({ text: 'Customer User Saved', timeout: 4000 })
      handleClose()
    } catch (error) {
      showErrorMessage({ text: `Save customer user failed: ${error.message}`, timeout: 4000 })
    }
  }

  const setCustomerUserField = (fieldName, value) => (
    setCustomerUser({

      ...customerUser,
      [fieldName]: value
    })
  )

  const updateCustomerUser = (fieldName) => (event) => {
    const { value } = event.target
    setCustomerUserField(fieldName, value)
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <EditCustomerUser
            customerUser={customerUser}
            updateCustomerUser={updateCustomerUser}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditCustomerUserDialog.propTypes = {
  initCustomerUser: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveCustomerUser: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default EditCustomerUserDialog
