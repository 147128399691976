import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Checkbox
} from '@mui/material'
import { Filter } from 'icons'
import { TextValidator } from 'react-material-ui-form-validator'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBox from '@mui/icons-material/CheckBox'

const filter = createFilterOptions()

const AddTagDialog = ({ initialValue, handleClose, handleAddTag }) => {
  const [tagName, setTagName] = React.useState(initialValue)

  return (
    <Dialog
      open
      maxWidth="sm"
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add a New Tag</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={tagName}
          onChange={(event) => setTagName(event.target.value)}
          label="Name"
          type="text"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={() => handleAddTag(tagName)} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddTagDialog.propTypes = {
  initialValue: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAddTag: PropTypes.func.isRequired
}

const TagPicker = ({
  tags,
  type,
  selectedTags,
  customerId,
  label,
  helperText,
  margin,
  onlyQueryable,
  hasAllOption,
  getTags,
  saveTag,
  addTag,
  removeTag,
  validators,
  errorMessages,
  openDialog,
  handleClose,
  showSuccessMessage,
  showErrorMessage,
  showIcon,
  variant,
  onlyUsed,
  enableAddNew,
  placeholder,
  multiple,
  styling
}) => {
  useEffect(() => {
    getTags(type, customerId, onlyQueryable, onlyUsed)
  }, []) // componentDidMount equivalent

  const changeTags = (newTags) => {
    if (multiple) {
      const newestTag = newTags[newTags.length - 1]
      if (newestTag && newestTag.inputValue) {
        openDialog(
          <AddTagDialog
            initialValue={newestTag.inputValue}
            handleClose={handleClose}
            handleAddTag={(tagName) => saveTag({ name: tagName, category: type }).then((res) => {
              if (!res.ok) {
                showErrorMessage('An error occurred while trying to save the tag')
              } else {
                addTag({
                  id: res.data.data.id,
                  name: res.data.data.attributes.name,
                  category: res.data.data.attributes.category,
                  type: 'tags'
                })
                handleClose()
                showSuccessMessage({ text: `Created tag "${tagName}"` })
              }
            })}
          />
        )
      } else if (selectedTags.length < newTags.length) {
        if (newestTag.id === 'all') {
          tags
            .filter((t) => t.category === type)
            .forEach((tagToAdd) => {
              if (!selectedTags.find((selectedTag) => selectedTag.id === tagToAdd.id)) {
                addTag(tagToAdd)
              }
            })
        } else {
          const tagToAdd = newTags[newTags.length - 1]
          if (!selectedTags.find((selectedTag) => selectedTag.id === tagToAdd.id)) {
            addTag(tagToAdd)
          }
        }
      } else {
        const removedTag = selectedTags.find((tag) => !newTags.includes(tag))
        removeTag(removedTag)
      }
    } else {
      addTag(newTags)
    }
  }

  const textField = (params) => (validators.length ? (
    <TextValidator
      {...params}
      variant={variant}
      label={label}
      helperText={helperText}
      placeholder={placeholder}
      margin={margin}
      validators={validators}
      errorMessages={errorMessages}
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
    />
  ) : (
    <TextField
      {...params}
      variant={variant}
      label={label}
      helperText={helperText}
      placeholder={placeholder}
      margin={margin}
      fullWidth
      sx={styling}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            {showIcon ? (
              <InputAdornment position="start" sx={{ mr: 0 }}>
                <Filter />
              </InputAdornment>
            ) : null}
            {params.InputProps.startAdornment}
          </>
        )
      }}
    />
  ))

  const sortedTags = sortBy(tags.filter((tag) => tag.category === type), (t) => t.name)
  const otherIndex = sortedTags.findIndex((t) => t.name === 'Other')
  if (otherIndex >= 0) sortedTags.push(sortedTags.splice(otherIndex, 1)[0])
  if (hasAllOption) sortedTags.unshift({ name: 'All', id: 'all' })

  return (
    <Autocomplete
      multiple={multiple}
      disableCloseOnSelect={multiple}
      onChange={(event, newTags) => {
        changeTags(newTags)
      }}
      options={sortedTags}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedTags}
      renderInput={textField}
      freeSolo={enableAddNew}
      forcePopupIcon={variant !== 'outlined'}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        if (params.inputValue !== '' && enableAddNew) {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`
          })
        }

        return filtered
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            checked={selected}
          />
          {option.name}
        </li>
      )}
    />
  )
}

TagPicker.defaultProps = {
  customerId: null,
  label: 'Tags',
  placeholder: 'Tags',
  helperText: null,
  hasAllOption: false,
  validators: [],
  errorMessages: [],
  onlyQueryable: false,
  margin: 'normal',
  variant: 'standard',
  onlyUsed: false,
  enableAddNew: false,
  multiple: true,
  showIcon: false,
  styling: {}
}

TagPicker.propTypes = {
  tags: PropTypes.array.isRequired,
  customerId: PropTypes.number,
  type: PropTypes.string.isRequired,
  selectedTags: PropTypes.array.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  onlyQueryable: PropTypes.bool,
  hasAllOption: PropTypes.bool,
  getTags: PropTypes.func.isRequired,
  saveTag: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  validators: PropTypes.array.isRequired,
  errorMessages: PropTypes.array.isRequired,
  openDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  showIcon: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  onlyUsed: PropTypes.bool,
  enableAddNew: PropTypes.bool,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  styling: PropTypes.object
}

export default TagPicker
