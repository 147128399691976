import PropTypes from 'prop-types'
import React from 'react'
import {
  StatusCircle
} from 'components'

const CustomerUserTooltips = ({ customerUser, showInactiveWarning }) => {
  const { status } = customerUser
  return (
    <StatusCircle
      status={status}
      component="span"
      sx={{ ml: '4px' }}
    />
  )
}

CustomerUserTooltips.defaultProps = {
  showInactiveWarning: true
}

CustomerUserTooltips.propTypes = {
  customerUser: PropTypes.object.isRequired,
  showInactiveWarning: PropTypes.bool
}

export default CustomerUserTooltips
