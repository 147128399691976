import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography
} from '@mui/material'
import {
  useEngagement,
} from 'hooks'

import FeedbackRequiredBox from './components/FeedbackRequiredBox'
import UpcomingCallBox from './components/UpcomingCallBox'

const CallActionsSection = ({ engagementId }) => {
  const { isCallUpcoming, isFeedbackRequired, isCallToday } = useEngagement(engagementId)
  if (isCallUpcoming) {
    return (
      <>
        <Typography variant="h3">
          {isCallToday ? 'Your call is happening soon.' : 'Your call has been scheduled.'}
        </Typography>
        <UpcomingCallBox engagementId={engagementId} />
      </>
    )
  }
  if (isFeedbackRequired) return <FeedbackRequiredBox engagementId={engagementId} />
  return null
}

CallActionsSection.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default CallActionsSection
