import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import {
  ConfirmDialog
} from 'components'
import {
  buildJsonApiOrdered,
  ACTION_CONSTANTS,
  formatDateTime,
  openDialog,
  recordUserAction,
  getAdvisorMostRecentRelevantCompany
} from 'utils-em'
import { JsonAPI } from 'store'
import { orderBy } from 'lodash'

const styles = {
  answer: {
    marginLeft: '2em',
    overflowWrap: 'break-word'
  },
  title: {
    fontWeight: 'bold'
  }
}

const mostRecentArrayTime = (array, timeKey) => {
  if (!array.length) { return '' }
  return orderBy(array, [timeKey], ['desc'])[0][timeKey]
}

const AdvisorActivityCard = ({
  advisor,
  adminId
}) => {
  const dispatch = useDispatch()
  const advisorEngagements = useSelector(({ data }) => buildJsonApiOrdered(data, 'engagements'))
  const mostRelevantCompany = getAdvisorMostRecentRelevantCompany(advisor)
  const advisorSurveys = useSelector(({ data }) => buildJsonApiOrdered(data, 'surveyAssignments'))

  const loadAdvisorEngagements = () => {
    dispatch(JsonAPI.getAll({
      type: 'engagements',
      queryStringParams: {
        'filter[advisorId]': advisor.id,
        include: [
          'engagementRejectReasons'
        ].join(',')
      }
    }))
  }

  const getStatus = (survey) => {
    if (survey.approvedDate !== null) {
      return `Approved: ${formatDateTime(survey.approvedDate)}`
    } if (survey.archivedDate !== null) {
      return `Archived: ${formatDateTime(survey.archivedDate)}`
    } if (survey.completedDate !== null) {
      return `Completed: ${formatDateTime(survey.completedDate)}`
    } if (survey.status === 'started') {
      return `Started: ${formatDateTime(survey.startedDate)}`
    } if (!survey.isTakeable) {
      return 'Expired/Unavailable'
    } return 'Not started'
  }

  const loadAdvisorSurveys = () => {
    dispatch(JsonAPI.getAll({
      type: 'surveyAssignments',
      queryStringParams: {
        'filter[userId]': advisor.id
      }
    }))
  }

  const calculateStandardizationStatusText = () => {
    if (mostRelevantCompany?.dateStandardizationVerified) {
      return `Validated on ${formatDateTime(mostRelevantCompany?.dateStandardizationVerified)}`
    }
    if (!mostRelevantCompany?.dateStandardizationSet) {
      return 'Not automatically standardized'
    }
    return 'Awaiting validation'
  }

  React.useEffect(() => {
    loadAdvisorEngagements()
    loadAdvisorSurveys()
  }, [])

  return (
    <Paper sx={{ mt: '0.5em', p: '1em' }}>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ ...styles.title }}>ADMIN ONLY</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ ...styles.title }}>ADMIN ONLY</Typography>
        </Grid>
        <Grid item xs={6}>
          <Divider />
          <Typography display="block" variant="body1" style={{ marginTop: '0.5em' }}>
            ♢ Onboard Status:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {advisor.onboardingStatus}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Onboard Date:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {formatDateTime(advisor.onboardingCompleted) || 'N/A'}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Last Invite Sent:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {formatDateTime(advisor.inviteSent) || 'N/A'}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Last Login:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {formatDateTime(advisor.lastLogin) || 'N/A'}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Total Engagements Responded / Total Engagements Proposed:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {advisorEngagements.filter((e) => !['proposed', 'expired', 'proposal-canceled', 'canceled'].includes(e.state)).length}
            {' '}
            /
            {advisorEngagements.filter((e) => !['proposal-canceled', 'canceled'].includes(e.state)).length}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Total Proposals Expired:
          </Typography>
          <Typography sx={{ ...styles.answer }}>
            {advisorEngagements.filter((e) => e.state === 'expired').length}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Total Proposals Rejected (with reasons):
          </Typography>
          <Typography sx={{ ...styles.answer }}>
            {advisorEngagements.filter((e) => e.state === 'rejected').length}
            &nbsp;(
            {advisorEngagements.filter((e) => e.state === 'rejected').map((e) => e.engagementRejectReasons?.map((rr) => rr.rejectReason.split('.')[1])).join(',')}
            )
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Last Engagement Start Date:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {formatDateTime(mostRecentArrayTime(advisorEngagements, 'startDate')) || 'N/A'}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Backend status:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {advisor.status}
          </Typography>
          {advisor.status === 'idle' ? (
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={() => openDialog(
                <ConfirmDialog
                  description="Are you sure you want to make this advisor active? (refresh after for result)"
                  actions={[
                    {
                      name: 'Confirm',
                      action: () => { recordUserAction(ACTION_CONSTANTS.isNotIdle, advisor, 'advisor', { markedBy: adminId }, advisor.id) }
                    }
                  ]}
                />
              )}
            >
              Mark as Active
            </Button>
          ) : null}
          { !advisor.isActive
            ? (
              <>
                <Typography display="block" variant="body1">
                  ♢ Date Deactivated:
                </Typography>
                <Typography sx={{ ...styles.answer }} variant="subtitle2">
                  {advisor.dateDeactivated ? formatDateTime(advisor.dateDeactivated) : 'Unknown'}
                </Typography>
              </>
              )
            : null }
          <Typography display="block" variant="body1">
            ♢ Standardization Status:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {calculateStandardizationStatusText()}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Divider />
          <Typography display="block" variant="body2" style={{ marginTop: '0.5em' }}>
            CONTRACTUAL DETAILS
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Engagement Rate:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            $
            {advisor.rate}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Single Call Rate:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            $
            {advisor.singleCallRate}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Phone Number:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {advisor.phoneNumber || 'N/A'}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ Email:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {advisor.email}
          </Typography>
          <Typography display="block" variant="body1">
            ♢ LinkedIn:
          </Typography>
          <Typography sx={{ ...styles.answer }} variant="subtitle2">
            {advisor.linkedinUrl || 'N/A'}
          </Typography>
          <Typography display="block" variant="body2" style={{ marginTop: '0.5em' }}>
            SURVEYS
          </Typography>
          {advisorSurveys.map((survey) => (
            <Typography display="block" variant="body2">
              {`♢ ${survey.surveyTitle}`}
              <Typography sx={{ ...styles.answer }} display="block" variant="subtitle2">
                {getStatus(survey)}
              </Typography>
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Paper>
  )
}

AdvisorActivityCard.propTypes = {
  advisor: PropTypes.object.isRequired,
  adminId: PropTypes.number.isRequired
}

export default AdvisorActivityCard
