import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  useEmissaryTheme
} from 'hooks'

export const CalendarTimeSelectorContext = React.createContext({})

export const CalendarTimeSelectorContextProvider = ({ initialSelectedTimes, onTimesSelected, children }) => {
  const {
    isSmOrSmaller
  } = useEmissaryTheme()

  const getInitialDate = () => {
    let determineInitialDate = moment()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)

    // On desktop, always start with the first Monday of the week
    if (!isSmOrSmaller) determineInitialDate = determineInitialDate.day(1)

    return determineInitialDate
  }

  const NUMBER_OF_TIMESLOTS = 48
  const MAX_DAYS_IN_FUTURE_ALLOWED = 29
  const NEXT_PAGE_IN_DAYS = isSmOrSmaller ? 2 : 7
  const initialDate = getInitialDate()

  const [firstDayToDisplay, setFirstDayToDisplay] = React.useState(initialDate)
  const [daysToShow, setDaysToShow] = React.useState([])
  const [selectedTimeslots, setSelectedTimeslots] = React.useState(initialSelectedTimes || [])

  const goToToday = () => setFirstDayToDisplay(initialDate)
  const goToPreviousWeek = () => setFirstDayToDisplay(firstDayToDisplay.subtract(NEXT_PAGE_IN_DAYS, 'days').clone())
  const goToNextWeek = () => setFirstDayToDisplay(firstDayToDisplay.add(NEXT_PAGE_IN_DAYS, 'days').clone())

  React.useEffect(() => {
    onTimesSelected(selectedTimeslots)
  }, [selectedTimeslots])

  React.useEffect(() => {
    if (firstDayToDisplay) {
      setDaysToShow([
        // mobile: only display two days at a time
        firstDayToDisplay.clone().add(0, 'day'),
        firstDayToDisplay.clone().add(1, 'day')
      ])
      if (!isSmOrSmaller) {
        // desktop sees all five weekdays, so add the remaining 3
        setDaysToShow((prevValue) => [
          ...prevValue,
          firstDayToDisplay.clone().add(2, 'day'),
          firstDayToDisplay.clone().add(3, 'day'),
          firstDayToDisplay.clone().add(4, 'day')
        ])
      }
    }
  }, [firstDayToDisplay])

  const isCurrentWeek = firstDayToDisplay.isSame(initialDate)

  const contextValue = React.useMemo(() => ({
    // constants
    NUMBER_OF_TIMESLOTS,
    MAX_DAYS_IN_FUTURE_ALLOWED,

    // methods
    goToToday,
    goToPreviousWeek,
    goToNextWeek,

    // setters
    setFirstDayToDisplay,
    setSelectedTimeslots,

    // values
    isCurrentWeek,
    daysToShow,
    firstDayToDisplay,
    selectedTimeslots,
  }), [isCurrentWeek, daysToShow, firstDayToDisplay, selectedTimeslots])

  return (
    <CalendarTimeSelectorContext.Provider value={contextValue}>
      {children}
    </CalendarTimeSelectorContext.Provider>
  )
}

CalendarTimeSelectorContextProvider.defaultProps = {
  initialSelectedTimes: []
}

CalendarTimeSelectorContextProvider.propTypes = {
  initialSelectedTimes: PropTypes.array,
  onTimesSelected: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}
