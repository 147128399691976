import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  buildJsonApiOrdered,
  deserializeJsonApi,
  getCountJsonApi,
  useSelectorWithFilters
} from 'utils-em'
import { JsonAPI } from 'store'

export default function useJsonAPIGetAll (type, options = {}) {
  const {
    include,
    filters,
    pageSize,
    pageNumber,
    postQueryFilters,
    orderKey,
    orderedType,
    sortBy,
    queryStringParams,
    dependencies
  } = {
    include: [],
    filters: null,
    pageSize: null,
    pageNumber: null,
    postQueryFilters: null,
    orderKey: null,
    orderedType: null,
    sortBy: '',
    dependencies: [],
    queryStringParams: {},
    ...options
  }
  const dispatch = useDispatch()
  const [loaded, setLoaded] = React.useState(false)
  const objects = useSelectorWithFilters(({ data }) => buildJsonApiOrdered(data, type, orderKey || 'displayOrderIds', orderedType || type), postQueryFilters)
  const totalCount = useSelector(({ data }) => getCountJsonApi(data, type, orderKey))
  const [data, setData] = React.useState(null)
  const reload = () => setLoaded(false)
  const skip = options.skip || false
  const builtQueryStringParams = {}
  if (include) { builtQueryStringParams.include = include.join(',') }
  if (filters) { builtQueryStringParams.filter = filters }
  if (pageSize) { builtQueryStringParams['page[size]'] = pageSize }
  if (pageNumber) { builtQueryStringParams['page[number]'] = pageNumber }

  const getAll = async () => {
    setLoaded(false)
    const res = await dispatch(JsonAPI.getAll({
      type,
      ...(orderedType && { metaType: orderedType }),
      queryStringParams: { ...builtQueryStringParams, ...queryStringParams },
      sort: sortBy
    }))
    if (res?.data) setData(deserializeJsonApi(res.data))
    setLoaded(true)
  }

  React.useEffect(() => { (!skip && !loaded) && getAll() }, [...dependencies, loaded, skip])

  return { objects, loaded, reload, totalCount, data }
}
