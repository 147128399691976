import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import { ArrowLeft } from 'icons'
import {
  buildJsonApiOrdered,
  constants,
  customAlert,
  getCountJsonApi,
  useDebounce,
  useSelectorWithFilters
} from 'utils-em'
import {
  JsonAPI
} from 'store'

const JsonApiTable = ({
  columns,
  type,
  queryStringParams,
  orderKey,
  orderedType,
  pageSizeOptions,
  noDataText,
  noSearchResultsText,
  dependencies,
  stateCallback,
  postQueryFilters,
  columnWidth,
  onRowClick,
  isFiltered,
  ...rest
}) => {
  const dispatch = useDispatch()
  const objects = useSelectorWithFilters(({ data }) => buildJsonApiOrdered(data, type, orderKey || 'displayOrderIds', orderedType || type), postQueryFilters)
  const totalCount = useSelector(({ data }) => getCountJsonApi(data, type, orderKey))
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0])
  const [loaded, setLoaded] = React.useState(false)
  const [dependencyChanged, setDependencyChanged] = React.useState(false)
  const [sort, setSort] = React.useState(columns.find((column) => column.defaultSort)?.sortKey || '')
  const [hoveredHeaderCell, setHoveredHeaderCell] = React.useState(null)

  const debouncePage = useDebounce(page)

  React.useEffect(() => { stateCallback && stateCallback({ loaded, totalCount, sortKey: sort }) }, [loaded, totalCount, sort])
  React.useEffect(() => { setDependencyChanged(true) }, [...dependencies]) // resets to page 0 if results are filtered

  React.useEffect(() => {
    let isSubscribed = true
    const abortController = new AbortController()
    loadObjects(dependencyChanged, abortController).then(() => {
      if (!isSubscribed) return
      setLoaded(true)
      setDependencyChanged(false)
    })
    return () => {
      isSubscribed = false
      abortController.abort()
    }
  }, [debouncePage, pageSize, dependencyChanged, queryStringParams, sort])

  const loadObjects = async (resetPage = false, abortController) => {
    try {
      setLoaded(false)
      resetPage && setPage(0)
      const res = await dispatch(JsonAPI.getAll({
        type: orderedType || type,
        queryStringParams: {
          ...queryStringParams,
          'page[size]': pageSize,
          'page[number]': page + 1, // 1 based
        },
        sort: sort || queryStringParams?.sort || '',
        orderKey,
        abortController
      }))
      if (res.error) throw res
    } catch (error) {
      if (!error.aborted) customAlert(`Data cannot be loaded. ${error.message}. Please contact support.`, true)
    }
  }

  const renderHeaderRow = () => (
    <TableRow component="div">
      {columns.map((c) => (
        <TableCell
          key={c.name}
          component="div"
          onMouseEnter={() => c.sortKey && setHoveredHeaderCell(c.sortKey)}
          onMouseLeave={() => c.sortKey && setHoveredHeaderCell(null)}
          onClick={() => {
            if (!c.sortKey) return
            if (!sort?.endsWith(c.sortKey)) setSort(c.sortKey) // first header click
            else if (!sort.startsWith('-')) setSort(`-${c.sortKey}`) // second header click
            else {
              // third header click returns to no sort
              setSort('')
              setHoveredHeaderCell('')
            }
          }}
          sx={{
            cursor: c.sortKey ? 'pointer' : undefined,
            width: columnWidth || c.width || undefined
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {c.header}
            <ArrowLeft
              sx={{
                transform: sort.includes(c.sortKey) ? `rotate(${sort.startsWith('-') ? 270 : 90}deg)` : 'rotate(90deg)',
                color: 'neutral.darkGrey',
                ml: 1,
                visibility: (sort.includes(c.sortKey) || (!sort?.endsWith(c.sortKey) && hoveredHeaderCell === c.sortKey)) ? 'visible' : 'hidden'
              }}
            />
          </Box>
        </TableCell>
      ))}
    </TableRow>
  )

  const renderBodyRow = (object) => (
    <TableRow
      key={object.id}
      component="div"
      onClick={!onRowClick ? undefined : (e) => { e.preventDefault(); onRowClick(object) }}
      hover={Boolean(onRowClick)}
      sx={{ cursor: onRowClick ? 'pointer' : undefined, opacity: loaded ? 1 : 0.5 }}
    >
      {columns.map((c) => (
        <TableCell component="div" key={c.name} sx={(columnWidth || c.width) ? { width: columnWidth || c.width } : null}>
          {c.value(object)}
        </TableCell>
      ))}
    </TableRow>
  )

  const emptyText = noSearchResultsText && (queryStringParams.searchValue || isFiltered)
    ? noSearchResultsText
    : noDataText

  return (
    <Box {...rest}>
      {loaded && totalCount === 0 ? null : (
        <TableContainer>
          <Table component="div">
            <TableHead component="div">{renderHeaderRow()}</TableHead>
            <TableBody component="div">{objects.map((e) => renderBodyRow(e))}</TableBody>
          </Table>
        </TableContainer>
      )}
      {loaded && totalCount === 0 && (
        <Box typography="body1" color="neutral.black" sx={{ m: 2 }}>{emptyText}</Box>
      )}
      {totalCount > pageSizeOptions[0] && (
        <TablePagination
          rowsPerPageOptions={pageSizeOptions}
          component="div"
          count={totalCount}
          rowsPerPage={pageSize}
          page={page} // 0 based
          onPageChange={(_e, newPage) => loaded && setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setPageSize(parseInt(e.target.value, 10))
            setPage(0)
          }}
        />
      )}
    </Box>
  )
}

JsonApiTable.defaultProps = {
  queryStringParams: {},
  orderKey: null,
  orderedType: null, // use if there is a custom key for jsonApiOrdered (ie customerEngagements)
  pageSizeOptions: constants.TWO_OH_ROWS_PER_PAGE_OPTIONS,
  noDataText: null,
  noSearchResultsText: null,
  dependencies: [],
  postQueryFilters: [],
  columnWidth: null,
  isFiltered: false,

  stateCallback: null,
  onRowClick: null
}

JsonApiTable.propTypes = {
  columns: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  queryStringParams: PropTypes.object,
  orderKey: PropTypes.string,
  orderedType: PropTypes.string,
  pageSizeOptions: PropTypes.array,
  noDataText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noSearchResultsText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dependencies: PropTypes.array,
  postQueryFilters: PropTypes.array,
  columnWidth: PropTypes.string,
  isFiltered: PropTypes.bool, // forces noSearchResultsText to be displayed if no results

  stateCallback: PropTypes.func, // Function to run when state of table has changed, including loaded, sorting, etc.
  onRowClick: PropTypes.func, // Function to run when a row is clicked
}
export default JsonApiTable
