import React from 'react'
import PropTypes from 'prop-types'
import { upperFirst } from 'lodash'
import {
  IconButton,
  Link,
  Tooltip
} from '@mui/material'
import { Download } from 'icons'
import { AssetIcon, JsonApiDataGrid } from 'components'
import { customAlert, downloadAllEngagementAssets, downloadAssetFromGcs } from 'utils-em'

const EngagementAssetTable = ({ organizationId }) => {
  const [downloading, setDownloading] = React.useState(false)

  const downloadOne = async (e, engagementId, asset) => {
    if (downloading) return
    setDownloading(true)
    try {
      await downloadAssetFromGcs(asset.id, asset.documentGcsObjectName)
    } catch (error) {
      customAlert('Something went wrong, please contact support', true)
    }
    setDownloading(false)
  }
  const downloadAll = async (e, engagementId) => {
    if (downloading) return
    setDownloading(true)
    try {
      await downloadAllEngagementAssets(e, engagementId)
    } catch (error) {
      customAlert('Something went wrong, please contact support', true)
    }
    setDownloading(false)
  }
  return (
    <JsonApiDataGrid
      disableExport
      type="engagements"
      jsonApiOptions={{
        include: [
          'advisor',
          'assets',
          'customerUser',
          'customerUser.customer',
          'role'
        ],
        filters: [
          { name: 'organizationId', op: 'eq', val: organizationId },
          { name: 'state', op: 'ne', val: 'canceled' },
          { name: 'assets', op: 'any', val: { name: 'engagementId', op: 'ne', val: null } }
        ],
        sortBy: '-startDate',
        dependencies: [organizationId]
      }}
      columns={[
        {
          field: 'startDate',
          headerName: 'Start date',
          valueGetter: ({ row }) => row?.startDate?.substring(0, 10)
        },
        {
          field: 'engagementId',
          headerName: 'Engagement',
          valueGetter: ({ row }) => row?.id,
          renderCell: ({ row }) => (
            <Link
              target="_blank"
              href={`/admin/engagements/${row.id}`}
            >
              {row.id}
            </Link>
          )
        },
        {
          field: 'advisor',
          headerName: 'Advisor',
          flex: 1,
          valueGetter: ({ row }) => `${row.advisor.fullName}, ${row.role.title}`,
          renderCell: ({ row }) => (
            <>
              <Link
                target="_blank"
                href={`/admin/advisors/${row.advisor.id}`}
              >
                {row.advisor.fullName}
              </Link>
              {`, ${row.role.title}`}
            </>
          )
        },
        {
          field: 'customer',
          headerName: 'Customer',
          flex: 1,
          valueGetter: ({ row }) => row?.customerUser?.customer?.name || null
        },
        {
          field: 'assets',
          headerName: 'Engagement assets',
          flex: 1,
          valueGetter: ({ row }) => row?.assets?.length,
          renderCell: ({ row }) => {
            if (!row?.assets?.length > 0) return null
            return (
              <>
                {row?.assets?.map((asset) => (
                  <Tooltip key={asset.id} title={upperFirst(asset.assetType.split('-')[1])}>
                    <IconButton disabled={downloading} onClick={(e) => downloadOne(e, row.id, asset)}>
                      <AssetIcon assetType={asset.assetType} />
                    </IconButton>
                  </Tooltip>
                ))}
                <Tooltip title="Download all">
                  <IconButton disabled={downloading} onClick={(e) => downloadAll(e, row.id)}>
                    <Download />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        }
      ]}
    />
  )
}

EngagementAssetTable.propTypes = {
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default EngagementAssetTable
