import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Chrome (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9974 20.3346C16.5998 20.3346 20.3307 16.6037 20.3307 12.0013C20.3307 7.39893 16.5998 3.66797 11.9974 3.66797C7.39502 3.66797 3.66406 7.39893 3.66406 12.0013C3.66406 16.6037 7.39502 20.3346 11.9974 20.3346Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.9974 15.3346C13.8383 15.3346 15.3307 13.8423 15.3307 12.0013C15.3307 10.1604 13.8383 8.66797 11.9974 8.66797C10.1564 8.66797 8.66406 10.1604 8.66406 12.0013C8.66406 13.8423 10.1564 15.3346 11.9974 15.3346Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.6417 8.66797H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.28906 7.05078L9.11406 13.6674" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.0703 20.2846L14.887 13.668" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
