import { buildJsonApiAll } from 'utils-em'
import { store, JsonAPI } from 'store'

function getRelevantNotificationsByType (type, extraFilter = null) {
  let notifications = buildJsonApiAll(store.getState().data, 'notifications')

  if (extraFilter) {
    notifications = notifications.filter((n) => extraFilter(n))
  }

  return notifications.filter((n) => !n.isCompleted && !n.isDismissed && n.notificationType === type)
}

export function dismissNotificationsOfType (type, extraFilter = null) {
  const notificationsToAction = getRelevantNotificationsByType(type, extraFilter)
  notificationsToAction.forEach((n) => {
    const notificationToSave = {
      ...n,
      isDismissed: true
    }
    store.dispatch(JsonAPI.save(notificationToSave))
  })
}

export function completeNotificationsOfType (type, extraFilter = null) {
  const notificationsToAction = getRelevantNotificationsByType(type, extraFilter)
  notificationsToAction.forEach((n) => {
    const notificationToSave = {
      ...n,
      isCompleted: true
    }
    store.dispatch(JsonAPI.save(notificationToSave))
  })
}

export function getActiveNotifications (session, data, sort = true) {
  const notifications = buildJsonApiAll(data, 'notifications') || []
  const activeNotifications = notifications.filter((n) => (
    !n.isCompleted &&
    !n.isDismissed &&
    parseInt(n.userId, 10) === parseInt(session.id, 10)
  ))
  if (sort) {
    const typesAlwaysAtTop = ['personal-information-missing', 'employment-history-missing', 'payment-information-missing']
    const topNotifications = activeNotifications.filter((n) => typesAlwaysAtTop.includes(n.notificationType))
    const otherNotifications = activeNotifications.filter((n) => !topNotifications.map((tn) => tn.id).includes(n.id))
    return [
      ...topNotifications.sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1)),
      ...otherNotifications.sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1))
    ]
  }
  return activeNotifications
}
