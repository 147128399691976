import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  useTheme
} from '@mui/material'
import { Bookmark, Heart } from 'icons'
import {
  buildJsonApiAll,
  customAlert
} from 'utils-em'
import { useFeatureFlag } from 'hooks'
import { JsonAPI } from 'store'

const SaveAdvisorButton = ({ advisorId, iconOnly }) => {
  const theme = useTheme()
  const userId = useSelector(({ session }) => session.id)
  const savedAdvisors = useSelector(({ data }) => buildJsonApiAll(data, 'bookmarks').filter((bookmark) => bookmark.bookmarkType === 'SAVED_ADVISOR' &&
    bookmark.bookmarkTarget === advisorId &&
    bookmark.ownerId === userId))
  const dispatch = useDispatch()
  const savedListsEnabled = useFeatureFlag('saved_lists')

  const loadSavedAdvisor = () => dispatch(
    JsonAPI.getAll({
      type: 'bookmarks',
      queryStringParams: {
        'filter[bookmarkType]': 'SAVED_ADVISOR',
        'filter[bookmarkTarget]': advisorId,
        'filter[ownerId]': userId
      }
    })
  )

  React.useEffect(() => {
    loadSavedAdvisor()
  }, [])

  const saveAdvisor = () => dispatch(JsonAPI.create({
    type: 'bookmarks',
    ownerId: userId,
    bookmarkType: 'SAVED_ADVISOR',
    bookmarkTarget: advisorId
  }))

  const deleteSavedAdvisor = () => {
    dispatch(JsonAPI.delete({
      type: 'bookmarks',
      id: savedAdvisors[0].id
    }))
  }

  const handleChange = (e) => {
    e.stopPropagation()
    if (savedAdvisors.length === 0) {
      saveAdvisor()
      customAlert('Advisor saved. You can access your saved advisors from your "My Saved Lists" page.')
    } else {
      deleteSavedAdvisor()
      customAlert('Advisor removed from saved.')
    }
  }

  const isBookmarked = savedAdvisors.length > 0
  const labelText = isBookmarked ? 'Saved' : 'Save advisor'
  const iconStyle = {
    color: isBookmarked ? 'primary.main' : 'none',
    '& svg': { fill: savedAdvisors.length ? `${theme.palette.primary.main} !important` : 'none' }
  }
  const bookmarkIcon = savedListsEnabled ? <Heart sx={iconStyle} /> : <Bookmark sx={iconStyle} />

  return (
    <>
      {iconOnly
        ? (
          <Heart
            onClick={(e) => handleChange(e)}
            onMouseDown={(e) => e.stopPropagation()} // disable ripple effect on parent
            sx={{
              color: isBookmarked ? theme.palette.brand.orange : 'brand.lightGrey',
              '&:hover': { fill: theme.palette.brand.orange, color: theme.palette.brand.orange },
              fill: isBookmarked ? theme.palette.brand.orange : 'none'
            }}
          />
          )
        : (
          <Button
            startIcon={bookmarkIcon}
            onClick={(e) => handleChange(e)}
            onMouseDown={(e) => e.stopPropagation()} // disable ripple effect on parent
            sx={{ ml: 2 }}
          >
            {labelText}
          </Button>
          )}
    </>
  )
}

SaveAdvisorButton.defaultProps = {
  iconOnly: false
}

SaveAdvisorButton.propTypes = {
  advisorId: PropTypes.string.isRequired,
  iconOnly: PropTypes.bool
}

export default SaveAdvisorButton
