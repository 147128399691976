import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'

import {
  useJsonAPISelectOne
} from 'hooks'
import {
  ChevronDown
} from 'icons'
import {
  closeDialog
} from 'utils-em'
import ExpertiseRatingsTable from './ExpertiseRatingsTable'
import QuestionResponseRatingsTable from './QuestionResponseRatingsTable'

const FinalExpertiseRatingsDialog = ({ advisorId }) => {
  const { finalExpertiseRatings } = useJsonAPISelectOne('advisors', advisorId)

  const sections = [
    {
      title: 'Survey ratings',
      content: <QuestionResponseRatingsTable advisorId={advisorId} />
    },
    {
      title: 'Transcript ratings',
      content: <ExpertiseRatingsTable advisorId={advisorId} />
    }
  ]

  const FinalRatings = () => (
    <Box>
      {finalExpertiseRatings.map(({ organizationId, organizationName, ratings }) => (
        <Box key={organizationId}>
          <Typography variant="h4" sx={{ mb: 1 }}>{`Areas of expertise for ${organizationName}`}</Typography>
          {ratings.map(({ expertiseId, expertiseName, rating }) => (
            <Typography key={expertiseId}>{`${expertiseId}. ${expertiseName}: ${rating}`}</Typography>
          ))}
        </Box>
      ))}
    </Box>
  )

  return (
    <Dialog open maxWidth="xl" onClose={closeDialog}>
      <DialogTitle>Advisor Areas of Expertise</DialogTitle>
      <DialogContent sx={{ minWidth: '1000px' }}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography>
            {`Final advisor area of expertise ratings are calculated as the max survey response 
            value if a survey is taken, otherwise the max rating extracted from transcripts.
            A rating of 0 indicates the advisor has not discussed the area, and
            a rating on 5 indicates the advisor has extensive knowledge in the area.`}
          </Typography>
          <FinalRatings />
          {sections.map(({ title, content }) => (
            <Accordion key={title}>
              <AccordionSummary expandIcon={<ChevronDown />}>
                <Typography variant="h4">{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {content}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

FinalExpertiseRatingsDialog.propTypes = {
  advisorId: PropTypes.string.isRequired
}

export default FinalExpertiseRatingsDialog
