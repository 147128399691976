import React from 'react'
import PropTypes from 'prop-types'

import {
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem
} from '@mui/material'

import AllInclusive from '@mui/icons-material/AllInclusive'
import HowToReg from '@mui/icons-material/HowToReg'
import Phone from '@mui/icons-material/Phone'
import Security from '@mui/icons-material/Security'
import { StickySelectField } from 'components'

const adminFilters = ({ filters, handleSearchFilter }) => (
  <FormControl fullWidth variant="outlined" size="small">
    <InputLabel id="role-select-label">Role</InputLabel>
    <StickySelectField
      stickyKey="admin.role"
      labelId="role-select-label"
      id="role-select"
      label="Role"
      variant="outlined"
      startAdornment={(
        <InputAdornment position="start">
          <Security />
        </InputAdornment>
      )}
      value={filters.roleType.value}
      onChange={(event) => handleSearchFilter('roleType', event.target.value)}
    >
      <MenuItem value="">
        <Chip size="small" label="Any" icon={<AllInclusive />} />
      </MenuItem>
      <MenuItem value="admin">
        <Chip size="small" label="Admin" icon={<HowToReg />} />
      </MenuItem>
      <MenuItem value="operator">
        <Chip size="small" label="Operator" icon={<Phone />} />
      </MenuItem>
    </StickySelectField>
  </FormControl>
)

adminFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  handleSearchFilter: PropTypes.func.isRequired
}

export default adminFilters
