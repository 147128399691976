import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'

const FeatureAccessControl = ({ children, defaultCase, userFeatures, feature, hideIf, customerId }) => {
  const foundFeature = userFeatures.find((userFeature) => userFeature.flag === feature)
  if (foundFeature) {
    // if customerIds is undefined (since CUs and advisors don't have them), the feature is visible
    // if the feature doesn't have any customer ids, the feature is visible
    // else, the customerId must be in the list of customerIds
    const customerCanSee = isUndefined(foundFeature.customerIds) || foundFeature.customerIds.length === 0 || foundFeature.customerIds.includes(customerId)
    if (customerCanSee) {
      return hideIf ? null : children
    }
  }
  return hideIf ? children : defaultCase
}

FeatureAccessControl.defaultProps = {
  defaultCase: null,
  hideIf: false,
  customerId: null
}

FeatureAccessControl.propTypes = {
  defaultCase: PropTypes.node,
  children: PropTypes.node.isRequired,
  feature: PropTypes.string.isRequired,
  hideIf: PropTypes.bool,
  customerId: PropTypes.number,
  userFeatures: PropTypes.array.isRequired,
}

export default FeatureAccessControl
