import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Chip, InputAdornment, TextField } from '@mui/material'
import { Filter } from 'icons'
import { useDebounce } from 'utils-em'

const ChipMultiAutocomplete = ({
  options,
  initialSelectedOptions,
  selectedOptionsCallback,
  searchValueCallback,
  label,
  placeholder,
  getOptionLabel,
  StartIcon,
  getChipLabel,
  textFieldProps,
  maxChipTextLength,
  multiple,
  ...autocompleteProps
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState(initialSelectedOptions)
  const [searchValue, setSearchValue] = React.useState(null)
  const debounceSearchValue = useDebounce(searchValue, 250)
  const [focus, setFocus] = React.useState(false)

  const selectedLabels = selectedOptions?.map((option) => option && getOptionLabel(option))
  const filteredOptions = options.filter((option) => !selectedLabels?.includes(getOptionLabel(option)))

  React.useEffect(() => { selectedOptionsCallback && selectedOptionsCallback(selectedOptions) }, [selectedOptions])
  React.useEffect(() => { searchValueCallback && searchValueCallback(searchValue) }, [debounceSearchValue])

  // handles clearing of initial options from another component
  React.useEffect(() => { !initialSelectedOptions?.length && setSelectedOptions([]) }, [initialSelectedOptions?.length])

  const renderTags = (tagValue, getTagProps) => {
    const getTagLabel = getChipLabel || getOptionLabel
    return (multiple ? tagValue : tagValue[0]).map((option, index) => {
      const tagLabel = getTagLabel(option)
      const truncatedLabel = maxChipTextLength && tagLabel.length > maxChipTextLength ? `${tagLabel.slice(0, maxChipTextLength)}...` : tagLabel
      return (
        <Chip label={truncatedLabel} {...getTagProps({ index })} />
      )
    })
  }

  return (
    <Autocomplete
      multiple={multiple}
      fullWidth
      disableCloseOnSelect={multiple}
      filterSelectedOptions
      options={filteredOptions}
      getOptionLabel={getOptionLabel}
      value={selectedOptions}
      onChange={(e, newValue) => {
        setSelectedOptions(multiple ? newValue : [newValue])
      }}
      renderTags={renderTags}
      renderOption={(props, option) => (
        <li {...props}>{getOptionLabel(option)}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={placeholder}
          label={label}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          sx={{ bgcolor: 'neutral.white' }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start" sx={{ mr: 0 }}>
                  {StartIcon}
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          InputLabelProps={{
            shrink: selectedOptions?.length > 0 || !!params.inputProps.value || focus
          }}
          {...textFieldProps}
        />
      )}
      {...autocompleteProps}
    />
  )
}

ChipMultiAutocomplete.defaultProps = {
  initialSelectedOptions: [],
  searchValueCallback: null,
  label: null,
  placeholder: null,
  getOptionLabel: (option) => option,
  StartIcon: <Filter />,
  textFieldProps: {},
  getChipLabel: null,
  maxChipTextLength: 20,
  multiple: true
}

ChipMultiAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  initialSelectedOptions: PropTypes.array,
  selectedOptionsCallback: PropTypes.func.isRequired,
  searchValueCallback: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getOptionLabel: PropTypes.func,
  StartIcon: PropTypes.node,
  maxChipTextLength: PropTypes.number,
  textFieldProps: PropTypes.object,
  getChipLabel: PropTypes.func,
  multiple: PropTypes.bool
}

export default ChipMultiAutocomplete
