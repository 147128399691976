import React from 'react'

/**
 * Custom hook for performing organization searches.
 * @param {string} searchText - The search text to query organizations.
 * @param {Object} options - Additional options for customization.
 * @returns {Object} - An object containing organization options, loading state, and error status.
 */
export default function useOrgSearch (searchText, options) {
  // default options
  const { endpoint, searchParams } = {
    endpoint: `${__API_HOST__}/v1/search/customerOrgSearch`,
    searchParams: {},
    ...options
  }
  const [orgResults, setOrgResults] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [abortController, setAbortController] = React.useState(null)

  React.useEffect(() => {
    if (searchText) {
      searchOrgs()
    } else {
      abortController && abortController.abort()
      setOrgResults([])
    }
  }, [searchText])

  const searchOrgs = async () => {
    setIsLoading(true)
    try {
      abortController && abortController.abort() // cancel previous request
      const newRequestAbortController = new AbortController()
      setAbortController(newRequestAbortController)
      const res = await fetch(
        `${endpoint}?${new URLSearchParams({ q: searchText, ...searchParams })}`,
        { credentials: 'include', method: 'GET', signal: newRequestAbortController.signal }
      )
      if (res.error) throw new Error('Cannot contact network, please contact support')
      const body = await res.json()
      setOrgResults(body)
      setError(null)
    } catch (err) {
      if (err instanceof DOMException && err.name === 'AbortError') return // ignore aborted request errors
      setError(err)
    }
    setIsLoading(false)
  }

  return { orgResults, isLoading, error }
}
