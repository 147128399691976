
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Lightbulb
} from 'icons'
import {
  Box,
  Skeleton,
  Typography
} from '@mui/material'
import { JsonAPIActions } from 'store'
import { AssetIcon } from 'components'
import {
  buildJsonApiOrdered,
  customAlert,
  navigateTo
} from 'utils-em'

import { AccountPageContext } from '../AccountPageContext'
import AvatarWithTextCombo from '../AvatarWithTextCombo'

const RelatedInsights = () => {
  const { accountPage } = React.useContext(AccountPageContext)
  const dispatch = useDispatch()

  const accountPages = useSelector(({ data }) => buildJsonApiOrdered(data, 'accountPages'))
  const assets = useSelector(({ data }) => buildJsonApiOrdered(data, 'assets'))

  const [loaded, setLoaded] = React.useState(false)

  const loadAssetsByOrg = async () => {
    try {
      const res = await fetch(
        `${__API_HOST__}/v1/search/insights/${accountPage.organizationId}?limit=4`,
        { credentials: 'include', method: 'GET' }
      )
      if (res.error) throw new Error(res.error.message)
      const body = await res.json()
      const accountPageData = body.accountPages
      const assetData = body.assets

      await Promise.all([
        dispatch(JsonAPIActions.populateJsonApi(accountPageData, { type: 'accountPages' })),
        dispatch(JsonAPIActions.populateJsonApi(assetData, { type: 'assets' }))
      ])
      setLoaded(true)
    } catch (error) {
      if (!error.aborted) customAlert('Something went wrong. Please contact support.', true)
    }
  }
  React.useEffect(() => { loadAssetsByOrg() }, [])

  const getInsights = () => {
    const otherAccountPages = accountPages.filter((ap) => ap.organizationId !== accountPage.organizationId)

    return [...assets, ...otherAccountPages]
  }

  const getInsightLabel = (insight) => {
    if (insight.type === 'accountPages') {
      return `${insight.organization.name} Organizational Buying Profile`
    }
    return insight.title
  }

  if (!loaded) return <Skeleton />

  const insights = getInsights()

  if (!insights.length) return null

  return (
    <Box sx={{ mt: 4 }}>
      <AvatarWithTextCombo
        icon={<Lightbulb sx={{ color: 'brand.purple' }} />}
        iconColor="brand.purple"
        backgroundColor="brand.lightestPurple"
        variant="h4"
        textColor="neutral.black"
        text="Explore these related insights:"
        sx={{ ml: 2, mb: '12px' }}
      />
      <Box>
        {insights.map((insight) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: 2,
              py: 1.5,
              ':hover': {
                bgcolor: 'primary.lightest',
                cursor: 'pointer',
              }
            }}
            key={insight.id + insight.type}
            onClick={() => navigateTo(`/c/${insight.type === 'accountPages' ? 'buying-profile' : 'insights'}/${insight.id}`)}
          >
            <Typography variant="body1" color="neutral.black">
              {getInsightLabel(insight)}
            </Typography>
            <AssetIcon
              sx={{ mx: 0.5, width: '16px' }}
              assetType={insight.type === 'accountPages' ? 'account-page' : insight.assetType}
            />
            <Typography variant="tiny" color="neutral.black">
              {insight.type === 'accountPages' ? 'Account Page' : insight.assetTypeDescription}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default RelatedInsights
