import PropTypes from 'prop-types'
import React from 'react'
import {
  Autocomplete,
  Backdrop,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import {
  Clear,
  MagnifyingGlass
} from 'icons'
import { BoldedTextMatch } from 'components'
import {
  useDebounce
} from 'utils-em'

const TEXT_FIELD_HEIGHT = 80
const PADDING = 16
const Z_INDEX_CLOSED = 15 // so the bar doesn't appear above navigation

/**
 * A versatile search bar component with autocomplete functionality.
 *
 * This component provides a search input field with autocompletion support. It can be used
 * to search and select items from a predefined list of options. It offers features like
 * debouncing, customizable rendering of options, and callbacks to track user interactions.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Function} [props.searchValueCallback] - A callback function invoked when the search value changes.
 * @param {Function} [props.selectedOptionCallback] - A callback function invoked when the selected option changes.
 * @param {Function} [props.selectedSearchCallback] - A callback function invoked when the selected search changes.
 * @param {object} [props.initialSelectedOption] - The initial selected option.
 * @param {string} [props.initialSelectedSearch] - The initial search value.
 * @param {string} [props.placeholder="Search..."] - The placeholder text for the search input field.
 * @param {string|ReactNode} [props.noOptionsText] - Text or JSX element displayed when no options are available.
 * @param {string|ReactNode} [props.belowOptionsText] - Additional text or JSX element displayed below the options.
 * @param {Array} [props.options] - An array of options to be displayed in the autocomplete dropdown.
 * @param {Function} [props.getOptionText] - A function to extract text from an option for display.
 * @param {Function} [props.renderOption] - A function to customize the rendering of each option.
 * @param {Function} [props.onClear] - A callback function when the X clear icon is used to clear search.
 * @returns {JSX.Element} The rendered `BigSearchBar` component.
 */
const BigSearchBar = ({
  searchValueCallback,
  selectedOptionCallback,
  selectedSearchCallback,
  initialSelectedOption,
  initialSelectedSearch,
  placeholder,
  noOptionsText,
  belowOptionsText,
  options,
  getOptionText,
  renderOption,
  onClear,
  ...rest
}) => {
  const [searchValue, setSearchValue] = React.useState(initialSelectedSearch)
  const [selectedOption, setSelectedOption] = React.useState(initialSelectedOption)
  const [selectedSearch, setSelectedSearch] = React.useState(initialSelectedSearch)
  const [popperOpen, setPopperOpen] = React.useState(false)
  const debounceSearchValue = useDebounce(searchValue, 50)
  const { header, palette, spacing, typography } = useTheme()
  const ref = React.useRef(null)
  const highlightedOptionRef = React.useRef(null)

  const zIndexOpen = header.zIndex + 1 // so the backdrop appears above navigation
  const zIndex = popperOpen ? zIndexOpen : Z_INDEX_CLOSED

  React.useEffect(() => { searchValueCallback && searchValueCallback(searchValue) }, [debounceSearchValue])
  React.useEffect(() => { selectedOptionCallback && selectedOptionCallback(selectedOption) }, [selectedOption])
  React.useEffect(() => { selectedSearchCallback && selectedSearchCallback(selectedSearch) }, [selectedSearch])
  React.useEffect(() => {
    if (initialSelectedOption) {
      getOptionText && setSearchValue(getOptionText(initialSelectedOption))
      setSelectedOption(initialSelectedOption)
      setSelectedSearch(null)
    } else {
      setSelectedOption(null)
      !initialSelectedSearch && !debounceSearchValue && setSearchValue(null)
    }
  }, [initialSelectedOption])

  React.useEffect(() => {
    if (initialSelectedSearch) {
      setSearchValue(initialSelectedSearch)
      setSelectedOption(null)
    } else {
      setSelectedSearch(null)
      !initialSelectedOption && setSearchValue(null)
    }
  }, [initialSelectedSearch])

  React.useEffect(() => {
    if (searchValue) return
    setSelectedOption(null)
    setSelectedSearch('')
  }, [searchValue])

  const hasSearched = searchValue !== null && searchValue !== ''
  const canBeCleared = hasSearched || Boolean(selectedOption)
  const autoCompleteWidth = ref && ref.current ? ref.current.offsetWidth : null

  const inputStyle = {
    height: `${TEXT_FIELD_HEIGHT}px`,
    borderRadius: `${TEXT_FIELD_HEIGHT / 2}px`,
    bgcolor: 'neutral.white',
    flexGrow: '1',
  }

  const CustomPopper = (props) => (
    <Popper
      {...props}
      style={{ zIndex }}
      modifiers={[{
        name: 'offset',
        options: { offset: [0, -TEXT_FIELD_HEIGHT - PADDING] }
      },
      {
        name: 'flip',
        enabled: false
      }
      ]}
    />
  )

  const CustomPaper = (props) => (
    <Paper
      {...props}
      elevation={6}
      sx={{
        width: autoCompleteWidth + 2 * PADDING,
        borderRadius: `${TEXT_FIELD_HEIGHT / 2}px`,
        p: `${PADDING}px`,
        pt: `${TEXT_FIELD_HEIGHT + 2 * PADDING}px`,
      }}
    >
      <Box sx={{ p: 3, pt: 1 }}>
        {props?.children}
        {debounceSearchValue && belowOptionsText ? (
          <>
            <Divider sx={{ mb: 3, mt: 3 }} />
            <Box sx={{ p: 2 }}>{belowOptionsText}</Box>
          </>
        ) : null}
      </Box>
    </Paper>
  )
  CustomPaper.propTypes = { children: PropTypes.node.isRequired }

  return (
    <Box {...rest}>
      <Backdrop
        sx={{ color: palette.brand.navy, zIndex: zIndexOpen - 1 }}
        style={{ opacity: 0.1 }}
        open={popperOpen}
      />
      <Autocomplete
        id="big-search-bar"
        ref={ref}
        value={selectedOption || searchValue}
        style={{ zIndex: zIndex + 1 }}
        sx={{ ...inputStyle, '& .MuiAutocomplete-root': { zIndex: zIndex + 1 } }}
        disableClearable // move clearing to the text input field
        noOptionsText={noOptionsText && !debounceSearchValue && (
          <>
            <Divider sx={{ mb: 4 }} />
            {noOptionsText}
          </>
        )}
        options={options}
        filterOptions={(item) => item}
        renderOption={(props, option) => {
          const text = (typeof option === 'string') ? option : getOptionText(option)
          return (
            <li {...props}>
              {renderOption(option, <BoldedTextMatch substring={searchValue} text={text} />)}
            </li>
          )
        }}
        getOptionLabel={(option) => ((typeof option === 'string') ? option : getOptionText(option))}
        onChange={(_e, option, _reason) => setSelectedOption(option)}
        onOpen={() => setPopperOpen(true)}
        onClose={() => setPopperOpen(false)}
        onHighlightChange={(_e, option) => { highlightedOptionRef.current = option }}
        PaperComponent={CustomPaper}
        PopperComponent={CustomPopper}
        popupIcon=""
        renderInput={(params) => {
          const combinedParams = {
            ...params,
            value: searchValue,
            onChange: (e) => setSearchValue(e.currentTarget.value),
            type: 'text',
            style: { zIndex: zIndex + 1 },
            placeholder,
            onKeyDown: (e) => {
              if (e.key === 'Enter' && e.target.value) {
                if (!selectedSearchCallback) return
                e.preventDefault()
                e.stopPropagation()

                const highlightedOption = highlightedOptionRef?.current
                const match = options.find((o) => getOptionText(o).toLowerCase() === e.target.value.toLowerCase())

                if (highlightedOption) {
                  // select highlighted option if exists
                  setSelectedOption(highlightedOption)
                } else if (match) {
                  // see if typed in text matches an option
                  setSelectedOption(match)
                } else {
                  setSelectedSearch(e.target.value)
                  setSelectedOption(null)
                }
                document.activeElement.blur()
              }
            },
            InputProps: {
              ...params.InputProps,
              sx: {
                ...typography.h3,
                input: { '&::placeholder': { color: palette.neutral.darkGrey, opacity: 1 } },
                color: palette.neutral.black,
                '&.Mui-focused': { color: palette.neutral.black },
                '&::placeholder': { color: palette.neutral.darkGrey, opacity: 1 },
                '& .MuiOutlinedInput-notchedOutline': { borderWidth: '2px', borderColor: palette.neutral.darkGrey },
                '&:hover .MuiOutlinedInput-notchedOutline': { borderWidth: '3px', borderColor: palette.neutral.black },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: '3px', borderColor: palette.primary.main }
              },
              style: {
                ...inputStyle,
                paddingLeft: spacing(2),
                paddingRight: spacing(2),
              },
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlass sx={{ height: 40, width: 40 }} />
                </InputAdornment>
              ),
              endAdornment: !canBeCleared ? undefined : (
                <InputAdornment position="end">
                  <IconButton onClick={() => {
                    setSearchValue(null)
                    setSelectedOption(null)
                    setSelectedSearch(null)
                    onClear && onClear()
                  }}
                  >
                    <Clear sx={{ height: 40, width: 40 }} />
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: 'off' // prevent browser autocomplete
            }
          }
          return <TextField {...combinedParams} />
        }}
      />
    </Box>
  )
}

BigSearchBar.defaultProps = {
  searchValueCallback: null,
  selectedOptionCallback: null,
  selectedSearchCallback: null,
  initialSelectedOption: null,
  initialSelectedSearch: null,
  placeholder: 'Search...',
  noOptionsText: null,
  belowOptionsText: null,
  options: null,
  getOptionText: (option) => option,
  renderOption: (option, boldedOptionText) => <Typography>{boldedOptionText}</Typography>,
  onClear: null
}

BigSearchBar.propTypes = {
  searchValueCallback: PropTypes.func,
  selectedOptionCallback: PropTypes.func,
  selectedSearchCallback: PropTypes.func,
  initialSelectedOption: PropTypes.object,
  initialSelectedSearch: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  belowOptionsText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  options: PropTypes.array,
  getOptionText: PropTypes.func,
  renderOption: PropTypes.func,
  onClear: PropTypes.func
}

export default BigSearchBar
