import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Bot (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9993 8.66634V5.33301H8.66602" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.0007 8.66699H7.00065C6.08018 8.66699 5.33398 9.41318 5.33398 10.3337V17.0003C5.33398 17.9208 6.08018 18.667 7.00065 18.667H17.0007C17.9211 18.667 18.6673 17.9208 18.6673 17.0003V10.3337C18.6673 9.41318 17.9211 8.66699 17.0007 8.66699Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.66602 13.667H5.33268" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.666 13.667H20.3327" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 12.833V14.4997" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 12.833V14.4997" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
