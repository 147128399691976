import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { map } from 'lodash'

import { ValidatorForm, SelectValidator, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  Paper,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@mui/material'

import {
  useGetEnums,
  useJsonAPIGetAll,
  useJsonAPIGetOne
} from 'hooks'
import { JsonAPI } from 'store'
import {
  closeDialog,
  insertItem,
  removeItem,
  httpResponseAlert,
  navigateTo
} from 'utils-em'
import {
  TagPicker,
  AdminUserSelector,
  OrganizationSelector
} from 'components'

const OIDC_PROVIDERS = [
  'okta'
]

const EditCustomer = ({ asDialog, customerId }) => {
  const dispatch = useDispatch()

  const { objects: samlIdps } = useJsonAPIGetAll('samlIdps')
  const { object: defaultCustomer, loaded } = useJsonAPIGetOne(customerId, 'customers', ['tags'])

  const { enums: statusEnum } = useGetEnums('statuses')

  const [sendingRequest, setSendingRequest] = React.useState(false)
  const [customer, setCustomer] = React.useState({
    name: '',
    adminNotes: '',
    status: 'Active',
    tags: [],
    supplyOwnerId: {},
    demandOwnerId: {},
    website: '',
    description: '',
    allowCallConflicts: false
  })

  React.useEffect(() => {
    defaultCustomer?.id && setCustomer(defaultCustomer)
  }, [defaultCustomer?.id])

  const saveCustomer = () => {
    const method = customer.id ? JsonAPI.save : JsonAPI.create
    return dispatch(method(Object.assign(customer, {
      type: 'customers',
      queryStringParams: {
        include: 'tags'
      },
      relationshipNames: ['tags']
    })))
  }

  const onSubmit = () => {
    setSendingRequest(true)
    saveCustomer().then((res) => {
      httpResponseAlert(res, {
        success: 'Client Saved',
        failure: `Client not created: ${res.status}: ${res.error?.errors?.map((e) => e.detail).join('; ')}`
      })
      setSendingRequest(false)

      if (res.ok) {
        if (asDialog) {
          closeDialog()
        } else {
          navigateTo(`/admin/customers/${res.data.data.id}`)
        }
      }
    })
  }

  const addFocusIndustry = (focusIndustry) => {
    setCustomer({
      ...customer,
      tags: insertItem(
        customer.tags,
        customer.tags.length,
        focusIndustry
      )
    })
  }

  const removeFocusIndustry = (focusIndustry) => {
    setCustomer({
      ...customer,
      tags: removeItem(
        customer.tags,
        customer.tags.findIndex((fi) => fi.id === focusIndustry.id)
      )
    })
  }

  const handleChange = (fieldName, isBool = false) => (event) => {
    const value = isBool ? event.target.checked : event.target.value
    setCustomer({
      ...customer,
      [fieldName]: value
    })
  }

  const handleObjectChange = (obj, fieldName) => {
    setCustomer({
      ...customer,
      [fieldName]: obj ? obj.id : null
    })
  }

  if (customerId && !loaded) return null

  const baseEditForm = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <TextValidator
              fullWidth
              validators={['required']}
              errorMessages={['Required Field']}
              value={customer.name}
              onChange={handleChange('name')}
              placeholder="Name"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>Status</FormLabel>
            <SelectValidator
              fullWidth
              value={customer.status}
              onChange={handleChange('status')}
            >
              { map(statusEnum, (status, id) => (
                <MenuItem key={id} value={status}>{status}</MenuItem>
              ))}
            </SelectValidator>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TagPicker
            compact
            label="Focus Industries"
            type="focus-industry"
            addTag={addFocusIndustry}
            removeTag={removeFocusIndustry}
            selectedTags={customer.tags?.filter((tag) => tag.category === 'focus-industry')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>Website</FormLabel>
            <TextField
              fullWidth
              value={customer.website}
              placeholder="Website"
              onChange={handleChange('website')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormLabel required>Account Manager</FormLabel>
          <AdminUserSelector
            userId={parseInt(customer.demandOwnerId, 10)}
            onChange={(user) => handleObjectChange(user, 'demandOwnerId')}
            validators={['required']}
            errorMessages={['Required Field']}
          />
        </Grid>
        <Grid item xs={3}>
          <FormLabel required>Client Engagement Manager</FormLabel>
          <AdminUserSelector
            userId={parseInt(customer.supplyOwnerId, 10)}
            onChange={(user) => handleObjectChange(user, 'supplyOwnerId')}
            validators={['required']}
            errorMessages={['Required Field']}
          />
        </Grid>
        <Grid item xs={3}>
          <OrganizationSelector
            organizationId={customer.organizationId}
            handleChange={(org) => handleObjectChange(org, 'organizationId')}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>OIDC provider</FormLabel>
            <SelectValidator
              fullWidth
              value={customer.oidcProvider || ''}
              onChange={handleChange('oidcProvider')}
            >
              <MenuItem value={null}>None</MenuItem>
              {OIDC_PROVIDERS.map((provider) => (
                <MenuItem key={provider} value={provider}>{provider}</MenuItem>
              ))}
            </SelectValidator>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>SAML IdP (enables SAML)</FormLabel>
            <SelectValidator
              fullWidth
              value={customer.samlIdpId || ''}
              onChange={handleChange('samlIdpId')}
            >
              <MenuItem value={null}>None</MenuItem>
              { samlIdps && samlIdps.map((samlIdp) => (
                <MenuItem key={samlIdp.id} value={samlIdp.id}>{samlIdp.displayName}</MenuItem>
              ))}
            </SelectValidator>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            value={customer.adminNotes || ''}
            label="Customer Notes"
            helperText="Customer notes are not shown to customers or advisors"
            onChange={handleChange('adminNotes')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            value={customer.targetTitles || ''}
            label="Target Titles"
            helperText="Target Titles for Advisors (not shown to advisors)"
            onChange={handleChange('targetTitles')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows="6"
            value={customer.description}
            label="Description"
            onChange={handleChange('description')}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={(
              <Switch
                checked={customer.allowCallConflicts}
                onChange={handleChange('allowCallConflicts', true)}
                name="allowCallConflicts"
              />
            )}
            label="Allow Call Conflicts"
          />
        </Grid>
        { !asDialog
          ? (
            <>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={sendingRequest}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </>
            )
          : null}
      </Grid>
    </>
  )

  if (!asDialog) {
    return (
      <Paper sx={{ mt: '1em', p: '1em' }}>
        <ValidatorForm onSubmit={onSubmit}>
          { baseEditForm }
        </ValidatorForm>
      </Paper>
    )
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      onBackdropClick={closeDialog}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h4">
            {`${customerId ? 'Edit' : 'Create'}`}
            {' '}
            Client
          </Typography>
        </DialogTitle>
        <DialogContent>
          {baseEditForm}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={sendingRequest}
          >
            Save
          </Button>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditCustomer.defaultProps = {
  customerId: null,
  asDialog: false
}

EditCustomer.propTypes = {
  asDialog: PropTypes.bool,
  customerId: PropTypes.number
}

export default EditCustomer
