const API_HOST = __API_HOST__

export function stealth (userId, callback) {
  fetch(
    `${API_HOST}/session/stealth/${userId}/?returnUrl=${document.URL}`,
    {
      credentials: 'include',
      method: 'POST'
    }
  ).then((res) => {
    res.json().then((body) => {
      callback(body.redirect_page)
    })
  })
}

export function unstealth (callback) {
  fetch(
    `${API_HOST}/session/exit-stealth`,
    {
      credentials: 'include',
      method: 'POST'
    }
  ).then((res) => {
    res.json().then((body) => {
      callback(body.redirect_page)
    })
  })
}
