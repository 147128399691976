import React from 'react'
import { useDispatch } from 'react-redux'
import {
  DataGrid,
  GridToolbar
} from '@mui/x-data-grid'
import { useJsonAPIGetAll } from 'hooks'
import {
  Skeleton,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material'
import { Edit, Trash } from 'icons'
import { JsonAPI } from 'store'
import {
  formatDate,
  httpResponseAlert,
  openDialog,
  constants
} from 'utils-em'

import EditIdpDialog from './EditIdPDialog'

const IdPEditor = () => {
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = React.useState(constants.DEFAULT_DATAGRID_ROWS_PER_PAGE)
  const { objects: samlIdPs, loaded } = useJsonAPIGetAll('samlIdps')

  if (!loaded) return <Skeleton variant="rounded" height={160} />

  const handleDelete = async (samlIdpId) => {
    const resp = await dispatch(JsonAPI.delete({
      type: 'samlIdps',
      id: samlIdpId
    }))
    httpResponseAlert(resp)
  }

  const columns = [
    { field: 'displayName', headerName: 'Name', width: 200, valueGetter: ({ row }) => row.displayName },
    { field: 'dateCreated', headerName: 'Creation dert', width: 200, type: 'date', valueFormatter: ({ value }) => formatDate(value) },
    {
      field: 'action',
      headerName: 'Actions',
      disableExport: true,
      width: 200,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <Button
              onClick={() => openDialog((
                <EditIdpDialog samlIdpId={params.row.id} />
              ))}
            >
              <Edit />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              onClick={() => handleDelete(params.row.id)}
            >
              <Trash />
            </Button>
          </Tooltip>
        </>
      )
    }
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', m: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '20px', m: 1 }}>
          SAML IdPs
        </Typography>
        <Button
          variant="contained"
          onClick={() => openDialog((
            <EditIdpDialog />
          ))}
        >
          Create New
        </Button>
      </Box>
      <Box>
        <Box sx={{ flexGrow: 1, '& .discarded': { opacity: '0.5' } }}>
          <DataGrid
            // use v5 documentation: https://v5.mui.com/x/react-data-grid
            autoHeight
            columns={columns}
            rows={samlIdPs}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{ toolbar: { csvOptions: { allColumns: true } } }}
            getRowClassName={(params) => (params.row.isDiscarded ? 'discarded' : '')}
            columnBuffer={25}
            pageSize={pageSize}
            rowsPerPageOptions={constants.DEFAULT_DATAGRID_ROWS_PER_PAGE_OPTIONS}
            onPageSizeChange={(size) => size !== pageSize && setPageSize(size)}
            sx={{
              '& .MuiDataGrid-menuIconButton': { zIndex: 9999 }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default IdPEditor
