import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const SideNavigationMenu = ({ menuItems, defaultActiveHash, onChange }) => {
  const visibleMenuItems = menuItems.filter((menuItem) => !menuItem.hidden)

  const menuItemClicked = (menuItem) => { onChange(menuItem) }
  const isActive = (menuHash) => menuHash === defaultActiveHash

  return (
    <Box id="side-navigation">
      <Box id="side-navigation-menu-items" sx={{ display: 'flex', flexDirection: 'column' }}>
        {visibleMenuItems.map((menuItem) => (
          <Box
            key={menuItem.hash}
            className={menuItem.className}
            sx={{
              px: 2,
              py: 1,
              mb: 0.5,
              borderRadius: 1,
              cursor: 'pointer',
              bgcolor: isActive(menuItem.hash) ? 'primary.lightest' : '',
              ':hover': { bgcolor: 'primary.lightest' }
            }}
            onClick={() => menuItemClicked(menuItem)}
          >
            <Typography
              variant={isActive(menuItem.hash) ? 'bodyBold' : 'body1'}
              color="neutral.black"
            >
              {menuItem.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

SideNavigationMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    className: PropTypes.string,
    hidden: PropTypes.bool
  })).isRequired,
  defaultActiveHash: PropTypes.string,
  onChange: PropTypes.func
}

export default SideNavigationMenu
