import { isEmpty, orderBy } from 'lodash'
import moment from 'moment'
import { getEngagementOrganizationName } from 'utils-em'

const SENIORITY_SHORTHANDS = {
  'C-Level / Head / President': 'C-level',
  'EVP / SVP / VP': 'Executive level',
  'Senior Director / Director': 'Director level',
  'Senior Manager / Manager': 'Manager level',
  Associate: 'Associate level'
}

export function isAdvisorAvailable (advisor) {
  if (!advisor) return false
  return !advisor.dateAvailable || moment(advisor.dateAvailable).isBefore(moment())
}

export function sortActiveCompaniesByRecency (companies) {
  const activeCompanies = companies.filter((company) => !company.deleted)

  return orderBy(activeCompanies, ['dateEnded', 'roles[0].dateEnded'], ['desc'])
}

export function sortActiveRolesByRecency (company) {
  const activeRoles = company.roles.filter((role) => !role.deleted)

  return orderBy(activeRoles, ['dateEnded'], ['desc'])
}

export function getAdvisorCompanyFromRoleId (advisor, roleId) {
  return advisor.companies.find((acr) => {
    if (!acr.roles) {
      return false
    }
    return acr.roles.find((role) => parseInt(role.id, 10) === parseInt(roleId, 10))
  })
}

export function getAdvisorRelevantCompanies (advisor, options = {}) {
  if (!advisor || isEmpty(advisor.companies)) { return [] }

  const { mustHaveRoles } = {
    mustHaveRoles: true,
    ...options
  }

  const hasRelevantRole = (company) => !isEmpty((company.roles || []).filter((r) => !r.deleted))

  const relevantCompanies = advisor.companies.filter((company) => (
    !company.deleted &&
    (!mustHaveRoles || hasRelevantRole(company))
  ))

  // `orderBy` correctly sorts those as the first entries in DESC mode
  return orderBy(relevantCompanies, 'roles[0].dateEnded', ['desc'])
}

export function getAdvisorCompanyByOrg (advisor, orgId) {
  return advisor.companies.find((company) => !company.deleted && company.organizationId === orgId)
}

export function getAdvisorMostRecentRelevantCompany (advisor, options = {}) {
  const { mustHaveRoles } = { mustHaveRoles: true, ...options }
  const relevantCompanies = getAdvisorRelevantCompanies(advisor, { mustHaveRoles })
  if (isEmpty(relevantCompanies)) return null
  return relevantCompanies[0]
}

export function getAdvisorMostRecentRelevantRole (advisor, company = null) {
  const selectedCompany = company || getAdvisorMostRecentRelevantCompany(advisor)
  if (!selectedCompany) return null
  const roles = selectedCompany.roles.filter((r) => !r.deleted)
  if (roles.length === 0) return null
  return roles[0]
}

export function getAdvisorDepartureDateByOrganization (advisor, orgId) {
  const company = getAdvisorCompanyByOrg(advisor, orgId)
  const role = getAdvisorMostRecentRelevantRole(advisor, company)
  return new Date(role.dateEnded)
}

export function getAdvisorSeniorityShorthand (seniority) {
  return SENIORITY_SHORTHANDS[seniority] || seniority
}

export function getLowestSeniorityLevel (seniorities) {
  if (!seniorities || seniorities.length === 0) return ''
  const seniorityKeys = Object.keys(SENIORITY_SHORTHANDS)
  // the lowest level is the one with the highest index in `SENIORITY_SHORTHANDS` (due to the way it's ordered)
  // so map all the given seniorities to indices, get the largest one, and return the associated seniority level
  return SENIORITY_SHORTHANDS[seniorityKeys[Math.max(...seniorities.map((seniority) => seniorityKeys.indexOf(seniority)))]]
}

export function getAdvisorTitle (advisor, options) {
  const { includeDate, includeFirstName, engagement, role } = {
    includeDate: false,
    includeFirstName: false,
    engagement: null,
    role: null,
    ...options
  }
  const selectedRole = role || engagement?.role || getAdvisorMostRecentRelevantRole(advisor)
  let formerTitle = includeFirstName ? advisor.firstName : null
  if (!selectedRole) return formerTitle

  const { title, dateStarted, dateEnded } = selectedRole
  const company = getAdvisorCompanyFromRoleId(advisor, selectedRole.id)
  const orgName = engagement ? getEngagementOrganizationName(engagement) : company.displayOrgName
  formerTitle = formerTitle ? `${formerTitle}, ` : ''
  formerTitle += title || 'employee'
  formerTitle += ` ${`at ${orgName}` || ''}`

  if (includeDate) {
    const started = dateStarted && moment(dateStarted).format('MMM YYYY')
    const ended = dateEnded && moment(dateEnded).format('MMM YYYY')
    formerTitle += (started && ended) ? `, ${started} - ${ended}` : ''
  }
  return formerTitle
}

export function roleHasAllDisplayedFields (role) {
  return role.title && role.dateStarted && role.seniority && role.description && role.tags &&
  role.tags.filter((tag) => tag && tag.category === 'region').length > 0 &&
  role.tags.filter((tag) => tag && tag.category === 'function-of-role').length > 0
}

export function areRoleDatesValid (role, company) {
  const { isCurrent } = company
  const roleStart = role.dateStarted ? moment(role.dateStarted).startOf('month') : null
  const roleEnd = role.dateEnded ? moment(role.dateEnded).endOf('month') : null
  const companyStart = company.dateStarted ? moment(company.dateStarted).startOf('month') : null
  const companyEnd = company.dateEnded ? moment(company.dateEnded).endOf('month') : null

  if (
    (!isCurrent && !roleEnd) ||
    (!isCurrent && !companyEnd) ||
    (roleEnd && roleStart > roleEnd) ||
    (roleEnd && roleEnd < companyStart) ||
    (roleEnd && companyEnd && (roleEnd > companyEnd)) ||
    (companyEnd && (roleStart > companyEnd)) ||
    (roleStart < companyStart)
  ) return false

  return true
}
