import PropTypes from 'prop-types'
import React from 'react'

import AdvisorSearchList from '../../../../../components/AdvisorSearchList'

const AdvisorSearchLayout = ({ getOne }) => (
  <AdvisorSearchList />
)

AdvisorSearchLayout.propTypes = {
  getOne: PropTypes.func.isRequired
}

export default AdvisorSearchLayout
