import React from 'react'
import PropTypes from 'prop-types'
import { Information } from 'icons'
import { TextField, Tooltip, InputAdornment, Grid, Typography } from '@mui/material'
import { validateEmail } from 'utils-em'

const FormTextField = ({
  form,
  name,
  label,
  disabled,
  lowestValidNumber,
  highestValidNumber,
  required,
  type,
  helperText,
  validator,
  invalidText,
  tooltip,
  ...rest
}) => {
  const { register, formState: { errors } } = form
  const [isFocused, setIsFocused] = React.useState(false)
  const isNumeric = type === 'currency' || type === 'number'
  const isCurrency = type === 'currency'
  const isEmail = type === 'email'
  const inputProps = {
    ...rest.inputProps,
    startAdornment: !isCurrency ? undefined : <InputAdornment position="start">$</InputAdornment>,
    inputMode: isNumeric ? 'numeric' : 'text',
    endAdornment: !tooltip ? undefined : (<Tooltip title={tooltip}><InputAdornment position="end"><Information /></InputAdornment></Tooltip>)
  }

  const formProps = register(name, {
    validate: (value) => {
      if (required && !value && value !== 0) return 'Required field'
      if (isNumeric) {
        const numValue = parseFloat(value)
        if (Number.isNaN(numValue)) {
          return 'Please enter a valid number'
        }
        if ((lowestValidNumber !== null) && numValue < lowestValidNumber) {
          return `Please enter a number greater than or equal to ${lowestValidNumber}`
        }
        if ((highestValidNumber !== null) && numValue > highestValidNumber) {
          return `Please enter a number less than or equal to ${highestValidNumber}`
        }
      }
      if (isEmail && !validateEmail(value).valid) return 'Enter a valid email address'
      if (validator && !validator(value)) return invalidText || 'Invalid value'
      return true
    },
    onBlur: () => setIsFocused(false),
  })
  return (
    <Grid container direction="column" gap="1">
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        disabled={disabled}
        error={Boolean(errors[name])}
        type="text"
        InputProps={inputProps}
        // this forces the label out of the way if there is a value
        // see https://github.com/react-hook-form/react-hook-form/issues/2192
        InputLabelProps={{ shrink: Boolean(form.getValues(name) !== '' || isFocused) }}
        onFocus={() => setIsFocused(true)}
        {...formProps}
        {...rest}
      />
      <Typography variant="tiny">{helperText}</Typography>
      <Typography variant="tiny" color="error">{errors?.[name]?.message}</Typography>
    </Grid>
  )
}

FormTextField.propTypes = {
  form: PropTypes.object.isRequired, // The React Hook Form object
  name: PropTypes.string.isRequired, // The name of the field
  label: PropTypes.string.isRequired, // The label of the field
  disabled: PropTypes.bool, // Whether the field should be disabled
  required: PropTypes.bool, // Whether the field is required
  type: PropTypes.oneOf(['text', 'currency', 'number', 'email']), // The type of the field
  helperText: PropTypes.string, // Helper text for the field
  lowestValidNumber: PropTypes.number, // The lowest valid number for the field
  highestValidNumber: PropTypes.number, // The highest valid number for the field
  validator: PropTypes.func, // A custom validator function
  invalidText: PropTypes.string, // The text to display when the field is invalid
  tooltip: PropTypes.string, // A tooltip to display next to the field
}

FormTextField.defaultProps = {
  disabled: false,
  required: false,
  type: 'text',
  helperText: null,
  lowestValidNumber: null,
  highestValidNumber: null,
  validator: null,
  invalidText: null,
  tooltip: null,
}

export default FormTextField
