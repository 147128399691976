import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import { AdvisorPrefillDialog } from 'components'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { ValidatorForm } from 'react-material-ui-form-validator'
import { trimObjectStringFields, openDialog } from 'utils-em'

import UserForm from './components/UserForm'

const CustomerUserProfile = ({
  createUser,
  getCustomerDivisions,
  saveUser,
  showSuccessMessage,
  showErrorMessage,
  handleUsersUpdated,
  userType,
  users,
  closeDialog,
  refreshUserTable,
}) => {
  let user = {}
  if (users.length > 0) {
    user = users[0]

    // NOTE: Can't have the any values be null in form,
    // as it results in uncontrolled component.
    if (user.phoneNumber === null) {
      user.phoneNumber = ''
    }
    if (user.location === null) {
      user.location = ''
    }
    if (user.jobTitle === null) {
      user.jobTitle = ''
    }
  }
  const otherUsers = users.slice(1, users.length)

  const initialFormData = {
    id: get(user, 'id'),
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
    email: get(user, 'email', ''),
    isActive: get(user, 'isActive', true)
  }

  const cuFormData = {
    location: get(user, 'location', ''),
    jobTitle: get(user, 'jobTitle', ''),
    phoneNumber: get(user, 'phoneNumber', ''),
    roleType: get(user, 'roleType', ''),
    divisions: get(user, 'divisions', []),
    sendInvite: false
  }

  const adminFormData = {
    roleType: get(user, 'roleType', '')
  }

  const formDataFields = userType === 'customerUser' ? cuFormData : adminFormData
  const [formData, setFormData] = React.useState(
    { ...initialFormData, ...formDataFields }
  )

  const [customerDivs, setCustomerDivs] = React.useState([])
  const [modalStep, setModalStep] = React.useState(users.length === 0 ? 'CREATE_USER' : 'EDIT_USER')

  const updateUser = async (cuId) => {
    const newUserData = { id: cuId }
    const trimmedFormData = trimObjectStringFields(formData)
    setFormData(trimmedFormData)
    Object.keys(trimmedFormData).forEach((key) => {
      const value = trimmedFormData[key]
      const incompleteFieldValues = (value === '–' || value === '')
      const restrictedBulkEditFields = (users.length > 1 && key === 'divisions')

      if (!incompleteFieldValues && !restrictedBulkEditFields) {
        newUserData[key] = value
      }
    })

    try {
      const userRes = await saveUser({ ...newUserData }, userType)
      if (userRes.error) throw new Error(userRes.error.message || userRes.error.detail)
      handleUsersUpdated()
      closeDialog()
      showSuccessMessage({ text: 'User saved.', timeout: 2000 })
    } catch (error) {
      showErrorMessage({ text: error.message, timeout: 2000 })
    }
  }

  const handleCreateUser = async () => {
    try {
      const trimmedFormData = trimObjectStringFields(formData)
      setFormData(trimmedFormData)
      const userRes = await createUser(trimmedFormData, userType)
      if (userRes.error) throw new Error(userRes.error.message || userRes.error.detail)
      handleUsersUpdated()
      closeDialog()
      showSuccessMessage({ text: 'User created.', timeout: 2000 })
      userType === 'advisor' && openDialog(<AdvisorPrefillDialog advisorId={userRes.data.data.id} refreshUserTable={() => refreshUserTable()} />)
    } catch (error) {
      showErrorMessage({ text: `User not created. ${error.message}.`, timeout: 4000 })
    }
  }

  const replaceIdenticalFieldsWithDash = () => {
    Object.keys(formData).forEach((key) => {
      if (users.length > 1) {
        const propValueIsString = (u) => typeof u[key] === 'string'
        const dataIsSameBetweenUsers = (u) => u[key] === formData[key]

        if (users.some((u) => propValueIsString(u) && !dataIsSameBetweenUsers(u))) {
          formData[key] = '–'
        }
      }
    })
  }

  useEffect(() => { replaceIdenticalFieldsWithDash() }, [])

  if (get(user, 'customerId')) {
    useEffect(
      () => {
        getCustomerDivisions(user.customerId).then(
          (res) => setCustomerDivs(
            res.data.data.map(
              (div) => ({ ...div.attributes, id: div.id, type: 'divisions' })
            )
          )
        )
      },
      [user.customerId]
    )
  }

  const prettyPrintUserFieldName = (fieldName) => {
    switch (fieldName) {
      case 'firstName':
        return 'first name'
      case 'lastName':
        return 'last name'
      case 'divisions':
        return 'teams'
      case 'email':
        return 'email'
      case 'jobTitle':
        return 'title'
      case 'roleType':
        return 'user role'
      case 'location':
        return 'location'
      case 'phoneNumber':
        return 'phone number'
      case 'isActive':
        return 'active'
      default:
        return 'Other'
    }
  }

  const prettyPrintUserFieldValue = (u, fieldName) => {
    if (fieldName === 'divisions') {
      return u.divisions.map((d) => d.displayName).join(', ')
    }

    if (fieldName === 'isActive') {
      return u.isActive ? 'yes' : 'no'
    }

    return u[fieldName]
  }

  if (modalStep === 'CREATE_USER') {
    return (
      <>
        <Typography variant="h6" style={{ padding: '0.5em 1em' }}>Add User</Typography>
        <Divider />
        <DialogContent>
          <ValidatorForm id="user-create-form" onSubmit={() => setModalStep('CONFIRM_CHANGES')}>
            <UserForm
              userType={userType}
              customerDivisions={[]}
              formData={formData}
              updateFormData={(newValue) => setFormData({ ...formData, ...newValue })}
            />
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            Close
          </Button>
          <Button
            form="user-create-form"
            type="submit"
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </>
    )
  }

  if (modalStep === 'EDIT_USER') {
    return (
      <>
        <Typography variant="h6" style={{ padding: '0.5em 1em' }}>
          Details&nbsp;
          <span style={{ color: '#aaa' }}>{otherUsers.length > 0 ? `(${users.length} people selected)` : null}</span>
        </Typography>
        <Divider />
        <DialogContent>
          <ValidatorForm id="cu-form" onSubmit={() => setModalStep('CONFIRM_CHANGES')}>
            <UserForm
              customerDivisions={customerDivs}
              disabledFields={[
                users.length > 1 ? 'email' : null
              ]}
              formData={formData}
              originalUserData={user}
              visibleSections={[
                users.length === 1 ? 'userActivity' : null,
                users.length === 1 ? 'divisions' : null

              ]}
              userType={userType}
              updateFormData={(newValue) => setFormData({ ...formData, ...newValue })}
            />
          </ValidatorForm>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            Close
          </Button>
          <Button
            form="cu-form"
            type="submit"
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </>
    )
  }

  if (modalStep === 'CONFIRM_CHANGES') {
    return (
      <>
        <Typography variant="h6" style={{ padding: '0.5em 1em' }}>
          Confirm Changes&nbsp;
          <span style={{ color: '#aaa' }}>{otherUsers.length > 0 ? `(${users.length} people selected)` : null}</span>
        </Typography>
        <Divider />
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Changes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((u) => (
                  <TableRow key={u.id}>
                    <TableCell>{u.fullName}</TableCell>
                    <TableCell>
                      <ul>
                        {Object.keys(u).reduce(
                          (acc, currentKey) => {
                            const value = formData[currentKey]
                            const incompleteFieldValues = (value === '–' || value === '')

                            let fieldsAreDifferent = formData[currentKey] !== u[currentKey]

                            if (currentKey === 'divisions') {
                              fieldsAreDifferent = formData[currentKey].filter((el) => u[currentKey].includes(el)) && !(formData[currentKey].length === 0 && u[currentKey].length === 0) && users.length === 1
                            }

                            if (value !== undefined && value !== null && !incompleteFieldValues && fieldsAreDifferent) {
                              return acc.concat(
                                <li key={`${u.id}-${currentKey}`}>
                                  Change
                                  {' '}
                                  <b>{prettyPrintUserFieldName(currentKey)}</b>
                                  {' '}
                                  from &ldquo;
                                  {prettyPrintUserFieldValue(u, currentKey)}
                                  &rdquo; to &ldquo;
                                  {prettyPrintUserFieldValue(formData, currentKey)}
                                  &rdquo;
                                </li>
                              )
                            }
                            return acc
                          },
                          []
                        )}
                        {formData.sendInvite && !u.onboardingCompleted && (userType === 'customerUser' || userType === 'advisor')
                          ? (
                            <li>Resend invitation to Emissary</li>
                            )
                          : (
                              null
                            )}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
                {users.length === 0
                  ? (
                    <TableRow key="new-user">
                      <TableCell>
                        {formData.firstName}
                        {' '}
                        {formData.lastName}
                        {' '}
                      </TableCell>
                      <TableCell>
                        {userType === 'customerUser' ? 'Send invite to the Emissary platform' : 'Create user account without sending invite'}
                      </TableCell>
                    </TableRow>
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => (users.length === 0 ? setModalStep('CREATE_USER') : setModalStep('EDIT_USER'))}>
            Back
          </Button>
          <Button
            onClick={() => {
              if (users.length === 0) {
                handleCreateUser()
              }
              users.forEach((u) => updateUser(u.id))
              handleUsersUpdated()
              closeDialog()
            }}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </>
    )
  }
  return null
}

CustomerUserProfile.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getCustomerDivisions: PropTypes.func.isRequired,
  handleUsersUpdated: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  userType: PropTypes.oneOf(['admin', 'advisor', 'customerUser']).isRequired,
  refreshUserTable: PropTypes.func.isRequired
}

const UserDetailDialog = ({
  closeDialog,
  createUser,
  getCustomerDivisions,
  handleUsersUpdated,
  saveUser,
  showSuccessMessage,
  showErrorMessage,
  users,
  userType,
  refreshUserTable
}) => (
  <Dialog
    open
    onClose={closeDialog}
    maxWidth="sm"
    fullWidth
  >
    <CustomerUserProfile
      users={users}
      getCustomerDivisions={getCustomerDivisions}
      createUser={createUser}
      saveUser={saveUser}
      showSuccessMessage={showSuccessMessage}
      showErrorMessage={showErrorMessage}
      handleUsersUpdated={handleUsersUpdated}
      closeDialog={closeDialog}
      userType={userType}
      refreshUserTable={refreshUserTable}
    />
  </Dialog>
)

UserDetailDialog.defaultProps = {
  users: []
}

UserDetailDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getCustomerDivisions: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  handleUsersUpdated: PropTypes.func.isRequired,
  userType: PropTypes.oneOf(['admin', 'advisor', 'customerUser']).isRequired,
  users: PropTypes.array,
  refreshUserTable: PropTypes.func.isRequired
}

export default UserDetailDialog
