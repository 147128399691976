
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Heart } from 'icons'
import {
  Button,
  useTheme
} from '@mui/material'

import { useJsonAPIGetAll } from 'hooks'
import { customAlert } from 'utils-em'
import { JsonAPI } from 'store'

const BookmarkButton = ({
  targetId,
  bookmarkType,
  bookmarkRemovedAlert,
  bookmarkAddedAlert,
  savedText,
  text,
  ...rest
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const userId = useSelector(({ session }) => session.id)

  const filters = [
    { name: 'bookmarkType', op: 'eq', val: bookmarkType },
    { name: 'bookmarkTarget', op: 'eq', val: targetId.toString() },
    { name: 'ownerId', op: 'eq', val: userId }
  ]

  const { objects: bookmarks } = useJsonAPIGetAll('bookmarks', { filters })
  const bookmark = bookmarks.length ? bookmarks[0] : null

  const onSave = async () => {
    if (bookmark) {
      const res = await dispatch(JsonAPI.delete({
        type: 'bookmarks',
        id: bookmark.id
      }))
      res.ok ? customAlert(bookmarkRemovedAlert, false) : customAlert('Error removing saved item', true)
    } else {
      const res = await dispatch(JsonAPI.create({
        type: 'bookmarks',
        ownerId: userId,
        bookmarkType,
        bookmarkTarget: targetId
      }))
      res.ok ? customAlert(bookmarkAddedAlert, false) : customAlert('Error saving', true)
    }
  }

  let textToDisplay = text
  if (bookmark) {
    if (savedText) {
      textToDisplay = savedText
    } else {
      textToDisplay = 'Saved'
    }
  }

  return (
    <Button
      variant-="text"
      startIcon={(
        <Heart
          sx={{
            color: 'primary.main',
            fill: bookmark ? theme.palette.primary.main : 'none'
          }}
        />
      )}
      onClick={() => onSave()}
      {...rest}
    >
      {textToDisplay}
    </Button>
  )
}

BookmarkButton.defaultProps = {
  bookmark: null,
  savedText: null
}

BookmarkButton.propTypes = {
  bookmark: PropTypes.object,
  targetId: PropTypes.string.isRequired,
  bookmarkType: PropTypes.string.isRequired,
  bookmarkAddedAlert: PropTypes.string.isRequired,
  bookmarkRemovedAlert: PropTypes.string.isRequired,
  savedText: PropTypes.string,
  text: PropTypes.string.isRequired

}

export default BookmarkButton
