import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { simpleHttpFetch } from 'utils-em'

const TipaltiCodeGenerate = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')
  const [loadingLink, setLoadingLink] = React.useState(false)
  const [validCode, setValidCode] = React.useState(code)
  const [loadingTokens, setLoadingTokens] = React.useState(false)
  const [processComplete, setProcessComplete] = React.useState(false)

  const loadApplicationAuthLink = async () => {
    setLoadingLink(true)
    const json = await simpleHttpFetch(
      `${__API_HOST__}/v1/tipalti/application-auth`
    )
    if (json?.authUrl) {
      window.open(json.authUrl, '_blank')
    }
    setLoadingLink(false)
  }

  const sendCodeForTokens = async (submitCode) => {
    if (submitCode) {
      const data = { code: submitCode }
      await simpleHttpFetch(
        `${__API_HOST__}/v1/tipalti/return?code=${encodeURI(submitCode)}`,
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
      setProcessComplete(true)
    }
  }

  React.useEffect(() => {
    if (code && !loadingTokens) {
      setValidCode(code)
      sendCodeForTokens(code)
      setLoadingTokens(true)
    }
  }, [code])

  return (
    <Box sx={{ width: '100%', m: 2, mt: 8 }}>
      <Typography sx={{ fontSize: '20px', m: 1 }}>
        Tipalti Auth Code Generation
      </Typography>
      {
        processComplete
          ? (
            <Box>
              <Typography>All done, see you next year</Typography>
            </Box>
            ) : (
              <Box>
                <Box>
                  {
                    // STEP 1: this just creates a link on the back end. Opens browser to Tipalti to create auth code. If running on local, you will need to copy and paste the parameters from the redirect into the current browser
                    !validCode && (
                      <Button disabled={loadingLink} onClick={loadApplicationAuthLink}>Step 1: Click to Generate Auth link</Button>
                    )
                  }
                </Box>
                <Box>
                  {
                    // Step 2: This submits the access code to the backend to generate a refresh and access token, these are stored in the DB.
                    // The refresh token lasts about 15 months but we are planning to renew every year.
                    // If you change permissions of the API call on the backend, you will need to run through this process again.
                    validCode && (
                      <Button disabled={loadingTokens} onClick={() => sendCodeForTokens(validCode)}>Step 2: Press to generate new Refresh and Access tokens</Button>
                    )
                  }
                </Box>
              </Box>
            )
      }
    </Box>
  )
}

export default TipaltiCodeGenerate
