/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Paper, Tooltip } from '@mui/material'
import Archive from '@mui/icons-material/Archive'
import Create from '@mui/icons-material/Create'
import Delete from '@mui/icons-material/Delete'
import Approval from '@mui/icons-material/Approval'

import { ArchiveDivisionDialog, CustomerUserTooltips } from 'components'
import { openDialog } from 'utils-em'

import TreeLevel from '../TreeLevel'
import TeamDeleteDialog from '../TeamDeleteDialog'

const styles = {
  treeNode: {
    listStyle: 'none',
    margin: 0,
    position: 'relative',
    display: 'table-cell',
    padding: '.5em 0',
    verticalAlign: 'top',

    '&:before': {
      outline: 'solid 0.5px #bbb',
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0
    },

    '&:first-child:before': {
      left: '50%'
    },

    '&:last-child:before': {
      right: '50%'
    }
  },

  treeNodeContent: {
    cursor: 'pointer',
    userSelect: 'none',
    fontWeight: 'bold',
    backgroundColor: 'white',
    borderRadius: '0.2em',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    display: 'inline-block',
    margin: '1.4em .2em 1.5em',
    padding: '1em',
    position: 'relative',

    '&:before': {
      top: '-1.9em',
      outline: 'solid 0.5px #bbb',
      content: '""',
      height: '1.8em',
      left: '50%',
      position: 'absolute'
    }
  },
  userList: {
    padding: 0,
    margin: 0,
    listStyleType: 'none'
  },

  userListItem: {
    marginBottom: '0.5em'
  },

  userBlock: {
    display: 'inline-block',
    padding: '0.25em 0.5em',
    margin: '0 0.2em'
  }

}

const TreeNode = ({ compact, editTeam, division, showInactive }) => {
  const [nodeExpanded, setNodeExpanded] = React.useState(false)
  const customerUsers = division?.customerUsers?.filter((cu) => showInactive || cu.isActive) || []
  const { isArchived } = division

  const showDetailsIfInNormalMode = () => {
    if (editTeam == null) {
      setNodeExpanded(!nodeExpanded)
    }
  }

  const showBorderBasedOnUserType = (userType) => {
    switch (userType) {
      case 'program_owner':
        return '0.33em solid #b2481b'
      case 'manager':
        return '0.33em solid #1976d2'
      default:
        return '0.33em solid #bbb'
    }
  }

  return (
    <Box sx={{ ...styles.treeNode }}>
      <Box
        component="span"
        role="button"
        tabIndex={0}
        onClick={showDetailsIfInNormalMode}
        sx={{
          ...styles.treeNodeContent,
          fontSize: compact ? '0.85em' : null,
          padding: compact ? '0.5em' : null,
          margin: compact ? '1.4em .2em 1.9em' : null
        }}
      >
        {division.displayName}
        {division.approvalRequired &&
          (
            <Tooltip title="Manager approval required">
              <Approval style={{ flex: '1 1 auto', display: 'block' }} />
            </Tooltip>
          )}
        {editTeam !== null
          ? (
            <Box sx={{ paddingTop: '0.33em' }}>
              <Box sx={{ padding: '0.2em 0' }}>
                <Button
                  fullWidth
                  onClick={() => editTeam(division)}
                  color="primary"
                  size="small"
                  variant="outlined"
                  startIcon={<Create />}
                >
                  Edit
                </Button>
              </Box>
              <Box sx={{ padding: '0.2em 0' }}>
                <Button
                  fullWidth
                  onClick={() => openDialog(<ArchiveDivisionDialog divisionWithHierarchy={division} />)}
                  color="warning"
                  size="small"
                  variant="outlined"
                  startIcon={<Archive />}
                >
                  {isArchived ? 'Unarchive' : 'Archive'}
                </Button>
              </Box>
              <Box sx={{ padding: '0.2em 0' }}>
                <Button
                  fullWidth
                  onClick={() => openDialog(<TeamDeleteDialog teamToDelete={division} />)}
                  color="secondary"
                  size="small"
                  variant="outlined"
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              </Box>
            </Box>
            )
          : null}
      </Box>
      {nodeExpanded
        ? (
          <Box
            sx={{
              marginBottom: '1.66em'
            }}
          >
            {customerUsers?.length > 0
              ? (
                <Box component="ul" sx={{ ...styles.userList }}>
                  {customerUsers.sort((a, b) => a.roleType.localeCompare(b.roleType)).map((user) => (
                    <Box component="li" key={`user-${user.id}`} sx={{ ...styles.userListItem }}>
                      <Paper
                        style={{
                          borderLeft: showBorderBasedOnUserType(user.roleType),
                          fontSize: compact ? '0.85em' : 'inherit',
                          padding: compact ? '0.125em 0.25em' : 'inherit'
                        }}
                        sx={{ ...styles.userBlock }}
                      >
                        {user.fullName}
                        <CustomerUserTooltips customerUser={user} />
                      </Paper>
                    </Box>
                  ))}
                </Box>
                )
              : (
                <Box component="span" sx={{ fontSize: compact ? '0.85em' : 'inherit', fontStyle: 'italic' }}>
                  Empty
                </Box>
                )}
          </Box>
          )
        : null}
      {division.children.length > 0
        ? (
          <TreeLevel
            compact={compact}
            editTeam={editTeam}
            divisions={division.children}
            showInactive={showInactive}
          />
          )
        : null}
    </Box>
  )
}

TreeNode.defaultProps = {
  compact: false,
  editTeam: null,
  showInactive: false
}

TreeNode.propTypes = {
  division: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  editTeam: PropTypes.func,
  showInactive: PropTypes.bool
}

export default TreeNode
