import React from 'react'
import PropTypes from 'prop-types'
import {
  useJsonAPIUpsert
} from 'hooks'
import {
  ApproveSurveyAssignmentQualityDialog,
  ConfirmDialog,
  PopoverButton,
  SurveyAssignmentDialog,
} from 'components'
import {
  getActionKeysByAssignmentStatus,
  errorAlert,
  openDialog,
  closeDialog,
  simpleHttpFetch,
} from 'utils-em'

const SurveyAssignmentActionsButton = ({ assignment, excludeView, button, onUpdate }) => {
  const { upsert, isLoading } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const { paymentStatus } = assignment
  const [quality, setQuality] = React.useState('')
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const patchData = {
    approve: {
      approvedDate: new Date().toISOString(),
      adminDisqualifiedDate: null,
      responseQuality: quality,
      publishedDate: quality !== 'weak' ? new Date().toISOString() : null
    },
    disqualify: { adminDisqualifiedDate: new Date().toISOString(), approvedDate: null, publishedDate: null },
    archive: { archivedDate: new Date().toISOString(), publishedDate: null },
    unarchive: { archivedDate: null }
  }

  const submitPayment = async () => {
    const data = { surveyAssignmentId: assignment.id }
    const res = await simpleHttpFetch(
      `${__API_HOST__}/v1/tipalti/create`,
      { method: 'POST', body: JSON.stringify(data) }
    )
    if (!res.ok) errorAlert({ message: 'Payment submission failed' })
  }

  const handleSave = async (action) => {
    if (action === 'pay') {
      await submitPayment()
    } else {
      await upsert({
        type: 'surveyAssignments',
        id: assignment.id,
        ...patchData?.[action]
      })
    }
    onUpdate && onUpdate({ assignmentId: assignment.id, assignment, action })
    setIsDialogOpen(false)
    closeDialog()
  }

  const handleView = () => openDialog(<SurveyAssignmentDialog assignmentId={assignment.id} onUpdate={onUpdate} />)

  const openApproveDialog = () => {
    setIsDialogOpen(true)
  }

  const openConfirmDialog = (action) => {
    const actionText = action.charAt(0).toUpperCase() + action.slice(1)
    const descriptionMap = {
      approve: 'approve',
      disqualify: 'disqualify',
      archive: 'archive',
      unarchive: 'unarchive',
      pay: 'submit payment for'
    }
    openDialog(
      <ConfirmDialog
        title={actionText}
        description={`Are you sure you want to ${descriptionMap[action]} this survey assignment?`}
        actions={[
          {
            name: actionText,
            preventClose: true,
            action: async () => handleSave(action)
          }
        ]}
      />,
      action
    )
  }

  const actions = [
    { key: 'view', text: '👁️ View', action: handleView },
    { key: 'approve', text: '✅ Approve', action: async () => openApproveDialog() },
    { key: 'disqualify', text: '❌ Disqualify', action: async () => openConfirmDialog('disqualify') },
    { key: 'archive', text: '📁 Archive', action: async () => openConfirmDialog('archive') },
    { key: 'unarchive', text: '📁 Unarchive', action: async () => openConfirmDialog('unarchive') },
    {
      key: 'pay',
      text: `💸 ${!paymentStatus ? 'Submit payment' : 'Re-submit payment'}`,
      action: async () => openConfirmDialog('pay'),
      hidden: paymentStatus === 'complete' || !assignment.completedDate
    }
  ]

  const actionsToShowKeys = getActionKeysByAssignmentStatus(assignment)
  const actionsToShow = actionsToShowKeys.map((key) => actions.find((a) => a.key === key))

  return (
    <>
      <PopoverButton actions={actionsToShow} button={button} disabled={isLoading} />
      {isDialogOpen && (
        <ApproveSurveyAssignmentQualityDialog
          quality={quality}
          setQuality={setQuality}
          handleSave={handleSave}
          handleClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  )
}

SurveyAssignmentActionsButton.defaultProps = {
  excludeView: false,
  button: null,
  onUpdate: null
}

SurveyAssignmentActionsButton.propTypes = {
  assignment: PropTypes.object.isRequired,
  excludeView: PropTypes.bool,
  button: PropTypes.element,
  onUpdate: PropTypes.func,
}

export default SurveyAssignmentActionsButton
