import PropTypes from 'prop-types'
import React from 'react'
import { find } from 'lodash'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem
} from '@mui/material'
import { OrganizationSelector } from 'components'
import AccountPicker from '../AccountPicker'
import DivisionPicker from '../DivisionPicker'

const ChargeSkuDialog = ({
  contract,
  contractSku,
  skus,
  handleClose,
  saveContractSku,
  showSuccessMessage,
  showErrorMessage
}) => {
  const [unitCount, setUnitCount] = React.useState(contractSku.unitCount || '')
  const [description, setDescription] = React.useState(contractSku.description || '')
  const [sourceObjectId, setSourceObjectId] = React.useState(contractSku.sourceObjectId || '')
  const [sourceObjectType, setSourceObjectType] = React.useState(contractSku.sourceObjectType || '')
  const [skuId, setSkuId] = React.useState(contractSku.skuId || '')
  const [account, setAccount] = React.useState(contractSku.account)
  const [divisions, setDivisions] = React.useState(contractSku.divisions || [])
  const [isRefund, setIsRefund] = React.useState(contractSku.isRefund || false)
  const [organization, setOrganization] = React.useState(contractSku.organization)
  const contractSkuHasOrganizationId = contractSku.organizationId

  const handleSubmit = () => {
    const chargeToSku = {
      skuId,
      unitCount,
      description,
      contractId: contract.id,
      isRefund,
      divisions,
      account,
      organization: organization ? { type: 'organizations', id: organization.id } : organization,
    }

    if (sourceObjectId !== '') { chargeToSku.sourceObjectId = sourceObjectId }
    if (sourceObjectType !== '') { chargeToSku.sourceObjectType = sourceObjectType }

    if (contractSku.id) {
      chargeToSku.id = contractSku.id
    }
    saveContractSku(chargeToSku).then((res) => {
      if (!res.ok) {
        showErrorMessage({
          text: `Sku Charge failed: ${res.error.errors.map((e) => e.detail).join('; ')}`,
          timeout: 2000
        })
      } else {
        showSuccessMessage({
          text: 'Sku Charged',
          timeout: 2000
        })
        handleClose()
      }
    })
  }

  const addDivision = (division) => {
    setDivisions(divisions.concat(division))
  }

  const removeDivision = (division) => {
    setDivisions(divisions.filter((d) => d.id !== division.id))
  }

  const matchingSkus = contract.contractSkuAllotments.map((csa) => find(skus, (sku) => parseInt(sku.id, 10) === csa.skuId))

  return (
    <Dialog
      open
      maxWidth="lg"
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>{`${contractSku.id ? 'Edit' : 'Create'} Charge`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>Sku</FormLabel>
                <SelectValidator
                  validators={['required']}
                  errorMessages={['Required Field']}
                  value={skuId}
                  onChange={(event) => { setSkuId(parseInt(event.target.value, 10)) }}
                >
                  { matchingSkus.map((sku) => (
                    <MenuItem key={sku.id} value={sku.id}>{sku.name}</MenuItem>
                  ))}
                </SelectValidator>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Unit Count"
                value={unitCount}
                onChange={(event) => { setUnitCount(event.target.value) }}
                margin="normal"
                validators={['required', 'isNumber']}
                errorMessages={['Required Field', 'Must be a number']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Description"
                value={description}
                onChange={(event) => { setDescription(event.target.value) }}
                margin="normal"
                validators={['required']}
                errorMessages={['Required Field']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Source Object Type"
                value={sourceObjectType}
                onChange={(event) => { setSourceObjectType(event.target.value) }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Source Object Id"
                value={sourceObjectId}
                onChange={(event) => { setSourceObjectId(event.target.value) }}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <DivisionPicker
                label="Teams"
                compact
                customerId={contract.customerId}
                addDivision={addDivision}
                removeDivision={removeDivision}
                selectedDivisions={divisions}
              />
            </Grid>
            <Grid item xs={6}>
              {contractSkuHasOrganizationId !== null
                ? (
                  <OrganizationSelector
                    placeholder="Company name"
                    noOptionsText="No company name"
                    variant="outlined"
                    label="Company name"
                    organizationId={contractSku.organizationId}
                    organizationName={organization && organization.name}
                    handleChange={(org) => {
                      if (org) {
                        setOrganization(org)
                      }
                    }}
                    textFieldVariant="outlined"
                    multipleSelect={false}
                  />
                  )
                : (
                  <AccountPicker
                    label="Account"
                    customerId={contract.customerId}
                    accountId={parseInt((account || {}).id, 10)}
                    onChange={(newAccount) => { setAccount(newAccount) }}
                    onClear={() => { setAccount(null) }}
                  />
                  )}
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={isRefund}
                    label="Is Refund"
                    onChange={() => { setIsRefund(!isRefund) }}
                  />
                  )}
                label="Is Refund"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

ChargeSkuDialog.defaultProps = {
  contractSku: null
}

ChargeSkuDialog.propTypes = {
  contract: PropTypes.object.isRequired,
  contractSku: PropTypes.object,
  skus: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveContractSku: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default ChargeSkuDialog
