import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material'

import CalendarToday from '@mui/icons-material/CalendarToday'
import CheckCircle from '@mui/icons-material/CheckCircle'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import Close from '@mui/icons-material/Close'
import Hail from '@mui/icons-material/Hail'

import {
  constants,
  formatDate,
  useDebounce
} from 'utils-em'

import EngagementContractUsageDialog from '../../../../../../components/EngagementContractUsageDialog'

const styles = {
  container: {
    height: '60vh'
  },
  tableRow: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  tableBorder: {
    border: '1px solid #ccc',
    fontWeight: 'bold'
  },
  tableSortLabel: {
    color: 'gray'
  }
}

const EngagementsTable = ({ getEngagements, engagements, engagementsCount, searchFilters, openDialog }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE)
  const [sortDir, setSortDirection] = useState('desc')
  const [sortCol, setSortColumn] = useState('startDate')

  const makeRequest = () => {
    getEngagements(
      rowsPerPage,
      page + 1,
      searchFilters,
      `${sortDir === 'desc' ? '-' : ''}${sortCol}`
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const debouncedFilters = useDebounce(searchFilters, 500)
  useEffect(() => {
    makeRequest()
  }, [debouncedFilters, page, rowsPerPage])

  // reset page when user updates a search filter
  useEffect(() => {
    setPage(0)
  }, [searchFilters, sortDir, sortCol])

  const sortOnColumn = (columnName) => {
    setSortDirection(sortCol !== columnName || sortDir === 'asc' ? 'desc' : 'asc')
    setSortColumn(columnName)
    makeRequest()
  }

  const displayEngagementState = (state) => {
    let stateIcon
    let stateLabel

    switch (state) {
      case 'scheduled':
        stateLabel = 'Scheduled'
        stateIcon = <CalendarToday />
        break
      case 'active':
        stateLabel = 'Active'
        stateIcon = <CheckCircle />
        break
      case 'canceled':
        stateLabel = 'Canceled'
        stateIcon = <RemoveCircle />
        break
      case 'closed':
        stateLabel = 'Closed'
        stateIcon = <Close />
        break
      case 'proposed':
        stateLabel = 'Proposed'
        stateIcon = <Hail />
        break
      case 'proposal-canceled':
        stateLabel = 'Proposal canceled'
        stateIcon = <Close />
        break
    }

    if (!stateIcon) return null
    return (
      <Chip
        size="small"
        icon={stateIcon}
        label={stateLabel}
      />
    )
  }

  const renderTwoOhRow = (engagement) => (
    <>
      <TableCell>{engagement.customerUser && engagement.customerUser.customer.name}</TableCell>
      <TableCell>{engagement.organization && engagement.organization.name}</TableCell>
      <TableCell>{engagement.advisor && engagement.advisor.fullName}</TableCell>
      <TableCell>{engagement.customerUser && engagement.customerUser.fullName}</TableCell>
    </>
  )

  return (
    <Paper>
      <Toolbar>
        <Typography style={{ flex: '1 1 100%' }} variant="h6" component="div">
          Engagements
        </Typography>
      </Toolbar>
      <TableContainer sx={{ ...styles.container }}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                <TableSortLabel
                  direction={sortDir}
                  onClick={() => sortOnColumn('state')}
                >
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                <TableSortLabel
                  direction={sortDir}
                  onClick={() => sortOnColumn('account.customer.name')}
                >
                  Customer
                </TableSortLabel>
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                Account
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                Advisors
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                Advisor Request Customer Users
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                SKU Unit Count
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                <TableSortLabel
                  direction={sortDir}
                  onClick={() => sortOnColumn('startDate')}
                >
                  Start Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                <TableSortLabel
                  direction={sortDir}
                  onClick={() => sortOnColumn('dateClosed')}
                >
                  Close Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                padding="normal"
                sx={{ ...styles.tableBorder }}
              >
                Last Activity
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {engagements.map((engagement, idx) => (
              <TableRow
                hover
                key={engagement.id}
                component={Link}
                to={`/admin/engagements/${engagement.id}`}
              >
                <TableCell>{displayEngagementState(engagement.state)}</TableCell>
                {renderTwoOhRow(engagement)}
                <TableCell>
                  {engagement.charges && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      openDialog(<EngagementContractUsageDialog
                        charges={engagement.charges}
                      />)
                    }}
                  >
                    {`${engagement.charges.reduce((acc, cs) => acc + cs.unitCount, 0)} credit(s)`}
                  </button>
                  )}
                </TableCell>
                <TableCell>{formatDate(engagement.startDate)}</TableCell>
                <TableCell>{formatDate(engagement.dateClosed)}</TableCell>
                <TableCell>{formatDate(engagement.lastActivity)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={engagementsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

EngagementsTable.propTypes = {
  getEngagements: PropTypes.func.isRequired,
  engagements: PropTypes.array.isRequired,
  searchFilters: PropTypes.array.isRequired,
  engagementsCount: PropTypes.number.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default EngagementsTable
