import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Avatar,
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  useTheme
} from '@mui/material'
import { Menu as MenuIcon } from 'icons'

const styles = (theme) => ({
  drawerOpen: {
    width: theme.navSideBar.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.navSideBar.drawerWidthClosed,
    [theme.breakpoints.up('sm')]: {
      width: theme.navSideBar.drawerWidthClosed
    }
  }
})

const NavigationSidebar = ({
  links,
  sideNavOpen,
  toggleSideNav
}) => {
  const { pathname } = useLocation()
  const theme = useTheme()
  return (
    <Drawer
      variant="permanent"
      PaperProps={{ sx: { border: 'none' } }}
      sx={{
        zIndex: theme.navSideBar.zIndex,
        width: theme.navSideBar.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(sideNavOpen ? styles(theme).drawerOpen : styles(theme).drawerClose),
        '& .MuiDrawer-paper': {
          // theme argument is required for sx children
          ...(sideNavOpen ? styles(theme).drawerOpen : styles(theme).drawerClose)
        }
      }}
    >
      <Box sx={{ borderRight: 1, borderColor: 'neutral.lightGrey', height: '100%' }}>
        <Box sx={{
          bgcolor: theme.header.color,
          minHeight: theme.header.height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          borderColor: 'neutral.disabled'
        }}
        >
          <IconButton
            onClick={toggleSideNav}
            size="large"
            sx={{
              p: 1,
              mr: `calc(${theme.navSideBar.drawerWidthClosed}px/2 - 24px/2 - 8px)`,
              path: { stroke: theme.header.secondaryColor }
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Divider sx={{ ...theme.header.divider }} />
        <List sx={{ mt: 2 }}>
          {links.map((link) => {
          // shorthand is not used for color due to setting child props (ie '& path')
            const isSelected = link.href === pathname
            const color = ({ palette }) => (isSelected ? palette.primary.main : palette.brand.navy)
            const bgcolor = isSelected ? 'primary.lightest' : 'transparent'
            const variant = isSelected ? 'bodyBold' : 'body1'
            return (
              <ListItem
                disableGutters
                key={link.text}
                component={Link}
                to={link.href}
                sx={{
                  '&:hover': {
                    '& .MuiAvatar-root': {
                      bgcolor: !isSelected ? theme.palette.brand.lightNavy : undefined
                    },
                    '& .MuiTypography-root': {
                      ...(!isSelected ? theme.typography.bodyBold : null)
                    }
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '32px', pl: 2.5, pr: 2.5, '& path': { stroke: color } }}>
                  <Avatar sx={{ color, bgcolor, width: '32px', height: '32px' }}>
                    {link.icon}
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={(
                  <Typography variant={variant} sx={{ color }}>
                    {link.text}
                  </Typography>
              )}
                />
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Drawer>
  )
}

NavigationSidebar.defaultProps = {
  links: []
}

NavigationSidebar.propTypes = {
  links: PropTypes.array,
  sideNavOpen: PropTypes.bool.isRequired,
  toggleSideNav: PropTypes.func.isRequired
}

export default NavigationSidebar
