import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material'
import { useJsonAPIUpsert, useJsonAPIGetAll } from 'hooks'

import {
  closeDialog,
} from 'utils-em'
import { TopicAutocomplete } from 'components'

const QuestionsTopicsDialog = ({ reload, question }) => {
  const { upsert, isLoading } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const { loaded } = useJsonAPIGetAll('topics')
  const [topicsData, setTopicsData] = React.useState(question?.topics || [])

  const updateQuestionTopic = async () => {
    const updateQuestion = {
      id: question.id,
      topics: {
        data: topicsData.map((topic) => ({ id: topic.id, type: 'topics' }))
      }
    }
    await upsert({
      type: 'questions',
      relationshipNames: 'topics',
      ...updateQuestion,
    })
    reload.setReload(reload.value + 1)
    closeDialog()
  }

  if (!loaded) {
    return null
  }

  return (
    <Dialog
      open
      onClose={closeDialog}
      size="xl"
    >
      <DialogTitle>Edit Topics</DialogTitle>
      <DialogContent sx={{ minWidth: '600px' }}>
        <Box p={2}>
          <TopicAutocomplete
            label="Question Topics"
            multiple
            onChange={(event, topic) => setTopicsData(topic)}
            helperText="Start typing to select a topic"
            initialTopics={topicsData}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>Cancel</Button>
        <Button variant="text" disabled={isLoading} onClick={updateQuestionTopic}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

QuestionsTopicsDialog.propTypes = {
  reload: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
}

export default QuestionsTopicsDialog
