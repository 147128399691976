import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Box,
  IconButton,
  Tooltip
} from '@mui/material'
import { green } from '@mui/material/colors'
import AutoFixNormal from '@mui/icons-material/AutoFixNormal'
import Backspace from '@mui/icons-material/Backspace'
import Cancel from '@mui/icons-material/Cancel'
import CheckCircle from '@mui/icons-material/CheckCircle'
import PersonAdd from '@mui/icons-material/PersonAdd'
import Warning from '@mui/icons-material/Warning'
import { LoadingButton, OrgSelectorDialog } from 'components'
import { useJsonAPIGetAll } from 'hooks'
import { JsonAPI } from 'store'
import {
  DataGrid,
  GridToolbar
} from '@mui/x-data-grid'
import {
  closeDialog,
  customAlert,
  openDialog,
  constants
} from 'utils-em'
import EditCustomerUserDialog from '../../CustomerUsers/components/EditCustomerUserDialog'

const AccountValidateTable = ({ jobId, customerId, onSubmit, allowSubmit }) => {
  const dispatch = useDispatch()
  const [rows, setRows] = React.useState([])
  const [finalizing, setFinalizing] = React.useState(false)
  const [pageSize, setPageSize] = React.useState(constants.DEFAULT_DATAGRID_ROWS_PER_PAGE)
  const { objects: dataIngestionItems, loaded } = useJsonAPIGetAll('dataIngestionItems', {
    filters: [
      {
        name: 'jobId',
        op: 'eq',
        val: jobId
      }
    ]
  })
  React.useEffect(() => {
    if (loaded) {
      setRows(dataIngestionItems)
    } else {
      setRows([])
    }
  }, [loaded])

  const handleFinalize = async () => {
    setFinalizing(true)
    try {
      const res = await fetch(`${__API_HOST__}/v1/customers/${jobId}/upload-accounts-unlimited`, {
        method: 'POST',
        credentials: 'include'
      })
      if (res.ok) {
        onSubmit()
      } else {
        customAlert('Something went wrong, please contact support', true)
      }
    } catch (error) {
      customAlert('Something went wrong, please contact support', true)
    }
    setFinalizing(false)
  }

  const excludeAccount = (row) => {
    const newStatus = row.status === 'discarded' ? 'processed' : 'discarded'
    saveRow({ ...row, status: newStatus })
    setRows(rows.map((newRow) => ((newRow.id === row.id)
      ? {
          ...newRow,
          status: newStatus
        } : newRow)))
  }

  const saveRow = (row) => {
    dispatch(JsonAPI.save({ ...row, jsonObjects: ['data'] }))
      .then((resp) => {
        if (!resp.ok) {
          customAlert('Something went wrong, please contact support.', true)
        }
      })
  }

  const getWarningsForRow = (row) => {
    const warnings = []
    if (!row.data?.organization) {
      warnings.push('Could not standardize')
    }
    if (!row.data?.user) {
      warnings.push('Could not find attached user')
    }
    return warnings
  }

  const onUserCreation = (user) => {
    if (user.id) {
      // update the other rows with the same user name
      const newRows = rows.map((row) => {
        const [firstName, lastName] = row.data?.accountOwnerName.split(' ')
        if (firstName === user.firstName && lastName === user.lastName) {
          const newRow = { ...row, data: { ...row.data, user } }
          saveRow(newRow)
          return newRow
        }
        return row
      })
      setRows(newRows)
      closeDialog()
    }
  }

  const onOrgChange = (row, selectedOrg) => {
    if (selectedOrg) {
      const newRows = rows.map((r) => {
        if (r.data?.accountName === row.data?.accountName) {
          const newRow = { ...r, data: { ...r.data, organization: { ...selectedOrg, score: 100 } } }
          saveRow(newRow)
          return newRow
        }
        return r
      })
      setRows(newRows)
    }
    closeDialog()
  }

  // return true if any row has a warning
  const hasWarnings = rows.some((row) => row.status !== 'discarded' && getWarningsForRow(row).length > 0)

  const renderPercentageBar = (value) => {
    let barColor = '#088208a3'
    if (value < 80 && value > 50) barColor = '#efbb5aa3'
    if (value < 50) barColor = '#f44336'

    const style = {
      height: '100%',
      maxWidth: `${value}%`,
      backgroundColor: barColor
    }
    return (
      <Box sx={{
        border: '1px solid',
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 26,
      }}
      >
        <Box sx={{
          position: 'absolute',
          width: '100%',
          lineHeight: '24px',
          display: 'flex',
          justifyContent: 'center'
        }}
        >
          {`${value}%`}
        </Box>
        <Box sx={style} />
      </Box>
    )
  }

  const getAllColumns = () => {
    const columns = [
      {
        field: 'warnings',
        headerName: 'Warnings',
        width: 100,
        valueGetter: ({ row }) => getWarningsForRow(row).length > 0,
        renderCell: ({ row }) => {
          const warnings = getWarningsForRow(row)
          return warnings.length > 0 ? (
            <Tooltip title={warnings.join(', ')}>
              <Warning color="error" />
            </Tooltip>
          ) : '-'
        }
      },
      { field: 'name', headerName: 'Name', width: 300, valueGetter: ({ row }) => row.data?.accountName },
      { field: 'org', headerName: 'Organization', width: 300, valueGetter: ({ row }) => row.data?.organization?.name },
      {
        field: 'inNetwork',
        headerName: 'In-Network',
        width: 130,
        type: 'boolean',
        valueGetter: ({ row }) => row.data?.organization?.originalSource === 'em1k',
        renderCell: ({ row }) => (
          row.data?.organization?.originalSource === 'em1k'
            ? <CheckCircle style={{ color: green[500] }} />
            : <Cancel color="error" />
        )
      },
      {
        field: 'orgScore',
        headerName: 'Org score',
        width: 150,
        type: 'number',
        valueGetter: ({ row }) => row.data?.organization?.score,
        renderCell: ({ row }) => (renderPercentageBar(row.data?.organization?.score || 0))
      },
      { field: 'pcuName', headerName: 'Primary customer user', width: 250, valueGetter: ({ row }) => row.data?.accountOwnerName },
      ...(allowSubmit ? [{
        field: 'actions',
        headerName: 'Actions',
        width: 350,
        renderCell: ({ row }) => {
          const [firstName, lastName] = row.data?.accountOwnerName.split(' ')
          const team = row.data?.team
          const customerUser = row.data?.user?.id ? row.data?.user : {
            firstName,
            lastName,
            email: row.data?.accountOwnerEmail,
            customerId,
            ...(team && { divisions: [{ ...team, type: 'divisions' }] })
          }
          return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 5 }}>
              <Tooltip title="Add user">
                <IconButton
                  sx={{ color: 'primary.main' }}
                  onClick={() => {
                    openDialog(
                      <EditCustomerUserDialog
                        customerId={customerId}
                        customerUser={customerUser}
                        handleUserCreateComplete={onUserCreation}
                      />
                    )
                  }}
                >
                  <PersonAdd />
                </IconButton>
              </Tooltip>
              <Tooltip title="Change organization">
                <IconButton
                  sx={{ color: 'primary.main' }}
                  onClick={() => {
                    openDialog(<OrgSelectorDialog
                      onSubmit={(org) => onOrgChange(row, org)}
                      selectedOrg={row.data?.organization}
                    />)
                  }}
                >
                  <AutoFixNormal />
                </IconButton>
              </Tooltip>
              <Tooltip title={row.status === 'discarded' ? 'Include account from import' : 'Exclude account from import'}>
                <IconButton
                  sx={{ color: 'primary.main' }}
                  onClick={() => excludeAccount(row)}
                >
                  <Backspace />
                </IconButton>
              </Tooltip>
            </Box>
          )
        }
      }] : [])
    ]

    return columns
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ flexGrow: 1, '& .discarded': { opacity: '0.5' } }}>
        <DataGrid
          // use v5 documentation: https://v5.mui.com/x/react-data-grid
          autoHeight
          columns={getAllColumns()}
          rows={rows}
          components={{ Toolbar: GridToolbar }}
          getRowClassName={(params) => (params.row.status === 'discarded' ? 'discarded' : '')}
          columnBuffer={25}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={constants.DEFAULT_DATAGRID_ROWS_PER_PAGE_OPTIONS}
          onPageSizeChange={(size) => size !== pageSize && setPageSize(size)}
          sx={{
            '& .MuiDataGrid-menuIconButton': { zIndex: 9999 }
          }}
        />
      </Box>
      {allowSubmit && (
        <Tooltip title={hasWarnings ? 'Please fix all warnings before finalizing' : ''}>
          <LoadingButton
            variant="contained"
            disabled={hasWarnings || finalizing}
            loading={finalizing}
            onClick={handleFinalize}
            sx={{ mt: 2, ml: 2 }}
          >
            Finalize
          </LoadingButton>
        </Tooltip>
      )}
    </Box>
  )
}

AccountValidateTable.propTypes = {
  jobId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allowSubmit: PropTypes.bool.isRequired
}

export default AccountValidateTable
