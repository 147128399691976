import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import Flags from 'country-flag-icons/react/3x2'
import { uniqBy } from 'lodash'
import { Controller } from 'react-hook-form'

import countryData from '../MuiPhoneNumber/country_data'

const getCountries = (unitedStatesFirstOption) => {
  let countries = uniqBy(
    countryData.rawAllCountries.map((country) => ({
      name: country[0],
      code: country[2]
    })),
    ({ name }) => name
  )

  if (unitedStatesFirstOption) {
    const unitedStatesIdx = countries.findIndex((c) => c.code === 'us')
    const unitedStatesElementRemoved = countries.splice(unitedStatesIdx, 1)[0]
    countries = [unitedStatesElementRemoved, ...countries]
  }

  return countries
}
const FormCountrySelector = ({ form, name, label, required, defaultValue, unitedStatesFirstOption, ...rest }) => {
  const countries = getCountries(unitedStatesFirstOption)
  const [selectedValue, setSelectedValue] = React.useState(defaultValue && countries.find((country) => country.code === defaultValue))
  const { register, formState: { errors }, control } = form
  const formProps = register(name, {
    required: required && 'Required field'
  })

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Autocomplete
            {...rest}
            {...formProps}
            options={countries}
            getOptionLabel={(country) => country.name}
            isOptionEqualToValue={(option, option2) => option?.code === option2?.code}
            value={selectedValue}
            onChange={(_, v) => {
              field.onChange(v?.code)
              setSelectedValue(v)
            }}
            renderOption={(props, option) => {
              const FlagComponent = Flags[option.code.toUpperCase()]

              if (unitedStatesFirstOption && option.code === 'us') {
                props.sx = { // eslint-disable-line
                  ...props.sx, // eslint-disable-line
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }
              }

              return (
                <Box component="li" {...props}>
                  {Boolean(FlagComponent) && (
                    <Box sx={{ width: '24px', height: '18px', mr: 1 }}>
                      <FlagComponent />
                    </Box>
                  )}
                  {option.name}
                </Box>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="large"
                variant="outlined"
                label={label}
                sx={{ backgroundColor: 'neutral.white' }}
                error={Boolean(errors[name])}
              />
            )}
          />
        )}
      />
      <Typography variant="tiny" color="error">{errors[name]?.message}</Typography>
    </Box>
  )
}

FormCountrySelector.defaultProps = {
  label: 'Country',
  defaultValue: null,
  unitedStatesFirstOption: true,
  required: false,
}

FormCountrySelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  form: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  unitedStatesFirstOption: PropTypes.bool,
}

export default FormCountrySelector
