import React from 'react'
import {
  Box,
  Typography,
  Divider,
} from '@mui/material'
import {
  useFeatureFlag
} from 'hooks'
import {
  WhiskerCard,
} from 'components'
import {
  ACCOUNT_PAGE_SECTION_DATA,
  getArtificialIntelligenceSurveyData
} from 'utils-em'
import SectionNotFound from '../SectionNotFound'
import { AccountPageContext } from '../AccountPageContext'
import DirectToPage from '../DirectToPage'
import AvatarWithTextCombo from '../AvatarWithTextCombo'
import AdvisorContext from '../AdvisorContext'

const ArtificialIntelligenceSection = () => {
  const directToPageEnabled = useFeatureFlag('direct_to_page_insights')
  const { accountPage, responses, responsesLoaded, loaded } = React.useContext(AccountPageContext)

  const aiPageData = ACCOUNT_PAGE_SECTION_DATA.find((sec) => sec.key === 'artificialIntelligence')

  const renderWhiskerCard = (module) => {
    const chartResponses = responses
      .filter((res) => (res.question?.topics || []).some((t) => t.key === module.key))
      .map((res) => parseInt(res.editedAnswerText || res.textAnswer, 10))

    if (chartResponses?.length === 0) return null

    const whiskerCardProps = {
      chartLow: Math.min(...chartResponses),
      chartMid: parseFloat((chartResponses.reduce((a, b) => a + b, 0) / chartResponses.length).toFixed(1)),
      chartHigh: Math.max(...chartResponses),
      textMin: module.textMin,
      textMax: module.textMax,
      title: module.title
    }

    return <WhiskerCard {...whiskerCardProps} sx={{ mb: 3 }} />
  }

  const renderQuoteOnlySection = (module, index) => (
    <Box key={module.key} sx={{ display: 'flex', flexDirection: 'column' }}>
      {index !== 0 && <Divider sx={{ mt: 5, mb: 5, borderColor: 'brand.paleSlate', borderBottomWidth: '1px' }} />}
      <AvatarWithTextCombo
        sx={{ px: 3, py: 1.5, pr: 2, mb: 2 }}
        backgroundColor="brand.paleSlate"
        icon={(<Typography variant="bodyBold" color="brand.darkNavy">{index + 1}</Typography>)}
        variant="tiny"
      />
      <Typography variant="tiny" sx={{ lineHeight: 2, ml: 3 }}>We asked advisors:</Typography>
      <Typography variant="bodyBold" color="neutral.black" mb={4} ml={3} mt={1}>{module.title}</Typography>
      <DirectToPage numberDefaultQuotesShown={3} organizationId={accountPage.organizationId} topicKey={module.key} />
    </Box>
  )

  if (loaded && responsesLoaded && (!directToPageEnabled || !getArtificialIntelligenceSurveyData(responsesLoaded, aiPageData, responses).length)) {
    return <SectionNotFound />
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {responsesLoaded && getArtificialIntelligenceSurveyData(responsesLoaded, aiPageData, responses).map((module, index) => {
        if (module.key === 'levelOfAdoption') {
          return (
            <>
              <AvatarWithTextCombo
                sx={{ px: 3, py: 1.5, pr: 2, mb: 2 }}
                backgroundColor="brand.paleSlate"
                icon={(<Typography variant="bodyBold" color="brand.darkNavy">{index + 1}</Typography>)}
                text={module.title}
                variant="tiny"
              />
              {accountPage.data?.artificialIntelligence?.title && <Typography variant="h2" color="neutral.black" sx={{ px: 3, py: 1.5, mb: 2 }}>{accountPage.data.artificialIntelligence.title}</Typography>}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ maxWidth: '50%' }}>
                  {renderWhiskerCard(aiPageData.modules.find((m) => m.key === 'levelOfAdoptionQuant'))}
                </Box>
                {
                  accountPage.data?.artificialIntelligence?.paragraph ? (
                    <Box sx={{ pl: 4, maxWidth: '50%' }}>
                      <AdvisorContext context={accountPage.data.artificialIntelligence.paragraph} />
                    </Box>
                  ) : (
                    <Box sx={{ flexGrow: 1 }} />
                  )
                }
              </Box>
              <DirectToPage numberDefaultQuotesShown={3} organizationId={accountPage.organizationId} topicKey="levelOfAdoption" />
            </>
          )
        }
        if (['impedingAIAdoption', 'aiUseCases'].includes(module.key)) {
          return renderQuoteOnlySection(module, index)
        }
        return null
      })}
    </Box>
  )
}

export default ArtificialIntelligenceSection
