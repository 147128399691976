import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material'
import moment from 'moment'
import {
  AddToCalendarButton
} from 'components'
import {
  Badge,
  Information,
  Phone,
  User
} from 'icons'

import {
  ENGAGEMENT_STEPS,
  ENGAGEMENT_COLOR_MAPPING,
  buildJsonApiOne,
  getAdvisorTitle,
  getEngagementMostRelevantTimeslot,
  getMostRecentProposal,
  formatDateTime,
  isProposalTimeslotsExpired,
  navigateByEvent,
  numberOfDaysBetweenDates
} from 'utils-em'
import IconTextCombo from '../IconTextCombo/IconTextCombo'

const EngagementActivityCard = ({ engagementId, engagementJsonApiType }) => {
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, engagementJsonApiType, engagementId))
  const user = useSelector(({ session }) => session)
  const isParticipant = engagement.participants && engagement.participants.some((e) => e.userId === user.id)
  const isPCU = engagement.customerUserId === user.id
  let tooltipText = ''

  const { advisor, customerUser } = engagement
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  const actionUrl = `/c/engagements/${engagement.id}`

  const daysToString = (days) => `${days} day${days === 1 ? '' : 's'}`

  const engagementStep = engagement.step
  if (isPCU) { tooltipText = 'You own this engagement' }
  if (isParticipant) { tooltipText = 'You are a participant on this engagement' }

  const renderCardAlert = () => {
    let text = null
    let icon = null

    switch (engagementStep) {
      // proposed
      case ENGAGEMENT_STEPS[0]: {
        text = 'Call proposal sent to advisor for review'
        icon = <Information sx={{ color: 'neutral.darkGrey' }} />
        break
      }
      // scheduling
      case ENGAGEMENT_STEPS[1]: {
        const activeProposal = getMostRecentProposal(engagement)
        const daysSinceProposal = activeProposal && numberOfDaysBetweenDates(activeProposal.dateCreated, new Date())
        const daysSinceEngagementCreation = numberOfDaysBetweenDates(engagement.dateCreated, new Date())
        if (activeProposal && isProposalTimeslotsExpired(activeProposal)) {
          icon = <Information sx={{ color: 'neutral.darkGrey' }} />
          text = 'All proposed times have expired'
        } else if ((!activeProposal && daysSinceEngagementCreation > 7) || (activeProposal && daysSinceProposal > 7)) {
          const daysOfNoActivity = activeProposal ? daysToString(daysSinceProposal) : daysToString(daysSinceEngagementCreation)
          icon = <Information sx={{ color: 'neutral.darkGrey' }} />
          text = `No scheduling activity in ${daysOfNoActivity}`
        }
        break
      }
      // call schedules
      case ENGAGEMENT_STEPS[2]:
      case ENGAGEMENT_STEPS[3]: {
        icon = <Phone sx={{ color: 'neutral.darkGrey' }} />
        text = (
          <>
            {`Call scheduled for ${formatDateTime(relevantTimeslot.startTime, { showTimezoneSuffix: true })}`}
            <AddToCalendarButton sx={{ ml: 2 }} timeslotId={relevantTimeslot.id} asLink addAsParticipant engagementId={engagementId} />
          </>
        )
        break
      }
      // feedback required
      case ENGAGEMENT_STEPS[4]: {
        const daysinceCall = numberOfDaysBetweenDates(relevantTimeslot.endTime, new Date())
        icon = <Information sx={{ color: 'neutral.darkGrey' }} />
        text = `Feedback was requested ${moment.duration(0 - daysinceCall, 'day').humanize(true, { d: 7, w: 4 })}`
        break
      }
    }
    return text ? (<IconTextCombo icon={icon} text={text} typography="body1" iconSize="medium" />) : null
  }

  const advisorFormerTitle = getAdvisorTitle(advisor, { engagement })

  return (
    <Card sx={{ mb: 3, ':hover': { bgcolor: 'neutral.offWhite', boxShadow: 4 } }}>
      <ButtonBase
        onClick={(e) => {
          // cancel if link is clicked or click outside of link popover menu
          if (e.target.nodeName === 'A') return
          if (String(e.target.className).includes('MuiBackdrop-root')) return
          navigateByEvent(actionUrl, e)
        }}
        sx={{ width: '100%' }}
      >
        <CardContent sx={{ width: '100%', textAlign: 'left' }}>
          <Box sx={{
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          >
            <Badge fill={ENGAGEMENT_COLOR_MAPPING[engagementStep]} />
            <Typography variant="h4" sx={{ width: '100%', ml: 2 }}>
              {advisorFormerTitle}
            </Typography>
            {(isPCU || isParticipant) && (
            <Tooltip title={tooltipText}>
              <SvgIcon
                size="small"
                sx={{
                  bgcolor: 'brand.lightestPurple',
                  borderRadius: '100%',
                  p: 0.5,
                  position: 'relative',
                  mr: 1,
                  fontSize: '16px'
                }}
              >
                <User size="small" sx={{ color: 'neutral.darkGrey' }} />
              </SvgIcon>
            </Tooltip>
            )}
          </Box>
          <Box sx={{ width: '100%', display: 'flex', ml: 4.5 }}>
            {renderCardAlert()}
            <IconTextCombo
              icon={<User sx={{ color: 'neutral.darkGrey' }} />}
              text={customerUser.fullName}
              typography="body1"
              iconSize="medium"
            />
          </Box>
        </CardContent>
      </ButtonBase>
    </Card>
  )
}

EngagementActivityCard.defaultProps = {
  engagementJsonApiType: 'engagements'
}

EngagementActivityCard.propTypes = {
  engagementId: PropTypes.number.isRequired,
  engagementJsonApiType: PropTypes.string,
}

export default EngagementActivityCard
