import React from 'react'
import {
  Box,
  ListItem,
  List,
  TextField,
  Typography,
  Chip
} from '@mui/material'
import {
  useDebounce,
  ACCOUNT_PAGE_TEXT_DATA,
  ACCOUNT_PAGE_SECTION_DATA,
} from 'utils-em'

import { EditAccountPageContext } from '../EditAccountPageContext'

const ArtificialIntelligenceForm = () => {
  const { data, setData, accountPage } = React.useContext(EditAccountPageContext)
  const [paragraph, setParagraph] = React.useState(data?.artificialIntelligence?.paragraph || '')
  const [title, setTitle] = React.useState(data?.artificialIntelligence?.title || '')
  const debouncedParagraph = useDebounce(paragraph, 500)
  const debouncedTitle = useDebounce(title, 500)

  const renderSurveyChips = () => {
    const surveyResult = accountPage?.surveyResults?.results?.levelOfAdoptionQuant
    const chipProps = { size: 'small', color: 'primary', sx: { ml: 1 } }
    if (!surveyResult) return <Chip label="no survey results" {...chipProps} color="error" />
    return (
      <Box display="flex">
        <Chip label={`min: ${surveyResult.min}`} {...chipProps} />
        <Chip label={`avg: ${surveyResult.avg}`} {...chipProps} />
        <Chip label={`max: ${surveyResult.max}`} {...chipProps} />
      </Box>
    )
  }

  React.useEffect(() => {
    setData({
      ...data,
      artificialIntelligence: {
        paragraph,
        title
      }
    })
  }, [debouncedParagraph, debouncedTitle])

  return (
    <Box sx={{ mb: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">
          {ACCOUNT_PAGE_TEXT_DATA.artificialIntelligence?.title}
        </Typography>
        {renderSurveyChips()}
      </Box>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        size="small"
        variant="outlined"
        rows={2}
        sx={{ mt: 2 }}
      />
      <TextField
        label="Paragraph"
        value={paragraph}
        onChange={(e) => setParagraph(e.target.value)}
        fullWidth
        size="small"
        variant="outlined"
        multiline
        rows={2}
        sx={{ mt: 2 }}
      />
      <List sx={{ listStyle: 'disc', ml: 2 }}>
        {
          ACCOUNT_PAGE_SECTION_DATA.find((sec) => sec.key === 'artificialIntelligence')?.modules.filter((m) => m.title).map((module) => (
            <ListItem key={module.title} sx={{ display: 'list-item' }}>
              <Typography>{module.title}</Typography>
            </ListItem>
          ))
        }
      </List>
    </Box>
  )
}

export default ArtificialIntelligenceForm
