import { connect } from 'react-redux'

import EnumAPI from '../../../../../../../store/EnumAPI'
import ContentManagementLayout from '../layouts/ContentManagementLayout'

const mapStateToProps = ({ data }) => ({
})

const mapDispatchToProps = {
  getEnum: EnumAPI.get
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentManagementLayout)
