import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Customer (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M15.3332 16.9998C15.3332 16.1158 14.982 15.2679 14.3569 14.6428C13.7317 14.0177 12.8839 13.6665 11.9998 13.6665C11.1158 13.6665 10.2679 14.0177 9.64281 14.6428C9.01769 15.2679 8.6665 16.1158 8.6665 16.9998" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 13.6665C13.3807 13.6665 14.5 12.5472 14.5 11.1665C14.5 9.78579 13.3807 8.6665 12 8.6665C10.6193 8.6665 9.5 9.78579 9.5 11.1665C9.5 12.5472 10.6193 13.6665 12 13.6665Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.8333 5.3335H6.16667C5.24619 5.3335 4.5 6.07969 4.5 7.00016V18.6668C4.5 19.5873 5.24619 20.3335 6.16667 20.3335H17.8333C18.7538 20.3335 19.5 19.5873 19.5 18.6668V7.00016C19.5 6.07969 18.7538 5.3335 17.8333 5.3335Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.6665 3.6665V5.33317" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.3335 3.6665V5.33317" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
