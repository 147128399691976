/*
The following activities are accounted for:
- Engagement started
- Call times proposed
- Call time accepted
- Call recording published
- Call notes published
- Call transcript published
- Message sent
- Feedback submitted
- Engagement ended
*/

import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Chip,
  Link,
  Stack,
  Typography
} from '@mui/material'
import {
  Download,
  Paperclip
} from 'icons'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  AdvisorCallCompletedText,
  SanitizedHTML
} from 'components'
import {
  downloadAttachment,
  formatDateTime,
  formatTimeslotForHumans,
  getEmailRelayLink,
  navigateTo
} from 'utils-em'

const LARGE_AVATAR_SIZE = 32
const SMALL_AVATAR_SIZE = 6

const ActivityItem = ({ activity, engagement, user, loadData }) => {
  const isAdvisorUser = user.userType === 'advisor'

  const {
    id,
    dateCreated,
    htmlBody,
    textBody,
    attachments,
    eventName,
    sender,
    senderName,
    senderAddress,
    payload
  } = activity
  const displaySenderName = sender?.firstName || senderName || senderAddress
  const relayLink = getEmailRelayLink(engagement)
  const activityKey = activity.type === 'messages' ? 'MESSAGE' : eventName
  const isFeedback = ['ADVISOR_FEEDBACK_SUBMITTED', 'CUSTOMER_FEEDBACK_SUBMITTED'].includes(activityKey)
  let isExpandable = false
  const expandByDefault = false
  const [isExpanded, setIsExpanded] = React.useState(false)
  let subText = textBody
  if (textBody === '') {
    subText = htmlBody && <SanitizedHTML html={htmlBody} />
  }

  const renderSummary = () => {
    const { advisor, customerUser } = engagement

    let text = activityKey
    let textColor = 'neutral.black'
    let avatarSize = SMALL_AVATAR_SIZE
    let avatarChar = ''

    switch (activityKey) {
      // message sent
      case 'MESSAGE':
        text = `Message from ${displaySenderName}`
        avatarChar = displaySenderName?.charAt(0)
        avatarSize = LARGE_AVATAR_SIZE
        isExpandable = true
        break
      // feedback submitted
      case 'ADVISOR_FEEDBACK_SUBMITTED':
        text = 'Feedback submitted'
        break
      case 'CUSTOMER_FEEDBACK_SUBMITTED':
        text = 'Feedback submitted'
        break
      // call completed
      case 'CALL_COMPLETED':
        text = 'Call completed'
        isExpandable = true
        break
      // multi call completed
      case 'ENGAGEMENT_STARTED':
        text = 'Engagement started'
        break
      // engagement ended
      case 'ENGAGEMENT_ENDED':
        text = 'Engagement closed'
        break
      case 'ENGAGEMENT_CANCELED':
        text = 'Engagement canceled'
        break
      // call recording published
      case 'ENGAGEMENT_RECORDING_PUBLISHED':
        text = 'Call recording published'
        break
      // call notes published
      case 'ENGAGEMENT_NOTES_PUBLISHED':
        text = 'Call notes published'
        break
      // call transcript published
      case 'ENGAGEMENT_TRANSCRIPT_PUBLISHED':
        text = 'Call transcript published'
        break
      // call times proposed
      case 'CREATE_ENGAGEMENT_SCHEDULING_PROPOSAL': {
        const initiatingUserId = JSON.parse(payload).initiating_user_id
        const isCurrrentUser = initiatingUserId === user.id
        const firstName = parseInt(customerUser.id, 10) === initiatingUserId ? customerUser.firstName : advisor.firstName
        text = `${isCurrrentUser ? 'You' : firstName} proposed call times`
        avatarChar = firstName.charAt(0)
        avatarSize = LARGE_AVATAR_SIZE
        break
      }
      // call time accepted (including rescheduling)
      case 'ACCEPT_SCHEDULING_PROPOSAL': {
        const timeslotProposalId = JSON.parse(payload).timeslot_proposal_id
        const timeslotProposal = engagement.timeslotProposals.find((tsp) => parseInt(tsp.id, 10) === timeslotProposalId)
        const timeslot = timeslotProposal?.timeslots?.find((t) => t.isSelected)

        if (!timeslot) return null
        text = `Call scheduled for ${formatTimeslotForHumans(timeslot)}`
        if (timeslotProposal.status === 'RESCHEDULED') {
          text = `Rescheduled - ${text}`
          textColor = 'neutral.disabled'
        } else if (timeslotProposal.status === 'CALL_MISSED') {
          text = `${text} - Marked as missed by ${timeslotProposal.decliningUser.firstName}`
        }
        break
      }
      default:
        return null // if null is returned, activity will not be rendered
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: LARGE_AVATAR_SIZE,
              minWidth: LARGE_AVATAR_SIZE,
              display: 'inline-flex',
              justifyContent: 'center',
              mr: 2
            }}
          >
            <Avatar
              alt="activity-item"
              sx={(theme) => ({
                height: avatarSize,
                width: avatarSize,
                ...theme.typography.bodyBold,
                bgcolor: 'brand.paleSlate',
                color: 'brand.slate'
              })}
            >
              {avatarChar}
            </Avatar>
          </Box>
          <Box className="summary-text-box" display="inline-block">
            <Typography variant="body1" mr="4px" display="inline" color={textColor}>
              {text}
            </Typography>
            <Typography variant="caption" color="neutral.darkGrey" display="inline" sx={{ ml: 2 }}>
              {formatDateTime(dateCreated)}
            </Typography>
            {attachments && attachments.length ? (
              <Chip
                label={
                  <Typography variant="caption">{`${attachments.length} attachment${attachments.length > 1 ? 's' : ''}`}</Typography>
                }
                icon={<Paperclip sx={{ width: '16px', height: '16px', color: 'neutral.darkGrey' }} />}
                sx={{ ml: 2, color: 'neutral.black', backgroundColor: 'neutral.offWhite' }}
                size="small"
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    )
  }

  const renderedSummary = renderSummary()
  if (!renderedSummary) return null
  return (
    <Accordion expanded={isExpanded || expandByDefault} onChange={() => setIsExpanded(!isExpanded)} key={id} sx={{ minWidth: '300px', '&.Mui-expanded': { margin: '0' } }}>
      <AccordionSummary
        expandIcon={isExpandable && !expandByDefault && <ExpandMore />}
        sx={{ pointerEvents: isExpandable ? 'auto' : 'none', alignContent: 'center' }}
      >
        {renderedSummary}
      </AccordionSummary>
      {isExpandable && (
        <AccordionDetails>
          {subText && (
            <Box sx={{ mt: 1, ml: 6 }}>
              <Typography variant="body1">
                {subText}
              </Typography>
            </Box>
          )}
          {activityKey === 'CALL_COMPLETED' && isAdvisorUser ? (
            <Box sx={{ ml: 6 }}>
              <AdvisorCallCompletedText
                engagementId={engagement.id}
                typography="caption"
                color="neutral.black"
              />
              <Box sx={{ mt: 2 }}>
                {!engagement.advisor.isPayable ? (
                  <Link onClick={() => navigateTo('/a/profile/edit#payment-info')} sx={{ display: 'block', mb: 1 }}>
                    <Typography variant="bodyBold">
                      Enter payment information
                    </Typography>
                  </Link>
                ) : null}
                {!engagement.advisorSingleCallFeedback ? (
                  <Link onClick={() => navigateTo(`/a/engagements/${engagement.id}#feedback`)}>
                    <Typography variant="bodyBold">
                      Submit feedback
                    </Typography>
                  </Link>
                ) : null}
              </Box>
            </Box>
          ) : null}
          {attachments && attachments.length ? (
            <Box sx={{ mt: 2, ml: 6 }}>
              <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                {attachments.map((attachment) => (
                  <Chip
                    key={attachment.id}
                    label={<Typography variant="bodyBold">{attachment.filename}</Typography>}
                    color="primary"
                    variant="outlined"
                    sx={{ borderWidth: '2px' }}
                    onClick={(e) => downloadAttachment(e, attachment.id, attachment.filename)}
                    // Delete icon and onDelete are workarounds to display an icon
                    // on the right hand side of the Chip. Trying to put this in
                    // the `label` caused lots of layout issues
                    deleteIcon={<Download style={{ color: 'inherit' }} />}
                    onDelete={() => { }}
                  />
                ))}
              </Stack>
            </Box>
          ) : null}
          {activityKey === 'MESSAGE' && subText && engagement.state !== 'closed' && !isFeedback && (
            <Box sx={{ mt: 2, ml: 5 }}>
              <Button
                component={Link}
                variant="text"
                href={`mailto:${relayLink}`}
                sx={{ ml: 0 }}
              >
                Reply
              </Button>
            </Box>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

ActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
  engagement: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired
}

export default ActivityItem
