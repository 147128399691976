import React from 'react'
import PropTypes from 'prop-types'

import { useJsonAPIGetAll } from 'hooks'
import { Selector } from 'components'

const ExpertiseSelector = ({ onChange, initialExpertise }) => {
  const { data: expertises, loaded } = useJsonAPIGetAll('expertises', {
    sortBy: 'name',
  })
  const [selectedExpertise, setSelectedExpertise] = React.useState(null)
  const foundInitialExpertise = expertises?.find((s) => s.id === initialExpertise?.id) || null

  React.useEffect(() => { onChange(selectedExpertise) }, [selectedExpertise])
  React.useEffect(() => { setSelectedExpertise(foundInitialExpertise) }, [foundInitialExpertise])

  return (
    <Selector
      initialOption={selectedExpertise}
      options={expertises}
      onChange={setSelectedExpertise}
      getOptionLabel={(option) => option.name}
      label="Area of Expertise"
      disabled={!loaded}
    />
  )
}

ExpertiseSelector.defaultProps = {
  initialExpertise: null,
}

ExpertiseSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialExpertise: PropTypes.object,
}

export default ExpertiseSelector
