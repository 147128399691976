import { connect } from 'react-redux'
import withAccessControl from '../../enhancers/withAccessControl'

import CustomerAccessControl from './CustomerAccessControl'

const mapStateToProps = ({ session }) => ({
  session
})

const accessFn = (session) => session.userType === 'customer'

export default withAccessControl(accessFn)(connect(mapStateToProps)(CustomerAccessControl))
