import PropTypes from 'prop-types'
import React from 'react'

import { StatCard } from 'components'
import { customAlert } from 'utils-em'

const STATS_PATH = `${__API_HOST__}/v1/stats`
const REQUESTED_STAT = 'engagements.count'

const EngagementsCountStatCard = ({
  engagementStates,
  onlyActiveContracts,
  userId,
  customCaption,
  onClick,
  active,
  ...rest
}) => {
  const [count, setCount] = React.useState(null)
  const [contractStatus, setContractStatus] = React.useState(null)

  const isActiveOnly = engagementStates && engagementStates.length === 1 && engagementStates[0] === 'active'
  const isProposedOnly = engagementStates && engagementStates.length === 1 && engagementStates[0] === 'proposed'
  const isCompletedOnly = engagementStates && engagementStates.length === 1 && engagementStates[0] === 'closed'
  const isActiveOrProposedOnly = (
    engagementStates &&
    engagementStates.length === 2 &&
    engagementStates.every((s) => ['proposed', 'active'].includes(s))
  )

  React.useEffect(() => { getCount() }, [])

  const getCount = async () => {
    let path = `${STATS_PATH}?requestedStats=["${REQUESTED_STAT}"]&engagementStates=${engagementStates}`
    if (onlyActiveContracts) path += `&onlyActiveContracts=${onlyActiveContracts}`
    if (userId) path += `&userId=${userId}`
    try {
      const res = await fetch(path, { method: 'GET', credentials: 'include' })
      if (res.error) throw new Error(res.error.message)
      const body = await res.json()
      setCount(parseInt(body.engagementsCount.count, 10))
      setContractStatus(body.engagementsCount.contractStatus)
    } catch (error) {
      customAlert('Cannot connect to network, please contact support', true)
    }
  }

  const getCaption = () => {
    if (customCaption) return customCaption

    if (isActiveOnly) return 'Active engagement(s)'
    if (isProposedOnly) return 'Call proposal(s) pending advisor review'
    if (isActiveOrProposedOnly) return 'Active & pending engagement(s)'
    if (isCompletedOnly) {
      if (contractStatus === 'active') return 'Engagement(s) completed in active contract(s)'
      if (contractStatus === 'expired') return 'Engagement(s) completed in most recent active contract'
      return 'Engagement(s) completed'
    }
  }

  return (
    <StatCard
      value={count}
      caption={getCaption()}
      onClick={onClick}
      active={active}
      {...rest}
    />
  )
}

EngagementsCountStatCard.defaultProps = {
  onlyActiveContracts: false,
  engagementStates: ['proposed'],
  userId: null,
  customCaption: null,
  onClick: null,
  active: false
}

EngagementsCountStatCard.propTypes = {
  engagementStates: PropTypes.array,
  onlyActiveContracts: PropTypes.bool,
  userId: PropTypes.number,
  customCaption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default EngagementsCountStatCard
