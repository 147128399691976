import React from 'react'
import PropTypes from 'prop-types'

import {
  Chip,
  Grid,
  MenuItem
} from '@mui/material'

import Stars from '@mui/icons-material/Stars'
import Phone from '@mui/icons-material/Phone'

import { SelectValidator } from 'react-material-ui-form-validator'

const AdminFormFields = ({ disabledFields, formData, updateFormData }) => (
  <Grid item xs={12}>
    <SelectValidator
      fullWidth
      required
      disabled={disabledFields.includes('roleType')}
      variant="outlined"
      validators={['required']}
      errorMessages={['Required']}
      size="small"
      label="Role"
      value={formData.roleType}
      onChange={
                    (e) => updateFormData(
                      {
                        roleType: e.target.value
                      }
                    )
                }
    >
      <MenuItem value="admin">
        <Chip size="small" label="Admin" icon={<Stars />} />
      </MenuItem>
      <MenuItem value="operator">
        <Chip size="small" label="Operator" icon={<Phone />} />
      </MenuItem>
      <MenuItem value="recruiter">
        <Chip size="small" label="Recruiter" icon={<Stars />} />
      </MenuItem>
    </SelectValidator>
  </Grid>
)

AdminFormFields.propTypes = {
  updateFormData: PropTypes.func.isRequired,
  disabledFields: PropTypes.array.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    roleType: PropTypes.string
  }).isRequired
}

export default AdminFormFields
