import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  Step,
  Stepper,
  StepLabel,
  Typography
} from '@mui/material'
import {
  EngagementSchedulingStep,
  EngagementCallPrepStep,
  EngagementFeedbackStep
} from 'components'
import { Check } from 'icons'
import {
  ENGAGEMENT_STEPS,
  buildJsonApiOne
} from 'utils-em'

const EngagementDetailsActionItemsTab = ({ engagementId, isAdvisor, showStepper, reloadEngagement, context }) => {
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const engagementStep = engagement.step
  // map engagement steps to UI Stepper
  const activeStepMapping = {
    PROPOSED: 0,
    SCHEDULING: 0,
    PREP_FOR_CALL: 1,
    COMPLETE_CALL: 2,
    SUBMIT_FEEDBACK: 3,
    FEEDBACK_SUBMITTED: 4
  }
  const activeStep = activeStepMapping[engagementStep]

  const renderActionItemsForEngagementState = () => {
    switch (engagementStep) {
      case ENGAGEMENT_STEPS[0]:
      case ENGAGEMENT_STEPS[1]: { return <EngagementSchedulingStep {...{ engagementId: Number(engagement.id), isAdvisor, context }} /> }
      case ENGAGEMENT_STEPS[2]:
      case ENGAGEMENT_STEPS[3]: { return <EngagementCallPrepStep {...{ engagement, isAdvisor, context }} /> }
      case ENGAGEMENT_STEPS[4]:
      case ENGAGEMENT_STEPS[5]: { return <EngagementFeedbackStep {...{ engagement, isAdvisor, reloadEngagement, context }} /> }
    }
    return null
  }

  const renderStep = (step, text) => {
    const textProps = { variant: 'body1', color: 'main.primary' }
    let icon = null
    // past, present, future
    if (step - activeStep < 0) {
      icon = <Check sx={{ color: 'brand.slate', borderRadius: '50%', backgroundColor: 'brand.paleSlate' }} />
    } else if (step - activeStep === 0) textProps.variant = 'bodyBold'
    else textProps.color = 'neutral.disabled'

    return (
      <Step key={ENGAGEMENT_STEPS[step]}>
        <StepLabel icon={icon}>
          <Typography {...textProps}>{text}</Typography>
        </StepLabel>
      </Step>
    )
  }
  return (
    <>
      <Box sx={{ mb: 6, mt: 2 }}>
        {showStepper && (
          <>
            <Stepper activeStep={activeStep}>
              {renderStep(0, 'Schedule call')}
              {renderStep(1, 'Prepare for call')}
              {renderStep(2, 'Complete call')}
              {renderStep(3, isAdvisor ? 'Complete feedback' : 'Submit feedback')}
            </Stepper>
          </>
        )}
      </Box>
      {renderActionItemsForEngagementState()}
    </>
  )
}

EngagementDetailsActionItemsTab.defaultProps = {
  showStepper: true,
  context: {}
}

EngagementDetailsActionItemsTab.propTypes = {
  engagementId: PropTypes.number.isRequired,
  isAdvisor: PropTypes.bool.isRequired,
  showStepper: PropTypes.bool,
  reloadEngagement: PropTypes.func.isRequired,
  context: PropTypes.object
}

export default EngagementDetailsActionItemsTab
