import React from 'react'
import moment from 'moment'
import {
  useDispatch,
} from 'react-redux'
import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AdvisorProfileDialog,
  ConfirmDialog,
  OrganizationSelectorTwo,
  JsonApiDataGrid
} from 'components'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import Delete from '@mui/icons-material/Delete'
import ThumbUp from '@mui/icons-material/ThumbUp'
import {
  JsonAPI
} from 'store'
import {
  formatDate,
  httpResponseAlert,
  openDialog,
  constants
} from 'utils-em'

const StandardizationValidator = () => {
  const dispatch = useDispatch()
  const [savedCompany, setSavedCompany] = React.useState(null)
  const [reload, setReload] = React.useState(0)

  // force refresh of table after save
  React.useEffect(() => { setReload(reload + 1) }, [savedCompany?.id])

  const verifyStandardization = async (company) => {
    const resp = await dispatch(
      JsonAPI.save({
        type: 'companies',
        id: company.id,
        dateStandardizationVerified: moment().toISOString(),
      })
    )
    httpResponseAlert(resp)
    setSavedCompany(company)
  }

  const leaveIntentionallyBlank = async (company) => {
    setSavedCompany(company)
    const resp = await dispatch(
      JsonAPI.save({
        type: 'companies',
        id: company.id,
        organizationId: null,
        dateStandardizationVerified: moment().toISOString(),
      })
    )
    httpResponseAlert(resp)
    setSavedCompany(company)
  }

  const discardCompany = async (company) => {
    const resp = await dispatch(
      JsonAPI.save({
        type: 'companies',
        id: company.id,
        dateStandardizationDiscarded: moment().toISOString(),
      })
    )
    httpResponseAlert(resp)
    setSavedCompany(company)
  }

  const setOrganizationForCompany = async (company, organization) => {
    // prevent saved company from being overwritten by selector
    if (savedCompany && company.id === savedCompany.id) {
      return
    }

    // prevent saving if the organization is the same
    if (!organization || parseInt(organization.id, 10) === company.organizationId) {
      return
    }

    const resp = await dispatch(
      JsonAPI.save({
        type: 'companies',
        id: company.id,
        organizationId: organization.id,
        relationshipNames: ['organization'],
        queryStringParams: {
          include: 'organization',
        },
      })
    )

    httpResponseAlert(resp)
  }

  const getAllColumns = () => [
    {
      field: 'advisor.id',
      headerName: 'Advisor ID',
      flex: 1,
      valueGetter: ({ row }) => row.advisor.id,
      type: 'number',
    },
    {
      field: 'advisor.fullName',
      headerName: 'Advisor Name',
      valueGetter: ({ row }) => row.advisor.fullName,
      type: 'string',
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault()
            openDialog(<AdvisorProfileDialog advisorId={parseInt(row.advisor.id, 10)} />, 'advisorProfile')
          }}
        >
          {row.advisor.fullName}
        </Link>
      ),
    },
    {
      field: 'advisor.onboardingCompleted',
      headerName: 'Onboard Date',
      flex: 1,
      valueGetter: ({ row }) => formatDate(row.advisor.onboardingCompleted),
      type: 'dateTime',
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2)
    },
    { field: 'orgName', headerName: 'Given Org Name', flex: 1, valueGetter: ({ row }) => row.orgName, type: 'string' },
    {
      field: 'displayOrgName',
      headerName: 'Standardized Org Name',
      flex: 1,
      valueGetter: ({ row }) => row.organization,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name, 'kn', { numeric: true }),
      renderCell: ({ row }) => (
        <OrganizationSelectorTwo
          textFieldParams={{
            variant: 'standard',
            label: '',
            fullWidth: true
          }}
          defaultValue={row.organization}
          onSelected={(organization) => setOrganizationForCompany(row, organization)}
        />
      ),
    },
    {
      field: 'dateStandardizationSet',
      headerName: 'Date Standardized',
      flex: 1,
      valueGetter: ({ row }) => formatDate(row.dateStandardizationSet),
      type: 'dateTime',
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      disableExport: true,
      flex: 1,
      renderCell: ({ row }) => {
        const company = row
        return (
          <>
            <Tooltip title="Approve">
              <IconButton
                onClick={() => openDialog(
                  <ConfirmDialog
                    size="sm"
                    title="Verify Standardization"
                    description={`Are you sure you want to verify this company? (${company.orgName} to ${company.organization.name})`}
                    actions={[
                      {
                        name: 'Verify',
                        action: async () => verifyStandardization(company),
                      },
                    ]}
                  />
                )}
              >
                <ThumbUp />
              </IconButton>
            </Tooltip>
            <Tooltip title="Leave Intentionally Blank">
              <IconButton
                onClick={() => openDialog(
                  <ConfirmDialog
                    size="sm"
                    title="Leave Blank"
                    description={`Are you sure you want to leave this unstandardized? (${company.orgName})`}
                    actions={[
                      {
                        name: 'Verify',
                        action: async () => leaveIntentionallyBlank(company),
                      },
                    ]}
                  />
                )}
              >
                <CheckBoxOutlineBlank />
              </IconButton>
            </Tooltip>
            <Tooltip title="Discard">
              <IconButton
                onClick={() => openDialog(
                  <ConfirmDialog
                    size="sm"
                    title="Discard Standardization"
                    description={`Are you sure you want to discard this company? (${company.orgName} to ${company.organization.name})`}
                    actions={[
                      {
                        isDelete: true,
                        name: 'Discard',
                        action: async () => discardCompany(company),
                      },
                    ]}
                  />
                )}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', m: 2, mt: 8 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '20px', m: 1 }}>Standardization Validator</Typography>
      </Box>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', mt: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <JsonApiDataGrid
            type="companies"
            columns={getAllColumns()}
            jsonApiOptions={{
              filters: [
                { name: 'dateStandardizationSet', op: 'isnot', val: null },
                { name: 'dateStandardizationVerified', op: 'is', val: null },
                { name: 'dateStandardizationDiscarded', op: 'is', val: null },
                { name: 'deleted', op: 'is', val: false },
                {
                  name: 'advisor',
                  op: 'has',
                  val: {
                    name: 'onboardingCompleted', op: 'isnot', val: null
                  }
                },
                {
                  name: 'advisor',
                  op: 'has',
                  val: {
                    name: 'isActive', op: 'is', val: true
                  }
                },
                {
                  name: 'roles',
                  op: 'any',
                  val: {
                    name: 'deleted', op: 'is', val: false
                  }
                }
              ],
              include: ['advisor', 'organization', 'roles']
            }}
            disableExport={false}
            mode="server"
            rowsPerPageOptions={constants.DEFAULT_DATAGRID_ROWS_PER_PAGE_OPTIONS}
            dependencies={[reload]}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default StandardizationValidator
