import React, { Suspense } from 'react'
import { Spinner, ErrorBoundary, ErrorPage } from 'components'

const LazyLoadWithError = (Component) => (
  <ErrorBoundary errorComponent={<ErrorPage />}>
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  </ErrorBoundary>
)

export default LazyLoadWithError
