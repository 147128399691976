import { useSelector } from 'react-redux'
import {
  buildJsonApiOne,
  getAdvisorRelevantCompanies,
  getAdvisorMostRecentRelevantCompany,
  getAdvisorMostRecentRelevantRole,
  isAdvisorAvailable,
  sortActiveCompaniesByRecency,
} from 'utils-em'

export default function useAdvisor (advisorId) {
  return useSelector(({ data }) => {
    const advisor = buildJsonApiOne(data, 'advisors', advisorId)
    return {
      advisor,
      companies: advisor && sortActiveCompaniesByRecency(advisor.companies?.filter((c) => !c.deleted)),
      currentCompany: advisor && advisor.companies?.find((c) => c.isCurrent),
      relevantCompanies: advisor && getAdvisorRelevantCompanies(advisor),
      recentRelevantCompany: advisor && getAdvisorMostRecentRelevantCompany(advisor),
      recentRelevantRole: advisor && getAdvisorMostRecentRelevantRole(advisor),
      isAvailable: advisor && isAdvisorAvailable(advisor)
    }
  })
}
