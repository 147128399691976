import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography
} from '@mui/material'
import { useEngagement } from 'hooks'

const ProposalMessage = ({ engagementId }) => {
  const { isAdvisor, advisor, daysUntilProposalExpires } = useEngagement(engagementId)

  if (isAdvisor) {
    return <>TODO</>
  }

  return (
    <>
      <Typography variant="h3">
        {`${advisor.firstName} is reviewing your call proposal.`}
      </Typography>
      <Typography variant="body1">
        {`The advisor has ${daysUntilProposalExpires} days to review your proposal. 
        Once they reach a decision, you’ll receive an email notification.`}
      </Typography>
    </>
  )
}

ProposalMessage.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default ProposalMessage
