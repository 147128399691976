import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import { Avatar, Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'

import { Clipboard, Emissary, Refresh, User } from 'icons'
import { copyToClipboard } from 'utils-em'

const Message = ({ message, onRequestRegenerate }) => {
  const { role, content, isLoading } = message
  const isUser = role === 'user'

  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false)

  const copyMessage = () => {
    copyToClipboard(content, () => {
      setCopiedToClipboard(true)
      setTimeout(() => {
        setCopiedToClipboard(false)
      }, 2000)
    })
  }

  const avatarIconStyle = { width: '24px', height: '24px' }
  const actionIconStyle = {
    width: '16px',
    height: '16px',
    color: isLoading ? 'neutral.lightGrey' : 'neutral.darkGrey',
    cursor: isLoading ? 'not-allowed' : 'pointer'
  }

  return (
    <Box sx={{ display: 'flex', mx: 1.5, my: 0.5, alignSelf: 'flex-end' }}>
      <Avatar
        sx={{
          bgcolor: (isUser ? 'brand.softTeal' : 'brand.softOrange'),
          height: '32px',
          width: '32px'
        }}
      >
        {isUser ? <User sx={{ ...avatarIconStyle, fill: 'white' }} /> : <Emissary sx={avatarIconStyle} />}
      </Avatar>
      <Typography sx={{ display: 'block', pl: 2 }}>
        <Typography variant="bodyBold">{isUser ? 'You' : 'Emmy'}</Typography>
        {isLoading ? (
          <>
            <Skeleton animation="wave" width={600} height={16} />
            <Skeleton animation="wave" width={550} height={16} />
            <Skeleton animation="wave" width={400} height={16} />
          </>
        ) : (
          <Markdown>
            {content}
          </Markdown>
        )}
        {!isUser ? (
          <Box sx={{ mt: 2 }}>
            <Tooltip title={copiedToClipboard ? 'Copied!' : 'Copy to clipboard'} placement="top">
              <IconButton
                size="small"
                disabled={isLoading}
                onClick={() => !isLoading && copyMessage()}
              >
                <Clipboard sx={actionIconStyle} />
              </IconButton>
            </Tooltip>
            {onRequestRegenerate && (
              <Tooltip title="Regenerate" placement="top">
                <IconButton
                  disabled={isLoading}
                  size="small"
                  onClick={() => !isLoading && onRequestRegenerate(message)}
                >
                  <Refresh sx={actionIconStyle} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : null}
      </Typography>
    </Box>
  )
}

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.oneOf(['user', 'assistant']),
    content: PropTypes.string,
    isLoading: PropTypes.bool
  }),
  onRequestRegenerate: PropTypes.func
}

export default Message
