import { connect } from 'react-redux'

import { buildJsonApiOrdered } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'
import DivisionsList from './DivisionsList'

const mapStateToProps = ({ data }) => ({
  divisions: buildJsonApiOrdered(data, 'divisions'),
  totalCount: data.meta.divisions.count
})

const mapDispatchToProps = {
  getAllDivisions: (customerId, size, page, sort, filters = []) => {
    const filter = [{
      name: 'customerId',
      op: 'eq',
      val: customerId
    }]
    return JsonAPI.getAll({
      type: 'divisions',
      queryStringParams: {
        include: 'customerUsers,children,parent,contracts,approverUser',
        'page[number]': page,
        'page[size]': size,
        filter: filter.concat(filters),
        sort
      }
    })
  },
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(DivisionsList)
