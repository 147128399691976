import React from 'react'
import PropTypes from 'prop-types'
import { useIsAdmin, useJsonAPIGetOne } from 'hooks'
import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material'
import {
  openDialogWithThemingTwo,
  roleHasAllDisplayedFields,
  sortActiveCompaniesByRecency
} from 'utils-em'
import {
  AdvisorCompanyEditDialog,
  CompanyCardTwo,
  InfoStrip
} from 'components'

const EmploymentHistory = ({ advisorId }) => {
  const isAdmin = useIsAdmin()
  const { object: advisor, reload } = useJsonAPIGetOne(
    advisorId,
    'advisors',
    ['companies', 'companies.advisor', 'companies.organization', 'companies.roles', 'companies.roles.tags']
  )

  const companiesWithMissingData = advisor?.companies.filter((co) => (!co.deleted && co.roles && co.roles.some((r) => !r.deleted && !roleHasAllDisplayedFields(r))))
  const companiesWithNoRoles = advisor?.companies.filter((co) => (!co.deleted && (!co.roles || co.roles.filter((r) => !r.deleted).length === 0)))
  const totalNumberOfRoles = advisor?.companies.reduce((count, current) => count + current.roles.length, 0)
  const totalCompanies = advisor?.companies.filter((c) => !c.deleted).length

  const infoStrips = []
  if (totalNumberOfRoles < 2) infoStrips.push('Add additional roles to give clients a full picture of your accomplishments and areas of expertise')
  companiesWithNoRoles.forEach((co) => infoStrips.push(`In order for your experience at ${co.orgName} to show up on your profile, add a role via the “Add New Role” button`))
  companiesWithMissingData.forEach((co) => infoStrips.push(`We're missing a few details about your role at ${co.orgName}`))

  return (
    <Box>
      <Typography variant="h2">Employment history</Typography>
      {!isAdmin && (
        <>
          <Typography sx={{ mt: 3 }}>
            Entering your full work experience paints a clearer picture of the insights you possess.
            Clients may reach out to connect with you regarding previous roles or industries, so including your full background
            increases your potential opportunities. We will not surface your current role to clients.
          </Typography>

          {infoStrips.length ? (
            <Box sx={{ mt: 4 }}>
              {infoStrips.map((stripText) => (
                <InfoStrip text={stripText} sx={{ mt: 4 }} />
              ))}
            </Box>
          ) : null}
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={() => openDialogWithThemingTwo(
          <AdvisorCompanyEditDialog
            advisorId={advisorId}
            saveComplete={() => reload()}
          />
        )}
      >
        Add new company
      </Button>

      <Divider sx={{ mt: 3, mb: 3 }} />

      {sortActiveCompaniesByRecency(advisor.companies).map((ac) => (
        <CompanyCardTwo
          key={ac.id}
          company={ac}
          onChange={() => reload()}
          totalCompanies={totalCompanies}
        />
      ))}
    </Box>
  )
}

EmploymentHistory.propTypes = {
  advisorId: PropTypes.string.isRequired
}

export default EmploymentHistory
