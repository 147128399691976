import {
  useDispatch,
  useSelector
} from 'react-redux'
import { SessionAPI } from 'store'

export const useSessionUser = () => {
  const dispatch = useDispatch()
  const sessionUser = useSelector(({ session }) => session)
  const isLoggedIn = Boolean(sessionUser?.id)
  const isStealthed = Boolean(sessionUser?.cloaked)

  // User types
  const isCustomerUser = Boolean(sessionUser?.userType.startsWith('customer'))
  const isAdmin = Boolean(sessionUser?.userType === 'admin')
  const isAdvisor = Boolean(sessionUser?.userType === 'advisor')

  // Account limits
  const isUnlimitedByAccount = Boolean(sessionUser?.isUnlimitedByAccount)

  // Customer user roles
  const isRep = sessionUser?.roleType === 'rep'
  const isManager = sessionUser?.roleType === 'manager'
  const isProgramOwner = sessionUser?.roleType === 'program_owner'
  const isPOM = isManager || isProgramOwner

  // SAML
  const hasAuthProvider = Boolean(sessionUser?.samlIdpId || sessionUser?.oidcProvider)

  // logout
  const logout = () => dispatch(SessionAPI.logout())

  const getHomePage = () => {
    if (isAdmin) return '/admin/customers'
    if (isCustomerUser) return '/c'
    if (isAdvisor) return '/a'
    return '/'
  }

  const getSupportEmailAddress = () => {
    if (isAdvisor) return 'advisor-support@emissary.io'
    return 'support@emissary.io'
  }

  const getTermsUrl = () => {
    if (isAdvisor) return 'https://emissary.io/advisor-terms-and-conditions/'
    return 'https://emissary.io/terms-conditions/'
  }

  const getIsOnboarding = () => {
    const { status } = sessionUser
    if (isCustomerUser) {
      return ['not_invited', 'invited', 'invite_accepted', 'sign_up_incomplete_needs_contact_info', 'sign_up_incomplete_needs_account_info'].includes(status)
    }
    if (isAdvisor) {
      return false // TODO
    }
    return false
  }

  return {
    isAdmin,
    isCustomerUser,
    isAdvisor,
    isRep,
    isManager,
    isProgramOwner,
    isPOM,
    isLoggedIn,
    hasAuthProvider,
    isStealthed,
    isUnlimitedByAccount,
    logout,
    getHomePage,
    getSupportEmailAddress,
    getTermsUrl,
    isOnboarding: getIsOnboarding(),
    ...sessionUser
  }
}

export default useSessionUser
