import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import {
  AddParticipantModal,
  DownloadEngagementAssetsDialog
} from 'components'
import { DotsVertical } from 'icons'
import {
  downloadFile,
  getEngagementMostRelevantTimeslot,
  openDialog,
  simpleHttpFetch
} from 'utils-em'

const EngagementTableActionsButton = ({ engagement }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { id: timeslotId, startTime } = (engagement && getEngagementMostRelevantTimeslot(engagement)) || {}
  const timeslotInFuture = moment(startTime) > moment()
  const assetCount = engagement?.assets?.length

  const handleAddToCalendar = async (e, calendarType) => {
    e.stopPropagation()
    const result = await simpleHttpFetch(
      `${__API_HOST__}/invites/${timeslotId}/?type=${calendarType}`,
      {
        errorMessage: 'Could not get calendar invite.',
        successMessage: 'Added call to calendar',
        json: calendarType === 'gcal'
      }
    )

    if (calendarType === 'gcal') {
      window.open(result.location)
    } else if (calendarType === 'ics') {
      const blob = await result.blob()
      downloadFile(blob)
    }
    setAnchorEl(null)
  }

  const handleAddParticipants = (e) => {
    e.stopPropagation()
    const callCompleted = ['SUBMIT_FEEDBACK', 'FEEDBACK_SUBMITTED'].includes(engagement.step)
    openDialog(
      <AddParticipantModal
        engagementId={engagement.id}
        description={callCompleted ? 'Select a member from your team below to add as a participant in this engagement. This will allow them to access call documents.'
          : undefined}
      />
    )
    setAnchorEl(null)
  }

  const handleDownloadAssets = async (e) => {
    e.stopPropagation()
    openDialog(
      <DownloadEngagementAssetsDialog engagementId={engagement.id} />
    )
  }

  const handlePopoverOpen = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handlePopoverClose = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  if (!engagement) return null
  if (!['active', 'closed'].includes(engagement.state)) return null

  return (
    <>
      <IconButton
        color="primary"
        sx={{ float: 'right', width: 32, height: 32 }}
        onClick={handlePopoverOpen}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <DotsVertical sx={{ color: 'primary.main' }} />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1, '& .MuiTypography-root': { cursor: 'pointer', p: 1 } }}>
          <Typography onClick={handleAddParticipants}>Add participants</Typography>
          {assetCount > 0 && <Typography onClick={handleDownloadAssets}>{`Download ${assetCount} file${assetCount !== 1 ? 's' : ''}`}</Typography>}
          {timeslotInFuture && (
            <>
              <Typography onClick={(e) => handleAddToCalendar(e, 'gcal')}>Add to Google Calendar</Typography>
              <Typography onClick={(e) => handleAddToCalendar(e, 'ics')}>Add to Outlook / Apple Calendar</Typography>
            </>
          )}
        </Box>
      </Popover>
    </>

  )
}

EngagementTableActionsButton.propTypes = {
  engagement: PropTypes.object.isRequired,
}

export default EngagementTableActionsButton
