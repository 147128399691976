import PropTypes from 'prop-types'
import React from 'react'
import {
  Dialog,
} from '@mui/material'

import { CallScheduling } from 'components'
import { closeDialog } from 'utils-em'

const CallSchedulingDialog = (props) => (
  <Dialog fullWidth maxWidth="lg" open PaperProps={{ sx: { height: '90%' } }}>
    <CallScheduling {...props} />
  </Dialog>
)

CallScheduling.defaultProps = {
  engagementId: null,
  timesReceivedCallback: null,
  initialTimes: [],
  advisorId: null,
  onSubmit: () => closeDialog('scheduling-dialog'),
  onCancel: () => closeDialog('scheduling-dialog'),
}

CallScheduling.propTypes = {
  engagementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timesReceivedCallback: PropTypes.func,
  initialTimes: PropTypes.array,
  advisorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default CallSchedulingDialog
