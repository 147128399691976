import PropTypes from 'prop-types'
import React from 'react'
import { Route } from 'react-router'

import NotFound from '../NotFound'

const AuthorizedRoute = (props) => {
  if (!props.isAuthorized) {
    return <NotFound />
  }
  if (props.featureRedirect) {
    if (props.feature && props.featureRedirect && props.userFeatures.findIndex((feature) => feature.flag === props.feature) !== -1) {
      props.navigateTo(props.featureRedirect)
      return null
    }
  } else if (props.feature && props.userFeatures.findIndex((feature) => feature.flag === props.feature) === -1) {
    return <NotFound />
  }

  return <Route {...props} />
}

AuthorizedRoute.defaultProps = {
  feature: undefined,
  featureRedirect: undefined,
  isAuthorized: true
}

AuthorizedRoute.propTypes = {
  feature: PropTypes.string,
  featureRedirect: PropTypes.string,
  isAuthorized: PropTypes.bool,
  userFeatures: PropTypes.array.isRequired,
  navigateTo: PropTypes.func.isRequired
}

export default AuthorizedRoute
