import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardActions, CardContent, Chip, LinearProgress } from '@mui/material'

const BarStatCard = ({
  value,
  max,
  caption,
  valueDecimals,
  headerOverride,
  showProgress,
  chipLabel,
  ...rest
}) => (
  <Card
    elevation={0}
    sx={{
      bgcolor: 'brand.lightNavy',
      p: 2,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '8px',
      border: 0
    }}
    {...rest}
  >
    <CardContent sx={{ p: 0 }}>
      <Box typography="h2" sx={{ mb: 1, color: 'brand.navy' }}>
        {headerOverride || `${valueDecimals !== null ? value.toFixed(valueDecimals) : value} ${max ? `/ ${max}` : ''}`}
      </Box>
      {showProgress && value !== null && max !== null && (
        <LinearProgress
          position="static"
          variant="determinate"
          value={(value / max) * 100}
          sx={{
            mb: 1,
            borderRadius: '4px',
            width: '100%',
            backgroundColor: 'brand.lightestPurple',
            height: '8px',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'brand.purple'
            }
          }}
        />
      )}
    </CardContent>
    <CardActions sx={{ p: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
      <Box typography="caption" sx={{ color: 'neutral.darkGrey' }}>
        {caption}
      </Box>
      {chipLabel && <Chip label={chipLabel} size="small" sx={{ bgcolor: 'brand.paleSlate', ml: 1 }} />}
    </CardActions>
  </Card>
)
BarStatCard.defaultProps = {
  value: null,
  max: null,
  valueDecimals: null,
  headerOverride: null,
  showProgress: true,
  chipLabel: null
}

BarStatCard.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  valueDecimals: PropTypes.number,
  headerOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showProgress: PropTypes.bool,
  chipLabel: PropTypes.string,
}

export default BarStatCard
