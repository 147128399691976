import { connect } from 'react-redux'
import { buildJsonApiAll, buildJsonApiFilter } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'

import TagPicker from './TagPicker'

const mapStateToProps = ({ data }, ownProps) => ({
  tags: buildJsonApiAll(data, 'tags').filter((tag) => (ownProps.onlyQueryable ? tag.isQueryable : true))
})

const mapDispatchToProps = {
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog, 'addTag')),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog('addTag')),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  saveTag: (tag) => JsonAPI.create(Object.assign(tag, { type: 'tags' })),
  getTags: (type, customerId, onlyQueryable, onlyUsed) => {
    const filters = [
      { name: 'category', op: 'eq', val: type }
    ]

    if (customerId) {
      filters.push({
        or: [
          { name: 'customerId', op: 'is', val: null },
          { name: 'customerId', op: 'eq', val: customerId }
        ]
      })
    } else {
      filters.push({ name: 'customerId', op: 'is', val: null })
    }
    if (onlyQueryable) {
      filters.push({ name: 'isQueryable', op: 'is', val: true })
    }

    if (onlyUsed) {
      filters.push(buildJsonApiFilter('advisorRequests__any.account.customerId__eq', customerId))
    }
    return JsonAPI.getAll({
      type: 'tags',
      queryStringParams: {
        filter: filters
      }
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagPicker)
