import { connect } from 'react-redux'

import { Alerts, Dialogs, JsonAPI } from 'store'
import EditCustomerUserDialog from './EditCustomerUserDialog'

const mapDispatchToProps = {
  saveCustomerUser: (customerUser) => {
    const method = customerUser.id ? JsonAPI.save : JsonAPI.create
    return method(Object.assign(customerUser, {
      type: 'customerUsers',
      include: 'divisions',
      relationshipNames: ['divisions']
    }))
  },
  handleClose: (dialog) => (dispatch) => dispatch(Dialogs.closeDialog()),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message))

}

export default connect(null, mapDispatchToProps)(EditCustomerUserDialog)
