import React from 'react'
import {
  Box,
  Link,
  Typography
} from '@mui/material'
import {
  Check,
  MagnifyingGlass
} from 'icons'
import {
  GuidedTourDialog
} from 'components'
import {
  openDialog,
  closeDialog,
  navigateTo
} from 'utils-em'

const customerGuidedTourSteps = [
  {
    avatar: <MagnifyingGlass sx={{ color: 'brand.slate' }} />,
    title: 'Find an advisor',
    text: (
      <>
        <Link onClick={() => { closeDialog(); navigateTo('/c/search') }}>
          Search our network
        </Link>
        &nbsp;to find an advisor you would like to speak with, and propose a call with them. They will have 14 days to let you know whether they are available to engage.
      </>
    )
  },
  {
    avatar: 1,
    title: 'Schedule call',
    text: (
      <>
        Once the advisor accepts your call proposal, use our scheduling system to choose a time for your call.
      </>
    )
  },
  {
    avatar: 2,
    title: 'Prepare for call',
    text: 'Get the most out of your call by ensuring that your Emissary advisor has all the information he or she needs and understands the call agenda in advance. Invite team members who can join your call, and give them background on the advisor in advance.'
  },
  {
    avatar: 3,
    title: 'Complete call',
    text: 'Once the call is complete you will have access to call recordings & transcripts, which can be found in the Engagement Details page.'
  },
  {
    avatar: 4,
    title: 'Submit feedback',
    text: 'Enabling valuable experiences for you is our number one goal. Help us understand where it went well and where it could be better.'
  },
  {
    avatar: <Check sx={{ color: 'neutral.white' }} />,
    title: 'Complete',
    text: 'For 30 days after your call, you can keep in touch with your advisor via email to ask clarifying questions or follow up. We use an anonymous email relay to ensure private but open lines of communication.'
  }
]

const HowItWorksSection = ({ ...rest }) => (
  <Box {...rest}>
    <Typography variant="label" color="neutral.black">
      How does emissary work?
    </Typography>

    <Box sx={{ mt: 2, typography: 'body1', color: 'neutral.black' }}>
      <Link onClick={() => { navigateTo('/c/search') }}>
        Find an advisor
      </Link>
      , propose and schedule a call, send messages back and forth, then submit feedback about your experience.
    </Box>

    <Box sx={{ mt: 2, typography: 'body1', color: 'neutral.black' }}>
      For more, check out our&nbsp;
      <Link
        onClick={() => {
          openDialog(<GuidedTourDialog steps={customerGuidedTourSteps} />)
        }}
      >
        guided tour
      </Link>
      &nbsp;of how it works, with additional resources on how to make the most of the program.
    </Box>

    <Box sx={{ mt: 2, typography: 'caption', color: 'neutral.black' }}>
      If you have any questions or need help, email us at&nbsp;
      <Link href="mailto:support@emissary.io">support@emissary.io</Link>
      .
    </Box>
  </Box>
)

export default HowItWorksSection
