import React from 'react'
import PropTypes from 'prop-types'

import { Calendar } from './components'

import { CalendarTimeSelectorContextProvider } from './CalendarTimeSelectorContext'

const CalendarTimeSelector = ({ selectedTimes, onTimesSelected }) => (
  <CalendarTimeSelectorContextProvider
    initialSelectedTimes={selectedTimes}
    onTimesSelected={onTimesSelected}
  >
    <Calendar />
  </CalendarTimeSelectorContextProvider>
)

CalendarTimeSelector.defaultProps = {
  selectedTimes: []
}

CalendarTimeSelector.propTypes = {
  selectedTimes: PropTypes.array,
  onTimesSelected: PropTypes.func.isRequired
}

export default CalendarTimeSelector
