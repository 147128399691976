import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Link,
  Tooltip,
  Typography
} from '@mui/material'
import { Link as LinkIcon } from 'icons'

import {
  closeDialog,
  constants,
  copyToClipboard,
  customAlert,
  getAdvisorMostRecentRelevantCompany,
  getAdvisorMostRecentRelevantRole,
  navigateTo,
  openDialog
} from 'utils-em'
import {
  ConfirmDialog,
  FilterGroup,
  JsonApiTable
} from 'components'
import { JsonAPI } from 'store'

const SavedAdvisors = ({ customerUserId }) => {
  const dispatch = useDispatch()

  const [forceRefresh, setForceRefresh] = React.useState(0)
  const [isCopied, setIsCopied] = React.useState(false)
  const isLoggedInUser = useSelector(({ session }) => session.id === customerUserId)

  const initialQueryStringParams = {
    include: [
      'advisor',
      'advisor.bookmarksReceived',
      'advisor.companies',
      'advisor.companies.roles',
      'advisor.companies.organization'
    ].join(',')
  }
  const [queryStringParams, setQueryStringParams] = React.useState(initialQueryStringParams)

  const removeBookmark = async (bookmarkId) => {
    const res = await dispatch(JsonAPI.delete({
      type: 'bookmarks',
      id: bookmarkId
    }))
    res.ok ? customAlert('Advisor successfully removed from list', false) : customAlert('Error removing advisor from list', true)
  }

  const filterOptions = [
    {
      initialQueryFilters: { name: 'ownerId', op: 'eq', val: customerUserId }
    },
    {
      initialQueryFilters: {
        name: 'bookmarkType',
        op: 'eq',
        val: 'SAVED_ADVISOR'
      }
    }
  ]

  return (
    <>
      <FilterGroup
        title="Saved Advisors"
        filterOptions={filterOptions}
        textSearchPlaceholder="Search by account or title"
        initialQueryStringParams={initialQueryStringParams}
        onQueryStringParamsChange={setQueryStringParams}
        textSearchQueryFields={['title', 'advisor.companies__any.organization__has.name', 'advisor.companies__any.organization__has.aliases']}
      />
      {isLoggedInUser && (
      <Typography variant="body" color="neutral.black" sx={{ mt: 2, mb: 2, ml: 2, display: 'inline-block' }}>
        Keep track of the advisors you're interested in by saving their profiles to your account.
      </Typography>
      )}
      <JsonApiTable
        type="bookmarks"
        queryStringParams={queryStringParams}
        pageSizeOptions={constants.TWO_OH_ROWS_PER_PAGE_OPTIONS}
        dependencies={[forceRefresh]}
        onRowClick={(bookmark) => navigateTo(`/c/advisor/${bookmark.advisor.id}`)}
        noDataText="There are no advisors to display."
        columns={[
          {
            name: 'ADVISOR_ACCOUNT_AND_TITLE',
            sortKey: 'title',
            defaultSort: true,
            header: 'Advisor Account & Title',
            value: (bookmark) => (
              <>
                <Typography variant="body" color="neutral.black">{getAdvisorMostRecentRelevantCompany(bookmark.advisor)?.displayOrgName}</Typography>
                <br />
                <Typography variant="tiny" color="neutral.black">{getAdvisorMostRecentRelevantRole(bookmark.advisor)?.title}</Typography>
              </>
            )
          },
          {
            name: 'ACTIONS',
            header: '',
            value: (bookmark) => (
              <Box sx={{ textAlign: 'right' }}>
                <Tooltip title={isCopied ? 'Copied to clipboard!' : ''}>
                  <Link
                    variant="caption"
                    color="primary.main"
                    onMouseEnter={() => setIsCopied(false)}
                    onClick={(e) => {
                      e.stopPropagation() // prevent triggering row click
                      setIsCopied(true)
                      copyToClipboard(`${__APP_HOST__}/c/advisor/${bookmark.advisor.id}`)
                    }}
                  >
                    <LinkIcon style={{ position: 'relative', top: '7px', mr: 1 }} />
                    Copy profile link
                  </Link>
                </Tooltip>
                <Link
                  variant="caption"
                  color="error.main"
                  sx={{ ml: 4 }}
                  onClick={(e) => {
                    e.stopPropagation() // prevent triggering row click
                    openDialog(
                      <ConfirmDialog
                        title="Remove advisor from list?"
                        description={`Are you sure you would like to remove ${getAdvisorMostRecentRelevantRole(bookmark.advisor).title} - ${getAdvisorMostRecentRelevantCompany(bookmark.advisor)?.displayOrgName} from ${isLoggedInUser ? 'your' : 'their'} Saved Advisors list?`}
                        cancelName={`Keep it on ${isLoggedInUser ? 'my' : 'their'} list`}
                        cancelAction={closeDialog()}
                        size="sm"
                        actions={[
                          {
                            name: 'Remove from list',
                            isDelete: true,
                            action: () => {
                              removeBookmark(bookmark.advisor.bookmarksReceived[0].id)
                              setForceRefresh(forceRefresh + 1)
                            }
                          }
                        ]}
                      />
                    )
                  }}
                >
                  Unsave
                </Link>
              </Box>
            )
          }
        ]}
      />
    </>
  )
}
SavedAdvisors.propTypes = {
  customerUserId: PropTypes.string.isRequired
}

export default SavedAdvisors
