import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography
} from '@mui/material'
import {
  DotsVertical,
  ChevronDown,
  ChevronUp
} from 'icons'

const PopoverButton = ({ button, actions, anchorOrigin, transformOrigin, onClose, disabled, buttonText }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [decoration, setDecoration] = React.useState(<ChevronDown />)
  const handlePopoverOpen = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setDecoration(<ChevronUp sx={{ color: 'white' }} />)
  }

  const handlePopoverClose = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
    setDecoration(<ChevronDown sx={{ color: 'white' }} />)
    onClose()
  }
  const textButton = buttonText && (
    <Button variant="contained">
      {buttonText}
      &nbsp;
      {decoration}
    </Button>
  )
  const buttonToClone = textButton || button || <IconButton color="primary"><DotsVertical /></IconButton>
  const filteredActions = actions?.filter(({ hidden }) => !hidden) || []

  return (
    <>
      {React.cloneElement(buttonToClone, { onClick: handlePopoverOpen, disabled })}
      <Popover
        id="mouse-over-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1, '& .MuiTypography-root': { cursor: 'pointer', p: 1 } }}>
          {filteredActions.map(({ text, action }) => (
            <Typography key={text} onClick={(e) => { action(); handlePopoverClose(e) }}>
              {text}
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  )
}

PopoverButton.defaultProps = {
  buttonText: null,
  button: null,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' },
  onClose: () => { },
  disabled: false
}

PopoverButton.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
      ]).isRequired,
      action: PropTypes.func.isRequired,
    })
  ).isRequired,
  buttonText: PropTypes.string,
  button: PropTypes.element,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  onClose: PropTypes.func,
  disabled: PropTypes.bool
}

export default PopoverButton
