import React from 'react'
import PropTypes from 'prop-types'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem
} from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { useJsonAPIGetAll } from 'hooks'
import {
  constants,
  convertDataGridFilterToJsonApiFilter,
  downloadExportedResourceAsCSV
} from 'utils-em'

const JsonApiDataGrid = ({
  type,
  columns,
  jsonApiOptions,
  disableExport,
  mode,
  initialState,
  autoHeight,
  getRowClassName,
  dependencies,
  density,
  rowsPerPageOptions,
  getRowHeight,
  ...rest
}) => {
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(rowsPerPageOptions?.[0] || constants.DEFAULT_DATAGRID_ROWS_PER_PAGE)
  const [sortBy, setSortBy] = React.useState('')
  const [columnFilter, setColumnFilter] = React.useState(null)

  // sets column visibility based on column.hidden
  const columnVisibilityModel = columns?.reduce((acc, column) => {
    if (column.hidden) {
      acc[column.field] = false
    }
    return acc
  }, {}) || {}

  const allFilters = []
  if (jsonApiOptions.filters) allFilters.push(...jsonApiOptions.filters)
  if (columnFilter) allFilters.push(columnFilter)

  const { objects, loaded, reload, totalCount } = useJsonAPIGetAll(
    type,
    {
      ...jsonApiOptions,
      ...(sortBy ? { sortBy } : {}),
      filters: allFilters,
      pageNumber: page + 1,
      pageSize
    }
  )

  /* eslint-disable-next-line react/prop-types */
  function CustomToolbar ({ csvOptions, printOptions }) {
    const CustomGridToolbarExport = ({ ...other }) => {
      const downloadCsvClickHandler = {}
      if (mode === 'server') {
        // Server-side DataGrid makes request to `/v1/export/___` to download entire CSV
        // `onClick` is added conditionally so `client` mode doesn't override the default click event
        downloadCsvClickHandler.onClick = (e) => {
          const fields = {}
          columns.filter((c) => !c.disableExport).forEach((c) => {
            fields[c.field] = (c.headerName || c.field).slice(0, 64)
          })

          downloadExportedResourceAsCSV(
            e,
            type,
            `Emissary-${type}`,
            fields,
            {
              include: jsonApiOptions.include?.join(','),
              sort: sortBy,
              filter: allFilters
            }
          )
        }
      }
      return (
        <GridToolbarExportContainer {...other}>
          <GridCsvExportMenuItem
            options={csvOptions}
            {...downloadCsvClickHandler}
          />
          <GridPrintExportMenuItem
            options={printOptions}
          />
        </GridToolbarExportContainer>
      )
    }

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {!disableExport && <CustomGridToolbarExport />}
      </GridToolbarContainer>
    )
  }

  React.useEffect(() => {
    if (mode === 'server') {
      reload()
    }
  }, [page, pageSize, sortBy, columnFilter, ...dependencies])

  return (
    <Box {...rest}>
      <Box sx={{ flexGrow: 1, '& .discarded': { opacity: '0.5' } }}>
        <DataGrid
          // use v5 documentation: https://v5.mui.com/x/react-data-grid
          autoHeight={autoHeight}
          disableSelectionOnClick
          loading={!loaded}
          columns={columns}
          rows={objects}
          rowCount={totalCount}
          sortingMode={mode}
          onSortModelChange={(newSortModel) => newSortModel.length && setSortBy(`${newSortModel[0].sort === 'desc' ? '-' : ''}${newSortModel[0].field}`)}
          filterMode={mode}
          onFilterModelChange={(newFilterModel) => setColumnFilter(convertDataGridFilterToJsonApiFilter(newFilterModel))}
          initialState={{
            columns: { columnVisibilityModel },
            ...initialState
          }}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{ toolbar: { csvOptions: { allColumns: true } } }}
          getRowClassName={(params) => (getRowClassName ? getRowClassName(params) : '')}
          columnBuffer={25}
          pagination
          paginationMode={mode}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={constants.DEFAULT_DATAGRID_ROWS_PER_PAGE_OPTIONS}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(size) => size !== pageSize && setPageSize(size)}
          sx={{
            '& .MuiDataGrid-menuIconButton': { zIndex: 9999 }
          }}
          density={density}
          getRowHeight={getRowHeight}
        />
      </Box>
    </Box>
  )
}

JsonApiDataGrid.defaultProps = {
  jsonApiOptions: {},
  disableExport: false,
  mode: 'client',
  initialState: {},
  autoHeight: true,
  getRowClassName: null,
  dependencies: [],
  density: 'standard',
  rowsPerPageOptions: constants.DEFAULT_DATAGRID_ROWS_PER_PAGE_OPTIONS,
  getRowHeight: null
}

JsonApiDataGrid.propTypes = {
  type: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  jsonApiOptions: PropTypes.object,
  disableExport: PropTypes.bool,
  mode: PropTypes.oneOf(['server', 'client']),
  initialState: PropTypes.object,
  autoHeight: PropTypes.bool,
  getRowClassName: PropTypes.func,
  dependencies: PropTypes.array,
  density: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
  getRowHeight: PropTypes.func,
}

export default JsonApiDataGrid
