import PropTypes from 'prop-types'
import React from 'react'
import HelpOutline from '@mui/icons-material/HelpOutline'
import {
  Button,
  Collapse,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material'
import { formatDateTime } from 'utils-em'
import { orderBy } from 'lodash'

const AdvisorActivityCard = ({
  advisor
}) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <Paper sx={{ marginTop: '0.5em', padding: '1em' }}>
      <Typography align="center">ADMIN ONLY </Typography>
      <Button
        variant="contained"
        onClick={() => { setExpanded(true) }}
      >
        Reveal actions
      </Button>

      <Tooltip title="Currently just off-platform and login actions, will expand in the future">
        <HelpOutline />
      </Tooltip>
      <Collapse in={expanded}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { orderBy(advisor.userActions, 'dateUpdated').map((action) => (
              <TableRow key={action.id}>
                <TableCell>{action.action}</TableCell>
                <TableCell>{formatDateTime(action.dateOfAction)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </Paper>
  )
}

AdvisorActivityCard.propTypes = {
  advisor: PropTypes.object.isRequired
}

export default AdvisorActivityCard
