import PropTypes from 'prop-types'

import React from 'react'
import { SvgIcon } from '@mui/material'

const Badge = (props) => (

  <SvgIcon {...props}>
    <svg width="24" height="24" fill={props.fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="3" />
    </svg>
  </SvgIcon>
)

Badge.defaultProps = {
  fill: null
}

Badge.propTypes = {
  fill: PropTypes.string
}

export default Badge
