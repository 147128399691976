import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { getTimezones } from 'utils-em'
import moment from 'moment'

const TimezoneSelector = ({ initialTimezone, timezoneCallback, onChange, ...rest }) => {
  const timezones = getTimezones()
  const [selectedTimezone, setSelectedTimezone] = React.useState(initialTimezone)
  React.useEffect(() => { timezoneCallback && timezoneCallback(selectedTimezone) }, [selectedTimezone])

  return (
    <Box {...rest}>
      <FormControl fullWidth>
        <InputLabel>Time zone</InputLabel>
        <Select
          value={selectedTimezone}
          onChange={(e) => {
            onChange && onChange(e)
            setSelectedTimezone(e.target.value)
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '50vh', // Limit to half of the screen height
                minHeight: '600px', // Minimum height of 600px
              },
            },
          }}
        >
          {timezones.map((timezone) => (
            <MenuItem key={timezone.value} value={timezone.value}>
              {timezone.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

TimezoneSelector.defaultProps = {
  initialTimezone: moment.tz.guess(),
  timezoneCallback: null,
  onChange: null
}

TimezoneSelector.propTypes = {
  initialTimezone: PropTypes.string,
  timezoneCallback: PropTypes.func,
  onChange: PropTypes.func, // can either use onChange or timezoneCallback
}

export default TimezoneSelector
