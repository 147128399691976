import { connect } from 'react-redux'

import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'
import UserSelector from '../UserSelector/UserSelector'

const mapStateToProps = ({ data }) => ({
  users: buildJsonApiOrdered(data, 'adminUsers')
})

const mapDispatchToProps = {
  userLookup: (userId) => JsonAPI.getOne({
    type: 'adminUsers',
    id: userId
  }),
  userSearch: (searchText) => JsonAPI.getAll({
    type: 'adminUsers',
    queryStringParams: {
      filter: [
        { name: 'fullName', op: 'like', val: `%${searchText}%` }
      ],
      'page[size]': 10
    },
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelector)
