import React from 'react'
import {
  useMediaQuery,
  useTheme
} from '@mui/material'

const useEmissaryTheme = () => {
  const theme = useTheme()
  const { breakpoints } = theme

  // breakpoints
  const isSmOrSmaller = useMediaQuery(breakpoints.down('md'))
  const isMdOrSmaller = useMediaQuery(breakpoints.down('lg'))

  // need to account for changing heights
  const [footerHeight, setFooterHeight] = React.useState(0)
  const [headerHeight, setHeaderHeight] = React.useState(0)

  // Columns
  const columns = (() => {
    switch (true) {
      case isSmOrSmaller:
        return 4
      case isMdOrSmaller:
        return 8
      default:
        return 12
    }
  })()

  // Page padding
  const { pagePaddingX, pagePaddingY } = (() => {
    switch (true) {
      case isSmOrSmaller:
        return { pagePaddingX: 3, pagePaddingY: 3 }
      case isMdOrSmaller:
        return { pagePaddingX: 5, pagePaddingY: 5 }
      default:
        return { pagePaddingX: 6, pagePaddingY: 5 }
    }
  })()
  const pagePaddingXPx = theme.spacing(pagePaddingX)
  const pagePaddingYPx = theme.spacing(pagePaddingY)

  // Vertical spacing
  const verticalSpacing = (() => {
    switch (true) {
      case isSmOrSmaller:
        return 3
      default:
        return 5
    }
  })()

  // Gutter, yes always 3, in case we want to change it later
  const gutter = 3

  // TODO this is still not a perfect calculation
  // need to figure out way to handle pages that are forced to have the height of the
  // content box, whether it be thru fancy css or a more exact calculation here
  const contentHeight = `calc(100vh - ${headerHeight} - ${footerHeight} - ${theme.spacing(pagePaddingY * 2)})`

  React.useEffect(() => {
    const updateHeights = () => {
      const footer = document.getElementById('footer')
      const header = document.getElementById('header')
      setFooterHeight(`${footer?.clientHeight || 0}px`)
      setHeaderHeight(`${header?.clientHeight || 0}px`)
    }

    // Update content height on mount and window resize
    updateHeights()
    window.addEventListener('resize', updateHeights)

    return () => {
      window.removeEventListener('resize', updateHeights)
    }
  }, [])

  return {
    ...theme,
    theme,
    headerHeight,
    contentHeight,
    isSmOrSmaller,
    isMdOrSmaller,
    pagePaddingX,
    pagePaddingY,
    pagePaddingXPx,
    pagePaddingYPx,
    verticalSpacing,
    columns,
    gutter
  }
}

export default useEmissaryTheme
