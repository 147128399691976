import * as ACTION_TYPE from './constants'

export function populateJsonApiManually (objects) {
  return {
    type: ACTION_TYPE.POPULATE_JSON_API_MANUALLY,
    objects
  }
}

export function populateJsonApi (response, params) {
  return {
    type: ACTION_TYPE.POPULATE_JSON_API,
    response,
    params
  }
}

export function populateOneJsonApi (response, params) {
  return {
    type: ACTION_TYPE.POPULATE_ONE_JSON_API,
    response,
    params
  }
}

export function createJsonApi (response, params) {
  return {
    type: ACTION_TYPE.CREATE_JSON_API,
    response,
    params
  }
}

export function updateJsonApiList (response, params) {
  return {
    type: ACTION_TYPE.UPDATE_JSON_API_LIST,
    response,
    params
  }
}

export function createJsonApiList (response, params) {
  return {
    type: ACTION_TYPE.CREATE_JSON_API_LIST,
    response,
    params
  }
}

export function updateJsonApi (response, params) {
  return {
    type: ACTION_TYPE.UPDATE_JSON_API,
    response,
    params
  }
}

export function deleteJsonApi (response, params) {
  return {
    type: ACTION_TYPE.DELETE_JSON_API,
    response,
    params
  }
}
