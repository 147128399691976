import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@mui/material'

const LoadingButton = (props) => {
  const {
    children,
    loading,
    ...rest
  } = props
  return (
    <Button {...rest}>
      {children}
      {loading && (
      <CircularProgress
        size={20}
        sx={{ ml: '8px' }}
      />
      )}
    </Button>
  )
}

LoadingButton.defaultProps = {
  loading: false,
  children: null
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node
}

export default LoadingButton
