import PropTypes from 'prop-types'
import React from 'react'

import { Box, IconButton } from '@mui/material'
import { SearchBar } from 'components'
import { MagnifyingGlass } from 'icons'
import { buildJsonApiFilter, useDebounce } from 'utils-em'

const TableSearch = ({ placeholder, applySearchFilter, disableSearch, searchFor }) => {
  const [showSearchBar, setShowSearchBar] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const debounceSearchValue = useDebounce(searchValue)

  const getFilter = (value) => {
    const filters = searchFor.map((searchField) => buildJsonApiFilter(searchField, value))
    return { or: filters }
  }

  React.useEffect(() => {
    applySearchFilter(getFilter(searchValue), searchValue)
  }, [debounceSearchValue])

  return (
    <>
      {showSearchBar && (
        <Box>
          <SearchBar
            placeholder={placeholder}
            valueCallback={(value) => !disableSearch && setSearchValue(value)}
            disableSearch={disableSearch}
            sx={{ minWidth: 400, mt: 1 }}
          />
        </Box>
      )}
      {!showSearchBar && (
      <IconButton
        color="primary"
        onClick={() => setShowSearchBar(!showSearchBar)}
        sx={{ width: '40px', height: '40px', mt: 2 }}
      >
        <MagnifyingGlass />
      </IconButton>
      )}
    </>
  )
}

TableSearch.defaultProps = {
  placeholder: '',
  disableSearch: false
}

TableSearch.propTypes = {
  placeholder: PropTypes.string,
  applySearchFilter: PropTypes.func.isRequired,
  disableSearch: PropTypes.bool,
  searchFor: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default TableSearch
