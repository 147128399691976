import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'
import { useSelector } from 'react-redux'
import { buildJsonApiOne } from 'utils-em'

const TimeAndFee = ({ customerId }) => {
  const advisorId = useSelector(({ session }) => session.id)
  const advisor = useSelector(({ data }) => buildJsonApiOne(data, 'advisors', advisorId))

  const customer = useSelector(({ data }) => buildJsonApiOne(data, 'customers', customerId))
  return (
    <Box>
      <Typography color="neutral.black" variant="bodyBold" sx={{ mt: 3 }}>
        If you accept this engagement, expect:
      </Typography>
      <Box sx={{ mt: 3, display: 'flex' }}>
        <Typography variant="bodyBold" color="neutral.black">
          Time:&nbsp;
        </Typography>
        <Typography variant="body1" color="neutral.black">
          {`One call and a handful of emails with ${customer.name} over a 30 day period`}
        </Typography>
      </Box>
      <Box sx={{ mt: 3, display: 'flex' }}>
        <Typography variant="bodyBold" color="neutral.black">
          Fee:&nbsp;
        </Typography>
        <Typography variant="body1" color="neutral.black">
          { `$${advisor.singleCallRate}` }
        </Typography>
      </Box>
      <Box sx={{ mt: 3, display: 'flex' }}>
        <Typography variant="body1" color="neutral.black">
          {'If you need to discuss the fee prior to accepting or declining this opportunity, contact us at '}
          <a href="mailto:advisor-support@emissary.io">advisor-support@emissary.io</a>
          .
        </Typography>
      </Box>
    </Box>
  )
}

TimeAndFee.propTypes = {
  customerId: PropTypes.number.isRequired
}

export default TimeAndFee
