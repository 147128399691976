import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography
} from '@mui/material'
import { IconTextCombo } from 'components'
import { ArrowRight, CheckCircle } from 'icons'
import { formatDate } from 'utils-em'

const SellerMilestoneSection = ({ userId }) => {
  const [milestones, setMilestones] = React.useState({})
  const loadMilestones = async () => {
    try {
      const res = await fetch(
        `${__API_HOST__}/v1/milestones/${userId}?requestedActions=["completed_engagement_call", "org_search", "viewed_insight"]`,
        { credentials: 'include', method: 'GET' }
      )
      if (res.error) throw new Error(res.error.message)
      const body = await res.json()
      setMilestones(body)
    } catch (error) {
      // We dont want to show an error to the user if the milestones fail to load for some reason
      console.error('Error occurred while loading milestones: ', error)
    }
  }

  React.useEffect(() => {
    loadMilestones()
  }, [])

  const renderMilestoneLine = (text, useArrowIcon) => (
    <IconTextCombo
      text={text}
      icon={useArrowIcon ? <ArrowRight /> : <CheckCircle />}
      iconColor={useArrowIcon ? 'neutral.darkGrey' : 'success.main'}
      typographyColor="neutral.black"
      typography="body"
      iconSize="medium"
      sx={{ mb: 1 }}
    />
  )

  return (
    <Box sx={{ mt: 4, p: 2, bgcolor: 'brand.lightNavy', borderRadius: 1 }}>
      <Typography variant="h4" color="neutral.black" sx={{ mb: 2 }}>
        Seller activation milestones
      </Typography>
      {'org_search' in milestones
        ? renderMilestoneLine(`Completed first advisor search on ${formatDate(milestones.org_search.date_of_action)}`)
        : renderMilestoneLine('Has not yet completed their first advisor search', true)}
      {'viewed_insight' in milestones
        ? renderMilestoneLine(`Viewed their first asset in the Insights Library on ${formatDate(milestones.viewed_insight.date_of_action)}`)
        : renderMilestoneLine('Has not viewed any assets in the Insights Library', true)}
      {'completed_engagement_call' in milestones
        ? renderMilestoneLine(`Completed first call with an advisor on ${formatDate(milestones.completed_engagement_call.date_of_action)}`)
        : renderMilestoneLine('Has not completed a call with an advisor yet', true)}
      <Typography variant="caption" color="neutral.black" sx={{ mt: 1 }}>
        Getting a seller to complete these three activities is our way of predicting who is going to be an Emissary power user!
      </Typography>
    </Box>
  )
}

SellerMilestoneSection.propTypes = {
  userId: PropTypes.string.isRequired
}

export default SellerMilestoneSection
