import Earbuds from '@mui/icons-material/Earbuds'
import {
  AISection,
  AnnotationDots,
  Cloud,
  Dollar,
  Key,
  Map,
  Server,
  Users
} from 'icons'

export const getOrgPublishedAccountPage = async (orgId) => {
  if (!orgId) return
  const res = await fetch(
    `${__API_HOST__}/v1/accountPages?filter=[{"name": "organizationId", "op": "eq", "val": ${orgId}}, {"name": "isPublished", "op": "eq", "val": true}]`,
    { credentials: 'include', method: 'GET' }
  )
  if (res.error) throw new Error(res.error.message)
  const json = await res.json()
  return json?.data[0]
}

export const getOrgHasEverHadPublishedAccountPage = async (orgId) => {
  if (!orgId) return
  const res = await fetch(
    `${__API_HOST__}/v1/accountPages?filter=[{"name": "organizationId", "op": "eq", "val": ${orgId}}, {"name": "publishDate", "op": "ne", "val": null}]`,
    { credentials: 'include', method: 'GET' }
  )
  if (res.error) throw new Error(res.error.message)
  const json = await res.json()
  return json?.meta.count === 0
}

export const getAccountPageProps = (accountPage, options = {}) => {
  const { type } = { type: 'accountPages', ...options }
  const organization = accountPage.organization || accountPage.organizations[0]
  // rich account page
  if (type === 'accountPages') {
    return {
      title: `${organization.name} Organizational Buying Profile`,
      description: `Insights into ${organization.name}'s tech spend drivers, tech biases and tech buying processes.
    Includes account-specific selling tips, analysis, and advisor ratings.`,
      detailPage: `/c/org-buying-profile-premium/${accountPage.id}`,
      isFeatured: accountPage.isFeatured
    }
  }
}

export const accountPageSectionHasData = (accountPageSectionData, dataKey = '') => {
  if (!accountPageSectionData) {
    return false
  }
  if (Array.isArray(accountPageSectionData) && accountPageSectionData.length === 0) {
    return false
  }
  if (typeof dataValue === 'object' && Object.keys(accountPageSectionData).length === 0) {
    return false
  }
  if (['buyingProcess', 'techPreferences', 'propensityToBuy'].includes(dataKey)) {
    let containsData = false
    Object.keys(accountPageSectionData).forEach((key) => {
      if (accountPageSectionData[key].sectionType === 'string' && accountPageSectionData[key].text) {
        containsData = true
      } else if (accountPageSectionData[key].sectionType === 'whisker' && (accountPageSectionData[key].quote || accountPageSectionData[key].paragraph || accountPageSectionData[key].title)) {
        containsData = true
      }
    })
    return containsData
  }

  return true
}

export const getArtificialIntelligenceSurveyData = (responsesLoaded, aiPageData, responses) => {
  const nonNullModules = []
  if (!responsesLoaded) {
    return nonNullModules
  }
  aiPageData.modules.forEach((module) => {
    if (module.key === 'levelOfAdoption') {
      const validResponses = responses.some((res) => (res.question?.topics || []).some((t) => t.key === 'levelOfAdoption'))
      const validQuant = responses.some((res) => (res.question?.topics || []).some((t) => t.key === 'levelOfAdoptionQuant'))
      if (validResponses && validQuant) {
        nonNullModules.push(module)
      }
    } else if (['impedingAIAdoption', 'aiUseCases'].includes(module.key)) {
      const validResponses = responses.some((res) => (res.question?.topics || []).some((t) => t.key === module.key))
      if (validResponses) {
        nonNullModules.push(module)
      }
    }
  })
  return nonNullModules
}

export const accountPageOrgHasChatbot = async (orgId) => {
  try {
    const res = await fetch(
      `${__API_HOST__}/v1/chatbotOrganizations`,
      { credentials: 'include', method: 'GET' }
    )
    const data = await res.json()
    if (!data || data.length === 0) {
      return false
    }
    if (data.find((org) => org.id === orgId)) {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

const ACCOUNT_WHISKER_TEMPLATE = {
  sectionType: 'whisker',
  title: '',
  quote: '',
  paragraph: ''
}

export const ACCOUNT_SELLING_TIP_TEMPLATE = {
  sectionType: 'sellingTip',
  title: '',
  quote: '',
  paragraph: ''
}

const ACCOUNT_TEXT_TEMPLATE = {
  sectionType: 'string',
  text: ''
}

export const ACCOUNT_PAGE_DATA_TEMPLATE = {
  sellingTips: [], // uses ACCOUNT_SELLING_TIP_TEMPLATE
  artificialIntelligence: {
    paragraph: '',
    title: '',
  },
  propensityToBuy: {
    techBudget: { ...ACCOUNT_WHISKER_TEMPLATE },
    riskTolerance: { ...ACCOUNT_WHISKER_TEMPLATE },
    makeOrBuy: { ...ACCOUNT_WHISKER_TEMPLATE },
    digitalTransformation: { ...ACCOUNT_WHISKER_TEMPLATE },
  },
  techPreferences: {
    techStrategy: { ...ACCOUNT_WHISKER_TEMPLATE },
    cloudApproach: { ...ACCOUNT_WHISKER_TEMPLATE },
    openSource: { ...ACCOUNT_WHISKER_TEMPLATE },
    techApproach: { ...ACCOUNT_WHISKER_TEMPLATE },
    cyberResiliency: { ...ACCOUNT_WHISKER_TEMPLATE },
  },
  buyingProcess: {
    budgetProcess: { ...ACCOUNT_WHISKER_TEMPLATE },
    influence: { ...ACCOUNT_WHISKER_TEMPLATE },
    buyingRoles: { ...ACCOUNT_TEXT_TEMPLATE },
    processSteps: { ...ACCOUNT_TEXT_TEMPLATE },
    additionalInsights: { ...ACCOUNT_TEXT_TEMPLATE },
  },
  techStack: [] // array of strings
}

export const ACCOUNT_PAGE_TEXT_DATA = {
  // Selling Tips
  sellingTips: { sectionType: 'tab', title: 'Account Selling Tips' },
  sellingTip: { sectionType: 'sellingTip', title: 'Selling Tip' },

  artificialIntelligence: { sectionType: 'tab', title: 'Artificial Intelligence' },

  weJustHeard: { sectionType: 'tab', title: 'We Just Heard' },
  chatbot: { sectionType: 'tab', title: 'Chatbot' },

  // Tech Spend Drivers (internally known as Propensity to Buy) and subsections
  propensityToBuy: { sectionType: 'tab', title: 'Tech Spend Drivers' },
  techBudget: {
    sectionType: 'whisker',
    title: 'Tech Budget Trends',
    textMin: 'Significantly shrinking',
    textMax: 'Significantly growing'
  },
  riskTolerance: {
    sectionType: 'whisker',
    title: 'Risk Tolerance for Tech Investments',
    textMin: 'Extremely risk-averse',
    textMax: 'Extremely innovative'
  },
  makeOrBuy: {
    sectionType: 'whisker',
    title: 'Preference of Build vs. Buy',
    textMin: 'Strong make in-house',
    textMax: 'Strong buy external'
  },
  digitalTransformation: {
    sectionType: 'whisker',
    title: 'Digital Transformation Progress',
    textMin: 'Minimal progress',
    textMax: 'Highly mature'
  },

  // Technology Preferences and subsections
  techPreferences: { sectionType: 'tab', title: 'Technology Approaches' },
  techStrategy: {
    sectionType: 'whisker',
    title: 'Tech Strategy Ownership',
    textMin: 'Siloed in BUs',
    textMax: 'Completely centralized'
  },
  cloudApproach: {
    sectionType: 'whisker',
    title: 'Cloud Approach',
    textMin: 'On-premise first',
    textMax: 'Cloud-first'
  },
  openSource: {
    sectionType: 'whisker',
    title: 'Perspective on Open-Source Solutions',
    textMin: 'Not supportive',
    textMax: 'Prefer open-source'
  },
  techApproach: {
    sectionType: 'whisker',
    title: 'Preference for Broad / Narrow Solutions',
    textMin: 'Favor broad solutions',
    textMax: 'Favor best-of-breed'
  },
  cyberResiliency: {
    sectionType: 'whisker',
    title: 'Cyber Resiliency',
    textMin: 'Not at all mature',
    textMax: 'Very mature'
  },

  // Buying Process and subsections
  buyingProcess: { sectionType: 'tab', title: 'Tech Buying Process' },
  budgetProcess: {
    sectionType: 'whisker',
    title: 'Annual Budgeting Process',
    textMin: 'Extremely rigid',
    textMax: 'Highly flexible'
  },
  influence: {
    sectionType: 'whisker',
    title: 'Procurement Influence on Tech Buys',
    textMin: 'Minimal influence',
    textMax: 'Powerful influence'
  },
  buyingRoles: { sectionType: 'string', title: 'Buying Roles' },
  processSteps: { sectionType: 'string', title: 'Process Steps' },
  additionalInsights: { sectionType: 'string', title: 'Additional Insights' },

  // Tech Alignment (internally known as Tech Stack)
  techStack: { sectionType: 'tab', title: 'Tech Alignment' }
}

export const ACCOUNT_PAGE_SECTION_DATA = [
  {
    key: 'chatbot',
    title: 'Chatbot',
    icon: AnnotationDots,
    text: ''
  },
  {
    key: 'weJustHeard',
    title: 'We Just Heard',
    icon: Earbuds,
    text: 'Get the latest insights and recommendations from our expert advisors.'
  },
  {
    key: 'sellingTips',
    icon: Key,
    title: 'Account Selling Tips',
    text: 'Get buyer advice for penetrating accounts and expanding your presence.',
  },
  {
    key: 'artificialIntelligence',
    icon: AISection,
    title: 'Artificial Intelligence',
    text: 'Understand the account’s current initiatives and goals around implementing AI.',
    modules: [
      {
        key: 'levelOfAdoptionQuant',
        sectionType: 'whisker',
        title: 'How would you rate your former organization\'s level of AI adoption?',
        textMin: 'Not using/researching',
        textMax: 'Wide scale adoption',
        help: '<ul><li>Qualify or disqualify an account based on budget availability.<li> Identify the business issues and entry points that are earning budgets.<li> Connect my offerings to funded priorities.</ul>',
        typeformQuestion: 'How would you rate {company name}\'s level of AI adoption?'
      },
      {
        key: 'levelOfAdoption',
        sectionType: 'quote',
        title: '',
        textMin: '',
        textMax: '',
        help: '',
        typeformQuestion: 'Please elaborate on your rating in 2-3 complete sentences.'
      },
      {
        key: 'impedingAIAdoption',
        sectionType: 'quote',
        title: 'Describe what is impeding progress towards AI adoption.',
        textMin: '',
        textMax: '',
        help: '',
        typeformQuestion: 'In 2-3 sentences, please describe what is impeding {company name}’s progress towards AI adoption?'
      },
      {
        key: 'aiUseCases',
        sectionType: 'quote',
        title: 'Please list 3 use cases where AI is being piloted or utilized within your former employer.',
        textMin: '',
        textMax: '',
        help: '',
        typeformQuestion: 'Please list 3 use cases where AI is being piloted or utilized within .'
      },
    ]
  },
  {
    key: 'propensityToBuy',
    icon: Dollar,
    title: 'Tech Spend Drivers',
    text: 'Identify account potential via budget growth, make vs. buy bias, risk appetite, and transformation approach.',
    modules: [
      {
        key: 'techBudget',
        sectionType: 'whisker',
        title: 'Tech Budget Trends',
        textMin: 'Significantly shrinking',
        textMax: 'Significantly growing',
        help: '<ul><li>Qualify or disqualify an account based on budget availability.<li> Identify the business issues and entry points that are earning budgets.<li> Connect my offerings to funded priorities.</ul>'
      },
      {
        key: 'riskTolerance',
        sectionType: 'whisker',
        title: 'Risk Tolerance for Tech Investments',
        textMin: 'Extremely risk-averse',
        textMax: 'Extremely innovative',
        help: '<ul><li>Qualify accounts based on ICP.<li> Determine which of my offerings may be most applicable based on the risk profile. <li> Determine whether my product positioning needs to more strongly emphasize leading edge innovations or safe, proven features. <li> Select the most relevant proof sources, references, and case studies.</ul>'
      },
      {
        key: 'makeOrBuy',
        sectionType: 'whisker',
        title: 'Preference of Build vs. Buy',
        textMin: 'Strong make in-house',
        textMax: 'Strong buy external',
        help: '<ul><li>Qualify accounts based on likelihood to consider. <li> Anticipate, and prepare to resolve, objections; Anticipate and prepare to sell against internal competition.<li> Determine whether to position my value as a replacement for existing capability or as a complement to those resources.</ul>'
      },
      {
        key: 'digitalTransformation',
        sectionType: 'whisker',
        title: 'Digital Transformation Progress',
        textMin: 'Minimal progress',
        textMax: 'Highly mature',
        help: '<ul><li>Qualify or prioritize an account for pursuit based on appetite for change, project funding.<li> Identify entry points and specific projects that my offering can be attached to for funding.</ul>'
      }
    ]
  },
  {
    key: 'techPreferences',
    icon: Cloud,
    title: 'Technology Approaches',
    text: 'Fuel messaging and positioning with insights into cloud, open-source, and cyber resiliency approaches.',
    modules: [
      {
        key: 'techStrategy',
        sectionType: 'whisker',
        title: 'Tech Strategy Ownership',
        textMin: 'Siloed in BUs',
        textMax: 'Completely centralized',
        help: '<ul><li>Clarify the decision-making roles of key teams across the organization: owners, decision influencers, potential coaches... <li> Focus my efforts on teams with the highest potential to act; ensure that I am engaging all involved teams appropriate to their role.</ul>'
      },
      {
        key: 'cloudApproach',
        sectionType: 'whisker',
        title: 'Cloud Approach',
        textMin: 'On-premise first',
        textMax: 'Cloud-first',
        help: '<ul><li>Qualify against ICP<li> Determine how well my offering fits, or not, with desired solution profiles (on-prem, cloud).<li> Anticipate, and prepare to resolve, potential objections.<li> Align my offering to funded migration projects.</ul>'
      },
      {
        key: 'openSource',
        sectionType: 'whisker',
        title: 'Perspective on Open-Source Solutions',
        textMin: 'Not supportive',
        textMax: 'Prefer open-source',
        help: '<ul><li>Qualify an account based on my solution type.<li> Get ahead of potential objections with initial and ongoing messaging, proof sources.<li> Identify teams that may be more open than others to solutions of this type.</ul>'
      },
      {
        key: 'techApproach',
        sectionType: 'whisker',
        title: 'Preference for Broad / Narrow Solutions',
        textMin: 'Favor broad solutions',
        textMax: 'Favor best-of-breed',
        help: '<ul><li>Qualify an account based on my solution type.<li> Get ahead of potential objections with initial and ongoing messaging, proof sources.<li> Determine how broad or narrow my product positioning should be (fits into an existing arch, or acts as an anchoring element and will replace multiple components)<li> Anticipate competitors (incumbents with broad solutions) and prepare to sell against them.</ul>'
      },
      {
        key: 'cyberResiliency',
        sectionType: 'whisker',
        title: 'Cyber Resiliency',
        textMin: 'Not at all mature',
        textMax: 'Very mature',
        help: '<ul><li>Determine how heavily to emphasize your solution’s security features<li> Identify potential objections to your solution and prepare to resolve them<li> Determine importance and timing for engaging the Security Team<li> Prioritize accounts for pursuit, alignment to ICP</ul>'
      },
    ]
  },
  {
    key: 'buyingProcess',
    icon: Map,
    title: 'Tech Buying Process',
    text: 'Align selling processes to how the account makes tech decisions, who’s involved, and budgeting cycles.',
    modules: [
      {
        key: 'budgetProcess',
        sectionType: 'whisker',
        title: 'Annual Budgeting Process',
        textMin: 'Extremely rigid',
        textMax: 'Highly flexible',
        help: '<ul><li>Determine the best time to engage the account.<li> Understand the potential to purchase at different seasons in the fiscal year to position myself for both current and future opportunities.<li> Configure messaging and solutions based on ability to buy at different points in the year.</ul>'
      },
      {
        key: 'influence',
        sectionType: 'whisker',
        title: 'Procurement Influence on Tech Buys',
        textMin: 'Minimal influence',
        textMax: 'Powerful influence',
        help: '<ul><li>Anticipate whether procurement will likely be involved in a purchase.<li> Determine when (and if) to engage procurement in the sales process.<li> Determine whether to treat procurement as a decision owner, facilitator, influencer, or other role.<li> Anticipate, and prepare for, key milestones, and hurdles, in the buying process.</ul>'
      },
      {
        key: 'buyingRoles',
        sectionType: 'string',
        title: 'Tech Buying Roles',
        bigTitle: 'Sell to these decision-making roles and influential teams.',
        fullWidth: true,
        help: '<ul><li>Determine who to engage and when to ensure final approvals.<li> Determine whether to sell up or down in the organization.<li> Align my selling time to decision-making roles - not overinvesting in relationships without authority and not missing key decision-makers.</ul>'
      },
      {
        key: 'processSteps',
        sectionType: 'string',
        title: 'Buying Process Steps',
        bigTitle: 'Align your sales process to these steps in this account’s buying process.',
        fullWidth: true,
        help: '<ul><li>Align my sales process to the account’s buying process. (e.g. know when to get registered as a preferred vendor).<li> Get the right resources aligned on my side at the right points in the process (e.g. line up solution architects).<li> Accelerate the buying process by anticipating and overcoming potential delays (e.g. engaging procurement, security or the architecture review board early)<li> Support accurate forecasting with a realistic view of buy cycle times.<li> Design pilots with an eye to typical next steps.</ul>'
      },
    ]
  },
  {
    key: 'techStack',
    icon: Server,
    title: 'Tech Alignment',
    text: 'Identify consultants to serve as entry points, vendors to partner with, and competitors to defend against.',
    help: '<ul><li>Qualify accounts based on ICP (e.g. my products work well alongside specific providers)<li> Create solution positioning based on my product features (e.g. integrations to highlight based on account tech stack)<li> Find additional routes to power via alliance partners, integrators and consultants.<li> Identify potential competitors and prepare to sell against them.<li> Identify case studies with organizations who have like architectures.</ul>'
  },
  {
    key: 'coaching',
    icon: Users,
    title: 'Get Deal Coaching with 1:1 Calls',
    text: 'Schedule time with our expert advisors to gain valuable guidance and recommendations.'
  }
]
