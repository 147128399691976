import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Quotation (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H14.625L12 19.25L9.375 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.046 8C10.4193 8 10.7287 8.11376 10.974 8.34129C11.2193 8.56882 11.342 8.86559 11.342 9.23161C11.342 9.38989 11.3153 9.55806 11.262 9.73613C11.2193 9.9043 11.1233 10.1615 10.974 10.5077L10.3186 12.1883C10.2217 12.4366 9.98257 12.6 9.71612 12.6C9.28746 12.6 8.97744 12.1905 9.09374 11.7779L9.31012 11.0104C9.41871 10.6251 9.17961 10.2363 8.942 9.91419C8.814 9.71634 8.75 9.48882 8.75 9.23161C8.75 8.86559 8.87267 8.56882 9.118 8.34129C9.36333 8.11376 9.67267 8 10.046 8Z" fill="currentColor" />
        <path d="M14.046 8C14.4193 8 14.7287 8.11376 14.974 8.34129C15.2193 8.56882 15.342 8.86559 15.342 9.23161C15.342 9.38989 15.3153 9.55806 15.262 9.73613C15.2193 9.9043 15.1233 10.1615 14.974 10.5077L14.3186 12.1883C14.2217 12.4366 13.9826 12.6 13.7161 12.6C13.2875 12.6 12.9774 12.1905 13.0937 11.7779L13.3101 11.0104C13.4187 10.6251 13.1796 10.2363 12.942 9.91419C12.814 9.71634 12.75 9.48882 12.75 9.23161C12.75 8.86559 12.8727 8.56882 13.118 8.34129C13.3633 8.11376 13.6727 8 14.046 8Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
