import { connect } from 'react-redux'
import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'

import DivisionAutocomplete from './DivisionAutocomplete'

const mapStateToProps = ({ data }) => ({
  divisions: buildJsonApiOrdered(data, 'divisions')
})

const mapDispatchToProps = {
  divisionSearch: (searchTerm, customerId) => JsonAPI.getAll({
    type: 'divisions',
    filter: [
      { name: 'name', op: 'like', val: `%${searchTerm}%` },
      { name: 'customerId', op: 'eq', val: customerId }
    ],
    sort: 'name'
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(DivisionAutocomplete)
