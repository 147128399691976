import { compose } from 'redux'
import { useSelector } from 'react-redux'

import React from 'react'
import {
  withAuthorization,
  withForcedOnboarding
} from 'enhancers'
import {
  Home,
  Settings,
  User
} from 'icons'
import { Layout } from 'layouts'

import AdvisorRoutes from '../routes'

const AdvisorLayout = () => {
  const urlHash = useSelector(({ router }) => router.location.hash)
  const advisor = useSelector(({ session }) => session)

  const links = [
    { icon: <Home />, text: 'Home', path: '/a', href: '/a' },
    { icon: <User />, text: 'My Activity', path: '/a/activity', href: '/a/activity' },
    { icon: <Settings />, text: 'My Preferences', path: '/a/profile/edit#personal-info', href: '/a/profile/edit#personal-info', validPaths: ['/a/profile'] },
  ]

  const isAdvisorInitiallyOnboarded = ![
    'not_invited',
    'invited',
    'invite_accepted',
    'sign_up_incomplete_needs_password_tcs'
  ].includes(advisor.status)
  const advisorNeedsToCompleteFullOnboarding = ['sign_up_incomplete_needs_basic_info', 'sign_up_incomplete_needs_employment_history'].includes(advisor.status)
  const isFromOnboarding = urlHash.toLowerCase() === '#onboarding'

  const visibleLinks = advisorNeedsToCompleteFullOnboarding ? [] : links

  return (
    <Layout
      links={visibleLinks}
      hideNotifications={advisorNeedsToCompleteFullOnboarding}
      hideMenu={advisorNeedsToCompleteFullOnboarding}
      hideFooter={!isAdvisorInitiallyOnboarded || isFromOnboarding}
    >
      <AdvisorRoutes />
    </Layout>
  )
}

const applyHOC = compose(
  withAuthorization,
  withForcedOnboarding
)

export default applyHOC(AdvisorLayout)
