import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Refresh (props) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.4987 3.1665L5.83203 4.6665L7.4987 6.1665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.5 12.8335L10.1667 11.3335L8.5 9.8335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.5 4.6665H8.83333C11.0425 4.6665 12.8333 6.45736 12.8333 8.6665V8.83317" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5013 11.3332H7.16797C4.95883 11.3332 3.16797 9.54231 3.16797 7.33317V7.1665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
