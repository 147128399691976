import PropTypes from 'prop-types'
import React from 'react'
import { Divider, Grid, Icon, Paper, Typography } from '@mui/material'
import {
  TreeItem,
  TreeView
} from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { sortBy } from 'lodash'

const AdvisorExpertiseCard = ({ advisor }) => {
  const getTagsByParent = (tags, parentId) => (
    tags.filter((tag) => tag.parentId === parentId)
  )

  const tags = sortBy(getTagsByParent(advisor.tags, null), 'name').filter((tag) => tag.category === 'vendor-category')

  const getTagChildren = (tagId) => {
    const childTags = getTagsByParent(advisor.tags, parseInt(tagId, 10)).map((recursiveTag) => (
      <TreeItem key={recursiveTag.id} nodeId={recursiveTag.id} label={recursiveTag.name}>
        { getTagChildren(parseInt(recursiveTag.id, 10)) }
      </TreeItem>
    ))

    if (!childTags.length) { return null }
    return childTags
  }

  if (!tags.length) { return null }
  return (
    <Paper sx={{ mt: '0.5em', p: '1em' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" align="left">
            Skills & Expertise
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={<Icon />}
          >
            { tags.map((tag) => (
              <TreeItem key={tag.id} nodeId={tag.id} label={tag.name}>
                { getTagChildren(parseInt(tag.id, 10)) }
              </TreeItem>
            ))}
          </TreeView>
        </Grid>
      </Grid>
    </Paper>
  )
}

AdvisorExpertiseCard.propTypes = {
  advisor: PropTypes.object.isRequired
}

export default AdvisorExpertiseCard
