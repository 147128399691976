import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography
} from '@mui/material'

import {
  buildJsonApiOne,
  closeDialog,
  customAlert,
  downloadAssetFromGcs,
  downloadFile,
  formatDate,
  getEngagementOrganizationName,
  getEngagementMostRelevantTimeslot
} from 'utils-em'
import { LoadingButton } from '@mui/lab'

const API_HOST = __API_HOST__

const DownloadEngagementAssetsDialog = ({ engagementId }) => {
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'customerEngagements', engagementId))
  const advisorTitle = engagement.role.title
  const accountName = getEngagementOrganizationName(engagement)
  const callStartDate = formatDate(getEngagementMostRelevantTimeslot(engagement)?.startTime, { includeYear: true })

  const [downloading, setDownloading] = React.useState(false)

  const hasTranscript = engagement.assets.some((asset) => asset.assetType === 'engagement-transcript')
  const hasRecording = engagement.assets.some((asset) => asset.assetType === 'engagement-recording')
  const hasNotes = engagement.assets.some((asset) => asset.assetType === 'engagement-notes')

  const [includeNotes, setIncludeNotes] = React.useState(hasNotes)
  const [includeRecording, setIncludeRecording] = React.useState(hasRecording)
  const [includeTranscript, setIncludeTranscript] = React.useState(hasTranscript)

  const handleDownload = async () => {
    setDownloading(true)
    const assetTypesToInclude = []
    if (includeNotes) assetTypesToInclude.push('engagement-notes')
    if (includeRecording) assetTypesToInclude.push('engagement-recording')
    if (includeTranscript) assetTypesToInclude.push('engagement-transcript')

    const assetTypesParam = `${assetTypesToInclude.map((type) => `"${type}"`).join(',')}`
    try {
      // If there's only one asset type, download it directly instead of zipping it
      if (assetTypesToInclude.length === 1) {
        const { id, documentGcsObjectName } = engagement.assets.find((asset) => asset.assetType === assetTypesToInclude[0])
        await downloadAssetFromGcs(id, documentGcsObjectName)
      } else {
        const resp = await fetch(
        `${API_HOST}/v1/export/${engagementId}/assets?assetTypes=[${assetTypesParam}]`,
        {
          credentials: 'include',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
        )
        const blob = await resp.blob()
        await downloadFile(blob, `${accountName}_insights.zip`)
      }
    } catch (e) {
      customAlert('Download failed. Please contact support.', true)
    }
    closeDialog()
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      scroll="paper"
      onClose={closeDialog}
    >
      <DialogTitle>
        Download call documents
      </DialogTitle>
      <DialogContent>
        <Typography variant="captionBold">
          Select the document types you would like to include in this download:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, ml: 1, variant: 'body1' }}>
          {hasNotes && <FormControlLabel control={<Checkbox checked={includeNotes} onChange={() => setIncludeNotes(!includeNotes)} />} label="Notes" /> }
          {hasRecording && <FormControlLabel control={<Checkbox checked={includeRecording} onChange={() => setIncludeRecording(!includeRecording)} />} label="Recording" /> }
          {hasTranscript && <FormControlLabel control={<Checkbox checked={includeTranscript} onChange={() => setIncludeTranscript(!includeTranscript)} />} label="Transcript" /> }
        </Box>
        <Typography variant="body1" sx={{ mt: 4 }}>
          {`For the call with ${advisorTitle} - ${accountName} on ${callStartDate}.`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          size="small"
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          size="small"
          onClick={handleDownload}
          loading={downloading}
          disabled={downloading || (!includeNotes && !includeRecording && !includeTranscript)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

DownloadEngagementAssetsDialog.propTypes = {
  engagementId: PropTypes.number.isRequired,
}

export default DownloadEngagementAssetsDialog
