import React from 'react'
import {
  Box,
  Grid,
  Typography,
  CircularProgress
} from '@mui/material'
import PropTypes from 'prop-types'
import {
  customAlert,
  getItemsInEqualColumns
} from 'utils-em'

const ChatbotOrganizationSelector = ({ setOrganization }) => {
  const [loading, setLoading] = React.useState(true)
  const [orgsToDisplay, setOrgsToDisplay] = React.useState(null)
  const [organizations, setOrganizations] = React.useState([])
  const getOrgs = async () => {
    try {
      setLoading(true)
      const res = await fetch(
        `${__API_HOST__}/v1/chatbotOrganizations`,
        { credentials: 'include', method: 'GET' }
      )
      const json = await res.json()
      if (!res.ok) throw new Error()
      setOrganizations(json.sort((a, b) => a.name.localeCompare(b.name)))
    } catch (error) {
      customAlert('Unable to retrieve eligible accounts', true)
    } finally {
      setLoading(false)
    }
  }
  React.useEffect(() => {
    getOrgs()
  }, [])

  React.useEffect(() => {
    setOrgsToDisplay(getItemsInEqualColumns(organizations, 3))
  }, [organizations])

  if (loading) {
    return (<div style={{ top: '50%', left: '50%', position: 'absolute' }}><CircularProgress /></div>)
  }
  const renderOrganizationColumn = (accountsInColumn) => (
    <Box sx={{ flex: '1 0 33%', display: 'flex', flexDirection: 'column' }}>
      {accountsInColumn.map((account) => (
        <Grid
          key={account?.name}
          item
          xs={12}
          onClick={() => setOrganization(account)}
          typography="body1"
          sx={{
            p: '8px 16px 8px 16px',
            display: 'flex',
            alignText: 'center',
            '&:hover': {
              backgroundColor: 'primary.lightest',
              cursor: 'pointer',
            },
          }}
        >
          <Typography variant="body1">
            {account.name}
          </Typography>
        </Grid>
      ))}
    </Box>
  )

  return (
    <Box>
      <Box sx={{ ml: 2.5, mr: 2.5, mt: 3 }} display="inline-block">
        <Typography display="inline" variant="h2">Welcome to our Advisor AI (Beta).</Typography>
        <Typography display="inline" variant="h3">This function is available for the following accounts. Select one to get started:</Typography>
      </Box>
      <Box sx={{ ml: 2, mr: 2, mt: 5, display: 'flex', flexDirection: 'row', alignItems: 'start', maxWidth: '100%' }}>
        {orgsToDisplay.map((account) => renderOrganizationColumn(account))}
      </Box>
    </Box>
  )
}

ChatbotOrganizationSelector.propTypes = {
  setOrganization: PropTypes.func.isRequired
}

export default ChatbotOrganizationSelector
