import { connect } from 'react-redux'
import { buildJsonApiAll } from 'utils-em'
import { JsonAPI } from 'store'

import DivisionPicker from './DivisionPicker'

const mapStateToProps = ({ data }) => ({
  divisions: buildJsonApiAll(data, 'divisions')
})

const mapDispatchToProps = {
  getDivisions: (searchTerm, customerId) => JsonAPI.getAll({
    type: 'divisions',
    filter: [
      { name: 'name', op: 'like', val: `%${searchTerm}%` },
      { name: 'customerId', op: 'eq', val: customerId }
    ],
    sort: 'name'
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(DivisionPicker)
