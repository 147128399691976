import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material'

const RadioButtonGroup = ({ label, options, value, handleChange, orientation }) => (
  <FormControl>
    <FormLabel id="radio-button-group-label">{label}</FormLabel>
    <RadioGroup
      aria-labelledby="radio-button-group-label"
      name="radio-button-group"
      value={value}
      onChange={handleChange}
      style={{ flexDirection: orientation === 'vertical' ? 'column' : 'row' }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
)

RadioButtonGroup.defaultProps = {
  orientation: 'vertical'
}

RadioButtonGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
}

export default RadioButtonGroup
