import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import { useJsonAPIGetAll } from 'hooks'
import { JsonAPI } from 'store'
import {
  closeDialog,
  httpResponseAlert,
} from 'utils-em'

const DeactivateCustomerUserDialog = ({ customerUser, setLoading }) => {
  const dispatch = useDispatch()

  const engagementFilters = [
    { name: 'customerUserId', op: 'eq', val: customerUser.id },
    {
      or: [
        { name: 'state', op: 'eq', val: 'active' },
        { name: 'state', op: 'eq', val: 'proposed' }
      ]
    }
  ]
  const { objects: engagements, loaded: engagementsLoaded } = useJsonAPIGetAll('engagements', { filters: engagementFilters })
  const deactivate = async () => {
    const resp = await dispatch(JsonAPI.save({ ...customerUser, isActive: false }))
    httpResponseAlert(resp)
    closeDialog('deactivateDialog')
    closeDialog() // close edit dialog as well
  }

  if (!engagementsLoaded) return null

  setLoading(false)

  return (
    <Dialog
      open
      maxWidth="xs"
      scroll="paper"
      onClose={closeDialog}
    >
      <DialogTitle>
        {engagements.length > 0 ? 'Unable to deactivate user' : `Deactivate ${customerUser.fullName}`}
      </DialogTitle>
      <DialogContent>
        {engagements.length > 0 ? (
          <>
            <Typography color="neutral.black" variant="body1" sx={{ mt: 3 }}>
              {`${customerUser.fullName} has ${engagements.length} active or pending engagement${engagements.length > 1 ? 's' : ''} and cannot be deactivated at this time.`}
            </Typography>
          </>
        ) : (
          <>
            <Typography color="neutral.black" variant="body1" sx={{ mt: 3 }}>
              {`Are you sure you want to deactivate ${customerUser.fullName}'s account? This will remove the user’s access to Emissary Exchange. You will still be able to view the user’s profile and historical data from the Teams page.`}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        { engagements.length === 0 ? (
          <>
            <Button variant="text" onClick={() => closeDialog('deactivateDialog')}>
              Cancel
            </Button>
            <Button sx={{ ml: 2 }} variant="contained" color="error" onClick={deactivate}>
              Confirm deactivation
            </Button>
          </>
        ) : (
          <Button variant="contained" onClick={() => closeDialog('deactivateDialog')}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

DeactivateCustomerUserDialog.propTypes = {
  customerUser: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default DeactivateCustomerUserDialog
