import { connect } from 'react-redux'

import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'
import UserAutocomplete from '../UserAutocomplete/UserAutocomplete'

const mapStateToProps = ({ data }) => ({
  users: buildJsonApiOrdered(data, 'customerUsers')
})

const mapDispatchToProps = {
  userSearch: (searchText, customerId, roleType) => {
    const filter = [
      { name: 'fullName', op: 'like', val: `%${searchText}%` },
      { name: 'customerId', op: 'eq', val: customerId }
    ]
    if (roleType) {
      filter.push({
        name: 'roleType', op: 'eq', val: roleType
      })
    }
    return JsonAPI.getAll({
      type: 'customerUsers',
      queryStringParams: {
        filter
      },
      sort: 'fullName'
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAutocomplete)
