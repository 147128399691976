import React, { useState } from 'react'

import Grid from '@mui/material/Grid'

import UserSearchForm from '../../../components/UserSearchForm'
import UserTable from '../../../../../../../components/UserTable'

const UserSearchLayout = () => {
  const [userType, setUserType] = useState(localStorage.getItem('user.type') || 'advisor')
  const [searchFilters, setFilters] = useState([])

  const handleUserTypeChosen = (userTypeName) => {
    setUserType(userTypeName)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <UserSearchForm
          onCustomerTypeSelected={(userTypeName) => handleUserTypeChosen(userTypeName)}
          onFilterUpdate={(filterList) => { setFilters(filterList) }}
          initialUserType={userType}
        />
      </Grid>
      <Grid item xs={9}>
        <UserTable
          addUsers
          userType={userType}
          searchFilters={searchFilters}
        />
      </Grid>
    </Grid>
  )
}

export default UserSearchLayout
