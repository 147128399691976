import { connect } from 'react-redux'
import { buildJsonApiOne, buildJsonApiOrdered } from 'utils-em'
import { forEach } from 'lodash'
import { Alerts, Dialogs, JsonAPI } from 'store'

import AdvisorSearchList from './AdvisorSearchList'

const mapStateToProps = ({ data, router, session }, ownProps) => ({
  companies: buildJsonApiOrdered(data, 'companies'),
  companiesCount: data.meta.companies.count,
  advisorRequest: buildJsonApiOne(data, 'advisorRequests', ownProps.advisorRequestId)
})

const mapDispatchToProps = {
  loadCompanies: (size, page, sort, otherFilters, isCurrent = false) => {
    const filters = [
      { name: 'deleted', op: 'is', val: false },
      { name: 'isCurrent', op: 'is', val: isCurrent },
      {
        name: 'roles',
        op: 'any',
        val: {
          name: 'deleted',
          op: 'is',
          val: false
        }
      },
      {
        name: 'advisor',
        op: 'has',
        val: {
          name: 'isActive',
          op: 'is',
          val: true
        }
      }
    ]
    if (otherFilters) {
      forEach(otherFilters, (filter, table) => {
        filters.push(filter)
      })
    }
    return JsonAPI.getAll({
      type: 'companies',
      queryStringParams: {
        include: 'advisor,roles,roles.company,advisor.companies',
        'page[number]': page,
        'page[size]': size,
        sort,
        filter: filters
      }
    })
  },
  openDialog: (dialog, key) => (dispatch) => dispatch(Dialogs.openDialog(dialog, key)),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message))
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorSearchList)
