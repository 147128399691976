import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'

import { JsonAPI } from 'store'
import { EmailChipInput } from 'components'
import {
  closeDialog,
  customAlert,
  deserializeJsonApi,
  httpResponseAlert
} from 'utils-em'
import { useJsonAPIGetOne } from 'hooks'
import UserSelector from './UserSelector'

const AddParticipantModal = ({
  engagementId,
  allowEmailUsers,
  description,
  setSavedUsers,
  setSavedEmails
}) => {
  const dispatch = useDispatch()
  const { object: engagement, loaded } = useJsonAPIGetOne(engagementId, 'engagements', ['customerUser', 'participants', 'participants.user'])
  const [selectedUsers, setSelectedUsers] = React.useState([])
  const [selectedEmails, setSelectedEmails] = React.useState([])
  const [selectableUsers, setSelectableUsers] = React.useState(null)
  const [participantsChanged, setParticipantsChanged] = React.useState(false)
  const initialParticipantUsers = engagement && engagement.participants.filter((p) => p.isUser).map((p) => p.user)
  const initialEmails = engagement ? engagement.participants.filter((p) => !p.isUser).map((p) => p.userEmail) : []
  const initialUserIds = initialParticipantUsers ? initialParticipantUsers.map((p) => parseInt(p.id, 10)) : []

  React.useEffect(() => {
    if (loaded) {
      setSelectedUsers(initialParticipantUsers)
      setSelectedEmails(initialEmails)
    }
  }, [loaded])

  React.useEffect(() => {
    if (loaded) {
      loadSelectableUsers().then((res) => {
        const loadedUsers = deserializeJsonApi(res.data)
        setSelectableUsers(loadedUsers)
      })
    }
  }, [loaded])

  React.useEffect(() => {
    const selectedUserIds = selectedUsers.map((u) => parseInt(u.id, 10))
    if (JSON.stringify([...selectedUserIds].sort()) !== JSON.stringify(initialUserIds.sort())) {
      setParticipantsChanged(true)
      return
    }
    if (JSON.stringify([...selectedEmails].sort()) !== JSON.stringify(initialEmails.sort())) {
      setParticipantsChanged(true)
      return
    }
    setParticipantsChanged(false)
  }, [selectedUsers, selectedEmails])

  if (!loaded) return null

  const { customerUserId, customerUser: { customerId } } = engagement

  const loadSelectableUsers = () => {
    const filter = [
      { name: 'id', op: 'ne', val: customerUserId },
      { name: 'customerId', op: 'eq', val: customerId },
      { name: 'isActive', op: 'is', val: true }
    ]
    return dispatch(JsonAPI.getAll({
      type: 'customerUsers',
      queryStringParams: {
        filter,
        sort: 'fullName'
      }
    }))
  }
  const saveEngagement = () => dispatch(JsonAPI.save({
    id: engagementId,
    type: 'engagements',
    participantUsers: selectedUsers,
    participantEmails: selectedEmails,
    riders: ['participantUsers', 'participantEmails']
  }))

  return (
    <Dialog open size="md">
      <DialogTitle color="neutral.black">Add participants</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'neutral.black', mb: 2 }}>
          {description}
        </DialogContentText>
        {selectableUsers && (
          <UserSelector
            selectableUsers={selectableUsers}
            initialUserIds={initialUserIds}
            userCallback={setSelectedUsers}
          />
        )}
        {allowEmailUsers && (
        <DialogContentText sx={{ color: 'neutral.black', mt: 2 }}>
          {
            `If a colleague does not have an Emissary account, you can add their email
            address. This will add them to the email message relay.`
          }
        </DialogContentText>
        )}
        {allowEmailUsers && (
          <Box sx={{ mt: 2 }}>
            <EmailChipInput
              initialEmails={initialEmails}
              emailCallback={setSelectedEmails}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <Button
          variant="contained"
          disabled={!participantsChanged}
          onClick={() => {
            saveEngagement().then((res) => {
              if (!res.ok) return httpResponseAlert(res)
              setSavedUsers(selectedUsers)
              setSavedEmails(selectedEmails)
              customAlert('Participants saved')
              closeDialog()
            })
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddParticipantModal.defaultProps = {
  setSavedUsers: () => {},
  setSavedEmails: () => {},
  description: `Select a member from your team below to add as a participant in this engagement.
  This will allow them to participate in the virtual call and any email communication with the advisor,
  as well as access call documents once the call has happened.`,
  allowEmailUsers: true
}

AddParticipantModal.propTypes = {
  engagementId: PropTypes.number.isRequired,
  allowEmailUsers: PropTypes.bool,
  description: PropTypes.string,
  setSavedUsers: PropTypes.func,
  setSavedEmails: PropTypes.func
}

export default AddParticipantModal
