import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'
import { Tabs } from 'components'
import ManagePrompts from './components/ManagePrompts'

const PromptManagement = () => (
  <Box sx={{ width: '100%', height: '100%' }}>
    <Typography variant="h2">
      Prompts
    </Typography>
    <Tabs
      tabs={[
        { name: 'Manage', component: <ManagePrompts />, hash: '#prompts' },
      ]}
      panelProps={{ minHeight: '100%' }}
      sx={{ height: '100%' }}
    />
  </Box>
)

export default PromptManagement
