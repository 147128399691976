import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Grid, Table, TableRow, TableBody, TableCell, TableHead, Tooltip } from '@mui/material'
import Warning from '@mui/icons-material/Warning'

import { downloadFile, updateItem, openDialog, closeAllDialogs } from 'utils-em'
import { ConfirmDialog } from 'components'

import EditCoverageItemDialog from '../EditCoverageItemDialog'

const API_HOST = __API_HOST__
const CLARIFICATION_THRESHOLD = 95

const CoverageReporterValidateStep = ({ defaultCoverageItemSets, onCancel }) => {
  const [saving, setSaving] = useState(false)

  const [coverageItemSets, setCoverageItemSets] = useState(defaultCoverageItemSets.map((itemSet) => {
    const newItemSet = { ...itemSet }
    if (newItemSet.matches.length) {
      // eslint-disable-next-line prefer-destructuring
      newItemSet.selectedOrg = newItemSet.matches[0]
    } else {
      newItemSet.selectedOrg = {}
    }
    return newItemSet
  }))

  const handleSetEdit = (idx, coverageItemSet) => {
    setCoverageItemSets(updateItem(coverageItemSets, idx, coverageItemSet))
    closeAllDialogs()
  }

  const discardCoverageItemSet = (idx) => {
    const discardedSet = { ...coverageItemSets[idx] }
    discardedSet.discarded = true
    handleSetEdit(idx, discardedSet)
  }

  const restoreCoverageItemSet = (idx) => {
    const discardedSet = { ...coverageItemSets[idx] }
    discardedSet.discarded = false
    handleSetEdit(idx, discardedSet)
  }

  const selectSetToClarify = (setIdx) => {
    openDialog(
      <EditCoverageItemDialog
        coverageItemIdx={setIdx}
        coverageItemSet={coverageItemSets[setIdx]}
        handleEdit={handleSetEdit}
        handleDiscard={() => {
          discardCoverageItemSet(setIdx)
          closeAllDialogs()
        }}
        handleClose={() => closeAllDialogs()}
      />
    )
  }

  const submitForAdvisorInfo = () => {
    setSaving(true)
    fetch(
      `${API_HOST}/v1/coverage/get-advisor-info`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(coverageItemSets.map((itemSet) => ({
          given: itemSet.given,
          selected_org: itemSet.selectedOrg,
          discarded: itemSet.discarded
        }))),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((resp) => resp.blob()).then((blob) => downloadFile(blob, 'advisor-results.csv'))
      .finally(() => setSaving(false))
  }

  const submitForOrgInfo = () => {
    setSaving(true)
    fetch(
      `${API_HOST}/v1/coverage/get-org-info`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(coverageItemSets.map((itemSet) => ({
          given: itemSet.given,
          selected_org: itemSet.selectedOrg,
          discarded: itemSet.discarded
        }))),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((resp) => resp.blob()).then((blob) => downloadFile(blob, 'organization-results.csv'))
      .finally(() => setSaving(false))
  }

  return (
    <>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Warn
                </TableCell>
                <TableCell>
                  Given Name
                </TableCell>
                <TableCell>
                  Closest Match
                </TableCell>
                <TableCell>
                  Closest Domain
                </TableCell>
                <TableCell>
                  Score (of 100)
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coverageItemSets.map((coverageItemSet, coverageItemSetIdx) => (
                <TableRow key={coverageItemSet.given || 1}>
                  <TableCell>
                    {coverageItemSet.matches.length === 0 ||
                      coverageItemSet.selectedOrg.score < CLARIFICATION_THRESHOLD
                      ? (
                        <Tooltip title={coverageItemSet.matches.length === 0 ? 'No matches' : `Low Score: ${coverageItemSet.selectedOrg.score}`}>
                          <Warning />
                        </Tooltip>
                        )
                      : '' }
                  </TableCell>
                  { coverageItemSet.discarded
                    ? (
                      <>
                        <TableCell colSpan={4}>
                          {coverageItemSet.given}
                          {' '}
                          (DISCARDED)
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { restoreCoverageItemSet(coverageItemSetIdx) }}
                          >
                            Restore
                          </Button>
                        </TableCell>
                      </>
                      )
                    : (
                      <>
                        <TableCell>
                          {coverageItemSet.given}
                        </TableCell>
                        <TableCell>
                          {coverageItemSet.selectedOrg.name || ''}
                        </TableCell>
                        <TableCell>
                          {coverageItemSet.selectedOrg.url || ''}
                        </TableCell>
                        <TableCell>
                          {coverageItemSet.selectedOrg.score || ''}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { selectSetToClarify(coverageItemSetIdx) }}
                          >
                            Clarify
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { discardCoverageItemSet(coverageItemSetIdx) }}
                          >
                            Discard
                          </Button>
                        </TableCell>
                      </>
                      )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: '0.5em' }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={saving}
            onClick={() => openDialog(
              <ConfirmDialog
                description="Start advisor download?"
                actions={[
                  {
                    name: 'Download',
                    action: () => { submitForAdvisorInfo() }
                  }
                ]}
              />
            )}
          >
            Get Advisors
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={saving}
            sx={{ ml: '0.5em' }}
            onClick={() => openDialog(
              <ConfirmDialog
                description="Start org download?"
                actions={[
                  {
                    name: 'Download',
                    action: () => { submitForOrgInfo() }
                  }
                ]}
              />
            )}
          >
            Get Org Info
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: '0.5em' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

CoverageReporterValidateStep.propTypes = {
  defaultCoverageItemSets: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CoverageReporterValidateStep
