import React from 'react'
import PropTypes from 'prop-types'
import {
  JsonApiDataGrid
} from 'components'

const QuestionResponseRatingsTable = ({ advisorId }) => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 75,
      valueGetter: ({ row }) => row.id,
      hidden: true
    },
    {
      field: 'surveyAssignmentId',
      headerName: 'Assignment ID',
      width: 75,
      valueGetter: ({ row }) => row.surveyAssignmentId,
    },
    {
      field: 'organizationName',
      width: 150,
      headerName: 'Account',
      valueGetter: ({ row }) => row.organizationName,
    },
    {
      field: 'expertiseName',
      width: 150,
      headerName: 'Expertise',
      valueGetter: ({ row }) => row.expertiseName,
    },
    {
      field: 'textAnswer',
      headerName: 'Rating',
      width: 75,
      valueGetter: ({ row }) => row.editedAnswerText || row.textAnswer,
    },
    {
      field: 'questionText',
      headerName: 'Question text',
      flex: 1,
      valueGetter: ({ row }) => row.questionText,
    },
  ]
  return (
    <JsonApiDataGrid
      type="questionResponses"
      columns={columns}
      jsonApiOptions={{
        include: [],
        sortBy: 'dateCreated',
        filters: [
          { name: 'userId', op: 'eq', val: advisorId },
          { name: 'expertiseId', op: 'ne', val: null }
        ]
      }}
      mode="server"
      density="compact"
      dependencies={[]}
    />
  )
}

QuestionResponseRatingsTable.propTypes = {
  advisorId: PropTypes.string.isRequired
}

export default QuestionResponseRatingsTable
