import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography
} from '@mui/material'
import {
  ArrowRight
} from 'icons'

const AdvisorContext = ({ context, fullWidth }) => {
  const bullets = context.replace(/•/gi, '').split('\n')
  return (
    <Box sx={{ flexBasis: fullWidth ? '100%' : '50%' }}>
      {bullets.filter((bullet) => bullet?.trim()?.length > 0).map((bullet) => (
        <Box
          key={bullet}
          sx={{
            display: 'flex',
            px: fullWidth ? 3 : 0,
            py: 1.5
          }}
        >
          <ArrowRight
            sx={{
              mr: 1,
              color: '',
              '& path': { stroke: ({ palette }) => palette.neutral.darkGrey }
            }}
          />
          <Typography variant="body1" color="neutral.black">
            {bullet}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

AdvisorContext.defaultProps = {
  fullWidth: false
}

AdvisorContext.propTypes = {
  context: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool

}

export default AdvisorContext
