import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import CircularProgress from '@mui/material/CircularProgress'

const APILoaderContainer = ({ onMount, children }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const done = () => setLoading(false)
    onMount(done)
  }, [])

  if (loading) { return <CircularProgress /> }
  return children
}

APILoaderContainer.propTypes = {
  onMount: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default APILoaderContainer
