import { connect } from 'react-redux'
import { queryStringParser } from 'utils-em'
import { JsonAPI } from 'store'

import AdvisorSearchLayout from '../layouts/AdvisorSearchLayout'

const mapStateToProps = ({ router }) => ({
  advisorRequestId: parseInt(queryStringParser(router.location).advisorRequestId, 10)
})

const mapDispatchToProps = {
  getOne: JsonAPI.getOne
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorSearchLayout)
