export function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject(src)
    img.src = src
  })
}

export function isImageCached (src) {
  const image = new Image()
  image.src = src
  return image.complete
}
