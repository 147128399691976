import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Grid, TextField, FormControl } from '@mui/material'

import {
  AssetTypeSelector,
  AssetStatusSelector,
  CustomerAutocomplete,
  OrganizationSelector,
  TagPicker
} from 'components'

const AssetListFilters = ({ assets, setFilteredAssets }) => {
  const [keyword, setKeyWord] = useState('')
  const [assetTypes, setAssetTypes] = useState('')
  const [assetStatus, setAssetStatus] = useState('')
  const [orgId, setOrgId] = useState('')
  const [verticals, setVerticals] = useState([])
  const [buyerTypes, setBuyerTypes] = useState([])
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    let filteredAssets = assets.filter((asset) => (asset.title.toLowerCase().includes(keyword.toLowerCase()) ||
      (asset.description && asset.description.toLowerCase().includes(keyword.toLowerCase()))))
    if (assetTypes.length > 0) {
      filteredAssets = filteredAssets.filter((asset) => assetTypes.map((insightType) => insightType.value).includes(asset.assetType))
    }
    if (orgId) {
      filteredAssets = filteredAssets.filter((asset) => asset.organizations.some((o) => parseInt(o.id, 10) === orgId))
    }
    if (verticals.length > 0) {
      filteredAssets = filteredAssets.filter((asset) => asset.tags.some((t) => verticals.map((v) => v.id).includes(t.id)))
    }
    if (buyerTypes.length > 0) {
      filteredAssets = filteredAssets.filter((asset) => asset.tags.some((t) => buyerTypes.map((b) => b.id).includes(t.id)))
    }
    if (customers.length > 0) {
      filteredAssets = filteredAssets.filter((asset) => asset.authorizedCustomers.some((c) => customers.map((cust) => cust.id).includes(c.id)))
    }
    if (assetStatus.length > 0) {
      if (assetStatus.find((s) => s.name.toLowerCase() === 'featured')) {
        filteredAssets = filteredAssets.filter((asset) => asset.isFeatured === true)
      }
      if (assetStatus.find((s) => s.name.toLowerCase() === 'archived')) {
        filteredAssets = filteredAssets.filter((asset) => asset.isArchived === true)
      }
    }

    setFilteredAssets(filteredAssets)
  }, [keyword, assetTypes, assetStatus, orgId, verticals, buyerTypes, customers])

  return (
    <>
      <Grid container spacing={2} sx={{ m: '1rem' }}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="Search Title & Description"
              defaultValue={keyword}
              onChange={(event) => setKeyWord(event.target.value)}
              margin="normal"
              size="small"
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <AssetTypeSelector
              multiple
              onChange={(selectedType) => setAssetTypes(selectedType)}
              placeholder=""
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} sx={{ mt: 2 }}>
          <OrganizationSelector
            label="Search by account"
            noOptionsText="enter account name"
            placeholder=""
            organizationId={null}
            handleChange={(org) => { org ? setOrgId(org.id) : setOrgId('') }}
          />
        </Grid>
        <Grid item xs={2}>
          <TagPicker
            label="Verticals"
            placeholder=""
            type="vertical"
            addTag={(tag) => setVerticals((previous) => [...previous, tag])}
            removeTag={(tag) => setVerticals((previous) => [...previous.filter((t) => t.id !== tag.id)])}
            selectedTags={verticals}
            margin="normal"
          />
        </Grid>
        <Grid item xs={1}>
          <TagPicker
            label="Department"
            placeholder=""
            type="buyer-type"
            addTag={(tag) => setBuyerTypes((previous) => [...previous, tag])}
            removeTag={(tag) => setBuyerTypes((previous) => [...previous.filter((t) => t.id !== tag.id)])}
            selectedTags={buyerTypes}
            margin="normal"
          />
        </Grid>
        <Grid item xs={2}>
          <CustomerAutocomplete
            multiple
            label="Customer permissions"
            onChange={(_, orgs) => setCustomers(orgs)}
            size="small"
            value={[]}
          />
        </Grid>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <AssetStatusSelector
              onChange={(selectedType) => setAssetStatus(selectedType)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

AssetListFilters.defaultProps = {
}

AssetListFilters.propTypes = {
  assets: PropTypes.array.isRequired,
  setFilteredAssets: PropTypes.func.isRequired
}

export default AssetListFilters
