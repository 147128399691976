import PropTypes from 'prop-types'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Chip,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  useTheme
} from '@mui/material'
import {
  Download,
  Clipboard,
  DotsVertical,
  Heart,
  Star,
} from 'icons'
import {
  ACTION_CONSTANTS,
  buildJsonApiOne,
  customAlert,
  copyToClipboard,
  recordUserAction,
  navigateByEvent,
  openDialog
} from 'utils-em'
import { AssetIcon, ClickableCard, DownloadDialog } from 'components'
import { JsonAPI } from 'store'

const AssetCard = ({ assetId, assetTypesEnum, clickOrigin, bookmarkId, ...rest }) => {
  const asset = useSelector(({ data }) => buildJsonApiOne(data, 'assets', assetId))
  const userId = useSelector(({ session }) => session.id)
  const dispatch = useDispatch()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [currentBookmarkId, setCurrentBookmarkId] = React.useState(bookmarkId)
  const isMenuOpen = Boolean(anchorEl)

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const saveInsight = async () => {
    const res = await dispatch(JsonAPI.create({
      type: 'bookmarks',
      ownerId: userId,
      bookmarkType: 'SAVED_INSIGHT',
      bookmarkTarget: assetId
    }))
    setCurrentBookmarkId(res.data.data.id)
  }

  const deleteSavedInsight = async () => {
    await dispatch(JsonAPI.delete({
      type: 'bookmarks',
      id: currentBookmarkId
    }))
    setCurrentBookmarkId(null)
  }

  const handleSaveInsight = (e) => {
    e.stopPropagation()
    if (currentBookmarkId === null) {
      saveInsight()
      customAlert('Insight saved. You can access your saved insights from your "My Saved Lists" page.')
    } else {
      deleteSavedInsight()
      customAlert('Insight removed from saved.')
    }
  }

  return (
    <>
      {/* Note: The <Menu> is purposely outside of the <Card> due to issues
      with click event propagation bubbling up to the Card and redirecting
      the user to the detail page. */}
      <Menu
        open={isMenuOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={(e) => handleSaveInsight(e)}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Heart
              sx={{
                mr: 1,
                color: currentBookmarkId !== null ? theme.palette.brand.orange : 'brand.lightGrey',
                '&:hover': { fill: theme.palette.brand.orange, color: theme.palette.brand.orange },
                fill: currentBookmarkId !== null ? theme.palette.brand.orange : 'none'
              }}
            />
            {currentBookmarkId !== null ? 'Insight saved' : 'Save insight'}
          </Box>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            recordUserAction(ACTION_CONSTANTS.copiedInsightLink, asset)
            copyToClipboard(
              `${__APP_HOST__}/c/insights/${asset.id}`,
              () => { customAlert('Insight link copied to clipboard.') }
            )
            handleClose()
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 1, mt: 1 }}>
              <Clipboard />
            </Box>
            Copy link to clipboard
          </Box>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            openDialog(<DownloadDialog asset={asset} />)
            handleClose()
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 1 }}>
              <Download />
            </Box>
            Download insight
          </Box>
        </MenuItem>
      </Menu>
      <ClickableCard
        {...rest}
        variant="outlined"
        onClick={(e) => {
          clickOrigin && recordUserAction(ACTION_CONSTANTS.clickedInsightCard, asset, 'asset', { origin: clickOrigin })
          navigateByEvent(`/c/insights/${asset.id}`, e)
        }}
        headerText={asset.title}
        headerChip={(
          <IconButton
            color="primary"
            sx={{ float: 'right', width: 32, height: 32 }}
            onClick={handleClick}
            onMouseDown={(e) => e.stopPropagation()} // disable ripple effect on parent
          >
            <DotsVertical sx={{ color: 'neutral.darkGrey' }} />
          </IconButton>
        )}
        bodyContent={(
          <>
            <Box sx={{ display: 'flex', margin: '12px 0px 18px 0px' }}>
              {asset.isFeatured && (
                <Chip
                  sx={{ backgroundColor: 'brand.lightestPurple', mr: 1 }}
                  icon={<Star stroke={theme.palette.brand.purple} />}
                  label="Featured"
                  size="large"
                />
              )}
              <Chip
                sx={{ backgroundColor: 'transparent' }}
                icon={(
                  <AssetIcon
                    assetType={asset.assetType}
                    sx={{ mr: 0.5, color: 'neutral.darkGrey' }}
                  />
                  )}
                label={assetTypesEnum.find((x) => x.value === asset.assetType)?.name}
                size="large"
              />
            </Box>
            <Typography variant="body1" align="left" component="div">
              {asset.description}
            </Typography>
          </>
        )}
      />
    </>
  )
}

AssetCard.defaultProps = {
  clickOrigin: null,
  bookmarkId: null
}

AssetCard.propTypes = {
  assetId: PropTypes.string.isRequired,
  assetTypesEnum: PropTypes.array.isRequired,
  bookmarkId: PropTypes.string,
  clickOrigin: PropTypes.string
}

export default AssetCard
