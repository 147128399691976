import PropTypes from 'prop-types'
import React from 'react'
import {
  Collapse,
  Grid,
  Button,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  Typography,
} from '@mui/material'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowForward from '@mui/icons-material/ArrowForward'

import { constants, formatDate } from 'utils-em'

import TablePaginationActions from '../../../../../../../../components/TablePaginationActions'
import EditContractDialog from '../EditContractDialog'
import ChargeSkuDialog from '../../../../../../../../components/ChargeSkuDialog'
import ContractInfoRow from '../../../../../../../../components/ContractInfoRow'

const ContractsList = ({
  customerId,
  getAllContracts,
  contracts,
  totalCount,
  openDialog
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [expandedList, setExpandedList] = React.useState([])

  const toggleExpanded = (contract) => {
    if (expandedList.indexOf(contract.id) !== -1) {
      setExpandedList(expandedList.filter((i) => i !== contract.id))
    } else {
      setExpandedList(expandedList.concat(contract.id))
    }
  }

  const getDivisionAndManagerName = (divisions) => (
    divisions?.map((d) => {
      if (d.customerUsers?.length) {
        const managers = d.customerUsers.filter((cu) => cu.roleType === 'manager')
        return `${d.name} ${managers && managers.length > 0 ? `(${managers.map((cu) => cu.fullName).join(', ')})` : ''}`
      }
      return d.displayName
    })
  )

  React.useEffect(() => {
    getAllContracts(
      customerId,
      rowsPerPage,
      page + 1
    )
  }, [page, rowsPerPage])

  return (
    <>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ m: '1em' }} variant="h4">Contracts</Typography>
          </Grid>

          <Grid item xs={4}>
            <Button
              sx={{ ml: '1em' }}
              color="primary"
              variant="contained"
              onClick={() => openDialog((
                <EditContractDialog
                  customerId={customerId}
                />
              ))}
            >
              Create Contract
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Salesforce ID</TableCell>
                  <TableCell>Sign Date</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Extension Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Teams</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { contracts.map((contract) => (
                  <React.Fragment key={contract.id}>
                    <TableRow>
                      <TableCell>
                        <Button
                          color="primary"
                          onClick={() => { toggleExpanded(contract) }}
                        >
                          { expandedList.indexOf(contract.id) === -1 ? (<ArrowForward />) : (<ArrowDownward />) }
                        </Button>
                      </TableCell>
                      <TableCell>{contract.id}</TableCell>
                      <TableCell>{contract.name}</TableCell>
                      <TableCell>{contract.sfId}</TableCell>
                      <TableCell>{formatDate(contract.dateSigned)}</TableCell>
                      <TableCell>{formatDate(contract.startDate)}</TableCell>
                      <TableCell>{formatDate(contract.endDate)}</TableCell>
                      <TableCell>{contract.extensionDate && formatDate(contract.extensionDate)}</TableCell>
                      <TableCell>{contract.amount}</TableCell>
                      <TableCell>{getDivisionAndManagerName(contract.divisions).join(', ')}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          onClick={() => openDialog((
                            <ChargeSkuDialog
                              contractId={contract.id}
                            />
                          ))}
                        >
                          Charge
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => openDialog((
                            <EditContractDialog
                              customerId={customerId}
                              contractId={contract.id}
                            />
                          ))}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ height: 0 }}>
                      <TableCell
                        sx={{
                          padding: '0px',
                          borderBottom: 'none',
                          columnSpan: 'all'
                        }}
                        colSpan="100%"
                      >
                        <Collapse in={expandedList.indexOf(contract.id) !== -1}>
                          <ContractInfoRow
                            editable
                            contractId={parseInt(contract.id, 10)}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true
                    }}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value)
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

ContractsList.propTypes = {
  customerId: PropTypes.number.isRequired,
  getAllContracts: PropTypes.func.isRequired,
  contracts: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default ContractsList
