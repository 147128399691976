import PropTypes from 'prop-types'
import React from 'react'

import APILoaderContainer from '../../../../../containers/APILoaderContainer'
import CustomerList from '../components/CustomerList'

const CustomerListLayout = ({ getEnum }) => (
  <APILoaderContainer
    onMount={(done) => {
      Promise.all([
        getEnum({ type: 'statuses' })
      ]).then(() => { done() })
    }}
  >
    <CustomerList />
  </APILoaderContainer>
)

CustomerListLayout.propTypes = {
  getEnum: PropTypes.func.isRequired
}

export default CustomerListLayout
