import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import moment from 'moment'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Grid,
  Link,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Attachment from '@mui/icons-material/Attachment'
import Favorite from '@mui/icons-material/Favorite'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import Image from '@mui/icons-material/Image'
import StarBorder from '@mui/icons-material/StarBorder'
import Star from '@mui/icons-material/Star'

import { customAlert, gcsFilenameFormatter } from 'utils-em'

import {
  AssetTypeSelector,
  CustomerAutocomplete,
  LoadingButton,
  OrganizationMultiSelector,
  TagPicker
} from 'components'

import { JsonAPI } from 'store'

const featuredTextStyle = { fontWeight: '400', fontSize: '16px', ml: '5px' }

const EditAssetDialog = ({
  asset,
  handleClose,
  updateAssets
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [title, setTitle] = useState(asset.title)
  const [insightType, setInsightType] = useState(asset.assetType)
  const [verticals, setVerticals] = useState(asset.tags ? asset.tags.filter((t) => t.category === 'vertical') : [])
  const [buyerType, setBuyerType] = useState(asset.tags ? asset.tags.filter((t) => t.category === 'buyer-type') : [])
  const [collectionDate, setCollectionDate] = useState(asset.collectionDates ? asset.collectionDates : null)
  const [description, setDescription] = useState(asset.description)
  const [note, setNote] = useState(asset.librarianNotes)
  const [isFeatured, setIsFeatured] = useState(asset.isFeatured)
  const [customers, setCustomers] = useState(asset.authorizedCustomers || [])
  const [allowAllCustomers, setAllowAllCustomers] = useState(asset.allowAllCustomers || false)
  const [isSuggested, setIsSuggested] = useState(asset.isSuggested)
  const [assetFile, setAssetFile] = useState(null)
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [organizations, setOrganizations] = useState(asset.organizations || [])

  const getAssetToSave = () => ({
    ...asset,
    title,
    assetType: insightType,
    description,
    librarianNotes: note,
    isFeatured,
    authorizedCustomers: customers,
    tags: [...verticals, ...buyerType],
    collectionDates: collectionDate || null,
    allowAllCustomers,
    isSuggested,
    organizations: organizations.map((org) => ({ id: org.id, type: 'organizations' }))
  })

  const saveAsset = () => {
    const assetToSave = getAssetToSave()
    const method = assetToSave.id ? JsonAPI.save : JsonAPI.create

    // if there is no documentGcsObjectName, delete the key so it can re-save
    if (!assetToSave.documentGcsObjectName) delete assetToSave.documentGcsObjectName

    return dispatch(method({
      ...assetToSave,
      type: 'assets',
      relationshipNames: ['authorizedCustomers', 'tags', 'organizations'],
      queryStringParams: {
        include: ['authorizedCustomers', 'tags', 'organizations'].join(',')
      }
    }))
  }

  const uploadFile = async (assetId, file) => {
    if (!file) return
    const endpoint = new URL(`${__API_HOST__}/v1/assets/${assetId}/upload-asset`)
    endpoint.searchParams.set('thumbnail', file === thumbnailFile)

    const formData = new FormData()
    formData.append('file', file)
    return fetch(endpoint.toString(), { credentials: 'include', method: 'POST', body: formData })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const files = []
    if (assetFile) files.push(assetFile)
    if (thumbnailFile) files.push(thumbnailFile)

    try {
      // save asset record
      const assetRes = await saveAsset()
      if (assetRes.error) throw new Error(assetRes.error.message)
      const assetId = assetRes.data.data.id
      customAlert(`Asset saved${files.length > 0 ? ', please wait for file to upload' : ''}`)

      // upload file and thumbnail
      const uploadRes = await Promise.all(files.map((file) => uploadFile(assetId, file)))
      const okFileNames = []
      const errorFileNames = []
      uploadRes.forEach((fileRes, i) => ((fileRes.ok) ? okFileNames.push(files[i].name) : errorFileNames.push(files[i].name)))
      if (errorFileNames.length > 0) throw new Error(`${errorFileNames.join(' and ')} upload failed, mostly likely due to file size. Please try again or contact engineering if problem persists`)
      if (okFileNames.length > 0) customAlert(`${okFileNames.join(' and ')} successfully uploaded`)
    } catch (error) {
      customAlert(error.message, true)
    }
    updateAssets()
    setIsLoading(false)
    handleClose()
  }

  const requirementsMet = () => {
    const assetGcsObjectName = asset.documentGcsObjectName ? gcsFilenameFormatter(asset.documentGcsObjectName)[0] : null
    const thumbnailGcsObjectName = asset.thumbnailGcsObjectName ? gcsFilenameFormatter(asset.thumbnailGcsObjectName)[0] : null
    const assetFileName = assetFile ? assetFile.name : assetGcsObjectName
    const thumbnailFileName = thumbnailFile ? thumbnailFile.name : thumbnailGcsObjectName
    return (
      title &&
      insightType &&
      description &&
      (assetFile || asset.documentGcsObjectName) &&
      (allowAllCustomers || customers.length > 0) &&
      assetFileName !== thumbnailFileName
    )
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      scroll="paper"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={() => handleSubmit()}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '20px' }}>
          {`${asset.id ? 'Edit' : 'Upload new '} document`}
          <Box>
            <Button
              variant="text"
              startIcon={isSuggested ? <Favorite sx={{ fill: '#dd7a64' }} /> : <FavoriteBorder />}
              onClick={() => setIsSuggested(!isSuggested)}
              size="large"
              sx={{ '&:hover': { textDecoration: 'none' } }}
            >
              <Typography sx={{ ...featuredTextStyle }}>Suggested asset</Typography>
            </Button>
            <Button
              variant="text"
              startIcon={isFeatured ? <Star sx={{ fill: '#E8AA4A' }} /> : <StarBorder />}
              onClick={() => setIsFeatured(!isFeatured)}
              size="large"
              sx={{ '&:hover': { textDecoration: 'none' } }}
            >
              <Typography sx={{ ...featuredTextStyle }}>Featured Insight</Typography>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextValidator
                required
                fullWidth
                label="Title"
                validators={['required']}
                errorMessages={['Required Field']}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AssetTypeSelector
                onChange={(assetType) => setInsightType(assetType)}
                insightTypes={[insightType]}
                required
                placeholder=""
                StartIcon={null}
                multiple={false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <OrganizationMultiSelector
                initialSelectedOrgs={organizations}
                selectedOrgsCallback={setOrganizations}
              />
            </Grid>
            <Grid item xs={6}>
              <TagPicker
                label="Verticals (Focus Industries)"
                placeholder=""
                hasAllOption
                type="vertical"
                addTag={(tag) => setVerticals((previous) => [...previous, tag])}
                removeTag={(tag) => setVerticals((previous) => [...previous.filter((t) => t.id !== tag.id)])}
                selectedTags={verticals}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TagPicker
                compact
                label="Department"
                placeholder=""
                hasAllOption
                type="buyer-type"
                addTag={(tag) => setBuyerType((previous) => [...previous, tag])}
                removeTag={(tag) => setBuyerType((previous) => [...previous.filter((t) => t.id !== tag.id)])}
                selectedTags={buyerType}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                sx={{ width: '100%' }}
                views={['year', 'month']}
                label="Data collection date"
                value={moment(collectionDate)}
                onChange={(date) => setCollectionDate(date ? date.toISOString() : null)}
                renderInput={(params) => <TextValidator fullWidth margin="normal" {...params} />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Description"
                required
                errorMessages={['Required Field']}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Librarian notes (not visible to end user)"
                defaultValue={note}
                onChange={(event) => setNote(event.target.value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sx={{ marginTop: '10px' }}>
              <Typography variant="caption">
                Customer permissions: Select the customers who are allowed to see this insight *
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '-15px' }}>
            <Grid item xs={2}>
              <FormControlLabel
                sx={{ marginTop: '30px' }}
                control={(
                  <Checkbox
                    checked={allowAllCustomers}
                    onChange={(e) => setAllowAllCustomers(e.target.checked)}
                  />
                  )}
                label="All customers"
              />
            </Grid>
            <Grid item xs={10}>
              <CustomerAutocomplete
                multiple
                label="Select customers"
                onChange={(_, orgs) => setCustomers(orgs)}
                size="small"
                initCustomers={customers}
                disabled={allowAllCustomers}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: '20px' }}>
            <label htmlFor="asset-file-input">
              <Button
                variant="text"
                size="large"
                color="primary"
                component="span"
                startIcon={<Attachment />}
              >
                Upload file
              </Button>
              <input
                id="asset-file-input"
                type="file"
                defaultValue={assetFile}
                onChange={(e) => setAssetFile(e.target.files[0])}
                style={{ display: 'none' }}
              />
            </label>
            {!assetFile && asset.documentGcsObjectName ? (
              <Link
                className="asset-file-link"
                target="_blank"
                href={`${__API_HOST__}/v1/assets/${asset.id}/download`}
              >
                {gcsFilenameFormatter(asset.documentGcsObjectName)}
              </Link>
            )
              : <label htmlFor="asset-file-link">{assetFile ? assetFile.name : ''}</label>}
          </Grid>
          <Grid sx={{ mt: '20px' }}>
            <label htmlFor="thumbnail-file-input">
              <Button
                variant="text"
                size="large"
                color="primary"
                component="span"
                startIcon={<Image />}
              >
                Upload thumbnail
              </Button>
              <input
                id="thumbnail-file-input"
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                defaultValue={thumbnailFile}
                onChange={(e) => setThumbnailFile(e.target.files[0])}
                style={{ display: 'none' }}
              />
            </label>
            {!thumbnailFile && asset.thumbnailGcsObjectName ? (
              <Link
                className="thumbnail-file-link"
                target="_blank"
                href={`${__API_HOST__}/v1/assets/${asset.id}/download?thumbnail=true`}
              >
                {gcsFilenameFormatter(asset.thumbnailGcsObjectName)}
              </Link>
            )
              : <label htmlFor="thumbnail-file-link">{thumbnailFile ? thumbnailFile.name : ''}</label>}
          </Grid>
          {insightType && insightType.value === 'video' ? (
            <Typography color="neutral.disabled">We only support video files with extension .mp4, .webm, .ogv and .m3u8</Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={isLoading} disabled={!requirementsMet() || isLoading} type="submit" variant="contained" color="primary">Save</LoadingButton>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditAssetDialog.defaultProps = {
  asset: {}
}

EditAssetDialog.propTypes = {
  updateAssets: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  asset: PropTypes.object
}

export default EditAssetDialog
