import PropTypes from 'prop-types'
import React from 'react'
import { merge } from 'lodash'
import { Button, Divider, Grid, Typography } from '@mui/material'
import Add from '@mui/icons-material/Add'

import { formatMonthYearForHumans, sortActiveCompaniesByRecency, calculateCompanyTenure } from 'utils-em'

import EditExperienceDialog from '../EditExperienceDialog'

const AdvisorExperienceCard = ({ advisorId, companies, saveCompany, openDialog, unnestedRoleId, allowAddEditDelete, userIsAdmin }) => {
  const handleDeleteExperience = (company) => {
    saveCompany(merge(
      {},
      company,
      { deleted: true }
    ))
  }

  return (
    <>
      <Typography variant="h5" align="left">
        Employment History
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        { sortActiveCompaniesByRecency(companies.filter((company) => userIsAdmin || !company.deleted)).map((company) => (
          <Grid item xs={12} key={company.id}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ mt: '0.5em', strikeThrough: company.deleted ? 'line-through' : 'none' }}>
                  <b>{`${company.orgName}${company.isCurrent ? ' (current)' : ''}`}</b>
                </Typography>
              </Grid>
              {allowAddEditDelete
                ? (
                  <Grid item xs={2} sx={{ mt: '0.5em' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => openDialog(
                        <EditExperienceDialog
                          advisorId={advisorId}
                          companyId={company ? company.id : null}
                          unnestedRoleId={unnestedRoleId}
                        />
                      )}
                    >
                      Edit
                    </Button>
                  </Grid>
                  )
                : <Grid item xs={2} />}
              {allowAddEditDelete
                ? (
                  <Grid item xs={3} sx={{ mt: '0.5em' }}>
                    <Button color="primary" variant="contained" onClick={() => handleDeleteExperience(company)}>
                      Delete
                    </Button>
                  </Grid>
                  )
                : <Grid item xs={3} />}
              <Grid item xs={1} sx={{ mt: '0.5em' }}>
                <Typography variant="h6" style={{ marginLeft: '0.5em' }}>
                  { calculateCompanyTenure(company, 'calculateDecimalYears') || 0 }
                </Typography>
                <Typography variant="subtitle1" style={{ marginTop: '-0.5em' }}>
                  <b>YEARS</b>
                </Typography>
              </Grid>
              { company.roles.filter((role) => (allowAddEditDelete && userIsAdmin) || !role.deleted).map((role) => (
                <React.Fragment key={role.id}>
                  <Grid item xs={12} sx={{ strikeThrough: company.deleted ? 'line-through' : 'none' }}>
                    <Typography variant="subtitle1">
                      {role.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ strikeThrough: company.deleted ? 'line-through' : 'none' }}>
                    <Typography variant="subtitle1">
                      {role.seniority ? role.seniority : '' }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ strikeThrough: company.deleted ? 'line-through' : 'none' }}>
                    <Typography variant="subtitle1">
                      {role.tags && role.tags.find((t) => t.category === 'region') ? role.tags.find((t) => t.category === 'region').name : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ strikeThrough: company.deleted ? 'line-through' : 'none' }}>
                    <Typography color="textSecondary" gutterBottom variant="subtitle1">
                      { formatMonthYearForHumans(role.dateStarted) }
                      {' '}
                      -
                      {' '}
                      { role.dateEnded ? formatMonthYearForHumans(role.dateEnded) : 'Current' }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ strikeThrough: company.deleted ? 'line-through' : 'none' }}>
                    <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-wrap' }}>
                      {role.description ? role.description : null }
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Divider />
          </Grid>
        ))}
        {allowAddEditDelete
          ? (
            <Grid item>
              <Button
                color="primary"
                onClick={() => openDialog(
                  <EditExperienceDialog
                    advisorId={advisorId}
                  />
                )}
              >
                <Add />
                {' '}
                Add New Company
              </Button>
            </Grid>
            )
          : null}
      </Grid>
    </>
  )
}

AdvisorExperienceCard.defaultProps = {
  unnestedRoleId: null,
  allowAddEditDelete: false
}

AdvisorExperienceCard.propTypes = {
  advisorId: PropTypes.number.isRequired,
  companies: PropTypes.array.isRequired,
  saveCompany: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  unnestedRoleId: PropTypes.string,
  allowAddEditDelete: PropTypes.bool,
  userIsAdmin: PropTypes.bool.isRequired
}

export default AdvisorExperienceCard
