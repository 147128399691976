import PropTypes from 'prop-types'
import React from 'react'
import {
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import { Check } from 'icons'

const OnboardingStepper = ({ currentStep, forceFinalStage }) => {
  const renderStep = (step) => {
    const isComplete = currentStep > step
    const icon = isComplete || forceFinalStage ? <Check sx={{ padding: '4px', color: 'brand.slate', borderRadius: '50%', backgroundColor: 'brand.paleSlate' }} /> : null
    return (
      <Step key={step}>
        <StepLabel icon={icon} />
      </Step>
    )
  }

  return (
    <Stepper activeStep={currentStep - 1} sx={{ mb: 3 }}>
      {renderStep(1)}
      {renderStep(2)}
      {renderStep(3)}
    </Stepper>
  )
}

OnboardingStepper.defaultProps = {
  forceFinalStage: false
}

OnboardingStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  forceFinalStage: PropTypes.bool
}

export default OnboardingStepper
