import PropTypes from 'prop-types'
import React from 'react'
import APILoaderContainer from '../../../../../../../containers/APILoaderContainer'
import EditCustomer from '../../../../CustomerList/components/EditCustomer'

const CustomerLayout = ({ getEnum, customerId }) => (
  <APILoaderContainer
    onMount={(done) => {
      Promise.all([
        getEnum({ type: 'statuses' })
      ]).then(() => { done() })
    }}
  >
    <EditCustomer customerId={customerId} />
  </APILoaderContainer>
)
CustomerLayout.defaultProps = {
  customerId: null
}

CustomerLayout.propTypes = {
  customerId: PropTypes.number,
  getEnum: PropTypes.func.isRequired
}

export default CustomerLayout
