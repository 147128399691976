import { connect } from 'react-redux'
import { Alerts, JsonAPI } from 'store'

import OrganizationSelector from './OrganizationSelector'

const mapDispatchToProps = {
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  loadOrgJsonApi: (organizationId) => JsonAPI.getOne({
    type: 'organizations',
    id: organizationId
  })
}

export default connect(null, mapDispatchToProps)(OrganizationSelector)
