import React from 'react'
import PropTypes from 'prop-types'
import {
  JsonApiDataGrid
} from 'components'

const ExpertiseRatingsTable = ({ advisorId }) => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 75,
      valueGetter: ({ row }) => row.id,
      hidden: true
    },
    {
      field: 'engagementId',
      headerName: 'Engagement ID',
      width: 75,
      valueGetter: ({ row }) => row.engagementId,
    },
    {
      field: 'organizationName',
      headerName: 'Account',
      width: 150,
      valueGetter: ({ row }) => row.organizationName,
    },
    {
      field: 'expertiseName',
      headerName: 'Expertise',
      width: 150,
      valueGetter: ({ row }) => row.expertiseName,
    },
    {
      field: 'rating',
      headerName: 'Rating',
      width: 75,
      valueGetter: ({ row }) => row.rating,
    },
    {
      field: 'explanation',
      headerName: 'Explanation',
      flex: 1,
      valueGetter: ({ row }) => row.explanation,
    }
  ]
  return (
    <JsonApiDataGrid
      type="expertiseRatings"
      columns={columns}
      jsonApiOptions={{
        include: [],
        sortBy: 'dateCreated',
        filters: [{ name: 'advisorId', op: 'eq', val: advisorId }]
      }}
      mode="server"
      density="compact"
      dependencies={[]}
    />
  )
}

ExpertiseRatingsTable.propTypes = {
  advisorId: PropTypes.string.isRequired
}

export default ExpertiseRatingsTable
