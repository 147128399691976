import PropTypes from 'prop-types'
import React from 'react'
import { get, find } from 'lodash'

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'

import { ConfirmDialog } from 'components'

import AdminAccessControl from '../../AdminAccessControl'
import ChargeSkuDialog from '../../ChargeSkuDialog'

const ContractUsageDetailDialog = ({
  contract,
  editable,
  contractSkuAllotment,
  loadAllotmentDetail,
  skus,
  deleteCharge,
  saveCharge,
  openDialog,
  handleClose
}) => {
  const [loaded, setLoaded] = React.useState(false)
  const getMatchingSku = (contractSku) => (
    find(skus, (sku) => parseInt(sku.id, 10) === contractSku.skuId)
  )

  const refundCharge = (csku) => {
    const skuToSave = {
      ...csku,
      isRefund: true,
      unitCount: 0
    }

    saveCharge(skuToSave)
  }

  React.useEffect(() => {
    loadAllotmentDetail(contractSkuAllotment.skuId, contract.id).then((_) => {
      setLoaded(true)
    })
  }, [])

  if (!loaded) { return null }

  return (
    <Dialog
      open
      maxWidth="lg"
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Unit Count</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Divisions</TableCell>
              <AdminAccessControl>
                <TableCell>Source Object Type</TableCell>
                <TableCell>Source Object ID</TableCell>
              </AdminAccessControl>
              { editable ? <TableCell>Actions</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            { contract.contractSkus.filter((csku) => csku.skuId === contractSkuAllotment.skuId).map((csku) => (
              <TableRow key={csku.id}>
                <TableCell>{getMatchingSku(csku).name}</TableCell>
                <TableCell>{csku.unitCount}</TableCell>
                <TableCell>
                  {csku.description}
                  {csku.isRefund ? <b>(REFUNDED)</b> : null}
                </TableCell>
                <TableCell>{get(csku, 'account.name', 'None')}</TableCell>
                <TableCell>{csku.divisions.map((d) => d.displayName).join(', ')}</TableCell>
                <AdminAccessControl>
                  <TableCell>{csku.sourceObjectType}</TableCell>
                  <TableCell>{csku.sourceObjectId}</TableCell>
                </AdminAccessControl>
                { editable
                  ? (
                    <TableCell>
                      <ButtonGroup>
                        <Button
                          color="primary"
                          onClick={() => openDialog((
                            <ChargeSkuDialog
                              contractSkuId={csku.id}
                              contractId={contract.id}
                            />
                          ))}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => openDialog(<ConfirmDialog
                            title="Refund Charge"
                            description={`Really refund charge for: ${getMatchingSku(csku).name}?`}
                            actions={[
                              {
                                name: 'Refund',
                                action: () => { refundCharge(csku) }
                              }
                            ]}
                          />)}
                        >
                          Refund
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => openDialog(<ConfirmDialog
                            title="Delete Charge"
                            description={`Really delete charge for: ${getMatchingSku(csku).name}?`}
                            actions={[
                              {
                                name: 'Delete',
                                action: () => { deleteCharge(csku) },
                                isDelete: true
                              }
                            ]}
                          />)}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    )
                  : null }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContractUsageDetailDialog.propTypes = {
  contract: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  contractSkuAllotment: PropTypes.object.isRequired,
  skus: PropTypes.array.isRequired,
  deleteCharge: PropTypes.func.isRequired,
  saveCharge: PropTypes.func.isRequired,
  loadAllotmentDetail: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ContractUsageDetailDialog
