import { forEach, merge, mergeWith, filter, isArray } from 'lodash'
import { insertItem } from 'utils-em'
import normalize from 'json-api-normalizer'
import * as ACTION_TYPE from './constants'

const INITIAL_STATE = {
  meta: {
    assets: { displayOrderIds: [] },
    accounts: { displayOrderIds: [], searchOrder: [] },
    accountPages: { displayOrderIds: [], searchOrder: [] },
    activities: { displayOrderIds: [] },
    adminUsers: { displayOrderIds: [] },
    advisors: { displayOrderIds: [] },
    advisorSingleCallFeedbackForms: { displayOrderIds: [] },
    advisorRequests: { displayOrderIds: [] },
    bookmarks: { displayOrderIds: [] },
    companies: { displayOrderIds: [] },
    contracts: { displayOrderIds: [] },
    contractSkus: { displayOrderIds: [] },
    contractSkuAllotments: { displayOrderIds: [] },
    customerEngagements: { displayOrderIds: [] },
    customerPostCallFeedbackForm: { displayOrderIds: [] },
    customers: { displayOrderIds: [] },
    customerUsers: { displayOrderIds: [] },
    customerUsersPendingAction: { displayOrderIds: [] },
    divisions: { displayOrderIds: [] },
    engagements: { displayOrderIds: [] },
    feedback: { displayOrderIds: [] },
    knowledgePages: { displayOrderIds: [] },
    knowledgePageSections: { displayOrderIds: [] },
    insights: { displayOrderIds: [] },
    roles: { displayOrderIds: [] },
    organizations: { displayOrderIds: [] },
    opportunities: { displayOrderIds: [] },
    samlIdps: { displayOrderIds: [] },
    sellerActivityCustomerUsers: { displayOrderIds: [] },
    skus: { displayOrderIds: [] },
    surveys: { displayOrderIds: [] },
    tags: { displayOrderIds: [] },
    timeslots: { displayOrderIds: [], requiredOrder: [] },
    timeslotProposals: { displayOrderIds: [] },
    topics: { displayOrderIds: [] },
    userActions: { displayOrderIds: [] },
    userMessages: { displayOrderIds: [] },
  }
}

function setEmptyArrayCustomizer (objValue, srcValue) {
  if (isArray(objValue)) {
    return srcValue
  }
  return undefined
}

export default function jsonApiReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPE.POPULATE_JSON_API_MANUALLY: {
      return mergeWith(
        {},
        state,
        normalize(action.objects),
        setEmptyArrayCustomizer
      )
    }
    case ACTION_TYPE.POPULATE_JSON_API: {
      const displayOrderIds = action.response.data.map((obj) => obj.id)

      const newState = mergeWith(
        {},
        state,
        normalize(action.response),
        setEmptyArrayCustomizer
      )
      const type = action.params.metaType ? action.params.metaType : action.params.type
      // re-add old order outside of merge if we're ignoring order here
      if (!action.params.ignoreOrder) {
        if (action.params.orderKey) {
          const orderedKeyMeta = {}
          orderedKeyMeta[`${action.params.orderKey}-count`] = action.response.meta.count
          newState.meta[type] = mergeWith({}, newState.meta[type], orderedKeyMeta)
          newState.meta[type][action.params.orderKey] = displayOrderIds
        } else {
          newState.meta[type] = mergeWith({}, newState.meta[type], action.response.meta)
          newState.meta[type].displayOrderIds = displayOrderIds
        }
      }
      return newState
    }
    case ACTION_TYPE.POPULATE_ONE_JSON_API: {
      return mergeWith(
        {},
        state,
        normalize(action.response),
        setEmptyArrayCustomizer
      )
    }
    case ACTION_TYPE.CREATE_JSON_API: {
      const displayOrderIds = insertItem(
        state.meta[action.params.type].displayOrderIds,
        0,
        action.response.data.id
      )

      return merge(
        {},
        state,
        normalize(action.response),
        {
          meta: {
            [action.params.type]: {
              ...state.meta[action.params.type],
              displayOrderIds,
              count: state.meta[action.params.type].count + 1
            }
          }
        }
      )
    }
    case ACTION_TYPE.CREATE_JSON_API_LIST: {
      return mergeWith(
        {},
        state,
        normalize(action.response),
        setEmptyArrayCustomizer
      )
    }
    case ACTION_TYPE.UPDATE_JSON_API_LIST: {
      return mergeWith(
        {},
        state,
        normalize(action.response),
        setEmptyArrayCustomizer
      )
    }
    case ACTION_TYPE.UPDATE_JSON_API: {
      return mergeWith(
        {},
        state,
        normalize(action.response),
        setEmptyArrayCustomizer
      )
    }
    case ACTION_TYPE.DELETE_JSON_API: {
      const newState = { ...state }
      const deletedType = action.params.type
      delete (newState[deletedType][action.params.id])
      newState[deletedType].count -= 1
      newState[deletedType].displayOrderIds = filter(newState.meta[deletedType].displayOrderIds, (id) => id !== action.params.id)

      // also clear out all relationships that contain it, whew
      forEach(newState, (jsonApiObjects, key) => {
        // don't touch meta
        if (key === 'meta') { return }

        forEach(jsonApiObjects, (fields, id) => {
          if (fields.relationships && fields.relationships[deletedType]) {
            const newFields = { ...fields }
            newFields.relationships[deletedType].data = newFields.relationships[deletedType].data.filter((r) => r.id !== action.params.id)

            newState[key][id] = newFields
          }
        })
      })

      return newState
    }
    default:
      return state
  }
}
