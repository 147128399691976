export function queryStringParser (location) {
  const queryStrings = location.search.slice(1).split('&')

  const parsed = {}
  queryStrings.forEach((query) => {
    if (!query.length) return

    const keyValue = query.split('=')
    parsed[keyValue[0]] = keyValue.length > 1 ? keyValue[1] : true
  })

  return parsed
}

export function getQueryStringFromParams (queryStringParams) {
  const params = new URLSearchParams()
  Object.entries(queryStringParams).forEach(([key, value]) => {
    if (Array.isArray(value) || typeof value === 'object') {
      params.set(key, JSON.stringify(value))
    } else {
      params.set(key, value)
    }
  })
  return params.toString()
}
