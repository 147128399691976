import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Box, Typography } from '@mui/material'

const AvatarWithTextCombo = ({
  icon,
  iconColor,
  iconSize,
  backgroundColor,
  variant,
  textColor,
  text,
  ...rest
}) => (
  <Box {...rest}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        sx={{
          bgcolor: backgroundColor,
          height: `${iconSize}px`,
          width: `${iconSize}px`,
          '& path': { stroke: ({ palette }) => palette[iconColor] }
        }}
      >
        {icon}
      </Avatar>
      <Typography
        sx={{ ml: 2 }}
        variant={variant}
        color={textColor}
      >
        {text}
      </Typography>
    </Box>
  </Box>
)

AvatarWithTextCombo.defaultProps = {
  backgroundColor: 'brand.lightestPurple',
  variant: 'h2',
  textColor: 'neutral.black',
  text: '',
  iconColor: 'brand.darkNavy',
  iconSize: 32
}

AvatarWithTextCombo.propTypes = {
  icon: PropTypes.node.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  variant: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string
}

export default AvatarWithTextCombo
