import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Button,
  Typography,
} from '@mui/material'
import { AddParticipantModal } from 'components'
import {
  openDialog
} from 'utils-em'

const AddParticipantsSection = ({ engagementId, showPostCallText, context }) => {
  const { setParticipantUsers, setParticipantEmails } = React.useContext(context)
  const description = showPostCallText ? 'If you have colleagues you\'d like to be able to access the call recording and transcript, add them to the engagement.'
    : `If you have colleagues you'd like to invite to join the call, you can add them to the engagement. 
                      They will receive a Zoom link and be able to access the recording and transcript once your call is complete.`
  const title = showPostCallText ? 'Share engagement with your colleagues' : 'Add participants'
  return (
    <Box sx={{ mt: 4, p: 3, bgcolor: 'brand.lightNavy', borderRadius: 1 }}>
      <Typography variant="h4" color="neutral.black">
        {title}
      </Typography>
      <Typography variant="body1" sx={{ mt: 3 }}>
        {description}
      </Typography>
      <Button
        sx={{ mt: 3 }}
        onClick={() => {
          openDialog(<AddParticipantModal
            engagementId={engagementId}
            setSavedUsers={setParticipantUsers}
            setSavedEmails={setParticipantEmails}
          />)
        }}
        variant="outlined"
        color="primary"
      >
        Share engagement
      </Button>
    </Box>
  )
}

AddParticipantsSection.defaultProps = {
  showPostCallText: false
}

AddParticipantsSection.propTypes = {
  engagementId: PropTypes.string.isRequired,
  showPostCallText: PropTypes.bool,
  context: PropTypes.object.isRequired
}

export default AddParticipantsSection
