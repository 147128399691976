import PropTypes from 'prop-types'
import React from 'react'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid
} from '@mui/material'

import CustomerUserAutocomplete from '../CustomerUserAutocomplete'
import DivisionAutocomplete from '../DivisionAutocomplete'

const EditDivisionDialog = ({
  initDivision,
  divisions,
  customerId,
  handleClose,
  saveDivision,
  getDivisions,
  showSuccessMessage,
  showErrorMessage,
  users,
  userSearch
}) => {
  const [division, setDivision] = React.useState(initDivision || {
    name: '',
    divisionType: 'vertical',
    customerUsers: [],
    customerId
  })

  React.useEffect(() => {
    getDivisions(
      customerId
    )
  }, [])

  const setDivisionState = (fieldName) => (event) => {
    const { value } = event.target
    setDivision({
      ...division,
      [fieldName]: value
    })
  }

  const handleSubmit = () => {
    saveDivision(division).then((res) => {
      if (!res.ok) {
        showErrorMessage({
          text: `Save team failed: ${res.error.errors.map((e) => e.detail).join('; ')}`,
          timeout: 4000
        })
      } else {
        showSuccessMessage({
          text: 'Team Saved',
          timeout: 4000
        })
        handleClose()
      }
    })
  }

  return (
    <Dialog
      open
      maxWidth="md"
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>{`${division.id ? 'Edit' : 'Create'} Team`}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Name *"
                validators={['required']}
                errorMessages={['Required Field']}
                value={division.name}
                onChange={setDivisionState('name')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomerUserAutocomplete
                customerId={customerId}
                label="Users"
                onChange={(e, cus) => setDivision({
                  ...division,
                  customerUsers: cus
                })}
                initUsers={division.customerUsers}
              />
            </Grid>
            <Grid item xs={12}>
              <DivisionAutocomplete
                label="Sub-Teams"
                customerId={customerId}
                onChange={(e, subteams) => setDivision({
                  ...division,
                  children: subteams
                })}
                initDivisions={division.children}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditDivisionDialog.defaultProps = {
  initDivision: null
}

EditDivisionDialog.propTypes = {
  initDivision: PropTypes.object,
  divisions: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveDivision: PropTypes.func.isRequired,
  getDivisions: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  userSearch: PropTypes.func.isRequired
}

export default EditDivisionDialog
