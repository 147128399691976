import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@mui/material'
import { constants } from 'utils-em'
import APILoaderContainer from '../../../../../../../containers/APILoaderContainer'
import CustomerUsersList from '../components/CustomerUsersList'

const CustomerUsersLayout = ({ getAllCustomerUsers, getAllDivisions, customerId }) => (
  <APILoaderContainer
    onMount={(done) => {
      Promise.all([
        getAllCustomerUsers(
          customerId,
          constants.ADMIN_DEFAULT_ROWS_PER_PAGE,
          1
        ),
        getAllDivisions(
          customerId,
          constants.ADMIN_DEFAULT_ROWS_PER_PAGE,
          1
        )
      ]).then(() => { done() })
    }}
  >
    <Box sx={{ width: '90%' }}>
      <CustomerUsersList customerId={customerId} />
    </Box>
  </APILoaderContainer>
)

CustomerUsersLayout.propTypes = {
  getAllCustomerUsers: PropTypes.func.isRequired,
  getAllDivisions: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired
}

export default CustomerUsersLayout
