import PropTypes from 'prop-types'
import React from 'react'

import { constants } from 'utils-em'
import APILoaderContainer from '../../../../../../../containers/APILoaderContainer'
import DivisionsList from '../components/DivisionsList'

const DivisionsLayout = ({ getAllDivisions, customerId }) => (
  <APILoaderContainer
    onMount={(done) => {
      Promise.all([
        getAllDivisions(
          customerId,
          constants.ADMIN_DEFAULT_ROWS_PER_PAGE,
          1
        )
      ]).then(() => { done() })
    }}
  >
    <DivisionsList customerId={customerId} />
  </APILoaderContainer>
)

DivisionsLayout.propTypes = {
  getAllDivisions: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired
}

export default DivisionsLayout
