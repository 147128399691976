import React from 'react'
import PropTypes from 'prop-types'

import {
  formatDate
} from 'utils-em'

import ActivityItem from '../ActivityItem'

const EngagementStarted = ({ activity, ...props }) => (
  <ActivityItem
    title="Engagement started"
    subtitle={formatDate(activity.dateCreated)}
    {...props}
  />
)

EngagementStarted.propTypes = {
  activity: PropTypes.object.isRequired
}

export default EngagementStarted
