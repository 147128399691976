import { filenameParser, jsonApiFormatter as jsonApiFormatterImport, getEmissaryApp } from 'utils-em'
import { each, get } from 'lodash'

import * as ACTION from './actions'

// due to the function being run in the reducer, redefine here
const jsonApiFormatter = jsonApiFormatterImport // eslint-disable-line no-unused-vars

const API_HOST = __API_HOST__

export default class API {
  /**
   * Create a new instance of API.
   *
   * All method definitions of an API is transformed into a function that
   * returns a redux thunk. These methods are async action creators that
   * should be given as the first argument of a dispatch() call. Each method
   * can be called with a single object parameter, which is parsed to build
   * the full URL, querystrings, and a fetch() handler. You can optionally
   * define defaultParams in the methodDefinitions which will be assigned
   * to the single object parameter.
   *
   * @param {string} url
   * @param {object} methodDefinitions
   * @return {Promise}
   */
  constructor (url, methodDefinitions) {
    this.methodDefinitions = methodDefinitions

    Object.keys(methodDefinitions).forEach((caller) => {
      const action = (params) => (dispatch, getState) => {
        const path = methodDefinitions[caller].url || url
        let paramsWithDefaults = { ...get(methodDefinitions[caller], 'defaultParams', {}), ...params }

        if (methodDefinitions[caller].beforeRequestTransformObject) {
          paramsWithDefaults = {

            ...methodDefinitions[caller].beforeRequestTransformObject(paramsWithDefaults)
          }
        }

        const { parsedPath, remainingParamTemplate, remainingRequestParams } =
          this.replaceParamsInsideURL(path, caller, paramsWithDefaults)

        const querystring = API.buildQueryStringFromTemplateParams(remainingParamTemplate, remainingRequestParams)

        const requestURL = `${API_HOST}${parsedPath}${querystring}`
        const requestConfig = API[`${methodDefinitions[caller].method}_CONFIG`](paramsWithDefaults)

        const shouldCache = !!methodDefinitions[caller].cache
        if (shouldCache && getState().resource.cached[requestURL]) {
          return Promise.resolve({})
        }
        return this.makeRequest(dispatch, getState, params, { caller, shouldCache, requestURL, requestConfig })
      }

      this[caller] = action
    })
  }

  /**
   * Create config for POST methods
   *
   * @param {string} body
   * @return {object} The fetch config.
   */
  static POST_CONFIG (body) {
    return {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Emissary-Origin-App': getEmissaryApp()
      },
      body: JSON.stringify(body)
    }
  }

  /**
   * Create config for GET methods
   *
   * @return {object} The fetch config.
   */
  static GET_CONFIG () {
    return {
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Emissary-Origin-App': getEmissaryApp()
      }
    }
  }

  /**
   * Create config for PUT methods
   *
   * @param {string} body
   * @return {object} The fetch config.
   */
  static PUT_CONFIG (body) {
    return {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Emissary-Origin-App': getEmissaryApp()
      },
      body: JSON.stringify(body)
    }
  }

  /**
   * Create config for PATCH methods
   *
   * @param {string} body
   * @return {object} The fetch config.
   */
  static PATCH_CONFIG (body) {
    return {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Emissary-Origin-App': getEmissaryApp()
      },
      body: JSON.stringify(body)
    }
  }

  /**
   * Create config for DELETE methods
   *
   * @return {object} The fetch config.
   */
  static DELETE_CONFIG () {
    return {
      method: 'DELETE',
      credentials: 'include'
    }
  }

  /**
   * Build query strings from a template and parameters passed in by the dispatch call.
   *
   * Example: Dispatch is called with the following parameters:
   * dispatch(myAPI.get({ type: 'food' })). The template in the method defintion
   * has: "params: { for: type }". Parsed together, it would produce "?for=food."
   *
   * Also allows a special case, any parameters passed in via a "queryStringParams"
   * will be serialized into the querystring, regardless of template design
   * ex: { queryStringParams: {'filter[name]': 'dave'}} will add ?filter[name]=dave
   * especially useful with jsonapi
   *
   * @param {object} paramTemplate
   * @param {object} requestParams
   * @return {string} querystring
   */
  static buildQueryStringFromTemplateParams (paramTemplate, requestParams) {
    const paramTemplateKeys = Object.keys(paramTemplate)
    const querystringArgs = []

    if (paramTemplateKeys.length) {
      paramTemplateKeys.forEach((key) => {
        const requestParamKey = paramTemplate[key]
        if (requestParamKey in requestParams) {
          const requestParamValue = typeof requestParams[key] === 'object'
            ? JSON.stringify(requestParams[key])
            : requestParams[key]

          const keyValuePair = `${key}=${encodeURIComponent(requestParamValue)}`
          querystringArgs.push(keyValuePair)
        }
      })
    }

    if (requestParams.queryStringParams) {
      each(requestParams.queryStringParams, (value, key) => {
        const requestParamValue = typeof value === 'object'
          ? JSON.stringify(value)
          : value

        const keyValuePair = `${key}=${encodeURIComponent(requestParamValue)}`
        querystringArgs.push(keyValuePair)
      })
    }

    return `?${querystringArgs.join('&')}`
  }

  /**
   * Parse and replace parameters inside the path.
   *
   * Example: The path is defined as "/users/:userId". This function will split it by "/"
   * and replace each element if it begins with ":" like ":userId" above by looking it up
   * in the request parameters. So calling dispatch(myAPI.post({ id: 100, body: 'body' })
   * with "params: { userId: 'id' }" will make the request to "/users/100" with request
   * body as "{ body: 'body' }".
   *
   * @param {string} path
   * @param {string} caller
   * @param {object} requestParams
   * @return {object} { parsedPath, remainingParamTemplate, remainingRequestParams }
   */
  replaceParamsInsideURL (path, caller, requestParams) {
    const paramTemplateCopy = { ...this.methodDefinitions[caller].params }
    const requestParamsCopy = { ...requestParams }

    const parsedPath = path.split('/')
      .map((urlPart) => {
        if (urlPart.startsWith(':')) {
          const identifier = urlPart.slice(1)

          const key = paramTemplateCopy[identifier]
          delete paramTemplateCopy[identifier]

          const paramValue = requestParamsCopy[key]
          if (!paramValue) {
            throw new Error(`Parameter missing: ${key}`)
          }
          delete requestParamsCopy[key]

          return paramValue
        }

        return urlPart
      })
      .join('/')

    return { parsedPath, remainingParamTemplate: paramTemplateCopy, remainingRequestParams: requestParamsCopy }
  }

  /**
   * Compose a fetch() request with give parameters.
   *
   * @param {function} dispatch - Should be a redux dispatcher
   * @param {object} { caller, shouldCache, requestURL, requestConfig }
   * @return {Promise}
   */
  makeRequest (dispatch, getState, params, { caller, shouldCache, requestURL, requestConfig }) {
    dispatch(ACTION.initializeRequest(`${requestURL}: ${caller}`))
    // eslint-disable-next-line no-param-reassign
    if (params && params.abortController) requestConfig.signal = params.abortController.signal
    return fetch(requestURL, requestConfig)
      .then((res) => {
        if (res.status >= 400) {
          return res.json()
            .then(
              // Relay error object and reject Promise.
              // eslint-disable-next-line prefer-promise-reject-errors
              (err) => Promise.reject({ status: res.status, data: null, error: err }),
              // Handle case where Promise is already rejected because no JSON body is returned.
              // eslint-disable-next-line prefer-promise-reject-errors
              () => Promise.reject({ status: res.status, data: null, error: { message: 'An error has occured' } })
            )
        }

        if (res.status === 204) {
          return { status: 204, data: res.statusText, error: null }
        }

        let parsedData = null
        const contentType = res.headers.get('Content-Type')
        if (contentType === 'application/json' || contentType === 'application/vnd.api+json') {
          parsedData = res.json()
        } else {
          parsedData = res.blob().then((content) => {
            let filename = 'untitled'

            const contentDisposition = res.headers.get('Content-Disposition')
            if (contentDisposition) {
              filename = filenameParser(contentDisposition)
            }

            return new File([content], filename)
          })
        }

        return parsedData.then((data) => ({ ok: res.ok, status: res.status, data, error: null }))
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          // eslint-disable-next-line no-throw-literal
          throw { status: null, data: null, error: true, aborted: true }
        }
        throw err
      })
      .then((result) => {
        if (shouldCache) {
          dispatch(ACTION.cacheRequest(requestURL))
        }

        dispatch(ACTION.handleRequestSuccess(result))

        const successCb = this.methodDefinitions[caller].onSuccess
        if (successCb) {
          successCb(result, dispatch, getState, params)
        }

        return result
      }, (err) => {
        let errorData = err

        if (errorData.aborted) {
          dispatch(ACTION.handleRequestAborted())
        } else {
          // fetch() is rejected with a TypeError when server responds with 500
          if (errorData instanceof TypeError) {
            errorData = { status: 500, data: null, error: { message: 'Server failure' } }
          }

          dispatch(ACTION.handleRequestError(errorData))

          const errorCb = this.methodDefinitions[caller].onError
          if (errorCb) {
            errorCb(errorData, dispatch, getState)
          }
        }

        return errorData
      })
  }
}
