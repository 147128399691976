import PropTypes from 'prop-types'
import React from 'react'

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material'

import { CaretRight } from 'icons'

const drawerWidth = 280

const styles = (theme) => ({
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 72,
    [theme.breakpoints.up('sm')]: {
      width: 72
    }
  },
  link: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
})

const NavigationSidebar = ({ links, navigateTo }) => {
  const visibleLinks = links.filter((l) => !l.hidden)
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Drawer
      variant="permanent"
      PaperProps={{ sx: { border: 'none' } }}
      sx={{
        zIndex: 1200,
        width: 264,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(open ? styles(theme).drawerOpen : styles(theme).drawerClose),
        '& .MuiDrawer-paper': {
          // theme argument is required for sx children
          ...(open ? styles(theme).drawerOpen : styles(theme).drawerClose),
          borderRight: (t) => `1px SOLID ${t.palette.neutral.lightGrey}`
        }
      }}
    >
      <Box sx={{ ...styles(theme).toolbar }} />
      <List sx={{ pt: 2.5 }}>
        <ListItem
          disableGutters
          button
          sx={{ ...styles(theme).link }}
          key="Close"
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          <ListItemIcon>
            <CaretRight sx={{ color: 'brand.darkNavy', transform: `rotate(${open ? '180deg' : '0deg'})` }} />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="bodyBold" color="brand.darkNavy">Main Dashboard</Typography>} />
        </ListItem>
        {visibleLinks.map((link) => (
          <ListItem
            disableGutters
            button
            sx={{ ...styles(theme).link }}
            key={link.text}
            onClick={() => {
              if (link.nonReactUrl) {
                window.location.assign(link.href)
              } else {
                navigateTo(link.href)
              }
            }}
          >
            <ListItemIcon id={link.text} sx={{ color: 'brand.darkNavy', '& svg': { fill: 'transparent' } }}>{link.icon}</ListItemIcon>
            <ListItemText primary={<Typography variant="bodyBold" color="brand.darkNavy">{link.text}</Typography>} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

NavigationSidebar.defaultProps = {
  links: []
}

NavigationSidebar.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  links: PropTypes.array
}

export default NavigationSidebar
