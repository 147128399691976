import React from 'react'
import { Box, Button } from '@mui/material'
import {
  ImportCSVDialog,
  SurveyAssignmentsTable,
  FeatureAccessControl
} from 'components'
import {
  Share
} from 'icons'

import { openDialog } from 'utils-em'
import AssignSurveysDialog from './components/AssignSurveysDialog'

const ManageSurveysTab = () => {
  const [newAssignmentIds, setNewAssignmentIds] = React.useState(null)

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => openDialog(
            <AssignSurveysDialog assignmentIdCallback={setNewAssignmentIds} />
          )}
        >
          Assign surveys
        </Button>
        <FeatureAccessControl feature="sync_surveys_with_dev">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="primary"
              variant="text"
              onClick={() => openDialog(<ImportCSVDialog type="sync_question_response_csv" />)}
            >
              Sync responses
            </Button>
            <Button
              color="primary"
              variant="text"
              href="https://metabase.emissary.io/question/2538"
              target="_blank"
              startIcon={<Share />}
            >
              Response query
            </Button>
          </Box>
        </FeatureAccessControl>
      </Box>
      <SurveyAssignmentsTable
        newAssignmentIds={newAssignmentIds}
      />
    </Box>
  )
}

export default ManageSurveysTab
