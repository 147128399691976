import React from 'react'
import EditAccountPage from './EditAccountPage'

import { EditAccountPageContextProvider } from './components/EditAccountPageContext'

export default function EditAccountPageContainer () {
  return (
    <EditAccountPageContextProvider>
      <EditAccountPage />
    </EditAccountPageContextProvider>
  )
}
