/* eslint-disable camelcase */
export default function setGtag (gtag) {
  const {
    user_id,
    user_email,
    user_type,
    stealth,
    page_path,
    page_title,
    customer_name,
    customer_user_role_type
  } = gtag

  window.gtag('config', __GTAG_MEASUREMENT_ID__, {
    user_id,
    page_path,
    page_title
  })

  window.gtag('set', 'user_properties', {
    user_email,
    user_type,
    stealth,
    customer_name,
    customer_user_role_type
  })
}
/* eslint-enable */
