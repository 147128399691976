import { useSelector } from 'react-redux'

/**
 * Determines if the user is an admin, but
 * being in stealth mode returns false
 * @returns True if user is an admin and is not in stealth mode
 */
export default function useIsAdmin () {
  return useSelector(({ session }) => session.userType === 'admin')
}
