import React from 'react'

import { Box, Typography } from '@mui/material'

const AccoladeFooter = () => {
  const generateAccolade = (logoPath, alt, text, date) => (
    <Box
      justifyContent="center"
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: { desktop: '22.5%', tablet: '40%', mobile: '90%' },
        height: '100%',
        backgroundColor: 'neutral.white',
        mb: 5,
        ml: { desktop: 2, tablet: 3, mobile: 0 }
      }}
    >
      <Box display="flex" justifyContent="center">
        <img
          src={logoPath}
          alt={alt}
          display="inline"
          height="80"
          width="120"
        />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="captionBold" display="flex" textAlign="center" justifyContent="center">
          {text}
        </Typography>
        <Typography variant="caption" display="flex" justifyContent="center" textAlign="center" color="neutral.darkGrey">
          {date}
        </Typography>
      </Box>
    </Box>
  )
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        height: '100%',
        mt: 0.5,
        pt: 5,
        pr: { desktop: 2, tablet: 3, mobile: 0 },
      }}
    >
      {generateAccolade('/public/img/inc-5000-68.png', 'inc5000', 'Fastest Growing Private Companies in the U.S.')}
      {generateAccolade('/public/img/martech.png', 'martech', 'Best Sales Enablement Software for Enterprises')}
      {generateAccolade('/public/img/sales-hacker.png', 'sales hacker', 'List of Best Sales Tools')}
      {generateAccolade('/public/img/forbes.png', 'forbes', 'America\'s Best Startup Employers')}
    </Box>
  )
}

export default AccoladeFooter
