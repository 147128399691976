import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'

const ApproveSurveyAssignmentQualityDialog = ({ quality, setQuality, handleSave, handleClose }) => (
  <Dialog
    open
    maxWidth="sm"
    onClose={handleClose}
  >
    <DialogTitle>Select quality marker</DialogTitle>
    <DialogContent>
      <RadioGroup
        value={quality}
        onChange={(event) => setQuality(event.target.value)}
      >
        <FormControlLabel
          value="strong"
          label="Strong - Publish"
          control={<Radio />}
        />
        <FormControlLabel
          value="average"
          label="Average - Publish"
          control={<Radio />}
        />
        <FormControlLabel
          value="weak"
          label="Weak - Approve but not publish"
          control={<Radio />}
        />
      </RadioGroup>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" onClick={() => handleSave('approve')}>Confirm</Button>
    </DialogActions>
  </Dialog>
)

ApproveSurveyAssignmentQualityDialog.propTypes = {
  quality: PropTypes.string.isRequired,
  setQuality: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ApproveSurveyAssignmentQualityDialog
