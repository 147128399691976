import React from 'react'
import { useDispatch } from 'react-redux'
import { JsonAPI } from 'store'
import { customAlert } from 'utils-em'

export const useJsonAPIUpsert = (options = {}) => {
  const dispatch = useDispatch()
  const { showDefaultAlerts } = options || {}
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [error, setError] = React.useState(null)

  const upsert = async (obj) => {
    let res = null
    setIsLoading(true)
    try {
      if (!('type' in obj)) throw new Error('Type must be defined')
      const method = obj.id ? JsonAPI.save : JsonAPI.create
      res = await dispatch(method(obj))
      if (res.error) throw new Error(res.error.message)
      setIsSuccess(true)
      if (options.successMessage) customAlert(options.successMessage)
      if (showDefaultAlerts) customAlert('Success!')
    } catch (err) {
      setError(err)
      if (options.errorMessage) customAlert(options.errorMessage)
      if (showDefaultAlerts) customAlert('Something went wrong, please contact support.', true)
    }
    setIsLoading(false)
    return res
  }

  return { upsert, isLoading, isSuccess, error }
}

export default useJsonAPIUpsert
