import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material'
import {
  FormCheckbox,
  FormDatePickerTwo,
  OrganizationSelectorTwo
} from 'components'
import { Information } from 'icons'
import {
  buildJsonApiOne,
  closeDialog,
  httpResponseAlert,
} from 'utils-em'
import { useSessionUser } from 'hooks'
import { JsonAPI } from 'store'

const AdvisorCompanyEditDialog = ({ companyId, advisorId, saveComplete, earliestRoleStartDate, latestRoleEndDate }) => {
  const { isAdmin } = useSessionUser()
  const company = useSelector(({ data }) => buildJsonApiOne(data, 'companies', companyId))

  const form = useForm({
    defaultValues: {
      isValidated: company ? !!company.dateStandardizationVerified : false,
      startDate: (company && company.dateStarted) ? moment(company.dateStarted) : null,
      endDate: (company && company.dateEnded) ? moment(company.dateEnded) : null,
      isCurrent: company?.isCurrent
    }
  })
  const [startDate, endDate, isCurrent] = form.watch(['startDate', 'endDate', 'isCurrent'])

  // Separate validation logic for organization/company due to old OrganizationSelector component
  const [isError, setIsError] = React.useState(false)
  const [editedCompany, setEditedCompany] = React.useState({
    id: company?.id || companyId,
    orgName: company?.organization?.name || company?.displayOrgName,
    organizationId: company?.organizationId
  })
  const validateCompanySelection = () => setIsError(!editedCompany.organizationId && !editedCompany.orgName)

  const dispatch = useDispatch()
  const onSubmit = (data) => {
    const missingFormData = (!editedCompany.organizationId && !editedCompany.orgName) || !data.startDate || (!data.endDate && !isCurrent)
    setIsError(missingFormData)
    if (missingFormData) return
    const method = companyId ? JsonAPI.save : JsonAPI.create
    if (editedCompany) {
      delete editedCompany.roles
      dispatch(method({
        ...editedCompany,
        id: companyId,
        advisorId,
        dateStarted: data.startDate.toISOString(),
        dateEnded: (!data.isCurrent && data.endDate) ? data.endDate.toISOString() : null,
        dateStandardizationVerified: data.isValidated ? moment().toISOString() : null,
        isCurrent,
        type: 'companies',
      })).then((response) => {
        httpResponseAlert(response)
        saveComplete({ ...response.data.data.attributes, id: response.data.data.id })
        closeDialog()
      })
    }
  }

  // clear out validation if org name changes
  React.useEffect(() => {
    if (!company) return
    if (!company.dateStandardizationVerified) return
    form.setValue('isValidated', editedCompany.orgName === company.displayOrgName)
  }, [editedCompany.orgName, company])

  let maxStartDate = endDate
  let minEndDate = startDate
  if (maxStartDate && earliestRoleStartDate) maxStartDate = moment.min(maxStartDate, moment(earliestRoleStartDate))
  if (minEndDate && latestRoleEndDate) minEndDate = moment.max(minEndDate, moment(latestRoleEndDate))

  return (
    <Dialog
      open
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
    >
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>{companyId ? 'Edit company' : 'Add company'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrganizationSelectorTwo
                textFieldParams={{
                  variant: 'outlined',
                  label: 'Company',
                  fullWidth: true,
                  placeholder: 'Company',
                  helperText: isError ? 'This field is required.' : null,
                  error: isError
                }}
                allowFreeText
                noOptionsText="No company name"
                defaultValue={{ name: editedCompany.orgName, id: editedCompany.organizationId }}
                onSelected={(org) => {
                  if (org?.id) {
                    setEditedCompany({ orgName: org?.name, organizationId: org?.id })
                  }
                }}
                onSearchTextUpdated={(text, currentOrgs) => {
                  // matches orgId if value is typed, but not selected
                  const orgName = text.trim()
                  const matchingOrg = currentOrgs.find((org) => org.name === orgName)
                  const matchingOrgId = matchingOrg ? matchingOrg.id : null
                  setEditedCompany({ orgName, organizationId: matchingOrgId })
                }}
              />
              {isAdmin && !editedCompany?.organizationId && (
                <Typography variant="caption" color="neutral.darkGrey">
                  Org standardization is blank
                </Typography>
              )}
            </Grid>
            {isAdmin && (
              <Grid item xs={12}>
                <FormCheckbox
                  name="isValidated"
                  label="Is validated"
                  form={form}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormCheckbox
                name="isCurrent"
                label={isAdmin ? 'Is current' : 'I currently work here'}
                form={form}
                sx={{ ml: 1 }}
              />
            </Grid>
            <Grid item xs={6} sx={{ pr: 1 }}>
              <FormDatePickerTwo
                name="startDate"
                label="Start date at company"
                form={form}
                required
                maxDate={maxStartDate}
              />
            </Grid>
            <Grid item xs={6} sx={{ pl: 1 }}>
              <FormDatePickerTwo
                name="endDate"
                label="End date at company"
                form={form}
                required={!isCurrent}
                disabled={isCurrent}
                minDate={minEndDate}
              />
            </Grid>
            {isCurrent && !isAdmin && (
              <Box sx={{ ml: 1, mt: 3, display: 'flex' }}>
                <Information sx={{ mr: 1 }} />
                <Typography variant="caption" color="neutral.darkGrey">
                  Include your current role to contribute to additional surveys.
                  It will be visible on your profile but clients will not be able
                  to engage with you for calls about this experience.
                </Typography>
              </Box>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={closeDialog}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => validateCompanySelection()}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
AdvisorCompanyEditDialog.defaultProps = {
  companyId: null,
  earliestRoleStartDate: null,
  latestRoleEndDate: null
}

AdvisorCompanyEditDialog.propTypes = {
  advisorId: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  saveComplete: PropTypes.func.isRequired,
  earliestRoleStartDate: PropTypes.any,
  latestRoleEndDate: PropTypes.any
}

export default AdvisorCompanyEditDialog
