import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  EngagementCard,
  JsonApiList
} from 'components'

const LOAD_SIZE = 6

const EngagementsList = ({
  header,
  filters,
  stateCallback
}) => {
  const user = useSelector(({ session }) => session)
  const isAdvisor = user.userType === 'advisor'

  const getEngagementFromRes = (res) => res.data.data.map((engagement) => ({
    engagementId: parseInt(engagement.id, 10),
    key: engagement.id
  }))

  const filter = !isAdvisor ? [
    {
      or: [
        { name: 'customerUserId', op: 'eq', val: user.id },
        {
          name: 'participants',
          op: 'any',
          val: {
            name: 'userId',
            op: 'eq',
            val: user.id
          }
        }
      ]
    },
    filters
  ] : [
    { name: 'advisorId', op: 'eq', val: user.id },
    filters
  ]

  return (
    <JsonApiList
      type="engagements"
      queryStringParams={{
        include: [
          'account',
          'advisor',
          'advisor.companies',
          'advisor.companies.organization',
          'advisor.companies.roles',
          'advisorSingleCallFeedback',
          'customerUser',
          'customerUser.customer',
          'organization',
          'participants',
          'relevantTimeslotProposals',
          'relevantTimeslotProposals.initiatingUser',
          'relevantTimeslotProposals.acceptedTimeslots',
          'relevantTimeslotProposals.acceptedTimeslots.customerPostCallFeedbackForm'
        ].join(','),
        filter,
        sort: '-startDate',
        'lazy[engagements]': 'step'
      }}
      renderObject={(props) => <EngagementCard {...props} />}
      getObjectsFromRes={getEngagementFromRes}
      loadSize={LOAD_SIZE}
      header={header}
      stateCallback={stateCallback}
      firstLoadSpinner={false}
    />
  )
}

EngagementsList.defaultProps = {
  header: null,
  filters: null,
  stateCallback: null
}

EngagementsList.propTypes = {
  header: PropTypes.string,
  filters: PropTypes.object,
  stateCallback: PropTypes.func,
}

export default EngagementsList
