import { connect } from 'react-redux'
import { buildJsonApiOrdered } from 'utils-em'
import { Alerts, JsonAPI } from 'store'

import AdvisorOffPlatformRecorder from './AdvisorOffPlatformRecorder'

const mapStateToProps = ({ data }) => ({
  advisors: buildJsonApiOrdered(data, 'advisors')
})

const mapDispatchToProps = {
  getAdvisorsByEmail: (emails) => JsonAPI.getAll({
    type: 'advisors',
    queryStringParams: {
      include: 'userActions',
      filter: [{
        name: 'email',
        op: 'in',
        val: emails
      }]
    }
  }),
  createJsonApiList: (objs) => JsonAPI.createList(objs),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message))
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorOffPlatformRecorder)
