import React from 'react'
import {
  Divider
} from '@mui/material'
import {
  useEmissaryTheme
} from 'hooks'

const ResponsiveDivider = () => {
  const {
    theme,
    pagePaddingX
  } = useEmissaryTheme()

  const sx = {
    width: '100vw',
    marginLeft: `-${theme.spacing(pagePaddingX)}`,
    borderBottomWidth: '4px',
    borderColor: 'brand.paleSlate'
  }

  return (
    <Divider sx={sx} />
  )
}

export default ResponsiveDivider
