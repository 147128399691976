import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import {
  ChevronDown,
  Globe
} from 'icons'
import {
  getTimezones,
  TIMEZONE_MAP
} from 'utils-em'
import moment from 'moment'

const TimezoneDropdown = ({ initialTimezone, onTimezoneSelected }) => {
  const timezones = getTimezones()

  const [selectedTimezone, setSelectedTimezone] = React.useState(initialTimezone || moment.tz.guess())
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)

  return (
    <Box>
      <ButtonBase onClick={(event) => setAnchorEl(event.currentTarget)}>
        <Globe sx={{ color: 'neutral.darkGrey', mr: 1 }} />
        <Typography variant="caption" color="neutral.black">{TIMEZONE_MAP[selectedTimezone]}</Typography>
        <ChevronDown sx={{ color: 'neutral.darkGrey' }} />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '50vh' // Limit to half of the screen height
          }
        }}
      >
        {timezones.map((timezone) => (
          <MenuItem
            key={timezone.value}
            value={timezone.value}
            selected={timezone.value === selectedTimezone}
            onClick={() => {
              setSelectedTimezone(timezone.value)
              onTimezoneSelected(timezone.value)
              handleClose()
            }}
          >
            {timezone.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

TimezoneDropdown.defaultProps = {
  initialTimezone: null,
  onTimezoneSelected: () => {},
}

TimezoneDropdown.propTypes = {
  initialTimezone: PropTypes.string,
  onTimezoneSelected: PropTypes.func
}

export default TimezoneDropdown
