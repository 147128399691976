import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography
} from '@mui/material'

import {
  ArrowLeft,
  Pencil
} from 'icons'
import {
  useJsonAPISelectOne
} from 'hooks'
import {
  openDialog
} from 'utils-em'

import SectionDialog from './SectionDialog'
import SectionEntry from './SectionEntry'

import { KnowledgePageBuilderContext } from '../KnowledgePageBuilderContext'

const PageEntry = ({ knowledgePageId, onEdit }) => {
  const {
    knowledgePages,
    savePage,
    saveSection,
    deleteSection
  } = React.useContext(KnowledgePageBuilderContext)

  const knowledgePage = useJsonAPISelectOne('knowledgePages', knowledgePageId)

  // Keep track of how many times each topic id is referenced in the sections
  // of this knowledge page, so that we can highlight when topics are repeated
  // in different sections
  const topicCountsInKnowledgePage = {/* [topicId]: count */}
  knowledgePage.sections?.forEach((section) => {
    section.topics?.forEach((topic) => {
      if (!topicCountsInKnowledgePage[topic.id]) topicCountsInKnowledgePage[topic.id] = 0
      topicCountsInKnowledgePage[topic.id] += 1
    })
  })

  const openEditCreateSectionDialog = (section = null) => {
    openDialog(
      <SectionDialog
        sectionId={section?.id}
        defaultPageId={parseInt(knowledgePage.id, 10)}
        onSave={saveSection}
        onDelete={deleteSection}
      />
    )
  }

  const setNewOrder = (offset) => {
    savePage({
      ...knowledgePage,
      order: parseInt(knowledgePage.order, 10) + offset
    })
  }

  const iconButtonSx = { '&:hover': { color: 'primary.main' } }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', '&:hover': { bgcolor: 'primary.lightest' } }}>
        <Typography variant="h4" sx={{ ml: 3 }}>{knowledgePage.name}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Move up">
            <span>
              <IconButton
                disabled={parseInt(knowledgePage.order, 10) === 1}
                onClick={() => setNewOrder(-1)}
                sx={iconButtonSx}
              >
                <ArrowLeft sx={{ transform: 'rotate(90deg)' }} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Move down">
            <span>
              <IconButton
                disabled={parseInt(knowledgePage.order, 10) === (knowledgePages.length)}
                onClick={() => setNewOrder(+1)}
                sx={iconButtonSx}
              >
                <ArrowLeft sx={{ transform: 'rotate(-90deg)' }} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => onEdit()} sx={iconButtonSx}>
              <Pencil />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {knowledgePage.sections?.sort((a, b) => a.order - b.order).map((section) => (
        <SectionEntry
          key={section.id}
          sectionId={section.id}
          onEdit={() => openEditCreateSectionDialog(section)}
          isLastSection={parseInt(section.order, 10) === knowledgePage.sections.length}
          topicCountsInKnowledgePage={topicCountsInKnowledgePage}
        />
      ))}
      <Box sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
        <Link
          onClick={() => openEditCreateSectionDialog()}
          sx={{ ml: 8 }}
        >
          Add new section
        </Link>
      </Box>
    </Box>
  )
}

PageEntry.propTypes = {
  knowledgePageId: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default PageEntry
