import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Badge,
  Drawer,
  IconButton,
  useTheme
} from '@mui/material'
import { useEmissaryTheme } from 'hooks'
import { JsonAPIActions, AppState } from 'store'
import { getActiveNotifications } from 'utils-em'
import { Bell, Close } from 'icons'
import { NotificationsList } from 'components'

const NotificationsDrawer = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { header } = useTheme()
  const activeNotifications = useSelector(({ session }) => session.activeNotifications)
  const { headerHeight } = useEmissaryTheme()
  const open = useSelector(({ appState }) => appState.notificationDrawerOpen)
  const count = useSelector(({ session, data }) => getActiveNotifications(session, data, false).length)

  React.useEffect(() => {
    if (activeNotifications) {
      dispatch(JsonAPIActions.populateJsonApiManually(activeNotifications))
    }
  }, [activeNotifications])

  const toggleNotificationsDrawer = () => dispatch(AppState.toggleNotificationsDrawer(!open))
  const closeNotificationsDrawer = () => dispatch(AppState.toggleNotificationsDrawer(false))

  return (
    <Box {...rest}>
      <IconButton
        onClick={() => toggleNotificationsDrawer()}
        sx={{ color: 'brand.navy' }}
        id="notifications-nav-bar"
      >
        <Badge
          badgeContent={count}
          color="primary"
          sx={{ '& .MuiBadge-standard': { boxShadow: 1 } }}
        >
          <Bell />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => closeNotificationsDrawer()}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: {
            top: headerHeight,
            width: '40%',
            height: `calc(100vh - ${headerHeight})`,
            minWidth: '400px',
          }
        }}
        sx={{ p: 5, zIndex: header.zIndex - 1 }}
      >
        <Box sx={{ p: 5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Box typography="h2">Notifications</Box>
            <IconButton onClick={() => closeNotificationsDrawer()}><Close /></IconButton>
          </Box>
          {count > 0 ? <NotificationsList /> : <Box typography="body1">You have no new notifications.</Box>}
        </Box>

      </Drawer>
    </Box>
  )
}

export default NotificationsDrawer
