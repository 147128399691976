import PropTypes from 'prop-types'
import React,
{
  useState,
  useEffect,
  useCallback
} from 'react'
import {
  debounce,
  omit,
  startCase
} from 'lodash'
import {
  ValidatorForm,
  SelectValidator,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import {
  Archive
} from 'icons'
import {
  ArchiveDivisionDialog,
  ArchiveTeamAdvisorRequestsDialog,
  ContractSelector
} from 'components'
import {
  getDivisionDescendants,
  openDialog
} from 'utils-em'

const EditDivisionDialog = ({
  division,
  divisions,
  customerId,
  handleClose,
  saveDivision,
  getDivisions,
  showSuccessMessage,
  showErrorMessage,
  users,
  userSearch
}) => {
  const [state, setState] = useState({
    division: division || {
      name: '',
      divisionType: '',
      customerUsers: [],
      customerId,
      cuAutocompleteOpen: false,
      childrenAutocompleteOpen: false,
      approvalRequired: false,
      approverUser: null,
      displayName: 'New Team'
    }
  })

  const debouncedCuSearch = useCallback(debounce(userSearch, 150), [userSearch])

  useEffect(() => {
    getDivisions(customerId)
  }, [customerId])

  const setDivisionState = (field, value) => {
    setState((prevState) => ({
      division: {
        ...prevState.division,
        [field]: value
      }
    }))
  }

  const setContracts = (contracts) => {
    setState((prevState) => ({
      ...prevState,
      division: { ...prevState.division, contracts }
    }))
  }

  const handleSubmit = () => {
    if (state.division.approvalRequired) {
      const { approverUser, customerUsers } = state.division
      const customerUserIds = customerUsers.map((cu) => cu.id)
      if (!approverUser || !customerUserIds.includes(approverUser.id)) {
        return showErrorMessage({
          text: 'Save team failed: please choose valid approving manager',
          timeout: 2000
        })
      }
    }
    saveDivision(state.division).then((res) => {
      if (!res.ok) {
        showErrorMessage({
          text: `Save team failed: ${res.error.errors.map((e) => e.detail).join('; ')}`,
          timeout: 2000
        })
      } else {
        showSuccessMessage({
          text: 'Team Saved',
          timeout: 2000
        })
        handleClose()
      }
    })
  }

  const handleDivisionChange = (name) => (event) => {
    const { value } = event.target
    setDivisionState(name, value)
  }

  const getUsers = (topDivision) => {
    const usersList = new Set(topDivision.customerUsers)
    const descendants = getDivisionDescendants(topDivision).filter((d) => d.type === 'divisions' && d.id !== topDivision.id)
    if (descendants) {
      descendants.forEach((d) => {
        const subUsers = getUsers(d)
        subUsers.forEach((user) => usersList.add(user))
      })
    }
    return Array.from(usersList)
  }
  const availableSubteams = divisions.filter((d) => !d.isArchived &&
    parseInt(d.parentId, 10) !== parseInt(state.division.id, 10) &&
    parseInt(d.id, 10) !== parseInt(state.division.parentId, 10) &&
    parseInt(d.id, 10) !== parseInt(state.division.id, 10)).map((d) => omit(d, ['children', 'parent', 'relationshipNames']))

  return (
    <Dialog
      open
      maxWidth="xl"
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>{`${state.division.id ? 'Edit' : 'Create'} ${division?.displayName || 'New Team'}`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Name *"
                validators={['required']}
                errorMessages={['Required Field']}
                value={state.division.name}
                onChange={handleDivisionChange('name')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectValidator
                label="Type"
                validators={['required']}
                errorMessages={['Required Field']}
                value={state.division.divisionType.toLowerCase()}
                onChange={handleDivisionChange('divisionType')}
                fullWidth
              >
                {['region', 'vertical'].map((type) => (
                  <MenuItem key={type} value={type}>{startCase(type)}</MenuItem>
                ))}
              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="cu-multiselect"
                open={state.division.cuAutocompleteOpen}
                onOpen={() => setDivisionState('cuAutocompleteOpen', true)}
                onInputChange={(event, text) => debouncedCuSearch(text, customerId)}
                onChange={(event, newValue) => setDivisionState('customerUsers', newValue)}
                onClose={() => setDivisionState('cuAutocompleteOpen', false)}
                getOptionLabel={(option) => option.fullName}
                options={users.map((cu) => omit(cu, 'divisions'))}
                value={state.division.customerUsers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Customer Users"
                    placeholder="Customer Users"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="children-multiselect"
                multiple
                open={state.division.childrenAutocompleteOpen}
                onOpen={() => setDivisionState('childrenAutocompleteOpen', true)}
                onChange={(event, newValue) => setDivisionState('children', newValue)}
                onClose={() => setDivisionState('childrenAutocompleteOpen', false)}
                getOptionLabel={(option) => option.name}
                options={availableSubteams}
                value={state.division.children}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Sub-Teams"
                    placeholder="Sub-Teams"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ContractSelector
                customerId={customerId}
                selectedContracts={state.division.contracts}
                setContract={setContracts}
                multiple
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={state.division.approvalRequired}
                    onChange={() => setDivisionState('approvalRequired', !state.division.approvalRequired)}
                    name="approvalRequired"
                  />
                )}
                label="Manager Approval Required"
              />
            </Grid>
            {state.division.approvalRequired && (
              <Grid item xs={8}>
                <Autocomplete
                  id="cu-select"
                  open={state.division.cuAutocompleteOpen}
                  onOpen={() => setDivisionState('cuAutocompleteOpen', true)}
                  onInputChange={(event, text) => debouncedCuSearch(text, customerId)}
                  onChange={(event, newValue) => setDivisionState('approverUser', newValue)}
                  onClose={() => setDivisionState('cuAutocompleteOpen', false)}
                  getOptionLabel={(option) => option.fullName}
                  options={state.division.customerUsers.filter((u) => u.roleType !== 'rep').map((cu) => omit(cu, 'divisions'))}
                  value={state.division.approverUser || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Approving Manager"
                      placeholder="Approving Manager"
                      margin="normal"
                      fullWidth
                      required={state.division.approvalRequired}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                color="error"
                startIcon={<Archive />}
                onClick={() => openDialog(<ArchiveDivisionDialog
                  divisionWithHierarchy={state.division}
                  onConfirm={() => setDivisionState('isArchived', !state.division.isArchived)}
                />)}
              >
                {state.division.isArchived ? 'Unarchive team' : 'Archive team'}
              </Button>
              <Button
                onClick={() => {
                  openDialog(<ArchiveTeamAdvisorRequestsDialog
                    affectedUsers={getUsers(state.division)}
                  />)
                }}
                startIcon={<Archive />}
                sx={{
                  ml: 0.5,
                }}
              >
                Archive all accounts
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditDivisionDialog.defaultProps = {
  division: null
}

EditDivisionDialog.propTypes = {
  division: PropTypes.object,
  divisions: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveDivision: PropTypes.func.isRequired,
  getDivisions: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  userSearch: PropTypes.func.isRequired
}

export default EditDivisionDialog
