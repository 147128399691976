import PropTypes from 'prop-types'
import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  ConfirmDialog,
  OrganizationSelector
} from 'components'
import {
  httpResponseAlert,
  simpleHttpFetch
} from 'utils-em'

const AbsorbOrganizationDialog = ({
  organization,
  saveOrg,
  deleteOrg,
  showSuccessMessage,
  closeDialog,
  openDialog
}) => {
  const [orgToAbsorb, setOrgToAbsorb] = React.useState(organization)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleAbsorbOrg = () => {
    let newAliases
    if (organization.aliases === '' || !organization.aliases) {
      newAliases = orgToAbsorb.name
    } else {
      newAliases = `${organization.aliases}|${orgToAbsorb.name}`
    }

    saveOrg(
      {
        ...organization,
        orgToAbsorbId: orgToAbsorb.id,
        aliases: newAliases
      }
    ).then((resp) => {
      // todo finish deleted then actually absorb backend
      if (resp.ok) {
        deleteOrg(orgToAbsorb).then((resp2) => {
          closeDialog('absorbOrgs')
          httpResponseAlert(resp2, {
            success: 'Organization Successfully Absorbed',
            failure: 'Failed to absorb org. Please contact engineering.'
          })
        })
      } else {
        httpResponseAlert(resp, {
          success: 'Organization Successfully Absorbed',
          failure: 'Failed to absorb org. Please contact engineering.'
        })
      }
    })
  }

  return (
    <Dialog
      open
      fullWidth
    >
      <DialogTitle>
        Absorb organization into
        {' '}
        {organization.name}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OrganizationSelector
              handleChange={(newOrg) => setOrgToAbsorb(newOrg)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={async () => {
            setIsLoading(true)
            const response = await simpleHttpFetch(
              `${__API_HOST__}/v1/organization/${organization.id}/get-absorbed-records/${orgToAbsorb.id}`,
              {
                errorMessage: 'Could not get records to be absorbed.',
                json: true
              }
            )
            setIsLoading(false)
            openDialog(
              <ConfirmDialog
                description={(
                  <>
                    <Typography variant="bodyBold">{`Are you sure you want to absorb: ${orgToAbsorb.name} into ${organization.name}?`}</Typography>
                    <Typography variant="caption">
                      The following records will be transferred to
                      {' '}
                      {organization.name}
                    </Typography>
                    {response.length ? (
                      <ul>
                        {response.map((record) => (
                          <li>{`${record.type} #${record.id}`}</li>
                        ))}
                      </ul>
                    ) : <p>None</p>}
                  </>
                )}
                actions={[
                  {
                    name: 'Absorb',
                    action: () => { handleAbsorbOrg() }
                  }
                ]}
              />
            )
          }}
        >
          Absorb
        </LoadingButton>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AbsorbOrganizationDialog.propTypes = {
  organization: PropTypes.object.isRequired,
  saveOrg: PropTypes.func.isRequired,
  deleteOrg: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default AbsorbOrganizationDialog
