import React from 'react'
import { SvgIcon } from '@mui/material'

export default function WarningTriangle (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.95221 16.3545L10.2152 5.8575C10.9531 4.38574 13.0539 4.38612 13.7913 5.85816L19.0495 16.3552C19.7156 17.6851 18.7487 19.2509 17.2613 19.2509H6.74008C5.25235 19.2509 4.28541 17.6845 4.95221 16.3545Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 10V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.7239 12.5 16Z" stroke="currentColor" />
      </svg>
    </SvgIcon>
  )
}
