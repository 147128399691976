import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Menu,
  IconButton,
  Typography
} from '@mui/material'
import {
  Filter
} from 'icons'

const TableFilters = ({ filterOptions, applyFilters, selectedItems, isRider, filterDialogTitle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  // filters is a dictionary of type {filterName: [filters] }
  const [filters, setFilters] = React.useState({})
  const [currentFilterStates, setCurrentFilterStates] = React.useState({})
  const [appliedFilterStates, setAppliedFilterStates] = React.useState({})
  const open = anchorEl !== null
  const handleClick = (e) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
  }

  React.useEffect(() => {
    setCurrentFilterStates(selectedItems)
    setAppliedFilterStates(selectedItems)
    setFilters(selectedItems)
  }, [selectedItems])

  const handleChange = (filterName, filter, state) => {
    setFilters({ ...filters, [filterName]: filter })
    setCurrentFilterStates({ ...currentFilterStates, [filterName]: state })
  }

  const onApplyFilters = () => {
    const updatedFilters = {}
    Object.entries(filters).forEach(
      ([key, value]) => {
        if (key !== 'dateRanges' && Array.isArray(value)) {
          updatedFilters[key] = value.map((v) => (v.value ? v.value : v))
        } else {
          updatedFilters[key] = value
        }
      }
    )
    applyFilters(updatedFilters, currentFilterStates)
    setAppliedFilterStates(currentFilterStates)
    setAnchorEl(null)
  }

  const orderedDisplayableFilters = filterOptions
    .filter((a) => !!a.component)
    .sort((a, b) => a.order - b.order)

  if (orderedDisplayableFilters.length === 0) return null

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ width: '40px', height: '40px', mt: 2 }}
      >
        <Filter color="primary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ p: 3, width: '728px' }}>
          <Typography variant="h2">
            {filterDialogTitle}
          </Typography>
          {orderedDisplayableFilters.map((filterOption) => (
            <Box key={filterOption.name} sx={{ mt: 4, ml: 2, mr: 2 }}>
              <filterOption.component
                key={filterOption.name}
                onChange={handleChange}
                currentState={appliedFilterStates[filterOption.name]}
                filter={filterOption.filter}
                initialSelectedFilters={filterOption.initialSelectedFilters}
                isRider={isRider}
              />
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3, mb: 2 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={onApplyFilters}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    </>
  )
}

TableFilters.defaultProps = {
  isRider: false,
  selectedItems: {}
}

TableFilters.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired
  })).isRequired,
  isRider: PropTypes.bool,
  selectedItems: PropTypes.object,
  filterDialogTitle: PropTypes.string.isRequired
}

export default TableFilters
