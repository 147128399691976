import React from 'react'
import { createTheme } from '@mui/material/styles'
import { SvgIcon } from '@mui/material'
import { ChevronDown, Clear } from 'icons'

const HEADER_HEIGHT = '80px'
const TOP_HEADER_HEIGHT = '56px' // for xs screens
const BOTTOM_HEADER_HEIGHT = '68px' // for xs screens
const XS_HEADER_HEIGHT = `calc(${BOTTOM_HEADER_HEIGHT} + ${TOP_HEADER_HEIGHT})`
const FOOTER_HEIGHT = '72px'
const PAGE_HEIGHT = `calc(100vh - ${FOOTER_HEIGHT} - ${HEADER_HEIGHT})`

const baseTheme = createTheme({
  // colors defined here match Figma naming conventions (in camelCase)
  // consult with product before adding global colors
  palette: {
    neutral: {
      black: '#242424',
      white: '#fff',
      offWhite: '#f4f4f4',
      darkGrey: '#5c5c61',
      lightGrey: '#dcdcdc',
      lightestGrey: '#414145',
      disabled: '#9c9c9c'
    },
    brand: {
      navy: '#01023d',
      lightNavy: '#f3f4f6',
      darkNavy: '#01023d',
      slate: '#6a797c',
      paleSlate: '#dae3e7',
      teal: '#33bfc0',
      darkTeal: '#00838c',
      softTeal: '#b6e3e4',
      paleTeal: '#e2f2f3',
      purple: '#7b6f95',
      lightPurple: '#9a93af',
      lightestPurple: '#eeeaf8',
      darkOrange: '#d06e58',
      orange: '#dd7a64',
      softOrange: '#f5b89c',
      paleOrange: '#fceeeb'
    },
    primary: {
      main: '#2b3bfa',
      dark: '#0918d0',
      light: '#717aed',
      lighter: '#bfc4ff',
      lightest: '#e5e7fe'
    },
    error: {
      main: '#d84b3b',
      dark: '#b51e0d',
      light: '#ee6a5b',
      lighter: '#f3c9c4',
      lightest: '#fae9e7'
    },
    warning: {
      main: '#e8aa4a',
      dark: '#cd8516',
      light: '#ffcd7e',
      lighter: '#f8e6c9',
      lightest: '#fcf5e9'
    },
    success: {
      main: '#56a75a',
      dark: '#2b7b2f',
      light: '#83ca87',
      lighter: '#cce5ce',
      lightest: '#ebf4eb'
    },
    // DO NOT USE: defined for MUI default purposes
    secondary: {
      main: '#2b3bfa',
      dark: '#0918d0',
      light: '#717aed'
    },
    // DO NOT USE: defined for MUI default purposes
    info: {
      main: '#2b3bfa',
      dark: '#0918d0',
      light: '#717aed'
    }
  },
  // fonts defined here match Figma naming conventions (in camelCase)
  // consult with product before adding global fonts
  typography: {
    fontFamily: 'Montserrat',
    hero: {
      fontWeight: '500',
      fontSize: '48px',
      lineHeight: '72px',
      letterSpacing: '0.4px'
    },
    h1: {
      fontWeight: '700',
      fontSize: '40px',
      lineHeight: '64px',
      letterSpacing: '1px'
    },
    h2: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '40px',
      letterSpacing: '0.4px'
    },
    h3: {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '40px',
      letterSpacing: '0.4px'
    },
    h4: {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.8px'
    },
    h5: {
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.8px'
    },
    bodyBold: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      fontFamily: 'Montserrat'
    },
    body1: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.4px'
    },
    captionBold: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      fontFamily: 'Montserrat'
    },
    caption: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px'
    },
    buttonL: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '1.6px',
      textTransform: 'none'
    },
    tiny: {
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    tinyBold: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    }
  },
  responsiveness: {
    hideMobile: {
      xs: 'block',
      sm: 'none'
    },
    hideNonMobile: {
      xs: 'none',
      sm: 'block'
    }
  },
  breakpoints: {
    // Responsiveness chart:
    //   sm: 0-599px (xs will fall here)
    //   md: 600-899px
    //   lg: 900-1399px
    //   xl: 1400px+

    values: {
      xs: 0,
      sm: 0,
      md: 600,
      lg: 900,
      xl: 1400,

      // deprecated breakpoints
      mobile: 0,
      tablet: 600,
      desktop: 900,
      wider: 1400
    }
  },
  footerHeight: FOOTER_HEIGHT,
  headerHeight: HEADER_HEIGHT,
  pageHeight: PAGE_HEIGHT,
})

const { breakpoints } = baseTheme

export const emissaryTheme = createTheme(baseTheme, {
  header: {
    height: HEADER_HEIGHT,
    xsHeight: XS_HEADER_HEIGHT,
    bottomHeight: BOTTOM_HEADER_HEIGHT,
    topHeight: TOP_HEADER_HEIGHT,
    color: baseTheme.palette.neutral.white,
    secondaryColor: baseTheme.palette.brand.navy,
    zIndex: 1300, // default mui value is 1100
    divider: {
      borderColor: baseTheme.palette.brand.paleSlate,
      borderBottomWidth: '4px'
    }
  },
  navSideBar: {
    zIndex: 1200, // default mui value is 1200
    drawerWidth: 264,
    drawerWidthClosed: 72,
  },
  // Responsiveness chart:
  //   h1 -> h3
  //   h4 -> bodyBold
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      ...baseTheme.typography.h1,
      [breakpoints.down('md')]: {
        ...baseTheme.typography.h2
      }
    },
    h2: {
      ...baseTheme.typography.h2,
      [breakpoints.down('md')]: {
        ...baseTheme.typography.h4
      }
    },
    h3: {
      ...baseTheme.typography.h3,
      [breakpoints.down('md')]: {
        ...baseTheme.typography.h5
      }
    },
    h4: {
      ...baseTheme.typography.h4,
      [breakpoints.down('md')]: {
        ...baseTheme.typography.bodyBold
      }
    },
    h5: {
      ...baseTheme.typography.h5,
      [breakpoints.down('md')]: {
        ...baseTheme.typography.body1
      }
    },
    // hero: {},
    // label: {},
    // bodyBold: {},
    // body1: {},
    // captionBold: {},
    // caption: {},
    // buttonL: {},
    // buttonS: {},
    // tiny: {},
    // tinyBold: {}
  },
  components: {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: baseTheme.spacing(1),
          paddingRight: baseTheme.spacing(3),
          paddingBottom: baseTheme.spacing(2),
          paddingLeft: baseTheme.spacing(3)
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.h2,
          [breakpoints.down('md')]: {
            ...baseTheme.typography.h4
          },
          paddingTop: baseTheme.spacing(3),
          paddingRight: baseTheme.spacing(3),
          paddingBottom: baseTheme.spacing(2),
          paddingLeft: baseTheme.spacing(3)
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: `${baseTheme.spacing(1)} !important`,
          paddingRight: baseTheme.spacing(3),
          paddingBottom: baseTheme.spacing(2),
          paddingLeft: baseTheme.spacing(3)
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: baseTheme.spacing(3),
          height: baseTheme.spacing(3),
          ...baseTheme.typography.tiny
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.neutral.darkGrey
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '&.MuiDialog-paperWidthSm': {
            maxWidth: '600px', // Remap sm to md, because sm breakpoint is 0
          },
          '&.MuiDialog-paperWidthMd': {
            maxWidth: '900px', // Remap md to lg
          },
          '&.MuiDialog-paperWidthLg': {
            maxWidth: '1200px', // Remap lg to xl
          },
          '&.MuiDialog-paperWidthXl': {
            maxWidth: '1536px', // Example size for xl
          },
        },
        root: {
          '& .MuiPaper-root': {
            color: baseTheme.palette.neutral.black,
            borderRadius: '4px'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.neutral.lightGrey
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
          underline: 'hover',
          '&[disabled]': {
            color: baseTheme.palette.neutral.disabled,
            cursor: 'default',
            '&:hover': {
              textDecoration: 'none'
            }
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: (props) => (
          <SvgIcon {...props} cursor="default" sx={{ pl: 0.5, pr: 0.5 }}><ChevronDown /></SvgIcon>
        ),
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <SvgIcon cursor="default" sx={{ pl: 0.5, pr: 0.5 }}><ChevronDown /></SvgIcon>,
        clearIcon: <Clear />,
      },
      styleOverrides: {
        popupIndicator: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
        clearIndicator: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& div': { paddingRight: baseTheme.spacing(1) },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          ...baseTheme.typography.body1
        },
        head: {
          textTransform: 'none',
          textAlign: 'left',
          ...baseTheme.typography.captionBold
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.neutral.darkGrey,
          '.Mui-disabled, .Mui-disabled > *': {
            color: `${baseTheme.palette.neutral.disabled} !important`,
            borderColor: `${baseTheme.palette.neutral.disabled} !important`
          },
          '.Mui-error.MuiInputLabel-root': {
            color: `${baseTheme.palette.neutral.darkGrey} !important`
          },
          '&:hover fieldset': {
            borderWidth: '1.5px'
          },
          input: {
            color: baseTheme.palette.neutral.darkGrey,
            '&::placeholder': { opacity: 1 },
          },
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.neutral.darkGrey
          },
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'span',
          body2: 'span',
          bodyBold: 'div'
        },
        fontFamily: 'Montserrat'
      },
      styleOverrides: {
        body1: {
          display: 'block',
          color: baseTheme.palette.neutral.black
        },
        body2: {
          display: 'block'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'large',
      },
      styleOverrides: {
        sizeLarge: {
          padding: '8px 16px 8px 16px',
          ...baseTheme.typography.buttonL
        },
        sizeSmall: {
          ...baseTheme.typography.buttonS,
          paddingTop: '6px',
          paddingBottom: '6px'
        }
      },
      variants: [
        // figma buttons design system:
        // https://www.figma.com/file/9b49i7O38o4l4aKMDu9RxI/Emissary-Design-System?node-id=363%3A4172&t=R7xsh66yrAbAkSJG-3
        //   primary: variant="contained"
        //   figma secondary: variant="outlined"
        //   figma tertiary: variant="text" (make this one act like a larger "Link")
        { // secondary primary
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: baseTheme.palette.primary.main
          },
        },
        { // secondary error
          props: { variant: 'outlined', color: 'error' },
          style: {
            borderColor: baseTheme.palette.error.main
          },
        },
        { // secondary warning
          props: { variant: 'outlined', color: 'warning' },
          style: {
            borderColor: baseTheme.palette.warning.main
          },
        },
        { // secondary success
          props: { variant: 'outlined', color: 'success' },
          style: {
            borderColor: baseTheme.palette.success.main
          },
        },
        { // tertiary (all); these are mainly used as cancel buttons in the dialog
          props: { variant: 'text' },
          style: {
            // before this change we were relying on the padding as our margins,
            // so the default margin is set to the default button padding
            padding: '0px',
            margin: '8px 16px 8px 16px', // default button padding,
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
            '& .MuiTouchRipple-child': {
              backgroundColor: 'transparent !important',
            },
          },
        },
      ]
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          flex: 1,
          borderWidth: '2px 2px 2px 2px !important',
          borderColor: baseTheme.palette.brand.darkNavy,
          borderLeftColor: `${baseTheme.palette.brand.darkNavy} !important`,
          borderRadius: '4px !important',
          p: baseTheme.spacing(2),
          mr: baseTheme.spacing(2),
          textTransform: 'initial',
          height: '100%',

          backgroundColor: baseTheme.palette.neutral.white,
          color: baseTheme.palette.brand.navy,
          '&.Mui-selected': {
            backgroundColor: baseTheme.palette.brand.navy,
            color: baseTheme.palette.neutral.white,
            '&:hover': {
              backgroundColor: baseTheme.palette.brand.navy
            }
          },
          '&:hover': {
            backgroundColor: baseTheme.palette.primary.lightest
          },
          '&:last-child': {
            mr: 0
          }
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          gap: baseTheme.spacing(2)
        }
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          border: `1px solid ${baseTheme.palette.neutral.lightGrey}`,
          boxSizing: 'border-box',
          borderRadius: '4px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: baseTheme.spacing(2),
          },
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: { alignItems: 'center' },
        message: { alignItems: 'center', display: 'flex' },
        action: { alignItems: 'center', paddingTop: 0 }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.body1
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          color: baseTheme.palette.neutral.darkGrey,
          backgroundColor: '#ffffff'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: baseTheme.palette.neutral.black,
        },
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.neutral.disabled,
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            textDecoration: 'none'
          },
          ...baseTheme.typography.buttonL,
          color: baseTheme.palette.neutral.black
        },
        indicator: {
          color: baseTheme.palette.primary.light
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          paddingLeft: '0 !important'
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...baseTheme.typography.tiny
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '& .Mui-completed': {
            ...baseTheme.typography.body1,
            fontSize: '24px',
            color: `${baseTheme.palette.brand.slate} !important`,
            fontWeight: `${baseTheme.typography.body1.fontWeight} !important`
          },
          '& .Mui-active': {
            ...baseTheme.typography.bodyBold,
            fontSize: '24px',
            color: `${baseTheme.palette.brand.slate} !important`,
            fontWeight: `${baseTheme.typography.bodyBold.fontWeight} !important`
          },
          '& .Mui-disabled': {
            ...baseTheme.typography.body1,
            color: `${baseTheme.palette.neutral.disabled} !important`,
            fontWeight: `${baseTheme.typography.body1.fontWeight} !important`
          }
        }
      }
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          ...baseTheme.typography.body1,
          color: baseTheme.palette.neutral.darkGrey
        }
      }
    }
  },
  // space between header and footer (3.0)
  pageContentSx: {
    minHeight: PAGE_HEIGHT,
    padding: 5,
    pl: 6,
    pr: 6
  },
})

const adminTheme = createTheme(emissaryTheme, {
  typography: {
    fontFamily: 'Inter'
  }
})

// The emissaryTheme contains multiple references to the Montserrat font
// deep within the object. Overriding the typography above isn't enough.
// For example, some components also have font overrides, so those need
// to be replaced with 'Inter'.
const deepReplaceAdminFontName = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (key === 'fontFamily') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = 'Inter'
    } else if (key && typeof obj[key] === 'object') {
      try {
        if (Object.keys(obj[key])?.length) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = deepReplaceAdminFontName(obj[key])
        }
      } catch { /* Ignore - e.g. an embedded React component */ }
    }
  })
  return obj
}
deepReplaceAdminFontName(adminTheme)

export { adminTheme }
