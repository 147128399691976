import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Link,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody
} from '@mui/material'
import Add from '@mui/icons-material/Add'
import { buildJsonApiOne } from 'utils-em'
import { JsonAPI } from 'store'

import { ConfirmDialog, Spinner } from 'components'
import EditAllotmentDialog from './EditAllotmentDialog'
import ContractUsageDetailDialog from './ContractUsageDetailDialog'

const ContractInfoRow = ({ contractId, editable, skus, deleteAllotment, openDialog }) => {
  const [loaded, setLoaded] = React.useState(false)
  const dispatch = useDispatch()
  const contract = useSelector(({ data }) => buildJsonApiOne(data, 'contracts', contractId))
  const getSkuForPool = (skuId) => (
    skus.find((sku) => parseInt(sku.id, 10) === skuId)
  )

  const getUsageForPool = (contractSkuAllotment) => {
    const matchingContractSkus = contract.contractSkus.filter((cs) => cs.skuId === contractSkuAllotment.skuId)
    return matchingContractSkus.reduce((acc, cs) => acc + cs.unitCount, 0)
  }

  const loadContract = () => {
    dispatch(JsonAPI.getOne({
      type: 'contracts',
      id: contractId,
      queryStringParams: {
        include: [
          'contractSkus',
          'contractSkuAllotments'
        ].join(','),
        sort: '-startDate'
      }
    })).then(() => setLoaded(true))
  }
  React.useEffect(() => {
    loadContract()
  }, [])

  if (!loaded) { return <Spinner /> }
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Usage</TableCell>
            { editable ? <TableCell>Actions</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {contract.contractSkuAllotments.map((csa) => (
            <TableRow key={csa.id}>
              <TableCell>{getSkuForPool(csa.skuId).name}</TableCell>
              <TableCell>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    openDialog(
                      <ContractUsageDetailDialog
                        contractId={contract.id}
                        contractSkuAllotmentId={csa.id}
                        editable={editable}
                      />
                    )
                  }}
                >
                  {getUsageForPool(csa)}
                  /
                  {csa.isUnlimitedAllotment ? 'Unlimited' : csa.allotment}
                </Link>
              </TableCell>
              { editable
                ? (
                  <TableCell>
                    <Button
                      sx={{ m: 1 }}
                      color="primary"
                      onClick={() => openDialog((
                        <EditAllotmentDialog
                          contractId={contract.id}
                          contractSkuAllotmentId={csa.id}
                        />
                      ))}
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{ m: 1 }}
                      color="primary"
                      onClick={() => openDialog(<ConfirmDialog
                        title="Delete Allotment"
                        description={`Really delete allotment for: ${getSkuForPool(csa.skuId).name}?`}
                        actions={[
                          {
                            name: 'Delete',
                            action: () => { deleteAllotment(csa) },
                            isDelete: true
                          }
                        ]}
                      />)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                  )
                : null }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      { editable
        ? (
          <Button
            sx={{ m: 1 }}
            color="primary"
            variant="contained"
            onClick={() => openDialog((
              <EditAllotmentDialog
                contractId={contract.id}
              />
            ))}
          >
            <Add />
            {' '}
            Add New Allotment
          </Button>
          )
        : null }
    </>
  )
}

ContractInfoRow.propTypes = {
  editable: PropTypes.bool.isRequired,
  contractId: PropTypes.number.isRequired,
  skus: PropTypes.array.isRequired,
  deleteAllotment: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default ContractInfoRow
