import React from 'react'

import { useSelector } from 'react-redux'
import { useJsonAPIGetAll } from 'hooks'
import PropTypes from 'prop-types'
import { navigateTo } from 'utils-em'

import { Box, Link, Typography } from '@mui/material'

const SavedInsightsSection = ({ userId }) => {
  const { id } = useSelector(({ session }) => session)

  const { objects: organizations } = useJsonAPIGetAll('organizations', {
    queryStringParams: {
      include: 'bookmarksReceived,accountPages',
      filter: [{
        name: 'bookmarksReceived',
        op: 'any',
        val: { name: 'ownerId', op: 'eq', val: id }
      }]
    }
  })
  const { objects: assets } = useJsonAPIGetAll('assets', {
    queryStringParams: {
      include: 'bookmarksReceived',
      filter: [
        {
          name: 'bookmarksReceived',
          op: 'any',
          val: { name: 'ownerId', op: 'eq', val: id }
        },
        { name: 'isArchived', op: 'is', val: false },
        { name: 'isDeleted', op: 'is', val: false }
      ]
    }
  })

  const savedOrganizations = organizations?.filter((org) => (org.bookmarksReceived)) || []
  const savedAssets = assets?.filter((asset) => (asset.bookmarksReceived)) || []

  const assetCardObjects = savedAssets.map((insight) => (
    {
      link: `/c/insights/${insight.id}`,
      title: insight.title,
      key: `asset-${insight.id}`
    }))

  const hasPublished = (org) => org.accountPages.find((ac) => ac.isPublished)

  const orgCardObjects = savedOrganizations.filter((org) => hasPublished(org)).map((org) => {
    const publishedPremium = org.accountPages.find((ac) => ac.isPublished)
    const link = `/c/org-buying-profile-premium/${publishedPremium.id}`
    const title = `${org.name} Organizational Buying Profile`
    return { link, title, key: org.id }
  })

  const insightCardObjects = [...assetCardObjects, ...orgCardObjects].sort((a, b) => (a.title < b.title ? -1 : 1))

  if (userId !== id) { return null }

  const renderCards = () => {
    if (!insightCardObjects.length) {
      return (
        <Typography>
          <Link onClick={() => navigateTo('/c/insights')}>Search the Insights Library</Link>
          {` to find insights. Keep track of the insights you're interested in by saving them 
          to your account, and we will notify you whenever they are updated.`}
        </Typography>
      )
    }

    return (
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        {insightCardObjects.map((insightObj, idx) => (
          <Box
            key={insightObj.key}
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              p: 2,
              border: 1,
              borderRadius: 1,
              m: 2,
              ml: (idx % 3 === 0 ? 0 : 2),
              borderColor: 'neutral.lightGrey',
              ':hover': { bgcolor: 'neutral.offWhite', boxShadow: 4 }
            }}
            onClick={() => navigateTo(insightObj.link)}
          >
            <Box typography="body1" color="neutral.black">
              {insightObj.title}
            </Box>
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Box>
      <Box typography="h4" color="neutral.black" sx={{ mb: 2 }}>Saved insights</Box>
      <Box sx={{ mb: 2 }}>{renderCards()}</Box>
    </Box>
  )
}

SavedInsightsSection.propTypes = {
  userId: PropTypes.number.isRequired
}

export default SavedInsightsSection
