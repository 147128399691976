import React from 'react'
import { Layout } from 'layouts'

import SignupForm from '../components/SignupForm'

const AdvisorOnboardingLayout = () => (
  <Layout hideNotifications hideMenu>
    <SignupForm />
  </Layout>
)

export default AdvisorOnboardingLayout
