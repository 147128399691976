import React from 'react'
import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip
} from '@mui/material'
import { useJsonAPIUpsert } from 'hooks'
import {
  Pencil
} from 'icons'
import {
  ConfirmDialog,
  JsonApiDataGrid
} from 'components'
import {
  formatDate,
  openDialog
} from 'utils-em'
import FeatureFlagDialog from './components/FeatureFlagDialog'

const FeatureFlags = () => {
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })

  const handleSave = async (flagId, enabled) => {
    await upsert({
      type: 'featureFlags',
      id: flagId,
      enabled
    })
  }

  const openConfirmDialog = (flag, enabled) => {
    const actionText = enabled ? 'Enable' : 'Disable'
    openDialog(
      (<ConfirmDialog
        size="sm"
        title={`${actionText} ${flag.feature}`}
        description={
          `Are you sure you want to ${actionText.toLowerCase()} ${flag.feature}?
          You may need to refresh the admin app to see changes.`
        }
        actions={[
          {
            name: actionText,
            action: async () => handleSave(flag.id, enabled)
          }
        ]}
      />),
      flag.feature
    )
  }

  const columns = [
    {
      field: 'dateCreated',
      headerName: 'Date created',
      type: 'date',
      width: 125,
      valueGetter: ({ row }) => row.dateCreated && new Date(row.dateCreated),
      renderCell: ({ row }) => (
        <>
          {formatDate(row.dateCreated, { includeYear: true })}
        </>
      )
    },
    {
      field: 'feature',
      headerName: 'Feature',
      width: 300,
      valueGetter: ({ row }) => row.feature,
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 1,
      valueGetter: ({ row }) => row.description,
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      valueGetter: ({ row }) => row.enabled,
      actions: true,
      renderCell: ({ row }) => (
        <Tooltip title={row.enabled ? 'Disable' : 'Enable'}>
          <FormControlLabel
            control={(
              <Switch
                checked={row.enabled}
                onChange={() => openConfirmDialog(row, !row.enabled)}
                name="hasPage"
              />
            )}
          />
        </Tooltip>
      ),
    },
    {
      field: 'customers',
      headerName: 'Customers',
      sortable: false,
      filterable: false,
      valueGetter: ({ row }) => row?.customers?.length || 0
    },
    {
      field: 'users',
      headerName: 'Users',
      sortable: false,
      filterable: false,
      valueGetter: ({ row }) => row?.users?.length || 0
    },
    {
      field: 'actions',
      headerName: 'Actions',
      actions: true,
      width: 100,
      renderCell: ({ row }) => (
        <>
          <Tooltip title="Edit customers and users">
            <IconButton color="primary" onClick={() => openDialog(<FeatureFlagDialog flagId={row.id} />)}>
              <Pencil />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ]
  return (
    <Box sx={(theme) => ({ ...theme.pageContentSx, width: '100%' })}>
      <Box sx={{ py: 4 }}>
        <Box typography="h1" variant="h1" sx={{ p: 2 }}>
          Features
        </Box>
        <JsonApiDataGrid
          type="featureFlags"
          columns={columns}
          jsonApiOptions={{
            sortBy: '-dateCreated',
            include: ['users', 'customers']
          }}
          mode="server"
          density="compact"
          disableExport
        />
      </Box>
    </Box>
  )
}

export default FeatureFlags
