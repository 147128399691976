/* eslint-disable camelcase */
const CURRENT_ENV = __ENV__

export default function initializePendo (state) {
  const {
    user_id,
    user_email,
    user_type,
    status,
    onboarding_date,
    stealth,
    customer_name,
    role_type,
    in_seat
  } = state
  window.pendo.initialize({
    visitor: {
      id: user_email,
      email: user_email,
      user_id,
      user_type,
      environment: CURRENT_ENV,
      status,
      onboarding_date,
      stealth,
      customer_name,
      role_type,
      in_seat
    },
    account: {
      id: customer_name
    },
    disableGuides: !user_email || user_email.startsWith('test_')
  })
}
