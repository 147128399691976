import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography
} from '@mui/material'
import { AddParticipantsSection, ConnectByEmailSection } from 'components'

const FeedbackCompleted = ({ engagementId, isAdvisor, context }) => (
  <>
    <Box sx={{ p: 3, bgcolor: 'brand.lightNavy', borderRadius: 1 }}>
      <Typography variant="h4">
        Done! Thank you for submitting feedback.
      </Typography>
    </Box>
    {!isAdvisor && (
      <Box>
        <AddParticipantsSection engagementId={engagementId} showPostCallText context={context} />
        <ConnectByEmailSection engagementId={engagementId} followUpText />
      </Box>
    )}
  </>
)

FeedbackCompleted.defaultProps = {
  isAdvisor: false,
  context: {}
}
FeedbackCompleted.propTypes = {
  isAdvisor: PropTypes.bool,
  engagementId: PropTypes.string.isRequired,
  context: PropTypes.object
}

export default FeedbackCompleted
