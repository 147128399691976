import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  Box,
  Typography
} from '@mui/material'

import { LoadingButton } from '@mui/lab'

import { TogglablePasswordInput } from 'components'

import {
  customAlert,
  validatePasswordStrict
} from 'utils-em'

const PasswordStage = ({ userUuid, onCompleteCallback }) => {
  const user = useSelector(({ session }) => session)
  const [displayErrors, setDisplayErrors] = React.useState(false)
  const [displayPasswordErrorOutFocus, setDisplayPasswordErrorOutFocus] = React.useState(false)

  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [acceptTermsChecked, setAcceptTermsChecked] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const isValidPassword = validatePasswordStrict(password).valid
  const passwordsMatch = password === confirmPassword

  const submitPasswords = async () => {
    setDisplayErrors(false)
    setIsSubmitting(true)

    const isValidForm = password.length && isValidPassword && passwordsMatch && acceptTermsChecked
    if (!isValidForm) {
      setDisplayErrors(true)
      setIsSubmitting(false)
      return
    }

    try {
      const result = await fetch(
        `${__API_HOST__}/onboard/advisor`,
        {
          credentials: 'include',
          method: 'PUT',
          body: JSON.stringify({ password, confirmPassword, userUuid }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const responseJson = await result.json()
      if (responseJson.error) {
        customAlert("Passwords must be at least 8 characters long and can't be easy to guess, like password, 12345, or abcde.", true)
      } else {
        onCompleteCallback(password, responseJson.assignmentId, user?.cloaked)
      }
    } catch (e) {
      customAlert('An unknown error occurred. Please contact support for assistance.', true)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        Welcome to Emissary
      </Typography>
      <Typography variant="body1" color="neutral.black">
        Set a password, then complete your first paid opportunity.
      </Typography>
      <TogglablePasswordInput
        placeholder="Create password"
        variant="outlined"
        required
        value={password}
        disabled={isSubmitting}
        error={(displayErrors || displayPasswordErrorOutFocus) && !isValidPassword}
        onChange={(event) => setPassword(event.target.value)}
        onBlur={() => setDisplayPasswordErrorOutFocus(true)} // first password field can show validation error after exiting focus for first time
        sx={{ mt: 3, backgroundColor: 'neutral.white' }}
      />
      {(displayErrors || displayPasswordErrorOutFocus) && !isValidPassword && (<Typography variant="caption" color="error.main">Passwords must be at least 8 characters long.</Typography>)}
      <TogglablePasswordInput
        placeholder="Confirm password"
        variant="outlined"
        required
        value={confirmPassword}
        disabled={isSubmitting}
        error={displayErrors && !passwordsMatch}
        onChange={(event) => setConfirmPassword(event.target.value)}
        sx={{ mt: 3, backgroundColor: 'neutral.white' }}
      />
      {displayErrors && !passwordsMatch && (<Typography variant="caption" color="error.main">Passwords do not match.</Typography>)}
      <FormControl>
        <FormControlLabel
          control={<Checkbox checked={acceptTermsChecked} onChange={() => setAcceptTermsChecked(!acceptTermsChecked)} />}
          label={(
            <>
              Accept&nbsp;
              <Link href="https://emissary.io/advisor-terms-and-conditions/" target="_blank" underline="hover">Terms and Conditions</Link>
              &nbsp;and&nbsp;
              <Link href="https://emissary.io/privacy-policy/" target="_blank">Privacy Statement</Link>
              .
            </>
          )}
          sx={{ mt: 4, ml: 1, wordBreak: 'break-word' }}
        />
      </FormControl>
      {displayErrors && !acceptTermsChecked && (<Typography variant="caption" color="error.main" sx={{ display: 'block' }}>This field is required.</Typography>)}
      <Box sx={{ mt: 4, mb: 3 }}>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          onClick={submitPasswords}
          id="continue-password"
        >
          Sign up
        </LoadingButton>
      </Box>
      <Typography variant="caption" color="neutral.black">
        {'If you have any questions or need help, email '}
        <Link href="mailto:advisor-support@emissary.io" rel="noreferrer" target="_blank">
          advisor-support@emissary.io
        </Link>
      </Typography>
    </Box>
  )
}

PasswordStage.propTypes = {
  userUuid: PropTypes.string.isRequired,
  onCompleteCallback: PropTypes.func.isRequired
}

export default PasswordStage
