import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { getComponentDisplayName } from 'utils-em'

export default function withForcedOnboarding (Component) {
  const ForcedOnboardingComponent = (props) => {
    const { userType, location, uuid } = props
    const otherProps = { ...props }
    delete otherProps.userType
    delete otherProps.location
    delete otherProps.dispatch

    const isNavigatingToOnboarding = location.pathname.split('/')[2] === 'onboarding'
    const isNavigatingToWelcome = location.pathname.split('/')[2] === 'welcome'
    const isNavigatingToSurvey = location.pathname.split('/')[2] === 'survey'
    const isNavigatingToAdvisorTwoOhHome = location.pathname.split('/')[1] === 'a'

    const { status } = useSelector(({ session }) => session)
    const stepsThatDoNotRequireFurtherOnboarding = ['active', 'idle']
    const needsFurtherOnboarding = !stepsThatDoNotRequireFurtherOnboarding.includes(status)
    if (userType === 'advisor') {
      const alreadyBeingRedirected = isNavigatingToOnboarding || isNavigatingToWelcome
      if (['sign_up_incomplete_needs_basic_info', 'sign_up_incomplete_needs_employment_history'].includes(status) && !alreadyBeingRedirected && !isNavigatingToSurvey) {
        return <Redirect to="/a/onboarding" />
      }
      if (!needsFurtherOnboarding && !isNavigatingToAdvisorTwoOhHome) {
        return <Redirect to="/a" />
      }
      if (!alreadyBeingRedirected) {
        if (isNavigatingToSurvey) {
          // Do nothing - allow this navigation to survey to continue
        } else if (needsFurtherOnboarding) {
          if (!isNavigatingToOnboarding) {
            return <Redirect to={`/signup?userUuid=${uuid}`} />
          }
        }
      }
    }
    if (userType === 'customer') {
      if (!isNavigatingToOnboarding && needsFurtherOnboarding) {
        return <Redirect to="/c/onboarding" />
      }
    }

    return <Component {...otherProps} />
  }

  ForcedOnboardingComponent.propTypes = {
    isOnboarded: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
    roleType: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired
  }

  ForcedOnboardingComponent.displayName = `ForcedOnboardingComponent(${getComponentDisplayName(Component)})`
  const mapStateToProps = ({ router, session }) => {
    const isOnboarded = !!session.onboardingCompleted
    const uuid = session.uuid || ''
    const userType = session.userType || ''
    const roleType = session.roleType || ''
    const features = session.features || []
    return { isOnboarded, userType, roleType, features, location: router.location, uuid }
  }

  const applyEnhancements = compose(withRouter, connect(mapStateToProps))

  return applyEnhancements(ForcedOnboardingComponent)
}
