import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { BadgeChip } from 'components'

const EngagementStateChip = ({ engagementState, onCard, isCallToday, size }) => {
  const isAdvisor = useSelector(({ session }) => session.userType === 'advisor')
  let text = engagementState
  let color = 'grey'
  let displayChip = false

  if (engagementState === 'active' && !onCard) {
    color = 'green'
    displayChip = true
  }

  if (engagementState === 'closed' && !onCard) {
    displayChip = true
  }

  if (!isAdvisor && engagementState === 'proposed') {
    text = 'PENDING ADVISOR REVIEW'
    color = 'yellow'
    displayChip = true
  }

  if (isCallToday) {
    text = 'Call Today'
    color = 'blue'
    displayChip = true
  }

  if (!displayChip) return null

  text = text.toLowerCase().charAt(0).toUpperCase() + text.toLowerCase().slice(1)

  return (
    <BadgeChip
      text={text}
      size={size}
      color={color}
      sx={{ ml: 1 }}
    />
  )
}

EngagementStateChip.defaultProps = {
  onCard: false,
  isCallToday: false,
  size: 'large',
}

EngagementStateChip.propTypes = {
  engagementState: PropTypes.string.isRequired,
  onCard: PropTypes.bool,
  isCallToday: PropTypes.bool,
  size: PropTypes.string,
}

export default EngagementStateChip
