import { connect } from 'react-redux'

import { buildJsonApiOrdered, getCountJsonApi } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'

import EngagementsTable from './EngagementsTable'

const mapStateToProps = ({ data }) => ({
  engagements: buildJsonApiOrdered(data, 'engagements'),
  engagementsCount: getCountJsonApi(data, 'engagements') || 0
})

const mapDispatchToProps = {
  getEngagements: (size, page, filter, sort) => (
    JsonAPI.getAll({
      type: 'engagements',
      queryStringParams: {
        'page[number]': page,
        'page[size]': size,
        include: [
          'advisor',
          'customerUser',
          'customerUser.customer',
          'charges',
          'charges.divisions',
          'charges.sku',
          'organization'
        ].join(','),
        filter,
        sort
      }
    })
  ),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementsTable)
