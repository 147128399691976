import React from 'react'
import { ValidatorComponent } from 'react-material-ui-form-validator'
import { MuiPhoneNumber } from 'components'

// inspired by https://github.com/NewOldMax/react-material-ui-form-validator/blob/master/src/TextValidator.jsx
class PhoneNumberValidator extends ValidatorComponent {
  renderValidatorComponent () {
    /* eslint-disable no-unused-vars */
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      value,
      withRequiredValidator,
      classes,
      variant,
      size,
      label,
      ...rest
    } = this.props
    const { isValid } = this.state

    return (
      <MuiPhoneNumber
        // this makes it so that numbers already in our system w/o a country code are defaulted to US
        {...rest}
        sx={{
          '& .MuiPhoneNumber-flagButton': { // https://github.com/alexplumb/material-ui-phone-number/pull/116/files
            width: 'min-content',
            '& svg': {
              width: 'fill-available'
            }
          }
        }}
        value={`${value?.includes('+') ? '' : '+1'}${value}`}
        error={!isValid || error}
        helperText={(!isValid && this.getErrorMessage()) || helperText}
        variant={variant || 'standard'}
        size={size || 'medium'}
        fullWidth
        label={label || 'Phone number'}
        disableAreaCodes
      />
    )
  }
}

export default PhoneNumberValidator
