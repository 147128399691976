import PropTypes from 'prop-types'

const AdvisorAccessControl = ({ children }) => children

AdvisorAccessControl.defaultProps = {
  children: null
}

AdvisorAccessControl.propTypes = {
  children: PropTypes.node
}

export default AdvisorAccessControl
