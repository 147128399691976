import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import {
  Box,
  Button,
  Typography,
} from '@mui/material'
import {
  confirmDialog,
  getEngagementMostRelevantTimeslot,
  getMostRecentProposal,
  formatDate,
  isCustomerFeedbackSubmitted,
  httpResponseAlert
} from 'utils-em'
import {
  AdvisorCallCompletedText,
  ConnectByEmailSection
} from 'components'
import { JsonAPI } from 'store'
import AdvisorFeedbackForm from './AdvisorFeedbackForm'
import FeedbackCompleted from './FeedbackCompleted'

const EngagementFeedbackStep = ({ engagement, isAdvisor, reloadEngagement, context }) => {
  const dispatch = useDispatch()
  const urlHash = useSelector(({ router }) => router.location.hash)
  const sessionUser = useSelector(({ session }) => session)
  const [showFeedbackForm, setShowFeedbackForm] = React.useState(urlHash === '#feedback')
  const firstName = isAdvisor ? engagement.customerUser.firstName : engagement.advisor.firstName
  const proposal = getMostRecentProposal(engagement)
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  const [hasCustomerSubmittedFeedback, setHasCustomerSubmittedFeedback] = React.useState(isCustomerFeedbackSubmitted(engagement))
  const [isAdvisorFeedbackSubmitted, setIsAdvisorFeedbackSubmitted] = React.useState(Boolean(engagement.advisorSingleCallFeedback))
  const isEngagementParticipant = sessionUser.userType === 'customer' && parseInt(engagement.customerUser.id, 10) !== sessionUser.id

  React.useEffect(() => {
    if (urlHash.toLowerCase() === '#missedcall') openMissedCallDialog()
  }, [])

  if (showFeedbackForm) {
    if (isAdvisor) {
      const handleComplete = () => {
        setShowFeedbackForm(false)
        setIsAdvisorFeedbackSubmitted(true)
        setHasCustomerSubmittedFeedback(true)
        reloadEngagement()
      }
      return <AdvisorFeedbackForm advisorId={engagement.advisor.id} engagementId={engagement.id} handleComplete={handleComplete} />
    }
    if (!isAdvisor && !hasCustomerSubmittedFeedback) {
      return <Redirect to={`/c/engagements/${engagement.id}/post-call-feedback`} />
    }
  }

  if (isAdvisor && isAdvisorFeedbackSubmitted) {
    return <FeedbackCompleted engagementId={engagement.id} isAdvisor={isAdvisor} />
  }
  if (!isAdvisor && hasCustomerSubmittedFeedback) {
    return <FeedbackCompleted engagementId={engagement.id} context={context} />
  }

  const markCallMissed = () => {
    dispatch(JsonAPI.save({
      ...proposal,
      markMissed: true,
      riders: ['markMissed'],
      include: 'engagement.notifications',
      queryStringParams: { 'lazy[engagements]': 'step' }
    })).then((resp) => {
      httpResponseAlert(resp, {
        success: 'Call marked as missed'
      })
    })
  }

  const openMissedCallDialog = (_) => {
    confirmDialog({
      title: 'Mark call as missed',
      description: 'Sorry you weren’t able to connect. Let’s try again.',
      actions: [
        {
          name: 'Reschedule Call',
          action: markCallMissed
        }
      ]
    })
  }

  return (
    <>
      {relevantTimeslot &&
        (
        <Box sx={{ p: 3, bgcolor: 'brand.lightNavy', borderRadius: 1 }}>
          <Typography variant="h4" color="neutral.black">
            {`Submit feedback for your call with ${firstName} on ${formatDate(relevantTimeslot.endTime, { includeYear: true })}`}
          </Typography>
          <Box sx={{ display: 'flex', my: 2 }}>
            <Button
              sx={{ mr: 2 }}
              onClick={() => { setShowFeedbackForm(true) }}
              variant="contained"
              color="primary"
              disabled={isEngagementParticipant}
            >
              Start feedback form
            </Button>

            {engagement.state === 'active' && (
            <Button
              sx={{ mr: 2, textTransform: 'none' }}
              onClick={openMissedCallDialog}
              disabled={isEngagementParticipant}
            >
              Mark call as missed
            </Button>
            )}
          </Box>
          {isAdvisor && <AdvisorCallCompletedText engagementId={engagement.id} />}
        </Box>
        )}
      {engagement.state === 'active' && <ConnectByEmailSection engagementId={engagement.id} followUpText />}
    </>
  )
}

EngagementFeedbackStep.defaultProps = {
  context: {}
}

EngagementFeedbackStep.propTypes = {
  engagement: PropTypes.object.isRequired,
  isAdvisor: PropTypes.bool.isRequired,
  reloadEngagement: PropTypes.func.isRequired,
  context: PropTypes.object
}

export default EngagementFeedbackStep
