import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  Slider,
  SliderThumb,
  Tooltip,
  Typography
} from '@mui/material'

import { HelpCircle } from 'icons'

const WhiskerThumb = ({ children, ...rest }) => {
  if (rest['data-index'] !== 1) return null // only display middle thumb
  return (
    <SliderThumb
      {...rest}
      elevation={0}
      sx={{
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'neutral.white',
        borderRadius: '100%',
        bgcolor: 'brand.orange',
        height: '32px',
        width: '32px',
        '&::before': { boxShadow: 'none' }
      }}
    >
      {children}
    </SliderThumb>
  )
}

WhiskerThumb.propTypes = {
  children: PropTypes.node.isRequired,
}

const WhiskerCard = ({
  chartLow,
  chartMid,
  chartHigh,
  textMin,
  textMax,
  chartMin,
  chartMax,
  title,
  ...rest
}) => (
  <Box {...rest}>
    <Card sx={{ p: 3, bgcolor: 'brand.lightNavy', borderRadius: 1, border: 'none', flexBasis: '50%' }}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
        <Typography variant="tiny" sx={{ lineHeight: 2 }}>We asked advisors:</Typography>
        <Tooltip
          placement="top"
          title={
            `Advisors rate each topic on a 1 to 7 scale.
            These charts show where the account typically leans on key topics
            (indicated by the response mean in the solid circle) and potential
            differences across business units (the orange bar illustrates the
            range of responses). Use the quotes and summaries to understand
            the ‘why’ behind the ratings.`
          }
        >
          <span>
            <HelpCircle
              sx={{
                color: 'neutral.darkGrey',
                cursor: 'pointer',
                width: 24,
                height: 24
              }}
            />
          </span>
        </Tooltip>
      </Box>
      {title && <Typography variant="bodyBold" color="neutral.black" mb={1}>{title}</Typography>}
      <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
        <Typography variant="tinyBold" color="neural.black" sx={{ mr: 2 }}>{chartMin}</Typography>
        <Slider
          disabled
          components={{ Thumb: WhiskerThumb }}
          min={chartMin}
          max={chartMax}
          defaultValue={[chartLow, chartMid, chartHigh]}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => (
            <Typography color="neutral.white" variant="captionBold">
              {value}
            </Typography>
          )}
          sx={{
            '& .MuiSlider-valueLabel': { bgcolor: 'transparent', top: '30px' },
            '& .MuiSlider-rail': { height: '8px', color: 'brand.paleSlate', opacity: 1 },
            '& .MuiSlider-track': { height: '8px', color: 'brand.orange', border: 'none' }
          }}
        />
        <Typography variant="tinyBold" color="neural.black" sx={{ ml: 2 }}>{chartMax}</Typography>
      </Box>
      {textMin && textMax && (
        <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
          <Box textAlign="left" sx={{ mr: 1 }}>
            <Typography variant="tiny">{textMin}</Typography>
          </Box>
          <Box textAlign="right" sx={{ ml: 1 }}>
            <Typography variant="tiny">{textMax}</Typography>
          </Box>
        </Box>
      )}
    </Card>
  </Box>
)

WhiskerCard.defaultProps = {
  textMin: null,
  textMax: null,
  chartMin: 1,
  chartMax: 7,
  title: null
}

WhiskerCard.propTypes = {
  chartLow: PropTypes.number.isRequired,
  chartMid: PropTypes.number.isRequired,
  chartHigh: PropTypes.number.isRequired,
  textMin: PropTypes.string,
  textMax: PropTypes.string,
  chartMin: PropTypes.number,
  chartMax: PropTypes.number,
  title: PropTypes.string,
}

export default WhiskerCard
