import React from 'react'
import { Box, Typography } from '@mui/material'
import { Tabs } from 'components'
import { useFeatureFlag } from 'hooks'

import TopicEditor from './components/TopicsTab'
import KnowledgePageBuilder from './components/KnowledgePageBuilder'
import ContentManagementContainer from './components/InsightLibraryTab'
import ExpertiseTab from './components/ExpertiseTab'

const ContentManagement = () => {
  const knowledgePageSchemaBuilder = useFeatureFlag('knowledge_page_schema_builder')

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h2">
        Content Management
      </Typography>
      <Tabs
        tabs={[
          { name: 'Page Hierarchy', component: <KnowledgePageBuilder />, hash: '#knowledge-page', visible: knowledgePageSchemaBuilder },
          { name: 'Topics', component: <TopicEditor />, hash: '#topics', visible: true },
          { name: 'Areas of Expertise', component: <ExpertiseTab />, hash: '#expertise', visible: true },
          { name: 'Insights Library', component: <ContentManagementContainer />, hash: '#insights-library', visible: true },
        ].filter((tab) => tab.visible)}
      />
    </Box>
  )
}

export default ContentManagement
