import PropTypes from 'prop-types'
import React from 'react'
import { Dialog } from '@mui/material'
import { ManageAccounts } from 'components'
import { closeDialog } from 'utils-em'

const ManageAccountsDialog = ({ userId, reload, sortBy, onSubmit }) => (
  <Dialog
    open
    onClose={closeDialog}
    fullWidth
    maxWidth="sm"
  >
    <ManageAccounts
      userId={userId}
      onSubmitCallback={() => {
        onSubmit && onSubmit()
        closeDialog()
      }}
      reload={reload}
      sortBy={sortBy}
    />
  </Dialog>
)

ManageAccountsDialog.defaultProps = {
  reload: null,
  sortBy: '',
  onSubmit: null
}

ManageAccountsDialog.propTypes = {
  userId: PropTypes.number.isRequired,
  reload: PropTypes.func,
  sortBy: PropTypes.string,
  onSubmit: PropTypes.func
}

export default ManageAccountsDialog
