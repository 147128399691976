import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'

const Selector = ({
  initialOption,
  options,
  onChange,
  getOptionLabel,
  onClear,
  textFieldProps,
  label,
  disableClear,
  disabled,
  error
}) => {
  const [selectedOption, setSelectedOption] = React.useState(initialOption)

  const handleChange = (_e, newValue) => {
    setSelectedOption(newValue)
    onChange(newValue)
  }

  const handleClear = () => {
    setSelectedOption(null)
    if (onClear) onClear()
  }

  React.useEffect(() => { onChange(selectedOption) }, [selectedOption])
  React.useEffect(() => { setSelectedOption(initialOption) }, [initialOption])

  return (
    <Autocomplete
      value={selectedOption}
      onChange={handleChange}
      fullWidth
      options={options}
      getOptionLabel={getOptionLabel}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: selectedOption || params.inputProps?.value || params.inputProps?.placeholder ? true : undefined,
          }}
          {...textFieldProps}
          value="hello"
          error={error}
        />
      )}
      clearButton={disableClear ? undefined : (
        <button type="button" onClick={handleClear}>Clear</button>
      )}
    />
  )
}

Selector.defaultProps = {
  initialOption: null,
  options: [],
  label: 'Select',
  getOptionLabel: (option) => String(option),
  onClear: null,
  textFieldProps: {},
  disableClear: false,
  disabled: false,
}

Selector.propTypes = {
  initialOption: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  onClear: PropTypes.func,
  textFieldProps: PropTypes.object,
  label: PropTypes.string,
  disableClear: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  getTextFieldLabel: PropTypes.func
}

export default Selector
