import { serializeJsonApi, serializeJsonApiList } from 'utils-em'
import { camelCase, snakeCase } from 'lodash'

import API from '../_resources'
import {
  populateJsonApiManually,
  populateJsonApi,
  populateOneJsonApi,
  createJsonApi,
  updateJsonApiList,
  createJsonApiList,
  updateJsonApi,
  deleteJsonApi
} from './actions'

export const JsonAPI = new API('/v1', {
  getAll: {
    url: '/v1/:type',
    params: { type: 'type', sort: 'sort', 'page[size]': 'size', 'page[number]': 'page', include: 'include', filter: 'filter' },
    method: 'GET',
    beforeRequestTransformObject: (data) => ({
      type: snakeCase(data.type),
      ...data
    }),
    onSuccess: ({ data }, dispatch, getState, params) => dispatch(populateJsonApi(data, params))
  },
  getOne: {
    url: '/v1/:type/:id',
    params: { type: 'type', id: 'id', include: 'include' },
    method: 'GET',
    beforeRequestTransformObject: (data) => ({
      type: snakeCase(data.type),
      ...data
    }),
    onSuccess: ({ data }, dispatch, getState, params) => dispatch(populateOneJsonApi(data, params))
  },
  create: {
    url: '/v1/:type',
    params: { type: 'type', include: 'include' },
    method: 'POST',
    beforeRequestTransformObject: (data) => (
      serializeJsonApi(data)
    ),
    onSuccess: ({ data }, dispatch, getState, params) => dispatch(createJsonApi(data, params))
  },
  createList: {
    url: '/v1/:type',
    params: { type: 'type', include: 'include' },
    method: 'POST',
    beforeRequestTransformObject: (data) => (
      serializeJsonApiList(data)
    ),
    onSuccess: ({ data }, dispatch, getState, params) => dispatch(createJsonApiList(data, params))
  },
  save: {
    url: '/v1/:type/:id',
    params: { type: 'type', id: 'id', include: 'include' },
    method: 'PATCH',
    beforeRequestTransformObject: (data) => (
      serializeJsonApi(data)
    ),
    onSuccess: ({ data }, dispatch, getState, params) => dispatch(updateJsonApi(data, params))
  },
  saveList: {
    url: '/v1/:type',
    params: { type: 'type' },
    method: 'PATCH',
    beforeRequestTransformObject: (data) => (
      serializeJsonApiList(data)
    ),
    onSuccess: ({ data }, dispatch, getState, params) => dispatch(updateJsonApiList(data, params))
  },
  delete: {
    url: '/v1/:type/:id',
    params: { type: 'type', id: 'id' },
    method: 'DELETE',
    beforeRequestTransformObject: (data) => ({
      type: camelCase(data.type),
      id: data.id
    }),
    onSuccess: ({ data }, dispatch, getState, params) => dispatch(deleteJsonApi(data, params))
  }
})

export const JsonAPIActions = {
  populateJsonApiManually,
  populateJsonApi,
  populateOneJsonApi,
  createJsonApi,
  updateJsonApiList,
  createJsonApiList,
  updateJsonApi,
  deleteJsonApi
}
