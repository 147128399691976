import { connect } from 'react-redux'
import { buildJsonApiAll } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'

import EditDivisionDialog from './EditDivisionDialog'

const mapStateToProps = ({ data }) => ({
  divisions: buildJsonApiAll(data, 'divisions'),
  users: buildJsonApiAll(data, 'customerUsers')
})

const mapDispatchToProps = {
  saveDivision: (division) => {
    const method = division.id ? JsonAPI.save : JsonAPI.create
    return method(Object.assign(division, {
      type: 'divisions',
      include: 'customerUsers,children',
      relationshipNames: ['customerUsers', 'children']
    }))
  },
  userSearch: (searchText, customerId) => JsonAPI.getAll({
    type: 'customerUsers',
    sort: 'fullName',
    queryStringParams: {
      filter: [
        { name: 'fullName', op: 'like', val: `%${searchText}%` },
        { name: 'customerId', op: 'eq', val: customerId }
      ]
    }
  }),
  getDivisions: (customerId) => JsonAPI.getAll({
    type: 'divisions',
    sort: 'name',
    queryStringParams: {
      filter: [
        { name: 'customerId', op: 'eq', val: customerId }
      ]
    }
  }),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog()),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message))
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDivisionDialog)
