import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Prompt } from 'react-router-dom'
import {
  Box,
  Link,
  Typography
} from '@mui/material'
import { useIsAdmin } from 'hooks'
import { buildJsonApiOne, navigateTo, openDialog } from 'utils-em'
import { JsonAPI } from 'store'
import { AdvisorProfileDialog, ChangePassword, Spinner } from 'components'
import { ArrowLeft, Eye } from 'icons'
import PersonalInfo from './components/PersonalInfo'
import PaymentInfo from './components/PaymentInfo'
import EmploymentHistory from './components/EmploymentHistory'
import AdminSpace from './components/AdminSpace'
import NotificationPreferences from './components/NotificationPreferences'

const AdvisorProfileBuilder = ({ advisorId }) => {
  const isAdmin = useIsAdmin() // note that we don't check `cloaked` so admin can still see advisor's view in stealth mode

  const [isEditing, setIsEditing] = React.useState(false)
  const advisor = useSelector(({ data }) => buildJsonApiOne(data, 'advisors', advisorId))

  const profileBuilderComponents = [
    {
      name: 'personal_info',
      text: isAdmin ? 'Contact information' : 'Manage personal information',
      hash: '#personal-info',
      visible: true,
      component: <PersonalInfo editingCallback={setIsEditing} user={advisor} />
    },
    {
      name: 'employment_history',
      text: isAdmin ? 'Employment history' : 'Manage employment history',
      hash: '#employment',
      visible: true,
      component: <EmploymentHistory advisorId={advisorId} />,
    },
    {
      name: 'notification_preferences',
      text: 'Manage notification preferences',
      hash: '#notifications',
      visible: !isAdmin,
      component: <NotificationPreferences />,
    },
    {
      name: 'payment_info',
      text: isAdmin ? 'Payment information' : 'Edit payment information',
      hash: '#payment-info',
      visible: true,
      component: <PaymentInfo user={advisor} />,
    },
    {
      name: 'change_password',
      text: 'Change password',
      hash: '#change-password',
      visible: !isAdmin,
      component: <ChangePassword />,
    },
    {
      name: 'admin_space',
      text: 'Admin space',
      hash: '#admin-space',
      visible: isAdmin,
      component: <AdminSpace advisorId={advisorId} editingCallback={setIsEditing} />
    }
  ]

  const dispatch = useDispatch()
  const [loaded, setLoaded] = React.useState(false)
  const [selectedComponent, setSelectedComponent] = React.useState()
  const urlHash = useSelector(({ router }) => router.location.hash)

  const loadUser = () => (
    dispatch(JsonAPI.getOne({
      type: 'advisors',
      id: advisorId,
      queryStringParams: {
        include: [
          'companies',
          'companies.organization',
          'companies.roles',
          'companies.roles.tags'
        ].join(',')
      }
    }))
  )

  React.useEffect(() => {
    loadUser().then(() => setLoaded(true))
  }, [])

  React.useEffect(() => {
    if (urlHash && loaded) {
      const selectedProfileBuilderComponent = profileBuilderComponents.find((c) => c.hash === urlHash)
      if (selectedProfileBuilderComponent) {
        setSelectedComponent(selectedProfileBuilderComponent.component)
      }
    }
  }, [urlHash, loaded])

  if (!loaded) { return <Spinner /> }
  if (!advisor) return 'No advisor selected'

  return (
    <Box id="advisor-profile-builder" sx={{ pt: 0.5, display: 'flex' }}>
      { isEditing && <Prompt message="Are you sure you would like to leave this page? You will lose any changes you made." /> }
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {isAdmin && (
            <Link variant="bodyBold" href="/admin/users" sx={{ mb: 4 }}>
              <ArrowLeft sx={{ position: 'relative', top: '7px', mr: 1 }} />
              Back to list
            </Link>
          )}
          <Typography variant="tinyBold" color="neutral.black">{isAdmin ? advisor.fullName : 'Preferences'}</Typography>
          {profileBuilderComponents.filter((section) => section.visible).map((p) => {
            const isSelected = p.hash === urlHash
            return (
              <Link
                key={p.name}
                underline="hover"
                variant={isSelected ? 'bodyBold' : ''}
                onClick={() => navigateTo(p.hash)}
                color="neutral.black"
                sx={{ mt: 2 }}
              >
                {p.text}
              </Link>
            )
          })}
          <Link
            variant="bodyBold"
            href={urlHash}
            onClick={(e) => {
              if (isAdmin) {
                openDialog(
                  <AdvisorProfileDialog
                    advisorId={parseInt(advisorId, 10)}
                  />
                )
              } else {
                e.preventDefault() // prevent appending hash to profile url if we navigate back to profile
                navigateTo('/a/profile')
              }
            }}
            sx={{ mt: 4 }}
          >
            <Eye sx={{ position: 'relative', top: '7px', mr: 1 }} />
            {isAdmin ? 'View profile' : 'Client view of profile'}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 2,
          pl: 3,
          bgcolor: 'neutral.white',
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: 'neutral.lightGrey',
        }}
      >
        {selectedComponent}
      </Box>
    </Box>
  )
}

AdvisorProfileBuilder.propTypes = {
  advisorId: PropTypes.number.isRequired
}

export default AdvisorProfileBuilder
