import PropTypes from 'prop-types'
import React from 'react'

import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Chip
} from '@mui/material'

import {
  Filter
} from 'icons'
import { useDivisionHierarchy } from 'hooks'
import { Spinner } from 'components'

const DivisionPickerPersonalized = ({ disabled, onChange, selectedIds, showArchivedTeams, ...rest }) => {
  const [selectedDivisionIds, setSelectedDivisionIds] = React.useState(selectedIds)
  const { topLevelDivisions, flattenedDivisions, loaded } = useDivisionHierarchy({ includeArchivedTeams: false })

  React.useEffect(() => {
    // get list of division names
    const divisionNames = selectedDivisionIds.map((id) => getDivisionNameById(id))
    // Notify via change callback, but if no divisions are selected, the filter acts like the user has picked all divisions
    selectedDivisionIds.length === 0 ? onChange(flattenedDivisions.map((division) => division.id)) : onChange(selectedDivisionIds, divisionNames)
  }, [selectedDivisionIds])

  const getDivisionById = (divisionId) => {
    const foundDivision = flattenedDivisions.find((division) => division.id === divisionId)
    if (foundDivision) return foundDivision
    return null
  }

  const getDivisionNameById = (divisionId) => {
    const foundDivision = getDivisionById(divisionId)
    if (foundDivision) return foundDivision.displayName
    return ''
  }

  const handleChange = (event, clickedEntry) => {
    const clickedDivision = getDivisionById(clickedEntry.props.value) // which division was clicked in the dropdown menu
    const { value } = event.target
    const asArray = typeof value === 'string' ? value.split(',') : value // handle auto-fill case of string vs. array
    let divisionIds = asArray.map((val) => parseInt(val, 10))
    const isClickedDivisionSelected = divisionIds.includes(clickedDivision.id)

    // user has clicked on a MenuItem that is disabled (since its parent is selected)
    // so we need to re-select it (i.e. prevent unselecting a disabled item)
    if (divisionIds.includes(clickedDivision.parentId)) divisionIds.push(clickedDivision.id)

    // if the MenuItem that was clicked has now been added to the selection
    // also select its children recursively (and also deselect)
    const children = []
    if (clickedDivision.children) children.push(...clickedDivision.children)
    while (children.length) {
      const child = children.pop()
      if (child.children) children.push(...child.children)
      if (isClickedDivisionSelected && !divisionIds.includes(child.id)) divisionIds = [...divisionIds, child.id]
      if (!isClickedDivisionSelected && divisionIds.includes(child.id)) divisionIds = divisionIds.filter((id) => id !== child.id)
    }

    // `sort()` is used below because if a user clicks a disabled checkbox that is checked
    // from a parent checkbox being selected, the same `id`s will be in the array, but
    // sometimes in a different order, which would re-trigger the `onChange` handler unnecessarily
    divisionIds.sort()
    if (JSON.stringify(selectedDivisionIds) !== JSON.stringify(divisionIds)) {
      setSelectedDivisionIds(divisionIds)
    }
  }

  const renderItem = (division, level = 0) => {
    const render = [(
      <MenuItem key={division.id} value={division.id} sx={{ pl: level * 2 + 2 }}>
        <Checkbox
          checked={selectedDivisionIds.includes(division.id)}
          disabled={selectedDivisionIds.includes(division.parentId)}
        />
        <ListItemText primary={division.displayName} />
      </MenuItem>
    )]
    if (division.children) render.push(...division.children.map((childDiv) => renderItem(childDiv, level + 1)))
    return render
  }

  return (
    <Box {...rest}>
      {loaded ? (
        <FormControl fullWidth sx={{ display: 'flex' }}>
          <Select
            multiple
            displayEmpty
            disabled={disabled}
            value={selectedDivisionIds}
            onChange={handleChange}
            fullWidth
            sx={{ maxHeight: '56px' }}
            input={(
              <OutlinedInput
                startAdornment={(
                  <InputAdornment position="start">
                    <Filter />
                  </InputAdornment>
                )}
              />
            )}
            renderValue={(selected) => {
              if (selected.length === 0 || disabled) {
                return (<Typography>Viewing all teams</Typography>)
              }
              return (
                <>
                  {selected.map((id) => (
                    <Chip label={getDivisionNameById(id)} key={id} sx={{ ml: 1 }} />
                  ))}
                </>
              )
              // selected.map(getDivisionNameById).join(', ')
            }}
          >
            {topLevelDivisions.map((division) => renderItem(division, 0))}
          </Select>
        </FormControl>
      ) : (
        <Spinner />
      )}
    </Box>
  )
}

DivisionPickerPersonalized.defaultProps = {
  disabled: false,
  selectedIds: [],
  showArchivedTeams: false
}

DivisionPickerPersonalized.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedIds: PropTypes.array,
  showArchivedTeams: PropTypes.bool
}

export default DivisionPickerPersonalized
