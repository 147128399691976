import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Link,
  Typography
} from '@mui/material'
import {
  closeDialog,
  constants,
  customAlert,
  handleAccountNavigation,
  openDialog
} from 'utils-em'
import {
  ConfirmDialog,
  FilterGroup,
  JsonApiTable,
  ManageAccountsDialog,
  PublishedInsightAvailable
} from 'components'
import { Plus } from 'icons'

const AccountsList = ({ customerUserId }) => {
  const [forceRefresh, setForceRefresh] = React.useState(0)
  const [sortKey, setSortKey] = React.useState('')
  const isLoggedInUser = useSelector(({ session }) => session.id === customerUserId)

  const initialQueryStringParams = {
    include: [
      'account',
      'account.organization',
      'account.organization.accountPages',
      'customerUsers'
    ].join(','),
    'lazy[organizations]': 'engageableAdvisorCount'
  }
  const [queryStringParams, setQueryStringParams] = React.useState(initialQueryStringParams)

  const onStateCallback = ({ sortKey: sort }) => {
    setSortKey(sort)
  }

  const removeAdvisorRequest = async (ar) => {
    const res = await fetch(`${__API_HOST__}/v1/users/${customerUserId}/primary-advisor-requests/${ar.id}`, { method: 'DELETE', credentials: 'include' })
    res.ok ? customAlert('Account successfully removed from list', false) : customAlert('Error removing account from list', true)
    setForceRefresh(forceRefresh + 1)
  }

  const filterOptions = [
    {
      initialQueryFilters: { name: 'status', op: 'eq', val: 'open' }
    },
    {
      initialQueryFilters: {
        name: 'customerUsers',
        op: 'any',
        val: {
          name: 'id',
          op: 'eq',
          val: customerUserId
        }
      }
    }
  ]

  return (
    <>
      <FilterGroup
        title="Account List"
        filterOptions={filterOptions}
        textSearchPlaceholder="Search by account"
        initialQueryStringParams={initialQueryStringParams}
        onQueryStringParamsChange={setQueryStringParams}
        textSearchQueryFields={['account__has.organization__has.name', 'account__has.organization__has.aliases']}
        extraComponents={[
          <Button
            startIcon={<Plus />}
            onClick={() => openDialog(
              <ManageAccountsDialog
                userId={customerUserId}
                sortBy={sortKey}
                onSubmit={() => setForceRefresh(forceRefresh + 1)}
              />
            )}
          >
            Add accounts
          </Button>
        ]}
      />
      <JsonApiTable
        type="advisorRequests"
        queryStringParams={queryStringParams}
        pageSizeOptions={constants.TWO_OH_ROWS_PER_PAGE_OPTIONS}
        dependencies={[forceRefresh]}
        onRowClick={(ar) => {
          const { publishedAccountPage, engageableAdvisorCount } = ar.account.organization
          if (publishedAccountPage || engageableAdvisorCount) {
            handleAccountNavigation(
              ar.account.organization?.id,
              ar.account.organization?.publishedAccountPage?.id,
              ar.account.organization?.engageableAdvisorCount
            )
          } else {
            openDialog(
              <ConfirmDialog
                title="Stay tuned!"
                description="Since you have saved this account, you will be notified when information is available."
                cancelName="Close"
                cancelAction={closeDialog()}
                size="sm"
              />
            )
          }
        }}
        stateCallback={onStateCallback}
        noDataText={isLoggedInUser ? `You can tailor your Emissary experience by keeping your account list up to date.
        You will receive notifications about new advisors and insights based on this list.`
          : `This user has no accounts saved yet. Encourage them to personalize their Emissary experience by keeping their account list up to date.
        They will receive notifications about new advisors and insights based on this list.`}
        columns={[
          {
            name: 'ACCOUNT',
            sortKey: 'account.organization.name',
            defaultSort: true,
            header: 'Account',
            value: (ar) => (
              <Box sx={{ display: 'flex' }}>
                <Typography variant="body" color="neutral.black">
                  {ar.account.organization?.name}
                </Typography>
                {ar.account.organization?.accountPages?.filter((ap) => ap.isPublished)?.length ? (
                  <PublishedInsightAvailable tooltipEnabled />
                ) : null}
              </Box>
            )
          },
          {
            name: 'ACTIONS',
            header: '',
            value: (ar) => (
              <Box sx={{ textAlign: 'right' }}>
                <Link
                  variant="caption"
                  color="error.main"
                  sx={{ ml: 4 }}
                  onClick={(e) => {
                    e.stopPropagation() // prevent triggering row click
                    openDialog(
                      <ConfirmDialog
                        title="Remove account from list?"
                        description={`Are you sure you would like to remove ${ar.account.organization?.name} from ${isLoggedInUser ? 'your' : 'their'} Account List?`}
                        cancelName={`Keep it on ${isLoggedInUser ? 'my' : 'their'} list`}
                        cancelAction={closeDialog()}
                        size="sm"
                        actions={[
                          {
                            name: 'Remove from list',
                            isDelete: true,
                            action: () => removeAdvisorRequest(ar)
                          }
                        ]}
                      />
                    )
                  }}
                >
                  Remove
                </Link>
              </Box>
            )
          }
        ]}
      />
    </>
  )
}

AccountsList.propTypes = {
  customerUserId: PropTypes.string.isRequired
}

export default AccountsList
