import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'

import { closeDialog } from 'utils-em'

const ConfirmDialog = ({
  title,
  description,
  actions,
  size,
  cancelName,
  cancelAction,
  fullWidth,
  extraButtons
}) => (
  <Dialog
    open
    fullWidth={fullWidth}
    maxWidth={size}
    onClose={closeDialog}
    aria-labelledby="form-dialog-title"
  >
    {title ? <DialogTitle>{title}</DialogTitle> : null}
    <DialogContent>
      <Box typography="body1" color="neutral.black">
        {description}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancelAction} color="primary" variant="text">
        {actions ? cancelName : 'Close'}
      </Button>
      {actions && actions.map((action) => (
        <Button
          key={action.name}
          color={action.isDelete ? 'error' : 'primary'}
          variant="contained"
          disabled={action.disabled !== undefined ? action.disabled : false}
          onClick={() => {
            !action?.preventClose && closeDialog()
            action.action()
          }}
          sx={{ ml: 2 }}
        >
          { action.name }
        </Button>
      ))}
      {extraButtons.map((button) => button)}
    </DialogActions>
  </Dialog>
)

ConfirmDialog.defaultProps = {
  actions: null,
  title: null,
  size: 'lg',
  cancelName: 'Cancel',
  cancelAction: () => closeDialog(),
  fullWidth: false,
  extraButtons: []
}

ConfirmDialog.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    isDelete: PropTypes.bool,
    disabled: PropTypes.bool,
    preventClose: PropTypes.bool,
    action: PropTypes.func
  })),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  size: PropTypes.string,
  cancelName: PropTypes.string,
  cancelAction: PropTypes.func,
  fullWidth: PropTypes.bool,
  extraButtons: PropTypes.array
}

export default ConfirmDialog
