import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Link,
  Typography
} from '@mui/material'
import {
  useEngagement
} from 'hooks'
import {
  Download,
  Headphones,
  Note
} from 'icons'
import {
  customAlert,
  downloadAssetFromGcs,
  downloadFile
} from 'utils-em'

const downloadAllAssets = (engagementId) => {
  fetch(
    `${__API_HOST__}/v1/export/${engagementId}/assets`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then((resp) => {
    if (resp.ok) {
      resp.blob().then((blob) => downloadFile(blob, `engagement_${engagementId}.zip`))
    } else {
      customAlert('Something went wrong with export, please contact support.', true)
    }
  })
}

const getAssetIcon = (assetType) => {
  if (assetType === 'engagement-recording') {
    return (<Headphones />)
  }
  return (<Note />)
}

const AssetCard = ({ asset, labelDescription }) => {
  const [isDownloading, setIsDownloading] = React.useState(false)
  const { id, assetType, documentGcsObjectName } = asset
  const url = `${__API_HOST__}/v1/assets/${id}/download`
  const filename = documentGcsObjectName ? documentGcsObjectName.replace(/^.*[\\/]/, '') : 'File not found, contact support.'

  const download = async (e) => {
    if (!isDownloading) {
      setIsDownloading(true)
      await downloadAssetFromGcs(id, documentGcsObjectName)
      setIsDownloading(false)
    }
  }

  return (
    <Card sx={{ p: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', alignItems: 'start', width: '67%' }}>
          {getAssetIcon(assetType)}
          <Box sx={{ display: 'block', pl: 1 }}>
            <Typography variant="bodyBold" color="neutral.black" sx={{ display: 'block' }}>{labelDescription}</Typography>
            <Typography variant="caption" color="neutral.darkGrey">{filename}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '33%', justifyContent: 'right' }}>
          <IconButton onClick={(e) => download(e)} color="primary">
            {isDownloading ? <CircularProgress size={20} /> : <Download />}
          </IconButton>
        </Box>
      </Box>
      {assetType === 'engagement-recording'
        ? (
          <Box sx={{ display: 'block', width: '90%', ml: 'auto', mr: 'auto', mt: 2 }}>
            <audio controls="controls" preload="auto" style={{ width: '100%' }}>
              <source src={url} type="audio/mpeg" />
              <track kind="captions" />
            </audio>
          </Box>
          ) : null}
    </Card>
  )
}

AssetCard.propTypes = {
  asset: PropTypes.object.isRequired,
  labelDescription: PropTypes.string.isRequired
}

const CallDocumentsSection = ({ engagementId }) => {
  const { assets } = useEngagement(engagementId)

  const recordingAsset = assets && assets.find((asset) => asset.assetType === 'engagement-recording')
  const notesAsset = assets && assets.find((asset) => asset.assetType === 'engagement-notes')
  const transcriptAsset = assets && assets.find((asset) => asset.assetType === 'engagement-transcript')

  return (
    <>
      <Typography variant="h3">Call documents:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {transcriptAsset && <AssetCard asset={transcriptAsset} labelDescription="Transcript" />}
        {notesAsset && <AssetCard asset={notesAsset} labelDescription="Notes" />}
        {recordingAsset && <AssetCard asset={recordingAsset} labelDescription="Recording" />}
        {assets?.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <Link
              onClick={() => downloadAllAssets(engagementId)}
              href="#"
              color="primary"
              variant="bodyBold"
              underline="hover"
            >
              Download all
            </Link>
          </Box>
        )}
      </Box>
    </>
  )
}

CallDocumentsSection.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default CallDocumentsSection
