import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'
import { useJsonAPIGetAll } from 'hooks'
import { Controller } from 'react-hook-form'

const FormAutocomplete = ({ form, name, label, type, required, queryStringParams }) => {
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const { objects: content, loaded: contentLoaded } = useJsonAPIGetAll(type, {
    queryStringParams
  })
  const { formState: { errors }, control } = form

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <Autocomplete
          id={`${label}-multiselectform`}
          disabled={!contentLoaded}
          open={autocompleteOpen}
          onOpen={() => setAutocompleteOpen(true)}
          onClose={() => setAutocompleteOpen(false)}
          getOptionLabel={(option) => option.name || ''}
          isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}
          options={content}
          value={field.value}
          onChange={(_, value) => field.onChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={contentLoaded ? label : 'Loading...'}
              placeholder={label}
              margin="normal"
              fullWidth
              error={Boolean(errors[name])}
              helperText={errors[name] ? errors[name].message : ''}
            />
          )}
        />
      )}
    />
  )
}
FormAutocomplete.defaultProps = {
  label: '',
  typography: 'body1',
  initialSelections: [],
  required: false,
  queryStringParams: {}
}

FormAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  form: PropTypes.object.isRequired, // This is the React Hook Form form object
  typography: PropTypes.string,
  type: PropTypes.string.isRequired,
  initialSelections: PropTypes.array,
  required: PropTypes.bool,
  queryStringParams: PropTypes.object
}

export default FormAutocomplete
