import 'babel-polyfill'

import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { sentryOptions } from 'sentry_ext'

import RootContainer from './containers/RootContainer'

const SENTRY_DSN = __SENTRY_DSN__
if (SENTRY_DSN) {
  Sentry.init(sentryOptions)
}

const container = document.getElementById('root')
const main = () => {
  const root = createRoot(container)
  root.render(<RootContainer />)
}

main()
