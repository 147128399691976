import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Drawer,
  Divider
} from '@mui/material'

import {
  BookmarkAccountButton
} from 'components'
import {
  useEmissaryTheme
} from 'hooks'

import CopyToClipboard from '../CopyToClipboard'
import LastUpdatedTag from '../LastUpdatedTag'
import Logo from '../Logo'
import TalkToSomeone from '../TalkToSomeone'

import { AccountPageContext } from '../AccountPageContext'

const StickyBar = ({ isOpen }) => {
  const {
    accountPage,
    accountSaved,
    savedListLoaded,
    checkIfAccountSaved
  } = React.useContext(AccountPageContext)
  const organization = accountPage?.organization

  const {
    isSmOrSmaller,
    pagePaddingX
  } = useEmissaryTheme()

  return (
    <Drawer
      anchor="top"
      open={isOpen}
      variant="persistent"
      PaperProps={{
        sx: {
          borderBottom: 'none !important'
        }
      }}
    >
      <Box
        sx={{
          height: '68px', // 64px bar with 4px border-bottom
          borderBottom: (theme) => `4px SOLID ${theme.palette.brand.paleSlate}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* Left side */}
        <Box sx={{ ml: pagePaddingX, display: 'flex', gap: isSmOrSmaller ? 1 : 2, alignItems: 'center' }}>
          <Logo isSmall />
          {!isSmOrSmaller && (
            <>
              <Divider orientation="vertical" flexItem sx={{ borderRightWidth: '4px', height: '40px' }} />
              <LastUpdatedTag isAbbreviated />
            </>
          )}
        </Box>
        {/* Right side */}
        <Box sx={{ mr: pagePaddingX, display: 'flex', gap: isSmOrSmaller ? 1 : 2, alignItems: 'center' }}>
          <BookmarkAccountButton
            orgId={organization.id}
            accountSaved={accountSaved}
            savedListLoaded={savedListLoaded}
            checkIfAccountSaved={checkIfAccountSaved}
            iconOnly
          />
          <CopyToClipboard isAbbreviated />
          <TalkToSomeone isResponsive organizationId={organization?.id} />
        </Box>
      </Box>
    </Drawer>
  )
}

StickyBar.defaultProps = {
  isOpen: false
}

StickyBar.propTypes = {
  isOpen: PropTypes.bool
}

export default StickyBar
