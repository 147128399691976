import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Typography,
  Paper
} from '@mui/material'

import AccountCircle from '@mui/icons-material/AccountCircle'
import Email from '@mui/icons-material/Email'
import Search from '@mui/icons-material/Search'
import TransferWithinAStation from '@mui/icons-material/TransferWithinAStation'

import { StickyTextField, StickySelectField } from 'components'

import { buildJsonApiFilter, useDebounce } from 'utils-em'
import CustomerUserFilters from './filters/CustomerUserFilters'
import AdminFilters from './filters/AdminFilters'
import AdvisorFilters from './filters/AdvisorFilters'

const UserSearchForm = ({ onFilterUpdate, onCustomerTypeSelected, initialUserType }) => {
  const [userType, setUserType] = useState(initialUserType)
  const [filters, setFilters] = useState({
    fullName: { value: '' },
    email: { value: '' },
    'divisions__any.name': { value: '' },
    jobTitle: { value: '' },
    roleType: { value: '' },
    onboardingCompleted__nullcheck: { value: '' },
    'customer.name': { value: '' },
    'recruiter.fullName': { value: '' },
    status: { value: '' }
  })

  const handleSearchFilter = (filterName, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [filterName]: {
        value,
        filter: value ? buildJsonApiFilter(filterName, value) : null
      }
    }))
  }

  const handleStatusFilter = (statuses) => {
    const statusFilter = { or: statuses.map((status) => ({ name: 'status', op: 'eq', val: status })) }
    setFilters((prevState) => ({
      ...prevState,
      status: { filter: statusFilter }
    }))
  }

  const getFilters = () => {
    switch (userType) {
      case 'admin':
        return (
          <AdminFilters
            filters={filters}
            handleSearchFilter={handleSearchFilter}
          />
        )
      case 'advisor':
        return (
          <AdvisorFilters
            filters={filters}
            handleSearchFilter={handleSearchFilter}
          />
        )
      default:
        return (
          <CustomerUserFilters
            filters={filters}
            handleSearchFilter={handleSearchFilter}
            handleStatusFilter={handleStatusFilter}
          />
        )
    }
  }

  const debouncedFilters = useDebounce(filters, 500)
  useEffect(() => {
    onFilterUpdate(Object.keys(filters).map((f) => filters[f].filter).filter((val) => !!val))
  }, [debouncedFilters])

  const onSearch = (e) => {
    e.preventDefault()
    onFilterUpdate(Object.keys(filters).map((f) => filters[f].filter).filter((val) => !!val))
  }

  const handleUserTypeSelected = (type) => {
    onCustomerTypeSelected(type)
    setUserType(type)
  }

  return (
    <form onSubmit={onSearch}>
      <Paper sx={{ p: '0rem 1rem 2rem' }}>
        <Typography sx={{ py: 1 }} variant="h6" component="div">
          Filters
        </Typography>
        <FormControl sx={{ p: 1 }} fullWidth variant="outlined" size="small">
          <InputLabel id="customer-type--select-label">Type</InputLabel>
          <StickySelectField
            stickyKey="user.type"
            labelId="type-select-label"
            id="type-select"
            label="Type"
            variant="outlined"
            value={userType}
            startAdornment={(
              <InputAdornment position="start">
                <TransferWithinAStation />
              </InputAdornment>
            )}
            onChange={(event) => handleUserTypeSelected(event.target.value)}
          >
            <MenuItem value="customerUser">Customer</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="advisor">Advisor</MenuItem>
          </StickySelectField>
        </FormControl>
        <StickyTextField
          stickyKey="user.name"
          fullWidth
          size="small"
          magin="dense"
          label="Name"
          variant="outlined"
          value={filters.fullName.value}
          onChange={(event) => handleSearchFilter('fullName', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            )
          }}
        />
        <StickyTextField
          stickyKey="user.email"
          fullWidth
          size="small"
          margin="dense"
          label="Email"
          variant="outlined"
          value={filters.email.value}
          onChange={(event) => handleSearchFilter('email', event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            )
          }}
        />
        {getFilters()}
        <Box sx={{ pt: 1 }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            endIcon={<Search />}
            type="submit"
            onClick={onSearch}
          >
            Search
          </Button>
        </Box>
      </Paper>
    </form>
  )
}

UserSearchForm.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  onCustomerTypeSelected: PropTypes.func.isRequired,
  initialUserType: PropTypes.string.isRequired
}

export default UserSearchForm
