import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router'
import { useRecordPageView } from 'hooks'

import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Reset from './Reset'
import Admin from './Admin'
import Advisor from './Advisor'
import AdvisorOnboarding from './AdvisorOnboarding'
import Customer from './Customer'
import InvalidRequest from './InvalidRequest'
import NotFound from '../components/NotFound'

/* eslint-disable react/prop-types */
const AppRoutes = ({ store }) => {
  useRecordPageView()
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/forgot" component={ForgotPassword} />
      <Route path="/reset" component={Reset} />
      <Route path="/admin" render={(props) => <Admin store={store} location={props.location} />} />
      <Route path="/a" component={Advisor} />
      <Route path="/c" component={Customer} />
      <Route path="/signup" component={AdvisorOnboarding} />
      <Route path="/invalid_request" component={InvalidRequest} />
      <Route component={NotFound} />
    </Switch>
  )
}
/* eslint-enable */

AppRoutes.propTypes = { store: PropTypes.object.isRequired }

export default AppRoutes
