import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import {
  closeDialog,
} from 'utils-em'
import ChatbotPage from '../../../../Customer/routes/ChatbotPage/ChatbotPage'

const ChatbotDialog = ({ prompt }) => (
  <Dialog
    open
    onClose={closeDialog}
    maxWidth="lg"
  >
    <DialogTitle>Chatbot Test</DialogTitle>
    <DialogContent sx={{ minWidth: '600px', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ChatbotPage promptId={prompt.id} />
    </DialogContent>
    <DialogActions>
      <Button variant="text" onClick={closeDialog}>Cancel</Button>
      <Button variant="text" type="submit">Save</Button>
    </DialogActions>
  </Dialog>
)

ChatbotDialog.defaultProps = {
  prompt: null,
}

ChatbotDialog.propTypes = {
  prompt: PropTypes.object,
}

export default ChatbotDialog
