export const ACTIONS = {
  TOGGLE_SIDE_NAV: 'TOGGLE_SIDE_NAV',
  TOGGLE_NOTIFICATION_DRAWER: 'TOGGLE_NOTIFICATION_DRAWER',
  SET_INSIGHTS_SEARCH_FILTERS: 'SET_INSIGHTS_SEARCH_FILTERS',
  SET_SEARCHED_ORG_RESULTS: 'SET_SEARCHED_ORG_RESULTS'
}

export function toggleSideNav () {
  return {
    type: ACTIONS.TOGGLE_SIDE_NAV
  }
}

export function toggleNotificationsDrawer (open = null) {
  return {
    type: ACTIONS.TOGGLE_NOTIFICATION_DRAWER,
    open // allows for force closing/opening
  }
}

export function setInsightsSearchFilters (insightsSearchFilterUpdatedObject) {
  return {
    type: ACTIONS.SET_INSIGHTS_SEARCH_FILTERS,
    insightsSearchFilterUpdatedObject
  }
}

export function setSearchedOrgResults (results) {
  return {
    type: ACTIONS.SET_SEARCHED_ORG_RESULTS,
    searchedOrgResults: results
  }
}
