import PropTypes from 'prop-types'

const CustomerAccessControl = ({ session, types, children }) => {
  if (types) {
    if (types.includes(session.roleType)) {
      return children
    }
    return null
  }
  return children
}

CustomerAccessControl.defaultProps = {
  children: null,
  types: null
}

CustomerAccessControl.propTypes = {
  session: PropTypes.object.isRequired,
  children: PropTypes.node,
  types: PropTypes.array
}

export default CustomerAccessControl
