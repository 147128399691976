import React from 'react'
import { Box, Typography } from '@mui/material'
import { AnnotationDots, Announcement, Information } from 'icons'
import ArrowForward from '@mui/icons-material/ArrowForward'
import AvatarWithTextCombo from '../AvatarWithTextCombo'
import { AccountPageContext } from '../AccountPageContext'

const WeJustHeard = () => {
  const { accountPage } = React.useContext(AccountPageContext)
  const { weJustHeard } = accountPage.data
  const advisorQuotes = weJustHeard.filter((item) => item.type === 'advisorQuote')
  const summarizedTexts = weJustHeard.filter((item) => item.type === 'summarizedText')
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'brand.lightNavy', borderRadius: 2, mb: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 1, mt: 2 }}>
        <AvatarWithTextCombo
          sx={{ ml: 2 }}
          icon={<Announcement sx={{ color: 'brand.darkTeal' }} />}
          iconColor="brand.darkTeal"
          variant="tiny"
          backgroundColor="brand.paleTeal"
          textColor="neutral.black"
          text="Did you know?"
        />
      </Box>
      <Typography variant="h2" color="neutral.black" sx={{ mx: 3, mt: 1, mb: 1 }}>We Just  Heard</Typography>
      {summarizedTexts.map((item) => (
        <Box sx={{ display: 'flex', alignItems: 'top', mb: 1 }}>
          <ArrowForward sx={{ color: 'neutral.darkGrey', mt: 2, ml: 3 }} />
          <Typography variant="body" sx={{ ml: 1, mt: 2, mr: 2 }}>{item.value}</Typography>
        </Box>
      ))}
      {advisorQuotes.map((item) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AnnotationDots sx={{ color: 'neutral.darkGrey', mt: 2, ml: 3 }} />
          <Typography variant="bodyBold" sx={{ ml: 1, mt: 2, mr: 2 }}>{`"${item.value}"`}</Typography>
        </Box>
      ))}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, ml: 3.5, mb: 2 }}>
        <Information sx={{ fontSize: '16px', color: 'neutral.darkGrey' }} />
        <Typography variant="tiny" sx={{ ml: 1 }}>{`This information was gathered from the most recent insights we have about ${accountPage.organization.name}${accountPage.organization.name.slice(-1) === '.' ? null : '.'}`}</Typography>
      </Box>
    </Box>
  )
}

export default WeJustHeard
