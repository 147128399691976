import { connect } from 'react-redux'

import { buildJsonApiOne, buildJsonApiAll } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'
import EditAllotmentDialog from './EditAllotmentDialog'

const mapStateToProps = ({ data }, ownProps) => ({
  contract: buildJsonApiOne(data, 'contracts', ownProps.contractId),
  contractSkuAllotment: buildJsonApiOne(data, 'contractSkuAllotments', ownProps.contractSkuAllotmentId) || {},
  skus: buildJsonApiAll(data, 'skus'),
  advisorRequests: buildJsonApiAll(data, 'advisorRequests')
})

const mapDispatchToProps = {
  saveAllotment: (allotment) => {
    const method = allotment.id ? JsonAPI.save : JsonAPI.create
    return method({
      ...allotment,
      type: 'contractSkuAllotments',
      queryStringParams: {
        include: [
          'contract.contractSkuAllotments'
        ].join(',')
      }
    })
  },
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAllotmentDialog)
