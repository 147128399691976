import React from 'react'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { simpleHttpFetch } from 'utils-em'

const TipaltiInfo = () => {
  const [loaded, setLoaded] = React.useState(false)
  const [tipaltiHash, setTipaltiHash] = React.useState()

  const isAdmin = useSelector(({ session }) => session.cloaked || session.userType === 'admin')

  const loadTipaltiHash = async () => {
    const json = await simpleHttpFetch(
      `${__API_HOST__}/v1/tipalti/hash`
    )
    setTipaltiHash(json)
    setLoaded(true)
  }

  React.useEffect(() => { loadTipaltiHash() }, [])

  if (!loaded) return null

  // NOTE, QUERY STRING ORDER MATTERS DO NOT CHANGE
  const params = new URLSearchParams({
    payer: tipaltiHash.msg.payer,
    idap: tipaltiHash.msg.idap,
    ts: tipaltiHash.msg.ts,
    hashkey: tipaltiHash.hash
  })
  const srcUrl = `${__TIPALTI_URL__}?${params.toString()}`

  return isAdmin ? (
    <Typography variant="captionBold" color="error.main" sx={{ display: 'block', mt: 3 }}>
      The Tipalti iFrame is unavailable to view as an admin.
    </Typography>
  ) : (
    // eslint-disable-next-line react/self-closing-comp
    <iframe
      height="1500"
      width="700"
      title="tipalti"
      src={srcUrl}
    >
    </iframe>
  )
}

export default TipaltiInfo
