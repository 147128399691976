import React from 'react'
import PropTypes from 'prop-types'

import Autocomplete from '@mui/material/Autocomplete'
import { TextValidator } from 'react-material-ui-form-validator'

const ContractSelector = ({ multiple, contracts, customerId, selectedContracts, contractId, loadContracts, setContract }) => {
  React.useEffect(() => {
    loadContracts(
      customerId
    )
  }, [])

  if (!contracts) { return null }
  const selectedContract = contracts.find((c) => c.id === contractId)

  return (
    <Autocomplete
      onChange={(event, contract) => { setContract(contract) }}
      getOptionLabel={(option) => option.name}
      options={contracts}
      value={multiple ? selectedContracts : selectedContract || null}
      multiple={multiple}
      isOptionEqualToValue={(option, value) => (option.id === value.id)}
      renderInput={(params) => (
        <TextValidator
          {...params}
          validators={multiple ? [] : ['required']}
          errorMessages={multiple ? [] : ['Required Field']}
          variant="standard"
          label={multiple ? 'Contracts' : 'Contract'}
          placeholder="Contract"
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

ContractSelector.defaultProps = {
  contractId: null,
  multiple: false,
  selectedContracts: []
}

ContractSelector.propTypes = {
  multiple: PropTypes.bool,
  contracts: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired,
  selectedContracts: PropTypes.array,
  contractId: PropTypes.string,
  loadContracts: PropTypes.func.isRequired,
  setContract: PropTypes.func.isRequired
}

export default ContractSelector
