import { connect } from 'react-redux'

import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'

import ContractsLayout from '../layouts/ContractsLayout'

const mapStateToProps = ({ data }, ownProps) => ({
  contracts: buildJsonApiOrdered(data, 'contracts'),
  customerId: parseInt(ownProps.match.params.id, 10)
})

const mapDispatchToProps = {
  getAllSkus: () => JsonAPI.getAll({
    type: 'skus'
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractsLayout)
