import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Typography,
} from '@mui/material'
import { RelayMessageDialog } from 'components'
import {
  buildJsonApiOne,
  getEmailRelayLink,
  openDialog
} from 'utils-em'

const ConnectByEmailSection = ({ engagementId, followUpText }) => {
  const isAdvisor = useSelector(({ session }) => session.userType === 'advisor')
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const relayLink = getEmailRelayLink(engagement)

  let titleText = 'Connect via email'
  if (followUpText) { titleText = 'Follow up after your call' }

  return (
    <Box sx={{ mt: 4, p: 3, bgcolor: 'brand.lightNavy', borderRadius: 1 }}>
      <Typography variant="h4" color="neutral.black">
        {titleText}
      </Typography>
      <Typography variant="body1" sx={{ mt: 3 }}>
        {`You and your ${isAdvisor ? 'client' : 'advisor'} can exchange emails using our private message relay directly to your inbox.`}
      </Typography>
      <Button
        sx={{ mt: 3 }}
        onClick={() => {
          openDialog(<RelayMessageDialog relayLink={relayLink} />)
        }}
        variant="outlined"
        color="primary"
      >
        Send message
      </Button>
    </Box>
  )
}

ConnectByEmailSection.defaultProps = {
  followUpText: false
}

ConnectByEmailSection.propTypes = {
  engagementId: PropTypes.string.isRequired,
  followUpText: PropTypes.bool
}

export default ConnectByEmailSection
