import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  useJsonAPIGetOne,
  useJsonAPIGetAll,
  useAccountSaved,
} from 'hooks'
import {
  navigateTo
} from 'utils-em'

export const AccountPageContext = React.createContext({})

export const AccountPageContextProvider = ({
  accountPageId,
  children
}) => {
  const urlHash = useSelector(({ router }) => router.location.hash)
  const { object: accountPage, loaded } = useJsonAPIGetOne(
    accountPageId,
    'accountPages',
    ['organization', 'organization.tags'],
    {
      'lazy[organizations]': 'engageableAdvisorCount',
      'lazy[accountPages]': ['contributingAdvisorSummary', 'surveyResults', 'mostRecentUpdateDate', 'logo'].join(',')
    }
  )

  const { objects: responses, loaded: responsesLoaded, } = useJsonAPIGetAll('questionResponses', {
    skip: !loaded,
    queryStringParams: {
      include: 'question,question.topics',
      filter: [
        { name: 'organizationId', op: 'eq', val: accountPage?.organizationId },
        { name: 'isDirectToPage', op: 'eq', val: 1 },
      ]
    },
  })
  const { accountSaved, savedListLoaded, checkIfAccountSaved } = useAccountSaved(accountPage?.organizationId || '')

  const [activeSectionKey, setActiveSectionKey] = React.useState(urlHash?.slice(1) || 'landingPage')
  const scrollableElement = React.useRef()

  React.useEffect(() => {
    if (urlHash.slice(1) !== activeSectionKey && activeSectionKey !== 'landingPage') {
      navigateTo(`#${activeSectionKey}`, { from: urlHash.slice(1) })
    }
  }, [activeSectionKey])

  return (
    <AccountPageContext.Provider value={{
      activeSectionKey,
      setActiveSectionKey,
      accountPage,
      scrollableElement,
      loaded,
      responses,
      responsesLoaded: responsesLoaded && loaded,
      accountSaved,
      savedListLoaded,
      checkIfAccountSaved
    }}
    >
      {children}
    </AccountPageContext.Provider>
  )
}

AccountPageContextProvider.propTypes = {
  accountPageId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
}
