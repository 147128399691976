import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Box, TextField } from '@mui/material'
import { customAlert, validateEmail } from 'utils-em'

const EmailChipInput = ({ initialEmails, emailCallback, instructions }) => {
  const [selectedEmails, setSelectedEmails] = React.useState(initialEmails)

  React.useEffect(() => { emailCallback(selectedEmails) }, [selectedEmails])

  const handleKeyDown = (event) => {
    if (['Enter', ',', ' '].includes(event.key)) {
      event.preventDefault()
      const newEmail = event.target.value.trim()
      const updatedEmails = [...selectedEmails]
      const duplicatedEmails = updatedEmails.indexOf(newEmail)
      const validated = validateEmail(newEmail)

      if (!validated.valid) {
        customAlert(validated.message, true)
        return
      }

      if (duplicatedEmails !== -1) {
        customAlert('Email already added.', true)
        return
      }
      if (!event.target.value.replace(/\s/g, '').length) return

      updatedEmails.push(newEmail)
      setSelectedEmails(updatedEmails)
    }
  }

  const handlePaste = (event) => {
    // allows pasting of comma, space, or newline separated emails
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')
    const emails = pastedData.split(/[\s,;\t\n]+/).filter((email) => email.trim() !== '')
    const uniqueEmailsSet = new Set([...selectedEmails, ...emails])
    const uniqueEmailsArray = [...uniqueEmailsSet].filter((email) => validateEmail(email.trim()).valid)
    setSelectedEmails(uniqueEmailsArray)
  }

  return (
    <>
      <Autocomplete
        multiple
        open={false}
        options={[]}
        popupIcon=""
        value={selectedEmails}
        onChange={(e, newval) => setSelectedEmails(newval)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Email addresses"
            placeholder="Add emails..."
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            sx={{ '& .MuiAutocomplete-inputRoot': { flexWrap: 'wrap' } }} // Added flexWrap property
          />
        )}
      />
      {instructions && (
      <Box typography="caption" color="neutral.disabled" sx={{ mt: 1 }}>
          {instructions}
      </Box>
      )}
    </>
  )
}

EmailChipInput.defaultProps = {
  initialEmails: [],
  instructions: 'Separate multiple email addresses with a comma, a space, semicolon, tab, or by pressing enter.'
}
EmailChipInput.propTypes = {
  initialEmails: PropTypes.arrayOf(PropTypes.string),
  emailCallback: PropTypes.func.isRequired,
  instructions: PropTypes.string
}

export default EmailChipInput
