import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBox from '@mui/icons-material/CheckBox'

import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'

const TagDropdown = ({ label, labelIcon, tagCategory, multiple, error, multipleLimit, multipleLimitError, onChange, required, initialTags, displayAsHierarchy, helperText, sxForSelect, preselectFirstItem }) => {
  const [selectedTags, setSelectedTags] = React.useState(initialTags.map((tag) => tag.name))
  const [selectedCount, setSelectedCount] = React.useState(initialTags.length)
  const flatTags = useSelector(({ data }) => buildJsonApiOrdered(data, 'tags', tagCategory))
  const tags = useSelector(({ data }) => {
    const unsortedTags = buildJsonApiOrdered(data, 'tags', tagCategory)
    if (displayAsHierarchy) {
      unsortedTags.forEach((tag) => {
        if (tag.parentId) {
          const parentTag = unsortedTags.find((findTag) => parseInt(findTag.id, 10) === parseInt(tag.parentId, 10))
          if (parentTag) {
            if (!parentTag.children) parentTag.children = []
            parentTag.children.push(tag)
          }
        }
      })
      return unsortedTags.filter((tag) => tag.parentId === null)
    }
    return unsortedTags
  })

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (preselectFirstItem && tags && tags.length > 0 && !selectedTags.length) {
      setSelectedTags([tags[0].name])
      onChange([tags[0]])
    }
  }, [tags])

  React.useEffect(() => {
    dispatch(JsonAPI.getAll({
      orderKey: tagCategory,
      type: 'tags',
      queryStringParams: {
        filter: [
          { name: 'category', op: 'eq', val: tagCategory }
        ],
        sort: 'name'
      }
    }))
  }, [])

  const getTagByName = (name) => flatTags.find((tag) => tag.name === name)

  const handleChange = (event) => {
    const { value } = event.target
    const selectedValues = typeof value === 'string' ? value.split(',') : value
    setSelectedTags(selectedValues)
    setSelectedCount(selectedValues.length)
    onChange(multiple ? selectedValues.map(getTagByName) : [getTagByName(value)])
  }

  if (!tags) return null

  const renderTag = (tag, level = 0) => {
    const tagsToRender = [(
      <MenuItem
        onKeyDown={(e) => {
          if (e?.key?.toLowerCase() === 'enter') {
            e.stopPropagation()
          }
        }}
        key={tag.id}
        value={tag.name}
        sx={{ pl: 2 * level + 2 }}
      >
        {multiple && (
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selectedTags.includes(tag.name)}
          />
        )}
        {tag.name}
      </MenuItem>
    )]
    if (tag.children) {
      tagsToRender.push(...tag.children.map((childTag) => renderTag(childTag, level + 1)))
    }
    return tagsToRender
  }

  const hasExceededLimit = multiple && selectedCount > multipleLimit
  const getLimitExceededMessage = () => (multipleLimitError.length > 0 ? multipleLimitError : `Please select up to ${multipleLimit} tags`)

  return (
    <FormControl fullWidth required={required} error={multiple && hasExceededLimit}>
      <InputLabel id={`tag-dropdown-${label}`}>{label}</InputLabel>
      <Select
        labelId={`tag-dropdown-${label}`}
        label={label}
        multiple={multiple}
        required={required}
        fullWidth
        error={error}
        value={selectedTags}
        onChange={handleChange}
        sx={sxForSelect}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {multiple ? (
              selected.map((value) => (
                <Chip key={value} label={value} />
              ))
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {labelIcon}
                {selected}
              </Box>
            )}
          </Box>
        )}
      >
        {tags.map((tag) => renderTag(tag))}
      </Select>
      {multiple && multipleLimit !== 0 && hasExceededLimit && (
        <FormHelperText>{getLimitExceededMessage()}</FormHelperText>
      )}
      {!hasExceededLimit && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

TagDropdown.defaultProps = {
  multiple: false,
  multipleLimit: 0,
  multipleLimitError: '',
  required: false,
  displayAsHierarchy: false,
  initialTags: [],
  helperText: '',
  sxForSelect: {},
  labelIcon: null,
  preselectFirstItem: false,
  error: false
}

TagDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  labelIcon: PropTypes.element,
  tagCategory: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  multipleLimit: PropTypes.number,
  multipleLimitError: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  displayAsHierarchy: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialTags: PropTypes.array,
  sxForSelect: PropTypes.object,
  preselectFirstItem: PropTypes.bool,
  error: PropTypes.bool
}

export default TagDropdown
