import React from 'react'
import PropTypes from 'prop-types'

import { Box, Tooltip } from '@mui/material'
import Cancel from '@mui/icons-material/Cancel'
import CheckCircle from '@mui/icons-material/CheckCircle'
import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled'

import { USER_STATUS_DATA } from 'utils-em'

const StatusCircle = ({ status, noTooltip, ...rest }) => {
  const icons = {
    red: <Cancel color="error" fontSize="small" />,
    yellow: <PauseCircleFilled color="warning" fontSize="small" />,
    green: <CheckCircle color="success" fontSize="small" />,
    grey: <PauseCircleFilled color="disabled" fontSize="small" />
  }

  if (!Object.keys(USER_STATUS_DATA).includes(status)) return null

  const { color, text } = USER_STATUS_DATA[status]
  const title = text || status
  const icon = icons[color]

  return (
    <Box {...rest}>
      <Tooltip title={!noTooltip ? title : ''}>
        {icon}
      </Tooltip>
    </Box>
  )
}

StatusCircle.defaultProps = {
  noTooltip: false
}
StatusCircle.propTypes = {
  status: PropTypes.string.isRequired,
  noTooltip: PropTypes.bool
}

export default StatusCircle
