import React from 'react'

import {
  Avatar,
  Box,
  Typography
} from '@mui/material'
import {
  ArrowRight,
  Chrome
} from 'icons'

const ChromeExtensionBanner = () => (
  <Box
    className="emissary-get-chrome-extension"
    onClick={() => window.open('https://chromewebstore.google.com/detail/emissary/lhlifkcapmgnlmnfanbloknkonkfbadf', '_blank')}
    sx={{
      cursor: 'pointer',
      bgcolor: 'brand.paleTeal',
      mt: 5,
      mb: 0,
      p: 3,
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      '&:hover': { bgcolor: 'brand.softTeal' }
    }}
  >
    <Avatar sx={{ width: '40px', height: '40px', bgcolor: 'neutral.white', mr: 2 }}>
      <Chrome sx={{ color: 'brand.darkTeal' }} />
    </Avatar>
    <Typography variant="h5">
      Download our new
      {' '}
      <Typography variant="h4" sx={{ display: 'inline' }}>Chrome Extension</Typography>
      {' '}
      to embed Emissary into your workflow
    </Typography>
    <Box sx={{ flexGrow: 1 }}>{/* Spacer */}</Box>
    <ArrowRight sx={{ position: 'relative', top: '1px', color: 'primary.main' }} />
  </Box>
)

export default ChromeExtensionBanner
