import PropTypes from 'prop-types'
import React from 'react'
import {
  Button
} from '@mui/material'
import {
  Share
} from 'icons'

const EngagementPrepGuideLink = ({ includeIcon }) => (
  <Button
    variant="text"
    sx={{ ml: 0 }}
    target="_blank"
    href="https://insights.emissary.io/cs/c/?cta_guid=fb640bae-0b45-4cbe-9be1-2279cfc83242&signature=AAH58kFRJoJaqxyBgBp_9jvof50wYiIneQ&pageId=71654397697&placement_guid=6dafed03-f8b1-460c-a3d5-1dce7e87716e&click=fed818ff-60a1-4f09-8e97-0c9666099622&hsutk=4866192844ddedcebb154f6b0f5a6429&canon=https%3A%2F%2Finsights.emissary.io%2Fclient-engagement-preparation&portal_id=20488060&redirect_url=APefjpHqlQc7w0H8-ohCWNM-5XxZsblbnCMPIpIChdWzz0qdABBgvjvvsIHTa7K_GLW-zeWJkzhPI6mR_RVrDGyG2yVoaySz8VjBYcpW0_cTGOctarRKKObKiZrrDou82qoeEL4ZqTVvQinfAmbqDBU0LfBFiFTuARKrRIKu-nAlzfXn0FFv1jfdMH0gpStbh5a4xYoltZmwi7FS7G3P5G85Gif7k3I8v_uF7WCSZCpKAq6an8Wf1PQ&__hstc=189212532.4866192844ddedcebb154f6b0f5a6429.1656611220257.1656611220257.1656611220257.1&__hssc=189212532.1.1656611220257&__hsfp=1542169609&contentType=landing-page"
  >
    Engagement prep guide
    { includeIcon ? <Share sx={{ verticalAlign: 'top', pl: 1 }} /> : null }
  </Button>
)

EngagementPrepGuideLink.defaultProps = {
  includeIcon: false
}

EngagementPrepGuideLink.propTypes = {
  includeIcon: PropTypes.bool
}

export default EngagementPrepGuideLink
