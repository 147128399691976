import PropTypes from 'prop-types'
import React from 'react'

import { constants } from 'utils-em'

import APILoaderContainer from '../../../../../containers/APILoaderContainer'
import SkuList from '../components/SkuList'

const SkuEditorLayout = ({ getAllSkus }) => (
  <APILoaderContainer
    onMount={(done) => {
      Promise.all([
        getAllSkus(constants.ADMIN_DEFAULT_ROWS_PER_PAGE, 1, '-name')
      ]).then(() => { done() })
    }}
  >
    <section>
      <SkuList />
    </section>
  </APILoaderContainer>
)

SkuEditorLayout.propTypes = {
  getAllSkus: PropTypes.func.isRequired
}

export default SkuEditorLayout
