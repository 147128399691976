import { connect } from 'react-redux'
import { buildJsonApiOrdered, buildJsonApiAll } from 'utils-em'
import { includes } from 'lodash'
import { Alerts, Dialogs, JsonAPI } from 'store'

import EditAccountDialog from './EditAccountDialog'

const mapStateToProps = ({ data }, ownProps) => ({
  advisorRequest: buildJsonApiOrdered(data, 'advisorRequests').find((ar) => ar.id === ownProps.advisorRequestId),
  bulkEditAdvisorRequests: buildJsonApiAll(data, 'advisorRequests').filter((ar) => includes(ownProps.bulkEditAdvisorRequestIds, ar.id))
})

const mapDispatchToProps = {
  saveAccount: (acc) => {
    const method = acc.id ? JsonAPI.save : JsonAPI.create
    return method({
      ...acc,
      type: 'accounts',
      queryStringParams: {
        include: 'organization'
      }
    })
  },
  saveAdvisorRequest: (ar) => {
    const method = ar.id ? JsonAPI.save : JsonAPI.create
    return method({
      ...ar,
      type: 'advisorRequests',
      relationshipNames: ['tags', 'customerUsers', 'account'],
      queryStringParams: {
        include: [
          'account',
          'customerUsers',
          'customerUsers.divisions',
          'tags'
        ].join(',')
      },
      riders: ['customerUsers']
    })
  },
  bulkSave: (arsToSave) => JsonAPI.saveList({
    type: 'advisorRequests',
    queryStringParams: {
      include: [
        'account',
        'customerUsers',
        'customerUsers.divisions',
        'tags'
      ].join(',')
    },
    data: arsToSave,
    riders: ['customerUsers']
  }),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog()),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountDialog)
