import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'
import {
  CalendarTimeSelectorContext
} from '../../CalendarTimeSelectorContext'

const DayOfWeekHeader = () => {
  const {
    daysToShow
  } = React.useContext(CalendarTimeSelectorContext)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40px'
        }}
      >
        <Box sx={{ width: '40px' }}>
          {/* Spacer to account for time label width */}
        </Box>
        {daysToShow.map((date) => (
          <Typography key={date} sx={{ flex: 1, textAlign: 'center' }}>
            {date.format('ddd')}
            {' '}
            <Typography variant="bodyBold" sx={{ display: 'inline' }}>{date.format('MMM D')}</Typography>
          </Typography>
        ))}
      </Box>
    </>
  )
}

export default DayOfWeekHeader
