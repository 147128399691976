import PropTypes from 'prop-types'
import React from 'react'

import {
  Alert,
  Box,
  Typography
} from '@mui/material'
import { Information } from 'icons'

const InfoStrip = ({ text, subtext, closeable }) => {
  const [isClosed, setIsClosed] = React.useState(false)

  if (isClosed) return null

  const closeProps = {}
  if (closeable) {
    closeProps.onClose = () => setIsClosed(true)
  }

  return (
    <Alert
      sx={{
        mb: 1,
        pt: 0,
        pb: 0,
        borderRadius: '8px',
        bgcolor: 'brand.lightestPurple',
        '& .MuiAlert-message': {
          pt: 1.5,
          pb: 1.5
        }
      }}
      icon={<Information />}
      {...closeProps}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>
          {text}
        </Typography>
        {subtext && (
        <Typography variant="tiny" sx={{ mt: 2 }}>
          {subtext}
        </Typography>
        )}
      </Box>

    </Alert>
  )
}

InfoStrip.defaultProps = {
  subtext: null,
  closeable: false
}

InfoStrip.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  closeable: PropTypes.bool
}

export default InfoStrip
