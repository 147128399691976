import PropTypes from 'prop-types'
import React from 'react'
import { get, map, startCase } from 'lodash'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import Email from '@mui/icons-material/Email'
import Link from '@mui/icons-material/Link'
import Work from '@mui/icons-material/Work'

import { constants, buildJsonApiFilter, stealth, copyToClipboard } from 'utils-em'
import { UserInviteDialog } from 'components'

import CustomerUserAccountsDialog from '../CustomerUserAccountsDialog'
import EditCustomerUserDialog from '../EditCustomerUserDialog'
import CustomerUserTooltips from '../../../../../../../../components/CustomerUserTooltips'
import TablePaginationActions from '../../../../../../../../components/TablePaginationActions'
import NinjaIcon from '../../../../../../../../components/CustomIcons/NinjaIcon'

const CustomerUsersList = ({
  getAllCustomerUsers,
  customerId,
  customerUsers,
  totalCount,
  openDialog,
  showSuccessMessage,
  linkedCustomerUserId,
  linkedDivisionId
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [sortDirection, setSortDirection] = React.useState('asc')
  const [sortColumn, setSortColumn] = React.useState('fullName')
  const [showInactiveUsers, setShowInactiveUsers] = React.useState(false)
  // the shape of filters is:
  // { fullName:
  //   value: 'smith'
  //   filter: {
  //     name: 'fullName',
  //     op: 'like',
  //     val: '%smith%'
  //   }
  // }

  const getInitialFilters = () => {
    const initial = {}
    if (linkedCustomerUserId) initial.id = { filter: buildJsonApiFilter('id__eq', linkedCustomerUserId) }
    if (linkedDivisionId) {
      initial['divisions__any.id'] = {
        value: linkedDivisionId,
        filter: {
          name: 'divisions',
          op: 'any',
          val: { name: 'id', op: 'eq', val: linkedDivisionId }
        }
      }
    }
    return initial
  }
  const [filters, setFilters] = React.useState(getInitialFilters())

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    setFilters((prevState) => ({
      ...prevState,
      [name]: {
        value,
        filter: value ? buildJsonApiFilter(name, value) : null
      }
    }))
  }

  const clearFilters = () => {
    setFilters({})
    makeRequest()
  }

  const sortOnColumn = (columnName) => {
    setSortDirection(sortColumn !== columnName || sortDirection === 'asc' ? 'desc' : 'asc')
    setSortColumn(columnName)
  }

  const makeRequest = () => {
    getAllCustomerUsers(
      customerId,
      rowsPerPage,
      page + 1,
      `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`,
      showInactiveUsers,
      map(filters, (v, k) => v.filter).filter((f) => f)
    )
  }

  React.useEffect(() => { makeRequest() }, [page, rowsPerPage, sortDirection, sortColumn, showInactiveUsers])

  const getAncestors = (division, ancestors) => {
    let currentAncestors = []
    if (division.parent) {
      currentAncestors = getAncestors(division.parent, ancestors)
    }
    currentAncestors.push(division.displayName)
    return currentAncestors
  }

  return (
    <>
      <ValidatorForm onSubmit={makeRequest}>
        <Paper>
          <Grid container>
            <Grid item xs={12} sx={{ m: '1rem' }}>
              <Typography variant="h4">People</Typography>
            </Grid>
            <Grid item xs={2} sx={{ m: '1rem' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => openDialog(
                  <EditCustomerUserDialog
                    customerId={customerId}
                  />
                )}
              >
                Add User
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ m: '1rem' }}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={() => { makeRequest() }}
              >
                Apply Filters
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ m: '1rem' }}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={() => { clearFilters() }}
              >
                Clear Filters
              </Button>
            </Grid>

            <Grid item xs={2} sx={{ m: '1rem' }}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={showInactiveUsers}
                    onChange={() => setShowInactiveUsers(!showInactiveUsers)}
                    name="isActive"
                  />
                )}
                label="Show deactivated"
              />
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          <TableSortLabel
                            active={sortColumn === 'fullName'}
                            direction={sortDirection}
                            onClick={() => sortOnColumn('fullName')}
                          >
                            Name
                          </TableSortLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            value={get(filters, 'fullName.value', '')}
                            onChange={handleSearchFilter('fullName')}
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          <TableSortLabel
                            active={sortColumn === 'email'}
                            direction={sortDirection}
                            onClick={() => sortOnColumn('email')}
                          >
                            Email
                          </TableSortLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            value={get(filters, 'email.value', '')}
                            onChange={handleSearchFilter('email')}
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          Teams
                        </Grid>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            value={get(filters, ['divisions__any.name', 'value'], '')}
                            onChange={handleSearchFilter('divisions__any.name')}
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          <TableSortLabel
                            active={sortColumn === 'roleType'}
                            direction={sortDirection}
                            onClick={() => sortOnColumn('roleType')}
                          >
                            Role
                          </TableSortLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            value={get(filters, 'roleType.value', '')}
                            onChange={handleSearchFilter('roleType')}
                            margin="dense"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortColumn === 'jobTitle'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('jobTitle')}
                      >
                        Job Title
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      Phone Number
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { customerUsers.map((cu) => (
                    <TableRow key={cu.id}>
                      <TableCell component="th" scope="row">
                        {cu.fullName}
                        <CustomerUserTooltips
                          customerUser={cu}
                        />
                      </TableCell>
                      <TableCell>{cu.email}</TableCell>
                      <TableCell>
                        {cu.divisions && cu.divisions.length
                          ? cu.divisions.map((d) => {
                            const ancestors = getAncestors(d, [])
                            return ancestors.map((divisionName) => (
                              <React.Fragment key={divisionName}>
                                •
                                {' '}
                                <strong>{divisionName}</strong>
                                <br />
                              </React.Fragment>
                            ))
                          })
                          : 'N/A'}
                      </TableCell>
                      <TableCell component="th" scope="row">{startCase(cu.roleType)}</TableCell>
                      <TableCell>{cu.jobTitle}</TableCell>
                      <TableCell>{cu.phoneNumber}</TableCell>
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton
                            edge="end"
                            onClick={() => openDialog(
                              <EditCustomerUserDialog
                                customerUser={cu}
                                customerId={customerId}
                              />
                            )}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Stealth Mode">
                          <IconButton
                            edge="end"
                            onClick={() => stealth(cu.id, (returnPage) => {
                              window.location.assign(returnPage)
                            })}
                            size="large"
                          >
                            <NinjaIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          edge="end"
                          onClick={() => openDialog(
                            <CustomerUserAccountsDialog
                              customerUserId={cu.id}
                            />
                          )}
                          size="large"
                        >
                          <Tooltip title="User Accounts">
                            <Work />
                          </Tooltip>
                        </IconButton>
                        {!cu.onboardingCompleted
                          ? (
                            <Tooltip title="Send Invite">
                              <IconButton
                                edge="end"
                                onClick={() => openDialog(
                                  <UserInviteDialog
                                    userType="customerUsers"
                                    userId={cu.id}
                                  />
                                )}
                                size="large"
                              >
                                <Email />
                              </IconButton>
                            </Tooltip>
                            )
                          : null }
                        <Tooltip title="User Link">
                          <IconButton
                            edge="end"
                            onClick={() => {
                              copyToClipboard(
                                `${window.location.href}?customerUserId=${cu.id}`,
                                showSuccessMessage
                              )
                            }}
                            size="large"
                          >
                            <Link />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true
                      }}
                      onPageChange={(event, newPage) => setPage(newPage)}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(event.target.value)
                        setPage(0)
                      }}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Grid>
        </Paper>
      </ValidatorForm>
    </>
  )
}

CustomerUsersList.propTypes = {
  getAllCustomerUsers: PropTypes.func.isRequired,
  customerUsers: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  openDialog: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  linkedCustomerUserId: PropTypes.number.isRequired,
  linkedDivisionId: PropTypes.number.isRequired,
}

export default CustomerUsersList
