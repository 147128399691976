import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { CardActionArea, Box, Button, Card, CardContent, IconButton, InputLabel, Typography, Tooltip, MenuItem, FormControl, Select } from '@mui/material'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Error from '@mui/icons-material/Error'
import HourglassEmpty from '@mui/icons-material/HourglassEmpty'
import CloudUpload from '@mui/icons-material/CloudUpload'
import PublishedWithChanges from '@mui/icons-material/PublishedWithChanges'
import Replay from '@mui/icons-material/Replay'
import { ConfirmDialog, ImportCSVDialog, DateSelector } from 'components'
import { formatDate, navigateTo, openDialog } from 'utils-em'
import { useJsonAPIGetAll } from 'hooks'
import { JsonAPI } from 'store'
import {
  Filter,
  Sort
} from 'icons'
import AccountValidateTable from './AccountValidateTable/AccountValidateTable'

const statuses = [
  'uploaded',
  'processing',
  'ready_to_finalize',
  'finalized',
  'error'
]

const AccountUploadJobsList = () => {
  const { id: customerId, jobId } = useParams()
  const [selectedJob, setSelectedJob] = useState(jobId || null)
  const [statusFilter, setStatusFilter] = useState('')
  const [ownerFilter, setOwnerFilter] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [sortBy, setSortBy] = useState('-dateCreated')
  const [filter, setFilter] = useState([
    {
      name: 'customerId',
      op: 'eq',
      val: customerId
    }
  ])
  const dispatch = useDispatch()

  const resetFilters = () => {
    setFilter(
      [
        {
          name: 'customerId',
          op: 'eq',
          val: customerId
        }
      ]
    )
    setStartDate('')
    setEndDate('')
    setOwnerFilter('')
    setStatusFilter('')
  }

  React.useEffect(() => {
    setSelectedJob(jobId)
  }, [jobId])

  const { objects: jobs, data } = useJsonAPIGetAll('dataIngestionJobs', { include: ['owner'], filters: filter, sortBy })

  const ownerDropdownValues = [...new Set(data?.map((o) => JSON.stringify({ ownerId: o?.owner?.id || '', ownerName: o?.owner?.fullName || '' })) || [])].map((jsonString) => JSON.parse(jsonString))

  const loadJobs = async () => {
    dispatch(JsonAPI.getAll({
      type: 'dataIngestionJobs',
      queryStringParams: {
        include: 'owner',
        filter,
        sort: sortBy
      }
    }))
  }

  React.useEffect(() => {
    loadJobs()
  }, [filter, sortBy])

  const onSubmit = () => {
    loadJobs()
    setSelectedJob(null)
  }

  const onNewCSVUpload = () => {
    loadJobs()
  }

  const onRemoveJob = async (jobIdToRemove) => {
    await dispatch(JsonAPI.save({
      type: 'dataIngestionJobs',
      id: jobIdToRemove,
      status: 'discarded'
    }))
    await loadJobs()
  }

  const getStatusIcon = (job) => {
    const { status, errors } = job

    switch (status) {
      case 'processing':
        return { icon: <HourglassEmpty />, text: 'Processing' }
      case 'finalizing':
        return { icon: <PublishedWithChanges />, text: 'Finalizing' }
      case 'ready_to_finalize':
        return { icon: <CloudUpload />, text: 'Ready to finalize' }
      case 'finalized':
        return { icon: <CheckCircle />, text: 'Finalized' }
      case 'error':
        return { icon: <Error />, text: 'Error with CSV', subText: errors }
      default:
        return null
    }
  }

  const updateFilters = (filterName, value, opType = 'eq') => {
    if (filterName === 'dateCreated') {
      const newFilter = [...filter].filter((f) => f.op !== opType)
      const filterDate = moment(value)
      newFilter.push({
        name: filterName,
        op: opType,
        val: filterDate.format('YYYY-MM-DD HH:mm')
      })
      setFilter(newFilter)
    } else {
      const newFilter = [...filter].filter((f) => f.name !== filterName)
      newFilter.push({
        name: filterName,
        op: opType,
        val: value
      })
      setFilter(newFilter)
    }
  }

  return (
    <Box sx={{ width: '100%', mt: 8 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, mt: 2 }}>
        <Typography variant="h5" gutterBottom>
          Account Ingestion (Unlimited Version)
        </Typography>
        <IconButton onClick={loadJobs}>
          <Tooltip title="Refresh jobs">
            <Replay />
          </Tooltip>
        </IconButton>
      </Box>
      {
        !selectedJob && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, mt: 2 }}>
            {/* Content for filters */}
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <FormControl fullWidth>
                <InputLabel id="status-label">
                  <Filter color="inherit" />
                  Status
                </InputLabel>
                <Select
                  labelId="status-label"
                  id="status-filter-select"
                  value={statusFilter}
                  sx={{ minWidth: 200, mr: 1, textTransform: 'capitalize' }}
                  onChange={(e) => {
                    setStatusFilter(e.target.value)
                    updateFilters('status', e.target.value)
                  }}
                >
                  {
                    statuses.map((s) => (
                      <MenuItem sx={{ textTransform: 'capitalize' }} key={s} value={s}>{s.replaceAll('_', ' ')}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <DateSelector
                disableFuture
                includeYears
                label="Start Date Filter"
                onChange={(e) => {
                  setStartDate(e)
                  updateFilters('dateCreated', e, 'gt')
                }}
                value={startDate}
                sx={{ minWidth: 200, mr: 1 }}
              />
              <DateSelector
                disableFuture
                includeYears
                label="End Date Filter"
                onChange={(e) => {
                  setEndDate(e)
                  const endDateMoment = moment(e)
                  const addEndDate = endDateMoment.add(1, 'days')
                  updateFilters('dateCreated', addEndDate.format('YYYY-MM-DD'), 'lt')
                }}
                value={endDate}
                sx={{ minWidth: 200, mr: 1 }}
              />
              <FormControl fullWidth>
                <InputLabel id="owner-label">
                  <Filter color="inherit" />
                  Owner
                </InputLabel>
                <Select
                  labelId="owner-label"
                  id="owner-filter-select"
                  value={ownerFilter}
                  sx={{ minWidth: 200, mr: 1 }}
                  onChange={(e) => {
                    setOwnerFilter(e.target.value)
                    updateFilters('ownerId', e.target.value)
                  }}
                >
                  {
                    ownerDropdownValues.sort((a, b) => (a.ownerName?.valueOf() > b.ownerName?.valueOf() ? 1 : -1)).map((s) => (
                      <MenuItem key={s.ownerId} value={s.ownerId}>{s.ownerName}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <Box sx={{ minWidth: 150, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 2 }}>
                <Button onClick={resetFilters} variant="text">
                  Clear filters
                </Button>
              </Box>
            </Box>
            <Box>
              {/* Content for sort */}
              <FormControl fullWidth>
                <InputLabel id="sort-label">
                  <Sort color="inherit" />
                  Sort by:
                </InputLabel>
                <Select
                  labelId="sort-label"
                  id="sort-select"
                  value={sortBy}
                  sx={{ minWidth: 200 }}
                  onChange={(e) => {
                    setSortBy(e.target.value)
                  }}
                >
                  <MenuItem key="dateCreated" value="-dateCreated">Date Created</MenuItem>
                  <MenuItem key="owner.firstName" value="owner.firstName">Owner</MenuItem>
                  <MenuItem key="status" value="-status">Status</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

        )
      }
      {selectedJob ? (
        <AccountValidateTable
          customerId={customerId}
          jobId={selectedJob}
          onSubmit={onSubmit}
          allowSubmit={jobs.find((j) => j.id === selectedJob)?.status === 'ready_to_finalize'}
        />
      )
        : (
          <>
            {jobs.length === 0 && (
            <Typography variant="h7" sx={{ mb: 4 }}>
              {
                (statusFilter || ownerFilter || startDate || endDate)
                  ? (
                      'No account ingestion jobs match this criteria.'
                    ) : (
                      'No account ingestion jobs performed yet. Start one!'
                    )
              }
            </Typography>
            )}
            <Box>
              <Card sx={{ minHeight: '184px', width: '300px', mr: 3, mt: 3 }}>
                <CardActionArea
                  sx={{ height: '184px' }}
                  onClick={() => openDialog((
                    <ImportCSVDialog
                      type={`customers/${customerId}/parse-account-csv-unlimited`}
                      onSubmit={onNewCSVUpload}
                      title="Upload a CSV file of accounts"
                    />
                  ))}
                >
                  <CardContent>
                    <Add fontSize="large" />
                    <Typography gutterBottom variant="h5" component="h2">
                      Start a new account ingestion job
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mt: 3, rowGap: 3 }}>
                {jobs.filter((j) => j.status !== 'discarded').map((job) => (
                  <Tooltip title={job.status === 'processing' ? 'Must wait for processing to complete.' : ''} key={job.id}>
                    <Card key={job.id} sx={{ mr: 5, width: '300px' }}>
                      <CardActionArea
                        sx={{ height: '200px' }}
                        onClick={() => (job.status !== 'processing' && job.status !== 'error' ? navigateTo(`/admin/customers/${customerId}/upload-accounts-unlimited/${job.id}`) : null)}
                      >
                        <CardContent sx={{ mb: 1 }}>
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                          >
                            <Tooltip title={getStatusIcon(job).text} sx={{ fontSize: 64, mb: 2 }}>
                              {getStatusIcon(job).icon}
                            </Tooltip>
                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                              <Typography sx={{ fontSize: 14, fontWeight: 300 }}>
                                {getStatusIcon(job).text}
                              </Typography>
                              {getStatusIcon(job).subText && getStatusIcon(job).subText !== 'null' && (
                              <Typography sx={{ fontSize: 10, fontWeight: 300 }}>
                                {getStatusIcon(job).subText}
                              </Typography>
                              )}
                            </Box>
                          </Box>
                          {job.status !== 'error' && (
                          <Typography color="textSecondary">
                            {`Total rows: ${job.rowCount}`}
                          </Typography>
                          )}
                          <Typography color="textSecondary">
                            {`Owner: ${job.owner?.fullName}`}
                          </Typography>
                          <Typography color="textSecondary">
                            {`Date created: ${formatDate(job.dateCreated)}`}
                          </Typography>
                          {job.status !== 'finalized' && (
                            <Box sx={{ display: 'flex' }}>
                              <Delete
                                sx={{ ml: 'auto' }}
                                onClick={() => openDialog(
                                  <ConfirmDialog
                                    description="Are you sure you want to discard the job?"
                                    actions={[
                                      {
                                        name: 'Discard job',
                                        action: () => { onRemoveJob(job.id) },
                                        isDelete: true
                                      }
                                    ]}
                                  />
                                )}
                              />
                            </Box>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Tooltip>
                ))}
              </Box>

            </Box>
          </>
          )}
    </Box>
  )
}

export default AccountUploadJobsList
