import * as ACTION_TYPE from './constants'

export const ENUM_KEY_TO_ENUM_TYPE = {
  tenure_types: 'tenure',
  seniorities: 'seniority',
  reject_reason: 'rejectReason',
  reasons: 'rejectReason',
  statuses: 'statuses',
  type_of_purpose_for_call: 'typeOfPurposeForCall',
  asset_types: 'assetType',
  notification_types: 'notificationType'
}

const INITIAL_STATE = {
  tenure: {},
  tenureList: [],
  seniority: {},
  seniorityList: [],
  rejectReason: {},
  rejectReasonList: [],
  statuses: {},
  statusesList: [],
  typeOfPurposeForCall: {},
  typeOfPurposeForCallList: [],
  assetTypesList: [],
  notificationTypesList: [],
  buyerPersonaTypesList: []
}

export default function enumReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPE.STORE_ENUMS: {
      const newEnums = Object.keys(action.enums).reduce((prev, enumKey) => {
        const enumType = ENUM_KEY_TO_ENUM_TYPE[enumKey]

        const enumCollectionByName = {
          [enumType]: action.enums[enumKey]
            .reduce((prevEnumDict, enumDict) => Object.assign(prevEnumDict, { [enumDict.name]: enumDict }), {})
        }

        const enumList = { [`${enumType}List`]: action.enums[enumKey] }

        return Object.assign(prev, enumCollectionByName, enumList)
      }, {})

      return { ...state, ...newEnums }
    }
    default: {
      return state
    }
  }
}
