import { connect } from 'react-redux'
import { JsonAPI } from 'store'

import SkuEditorLayout from '../layouts/SkuEditorLayout'

const mapDispatchToProps = {
  getAllSkus: (size, page, sort) => JsonAPI.getAll({
    type: 'skus',
    sort,
    queryStringParams: {
      'page[size]': size,
      'page[number]': page
    }
  })
}

export default connect(null, mapDispatchToProps)(SkuEditorLayout)
