import PropTypes from 'prop-types'
import React from 'react'

import { Tooltip } from '@mui/material'
import AttachMoney from '@mui/icons-material/AttachMoney'
import Star from '@mui/icons-material/Star'
import Note from '@mui/icons-material/Note'
import Warning from '@mui/icons-material/Warning'
import EventBusy from '@mui/icons-material/EventBusy'
import LabelOff from '@mui/icons-material/LabelOff'
import {
  formatDateTime,
  isAdvisorAvailable,
} from 'utils-em'

import {
  StatusCircle
} from 'components'

const styles = {
  advisorInfoIcon: {
    height: '0.75em',
    width: '0.75em',
    verticalAlign: 'top'
  }
}

const AdvisorTooltips = ({ advisor }) => {
  const advisorCompany = advisor.companies && advisor.companies.length && advisor.companies && advisor.companies[0]
  const advisorCompanyVerified = advisorCompany && (advisorCompany.dateStandardizationVerified || !advisorCompany.dateStandardizationSet)
  return (
    <>
      <StatusCircle
        status={advisor.status}
        component="span"
        sx={{ ml: '4px' }}
      />
      { !isAdvisorAvailable(advisor)
        ? (
          <Tooltip title={`The advisor is not available until: ${formatDateTime(advisor.dateAvailable)}`}>
            <EventBusy color="secondary" sx={{ ...styles.advisorInfoIcon }} />
          </Tooltip>
          )
        : null }
      {(!advisorCompanyVerified) && (
        <Tooltip title={"The advisor's company has not had its standardization verified"}>
          <LabelOff sx={{ ...styles.advisorInfoIcon }} />
        </Tooltip>
      )}
      { advisor.singleCallRate !== 250
        ? (
          <Tooltip title={(
            <div>
              Single Call Rate:
              {' '}
              {advisor.singleCallRate}
            </div>
          )}
          >
            <AttachMoney sx={{ ...styles.advisorInfoIcon }} />
          </Tooltip>
          )
        : null }
      { advisor.isVip
        ? (
          <Tooltip title="VIP">
            <Star sx={{ ...styles.advisorInfoIcon }} />
          </Tooltip>
          )
        : null }
      { advisor.adminNotes
        ? (
          <Tooltip title={advisor.adminNotes}>
            <Note sx={{ ...styles.advisorInfoIcon }} />
          </Tooltip>
          )
        : null}
      { !advisor.isActive
        ? (
          <Tooltip title="Inactive">
            <Warning color="error" sx={{ ...styles.advisorInfoIcon }} />
          </Tooltip>
          )
        : null}
    </>
  )
}

AdvisorTooltips.propTypes = {
  advisor: PropTypes.object.isRequired
}

export default AdvisorTooltips
