import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Typography } from '@mui/material'

import { Pencil, Trash } from 'icons'
import { RoleIconDetailsTray } from 'components'
import { useSessionUser } from 'hooks'

const RoleCardTwo = ({ role, onEditClick, onDeleteClick }) => {
  const { isCustomerUser } = useSessionUser()

  return (
    <Box sx={{ mx: 3, my: isCustomerUser ? 5 : 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" color="neutral.black" sx={{ display: 'block', mb: isCustomerUser ? 3 : 2 }}>
          {role.title}
        </Typography>
        {!isCustomerUser && (
          <Box>
            <IconButton onClick={onEditClick}>
              <Pencil sx={{ color: 'primary.main' }} />
            </IconButton>
            <IconButton onClick={onDeleteClick}>
              <Trash sx={{ color: 'error.main' }} />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Detail chips */}
      <RoleIconDetailsTray role={role} typography="body1" />

      {/* Description */}
      <Typography variant="body1" color="neutral.black" sx={{ display: 'block', mt: isCustomerUser ? 3 : 2 }}>
        {role.description}
      </Typography>
    </Box>
  )
}

RoleCardTwo.propTypes = {
  role: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
}

export default RoleCardTwo
