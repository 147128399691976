import React, { useState, useEffect } from 'react'
import { map } from 'lodash'
import { useDispatch } from 'react-redux'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  Paper
} from '@mui/material'

import { ConfirmDialog } from 'components'
import {
  buildJsonApiFilter,
  constants,
  httpResponseAlert,
  openDialog
} from 'utils-em'
import {
  useJsonAPIGetAll,
} from 'hooks'
import { Alerts, JsonAPI } from 'store'

import TablePaginationActions from '../../../../../../components/TablePaginationActions'

const SkuList = () => {
  const dispatch = useDispatch()

  const [dialogActive, setDialogActive] = useState(false)
  const [activeSku, setActiveSku] = useState({})
  const [sortDirection, setSortDirection] = useState('desc')
  const [sortColumn, setSortColumn] = useState('dateUpdated')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [searchTerms, setSearchTerms] = useState({})
  const [searchFilters, setSearchFilters] = useState({})

  const { objects: skus, totalCount: skusCount, reload } = useJsonAPIGetAll('skus', {
    pageSize: rowsPerPage,
    pageNumber: page + 1,
    sortBy: `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`,
    queryStringParams: {
      filter: map(searchFilters, (v, k) => v)
    }
  })

  useEffect(() => {
    reload()
  }, [sortDirection, sortColumn, rowsPerPage, page, searchFilters])

  const sort = {
    desc: {
      opposite: 'asc',
      prefix: '-'
    },
    asc: {
      opposite: 'desc',
      prefix: ''
    }
  }

  const handleChange = (name) => (event) => {
    setActiveSku({
      ...activeSku,
      [name]: event.target.value
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const saveSku = () => {
    const method = activeSku.id ? JsonAPI.save : JsonAPI.create
    dispatch(method(Object.assign(activeSku, {
      type: 'skus'
    }))).then((res) => {
      if (!res.ok) {
        Alerts.showSuccess({
          text: `Save sku failed: ${res.error.errors.map((e) => e.detail).join('; ')}`,
          timeout: 2000
        })
      } else {
        Alerts.showSuccess({
          text: 'Sku Saved',
          timeout: 2000
        })

        setDialogActive(false)
      }
    })
  }

  const sortOnColumn = (column) => {
    const newSortDirection = sortColumn === column ? sort[sortDirection].opposite : 'desc'
    setSortColumn(column)
    setSortDirection(newSortDirection)
  }

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    const filter = buildJsonApiFilter(name, value)
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [name]: value
    }))
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: filter
    }))
  }

  const editSku = (sku) => {
    setActiveSku(sku)
    setDialogActive(true)
  }

  const createNewSku = () => {
    setActiveSku({ sku: '', name: '' })
    setDialogActive(true)
  }

  const deleteSku = (sku) => {
    dispatch(JsonAPI.delete(sku)).then((res) => {
      httpResponseAlert(res)
      reload()
    })
  }

  return (
    <div>
      <Dialog open={dialogActive} fullWidth>
        <ValidatorForm
          onSubmit={() => openDialog(
            <ConfirmDialog
              description={`Are you sure you want to save: ${activeSku.name}?`}
              actions={[
                {
                  name: 'Save',
                  action: () => { saveSku() }
                }
              ]}
            />
          )}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label="Name"
                  validators={['required']}
                  errorMessages={['Required Field']}
                  value={activeSku.name}
                  onChange={handleChange('name')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label="Sku"
                  validators={['required']}
                  errorMessages={['Required Field']}
                  value={activeSku.sku}
                  onChange={handleChange('sku')}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button onClick={() => setDialogActive(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <Paper sx={{ mt: 8, p: '1em' }}>
        <ValidatorForm onSubmit={reload}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={createNewSku}>
                Create new Sku
              </Button>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <Button color="primary" type="submit" variant="contained" onClick={reload}>
                Apply Filters
              </Button>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        active={sortColumn === 'name'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('name')}
                      >
                        Name
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={searchTerms.name || ''}
                        onChange={handleSearchFilter('name')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        active={sortColumn === 'sku'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('sku')}
                      >
                        Sku
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={searchTerms.sku || ''}
                        onChange={handleSearchFilter('sku')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {skus.map((sku) => (
                <TableRow key={sku.id}>
                  <TableCell>{sku.name}</TableCell>
                  <TableCell>{sku.sku}</TableCell>
                  <TableCell>
                    <Button onClick={() => editSku(sku)}>Edit</Button>
                    <Button
                      onClick={() => openDialog(
                        <ConfirmDialog
                          description={`Really delete sku: ${sku.name}?`}
                          actions={[
                            {
                              name: 'Delete',
                              action: () => { deleteSku(sku) },
                              isDelete: true
                            }
                          ]}
                        />
                      )}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                  count={skusCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </ValidatorForm>
      </Paper>
    </div>
  )
}

export default SkuList
