import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { AdvisorCard, JsonApiList } from 'components'
import { buildJsonApiOne } from 'utils-em'

const AdvisorsList = ({ orgId = null, sort }) => {
  const selectedOrg = useSelector(({ data }) => orgId && buildJsonApiOne(data, 'organizations', orgId))

  const getNoDataText = (org) => {
    if (org) {
      return 'The Emissary network is comprised of former executives from the world\'s largest companies. Unfortunately, we do not have any advisors for this account.'
    }
    return 'Unfortunately, there are no active advisors associated with this account.'
  }

  return (
    <Box sx={{ width: '100%', display: 'inline-block' }}>
      <JsonApiList
        type="engageableAdvisors"
        queryStringParams={{
          ...(selectedOrg?.id && { orgId: selectedOrg.id }),
          include: [
            'companies',
            'companies.organization',
            'companies.roles',
            'companies.roles.tags',
          ].join(','),
          'lazy[advisors]': 'isEngageable',
          sort,
        }}
        getObjectsFromRes={(res) => res.data.data.map((advisor) => ({
          advisorId: parseInt(advisor.id, 10),
          orgId: selectedOrg?.id ?? advisor.attributes.accountListOrganizationId,
          key: advisor.id,
          jsonApiType: 'engageableAdvisors',
        }))}
        dependencies={selectedOrg ? [selectedOrg.id, sort] : [sort]}
        renderObject={(props) => <AdvisorCard {...props} />}
        noDataText={getNoDataText(selectedOrg)}
        loadSize={20}
      />
    </Box>
  )
}

AdvisorsList.propTypes = {
  orgId: PropTypes.string,
  sort: PropTypes.string,
}

export default AdvisorsList
