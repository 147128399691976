import React from 'react'
import { preloadImage, isImageCached } from 'utils-em'

export default function usePreloadImage (imageUrl) {
  const [preloaded, setPreloaded] = React.useState(false)

  React.useEffect(() => {
    if (!imageUrl) return
    if (isImageCached(imageUrl)) setPreloaded(true)
    else preloadImage(imageUrl).then(() => setPreloaded(true))
  }, [imageUrl])

  return preloaded
}
