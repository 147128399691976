import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import AuthorizedRoute from './AuthorizedRoute'

const mapStateToProps = ({ session }) => ({
  userFeatures: session.features
})

const mapDispatchToProps = {
  navigateTo: (path) => (dispatch) => dispatch(push(path))
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedRoute)
