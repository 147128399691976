import { ACTION_CONSTANTS, navigateTo, recordUserAction } from 'utils-em'

export function getDistinctAccountsFromARList (advisorRequests) {
  return advisorRequests.reduce((acc, ar) => {
    if (!acc.find((a) => a.organizationId === ar.account.organizationId)) {
      acc.push(ar.account)
    }
    return acc
  }, [])
}

export function handleAccountNavigation (orgId, publishedAccountPageId = null, engageableAdvisorCount = 0, origin = null) {
  if (origin) {
    recordUserAction(ACTION_CONSTANTS.clickedAccount, { id: orgId }, 'organization', {
      publishedAccountPageId,
      engageableAdvisorCount,
      origin
    })
  }
  if (publishedAccountPageId) navigateTo(`/c/buying-profile/${publishedAccountPageId}`)
  else if (engageableAdvisorCount > 0) navigateTo(`/c/accounts/${orgId}/advisor-list`)
}
