import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

const AssignmentValidationTable = ({ validationData }) => {
  const renderValidationRow = (validationRecord) => {
    const {
      valid,
      email,
      message,
      advisor_full_name: advisorFullName,
      organization_name: organizationName
    } = validationRecord
    return (
      <TableRow component="div" key={email}>
        <TableCell component="div">{`${valid ? '✅' : '❌'} ${email}`}</TableCell>
        <TableCell component="div">{advisorFullName}</TableCell>
        <TableCell component="div">{organizationName}</TableCell>
        <TableCell component="div">{message}</TableCell>
      </TableRow>
    )
  }

  return (
    <Table component="div" size="small">
      <TableHead component="div">
        <TableRow component="div">
          <TableCell component="div">Email</TableCell>
          <TableCell component="div">Advisor</TableCell>
          <TableCell component="div">Organization</TableCell>
          <TableCell component="div">Message</TableCell>
        </TableRow>
      </TableHead>
      <TableBody component="div">{validationData.map((r) => renderValidationRow(r))}</TableBody>
    </Table>
  )
}

AssignmentValidationTable.propTypes = {
  validationData: PropTypes.array.isRequired
}

export default AssignmentValidationTable
