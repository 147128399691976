import React from 'react'
import { Box } from '@mui/material'
import propTypes from 'prop-types'

const GreyBox = ({ children }) => (
  <Box sx={{
    bgcolor: 'brand.lightNavy',
    p: 3,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  }}
  >
    {children}
  </Box>
)

GreyBox.propTypes = {
  children: propTypes.node.isRequired
}

export default GreyBox
