import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid
} from '@mui/material'
import {
  useEmissaryTheme
} from 'hooks'

const ResponsiveGrid = ({ children, ...props }) => {
  const { gutter, columns } = useEmissaryTheme()

  return (
    <Grid container spacing={gutter} columns={columns} {...props}>
      <Grid item sm={0} lg={2} />
      <Grid item sm={columns} lg={8}>
        {children}
      </Grid>
      <Grid item sm={0} lg={2} />
    </Grid>
  )
}

ResponsiveGrid.propTypes = {
  children: PropTypes.node.isRequired
}

export default ResponsiveGrid
