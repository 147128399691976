import { connect } from 'react-redux'
import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'

import ContractSelector from './ContractSelector'

const mapStateToProps = ({ data }) => ({
  contracts: buildJsonApiOrdered(data, 'contracts')
})

const mapDispatchToProps = {
  loadContracts: (customerId) => JsonAPI.getAll({
    type: 'contracts',
    queryStringParams: {
      filter: [
        { name: 'customerId', op: 'eq', val: customerId },
        { name: 'name', op: 'isnot', val: null }
      ],
      include: 'contractSkuAllotments,contractSkuAllotments.sku,contractSkus',
      sort: '-startDate'
    }
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSelector)
