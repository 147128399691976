import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import {
  Button,
  IconButton
} from '@mui/material'
import {
  Clipboard
} from 'icons'
import {
  copyToClipboard,
  customAlert
} from 'utils-em'
import { AccountPageContext } from '../AccountPageContext'

const CopyToClipboard = ({ isAbbreviated }) => {
  const { pathname } = useLocation()
  const {
    activeSectionKey
  } = React.useContext(AccountPageContext)

  const copyLink = () => {
    copyToClipboard(
      activeSectionKey !== 'landingPage' ? `${__APP_HOST__}${pathname}#${activeSectionKey}` : `${__APP_HOST__}${pathname}`,
      () => customAlert('Link copied to clipboard.')
    )
  }

  return isAbbreviated ? (
    <IconButton onClick={copyLink} sx={{ '&:hover': { backgroundColor: 'primary.lightest' } }}>
      <Clipboard sx={{ color: 'primary.main' }} />
    </IconButton>
  ) : (
    <Button
      variant="text"
      startIcon={<Clipboard />}
      onClick={copyLink}
    >
      Copy link to share
    </Button>
  )
}

CopyToClipboard.defaultProps = {
  isAbbreviated: false
}

CopyToClipboard.propTypes = {
  isAbbreviated: PropTypes.bool
}

export default CopyToClipboard
