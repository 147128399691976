import React from 'react'
import { JsonApiDataGrid, FeatureAccessControl } from 'components'
import { useDispatch } from 'react-redux'
import { JsonAPI } from 'store'
import { Box, Button, IconButton, Link, Tooltip, Typography } from '@mui/material'
import {
  customAlert,
  formatDate,
  constants,
  navigateTo
} from 'utils-em'
import { Archive, Pencil } from 'icons'
import moment from 'moment'
import DevApiUrlInput from './components/DevApiUrlInput'

const BuildSurveyTab = () => {
  const dispatch = useDispatch()
  const toggleSurveyArchival = (row) => {
    const updatedArchivedDate = row.archivedDate === null ? moment().toISOString() : null
    dispatch(JsonAPI.save({
      type: 'surveys',
      id: row.id,
      archivedDate: updatedArchivedDate
    }, 'surveys')).then(
      customAlert(`Archived ${row.title}`)
    )
  }

  const columns = [
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      width: 150,
      type: 'date',
      valueFormatter: ({ value }) => formatDate(value, { includeYear: true })
    },
    {
      field: 'title',
      type: 'string',
      headerName: 'Title',
      flex: 1,
    },
    {
      field: 'typeformTitle',
      type: 'string',
      headerName: 'Typeform Title',
      flex: 1,
    },
    {
      field: 'typeformFormId',
      headerName: 'Typeform ID',
      width: 150,
      renderCell: ({ row }) => (
        <Link
          target="_blank"
          href={`${constants.TYPEFORM_ADMIN_URL}/form/${row.typeformFormId}/create`}
        >
          {row.typeformFormId}
        </Link>
      )
    },
    {
      field: 'payment',
      type: 'string',
      headerName: 'Payment Amount',
      width: 150,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {`$${row.payment}`}
        </Typography>
      )
    },
    {
      field: 'surveyUses',
      headerName: 'Onboarding',
      width: 150,
      valueGetter: ({ row }) => row.surveyUses
    },
    {
      fieldName: 'actions',
      field: 'actions',
      headerName: 'Actions',
      actions: true,
      disablesSort: true,
      renderCell: ({ row }) => (
        <Box
          sx={{ display: 'flex' }}
        >
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => navigateTo(`/admin/surveys/${row.id}`)}
            >
              <Pencil />
            </IconButton>
          </Tooltip>
          <Tooltip title={row.archivedDate === null ? 'Archive' : 'Restore'}>
            <IconButton
              aria-label="Archive/Restore"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => toggleSurveyArchival(row)}
            >
              <Archive />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      width: 150,
    },
  ]

  const renderedColumns = columns.map((column) => ({
    ...column,
    cellClassName: column.actions ? undefined : (params) => (params.row.archivedDate !== null ? 'discarded' : '')
  }))

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button variant="contained" sx={{ mb: 2 }} onClick={() => navigateTo('/admin/surveys/create-new')}>
          New survey
        </Button>
        <FeatureAccessControl feature="dev_api_url">
          <DevApiUrlInput />
        </FeatureAccessControl>
      </Box>
      <JsonApiDataGrid
        disableExport
        type="surveys"
        columns={renderedColumns}
        jsonApiOptions={{
          include: [],
          sortBy: '-dateCreated',
        }}
        mode="server"
        density="compact"
      />
    </Box>
  )
}

export default BuildSurveyTab
