import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  InputLabel,
  TextField
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { JsonAPI } from 'store'

import {
  closeDialog,
  customAlert
} from 'utils-em'
import { TopicAutocomplete } from 'components'

const CreateEditTopicDialog = ({ topic, setReload }) => {
  const [topicData, setTopicData] = React.useState(topic || {
    name: '',
    description: '',
    guidanceText: '',
  })
  const dispatch = useDispatch()

  const createUpdateTopic = async () => {
    const method = topic?.id ? JsonAPI.save : JsonAPI.create
    try {
      const res = await dispatch(method({
        type: 'topics',
        ...topicData,
        id: topic?.id,
      }))
      if (res.ok) {
        setReload({ ...topicData })
        closeDialog()
      } else {
        customAlert('Error updating or creating topic. Please contact support.', true)
      }
    } catch (err) {
      customAlert('Error updating or creating topic. Please contact support.', true)
    }
  }

  return (
    <Dialog
      open
      onClose={closeDialog}
      size="xl"
    >
      <DialogTitle>{topic?.id ? 'Edit Topic' : 'Create Topic'}</DialogTitle>
      <DialogContent sx={{ minWidth: '600px', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <InputLabel id="name-label">Name</InputLabel>
          <TextField
            id="name-input"
            value={topicData.name}
            onChange={(e) => setTopicData({ ...topicData, name: e.target.value })}
            multiline
            required
            fullWidth
            variant="outlined"
          />
        </Box>
        <Box>
          <InputLabel id="description-label">Description</InputLabel>
          <TextField
            id="description-input"
            value={topicData.description}
            onChange={(e) => setTopicData({ ...topicData, description: e.target.value })}
            multiline
            rows={4}
            required
            fullWidth
            variant="outlined"
          />
        </Box>
        <Box>
          <InputLabel id="guidance-label">Guidance Text</InputLabel>
          <TextField
            id="guidance-input"
            value={topicData.guidanceText}
            onChange={(e) => setTopicData({ ...topicData, guidanceText: e.target.value })}
            multiline
            rows={4}
            required
            fullWidth
            variant="outlined"
          />
        </Box>
        <Box>
          <TopicAutocomplete
            label="Parent Topic"
            onChange={(e, value) => setTopicData({ ...topicData, parentId: value?.id || null })}
            helperText="Start typing to select a parent topic"
            initialTopics={[topicData.parent]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>Cancel</Button>
        <Button variant="text" disabled={!topicData?.name} onClick={createUpdateTopic}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

CreateEditTopicDialog.defaultProps = {
  topic: null,
}

CreateEditTopicDialog.propTypes = {
  topic: PropTypes.object,
  setReload: PropTypes.func.isRequired,
}

export default CreateEditTopicDialog
