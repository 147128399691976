import { store } from 'store'
import { push } from 'redux-first-history'

export function navigateByEvent (path, event, state) {
  if (event.ctrlKey || event.metaKey) {
    navigateToNewTab(path)
  } else {
    navigateTo(path, state)
  }
}

export function navigateTo (path, state = {}) {
  store.dispatch(push(path, { ...state, from: path }))
}

export function navigateToNewTab (path) {
  window.open(path, '_blank')
}
