import React from 'react'
import PropTypes from 'prop-types'
// import { useJsonAPIUpsert } from 'hooks'
import { Box, Tooltip, IconButton } from '@mui/material'
import { openDialog } from 'utils-em'
import { Edit, Trash, QuestionMark } from 'icons'
import CreateEditTopicDialog from './CreateEditTopicDialog'
import TaggedQuestionsDialog from './TaggedQuestionsDialog'

const TopicsTableActions = ({ topic, setReload }) => {
  const taggedQuestions = topic.questions
  return (
    <Box>
      <Tooltip title="Edit">
        <IconButton
          color="primary"
          onClick={() => openDialog((
            <CreateEditTopicDialog topic={topic} setReload={setReload} />
          ))}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          color="primary"
          variant="text"
          onClick={() => alert('Delete is not yet functional')}
        >
          <Trash />
        </IconButton>
      </Tooltip>
      {
        !!taggedQuestions && taggedQuestions.length > 0 && (
          <Tooltip title="Questions Tagged">
            <IconButton
              color="primary"
              variant="text"
              onClick={() => openDialog((
                <TaggedQuestionsDialog topic={topic} taggedQuestions={taggedQuestions} />
              ))}
            >
              <QuestionMark />
            </IconButton>
          </Tooltip>
        )
      }
    </Box>
  )
}

TopicsTableActions.propTypes = {
  topic: PropTypes.object.isRequired,
  setReload: PropTypes.func.isRequired,
}

export default TopicsTableActions
