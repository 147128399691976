import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { buildJsonApiOne } from 'utils-em'

/*
Best way to think of it:
nonPrimary: anyone who can reach the engagement page but isn't the primary (engagement.customerUserId),
this controls things like turning off call scheduling if you're a participant/PO

participantsOnly: primary OR participants (engagement.customerUserId || engagement.participants.id)
which is to say, lock down things like the relay
*/
const EngagementAccessControl = ({ children, nonPrimary, participantsOnly, engagementId }) => {
  const calculatedEngagementId = engagementId || useParams().engagementId
  if (!calculatedEngagementId) return null

  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', calculatedEngagementId))
  if (!engagement) return null

  const { id: loggedInUserId, roleType: loggedInUserRoleType } = useSelector(({ session }) => session)
  const isParticipant = engagement.participants?.some((p) => p.userId === loggedInUserId)
  const isPrimary = engagement.customerUserId === loggedInUserId || engagement.advisorId === loggedInUserId
  if (nonPrimary) {
    if ((!isParticipant && loggedInUserRoleType === 'rep') || isPrimary) return null
  } else if (participantsOnly) {
    if (!isParticipant && !isPrimary) return null
  } else if (!isPrimary) {
    return null
  }

  return children
}

EngagementAccessControl.defaultProps = {
  engagementId: null,
  nonPrimary: false,
  participantsOnly: false
}

EngagementAccessControl.propTypes = {
  children: PropTypes.node.isRequired,
  engagementId: PropTypes.number,
  nonPrimary: PropTypes.bool,
  participantsOnly: PropTypes.bool
}

export default EngagementAccessControl
