import React from 'react'
import { useDispatch } from 'react-redux'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Paper,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  ChevronDown,
  Clipboard,
  Download,
  FileWithText,
  Upload,
  MagnifyingGlass,
  Message,
  Note,
  WarningTriangle
} from 'icons'
import { useJsonAPIGetOne } from 'hooks'
import {
  AccountPage,
  OrganizationSelectorTwo,
  DownloadAccountAssetsDialog,
  IconTextCombo,
} from 'components'
import { JsonAPI } from 'store'
import {
  ACCOUNT_PAGE_TEXT_DATA,
  customAlert,
  convertFileToBase64,
  formatDate,
  httpResponseAlert,
  navigateTo,
  openDialog,
  closeDialog,
  confirmDialog,
  getOrgHasEverHadPublishedAccountPage
} from 'utils-em'

import { EditAccountPageContext } from './components/EditAccountPageContext'
import EngagementAssetTable from './components/EngagementAssetTable'
import SellingTipsForm from './components/SellingTipsForm'
import WhiskerTabForm from './components/WhiskerTabForm'
import TechStackForm from './components/TechStackForm'
import UploadLogoButton from './components/UploadLogoButton'
import SurveyAssignmentTabs from './components/SurveyAssignmentTabs'
import WeJustHeardForm from './components/WeJustHeardForm'
import ArtificialIntelligenceForm from './components/ArtificialIntelligenceForm'

const EditAccountPage = () => {
  const dispatch = useDispatch()
  const [saving, setSaving] = React.useState(false)
  const [missingInfoSections, setMissingInfoSections] = React.useState([]) // array of strings (sectionKey) for warning user after OBP upload if missing info
  const {
    id,
    loaded,
    MAX_LOGO_SIZE,
    accountPage,
    organization,
    setOrganization,
    setPublished,
    data,
    setData,
    logoFile,
    logoGcsObjectName,
    hasNoSurveyResults,
    publishedAccountPageForOrg,
    setLoaded,
    surveysLoaded,
    hasResultsForEverySurveyCategory,
    deleteLogo
  } = React.useContext(EditAccountPageContext)

  const isFormValid = loaded && !saving && organization && (logoFile ? logoFile.size <= MAX_LOGO_SIZE : true)
  // need to reload org to get tags if selection is changed
  // (org is set to the ES final_result which doesn't have tags)
  // its the admin app so whatever
  const { object: orgWithTags } = useJsonAPIGetOne(organization?.id || 0, 'organizations', ['tags'])
  const hasOrgTags = orgWithTags?.tags?.length > 0 || false
  const orgTagList = hasOrgTags && orgWithTags.tags.map((t) => t.name).join(', ')

  if (loaded && id && !accountPage) return <Typography>Account page not found</Typography>
  if (!loaded) return <Skeleton variant="rounded" height={160} />

  const saveAccountPage = async () => {
    const method = id ? JsonAPI.save : JsonAPI.create
    const logoBase64 = logoFile ? await convertFileToBase64(logoFile) : null
    const saveData = {
      id,
      type: 'accountPages',
      queryStringParams: {
        include: 'organization',
        'lazy[accountPages]': 'logo',
      },
      logo: deleteLogo ? null : logoBase64?.toString(),
      organizationId: organization.id,
      logoGcsObjectName: deleteLogo || logoBase64 ? null : logoGcsObjectName,
      data,
      jsonObjects: ['data']
    }
    let accountPageId = null
    setSaving(true)
    setMissingInfoSections([])
    saveOrganization(organization.id)
    try {
      const resp = await dispatch(method(saveData))
      if (resp.error) throw new Error(resp.error.message)
      accountPageId = parseInt(resp.data.data.id, 10)
      customAlert('Successfully saved.')
    } catch (error) {
      customAlert('Something went wrong. Please contact support.', true)
    }
    setSaving(false)
    return accountPageId
  }

  const saveOrganization = async (orgId) => {
    try {
      const resp = await dispatch(JsonAPI.save({
        id: orgId,
        type: 'organizations'
      }))
      if (resp.error) throw new Error(resp.error.message)
    } catch {
      customAlert('Something went wrong. Please contact support.', true)
    }
  }

  const setPublishedStatus = async (publishedStatus = false) => {
    try {
      setSaving(true)
      const resp = await dispatch(JsonAPI.save({
        id,
        type: 'accountPages',
        isPublished: publishedStatus
      }))
      if (resp.error) throw new Error(resp.error.message)
      customAlert(`Successfully ${publishedStatus ? 'published' : 'unpublished'}`)
      setPublished(publishedStatus)
    } catch (error) {
      customAlert('Something went wrong while publishing. Please contact support.', true)
    } finally {
      setSaving(false)
    }
  }

  const uploadFile = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    setLoaded(false)
    try {
      const result = await fetch(`${__API_HOST__}/v1/accountPages/extract`, {
        method: 'POST',
        credentials: 'include',
        body: formData
      })
      httpResponseAlert(result, {
        success: 'Uploaded organizational buying profile successfully!',
        failure: 'Upload of OBP failed!'
      })
      const json = await result.json()
      setData({
        ...data,
        sellingTips: json.data.sellingTips,
        propensityToBuy: json.data.propensityToBuy,
        techPreferences: json.data.techPreferences,
        buyingProcess: json.data.buyingProcess,
        techStack: json.data.techStack
      })

      // Determine if any of the sections are missing info
      // to warn user that upload missed a few spots
      const newMissingInfoSections = []
      if (!json.data.sellingTips.length) {
        newMissingInfoSections.push('sellingTips')
      }
      ['propensityToBuy', 'techPreferences', 'buyingProcess'].forEach((sectionKey) => {
        Object.keys(json.data[sectionKey]).forEach((subsection) => {
          if (!json.data[sectionKey][subsection].title || !json.data[sectionKey][subsection].paragraph || !json.data[sectionKey][subsection].quote) {
            newMissingInfoSections.push(sectionKey)
          }
        })
      })
      if (!json.data.techStack.length) {
        newMissingInfoSections.push('techStack')
      }
      setMissingInfoSections(newMissingInfoSections)
    } catch {
      customAlert('An unknown error occurred while uploading. Please contact support.', true)
    }
    setLoaded(true)
  }

  const openPreview = async (accountPageId) => {
    // <AccountPage> preview element needs an `id` for the record to view,
    // so get the record id from the save response, in case we are creating
    // a new Account Page
    openDialog(
      <Dialog open fullWidth maxWidth="xl" onClose={() => closeDialog()}>
        <DialogTitle>
          {`[Preview] ${organization.name} - ${orgTagList}`}
        </DialogTitle>
        <DialogContent>
          <AccountPage accountPageId={accountPageId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const openDownloadAccountAssetsDialog = () => {
    openDialog(<DownloadAccountAssetsDialog accountName={organization.name} organizationId={organization.id} />)
  }

  const togglePublishStatus = async () => {
    const alreadyHasPublishedAccountPage = !accountPage.isPublished && publishedAccountPageForOrg && publishedAccountPageForOrg.id !== accountPage.id
    const incompleteSurveyResponses = !accountPage.isPublished && surveysLoaded && !hasResultsForEverySurveyCategory
    const publishingForOrgFirstTime = !accountPage.isPublished && await getOrgHasEverHadPublishedAccountPage(organization.id)

    if (alreadyHasPublishedAccountPage || incompleteSurveyResponses || publishingForOrgFirstTime) {
      confirmDialog({
        title: 'Publication notices',
        description: (
          <>
            {alreadyHasPublishedAccountPage && (
              <IconTextCombo
                icon={<FileWithText />}
                iconSize="medium"
                typography="body1"
                text="An account page for this organization is already published. You may publish this one, but doing so will unpublish the existing one."
                sx={{ mb: 2 }}
              />
            )}
            {incompleteSurveyResponses && (
              <IconTextCombo
                icon={<Clipboard />}
                iconSize="medium"
                typography="body1"
                text="Some survey questions do not have any responses. Survey questions without a response will not show aggregate survey results on the account page."
                sx={{ mb: 2 }}
              />
            )}
            {publishingForOrgFirstTime && (
              <IconTextCombo
                icon={<Message />}
                iconSize="medium"
                typography="body1"
                text="This will be the first time this organization has had an account page published. Customer users who have saved this account will be notified."
                sx={{ mb: 2 }}
              />
            )}
          </>
        ),
        actions: [
          {
            name: 'Publish',
            action: () => setPublishedStatus(true)
          }
        ],
        size: 'sm'
      })
      return
    }

    setPublishedStatus(!accountPage.isPublished)
  }

  if (!loaded) return <Skeleton variant="rounded" height={160} />

  const tabs = [
    {
      tabKey: 'weJustHeard',
      component: <WeJustHeardForm />
    },
    {
      tabKey: 'sellingTips',
      component: <SellingTipsForm />
    },
    {
      tabKey: 'artificialIntelligence',
      component: <ArtificialIntelligenceForm />
    },
    {
      tabKey: 'propensityToBuy',
      component: <WhiskerTabForm tabKey="propensityToBuy" />
    },
    {
      tabKey: 'techPreferences',
      component: <WhiskerTabForm tabKey="techPreferences" />
    },
    {
      tabKey: 'buyingProcess',
      component: <WhiskerTabForm tabKey="buyingProcess" />
    },
    {
      tabKey: 'techStack',
      component: <TechStackForm />
    }
  ]

  return (
    <Paper elevation={3} sx={{ m: 3, p: 3, mt: 8, width: '100%' }}>
      <Stack spacing={2}>
        <Typography variant="h4">{`${id ? 'Edit' : 'Create'} account page ${organization ? ` - ${organization.name}` : ''}`}</Typography>
        <Stack direction="row" spacing={2}>
          <label htmlFor="obp-upload">
            <Button
              variant="outlined"
              size="large"
              color="primary"
              component="span"
              startIcon={<Upload />}
            >
              Auto-fill with OBP upload
              <small>&nbsp;(.HTML, .ZIP)</small>
            </Button>
            <input
              id="obp-upload"
              accept="text/html, application/zip"
              type="file"
              onChange={(e) => uploadFile(e.target.files[0])}
              style={{ display: 'none' }}
            />
          </label>
          <LoadingButton
            variant="outlined"
            size="large"
            color="primary"
            component="span"
            startIcon={<Download />}
            disabled={!organization}
            onClick={openDownloadAccountAssetsDialog}
          >
            Download all files
            <small>&nbsp;(Recordings, Transcripts, Notes, Surveys)</small>
          </LoadingButton>
        </Stack>
        <OrganizationSelectorTwo
          textFieldParams={{
            variant: 'standard',
            label: 'Account',
            placeholder: 'Account',
            required: true
          }}
          defaultValue={organization}
          noOptionsText="Enter account name"
          onSelected={(org) => setOrganization(org)}
        />
        <Typography mt={2}>
          {`Published: ${accountPage?.isPublished ? 'Yes' : 'No'} (last published on: ${accountPage?.publishDate ? formatDate(accountPage.publishDate) : 'never'})`}
        </Typography>
        <Typography mt={2}>{`Industries: ${orgTagList} (set from Master Org List)`}</Typography>
        <UploadLogoButton sx={{ pb: 1 }} />
      </Stack>
      {hasNoSurveyResults && (
        <Alert severity="error" mb={1} mt={1}>
          This organization has no surveys submitted
        </Alert>
      )}
      {surveysLoaded && !hasResultsForEverySurveyCategory && (
        <Alert severity="error" mb={1} mt={1}>
          Some survey questions do not have any responses
        </Alert>
      )}
      {tabs.map((section) => (
        <Accordion key={section.tabKey} sx={{ bgcolor: 'white' }}>
          <AccordionSummary expandIcon={<ChevronDown />}>
            <Typography variant="h6">{ACCOUNT_PAGE_TEXT_DATA[section.tabKey].title}</Typography>
            {missingInfoSections.indexOf(section.tabKey) !== -1 && (
              <Box sx={{ ml: 2 }}>
                <Alert
                  severity="warning"
                  icon={<WarningTriangle style={{ color: 'warning.dark' }} />}
                  sx={{ pt: 0, pb: 0 }}
                >
                  Missing fields after OBP ingestion!
                </Alert>
              </Box>
            )}
          </AccordionSummary>
          <AccordionDetails sx={{ m: 2 }}>{section.component}</AccordionDetails>
        </Accordion>
      ))}
      <Accordion sx={{ bgcolor: 'white', mt: 3 }}>
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="h6">Surveys</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ m: 2 }}>
          <SurveyAssignmentTabs organizationId={organization?.id} />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ bgcolor: 'white', mt: 3 }}>
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography variant="h6">Engagement Assets</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ m: 2 }}>
          <EngagementAssetTable organizationId={organization?.id} />
        </AccordionDetails>
      </Accordion>

      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <Button onClick={() => navigateTo('/admin/account-page-management')}>Close</Button>
        <Button
          variant="contained"
          disabled={!isFormValid}
          onClick={async () => {
            const accountPageId = await saveAccountPage()
            !id && navigateTo(`/admin/account-page-management/edit/${accountPageId}`)
          }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          disabled={!isFormValid}
          onClick={async () => {
            const accountPageId = await saveAccountPage()
            !id && navigateTo(`/admin/account-page-management/edit/${accountPageId}`)
            openPreview(accountPageId)
          }}
          startIcon={<MagnifyingGlass />}
        >
          Save &amp; Preview
        </Button>
        <Button
          variant={accountPage?.isPublished ? 'outlined' : 'contained'}
          disabled={!id || !isFormValid || !surveysLoaded}
          onClick={togglePublishStatus}
          startIcon={(
            <Note sx={{
              '& path':
                { stroke: accountPage?.isPublished ? undefined : 'white' }
            }}
            />
          )}
        >
          {accountPage?.isPublished ? 'Unpublish' : 'Publish'}
        </Button>
      </Stack>
    </Paper>
  )
}

export default EditAccountPage
