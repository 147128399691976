import React from 'react'
import {
  Box,
} from '@mui/material'

import {
  DayColumn,
  DayOfWeekHeader,
  TimeLabels,
  WeekSelectionControls
} from '../index'
import {
  CalendarTimeSelectorContext
} from '../../CalendarTimeSelectorContext'

const Calendar = () => {
  const {
    daysToShow
  } = React.useContext(CalendarTimeSelectorContext)

  React.useEffect(() => {
    // Calendar should set scroll so 8 AM slot is the first one visible by default
    const timeslotSelector = document.getElementById('timeslot-selector')
    const eightAmSlot = document.getElementById('8 AM')
    timeslotSelector.scrollTop = eightAmSlot.offsetTop + 5
  }, [])

  return (
    <>
      <WeekSelectionControls />
      <DayOfWeekHeader />
      <Box
        id="timeslot-selector"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '400px',
          overflowY: 'scroll',
          pt: 1,
          mt: -2
        }}
      >
        <TimeLabels />
        {daysToShow.map((date) => (
          <DayColumn
            key={date}
            date={date}
          />
        ))}
      </Box>
    </>
  )
}

export default Calendar
