export const getUserStatusesFilter = (chosenOptions) => {
  const userStatuses = [
    {
      label: 'Active',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'eq', val: 'active' }
        ]
      }
    },
    {
      label: 'Not Invited',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'eq', val: 'not_invited' }
        ]
      }
    },
    {
      label: 'Invited',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'in_', val: ['invited'] }
        ]
      }
    },
    {
      label: 'Sign-up Incomplete',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          {
            name: 'status',
            op: 'in_',
            val: [
              'invited', // `invited` status is purposely duplicated here and in the "Invited" option
              'sign_up_incomplete_needs_contact_info',
              'sign_up_incomplete_needs_account_info',
              'invite_accepted'
            ]
          }
        ]
      }
    },
    {
      label: 'Idle',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'eq', val: 'idle' }
        ]
      }
    },
    {
      label: 'Deactivated',
      filter: { name: 'isActive', op: 'eq', val: 0 }
    }
  ]
  let filters = []
  if (chosenOptions.length > 0) {
    const topLevelOrs = chosenOptions.map((cusf) => {
      const matchingUserStatus = userStatuses.find((us) => us.label === cusf.value)
      return matchingUserStatus.filter
    })
    filters = {
      or: topLevelOrs
    }
  }
  return filters
}

export default getUserStatusesFilter
