import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material'
import { useJsonAPIUpsert } from 'hooks'

import {
  closeDialog,
} from 'utils-em'
import ExpertiseSelector from './ExpertiseSelector'

const QuestionExpertiseDialog = ({ question, reload }) => {
  const { upsert, isLoading } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const [selectedExpertise, setSelectedExpertise] = React.useState(question.expertise || null)

  const saveQuestion = async () => {
    await upsert({
      type: 'questions',
      id: question.id,
      expertiseId: selectedExpertise?.id || null,
    })
    reload.setReload(reload.value + 1)
    closeDialog()
  }

  return (
    <Dialog
      open
      onClose={closeDialog}
      size="xl"
    >
      <DialogTitle>Select Area of Expertise</DialogTitle>
      <DialogContent sx={{ minWidth: '600px' }}>
        <Box p={2}>
          <ExpertiseSelector
            onChange={setSelectedExpertise}
            initialExpertise={question.expertise}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>Cancel</Button>
        <Button variant="text" disabled={isLoading} onClick={saveQuestion}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

QuestionExpertiseDialog.propTypes = {
  question: PropTypes.object.isRequired,
  reload: PropTypes.object.isRequired,
}

export default QuestionExpertiseDialog
