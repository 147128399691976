import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Link,
  Toolbar
} from '@mui/material'

import {
  useEmissaryTheme
} from 'hooks'
import {
  Logo,
} from 'icons'
import {
  navigateTo,
} from 'utils-em'

import HeaderMenu from './component/HeaderMenu'

const Header = ({
  links,
  menuItems,
  menuItemsVisible,
  relativePosition
}) => {
  const { pathname } = useLocation()
  const ref = React.useRef()
  const { theme, isSmOrSmaller, isMdOrSmaller } = useEmissaryTheme()

  const HomeLogo = () => {
    const logoSize = isSmOrSmaller ? '32px' : '40px'
    return (
      <Box sx={{ display: 'flex' }}>
        <IconButton
          id="emissary-top-left-icon-button"
          disableRipple
          onClick={() => links && links.length > 0 && navigateTo(links[0].path)}
          sx={{
            p: 0,
            '&:hover': {
              bgcolor: 'transparent',
              cursor: links.length > 0 ? 'pointer' : 'default'
            }
          }}
        >
          <Logo sx={{ width: logoSize, height: logoSize }} />
        </IconButton>
      </Box>
    )
  }

  const Links = ({ ...rest }) => (
    <Box {...rest}>
      {links.map(({ text, icon, path, validPaths }) => {
        const isSelected = validPaths ? validPaths.some((validPath) => pathname.startsWith(validPath)) : pathname === path
        const color = isSelected ? theme.palette.primary.main : theme.palette.brand.darkNavy
        return (
          <Box
            key={text}
            sx={{
              display: 'flex',
              width: isSmOrSmaller ? '100%' : undefined,
              flexDirection: isMdOrSmaller ? 'column' : 'row',
              gap: isMdOrSmaller ? 0 : 2,
              alightContent: 'center',
              px: isSmOrSmaller ? 0 : 2,
              py: isSmOrSmaller ? 0 : 1,
              color,
              '&:hover': { color: theme.palette.primary.main },
            }}
            onClick={() => navigateTo(path)}
          >
            <IconButton
              fontSize="inherit"
              color="inherit"
              sx={{
                bgcolor: isSelected && !isSmOrSmaller ? theme.palette.primary.lightest : 'transparent',
                p: 0.5
              }}
              disableRipple
            >
              {icon}
            </IconButton>
            <Link
              variant={isMdOrSmaller ? 'tinyBold' : 'buttonL'}
              color="inherit"
              underline="none"
              sx={{
                textAlign: isMdOrSmaller ? 'center' : undefined,
                alignContent: 'center',
              }}
            >
              {text}
            </Link>
          </Box>
        )
      })}
    </Box>
  )

  // only for xs screens
  const TopToolbar = () => (
    <Toolbar sx={{ height: '56px', bgcolor: 'brand.lightNavy' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mx: 2 }}>
        <HomeLogo />
        <HeaderMenu
          menuItemsVisible={menuItemsVisible}
          menuItems={menuItems}
          sx={{ marginLeft: 'auto' }}
        />
      </Box>
    </Toolbar>
  )

  return (
    <Box id="header" ref={ref}>
      <AppBar
        position={relativePosition ? 'relative' : 'fixed'}
        elevation={0}
        sx={{
          background: theme.header.color,
          zIndex: theme.header.zIndex,
          bgColor: 'neutral.white'
        }}
      >
        {isSmOrSmaller && <TopToolbar />}
        <Toolbar sx={{
          p: 0,
          height: !isSmOrSmaller ? theme.header.height : theme.header.bottomHeight,
          mx: isSmOrSmaller ? 2 : 5
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%' }}>
              {!isSmOrSmaller && <HomeLogo />}
              <Links sx={{ display: 'flex', gap: 1, width: '100%' }} />
            </Box>
            {!isSmOrSmaller && (
              <HeaderMenu
                menuItemsVisible={menuItemsVisible}
                menuItems={menuItems}
              />
            )}
          </Box>
        </Toolbar>
        <Divider sx={{ ...theme.header.divider }} />
      </AppBar>
    </Box>
  )
}

Header.defaultProps = {
  links: [],
  menuItems: [],
  menuItemsVisible: {
    stealth: true,
    notifications: true,
    menu: true
  },
  relativePosition: false,
}

Header.propTypes = {
  menuItemsVisible: PropTypes.shape({
    stealth: PropTypes.bool,
    notifications: PropTypes.bool,
    menu: PropTypes.bool
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    validPaths: PropTypes.arrayOf(PropTypes.string)
  })),
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired
  })),
  relativePosition: PropTypes.bool
}

export default Header
