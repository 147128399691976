import React from 'react'

import { useHistory } from 'react-router-dom'
import {
  Box,
  Button
} from '@mui/material'
import PropTypes from 'prop-types'
import { useEmissaryTheme } from 'hooks'
import { Chatbot, ChatbotOrganizationSelector } from 'components'
import { ArrowLeft } from 'icons'

const ChatbotPage = ({ promptId }) => {
  const history = useHistory()
  const { contentHeight } = useEmissaryTheme()
  const [organization, setOrganization] = React.useState(null)
  const goBack = () => {
    if (organization) {
      setOrganization(null)
    } else {
      history.goBack()
    }
  }

  return (
    <Box id="chatbot-page" sx={{ display: 'flex', flexDirection: 'column', minHeight: contentHeight }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Button
          variant="text"
          startIcon={<ArrowLeft />}
          onClick={goBack}
        >
          {organization ? 'Back to account list' : 'Back'}
        </Button>
      </Box>
      {organization === null ? <ChatbotOrganizationSelector setOrganization={setOrganization} />
        : (
          <Chatbot
            inKnowledgePage={false}
            filters={{
              organizationId: organization?.id
            }}
            promptId={promptId}
          />
          )}
    </Box>
  )
}

ChatbotPage.defaultProps = {
  promptId: null
}

ChatbotPage.propTypes = {
  promptId: PropTypes.number
}

export default ChatbotPage
