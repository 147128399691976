import { connect } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'

import { buildJsonApiOrdered } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'
import ContractsList from './ContractsList'

const mapStateToProps = ({ data }) => ({
  contracts: buildJsonApiOrdered(data, 'contracts').map((contract) => (
    {
      ...contract,
      endDate: moment.tz(contract.endDate, 'UTC'),
      extensionDate: moment.tz(contract.extensionDate, 'UTC')
    }
  )),
  totalCount: get(data, 'meta.contracts.count', 0)
})

const mapDispatchToProps = {
  getAllContracts: (customerId, size, page) => JsonAPI.getAll({
    type: 'contracts',
    queryStringParams: {
      include: [
        'divisions',
        'divisions.customerUsers'
      ].join(','),
      'filter[customerId]': customerId,
      'page[number]': page,
      'page[size]': size,
      'fields[divisions]': 'id,name,customerUsers,displayName',
      'fields[customerUsers]': 'roleType,fullName',
      sort: '-startDate'
    }
  }),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractsList)
