/**
 * Increment a time object by (30 * multiplier) minutes.
 *
 * @param {Object} time
 * @param {Number} multiplier
 *
 * @returns {Object} time
 */
const incrementByHalfHour = (time, multiplier) => {
  let hoursToAdd = Math.floor(multiplier / 2)
  const minutesToAdd = multiplier % 2 * 30

  let minute = time.minute + minutesToAdd
  if (minute === 60) {
    hoursToAdd += 1
    minute = 0
  }

  let hour = time.hour + hoursToAdd

  const isBeforeNoon = Math.floor(hour / 12) % 2 === 0 ? time.isBeforeNoon : !time.isBeforeNoon

  if (hour > 11) {
    hour %= 12
  }

  if (hour === 0) {
    hour = 12
  }

  return { hour, minute, isBeforeNoon }
}

/**
 * Format a time object into "00:00 am/pm" format
 *
 * @param {Object} time
 *
 * @returns {String}
 */
const formatTimes = (time) => `${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')} \
  ${time.isBeforeNoon ? 'am' : 'pm'}`

/**
 * Create x number of formatted times, starting with "firstTime" and incrementing by 30 min
 *
 * @param {Object} firstTime
 * @param {Number} numberOfTimes
 *
 * @returns {Array}
 */
const generateTimesWithHalfHourInterval = (firstTime, numberOfTimes) => Array(numberOfTimes)
  .fill(firstTime)
  .map(incrementByHalfHour)
  .map(formatTimes)

export default generateTimesWithHalfHourInterval
