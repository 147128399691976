import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Emissary (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.4999 17.0008C37.2999 15.9008 35.3999 15.9008 34.1999 17.0008L19.6999 30.0008C18.4999 31.1008 18.4999 32.8008 19.6999 33.9008C20.2999 34.4008 21.0999 34.7008 21.8999 34.7008C22.6999 34.7008 23.4999 34.4008 24.0999 33.9008L29.2999 29.2008C29.6999 30.1008 29.8999 31.0008 29.8999 32.0008C29.8999 35.9008 26.2999 39.2008 21.8999 39.2008C17.6999 39.2008 14.2999 36.4008 13.8999 32.8008V32.7008C13.8999 32.5008 13.8999 32.3008 13.8999 32.1008V32.2008C13.8999 32.2008 13.8999 32.2008 13.8999 32.1008C13.8999 32.0008 13.8999 32.0008 13.8999 31.9008C13.8999 31.7008 13.8999 31.6008 13.7999 31.4008C13.7999 31.6008 13.8999 31.8008 13.8999 32.0008C13.8999 28.1008 17.4999 24.8008 21.8999 24.8008C23.5999 24.8008 24.9999 23.6008 24.9999 22.1008C24.9999 20.6008 23.5999 19.4008 21.8999 19.4008C18.6999 19.4008 16.1999 17.1008 16.1999 14.3008C16.1999 11.5008 18.7999 9.20078 21.8999 9.20078C23.2999 9.20078 24.5999 9.60078 25.5999 10.4008C26.1999 11.1008 27.0999 11.6008 28.0999 11.6008C29.7999 11.6008 31.1999 10.4008 31.1999 8.90078C31.1999 8.20078 30.8999 7.50078 30.2999 6.90078C28.0999 4.90078 25.0999 3.80078 21.8999 3.80078C15.3999 3.80078 10.0999 8.50078 10.0999 14.4008C10.0999 17.3008 11.4999 20.0008 13.6999 21.9008C10.1999 24.2008 7.8999 27.9008 7.8999 32.1008C7.8999 31.9008 7.8999 31.7008 7.9999 31.5008C7.9999 31.7008 7.8999 31.8008 7.8999 32.0008C7.8999 32.2008 7.8999 32.4008 7.9999 32.6008V32.7008C7.8999 32.5008 7.8999 32.3008 7.8999 32.1008C7.8999 39.1008 14.1999 44.7008 21.9999 44.7008C29.7999 44.7008 36.0999 39.0008 36.0999 32.1008C36.0999 29.7008 35.2999 27.3008 33.8999 25.3008L38.6999 21.1008C39.5999 19.8008 39.5999 18.0008 38.4999 17.0008Z" fill="white" />
      </svg>
    </SvgIcon>
  )
}
