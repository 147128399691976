import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Trash (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M6.75 7.75L7.19 17.213C7.27987 18.224 8.14258 19 9.18 19H14.82C15.8574 19 16.7201 18.224 16.81 17.213L17.25 7.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 7V6.45455C9.5 5.5 10.4949 5 11.7222 5H12.2778C13.5051 5 14.5 5.5 14.5 6.45455V7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 7.75H19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.1777 10.9531L10.2615 15.7524" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.8223 10.9531L13.7385 15.7524" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
