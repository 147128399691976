import React from 'react'
import { compose } from 'redux'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  useFeatureFlag,
  useIsStealth
} from 'hooks'

import {
  Briefcase,
  Book,
  Bot,
  Clipboard,
  Customer,
  Lightbulb,
  Phone,
  Users
} from 'icons'
import { buildJsonApiOne } from 'utils-em'
import { AdminHeader } from 'components'

import AdminRoutes from '../routes'
import ActiveDialogStack from '../../../components/ActiveDialogStack'
import GlobalAlertStack from '../../../components/GlobalAlertStack'
import NavigationSidebar from '../../../components/NavigationSidebar'
import withAuthorization from '../../../enhancers/withAuthorization'

const AdminLayout = () => {
  const stealthMode = useIsStealth()
  const url = useSelector(({ router }) => router.location.pathname)
  const promptFlagActive = useFeatureFlag('prompt_admin')

  const customerRouteParam = url.match(/\/admin\/customers\/(\d+)/i)
  const customerId = customerRouteParam?.[1] || null
  const customer = useSelector(({ data }) => buildJsonApiOne(data, 'customers', customerId))

  const links = [
    {
      text: 'Clients',
      href: '/admin/customers',
      icon: <Customer />
    },
    {
      text: 'User Search',
      href: '/admin/users',
      icon: <Users />
    },
    {
      text: 'Advisor Role Search',
      href: '/admin/advisors',
      icon: <Briefcase />
    },
    {
      text: 'Engagement Search',
      href: '/admin/engagements',
      icon: <Phone />
    },
    {
      text: 'Content Management',
      href: '/admin/content-management',
      icon: <Book />
    },
    {
      text: 'Surveys',
      href: '/admin/surveys',
      icon: <Clipboard />,
    },
    {
      text: 'Knowledge Pages',
      href: '/admin/account-page-management',
      icon: <Lightbulb />
    },
    {
      text: 'Prompts',
      href: '/admin/prompts',
      icon: <Bot />,
      hidden: !promptFlagActive
    }]

  return (
    <>
      <AdminHeader
        label={customerId ? (customer?.name || '...') : 'Main Dashboard'}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 80px)',
          mt: 12
        }}
      >
        <ActiveDialogStack />
        <GlobalAlertStack />
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            px: 3,
            py: 3
          }}
        >
          {!/^\/admin\/customers\/\d+$/.test(url) && (
            // don't render navbar if at customer route
            <NavigationSidebar links={links} />
          )}
          { !stealthMode && (
            <AdminRoutes />
          )}
          {stealthMode && (
            <Box sx={{ mx: 5, my: 10 }}>
              Your browser is in stealth mode (probably from another tab), while you are in stealth mode you cannot access admin functions and other pages may not function properly
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

const applyHOC = compose(
  withAuthorization
)

export default applyHOC(AdminLayout)
