import React from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Switch
} from '@mui/material'

const UserStatusFilter = ({ onChange, currentState, initialSelectedFilters }) => {
  const userStatuses = [
    {
      label: 'Active',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'eq', val: 'active' }
        ]
      }
    },
    {
      label: 'Not Invited',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'eq', val: 'not_invited' }
        ]
      }
    },
    {
      label: 'Invited',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'in_', val: ['invited'] }
        ]
      }
    },
    {
      label: 'Sign-up Incomplete',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          {
            name: 'status',
            op: 'in_',
            val: [
              'invited', // `invited` status is purposely duplicated here and in the "Invited" option
              'sign_up_incomplete_needs_contact_info',
              'sign_up_incomplete_needs_account_info',
              'invite_accepted'
            ]
          }
        ]
      }
    },
    {
      label: 'Idle',
      filter: {
        and: [
          { name: 'isActive', op: 'eq', val: 1 },
          { name: 'status', op: 'eq', val: 'idle' }
        ]
      }
    },
    {
      label: 'Deactivated',
      filter: { name: 'isActive', op: 'eq', val: 0 },
      switch: true
    }
  ]
  const [currentUserStatusesFilters, setCurrentUserStatusesFilters] = React.useState(currentState || initialSelectedFilters)

  const filterResults = (updateFilters) => {
    let filters = null
    if (updateFilters.length) {
      const topLevelOrs = updateFilters.map((customerUserStatusFilter) => {
        const matchingUserStatus = userStatuses.find((us) => us.label === customerUserStatusFilter.value)
        return matchingUserStatus.filter
      })
      filters = {
        or: topLevelOrs
      }
    }
    onChange('userStatus', filters, updateFilters)
  }

  const onOptionSelected = (status) => {
    // add if not in array, remove if in array
    const updateFilters = currentUserStatusesFilters.some((item) => item.value === status) ? currentUserStatusesFilters.filter((item) => item.value !== status)
      : [...currentUserStatusesFilters, { value: status, name: status }]
    setCurrentUserStatusesFilters(updateFilters)
    filterResults(updateFilters)
  }

  return (
    <>
      <Typography variant="captionBold">
        Status
      </Typography>
      <FormGroup sx={{ mb: 2 }}>
        {userStatuses.filter((a) => !a.switch).map((status) => (
          <FormControlLabel
            key={status.label}
            control={(
              <Checkbox
                checked={currentUserStatusesFilters.some((selected) => selected.value === status.label)}
                onChange={() => onOptionSelected(status.label)}
              />
            )}
            label={status.label}
          />
        ))}
      </FormGroup>
      <Typography variant="captionBold">
        Deactivated users
      </Typography>
      <FormGroup sx={{ mt: 1 }}>
        <FormControlLabel
          control={(
            <Switch
              checked={currentUserStatusesFilters.some((selected) => selected.value === 'Deactivated')}
              onChange={() => onOptionSelected('Deactivated')}
            />
          )}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            ml: 0,
          }}
          labelPlacement="start"
          label="Show deactivated users"
        />
      </FormGroup>
    </>
  )
}

UserStatusFilter.defaultProps = {
  initialSelectedFilters: [],
  currentState: []
}

UserStatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.array,
  initialSelectedFilters: PropTypes.array,
}

export default UserStatusFilter
