import PropTypes from 'prop-types'
import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'

const ContractUsageDetailDialog = ({
  charges,
  handleClose
}) => (

  <Dialog
    open
    maxWidth="lg"
    scroll="paper"
    fullWidth
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
  >
    <DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Unit Count</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Divisions</TableCell>
            <TableCell>Source Object Type</TableCell>
            <TableCell>Source Object ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { charges.map((csku) => (
            <TableRow key={csku.id}>
              <TableCell>{csku.sku.name}</TableCell>
              <TableCell>{csku.unitCount}</TableCell>
              <TableCell>
                {csku.description}
                {csku.isRefund ? <b>(REFUNDED)</b> : null}
              </TableCell>
              <TableCell>{csku.divisions.map((d) => d.displayName).join(', ')}</TableCell>
              <TableCell>{csku.sourceObjectType}</TableCell>
              <TableCell>{csku.sourceObjectId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

ContractUsageDetailDialog.propTypes = {
  charges: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ContractUsageDetailDialog
