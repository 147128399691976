import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Tooltip
} from '@mui/material'
import { Lightbulb } from 'icons'

const PublishedInsightAvailable = ({ tooltipEnabled, ...rest }) => (
  <Tooltip title={tooltipEnabled ? 'Published insight available' : ''}>
    <Box {...rest}>
      <Avatar
        sx={{
          ml: 1,
          bgcolor: 'brand.paleTeal',
          color: 'brand.darkTeal',
          height: '24px',
          width: '24px'
        }}
      >
        <Lightbulb sx={{ height: '16px', width: '16px' }} />
      </Avatar>
    </Box>
  </Tooltip>
)

PublishedInsightAvailable.propTypes = {
  tooltipEnabled: PropTypes.bool
}

PublishedInsightAvailable.defaultProps = {
  tooltipEnabled: false
}

export default PublishedInsightAvailable
