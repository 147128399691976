import React from 'react'
import { Box } from '@mui/material'
import { Tabs } from 'components'

import SurveyData from './components/SurveyData'

const ChatbotSandbox = () => (
  <Box sx={(theme) => ({ ...theme.pageContentSx, width: '100%' })}>
    <Box sx={{ py: 4, height: '100%' }}>
      <Box typography="h1" variant="h1" sx={{ p: 2 }}>
        Chatbot Sandbox
      </Box>
      <Tabs
        tabs={[
          { name: 'Survey Data', component: <SurveyData />, hash: '#survey-data' },
        ]}
        panelProps={{ minHeight: '100%' }}
        sx={{ height: '100%' }}
      />
    </Box>
  </Box>
)

export default ChatbotSandbox
