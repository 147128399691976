import React from 'react'
import PropTypes from 'prop-types'
import { Box, Chip, TextField, Typography } from '@mui/material'
import { useDebounce, ACCOUNT_PAGE_DATA_TEMPLATE, ACCOUNT_PAGE_TEXT_DATA } from 'utils-em'

import { EditAccountPageContext } from '../EditAccountPageContext'

const WhiskerTabForm = ({ tabKey }) => {
  const {
    loaded,
    data,
    setData,
    surveyResults,
    surveysLoaded,
    initialData,
  } = React.useContext(EditAccountPageContext)

  const [tabData, setTabData] = React.useState(null)
  const initialTabData = loaded && initialData?.[tabKey]
  const debouncedTabData = useDebounce(tabData, 500)

  const handleChange = (sectionKey, fieldKey, value) => {
    const updatedTabData = { ...tabData }
    updatedTabData[sectionKey][fieldKey] = value
    setTabData(updatedTabData)
  }

  // set the tab data on load
  React.useEffect(() => {
    if (!loaded) return null
    if (!tabData) setTabData({ ...ACCOUNT_PAGE_DATA_TEMPLATE[tabKey] })
    if (loaded && data && data[tabKey]) {
      setTabData(data[tabKey])
    }
  }, [loaded, data])

  // update data in the state when tab data changes
  React.useEffect(() => {
    setData({ ...data, [tabKey]: tabData })
  }, [debouncedTabData])

  const renderSection = (sectionKey) => {
    if (!sectionKey || !ACCOUNT_PAGE_TEXT_DATA[sectionKey]) return null
    const sectionTitle = ACCOUNT_PAGE_TEXT_DATA[sectionKey].title
    const sectionType = ACCOUNT_PAGE_TEXT_DATA[sectionKey].sectionType
    const templateKeys = ACCOUNT_PAGE_DATA_TEMPLATE[tabKey][sectionKey]

    const renderField = (fieldKey, numLines = 1) => {
      // see if template has this field
      if (!Object.keys(templateKeys).includes(fieldKey)) return null

      const initialValue = initialTabData?.[sectionKey]?.[fieldKey] || ''
      const value = tabData[sectionKey][fieldKey]
      const isChanged = value !== initialValue

      return (
        <TextField
          label={fieldKey.charAt(0).toUpperCase() + fieldKey.slice(1)} // title case
          value={value || ''}
          onChange={(e) => handleChange(sectionKey, fieldKey, e.target.value)}
          fullWidth
          size="small"
          variant="outlined"
          multiline={numLines > 1}
          rows={numLines}
          InputProps={{ sx: isChanged ? { bgcolor: 'rgb(225, 225, 255)' } : undefined }}
          sx={{ mt: 2 }}
        />
      )
    }

    const renderSurveyChips = () => {
      if (!surveysLoaded) return null
      if (sectionType !== 'whisker') return null
      const surveyResult = surveyResults?.[sectionKey]
      const chipProps = { size: 'small', color: 'primary', sx: { ml: 1 } }
      if (!surveyResult) return <Chip label="no survey results" {...chipProps} color="error" />
      return (
        <Box display="flex">
          <Chip label={`min: ${surveyResult.min}`} {...chipProps} />
          <Chip label={`avg: ${surveyResult.avg}`} {...chipProps} />
          <Chip label={`max: ${surveyResult.max}`} {...chipProps} />
        </Box>
      )
    }

    return (
      <Box key={sectionKey} sx={{ mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{sectionTitle}</Typography>
          {renderSurveyChips()}
        </Box>
        {sectionType === 'string' ? (
          <>
            {renderField('text', 4)}
          </>
        ) : (
          <>
            {renderField('title')}
            {renderField('paragraph', 2)}
            {renderField('quote', 2)}
          </>
        )}
      </Box>
    )
  }

  if (!tabData || !loaded) return null
  return (
    <>
      {Object.keys(tabData).map((sectionKey) => renderSection(sectionKey))}
    </>
  )
}

WhiskerTabForm.propTypes = {
  tabKey: PropTypes.string.isRequired
}

export default WhiskerTabForm
