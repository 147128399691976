import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Network (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M19.4997 15.332H16.1663C15.7061 15.332 15.333 15.7051 15.333 16.1654V19.4987C15.333 19.9589 15.7061 20.332 16.1663 20.332H19.4997C19.9599 20.332 20.333 19.9589 20.333 19.4987V16.1654C20.333 15.7051 19.9599 15.332 19.4997 15.332Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.83366 15.332H4.50033C4.04009 15.332 3.66699 15.7051 3.66699 16.1654V19.4987C3.66699 19.9589 4.04009 20.332 4.50033 20.332H7.83366C8.2939 20.332 8.66699 19.9589 8.66699 19.4987V16.1654C8.66699 15.7051 8.2939 15.332 7.83366 15.332Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.6667 3.66797H10.3333C9.8731 3.66797 9.5 4.04106 9.5 4.5013V7.83464C9.5 8.29487 9.8731 8.66797 10.3333 8.66797H13.6667C14.1269 8.66797 14.5 8.29487 14.5 7.83464V4.5013C14.5 4.04106 14.1269 3.66797 13.6667 3.66797Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.16699 15.3333V12.8333C6.16699 12.6123 6.25479 12.4004 6.41107 12.2441C6.56735 12.0878 6.77931 12 7.00033 12H17.0003C17.2213 12 17.4333 12.0878 17.5896 12.2441C17.7459 12.4004 17.8337 12.6123 17.8337 12.8333V15.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 12.0013V8.66797" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
