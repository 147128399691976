/* eslint-disable react/no-children-prop */
import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from '@mui/material/CssBaseline'
import { adminTheme } from 'themes'
import { store, history } from 'store'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import SessionAPI from '../store/SessionAPI'

import AppRoutes from '../routes'

const RootContainer = () => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    store.dispatch(SessionAPI.initialize()).then(() => {
      setLoading(false)
    })
  })

  if (loading) {
    return (<div style={{ top: '50%', left: '50%', position: 'absolute' }}><CircularProgress /></div>)
  }
  return (
    <>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={adminTheme}>
          <Provider store={store}>
            <Router history={history}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <AppRoutes store={store} />
              </LocalizationProvider>
            </Router>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

export default RootContainer
