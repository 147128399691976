import React from 'react'
import { useSelector } from 'react-redux'
import {
  openDialog
} from 'utils-em'
import {
  useIsStealth
} from 'hooks'
import { TermsChangedDialog } from 'components'

export default function useTermsDialogInitiator () {
  const termsAccepted = useSelector(({ session }) => session.termsAccepted)
  const [dialogTriggered, setDialogTriggered] = React.useState(false)
  const stealthMode = useIsStealth()

  if (stealthMode) { return }

  React.useEffect(() => {
    if (!termsAccepted && !dialogTriggered) {
      setDialogTriggered(true)
      openDialog(<TermsChangedDialog />, 'terms')
    }
  }, [termsAccepted])
}
