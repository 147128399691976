import * as ACTION from './actions'

const Alerts = {
  show: (alert) => ACTION.show(alert),
  showSuccess: (alert) => Alerts.show(Object.assign(alert, { level: 'success' })),
  showWarning: (alert) => Alerts.show(Object.assign(alert, { level: 'warning' })),
  showError: (alert) => Alerts.show(Object.assign(alert, { level: 'error' })),
  showInfo: (alert) => Alerts.show(Object.assign(alert, { level: 'info' })),
  showPurple: (alert) => Alerts.show(Object.assign(alert, { level: 'purple' })),
  hideAll: () => ACTION.hideAll()
}

export default Alerts
