import React from 'react'
import { Route, Switch } from 'react-router'

import CustomerList from './CustomerList'
import TagEditor from './TagEditor'
import FeatureFlags from './FeatureFlags'
import IdPEditor from './IdPEditor'
import SkuEditor from './SkuEditor'
import Advisors from './Advisors'
import Customers from './Customers'
import OrganizationEditor from './OrganizationEditor'
import CoverageReporter from './CoverageReporter'
import NotFound from '../../../components/NotFound'
import Users from './Users'
import Engagements from './Engagements'
import EngagementDetail from './Engagements/routes/EngagementDetail'
import ContentManagement from './ContentManagement'
import StandardizationValidator from './StandardizationValidator'
import SummarizerNineThousand from './SummarizerNineThousand'
import AccountPageManagement from './AccountPageManagement'
import EditAccountPage from './AccountPageManagement/routes/EditAccountPage'
import TipaltiCodeGenerate from './TipaltiCodeGenerate'
import Surveys from './Surveys'
import ChatbotSandbox from './ChatbotSandbox'
import SurveyBuilder from './SurveyBuilder'
import PromptManagement from './PromptManagement'

export default () => (
  <Switch>
    <Route path="/admin/customers/:id" component={Customers} />
    <Route path="/admin/customers" component={CustomerList} />
    <Route path="/admin/tags" component={TagEditor} />
    <Route path="/admin/advisors" component={Advisors} />
    <Route path="/admin/features" component={FeatureFlags} />
    <Route path="/admin/skus" component={SkuEditor} />
    <Route path="/admin/idps" component={IdPEditor} />
    <Route path="/admin/orgs" component={OrganizationEditor} />
    <Route path="/admin/coverage" component={CoverageReporter} />
    <Route path="/admin/users" component={Users} />
    <Route path="/admin/engagements/:id" component={EngagementDetail} />
    <Route path="/admin/engagements" component={Engagements} />
    <Route path="/admin/content-management" component={ContentManagement} />
    <Route path="/admin/standardization-validator" component={StandardizationValidator} />
    <Route path="/admin/summarizer-nine-thousand" component={SummarizerNineThousand} />
    <Route path="/admin/account-page-management/edit/:id?" component={EditAccountPage} />
    <Route path="/admin/account-page-management" component={AccountPageManagement} />
    <Route path="/admin/tipalti-code-generate" component={TipaltiCodeGenerate} />
    <Route path="/admin/chatbot-sandbox" component={ChatbotSandbox} />
    <Route path="/admin/prompts" component={PromptManagement} />
    <Route exact path="/admin/surveys" component={Surveys} />
    <Route path="/admin/surveys/:surveyId" component={SurveyBuilder} />
    {/* {topicsEnabled && <Route path="/admin/topics" component={TopicEditor} />} */}
    <Route component={NotFound} />
  </Switch>
)
