import React from 'react'
import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Link,
  Typography
} from '@mui/material'
import {
  ACCOUNT_PAGE_SECTION_DATA,
  getLowestSeniorityLevel,
  joinWithAnd,
  accountPageSectionHasData,
  accountPageOrgHasChatbot,
  openDialog,
  getArtificialIntelligenceSurveyData,
} from 'utils-em'

import {
  ChatbotBanner,
  SuggestAnAdvisorDialog
} from 'components'
import {
  Flag,
  HelpCircle
} from 'icons'
import {
  useSessionUser
} from 'hooks'

import { AccountPageContext } from '../AccountPageContext'
import RelatedInsights from '../RelatedInsightsSection'
import AvatarWithTextCombo from '../AvatarWithTextCombo'
import WeJustHeard from '../WeJustHeard'

const LandingPage = () => {
  const {
    accountPage,
    setActiveSectionKey,
    scrollableElement,
    responsesLoaded,
    responses
  } = React.useContext(AccountPageContext)
  const { isUnlimitedByAccount } = useSessionUser()

  const [showChatbotBanner, setShowChatbotBanner] = React.useState(false)

  const checkForChatbotData = async () => {
    const hasDataForChatbot = await accountPageOrgHasChatbot(accountPage.organizationId)
    if (hasDataForChatbot) {
      setShowChatbotBanner(true)
    }
  }

  const renderCard = (cardData) => {
    const IconComponent = cardData.icon
    if (!['coaching', 'artificialIntelligence'].includes(cardData.key) && !accountPageSectionHasData(accountPage.data[cardData.key], cardData.key)) {
      return null
    }
    if (cardData.key === 'coaching' && isUnlimitedByAccount) {
      return null
    }
    if (cardData.key === 'artificialIntelligence') {
      const aiPageData = ACCOUNT_PAGE_SECTION_DATA.find((sec) => sec.key === 'artificialIntelligence')
      const containsData = getArtificialIntelligenceSurveyData(responsesLoaded, aiPageData, responses)
      if (!containsData.length) {
        return null
      }
    }
    return (
      <Grid item xs={6} key={cardData.key}>
        <Card
          variant="outlined"
          sx={{
            p: 3,
            cursor: 'pointer',
            borderColor: 'neutral.lightGrey',
            borderRadius: 2,
            height: '100%',
            ':hover': { bgcolor: 'primary.lightest', boxShadow: 0 }
          }}
          onClick={() => {
            setActiveSectionKey(cardData.key)
            scrollableElement.current?.scrollTo(0, 0)
            scrollableElement.current?.parentNode?.scrollTo(0, 0)
            window.scrollTo(0, 0)
          }}
        >
          <Avatar
            sx={{
              ml: 1,
              bgcolor: 'brand.paleOrange',
              height: '40px',
              width: '40px',
              '& path': { stroke: ({ palette }) => palette.brand.darkOrange }
            }}
          >
            <IconComponent />
          </Avatar>
          <Typography variant="h2" color="neutral.black" sx={{ mt: 3 }}>
            {cardData.title}
          </Typography>
          <Typography variant="body1" color="neutral.black" sx={{ mt: 3 }}>
            {cardData.text}
          </Typography>
        </Card>
      </Grid>
    )
  }

  React.useEffect(() => {
    checkForChatbotData()
  }, [])

  return (
    <>
      {showChatbotBanner && <ChatbotBanner embeddedInKnowledgePage weJustHeardSectionPresent={accountPage?.data?.weJustHeard?.length > 0} />}
      {accountPage.data.weJustHeard?.length > 0 && <WeJustHeard />}
      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={5}
        sx={{ mt: -1 }}
      >
        {ACCOUNT_PAGE_SECTION_DATA.filter((item) => !['chatbot', 'weJustHeard'].includes(item.key)).map((data) => renderCard(data))}
      </Grid>

      <Divider sx={{ mt: 5, borderBottomWidth: '1px' }} />

      <Box sx={{ mt: 5 }}>
        <RelatedInsights />
      </Box>

      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: 'flex', mx: 2, mb: 2 }}>
          <AvatarWithTextCombo
            icon={<HelpCircle sx={{ color: 'brand.purple' }} />}
            iconColor="brand.purple"
            variant="h4"
            backgroundColor="brand.lightestPurple"
            textColor="neutral.black"
            text="Where do these insights come from?"
          />
        </Box>
        <Typography variant="body1" sx={{ p: 1, mx: 1 }}>
          {`Emissary surveyed and interviewed former executives at ${accountPage.organization.name} to collect first hand
          insights into how and why ${accountPage.organization.name} purchases technology. `}
          {accountPage.contributingAdvisorSummary.seniorities?.length > 0 && accountPage.contributingAdvisorSummary.functionsOfRole?.length > 0 && (
            `The advisors Emissary consulted
            were all ${getLowestSeniorityLevel(accountPage.contributingAdvisorSummary.seniorities)} or above with experience in
            ${joinWithAnd(accountPage.contributingAdvisorSummary.functionsOfRole)} at ${accountPage.organization.name}.`
          )}
        </Typography>
      </Box>

      <Box sx={{ mt: 5 }}>
        <Box sx={{ display: 'flex', mx: 2, mb: 2 }}>
          <AvatarWithTextCombo
            icon={<Flag sx={{ color: 'brand.purple' }} />}
            iconColor="brand.purple"
            variant="h4"
            backgroundColor="brand.lightestPurple"
            textColor="neutral.black"
            text="Know someone we should contact?"
          />
        </Box>
        <Typography variant="body1" sx={{ p: 1, mx: 1, mb: 2 }}>
          Do you know of an executive who recently left this company that we should recruit?
        </Typography>
        <Link
          variant="buttonL"
          sx={{ p: 1, mx: 1 }}
          onClick={() => openDialog(<SuggestAnAdvisorDialog organizationId={accountPage.organization.id} />)}
        >
          Suggest an advisor
        </Link>
      </Box>
    </>
  )
}

export default LandingPage
