import PropTypes from 'prop-types'
import React from 'react'

import { Box, Grid } from '@mui/material'

import { AdvisorProfile } from 'components'
import APILoaderContainer from '../../../../../../containers/APILoaderContainer'

const AdvisorProfilePreview = ({ advisorId, getEnum, getOne }) => (
  <APILoaderContainer
    onMount={(done) => {
      Promise.all([
        getEnum({ type: 'tenure' }),
        getEnum({ type: 'seniority_dict' })
      ]).then(() => { done() })
    }}
  >
    <Grid container spacing={2} sx={{ p: '1em', mt: 8 }}>
      <Box sx={{ maxWidth: '50em', minWidth: '40em', m: 'auto' }}>
        <AdvisorProfile advisorId={parseInt(advisorId, 10)} />
      </Box>
    </Grid>
  </APILoaderContainer>
)

AdvisorProfilePreview.defaultProps = {
  advisorId: null
}

AdvisorProfilePreview.propTypes = {
  advisorId: PropTypes.string,
  getEnum: PropTypes.func.isRequired,
  getOne: PropTypes.func.isRequired
}

export default AdvisorProfilePreview
