import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'

function renderSuggestion (suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.displayName}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}

const DivisionPicker = (props) => {
  const [textInProgress, setTextInProgress] = useState('')
  const [visibleDivisions, setVisibleDivisions] = useState([])

  const debouncedSearch = debounce((searchTerm) => {
    props.getDivisions(
      searchTerm,
      props.customerId
    ).then((resp) => {
      if (resp?.data?.data) {
        setVisibleDivisions(resp.data.data.map((division) => division.id))
      }
    })
  }, 150)

  // Cleanup debounce function on component unmount
  useEffect(() => () => {
    debouncedSearch.cancel()
  }, [])

  const addDivision = (division) => {
    if (!props.selectedDivisions.find((selectedDivision) => selectedDivision.id === division.id)) {
      props.addDivision(division)
    }
    setTextInProgress('')
  }

  const removeDivision = (division) => {
    props.removeDivision(division)
  }

  const handleSearchTextChange = (event) => {
    const searchTerm = event.currentTarget.value
    setTextInProgress(searchTerm)
    debouncedSearch(searchTerm)
  }

  const divisionsToShow = props.divisions.filter((d) => visibleDivisions.indexOf(d.id) !== -1)

  return (
    <Downshift
      onChange={addDivision}
      inputValue={textInProgress}
      itemToString={(item) => (item ? item.name : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen
      }) => {
        const { onChange } = getInputProps()
        return (
          <div>
            <TextField
              label={props.label}
              value={inputValue || ''}
              onChange={(event) => {
                handleSearchTextChange(event)
                onChange(event)
              }}
              fullWidth
            />
            <List>
              {props.selectedDivisions
                ? props.selectedDivisions.map((division) => {
                  const chip = (
                    <Chip
                      key={division.id}
                      tabIndex={-1}
                      label={division.displayName}
                      onDelete={removeDivision(division)}
                    />
                  )
                  if (props.compact) { return chip }
                  return (
                    <ListItem key={division.id}>
                      { chip }
                    </ListItem>
                  )
                })
                : ''}
            </List>

            <div {...getMenuProps()}>
              {isOpen
                ? (
                  <Paper square>
                    {divisionsToShow.map((suggestion, index) => renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: inputValue
                    }))}
                  </Paper>
                  )
                : null }
            </div>
          </div>
        )
      }}
    </Downshift>
  )
}

DivisionPicker.defaultProps = {
  compact: false,
  label: 'Divisions'
}

DivisionPicker.propTypes = {
  compact: PropTypes.bool,
  divisions: PropTypes.array.isRequired,
  customerId: PropTypes.number.isRequired,
  selectedDivisions: PropTypes.array.isRequired,
  label: PropTypes.string,
  getDivisions: PropTypes.func.isRequired,
  addDivision: PropTypes.func.isRequired,
  removeDivision: PropTypes.func.isRequired
}

export default DivisionPicker
