import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'

import { Pencil } from 'icons'

/*
 * A <TogglableInput> is an input field with
 * an a pencil icon floating to the right that
 * allows the user to enter editing mode.
 */
const TogglableInput = ({ label, value, inputComponent, id, editModeOverride, keepOriginalLabel, disabled }) => {
  const [editMode, setEditMode] = React.useState(editModeOverride)
  const toggleEditMode = (event) => {
    if (event?.key?.toLowerCase() === 'enter') {
      event.preventDefault()
      setEditMode(false)
    }
  }
  const parseValue = () => (Array.isArray(value) ? value.join(', ') : value)
  return (
    <Box onKeyDown={toggleEditMode}>
      {
        editMode || editModeOverride ? (
          <>
            {keepOriginalLabel && (
              <Typography
                variant="tiny"
                color="neutral.darkGrey"
                sx={{ display: 'block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              >
                {label}
              </Typography>
            )}
            {inputComponent}
          </>
        ) : (
          <>
            <Typography
              variant="tiny"
              color="neutral.darkGrey"
              sx={{ display: 'block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
              {label}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="bodyBold" color="neutral.black" sx={{ mt: 1 }}>{disabled ? '' : parseValue()}</Typography>
              <Tooltip title="Edit">
                <IconButton disabled={disabled} id={id ?? label.replace(/\s/gi, '-')} onClick={() => { setEditMode(true) }}>
                  <Pencil sx={{ color: disabled ? 'neutral.disabled' : 'primary.main' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )
      }
    </Box>
  )
}

TogglableInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  inputComponent: PropTypes.element.isRequired,
  id: PropTypes.string,
  editModeOverride: PropTypes.bool,
  keepOriginalLabel: PropTypes.bool,
  disabled: PropTypes.bool
}

TogglableInput.defaultProps = {
  id: null,
  editModeOverride: false,
  keepOriginalLabel: false,
  disabled: false
}

export default TogglableInput
