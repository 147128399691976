import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { useGetEnums } from 'hooks'
import { ChipMultiAutocomplete } from 'components'

const AssetTypeSelector = ({
  insightTypes,
  onChange,
  multiple,
  label,
  placeholder,
  enumKey,
  enumsOverride,
  ...rest
}) => {
  const [assetTypes, setAssetTypes] = useState([])
  const [chosenTypes, setChosenTypes] = useState([])

  const { enums } = useGetEnums(enumKey, { skip: Boolean(enumsOverride) })
  const assetTypesEnum = enumsOverride || enums

  useEffect(() => {
    if (!assetTypesEnum) return
    const assetTypesToFilter = ['engagement-transcript', 'engagement-notes', 'engagement-recording']
    const sortedAssetTypes = [...assetTypesEnum.filter((a) => !assetTypesToFilter.includes(a.value))].sort((a, b) => a.name.localeCompare(b.name))
    //  'Other' option should always be last
    sortedAssetTypes.push(sortedAssetTypes.splice(sortedAssetTypes.findIndex((t) => t.name === 'Other'), 1)[0])
    setAssetTypes(sortedAssetTypes)
    const typeList = assetTypesEnum.filter((x) => insightTypes.includes(x.value))
    setChosenTypes(multiple ? typeList : typeList?.[0])
  }, [assetTypesEnum])

  const handleChange = (chosenType) => {
    if (multiple) {
      onChange(chosenType?.map((type) => type.value))
    } else {
      onChange(chosenType[0]?.value)
    }
  }

  if (!assetTypes?.length > 0) return null

  return (
    <ChipMultiAutocomplete
      label={label}
      placeholder={placeholder}
      options={assetTypes}
      initialSelectedOptions={multiple ? chosenTypes : [chosenTypes]}
      getOptionLabel={(option) => (Array.isArray(option) ? option[0]?.name : option?.name)}
      selectedOptionsCallback={(selection) => handleChange(selection)}
      multiple={multiple}
      {...rest}
    />
  )
}

AssetTypeSelector.defaultProps = {
  multiple: false,
  insightTypes: [],
  label: 'Insight Type',
  placeholder: 'Insight Type',
  required: false,
  enumKey: 'asset_types',
  enumsOverride: null
}

AssetTypeSelector.propTypes = {
  insightTypes: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  enumKey: PropTypes.string,
  enumsOverride: PropTypes.array,
}

export default AssetTypeSelector
