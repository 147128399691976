import PropTypes from 'prop-types'
import React from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
} from '@mui/material'

const EngagementCloseDialog = ({
  closeAction,
  engagement,
  saveEngagement,
  showSuccessMessage,
  showErrorMessage,
  handleClose
}) => {
  const [sendAdvisorFeedback, setSendAdvisorFeedback] = React.useState(false)
  const [sendCustomerFeedback, setSendCustomerFeedback] = React.useState(false)

  let closeDetails
  let closeActionLabel
  let targetState
  switch (closeAction) {
    case 'cancelEngagement': {
      closeActionLabel = 'Cancel Engagement'
      closeDetails = (
        <>
          Cancelling this engagement will have the following effects:
          <br />
          {' '}
          • Engagement will enter a &quot;cancelled&quot; state
          <br />
          {' '}
          • Any associated skus and their charges will be deleted
          <br />
          {' '}
          • Engagement will no longer be visible to customers
          <br />
          {' '}
          • An email notification will be sent to the advisor
        </>
      )
      targetState = 'canceled'
      break
    }
    case 'refundEngagement': {
      closeActionLabel = 'Refund/Close Engagement'
      closeDetails = (
        <>
          Refunding / Closing this engagement will have the following effects:
          <br />
          {' '}
          • Engagement will enter a &quot;closed&quot; state
          <br />
          {' '}
          • Any associated skus and their charges will be refunded (0 charge count and &quot;refunded&quot; state)
          <br />
          {' '}
          • Engagement will be visible to customers in a closed state
          <br />
          {' '}
          • If no call has taken place, feedback requests are controlled below. Please note that if a call happened feedback has already been sent
        </>
      )
      targetState = 'closed'
      break
    }
    case 'closeEngagementNow': {
      closeActionLabel = 'Close Engagement Now'
      closeDetails = (
        <>
          Closing this engagement now will have the following effects:
          <br />
          {' '}
          • Engagement will enter a &quot;closed&quot; state
          <br />
          {' '}
          • Any associated skus and their charges will not be affected
          <br />
          {' '}
          • Engagement will be visible to customers in a closed state
          <br />
          {' '}
          • If no call has taken place, feedback requests are controlled below. Please note that if a call happened feedback has already been sent
        </>
      )
      targetState = 'closed'
      break
    }
  }

  const updateEngagement = () => {
    const bodyToSave = {
      ...engagement,
      state: targetState,
      [closeAction]: true
    }

    if (closeAction !== 'cancelEngagement') {
      bodyToSave.sendCustomerFeedback = sendCustomerFeedback
      bodyToSave.sendAdvisorFeedback = sendAdvisorFeedback
    }

    saveEngagement(bodyToSave).then((res) => {
      if (res.ok) {
        showSuccessMessage({
          text: `Engagement successfully ${targetState}`,
          timeout: 2000
        })
        handleClose()
      } else {
        showErrorMessage({
          text: 'Something went wrong, please file a bug ticket with exact time',
          timeout: 2000
        })
      }
    })
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>{closeActionLabel}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            { closeDetails }
          </Grid>
          {closeAction !== 'cancelEngagement'
            ? (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={sendAdvisorFeedback}
                        onChange={() => {
                          setSendAdvisorFeedback(!sendAdvisorFeedback)
                        }}
                      />
                  )}
                    label="Send feedback request to advisor"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={sendCustomerFeedback}
                        onChange={() => {
                          setSendCustomerFeedback(!sendCustomerFeedback)
                        }}
                      />
                  )}
                    label="Send feedback request to customer"
                  />
                </Grid>
              </>
              )
            : null }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => { updateEngagement() }}
        >
          {closeActionLabel}
        </Button>
        <Button onClick={handleClose} color="primary">
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EngagementCloseDialog.defaultProps = {
  closeLabel: 'Cancel',
  title: null,
  content: null
}

EngagementCloseDialog.propTypes = {
  closeAction: PropTypes.string.isRequired,
  engagement: PropTypes.object.isRequired,
  saveEngagement: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default EngagementCloseDialog
