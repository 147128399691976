import PropTypes from 'prop-types'
import React from 'react'
import { get, map } from 'lodash'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  FormControlLabel,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  Typography,
  Paper,
  Stack,
  Switch,
} from '@mui/material'
import Check from '@mui/icons-material/Check'

import { ConfirmDialog } from 'components'
import { buildJsonApiFilter, constants } from 'utils-em'

import TablePaginationActions from '../../../../../../components/TablePaginationActions'
import AbsorbOrganizationDialog from '../AbsorbOrganizationDialog'
import EditOrganizationDialog from '../EditOrganizationDialog'

const API_HOST = __API_HOST__

const OrganizationList = ({
  organizations,
  organizationsCount,
  getOrgs,
  createNewOrg,
  deleteOrg,
  updateJsonAPI,
  openDialog,
  showErrorMessage,
  showSuccessMessage,
  getAssociatedEngagements
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [sortDirection, setSortDirection] = React.useState('asc')
  const [sortColumn, setSortColumn] = React.useState('name')
  const [filters, setFilters] = React.useState({})

  const [tealFilter, setTealFilter] = React.useState(false)

  const [hasAccountPage, setHasAccountPage] = React.useState(false)

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    setFilters((prevState) => ({
      ...prevState,
      [name]: {
        value,
        filter: value ? buildJsonApiFilter(name, value) : null
      }
    }))
  }

  const sortOnColumn = (columnName) => {
    setSortDirection(sortColumn !== columnName || sortDirection === 'asc' ? 'desc' : 'asc')
    setSortColumn(columnName)
  }

  const makeRequest = (abortController = null) => {
    const jsonApiFilters = map(filters, (v, k) => v.filter).filter((f) => f)

    if (tealFilter) { jsonApiFilters.push(buildJsonApiFilter('teal__is', true)) }

    if (hasAccountPage) { jsonApiFilters.push(buildJsonApiFilter('accountPages__any.id__nullcheck')) }

    getOrgs(
      rowsPerPage,
      page + 1,
      `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`,
      jsonApiFilters,
      abortController
    )
  }

  const handleDeleteOrg = async (org) => {
    try {
      // check for attached engagements
      const engRes = await getAssociatedEngagements(org)
      const engagementData = engRes.data && engRes.data.data
      if (engagementData && engagementData.length > 0) {
        const engagementIds = engagementData.map((e) => e.id)
        throw new Error(`Cannot delete. Organization attached to the following engagements:${engagementIds.join(',')}`)
      }
      const delRes = await deleteOrg(org)
      if (delRes.error) throw new Error(delRes.error.message)
      showSuccessMessage({ text: 'Organization deleted!', timeout: 4000 })
    } catch (error) {
      showErrorMessage({ text: error.message, timeout: 4000 })
    }
  }

  const triggerResync = () => {
    fetch(
      `${API_HOST}/v1/elasticsearch/reindex`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ index: 'organization' }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((res) => {
      if (!res.ok) {
        showErrorMessage({ text: 'Error resyncing', timeout: 1000 })
      } else {
        showSuccessMessage({ text: 'Resync started', timeout: 1000 })
      }
    })
  }

  React.useEffect(() => {
    const abortController = new AbortController()
    makeRequest(abortController)

    return () => {
      abortController.abort()
    }
  }, [page, rowsPerPage, sortDirection, sortColumn])

  return (
    <>
      <ValidatorForm onSubmit={makeRequest}>
        <Paper>
          <Box sx={{ p: 2 }}>
            <Typography variant="h2">Master Org List</Typography>
            <Stack direction="row" spacing={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => openDialog((
                  <EditOrganizationDialog />
                ))}
              >
                New Org
              </Button>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={() => { makeRequest() }}
              >
                Apply Filters
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={triggerResync}
              >
                Manually Resync ES
              </Button>
              <FormControlLabel
                control={(
                  <Switch
                    checked={hasAccountPage}
                    onChange={() => { setHasAccountPage(!hasAccountPage) }}
                    name="hasPage"
                  />
                )}
                label="Has Account Page"
              />
            </Stack>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        active={sortColumn === 'id'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('id')}
                      >
                        Id
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={get(filters, 'id.value', '')}
                        onChange={handleSearchFilter('id')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        active={sortColumn === 'name'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('name')}
                      >
                        Name
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={get(filters, 'name.value', '')}
                        onChange={handleSearchFilter('name')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      Is TEAL
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={tealFilter}
                            onChange={() => {
                              setTealFilter(!tealFilter)
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        active={sortColumn === 'origin'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('origin')}
                      >
                        Origin
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={get(filters, 'origin.value', '')}
                        onChange={handleSearchFilter('origin')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        active={sortColumn === 'url'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('url')}
                      >
                        Url
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={get(filters, 'url.value', '')}
                        onChange={handleSearchFilter('url')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>Verticals</TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        active={sortColumn === 'aliases'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('aliases')}
                      >
                        Aliases
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={get(filters, 'aliases.value', '')}
                        onChange={handleSearchFilter('aliases')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { organizations.map((org) => (
                <TableRow key={org.id}>
                  <TableCell component="th" scope="row">{org.id}</TableCell>
                  <TableCell>
                    <span>{org.name}</span>
                  </TableCell>
                  <TableCell>{org.teal ? <Check /> : null }</TableCell>
                  <TableCell>{org.origin}</TableCell>
                  <TableCell>{org.url}</TableCell>
                  <TableCell component="th" scope="row">
                    {org.tags?.filter((t) => t.category === 'vertical').map((tag) => (
                      <Chip
                        key={tag.id}
                        size="small"
                        label={tag.name}
                      />
                    ))}
                  </TableCell>
                  <TableCell>{org.aliases}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => openDialog((
                        <EditOrganizationDialog
                          organization={org}
                        />
                      ))}
                    >
                      Edit
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => openDialog(<ConfirmDialog
                        title="Delete Allotment"
                        description={`Really delete org for: ${org.name}?`}
                        actions={[
                          {
                            name: 'Delete',
                            action: () => { handleDeleteOrg(org) },
                            isDelete: true
                          }
                        ]}
                      />)}
                    >
                      Delete
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => openDialog((
                        <AbsorbOrganizationDialog
                          organization={org}
                        />
                      ))}
                    >
                      Absorb
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                  count={organizationsCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true
                  }}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(event.target.value)
                    setPage(0)
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </ValidatorForm>
    </>
  )
}

OrganizationList.propTypes = {
  organizations: PropTypes.array.isRequired,
  organizationsCount: PropTypes.number.isRequired,
  getOrgs: PropTypes.func.isRequired,
  createNewOrg: PropTypes.func.isRequired,
  deleteOrg: PropTypes.func.isRequired,
  updateJsonAPI: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  getAssociatedEngagements: PropTypes.func.isRequired
}

export default OrganizationList
