import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  LinearProgress
} from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { emissaryTheme } from 'themes'
import { closeDialog, customAlert } from 'utils-em'

const API_HOST = __API_HOST__

const ImportCSVDialog = ({ type, onSubmit, title }) => {
  const [file, setFile] = React.useState('')
  const [inProgress, setInProgress] = React.useState(false)
  const [error, setError] = React.useState('')
  const allowedExtensions = ['csv']

  const handleFileChange = async (e) => {
    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0]

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split('/')[1]
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please input a csv file')
        return
      }

      setError('')
      // If input type is correct set the state
      await setFile(inputFile)
    }
  }

  const handleSubmit = async () => {
    setInProgress(true)
    try {
      const results = await fetch(
      `${API_HOST}/v1/${type}`,
      {
        credentials: 'include',
        method: 'POST',
        body: file
      }
      )
      const body = await results.json()
      if (!results.ok) {
        setError(`An error occurred while importing: ${body.errors}`)
      } else {
        onSubmit(body)
        customAlert(`Successfully imported ${body.importedCount} records!`)
        closeDialog()
      }
    } catch (e) {
      setError(`An error occurred while importing: ${e.message}`)
    } finally {
      setInProgress(false)
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={emissaryTheme}>
        <Dialog
          open
          maxWidth="xs"
          scroll="paper"
          onClose={closeDialog}
        >
          <DialogTitle>
            {title || `Import ${type} from CSV`}
          </DialogTitle>
          <DialogContent>
            <input
              onChange={handleFileChange}
              id="csvInput"
              name="file"
              type="File"
              accept=".csv"
            />
            <Box sx={{ mt: 2 }}>
              {inProgress && <LinearProgress />}
              {error && <Box sx={{ color: 'red' }}>{error}</Box>}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={(closeDialog)}>
              Cancel
            </Button>
            <Button sx={{ ml: 2 }} disabled={inProgress || !file} variant="contained" onClick={handleSubmit}>
              Import
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

ImportCSVDialog.defaultProps = {
  onSubmit: () => {},
  title: ''
}

ImportCSVDialog.propTypes = {
  type: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  title: PropTypes.string
}

export default ImportCSVDialog
