import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  Chip,
  Grid,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  TableSortLabel,
  TablePagination,
  Tooltip
} from '@mui/material'
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined'
import Edit from '@mui/icons-material/Edit'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import Favorite from '@mui/icons-material/Favorite'
import FlipCameraAndroid from '@mui/icons-material/FlipCameraAndroid'
import Star from '@mui/icons-material/Star'
import { ConfirmDialog } from 'components'

import { constants, formatDate } from 'utils-em'
import EditAssetDialog from '../EditAssetDialog'
import AdminAccessControl from '../AdminAccessControl'
import AssetListFilters from './AssetListFilters'

const styles = {
  header: {
    fontSize: '20px',
    margin: '1rem'
  },
  button: {
    backgroundColor: '#2B3BFA',
    color: '#FFFFFF',
    margin: '1rem'
  },
  filters: {
    margin: '1rem'
  },
  table: {
    marginLeft: '5rem'
  },
  tableHeader: {
    fontSize: '12px'
  },
  tableCell: {
    fontSize: '14px'
  },
  icon: {
    position: 'relative',
    top: '6px',
    left: '5px',
    marginLeft: '.5rem'
  },
  featuredStar: {
    color: '#E8AA4A'
  },
  favoriteHeart: {
    color: '#dd7a64'
  },
  actionButton: {
    color: '#2B3BFA'
  },
  deleteButton: {
    color: '#D84B3B'
  }
}

const AssetList = ({ openDialog, closeDialog, getAssets, assets, saveAsset, assetTypesEnum }) => {
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE)
  const [sortDirection, setSortDirection] = useState('desc')
  const [sortColumn, setSortColumn] = useState('dateUpdated')
  const [filteredAssets, setFilteredAssets] = useState([])
  const [assetsLoading, setAssetsLoading] = useState(true)

  useEffect(() => {
    populateListOfAssets()
  }, [])

  useEffect(() => {
    !assetsLoading && setFilteredAssets(assets)
  }, [assetsLoading, assets])

  useEffect(() => {
    setPage(0)
    setTotalCount(filteredAssets.length)
  }, [filteredAssets])

  const populateListOfAssets = () => {
    setAssetsLoading(true)
    getAssets('-dateUpdated').then((res) => {
      setAssetsLoading(false)
    })
  }

  useEffect(() => {
    let orderedAssets = [...filteredAssets].sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]))
    if (sortDirection === 'desc') {
      orderedAssets = [...orderedAssets].reverse()
    }
    setFilteredAssets(orderedAssets)
  }, [sortColumn, sortDirection])

  const setColumnSort = (column) => {
    setSortDirection(sortColumn !== column || sortDirection === 'asc' ? 'desc' : 'asc')
    setSortColumn(column)
    setPage(0)
  }

  const editAsset = (asset, action) => {
    const assetToUpdate = asset
    switch (action) {
      case 'delete':
        assetToUpdate.isDeleted = true
        break
      case 'archive':
        assetToUpdate.isArchived = true
        break
      case 'unarchive':
        assetToUpdate.isArchived = false
        break
    }

    saveAsset(assetToUpdate).then((res) => {
      if (res.ok && action === 'delete') {
        populateListOfAssets()
      }
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <AdminAccessControl>
          <Grid item xs={10}>
            <Typography sx={{ ...styles.header }}>
              Insights Library CMS
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={{ ...styles.button }}
              variant="contained"
              onClick={() => openDialog((
                <EditAssetDialog
                  handleClose={closeDialog}
                  updateAssets={populateListOfAssets}
                  asset={{}}
                />
              ))}
            >
              Upload New
            </Button>
          </Grid>
          <AssetListFilters
            sx={{ ...styles.filters }}
            assets={[...assets]}
            setFilteredAssets={setFilteredAssets}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    sx={{ ...styles.tableHeader }}
                    active={sortColumn === 'title'}
                    direction={sortDirection}
                    onClick={() => setColumnSort('title')}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ ...styles.tableHeader }}
                    active={sortColumn === 'assetType'}
                    direction={sortDirection}
                    onClick={() => setColumnSort('assetType')}
                  >
                    Insight Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    sx={{ ...styles.tableHeader }}
                    active={sortColumn === 'dateUpdated'}
                    direction={sortDirection}
                    onClick={() => setColumnSort('dateUpdated')}
                  >
                    Date Updated
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Accounts
                </TableCell>
                <TableCell>
                  Vertical
                </TableCell>
                <TableCell>
                  Department
                </TableCell>
                <TableCell>
                  Customers with access
                </TableCell>
                <AdminAccessControl>
                  <TableCell sx={{ ...styles.tableHeader }}>Actions</TableCell>
                </AdminAccessControl>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...filteredAssets.slice(page * rowsPerPage, (page + 1) * rowsPerPage)].map((asset) => (
                <TableRow key={asset.id} sx={{ opacity: asset.isArchived ? '0.5' : null }}>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ ...styles.tableCell }}>
                      {asset.title}
                      {asset.isSuggested
                        ? <Favorite sx={{ ...styles.favoriteHeart, ...styles.icon }} />
                        : ''}
                      {asset.isFeatured
                        ? <Star sx={{ ...styles.featuredStar, ...styles.icon }} />
                        : ''}
                      {asset.isArchived
                        ? <ArchiveOutlined sx={{ ...styles.icon }} />
                        : ''}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ ...styles.tableCell }}>{assetTypesEnum.find((type) => type.value === asset.assetType).name}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ ...styles.tableCell }}>{formatDate(asset.dateUpdated)}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {asset.organizations.map((org) => (
                      <Chip
                        key={`org-${org.id}`}
                        size="small"
                        label={org.name}
                      />
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {asset.tags.filter((t) => t.category === 'vertical').map((tag) => (
                      <Chip
                        key={tag.id}
                        size="small"
                        label={tag.name}
                      />
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {asset.tags.filter((t) => t.category === 'buyer-type').map((tag) => (
                      <Chip
                        key={tag.id}
                        size="small"
                        label={tag.name}
                      />
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {asset.authorizedCustomers.length > 0 ? asset.authorizedCustomers.map((customer) => (
                      <Chip
                        key={customer.id}
                        size="small"
                        label={customer.name}
                      />
                    )) : (
                      <Chip
                        size="small"
                        label="All"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center" component="th">
                    <ButtonGroup>
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{ ...styles.actionButton }}
                          target="_blank"
                          onClick={() => openDialog((
                            <EditAssetDialog asset={asset} handleClose={closeDialog} updateAssets={populateListOfAssets} />
                          ))}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={asset.isArchived ? 'Unarchive' : 'Archive'}>
                        <IconButton
                          sx={{ ...styles.actionButton }}
                          onClick={() => editAsset(asset, asset.isArchived ? 'unarchive' : 'archive')}
                        >
                          {asset.isArchived ? <FlipCameraAndroid /> : <ArchiveOutlined />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ ...styles.deleteButton }}
                          target="_blank"
                          onClick={() => openDialog(
                            <ConfirmDialog
                              title="Delete asset"
                              description={`Are you sure you want to delete ${asset.title}?`}
                              actions={[
                                {
                                  name: 'Delete',
                                  action: () => { editAsset(asset, 'delete') },
                                  isDelete: true
                                }
                              ]}
                            />
                          )}
                        >
                          <DeleteForeverOutlined />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true
                  }}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(event.target.value)
                    setPage(0)
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AdminAccessControl>
      </Grid>
    </>
  )
}

AssetList.defaultProps = {
  assets: []
}

AssetList.propTypes = {
  openDialog: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  saveAsset: PropTypes.func.isRequired,
  getAssets: PropTypes.func.isRequired,
  assets: PropTypes.array,
  assetTypesEnum: PropTypes.array.isRequired
}

export default AssetList
