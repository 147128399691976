import * as Sentry from '@sentry/react'
import * as SESSION_ACTIONS from 'store/SessionAPI/constants'

const SENTRY_DSN = __SENTRY_DSN__
const SENTRY_RELEASE = __SENTRY_RELEASE__
const CURRENT_ENV = __ENV__

export const sentryOptions = {
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing()
  ],
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'Illegal invocation',
    'Loading chunk',
    'NotAllowedError',
    'EvalError',
    // https://github.com/getsentry/sentry-javascript/issues/2514
    'Non-Error promise rejection captured'
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ],
  tracesSampleRate: __FOR_DEV__ ? 0.1 : 1.0,
  tracePropagationTargets: ['localhost', 'https://staging-api.emissary.io', 'https://api.emissary.io'],
  environment: CURRENT_ENV,
  release: SENTRY_RELEASE
}

export const SentryEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if ([SESSION_ACTIONS.INITIALIZE_USER,
      SESSION_ACTIONS.INITIALIZE_ADVISOR_USER,
      SESSION_ACTIONS.INITIALIZE_CUSTOMER_USER
    ].includes(action.type)) {
      Sentry.setUser({
        email: action.user.email,
        id: action.user.id,
        userType: action.user.user_type,
        cloaked: action.cloaked
      })
    }
  }
})
