export const ADVISOR_EMAIL_STAGE_QUOTES = [
  {
    text: '"Emissary helps me monetize the wealth of knowledge I gained in the IT space. It is a fantastic model to connect the human knowledge base with those looking for it."',
    quotee: 'Emissary Advisor'
  },
  {
    text: '"My biggest motivation for working with Emissary is to help people achieve their professional and personal goals. I love talking to the different clients and hearing about their challenges and offerings and what they’re trying to achieve."',
    quotee: 'Emissary Advisor'
  },
  {
    text: '"I’m still a shareholder [of my former company]. I want to be sure that they have the best tech in there!"',
    quotee: 'Emissary Advisor'
  }
]

export const ADVISOR_PASSWORD_STAGE_QUOTES = [
  {
    text: '"Emissary helps me play the right card, at the right time."',
    quotee: 'SVP Global Field Operations, data management platform'
  },
  {
    text: '"No exaggeration: this one call saved us no less than 40 hours of research."',
    quotee: 'Worldwide VP of Sales, application performance software'
  },
  {
    text: '"The Emissary Advisor was able to work ‘on the same side of the table’ to build out a picture of the account and how to approach it. Great preparation, great insight."',
    quotee: 'Sr. Major Account Manager, Networking Solutions'
  }
]
