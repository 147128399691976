import { connect } from 'react-redux'
import { buildJsonApiAll, buildJsonApiOne } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'

import EditExperienceDialog from './EditExperienceDialog'

const mapStateToProps = ({ enums, data, session }, ownProps) => ({
  isAdvisor: session.userType === 'advisor',
  tenureEnums: enums.tenure,
  seniorityEnums: enums.seniority,
  company: buildJsonApiOne(data, 'companies', ownProps.companyId),
  allAdvisorCompanies: buildJsonApiAll(data, 'companies')
})

const mapDispatchToProps = {
  saveCompany: (company) => {
    const method = company.id ? JsonAPI.save : JsonAPI.create
    return method({
      ...company,
      type: 'companies',
      include: 'roles',
      relationshipNames: ['roles']
    })
  },
  saveRoles: (rolesToSave) => JsonAPI.createList({
    type: 'roles',
    queryStringParams: {
      include: 'company,company.roles,tags'
    },
    data: rolesToSave
  }),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(EditExperienceDialog)
