import PropTypes from 'prop-types'
import React from 'react'

import GlobalAlert from '../GlobalAlert'

// NOTE: notifications never change and are always ordered the same. Change to use a real id as
// key for React if individual notifications can change order or be removed.

/* eslint-disable react/no-array-index-key */
const GlobalAlertStack = ({ alerts }) => (
  <div>
    {alerts.map((n, idx) => (
      <GlobalAlert
        key={idx}
        text={n.text}
        level={n.level}
        timeout={n.timeout}
        vertical={n.vertical || 'bottom'}
        horizontal={n.horizontal || 'left'}
      />
    ))}
  </div>
)
/* eslint-enable react/no-array-index-key */

GlobalAlertStack.propTypes = {
  alerts: PropTypes.array.isRequired
}

export default GlobalAlertStack
