import React from 'react'
import {
  Box,
  IconButton,
  Link
} from '@mui/material'
import {
  ChevronDown
} from 'icons'
import {
  useEmissaryTheme
} from 'hooks'

import {
  CalendarTimeSelectorContext
} from '../../CalendarTimeSelectorContext'

const WeekSelectionControls = () => {
  const {
    goToToday,
    goToPreviousWeek,
    goToNextWeek,
    isCurrentWeek
  } = React.useContext(CalendarTimeSelectorContext)
  const {
    isSmOrSmaller
  } = useEmissaryTheme()

  const linkSx = {
    color: 'neutral.black',
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', columnGap: 3 }}>
      {isSmOrSmaller ? (
        <IconButton disabled={isCurrentWeek} onClick={goToPreviousWeek} sx={{ '&:hover': { backgroundColor: 'primary.lightest' } }}>
          <ChevronDown sx={{ color: isCurrentWeek ? 'neutral.disabled' : 'neutral.black', transform: 'rotate(90deg)' }} />
        </IconButton>
      ) : (
        <Link
          variant="caption"
          onClick={() => {
            if (!isCurrentWeek) goToPreviousWeek()
          }}
          underline={isCurrentWeek ? 'none' : 'hover'}
          sx={{
            ...linkSx,
            color: isCurrentWeek ? 'neutral.disabled' : 'neutral.black'
          }}
        >
          <ChevronDown sx={{ color: isCurrentWeek ? 'neutral.disabled' : 'neutral.black', transform: 'rotate(90deg)' }} />
          Previous week
        </Link>
      )}
      <Link
        variant="caption"
        onClick={() => {
          if (!isCurrentWeek) goToToday()
        }}
        underline={isCurrentWeek ? 'none' : 'hover'}
        sx={{
          ...linkSx,
          color: isCurrentWeek ? 'neutral.disabled' : 'neutral.black'
        }}
      >
        Today
      </Link>
      {isSmOrSmaller ? (
        <IconButton onClick={goToNextWeek} sx={{ '&:hover': { backgroundColor: 'primary.lightest' } }}>
          <ChevronDown sx={{ color: 'neutral.black', transform: 'rotate(-90deg)' }} />
        </IconButton>
      ) : (
        <Link
          variant="caption"
          onClick={goToNextWeek}
          underline="hover"
          sx={linkSx}
        >
          Next week
          <ChevronDown sx={{ color: 'neutral.black', transform: 'rotate(-90deg)' }} />
        </Link>
      )}
    </Box>
  )
}

export default WeekSelectionControls
