import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  Typography
} from '@mui/material'
import {
  useEngagement,
  useSessionUser
} from 'hooks'
import {
  navigateTo
} from 'utils-em'

const ProposalRejected = ({ engagementId }) => {
  const { isAdvisor } = useSessionUser()
  const {
    advisor,
    engagementRejectReasons
  } = useEngagement(engagementId)

  const advisorRejectedDueToRate = engagementRejectReasons?.[0] && engagementRejectReasons[0].rejectReason === 'PAYMENT_RATE'

  return isAdvisor ? (
    <Typography variant="body1">
      You indicated that you are not available to engage.
    </Typography>
  ) : (
    <>
      <Typography variant="h3">
        {advisorRejectedDueToRate ? 'Stay tuned.' : 'We\'re sorry!'}
      </Typography>
      <Typography variant="body1">
        {advisorRejectedDueToRate ? (
          <>
            {`Emissary is communicating with ${advisor.firstName} about this opportunity. You will receive a notification with next steps.`}
          </>
        ) : (
          <>
            Unfortunately, this advisor has indicated that they are not available to engage.
            {' '}
            <Link onClick={() => navigateTo('/c/search')}>Find another advisor</Link>
            {' '}
            from our network.
          </>
        )}
      </Typography>
    </>
  )
}

ProposalRejected.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default ProposalRejected
