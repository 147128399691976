import React from 'react'
import PropTypes from 'prop-types'

import {
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem
} from '@mui/material'

import Face from '@mui/icons-material/Face'
import AllInclusive from '@mui/icons-material/AllInclusive'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import Group from '@mui/icons-material/Group'
import { StickyTextField, StickySelectField } from 'components'

const advisorFilters = ({ filters, handleSearchFilter }) => (
  <>
    <StickyTextField
      stickyKey="advisor.recruiter"
      fullWidth
      size="small"
      margin="dense"
      label="Recruiter"
      variant="outlined"
      value={filters['recruiter.fullName'].value}
      onChange={(event) => handleSearchFilter('recruiter.fullName', event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Group />
          </InputAdornment>
        )
      }}
    />
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id="role-select-label">Onboarded</InputLabel>
      <StickySelectField
        stickyKey="advisor.onboarded"
        id="onboarding-select"
        label="Onboarded"
        startAdornment={(
          <InputAdornment position="start">
            <Face />
          </InputAdornment>
        )}
        variant="outlined"
        value={filters.onboardingCompleted__nullcheck.value}
        onChange={(event) => handleSearchFilter('onboardingCompleted__nullcheck', event.target.value)}
      >
        <MenuItem value="">
          <Chip size="small" label="Any" icon={<AllInclusive />} />
        </MenuItem>
        <MenuItem value>
          <Chip size="small" label="Onboarded" icon={<CheckCircle style={{ color: '#5aa469' }} fontSize="small" />} />
        </MenuItem>
        <MenuItem value="null">
          <Chip size="small" label="Not onboarded" icon={<Cancel style={{ color: '#cd5d7d' }} fontSize="small" />} />
        </MenuItem>
      </StickySelectField>
    </FormControl>
  </>
)

advisorFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  handleSearchFilter: PropTypes.func.isRequired
}

export default advisorFilters
