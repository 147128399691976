import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Typography
} from '@mui/material'

const ClickableCard = ({ headerText, bodyContent, headerChip, onClick, ...rest }) => (
  <Box {...rest}>
    <Card sx={{ ':hover': { bgcolor: 'primary.lightest', boxShadow: 0 } }}>
      <ButtonBase
        onClick={onClick}
        sx={{ width: '100%' }}
      >
        <CardContent sx={{ px: 3, py: 2, width: '100%', textAlign: 'left' }}>
          <Box sx={{ mb: 1, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" color="neutral.black">{headerText}</Typography>
            {headerChip}
          </Box>
          <Box typography="body1" sx={{ width: '100%' }}>
            {bodyContent}
          </Box>
        </CardContent>
      </ButtonBase>
    </Card>
  </Box>
)

ClickableCard.defaultProps = {
  headerChip: null,
}
ClickableCard.propTypes = {
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bodyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
  headerChip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ClickableCard
