import PropTypes from 'prop-types'
import React from 'react'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  useTheme
} from '@mui/material'

import { closeDialog } from 'utils-em'

const GuidedTourDialog = ({
  steps
}) => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)

  const getAvatarStyles = (idx) => {
    // completed
    if (activeStep > idx || idx === 0) {
      return {
        backgroundColor: theme.palette.brand.paleSlate,
        color: theme.palette.brand.slate
      }
    }

    // active
    if (activeStep === idx) {
      return {
        backgroundColor: theme.palette.brand.slate
      }
    }

    // pending, default, needs no override
    return {
      backgroundColor: theme.palette.neutral.disabled
    }
  }

  const isLastStep = activeStep === steps.length - 1
  return (
    <Dialog
      open
      size="sm"
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle>How Emissary works</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, idx) => (
            <Step
              key={step.title}
            >
              <StepLabel
                icon={(
                  <Avatar
                    sx={getAvatarStyles(idx)}
                  >
                    {step.avatar}
                  </Avatar>
                )}
              >
                {step.title}
              </StepLabel>
              <StepContent>
                {step.text}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary" variant={isLastStep ? 'contained' : 'text'}>
          Close
        </Button>
        {!isLastStep ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setActiveStep(activeStep + 1)
            }}
            sx={{ ml: 2 }}
          >
            Next
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

GuidedTourDialog.propTypes = {
  steps: PropTypes.array.isRequired
}

export default GuidedTourDialog
