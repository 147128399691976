import * as ACTION_TYPE from './constants'

export function initializeRequest (caller) {
  return {
    type: ACTION_TYPE.REQUEST_INIT,
    isLoading: true,
    caller
  }
}

export function handleRequestSuccess (data) {
  return {
    type: ACTION_TYPE.REQUEST_SUCCESS,
    isLoading: false
  }
}

export function handleRequestError (err) {
  return {
    type: ACTION_TYPE.REQUEST_ERROR,
    isLoading: false,
    error: err
  }
}

export function handleRequestAborted () {
  return {
    type: ACTION_TYPE.REQUEST_ABORTED,
    isLoading: false
  }
}

export function cacheRequest (key) {
  return {
    type: ACTION_TYPE.CACHE_REQUEST,
    key
  }
}

export function cacheReset (key) {
  return {
    type: ACTION_TYPE.CACHE_RESET,
    key
  }
}
