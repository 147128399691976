import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Chip,
  Button,
  Typography
} from '@mui/material'
import {
  TableFilters,
  TableSearch
} from 'components'
import { Check, CloseCircle, Download } from 'icons'
import {
  getUserStatusesFilter
} from 'utils-em'

// The main function of this component is to take in an initialQueryStringParams object and output the updated version of that object based on the user's filter selections
const FilterGroup = ({
  title,
  filterDialogTitle,
  filterOptions,
  isRider,
  textSearchQueryFields,
  textSearchPlaceholder,
  initialQueryStringParams,
  onQueryStringParamsChange,
  allowSearch,
  onExport,
  extraComponents
}) => {
  const [searchFilter, setSearchFilter] = React.useState()
  const [categoryFilters, setCategoryFilters] = React.useState([])
  const [selectedChipItems, setSelectedChipItems] = React.useState({})
  const [queryStringParams, setQueryStringParams] = React.useState(initialQueryStringParams)

  React.useEffect(() => { onQueryStringParamsChange(queryStringParams) }, [queryStringParams])

  React.useEffect(() => {
    const queryFilters = []
    filterOptions.forEach((filterOption) => {
      if (filterOption.initialSelectedFilters) {
        setSelectedChipItems({
          ...selectedChipItems,
          [filterOption.name]: filterOption.initialSelectedFilters
        })
      }
      if (filterOption.initialQueryFilters) {
        queryFilters.push(filterOption.initialQueryFilters)
      }
    })
    if (queryFilters.length > 0) {
      setCategoryFilters(queryFilters)
    }
  }, [])

  const applyFilters = () => {
    const newQueryStringParams = initialQueryStringParams
    if (isRider) {
      const riders = []
      Object.entries(categoryFilters).forEach(([key, value]) => {
        newQueryStringParams[key] = value
        riders.push(key)
      })

      newQueryStringParams.riders = riders
      newQueryStringParams.searchValue = searchFilter
    } else {
      let nonTextSearchFiltersFlattened = []
      Object.entries(categoryFilters).forEach(([key, value]) => {
        if (value) {
          nonTextSearchFiltersFlattened.push(value)
        }
      })
      nonTextSearchFiltersFlattened = nonTextSearchFiltersFlattened.filter((inner) => !Array.isArray(inner))
      const allFilters = []
      if (nonTextSearchFiltersFlattened.length > 0) {
        allFilters.push({
          and: [
            searchFilter,
            ...nonTextSearchFiltersFlattened
          ]
        })
      } else if (searchFilter) {
        searchFilter && allFilters.push(searchFilter)
      }
      newQueryStringParams.filter = allFilters
    }
    if (newQueryStringParams?.dateRanges && Array.isArray(newQueryStringParams?.dateRanges)) {
      if (newQueryStringParams.dateRanges.length > 0) {
        [newQueryStringParams.dateRanges] = newQueryStringParams.dateRanges
      } else {
        newQueryStringParams.dateRanges = {}
      }
    }
    setQueryStringParams(newQueryStringParams)
  }

  React.useEffect(() => {
    applyFilters()
  }, [searchFilter, categoryFilters])

  const applySearchFilter = (newSearchFilter, newSearchValue) => {
    if (isRider) {
      setSearchFilter(newSearchValue)
    } else {
      setSearchFilter(newSearchFilter)
    }
  }

  // filters are the filters used to update the table
  // itemsSelected are human readable items chosen (for chip display) in dictionary format
  const applyCategoryFilters = (newFilters, selectedItems) => {
    setCategoryFilters(newFilters)
    setSelectedChipItems(selectedItems)
  }

  const removeSelection = (filterName, valueToRemove) => {
    const items = selectedChipItems[filterName]
    const newItems = items.filter((item) => item.value !== valueToRemove)
    setSelectedChipItems({
      ...selectedChipItems,
      [filterName]: newItems
    })

    if (filterName === 'callStatusCombos') {
      setCategoryFilters({
        ...categoryFilters,
        [filterName]: newItems
      })
    } else if (filterName === 'dateRanges') {
      setCategoryFilters({
        ...categoryFilters,
        [filterName]: {}
      })
    } else if (filterName === 'divisions') {
      let divisionFilter = null
      if (isRider) {
        divisionFilter = categoryFilters[filterName].filter((div) => div.value !== valueToRemove)
      } else {
        const currentFilters = categoryFilters[filterName]
        if (currentFilters?.val?.or?.length === 1) {
          divisionFilter = null
        } else {
          const orValues = currentFilters.val.or
          currentFilters.val.or = orValues.filter((div) => div.val !== `%${valueToRemove}%`)
          divisionFilter = currentFilters
        }
        categoryFilters[filterName] = divisionFilter
      }
      setCategoryFilters({
        ...categoryFilters,
        [filterName]: divisionFilter
      })
    } else if (filterName === 'userStatus') {
      const statusFilters = getUserStatusesFilter(newItems)
      setCategoryFilters({
        ...categoryFilters,
        [filterName]: statusFilters
      })
    } else {
      setCategoryFilters({
        ...categoryFilters,
        [filterName]: newItems.map((item) => item.value)
      })
    }
  }

  const hasActiveFilters = Object.values(selectedChipItems).some((x) => x.length > 0)

  const clearAllFilters = () => {
    setCategoryFilters({})
    setSelectedChipItems({})
    onQueryStringParamsChange({ ...initialQueryStringParams })
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '72px' }}>
        {title && (
          <Typography variant="h2" sx={{ pl: 0, mt: 2, ml: 2 }}>
            {title}
          </Typography>
        )}
        <Box sx={{ display: 'flex', mr: 2, gap: 2 }}>
          {textSearchQueryFields && (
            <TableSearch
              placeholder={textSearchPlaceholder}
              allowSearch={allowSearch}
              applySearchFilter={applySearchFilter}
              searchFor={textSearchQueryFields}
            />
          )}
          {filterOptions &&
            (
              <TableFilters
                filterOptions={filterOptions}
                applyFilters={applyCategoryFilters}
                selectedItems={selectedChipItems}
                isRider={isRider}
                filterDialogTitle={filterDialogTitle}
              />
            )}
          {onExport && (
            <Button
              color="primary"
              onClick={(e) => onExport(e)}
              sx={{ display: 'flex', flexDirection: 'columns', }}
            >
              <Download />
              <Typography variant="bodyBold" sx={{ ml: 1 }}>Export data</Typography>
            </Button>
          )}
          {extraComponents && extraComponents.map((c) => c)}
        </Box>
      </Box>
      <Box />
      {hasActiveFilters && (
        <Box sx={{ ml: 3, mt: 1, mb: 1, display: 'flex', flexWrap: 'wrap', rowGap: 1, columnGap: '12px' }}>
          <Typography variant="bodyBold" sx={{ mt: '4px' }}>Applied filters:</Typography>
          {Object.entries(selectedChipItems)?.map(([key, value]) => (
            value?.map((item) => (
              <Chip
                key={item.value}
                icon={<Check />}
                label={item.name}
                size="large"
                sx={{ bgcolor: 'neutral.offWhite' }}
                onClick={() => removeSelection(key, item.value)}
              />
            ))
          ))}
          <Chip
            icon={<CloseCircle />}
            label="Clear filters"
            size="large"
            sx={{ bgcolor: 'transparent' }}
            onClick={clearAllFilters}
          />
        </Box>
      )}
    </>
  )
}

FilterGroup.defaultProps = {
  title: null,
  filterDialogTitle: 'Filter table',
  filterOptions: null,
  textSearchQueryFields: null,
  textSearchPlaceholder: null,
  initialQueryStringParams: {},
  allowSearch: true,
  onExport: null,
  isRider: false,
  extraComponents: null
}

FilterGroup.propTypes = {
  title: PropTypes.string,
  filterDialogTitle: PropTypes.string,
  filterOptions: PropTypes.array,
  textSearchQueryFields: PropTypes.array,
  textSearchPlaceholder: PropTypes.string,
  initialQueryStringParams: PropTypes.object,
  onQueryStringParamsChange: PropTypes.func.isRequired,
  allowSearch: PropTypes.bool,
  onExport: PropTypes.func,
  isRider: PropTypes.bool,
  extraComponents: PropTypes.arrayOf(PropTypes.node)
}

export default FilterGroup
