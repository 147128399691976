import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  Card,
  CardContent,
  Typography
} from '@mui/material'
import { IconTextCombo } from 'components'
import { Building, Clock } from 'icons'
import {
  buildJsonApiOne,
  formatDate
} from 'utils-em'

const SurveyCard = ({ surveyId }) => {
  const survey = useSelector(({ data }) => buildJsonApiOne(data, 'surveys', surveyId))
  const { submittedDate } = survey

  const renderCardDetailText = () => (
    <Box typography="caption" sx={{ display: 'flex', alignItems: 'center' }}>
      <IconTextCombo
        icon={<Clock />}
        text={`Submitted ${formatDate(submittedDate)}`}
      />
      <IconTextCombo
        icon={<Building />}
        text={survey.organization?.name}
      />
    </Box>
  )

  return (
    <Card sx={{ mb: 3, ':hover': { bgcolor: 'neutral.offWhite', boxShadow: 4 } }}>
      <CardContent sx={{ width: '100%', textAlign: 'left' }}>
        <Box sx={{
          mb: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        >
          <Typography variant="bodyBold" color="neutral.black">
            {`Survey about your experience at ${survey.organization?.name}`}
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          {renderCardDetailText()}
        </Box>
      </CardContent>
    </Card>
  )
}

SurveyCard.propTypes = {
  surveyId: PropTypes.string.isRequired,
}

export default SurveyCard
