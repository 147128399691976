import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, IconButton, Link, Typography } from '@mui/material'

import Delete from '@mui/icons-material/Delete'
import Image from '@mui/icons-material/Image'
import { gcsFilenameFormatter } from 'utils-em'
import { EditAccountPageContext } from '../EditAccountPageContext'

const UploadLogoButton = ({ ...rest }) => {
  const { id: accountPageId } = useParams()
  const {
    logoFile,
    setLogoFile,
    logo, // base 64 logo
    logoGcsObjectName, // gcs logo name (deprecated)
    MAX_LOGO_SIZE,
    deleteLogo,
    setDeleteLogo
  } = React.useContext(EditAccountPageContext)

  const handleDelete = () => {
    setLogoFile(null)
    setDeleteLogo(true)
  }

  const handleSelectFile = (e) => {
    setLogoFile(e.target.files[0])
    setDeleteLogo(false)
  }

  const renderFileName = () => {
    // logo is deleted
    if (deleteLogo) {
      return null
    }

    // has a too large selected logo
    if (logoFile && logoFile.size > MAX_LOGO_SIZE) {
      return (
        <Typography variant="tiny" color="error.main">
          {`Image file too large (${Math.floor(logoFile.size / 1024)}kb)! Select an image smaller than ${Math.floor(MAX_LOGO_SIZE / 1024)}kb`}
        </Typography>
      )
    }

    // has selected logo
    if (logoFile) {
      return (
        <Typography>{logoFile.name}</Typography>
      )
    }

    // has old logo and no selected logo
    if (logoGcsObjectName) {
      return (
        <Link
          className="logo-file-link"
          target="_blank"
          href={`${__API_HOST__}/v1/accountPages/${accountPageId}/downloadLogo`}
          sx={{ ml: 1 }}
        >
          {gcsFilenameFormatter(logoGcsObjectName)}
        </Link>
      )
    }
    // has new logo and no selected logo
    if (logo) {
      return (
        <Typography>uploaded_image.jpg</Typography>
      )
    }
    return null
  }

  return (
    <Box display="flex" alignItems="center" {...rest}>
      <label htmlFor="logo-file-input">
        <Button
          startIcon={<Image />}
          component="span"
          variant="outlined"
          sx={{ mr: 1 }}
        >
          Upload company logo
        </Button>
        <input
          id="logo-file-input"
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          value=""
          onChange={handleSelectFile}
          style={{ display: 'none' }}
        />
      </label>
      <>
        {renderFileName()}
        {renderFileName() && (
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        )}
      </>
    </Box>

  )
}

export default UploadLogoButton
