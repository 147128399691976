import React from 'react'
import { useLocation } from 'react-router-dom'
import { recordUserAction, ACTION_CONSTANTS } from 'utils-em'

export default function useRecordPageView () {
  const { pathname } = useLocation()

  const recordPageViewUserAction = () => {
    const id = pathname?.split('/')?.slice(-1)?.[0] || null

    // customer user
    if (pathname === '/c') recordUserAction(ACTION_CONSTANTS.viewedHomePage)
    if (pathname === '/c/teams') recordUserAction(ACTION_CONSTANTS.viewedMyTeam)
    if (pathname === '/c/activity') recordUserAction(ACTION_CONSTANTS.viewedMyActivity)
    if (pathname === '/c/profile') recordUserAction(ACTION_CONSTANTS.viewedMyProfile)
    if (pathname === '/c/team-activity') recordUserAction(ACTION_CONSTANTS.viewedTeamActivity)
    if (pathname.startsWith('/c/seller-activity/')) recordUserAction(ACTION_CONSTANTS.viewedSellerProfile, { id }, 'customerUser')
    if (pathname.match(/c\/engagements\/$/gi)) recordUserAction(ACTION_CONSTANTS.viewedEngagementDetails, { id }, 'engagement')
    if (pathname.startsWith('/c/accounts/browse/alphabetical')) recordUserAction(ACTION_CONSTANTS.viewedAccountsAlphabetically)
    if (pathname.startsWith('/c/accounts/browse/by-industry')) recordUserAction(ACTION_CONSTANTS.viewedAccountsByIndustry)

    // advisor user
    if (pathname === '/a') recordUserAction(ACTION_CONSTANTS.viewedHomePage)
  }

  React.useEffect(() => { pathname && recordPageViewUserAction() }, [pathname])
}
