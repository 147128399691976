import { connect } from 'react-redux'
import { buildJsonApiAll } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'

import AdvisorExperienceCard from './AdvisorExperienceCard'

const mapStateToProps = ({ data, enums, session }, ownProps) => ({
  userIsAdmin: session.userType === 'admin',
  tenureEnums: enums.tenure,
  companies: buildJsonApiAll(data, 'companies').filter((c) => c.advisorId === ownProps.advisorId)
})

const mapDispatchToProps = {
  saveCompany: (company) => JsonAPI.save(company),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorExperienceCard)
