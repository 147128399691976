import React from 'react'
import PropTypes from 'prop-types'
import {
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Box,
} from '@mui/material'
import {
  ChevronDown,
  ChevronUp,
  Sort
} from 'icons'

const AdvisorsListSort = ({ onSortChange, defaultSort, excludedSorts }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuRef = React.useRef()
  const sortItems = [
    {
      label: 'Newest to Emissary',
      value: '-onboarding_completed'
    },
    {
      label: 'Departure date',
      value: 'departure'
    },
    {
      label: 'First name',
      value: 'first_name',
    },
    {
      label: 'Company name',
      value: 'company',
    },
    {
      label: 'Tenure',
      value: 'tenure',
    }
  ].filter((s) => !excludedSorts.includes(s.value))
  const [selectedItem, setSelectedItem] = React.useState(sortItems.find((s) => s.value === defaultSort) || sortItems[0])

  return (
    <>
      <IconButton
        disableRipple
        ref={menuRef}
        data-link-ref="open-menu"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{ bgcolor: 'transparent', mr: 4 }}
      >
        <Box sx={{ display: 'flex' }}>
          <Sort />
          <Typography sx={{ mr: 2, ml: 1 }}>
            Sort by:
            {' '}
            {selectedItem.label}
          </Typography>
          {anchorEl ? <ChevronUp /> : <ChevronDown />}
        </Box>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 0, horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{ mt: 6, zIndex: 9999 }}
      >
        {
          sortItems.map((item) => (
            <MenuItem
              key={item.value}
              onClick={() => {
                setSelectedItem(item)
                onSortChange(item.value)
                setAnchorEl(null)
              }}
            >
              <ListItemText
                key={item.value}
                primary={(
                  <Typography>
                    {item.label}
                  </Typography>
                )}
              />
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}

AdvisorsListSort.defaultProps = {
  excludedSorts: []
}

AdvisorsListSort.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  defaultSort: PropTypes.oneOf(['-onboarding_completed', 'departure', 'first_name', 'company', 'tenure']).isRequired,
  excludedSorts: PropTypes.arrayOf(PropTypes.string),
}

export default AdvisorsListSort
