import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Link,
  Typography
} from '@mui/material'

import {
  AddToCalendarButton
} from 'components'
import {
  Calendar,
  Clock
} from 'icons'
import {
  addToCalendar,
  copyToClipboardWithAlert,
  formatDate,
  formatTimeRange,
  navigateTo,
  confirmDialog
} from 'utils-em'

import { useEngagement } from 'hooks'
import GreyBox from './GreyBox'

const UpcomingCallBox = ({ engagementId }) => {
  const {
    relevantTimeslot,
    isCallToday,
    isCallInThirty,
    zoomMeetingJoinLink,
    zoomMeetingId,
    isAdvisor,
    isParticipant
  } = useEngagement(engagementId)
  const { id: timeslotId, startTime, endTime } = relevantTimeslot || {}

  const CallTimeInfo = () => {
    // eslint-disable-next-line react/prop-types
    const IconText = ({ Icon, text }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon sx={{ color: 'neutral.darkGrey', mr: 1 }} />
        <Typography variant="bodyBold">{text}</Typography>
      </Box>
    )
    return (
      <>
        <IconText Icon={Calendar} text={formatDate(startTime, { longMonth: true, includeDayOfWeek: true, includeYear: true })} />
        <IconText Icon={Clock} text={formatTimeRange(startTime, endTime, { showTimezoneSuffix: true })} />
      </>
    )
  }

  const AddToCalendarButtonGroup = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 2, rowGap: 1 }}>
      <Button variant="contained" onClick={() => addToCalendar(timeslotId, 'gcal')}>
        Add to Google Cal
      </Button>
      <Button variant="contained" onClick={() => addToCalendar(timeslotId, 'ics')}>
        Add to Outlook or iCal
      </Button>
    </Box>
  )

  const rescheduleDialog = () => {
    confirmDialog({
      title: 'Reschedule',
      description: 'Can\'t make the upcoming call? You will be able to propose new times and all participants will be notified.',
      actions: [
        {
          name: 'Reschedule call',
          action: () => {
            navigateTo(`/${isAdvisor ? 'a' : 'c'}/engagements/${engagementId}#reschedule`)
          }
        }
      ],
      size: 'sm'
    })
  }

  const DayOfCallActions = () => (
    <>
      <Box sx={{ typography: 'body1' }}>
        Zoom Meeting Link:&nbsp;
        <Link onClick={() => copyToClipboardWithAlert(zoomMeetingJoinLink)}>
          {zoomMeetingJoinLink}
        </Link>
        <br />
        {`ID: ${zoomMeetingId}`}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 0, rowGap: 1 }}>
        <Button variant="contained" target="_blank" href={zoomMeetingJoinLink} disabled={!isCallInThirty} sx={{ ml: 0 }}>
          Join Zoom meeting
        </Button>
        <AddToCalendarButton timeslotId={timeslotId} />
      </Box>
    </>
  )

  const RescheduleCallButton = () => (
    <Box>
      <Button sx={{ m: 0 }} onClick={rescheduleDialog}>
        Reschedule call
      </Button>
    </Box>
  )

  return (
    <>
      <GreyBox>
        <CallTimeInfo />
        {!isCallToday && <AddToCalendarButtonGroup />}
        {isCallToday && <DayOfCallActions />}
        {!isParticipant && <RescheduleCallButton />}
      </GreyBox>
    </>
  )
}

UpcomingCallBox.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default UpcomingCallBox
