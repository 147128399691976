import PropTypes from 'prop-types'
import React from 'react'

import Box from '@mui/material/Box'

import { AdvisorProfileBuilder } from 'components'

const AdvisorEditorLayout = ({ advisorId }) => (
  <Box sx={{ mt: 6, flex: 1 }}>
    <AdvisorProfileBuilder advisorId={advisorId} />
  </Box>
)

AdvisorEditorLayout.defaultProps = {
  advisorId: null
}

AdvisorEditorLayout.propTypes = {
  advisorId: PropTypes.string
}

export default AdvisorEditorLayout
