import React from 'react'
import {
  Box,
  Divider,
  Typography
} from '@mui/material'
import {
  Calendar,
  Message,
  Phone
} from 'icons'
import {
  IconTextCombo,
} from 'components'

const COACH_ALERT_LIST = [
  { Icon: Phone, text: 'Call proposal notifications' },
  { Icon: Calendar, text: 'Scheduling reminders and confirmation' },
  { Icon: Message, text: 'Email relay messages between you and the client' },
]

const NotificationPreferences = () => (
  <>
    <Typography variant="h2" sx={{ mt: 2 }}>Notification preferences</Typography>
    <Divider sx={{ mt: 3, mb: 3, borderColor: 'brand.paleSlate' }} />
    <Typography variant="h4">Emails</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
      <Typography variant="bodyBold">Emissary Coach engagement alerts</Typography>
      <Typography variant="caption" color="neutral.darkGrey">
        {`You will receive email alerts about call proposals and any upcoming calls 
        you have with a client, including:`}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {COACH_ALERT_LIST.map(({ Icon, text }) => (
          <IconTextCombo
            key={text}
            icon={<Icon />}
            text={text}
            iconColor="neutral.darkGrey"
            typographyColor="neutral.black"
          />
        ))}
      </Box>
    </Box>
  </>
)

export default NotificationPreferences
