import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'
import { Tabs } from 'components'

import BuildSurveyTab from './components/BuildSurveyTab'
import ManageSurveysTab from './components/ManageSurveysTab'
import ViewResponsesTab from './components/ViewResponsesTab'

const Surveys = () => (
  <Box sx={{ width: '100%' }}>
    <Box sx={{ pt: 0, pb: 4 }}>
      <Typography variant="h2">
        Surveys
      </Typography>
      <Tabs
        tabs={[
          { name: 'Manage', component: <ManageSurveysTab />, hash: '#manage' },
          { name: 'Responses', component: <ViewResponsesTab />, hash: '#responses' },
          { name: 'Build', component: <BuildSurveyTab />, hash: '#build' },
        ]}
      />
    </Box>
  </Box>
)

export default Surveys
