import React from 'react'
import PropTypes from 'prop-types'
import {
  DivisionPickerPersonalized
} from 'components'
import { buildJsonApiFilter } from 'utils-em'

import {
  Typography
} from '@mui/material'

const DivisionFilter = ({ filter, onChange, currentState, isRider }) => {
  const [teams, setTeams] = React.useState(currentState)

  React.useEffect(() => {
    const teamIds = teams?.map((t) => t.value)
    if (teamIds) {
      if (isRider) {
        onChange('divisions', teamIds, teams)
      } else {
        onChange('divisions', buildJsonApiFilter(filter, teamIds), teams)
      }
    }
  }, [teams])

  const handleChange = (divisionIds, divisionNames) => {
    const selectedDivisions = divisionIds.map((teamId, index) => ({ value: teamId, name: divisionNames ? divisionNames[index] : '' }))
    setTeams(selectedDivisions)
  }

  return (
    <>
      <Typography variant="captionBold">
        Teams
      </Typography>
      <DivisionPickerPersonalized
        sx={{ mt: 2 }}
        onChange={handleChange}
        selectedIds={currentState?.map((t) => t.value)}
      />
    </>
  )
}

DivisionFilter.defaultProps = {
  isRider: false,
  currentState: []
}

DivisionFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.array,
  filter: PropTypes.string.isRequired,
  isRider: PropTypes.bool
}

export default DivisionFilter
