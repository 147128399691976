import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Typography } from '@mui/material'

const CardWithIcon = ({ text, icon }) => (
  <Card sx={{
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'brand.lightNavy',
    mt: 3,
    p: 3,
    borderWidth: 0,
    borderRadius: 1
  }}
  >
    <Box sx={{
      backgroundColor: 'brand.paleSlate',
      borderRadius: 4,
      height: 32,
      minWidth: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0.5,
      alignSelf: 'center'
    }}
    >
      {icon}
    </Box>
    <Typography variant="body1" color="brand.navy" sx={{ ml: 3 }}>
      {text}
    </Typography>
  </Card>
)

CardWithIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default CardWithIcon
