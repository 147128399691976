import PropTypes from 'prop-types'
import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material'
import { SaveAccountListOrgWrapper } from 'components'
import { closeDialog } from 'utils-em'

const EmptyAccountDialog = ({ orgId }) => (
  <Dialog
    open
    maxWidth="xs"
    scroll="paper"
    onClose={closeDialog}
  >
    <DialogTitle>
      Stay tuned!
    </DialogTitle>
    <DialogContent>
      <Typography>
        Stay tuned! While this account is part of The Emissary Account List, we’re
        still in the process of recruiting advisors and gathering insights for it.&nbsp;
        <SaveAccountListOrgWrapper orgId={orgId}>
          <Link>Save this account</Link>
        </SaveAccountListOrgWrapper>
        &nbsp;to be notified when information is available.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="text" onClick={(closeDialog)}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

EmptyAccountDialog.propTypes = {
  orgId: PropTypes.number.isRequired,
}

export default EmptyAccountDialog
