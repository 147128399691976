import { connect } from 'react-redux'

import AdvisorExperienceInfo from './AdvisorExperienceInfo'
import Alerts from '../../../store/Alerts'

const mapStateToProps = ({ session }) => ({
  userIsAdmin: session.userType === 'admin'
})

const mapDispatchToProps = {
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message))
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorExperienceInfo)
