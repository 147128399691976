import { connect } from 'react-redux'

import { buildJsonApiOne } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'

import EngagementDetail from './EngagementDetail'

const mapStateToProps = ({ data }, ownProps) => ({
  engagementId: parseInt(ownProps.match.params.id, 10),
  engagement: buildJsonApiOne(
    data,
    'engagements',
    parseInt(ownProps.match.params.id, 10)
  )
})

const mapDispatchToProps = {
  loadEngagementData: (engagementId) => JsonAPI.getOne({
    type: 'engagements',
    id: engagementId,
    queryStringParams: { 'lazy[engagements]': 'step,transcriptExpertiseResult' },
    include: [
      'assets',
      'customerUser',
      'customerUser.customer',
      'participants',
      'participants.user',
      'advisor',
      'organization',
      'messages',
      'charges',
      'charges.divisions',
      'charges.sku',
      'expertiseRatings',
      'payment'
    ].join(',')
  }),
  loadTimeslots: (engagementId) => JsonAPI.getOne({
    type: 'engagements',
    id: engagementId,
    include: [
      'timeslotProposals',
      'timeslotProposals.timeslots',
      'timeslotProposals.initiatingUser',
      'timeslotProposals.respondingUser',
      'timeslotProposals.decliningUser'
    ].join(',')
  }),
  loadEvents: (engagementId) => JsonAPI.getOne({
    type: 'engagements',
    id: engagementId,
    include: [
      'events'
    ].join(',')
  }),
  saveEngagement: (engagementData) => JsonAPI.save({ ...engagementData, type: 'engagements' }),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementDetail)
