import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Paperclip (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M20 11.4727L12.4356 19.0371C11.5089 19.9638 10.252 20.4844 8.94144 20.4844C7.63089 20.4844 6.37401 19.9638 5.44731 19.0371C4.52061 18.1104 4 16.8535 4 15.543C4 14.2324 4.52061 12.9755 5.44731 12.0488L13.0117 4.48441C13.6295 3.86661 14.4675 3.51953 15.3412 3.51953C16.2149 3.51953 17.0528 3.86661 17.6706 4.48441C18.2884 5.10221 18.6355 5.94012 18.6355 6.81382C18.6355 7.68753 18.2884 8.52544 17.6706 9.14324L10.0979 16.7077C9.78902 17.0166 9.37006 17.1901 8.93321 17.1901C8.49636 17.1901 8.0774 17.0166 7.7685 16.7077C7.4596 16.3988 7.28606 15.9798 7.28606 15.543C7.28606 15.1061 7.4596 14.6872 7.7685 14.3783L14.7568 7.39824" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
