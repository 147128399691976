import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Link
} from '@mui/material'
import { useJsonAPISelectOne } from 'hooks'

const AdvisorCallCompletedText = ({ engagementId, ...rest }) => {
  const { advisor, payment, zoomRecordingStatus } = useJsonAPISelectOne('engagements', engagementId)

  const renderText = () => {
    // payment has been made OR successful zoom upload and payable advisor
    if (payment?.paymentStatus || (zoomRecordingStatus === 'upload_successful' && advisor.isPayable)) {
      return (
        <>
          Now that your call has occurred, you can expect to receive an email confirmation in
          our next weekly pay cycle that your payment has been sent. You can confirm or
          modify your payment information in your Preferences section. If you require
          assistance, reach out to us at
        </>
      )
    }

    // successful zoom upload and non-payable advisor
    if (zoomRecordingStatus === 'upload_successful' && !advisor.isPayable) {
      return (
        <>
          Now that your call has occurred, you can expect to receive payment within 7 days.
          In order to receive payment you must complete your payment details first. Please
          input your payment information in your&nbsp;
          <Link href="/a/profile/edit#payment-info">Preferences</Link>
          &nbsp;section. You will receive an email confirmation once payment has been sent.
          If you require assistance, reach out to us at
        </>
      )
    }

    // pending zoom upload within 24hrs of call
    if (zoomRecordingStatus === 'upload_pending') {
      return (
        <>
          Payment for completed calls are made as part of our weekly payment cycle via our
          e-pay partner, Tipalti. We will have further details for you soon. If you have any
          questions in the meantime, please email us at
        </>
      )
    }

    // (default) unsuccessful zoom upload after 24hrs or recording too short
    return (
      <>
        It appears your scheduled call did not take place and therefore payment will not
        be issued. To reschedule the call or if you have any questions regarding payment,
        please email us at
      </>
    )
  }

  return (
    <Box typography="body1" {...rest}>
      {renderText()}
      &nbsp;
      <Link href="mailto:advisor-support@emissary.io" target="_blank">advisor-support@emissary.io</Link>
      .
    </Box>
  )
}

AdvisorCallCompletedText.propTypes = {
  engagementId: PropTypes.number.isRequired
}

export default AdvisorCallCompletedText
