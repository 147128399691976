import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import {
  Box,
  Button,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import {
  buildJsonApiOne,
  customAlert,
  getDaysUntilProposalExpiration,
  getMostRecentProposal,
  formatDate,
  formatTimeAgoForHumans,
  formatTimeslotForHumans,
  httpResponseAlert,
  confirmDialog,
  openDialog
} from 'utils-em'
import {
  AddParticipantsSection,
  CallSchedulingDialog,
  ConnectByEmailSection,
  EngagementAccessControl
} from 'components'
import { JsonAPI } from 'store'

const EngagementSchedulingStep = ({ engagementId, isAdvisor, context }) => {
  const dispatch = useDispatch()
  const sessionUser = useSelector(({ session }) => session)

  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const [conflictingIds, setConflictingIds] = React.useState([])
  const [isAccepting, setIsAccepting] = React.useState(false)
  const activeProposal = getMostRecentProposal(engagement)
  const { customerUser, advisor } = engagement

  const isProposer = activeProposal && activeProposal.initiatingUserId === sessionUser.id
  const otherPersonFirstName = isAdvisor ? customerUser.firstName : advisor.firstName
  const columnWidth = isProposer ? '50%' : '33%'
  const primaryCustomerUser = customerUser.firstName
  const isProposedState = engagement.state === 'proposed'

  const getConflictingProposals = async () => {
    try {
      const res = await fetch(`${__API_HOST__}/engagements/${engagementId}/conflicts/`, { method: 'GET', credentials: 'include' })
      if (res.error) throw new Error(res.error.message)
      const body = await res.json()
      setConflictingIds(body.conflictingIds)
    } catch (error) {
      customAlert('Cannot connect to network, please contact support', true)
    }
  }

  React.useEffect(() => {
    getConflictingProposals()
  }, [])

  const renderProposalAction = () => {
    if (activeProposal && !isProposer) {
      return (
        <Link variant="bodyBold" underline="hover" onClick={handlePropose}>
          None of these times work for me
        </Link>
      )
    }
    return (
      <>
        <Button variant="contained" onClick={handlePropose} sx={{ mt: 2 }}>
          {activeProposal ? 'Modify call times' : 'Propose call times'}
        </Button>
      </>
    )
  }

  const acceptTimeslot = async (timeslot) => {
    setIsAccepting(true)
    try {
      const resp = await dispatch(JsonAPI.save({
        ...timeslot,
        isSelected: true,
        acceptProposal: true,
        include: 'proposal,proposal.engagement',
        riders: ['acceptProposal'],
        queryStringParams: { 'lazy[engagements]': 'step' }
      }))
      httpResponseAlert(resp, { success: `Call scheduled for ${formatTimeslotForHumans(timeslot)}` })
    } catch (error) {
      customAlert('Something went wrong. Please contact support.', true)
    }
    setIsAccepting(false)
  }

  const openSchedulingDialog = () => {
    openDialog(
      <CallSchedulingDialog
        engagementId={parseInt(engagement.id, 10)}
      />
    )
  }

  const handlePropose = () => {
    if (activeProposal) {
      confirmDialog({
        title: isProposer ? 'Modify call times' : 'Propose alternate times',
        description: isProposer
          ? `Modifying your call times overrides any previous times you have suggested
          and all participants will be notified.`
          : `If none of the proposed times work, please propose a new set of times.
          Note: this will cancel out the times ${otherPersonFirstName} proposed and
          all participants will be notified.`,
        actions: [
          {
            name: 'Propose new times',
            action: () => openSchedulingDialog()
          }
        ],
        cancelName: 'Keep times',
        size: 'sm'
      })
    } else {
      openSchedulingDialog()
    }
  }

  const renderContent = () => {
    // no active proposal
    let title = `Schedule your call with ${otherPersonFirstName}`
    let text = 'Propose call times to get started. Zoom details will be provided once the call is scheduled.'

    if (activeProposal && !isProposer) {
      text = (`${otherPersonFirstName} has proposed the times below for a 60 minute call. Please select the slot that works best for ` +
      'you, or click on the link below if none of these times work. Final call details will be provided upon the ' +
      'selection of a mutually-available time.')
    }

    if (activeProposal && isProposer) {
      text = (`Thanks for proposing some time to connect with ${isAdvisor ? otherPersonFirstName : 'this advisor'}! The slots below are being reviewed ` +
        `by ${isAdvisor ? otherPersonFirstName : 'your advisor'}. Call details will be sent when a mutual time is agreed upon and the call is scheduled.`)
    }

    if (isProposedState && isProposer && !isAdvisor) {
      title = `${otherPersonFirstName} is reviewing your call proposal`
      text = `The advisor has ${getDaysUntilProposalExpiration(engagement)} day${getDaysUntilProposalExpiration(engagement) > 1 ? 's' : ''} to review your proposal. Once they reach a decision, you’ll receive an email notification.`
    }

    return (
      <>
        <Box typography="h4" color="neutral.black" sx={{ display: 'flex' }}>
          {title}
        </Box>
        <Box typography="body1" color="neutral.black" sx={{ pt: 3 }}>
          {text}
        </Box>
        {activeProposal && (
        <Box typography="caption" color="neutral.disabled" sx={{ mt: '4px' }}>
          {formatTimeAgoForHumans(activeProposal.dateCreated)}
        </Box>
        )}
        {activeProposal && (
          <Table size="small" sx={{ mt: 3, '& .MuiTableCell-root': { textAlign: 'left' } }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: columnWidth }}>Day</TableCell>
                <TableCell sx={{ width: columnWidth }}>Time</TableCell>
                {!isProposer ? (<TableCell sx={{ width: columnWidth }}>Actions</TableCell>) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortBy(activeProposal.timeslots, 'startTime').map((timeslot) => {
                const isTimeExpired = Date.parse(timeslot.startTime) < Date.parse(new Date())
                const isTimeConflicted = !isTimeExpired && conflictingIds.some((id) => id === parseInt(timeslot.id, 10))
                const isTimeDisabled = isTimeExpired || isTimeConflicted
                return (
                  <TableRow
                    key={timeslot.id}
                    sx={{ '& .MuiTableCell-root': { color: isTimeDisabled ? 'neutral.disabled' : undefined } }}
                  >
                    <TableCell sx={{ width: columnWidth }}>
                      {formatDate(timeslot.startTime, { includeYear: true })}
                      {isTimeExpired ? ' - Time expired' : ''}
                      {isTimeConflicted ? ' - Time unavailable' : ''}
                    </TableCell>
                    <TableCell sx={{ width: columnWidth }}>
                      {formatTimeslotForHumans(timeslot, false)}
                    </TableCell>
                    {!isProposer ? (
                      <TableCell sx={{ width: columnWidth }}>
                        <Link
                          disabled={isTimeDisabled || isAccepting}
                          underline="hover"
                          onClick={(_) => {
                            !isTimeDisabled &&
                              confirmDialog({
                                title: 'Accept call time',
                                description: `Do you want to confirm your call for ${formatTimeslotForHumans(timeslot)}?`,
                                actions: [{ name: 'Confirm', action: () => acceptTimeslot(timeslot) }],
                                size: 'sm'
                              })
                          }}
                        >
                          Accept
                        </Link>
                      </TableCell>
                    ) : null}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </>
    )
  }

  return (
    <>
      <EngagementAccessControl>
        <Box sx={{ bgcolor: 'brand.lightNavy', p: 3, borderRadius: 1 }}>
          {renderContent()}
          <Box sx={{ mt: 2 }}>
            {renderProposalAction()}
          </Box>
        </Box>
      </EngagementAccessControl>
      <EngagementAccessControl nonPrimary>
        <Box typography="h4" color="brand.navy" sx={{ display: 'flex' }}>
          {`${primaryCustomerUser} is scheduling the call with ${otherPersonFirstName}`}
        </Box>
        <Box typography="body1" color="neutral.black" sx={{ pt: 3 }}>
          Zoom details will be provided once the call is scheduled.
        </Box>
      </EngagementAccessControl>
      {!isAdvisor && !isProposedState && <AddParticipantsSection engagementId={engagement.id} context={context} />}
      {!isProposedState && (
        <ConnectByEmailSection engagementId={engagementId} />
      )}
    </>
  )
}

EngagementSchedulingStep.defaultProps = {
  isAdvisor: false,
  context: {}
}

EngagementSchedulingStep.propTypes = {
  engagementId: PropTypes.number.isRequired,
  isAdvisor: PropTypes.bool,
  context: PropTypes.object
}

export default EngagementSchedulingStep
