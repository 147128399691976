import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { IconTextCombo } from 'components'
import { Briefcase, Calendar, Grid, Pin } from 'icons'
import {
  formatMonthYearForHumans,
  getAdvisorSeniorityShorthand,
  getRoleFunction,
  getTagsByCategory
} from 'utils-em'

const RoleIconDetailsTray = ({ role, typography = 'body1', typographyColor = 'neutral.black', iconSize = 'small', iconColor = 'neutral.darkGrey' }) => {
  const getRoleRegion = (r) => {
    const allRegions = getTagsByCategory(r.tags, 'region')
    return allRegions.length ? allRegions[0].name : null
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <IconTextCombo
        icon={<Calendar />}
        text={`${formatMonthYearForHumans(role.dateStarted, true)} - ${role.dateEnded ? formatMonthYearForHumans(role.dateEnded, true) : 'Present'}`}
        typography={typography}
        typographyColor={typographyColor}
        iconSize={iconSize}
        iconColor={iconColor}
      />
      {role.seniority && (
        <IconTextCombo
          icon={<Briefcase />}
          text={getAdvisorSeniorityShorthand(role.seniority)}
          typography={typography}
          typographyColor={typographyColor}
          iconSize={iconSize}
          iconColor={iconColor}
        />
      )}
      {role.tags && getRoleRegion(role) && (
        <IconTextCombo
          icon={<Pin />}
          text={`Scope: ${getRoleRegion(role)}`}
          typography={typography}
          typographyColor={typographyColor}
          iconSize={iconSize}
          iconColor={iconColor}
        />
      )}
      {getRoleFunction(role).map((f) => (
        <IconTextCombo
          icon={<Grid />}
          text={f}
          key={f}
          typography={typography}
          typographyColor={typographyColor}
          iconSize={iconSize}
          iconColor={iconColor}
        />
      ))}
    </Box>
  )
}

RoleIconDetailsTray.propTypes = {
  role: PropTypes.object.isRequired,
  typography: PropTypes.string,
  typographyColor: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string
}

export default RoleIconDetailsTray
