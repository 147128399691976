import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@mui/material'

import APILoaderContainer from '../../../../../../../containers/APILoaderContainer'
import ContractsList from '../components/ContractsList'

const ContractsLayout = ({ customerId, getAllSkus }) => (
  <APILoaderContainer
    onMount={(done) => {
      getAllSkus().then(() => { done() })
    }}
  >
    <Box sx={{ width: '100%' }}>
      <ContractsList customerId={customerId} />
    </Box>
  </APILoaderContainer>
)

ContractsLayout.propTypes = {
  customerId: PropTypes.number.isRequired,
  getAllSkus: PropTypes.func.isRequired
}

export default ContractsLayout
