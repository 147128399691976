import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'

import {
  downloadAssetFromGcs,
  closeDialog,
  customAlert
} from 'utils-em'

const DownloadDialog = ({ asset }) => (
  <Dialog
    open
    maxWidth="xs"
    scroll="paper"
    onClose={closeDialog}
  >
    <DialogTitle>
      Download&nbsp;
      {asset.title}
    </DialogTitle>
    <DialogContent>
      <Typography>
        This file is licensed to you under the Emissary Inc. customer agreement for your organization's internal use only - you may not redistribute, sell, license or otherwise transfer it without Emissary's prior written approval.
        Although the information in this file is believed to have been obtained from reliable sources, much of this information is subjective in nature and in all cases, Emissary makes no representation as to its accuracy or completeness.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="text" onClick={closeDialog}>
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={async () => {
          try {
            await downloadAssetFromGcs(asset.id, asset.documentGcsObjectName)
          } catch (error) {
            customAlert('Failed to download asset. Please contact support.', true)
          }
          closeDialog()
        }}
      >
        Download
      </Button>
    </DialogActions>
  </Dialog>
)

DownloadDialog.propTypes = {
  asset: PropTypes.object.isRequired
}

export default DownloadDialog
