import { connect } from 'react-redux'
import { Alerts, Dialogs, JsonAPI } from 'store'

import AbsorbOrganizationDialog from './AbsorbOrganizationDialog'

const mapDispatchToProps = {
  saveOrg: (org) => JsonAPI.save(Object.assign(org, {
    riders: ['orgToAbsorbId']
  })),
  deleteOrg: (org) => JsonAPI.delete({ ...org, type: 'organizations' }),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  closeDialog: (dialog, dialogToClose) => (dispatch) => dispatch(Dialogs.closeDialog(dialogToClose)),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(null, mapDispatchToProps)(AbsorbOrganizationDialog)
