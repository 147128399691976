import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Box, TextField } from '@mui/material'
import Flags from 'country-flag-icons/react/3x2'
import { uniqBy } from 'lodash'

import countryData from '../MuiPhoneNumber/country_data'

const getCountries = (unitedStatesFirstOption) => {
  let countries = uniqBy(
    countryData.rawAllCountries.map((country) => ({
      name: country[0],
      code: country[2]
    })),
    ({ name }) => name
  )

  if (unitedStatesFirstOption) {
    // Find the United States element, remove it from the array, and replace it in the front (top)
    const unitedStatesIdx = countries.findIndex((c) => c.code === 'us')
    const unitedStatesElementRemoved = countries.splice(unitedStatesIdx, 1)[0]
    countries = [unitedStatesElementRemoved, ...countries]
  }

  return countries
}

const CountrySelector = ({ label, defaultValue, unitedStatesFirstOption, onChange, ...rest }) => {
  const countries = getCountries(unitedStatesFirstOption)
  const [value, setValue] = React.useState(defaultValue && countries.find((country) => country.code === defaultValue))

  React.useEffect(() => {
    if (value && value?.code !== defaultValue) { // don't raise onChange event for default value being provided or null selections
      onChange(value)
    }
  }, [value])

  return (
    <Autocomplete
      {...rest}
      options={countries}
      getOptionLabel={(country) => country.name}
      isOptionEqualToValue={(option, option2) => option?.code === option2?.code}
      value={value}
      onChange={(e, newValue) => setValue(newValue)}
      onInputChange={(event, newValue, reason) => reason === 'clear' && setValue(null)}
      renderOption={(props, option) => {
        const FlagComponent = Flags[option.code.toUpperCase()]

        if (unitedStatesFirstOption && option.code === 'us') {
          // Dividing line below United States option if it's at the top of the list
          // <Divider /> wouldn't work in the rendered option, so use styling here instead
          props.sx = { // eslint-disable-line
            ...props.sx, // eslint-disable-line
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
          }
        }

        return (
          <Box component="li" {...props}>
            {Boolean(FlagComponent) && (
              <Box sx={{ width: '24px', height: '18px', mr: 1 }}>
                <FlagComponent />
              </Box>
            )}
            {option.name}
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="large"
          variant="outlined"
          label={label}
          sx={{ backgroundColor: 'neutral.white' }}
        />
      )}
    />
  )
}

CountrySelector.defaultProps = {
  label: 'Country',
  defaultValue: null,
  unitedStatesFirstOption: true,
  onChange: () => {}
}

CountrySelector.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  unitedStatesFirstOption: PropTypes.bool,
  onChange: PropTypes.func // emits { name: '', code: '' }
}

export default CountrySelector
