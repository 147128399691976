import { connect } from 'react-redux'
import { buildJsonApiOrdered } from 'utils-em'

import { map } from 'lodash'
import { Alerts, Dialogs, JsonAPI } from 'store'

import OrganizationList from './OrganizationList'

const mapStateToProps = ({ data }) => ({
  organizations: buildJsonApiOrdered(data, 'organizations'),
  organizationsCount: data.meta.organizations.count
})

const mapDispatchToProps = {
  getOrgs: (size, page, sort, searchFilters = [], abortController = null) => JsonAPI.getAll({
    type: 'organizations',
    queryStringParams: {
      include: 'tags',
      'page[number]': page,
      'page[size]': size,
      sort,
      filter: map(searchFilters, (v, k) => v),
    },
    abortController
  }),
  createNewOrg: (org) => JsonAPI.create(org),
  deleteOrg: (org) => JsonAPI.delete(org),
  getAssociatedEngagements: (org) => JsonAPI.getAll({
    type: 'engagements',
    queryStringParams: { 'filter[organizationId]': org.id }
  }),
  updateJsonAPI: (org) => JsonAPI.save(org),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message))
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList)
