import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography
} from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { emissaryTheme } from 'themes'
import { useAdvisor, useJsonAPIGetAll, useJsonAPIUpsert } from 'hooks'
import { SurveySelector } from 'components'

import {
  closeDialog,
  copyToClipboard,
  customAlert,
  validateSurveyIsAssignable
} from 'utils-em'

const PhoneOnboardingDialog = ({ advisorId, refreshUserTable }) => {
  const { advisor, currentCompany, companies } = useAdvisor(advisorId)
  const { data: surveys, loaded: surveysLoaded } = useJsonAPIGetAll('surveys')
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const [isLoading, setIsLoading] = React.useState(false)
  const [validation, setValidation] = React.useState(null)
  const [survey, setSurvey] = React.useState(null)
  const [error, setError] = React.useState(false)
  const [surveyLink, setSurveyLink] = React.useState()

  const populateInitialSurvey = () => {
    if (currentCompany) {
      return surveys.find((s) => !s.isArchived && s.isInseat)
    }
    if (companies?.length) {
      return surveys.find((s) => !s.isArchived && s.isOnboarding)
    }
    return null
  }

  const handleValidate = async (surveyId, advisorEmail) => {
    setIsLoading(true)
    const validationData = await validateSurveyIsAssignable(surveyId, [advisorEmail], !!currentCompany)
    setValidation(validationData?.[0])
    setError(!validationData?.[0]?.valid)
    setIsLoading(false)
  }

  const onSurveyChange = (e) => {
    if (!e) {
      return
    }
    handleValidate(e.id, advisor.email)
    setSurvey(e)
  }

  const createLink = () => {
    if (validation?.valid) {
      const { organization_name: employer, organization_id: orgId } = validation
      const link = `${survey.typeformShareUrl}#employer=${employer}&org_id=${orgId}&user_id=${advisorId}&method=recruiter_onboarding`
      copyToClipboard(
        link,
        () => customAlert('Link copied to clipboard'),
        () => setSurveyLink(link)
      )
    } else {
      customAlert('Link generation failed.', true)
    }
  }

  const markComplete = async () => {
    await upsert({
      type: 'advisors',
      id: advisorId,
      sendInvite: true,
      createOnboardingAssignment: true,
      phoneOnboardingCompletedDate: new Date().toISOString(),
      riders: ['sendInvite', 'createOnboardingAssignment']
    })
    refreshUserTable()
    closeDialog()
  }

  const close = () => {
    refreshUserTable()
    closeDialog()
  }

  if (!surveysLoaded) return null

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={emissaryTheme}>
        <Dialog
          open
          onClose={close}
          size="xl"
        >
          <DialogTitle>Onboard over phone</DialogTitle>
          <DialogContent sx={{ minWidth: '600px', display: 'flex', flexDirection: 'column', paddingX: 3 }}>
            <Box>
              <Box sx={{ paddingY: 1.5 }}>
                <Typography variant="body">
                  <Typography variant="bodyBold" sx={{ display: 'inline' }}>Step 1: </Typography>
                  Select the appropriate survey if you are completing it on behalf of the advisor. It will open in Typeform.
                </Typography>
              </Box>
              <Box sx={{ paddingY: 1.5 }}>
                <SurveySelector
                  onChange={onSurveyChange}
                  label="Survey"
                  initialSurvey={populateInitialSurvey()}
                  disabled={isLoading || !surveysLoaded}
                  error={error}
                />
                {
                  error && (
                    <Typography variant="caption" color="error">
                      The selected survey is unavailable for this advisor.
                    </Typography>
                  )
                }
              </Box>
              <Box sx={{ paddingY: 1 }}>
                <Button disabled={error} variant="text" onClick={createLink}>
                  Copy survey link
                </Button>
              </Box>
              {surveyLink && (
                <Typography variant="tiny" sx={{ paddingY: 1 }} id="survey-link">{surveyLink}</Typography>
              )}
              <Box sx={{ paddingY: 1.5 }}>
                <Typography variant="body">
                  <Typography variant="bodyBold" sx={{ display: 'inline' }}>Step 2: </Typography>
                  Pressing “Finish phone onboarding” will immediately send the advisor an invitation email and allow the advisor to bypass the onboarding screens when they log in. Once they create a password their status will change to “Onboarded”.
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
            <Button variant="text" onClick={close}>Cancel</Button>
            <Button variant="contained" onClick={markComplete}>Finish phone onboarding</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

PhoneOnboardingDialog.propTypes = {
  advisorId: PropTypes.string.isRequired,
  refreshUserTable: PropTypes.func.isRequired
}

export default PhoneOnboardingDialog
