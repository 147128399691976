import { connect } from 'react-redux'

import { Dialogs, JsonAPI } from 'store'
import { buildJsonApiOrdered } from 'utils-em'
import AssetList from './AssetList'

const mapStateToProps = ({ data, enums }) => ({
  assets: buildJsonApiOrdered(data, 'assets'),
  assetTypesEnum: enums.assetTypeList
})

const mapDispatchToProps = {
  getAssets: (sort) => JsonAPI.getAll({
    type: 'assets',
    queryStringParams: {
      include: 'authorizedCustomers,tags,organizations',
      filter: [
        { name: 'isDeleted', op: 'is', val: false },
        { name: 'engagementId', op: 'eq', val: null },
      ],
      sort
    }
  }),
  saveAsset: (asset) => JsonAPI.save({
    ...asset,
    type: 'assets',
    documentGcsObjectName: asset.documentGcsObjectName || 'upload failed'
  }),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog)),
  closeDialog: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetList)
