import React from 'react'
import { SvgIcon } from '@mui/material'

export default function LinkedIn (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.3333 8.66797C16.6594 8.66797 17.9312 9.19475 18.8689 10.1324C19.8065 11.0701 20.3333 12.3419 20.3333 13.668V19.5013H17V13.668C17 13.2259 16.8244 12.802 16.5118 12.4895C16.1993 12.1769 15.7754 12.0013 15.3333 12.0013C14.8913 12.0013 14.4674 12.1769 14.1548 12.4895C13.8423 12.802 13.6667 13.2259 13.6667 13.668V19.5013H10.3333V13.668C10.3333 12.3419 10.8601 11.0701 11.7978 10.1324C12.7355 9.19475 14.0072 8.66797 15.3333 8.66797Z" stroke="#1D1E52" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.00001 9.5H3.66667V19.5H7.00001V9.5Z" stroke="#1D1E52" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.33334 7.0013C6.25381 7.0013 7.00001 6.25511 7.00001 5.33464C7.00001 4.41416 6.25381 3.66797 5.33334 3.66797C4.41286 3.66797 3.66667 4.41416 3.66667 5.33464C3.66667 6.25511 4.41286 7.0013 5.33334 7.0013Z" stroke="#1D1E52" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
