import React from 'react'
import { ErrorPage } from 'components'

const SectionNotFound = () => (
  <ErrorPage
    title="404"
    message="Oops! This section is not available for this published insight."
  />
)

export default SectionNotFound
