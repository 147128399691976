import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Link,
  Popover,
  Typography
} from '@mui/material'
import { JsonAPI } from 'store'
import { buildJsonApiOne, downloadFile, httpResponseAlert, simpleHttpFetch } from 'utils-em'

const AddToCalendarButton = ({ timeslotId, asLink, addAsParticipant, engagementId, ...props }) => {
  const user = useSelector(({ session }) => session)
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const [anchorEl, setAnchorEl] = React.useState(null)
  const ClickableComponent = asLink ? Link : Button

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const dispatch = useDispatch()

  const addToCalendar = async (calendarType) => {
    const successfullyAdded = addAsParticipant ? await addParticipant() : true

    if (successfullyAdded) {
      const result = await simpleHttpFetch(
        `${__API_HOST__}/invites/${timeslotId}/?type=${calendarType}`,
        {
          errorMessage: 'Could not get calendar invite.',
          successMessage: 'Added call to calendar',
          json: calendarType === 'gcal'
        }
      )

      if (calendarType === 'gcal') {
        window.open(result.location)
      } else if (calendarType === 'ics') {
        const blob = await result.blob()
        downloadFile(blob, 'Emissary_Call.ics')
      }
    }
  }

  const addParticipant = async () => {
    if (engagement.participants.some((p) => p.userId === user.id)) return true

    const response = await dispatch(JsonAPI.save({
      id: engagementId,
      type: 'engagements',
      participantUsers: [...engagement.participants.filter((p) => p.isUser).map((p) => p.user), user],
      riders: ['participantUsers']
    }))
    httpResponseAlert(response, { failure: 'Failed to add as participant.' })
    return !!response.ok
  }

  return (
    <>
      <ClickableComponent
        variant="text"
        underline="hover"
        sx={{ mr: 2, textTransform: 'none' }}
        onClick={handlePopoverOpen}
        {...props}
      >
        Add to calendar
      </ClickableComponent>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ maxWidth: '980px' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1 }}>
          <Link color="inherit" variant="body1" underline="none" sx={{ p: 1 }} onClick={() => addToCalendar('gcal')}>Add to Google Calendar</Link>
          <Link color="inherit" variant="body1" underline="none" sx={{ p: 1 }} onClick={() => addToCalendar('ics')}>Download for Outlook / Apple Calendar</Link>
          {addAsParticipant && (
            <Box sx={{ p: 1 }}>
              <Typography variant="caption">
                By adding this call to your calendar, you will
                be added to engagement communications and receive scheduling notifications.
              </Typography>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  )
}

AddToCalendarButton.defaultProps = {
  asLink: false,
  addAsParticipant: false,
  engagementId: null
}

AddToCalendarButton.propTypes = {
  addAsParticipant: PropTypes.bool,
  engagementId: PropTypes.number,
  timeslotId: PropTypes.string.isRequired,
  asLink: PropTypes.bool
}

export default AddToCalendarButton
