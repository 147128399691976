import { connect } from 'react-redux'

import Dialogs from '../../store/Dialogs'
import Alerts from '../../store/Alerts'

import BulkFindAccountsDialog from './BulkFindAccountsDialog'

const mapDispatchToProps = {
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog()),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message))
}

export default connect(null, mapDispatchToProps)(BulkFindAccountsDialog)
