import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { buildJsonApiOrdered } from 'utils-em'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { Paper, TextField } from '@mui/material'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { TextValidator } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { JsonAPI } from 'store'
import RenderSuggestion from './components/Suggestion'

const styles = {
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    width: '200%'
  },
  accountSelectedCheck: {
    marginRight: '0.25em'
  }
}

const AccountPicker = (props) => {
  const [textInProgress, setTextInProgress] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const dispatch = useDispatch()
  const accounts = useSelector(({ data }) => buildJsonApiOrdered(data, 'accounts'))

  const accountLookup = async (accountId) => {
    const res = await dispatch(JsonAPI.getOne({
      type: 'accounts',
      id: accountId
    }))
    if (res.data?.data?.attributes) {
      const account = res.data.data.attributes
      setSelectedAccount(account)
      setTextInProgress(account.name)
    }
  }

  const accountSearch = (searchTerm, customerId) => {
    dispatch(JsonAPI.getAll({
      type: 'accounts',
      queryStringParams: {
        filter: [
          { name: 'name', op: 'like', val: `%${searchTerm}%` },
          { name: 'customerId', op: 'eq', val: customerId }
        ],
        sort: 'name',
        'page[size]': 10
      }
    }))
  }

  const handleSearch = useCallback(debounce(() => {
    accountSearch(textInProgress, props.customerId)
    setIsMenuOpen(true)
  }, 400), [textInProgress, props.customerId])

  useEffect(() => {
    if (props.accountId) {
      accountLookup(props.accountId)
    }
  }, [props.accountId])

  const handleSearchTextChange = (event) => {
    const searchTerm = event.currentTarget.value
    if (selectedAccount) {
      props.onClear()
    }
    setTextInProgress(searchTerm)
    setSelectedAccount(null)
    props.onTextChange(searchTerm)
    handleSearch()
  }

  const handleChange = (account) => {
    setSelectedAccount(account)
    setTextInProgress(account.name)
    setIsMenuOpen(false)
    props.onChange(account)
  }

  const textField = props.validators.length ? (
    <TextValidator
      label={props.label}
      placeholder="Account"
      InputProps={{
        startAdornment: selectedAccount ? <CheckCircle sx={{ ...styles.accountSelectedCheck }} /> : null
      }}
      value={textInProgress}
      onChange={handleSearchTextChange}
      validators={props.validators}
      errorMessages={props.errorMessages}
      fullWidth
    />
  ) : (
    <TextField
      label={props.label}
      placeholder="Account"
      InputProps={{
        startAdornment: selectedAccount ? <CheckCircle sx={{ ...styles.accountSelectedCheck }} /> : null
      }}
      value={textInProgress}
      onChange={handleSearchTextChange}
      fullWidth
    />
  )

  return (
    <Downshift
      id="downshift-simple"
      onChange={handleChange}
      itemToString={(item) => (item ? item.primary_name : '')}
      isOpen={isMenuOpen}
      onOuterClick={() => setIsMenuOpen(false)}
    >
      {({
        getItemProps,
        getMenuProps,
        highlightedIndex
      }) => (
        <div style={{ ...styles.container }}>
          {textField}
          <div {...getMenuProps()}>
            {isMenuOpen ? (
              <Paper sx={{ ...styles.paper }} square>
                {accounts.map((suggestion, index) => (
                  <RenderSuggestion
                    suggestion={suggestion}
                    index={index}
                    itemProps={getItemProps({ item: suggestion })}
                    highlightedIndex={highlightedIndex}
                  />
                ))}
              </Paper>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  )
}

AccountPicker.defaultProps = {
  customerId: null,
  accountId: null,
  validators: [],
  errorMessages: [],
  disabled: false,
  onClear: () => {},
  onTextChange: () => {},
  label: 'Name'
}

AccountPicker.propTypes = {
  customerId: PropTypes.number,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  accountId: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func,
  onClear: PropTypes.func,
  label: PropTypes.string,
}

export default AccountPicker
