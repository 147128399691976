import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@mui/material'
import { JsonAPI } from 'store'
import { buildJsonApiOrdered, useDebounce } from 'utils-em'

const CustomerAutocomplete = ({
  label,
  onChange,
  initCustomers,
  disabled,
  multiple,
  helperText,
  variant
}) => {
  const dispatch = useDispatch()
  const customers = useSelector(({ data }) => buildJsonApiOrdered(data, 'customers'))
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const [selectedCustomers, setSelectedCustomers] = React.useState(initCustomers)
  const [searchText, setSearchText] = React.useState('')
  const debouncedSearchText = useDebounce(searchText, 150)

  const customerSearch = () => dispatch(JsonAPI.getAll({
    type: 'customers',
    queryStringParams: {
      filter: [{ name: 'name', op: 'like', val: `%${searchText}%` }]
    },
    sort: 'name'
  }))

  React.useEffect(() => { setSelectedCustomers(initCustomers) }, [initCustomers])
  React.useEffect(() => { searchText?.length > 1 && customerSearch() }, [debouncedSearchText])

  return (
    <Autocomplete
      disabled={disabled}
      multiple={multiple}
      id={`${label}-multiselect`}
      open={autocompleteOpen}
      onOpen={() => setAutocompleteOpen(true)}
      onInputChange={(event, text) => {
        setSearchText(text)
      }}
      onChange={(event, newValue) => {
        onChange(event, newValue)
      }}
      onClose={() => setAutocompleteOpen(false)}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}
      options={customers}
      defaultValue={multiple ? selectedCustomers : selectedCustomers[0]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={label}
          helperText={helperText}
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

CustomerAutocomplete.defaultProps = {
  initCustomers: [],
  multiple: true,
  disabled: false,
  label: '',
  helperText: '',
  variant: 'standard'
}

CustomerAutocomplete.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initCustomers: PropTypes.array,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string
}

export default CustomerAutocomplete
