// Engagement details sidebar text formatting component
// for both customer and advisor users

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const SidebarText = ({ children, sx, header }) => (
  <Box
    sx={{
      mt: header ? 6 : 2,
      ...sx
    }}
  >
    <Typography
      variant={header ? 'captionBold' : 'body1'}
      color={header ? 'neutral.black' : 'neutral.black'}
    >
      {children}
    </Typography>
  </Box>
)

SidebarText.defaultProps = {
  sx: undefined,
  header: false
}

SidebarText.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
  header: PropTypes.bool
}

export default SidebarText
