import React from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { store, Dialogs } from 'store'
import { emissaryTheme } from 'themes'
import { ConfirmDialog } from 'components'

export function openDialog (dialog, key) {
  store.dispatch(Dialogs.openDialog(dialog, key))
}

export function openDialogWithThemingTwo (dialog, key) {
  // FUTURE: delete when 2.0 theme is applied across the site
  store.dispatch(Dialogs.openDialog((
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={emissaryTheme}>
        {dialog}
      </ThemeProvider>
    </StyledEngineProvider>
  ), key))
}

export function closeDialog (key) {
  store.dispatch(Dialogs.closeDialog(key))
}

export function closeAllDialogs () {
  store.dispatch(Dialogs.closeAllDialogs())
}

export function confirmDialog (props) {
  return openDialog(
    <ConfirmDialog {...props} />
  )
}
