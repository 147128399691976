// eslint-disable-next-line import/prefer-default-export
export const isFormDirty = (form, initialValues) => {
  const formValues = form.watch()
  return Object.keys(initialValues).some((key) => initialValues[key] !== formValues[key])
}

export const getDirtyFields = (form, initialValues) => {
  const dirtyFields = {}

  const formValues = form.watch()
  Object.keys(initialValues).forEach((key) => {
    if (initialValues[key] !== formValues[key]) {
      dirtyFields[key] = formValues[key]
    }
  })

  return dirtyFields
}
