import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Grid,
  Typography
} from '@mui/material'

const API_HOST = __API_HOST__
const MAX_LINES = 200

const CoverageReporterUploadStep = ({ showErrorMessage, onCSVParsed }) => {
  const [uploadedCsvDump, setUploadedCsvDump] = useState('')
  const [saving, setSaving] = useState(false)

  const handleTextChange = (event) => {
    setUploadedCsvDump(event.currentTarget.value)
  }

  const handleSubmit = () => {
    // check max lines
    const parsedRows = uploadedCsvDump.split(/\r\n|\r|\n/)
    if (parsedRows.length > MAX_LINES + 1) {
      showErrorMessage({
        text: `Too many rows, maximum is: ${MAX_LINES}`,
        timeout: 2000
      })
      return
    }

    setSaving(true)

    fetch(
      `${API_HOST}/v1/search`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(parsedRows),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    ).then((res) => {
      res.json().then((body) => {
        if (!res.ok) {
          setSaving(false)
          showErrorMessage({
            text: body.errors.join(', '),
            timeout: 2000
          })
        } else {
          // zip item { given: 'asd', matches: [1,2,3]}
          const zippedItems = parsedRows.map((item, idx) => ({
            given: item,
            matches: body[idx]
          }))
          onCSVParsed(zippedItems)
        }
      })
    }).catch((err) => console.error(err))
  }

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">
            Coverage Reporter
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            fullWidth
            label="CSV Dump"
            variant="outlined"
            validators={['required']}
            errorMessages={['Required Field']}
            multiline
            value={uploadedCsvDump}
            onChange={handleTextChange}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            Parse
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}

CoverageReporterUploadStep.propTypes = {
  showErrorMessage: PropTypes.func.isRequired,
  onCSVParsed: PropTypes.func.isRequired
}

export default CoverageReporterUploadStep
