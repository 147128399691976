// create react dialog component for org selection
import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { OrganizationSelectorTwo } from 'components'
import {
  closeDialog
} from 'utils-em'

const OrgSelectorDialog = ({ onSubmit, initialOrg }) => {
  const [selectedOrg, setSelectedOrg] = React.useState(initialOrg)

  const handleSubmit = () => {
    onSubmit(selectedOrg)
    closeDialog()
  }

  return (
    <Dialog
      scroll="paper"
      open
    >
      <DialogTitle>Select an organization</DialogTitle>
      <DialogContent>
        <Box variant="body1" color="neutral.black" sx={{ minWidth: '300px' }}>
          <OrganizationSelectorTwo
            textFieldParams={{
              variant: 'standard',
              label: 'Account',
              placeholder: 'Account',
              required: true
            }}
            defaultValue={selectedOrg}
            onSelected={(org) => setSelectedOrg(org)}
            noOptionsText="Enter account name"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={closeDialog}
          sx={{ ml: 2 }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => { handleSubmit() }}
          color="primary"
          variant="contained"
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrgSelectorDialog.defaultProps = {
  onSubmit: () => {},
  initialOrg: null
}

OrgSelectorDialog.propTypes = {
  onSubmit: PropTypes.func,
  initialOrg: PropTypes.object
}

export default OrgSelectorDialog
