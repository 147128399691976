import React from 'react'
import PropTypes from 'prop-types'
import { SanitizedHTML } from 'components'

const BoldedTextMatch = ({ substring, text }) => {
  const regex = new RegExp(RegExp.escape(substring), 'gi')
  const getHtmlText = () => {
    // ignore text entries with regex chars (such as backslash)
    try {
      return text.replace(regex, '<b>$&</b>')
    } catch (error) {
      return text
    }
  }
  return <SanitizedHTML html={`<span>${getHtmlText()}</span>`} />
}

BoldedTextMatch.propTypes = {
  substring: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default BoldedTextMatch
