import React from 'react'
import PropTypes from 'prop-types'
import { customAlert } from 'utils-em'

const SaveAccountListOrgWrapper = ({ orgId, enableUnsave, isCurrentlySaved, onUpdate, children }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const saveAccountListOrg = async () => {
    setIsLoading(true)
    try {
      const res = await fetch(`${__API_HOST__}/v1/accountListOrgs/${orgId}`, {
        credentials: 'include',
        method: 'POST',
      })
      if (res.error) throw new Error(res.error.message)
      onUpdate && onUpdate()
      customAlert('Account saved to your list')
    } catch (error) {
      customAlert('Unable to update accounts. Please contact support.', true)
    }
    setIsLoading(false)
  }

  const deleteFromAccountList = async () => {
    setIsLoading(true)
    try {
      const res = await fetch(`${__API_HOST__}/v1/accountListOrgs/${orgId}`, {
        credentials: 'include',
        method: 'DELETE',
      })
      if (res.error) throw new Error(res.error.message)
      onUpdate && onUpdate()
      customAlert('Account removed from your list')
    } catch (error) {
      customAlert('Unable to update accounts. Please contact support.', true)
    }
    setIsLoading(false)
  }

  const handleClick = async () => {
    if (enableUnsave && isCurrentlySaved) {
      await deleteFromAccountList()
    } else {
      await saveAccountListOrg()
    }
  }

  // assign onClick and disabled props to the child component(s)
  const childrenWithAddedProps = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return child
    return React.cloneElement(child, { onClick: handleClick, disabled: isLoading })
  })

  return <>{childrenWithAddedProps}</>
}

SaveAccountListOrgWrapper.defaultProps = {
  enableUnsave: false,
  isCurrentlySaved: false,
  onUpdate: null
}

SaveAccountListOrgWrapper.propTypes = {
  orgId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  enableUnsave: PropTypes.bool,
  isCurrentlySaved: PropTypes.bool,
  onUpdate: PropTypes.func
}

export default SaveAccountListOrgWrapper
