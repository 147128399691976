import { connect } from 'react-redux'
import { JsonAPI } from 'store'

import UserSearchLayout from '../layouts/UserSearchLayout'

const mapDispatchToProps = {
  getOne: JsonAPI.getOne,
  getAll: JsonAPI.getAll
}

export default connect(null, mapDispatchToProps)(UserSearchLayout)
