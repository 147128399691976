import React from 'react'
import { Grid, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'

const RenderSuggestion = (props) => {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = props
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      <Grid container spacing={1}>
        {suggestion.name}
      </Grid>
    </MenuItem>
  )
}

RenderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({ label: PropTypes.string, id: PropTypes.number, name: PropTypes.string }).isRequired
}

export default RenderSuggestion
