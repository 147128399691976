import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  AppBar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material'

import { useSessionUser } from 'hooks'
import {
  Logo,
  Logout,
  Menu as MenuIcon,
} from 'icons'

const styles = (theme) => ({
  appBar: {
    background: theme.header.color,
    marginLeft: theme.navSideBar.drawerWidthClosed,
    width: `calc(100% - ${theme.navSideBar.drawerWidthClosed}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
  },
  fullWidthAppBar: {
    marginLeft: '0px !important',
    width: '100% !important'
  },
  sideNavOpen: {
    marginLeft: theme.navSideBar.drawerWidth,
    width: `calc(100% - ${theme.navSideBar.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
})

const AdminHeader = ({ label }) => {
  const theme = useTheme()
  const menuRef = React.useRef()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const sideNavOpen = useSelector(({ appState }) => appState.sideNavOpen)

  // Menu button for customers and advisors is rendered as initials in a circle
  const { logout } = useSessionUser()
  const renderMenu = () => (
    <Box sx={{ height: theme.header.height, display: 'flex', alignItems: 'center' }}>
      <>
        <IconButton
          disableRipple
          ref={menuRef}
          data-link-ref="open-menu"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ bgcolor: 'transparent', mr: 4 }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'brand.darkNavy',
            '&:hover, &:hover > svg path': {
              // apply hover color to both typography and icon
              color: theme.palette.primary.main,
              stroke: theme.palette.primary.main,
            }
          }}
          >
            <Typography variant="buttonL" sx={{ mr: 1 }}>
              Menu
            </Typography>
            <MenuIcon />
          </Box>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          sx={{ mt: 6, zIndex: 9999 }}
        >
          <MenuItem onClick={() => logout()}>
            <ListItemIcon sx={{ minWidth: 5 }}>
              <Logout sx={{ color: 'brand.darkNavy' }} />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography sx={{ color: 'brand.darkNavy' }}>
                  Log Out
                </Typography>
              )}
            />
          </MenuItem>
        </Menu>
      </>
    </Box>
  )

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          ...styles(theme).appBar,
          ...(sideNavOpen && styles(theme).sideNavOpen),
          ...styles(theme).fullWidthAppBar,
          zIndex: theme.header.zIndex,
          height: '80px',
          '& .MuiToolbar-root': { p: 0 },
          bgColor: 'neutral.white'
        }}
      >
        <Toolbar sx={{ height: theme.header.height }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', ml: 0 }}>
            <Box sx={{ ml: 6, mr: 4, p: 0, }}>
              <Logo sx={{ width: '40px', height: '40px' }} />
            </Box>
            {label && (
              <Typography variant="bodyBold" color="brand.darkNavy" sx={{ ml: 4 }}>
                {label}
              </Typography>
            )}
            <Box sx={{ marginLeft: 'auto' }}>{renderMenu()}</Box>
          </Box>
        </Toolbar>
        <Divider sx={{ ...theme.header.divider }} />
      </AppBar>
    </Box>
  )
}

AdminHeader.defaultProps = {
  label: null,
}

AdminHeader.propTypes = {
  label: PropTypes.string,
}

export default AdminHeader
