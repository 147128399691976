import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material'
import {
  Spinner,
  OrganizationSelectorTwo
} from 'components'
import {
  customAlert
} from 'utils-em'

const TOPICS = [
  { value: 'techBudget', text: 'Tech Budget' },
  { value: 'riskTolerance', text: 'Risk Tolerance' },
  { value: 'makeOrBuy', text: 'Build vs Buy' },
  { value: 'digitalTransformation', text: 'Digital Transformation' },
  { value: 'techStrategy', text: 'Tech Strategy' },
  { value: 'cloudApproach', text: 'Cloud Approach' },
  { value: 'openSource', text: 'Open Source' },
  { value: 'techApproach', text: 'Tech Approach' },
  { value: 'cyberResiliency', text: 'Cyber Resiliency' },
  { value: 'budgetProcess', text: 'Budget Process' },
  { value: 'influence', text: 'Influence' }
]

const SummarizerNineThousand = () => {
  const [generating, setGenerating] = React.useState(false)
  const [topic, setTopic] = React.useState('')
  const [org, setOrg] = React.useState(null)

  const [prompt, setPrompt] = React.useState('Run the summarizer above to generate a prompt.')
  const [answer, setAnswer] = React.useState('Run the summarizer above to generate an answer.')
  const [surveyCount, setSurveyCount] = React.useState(null)
  const [avgRating, setAvgRating] = React.useState(null)
  const [assetCount, setAssetCount] = React.useState(null)
  const [cost, setCost] = React.useState(null)

  const topicText = topic && TOPICS.find((t) => t.value === topic)?.text

  const generate = async () => {
    setGenerating(true)

    const promptModule = 'summarizer.Generator'
    try {
      const res = await fetch(
        `${__API_HOST__}/v1/ai/${promptModule}`,
        {
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ topic, orgId: org.id }),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (res.error) throw new Error(res.error.message)
      const body = await res.json()

      setPrompt(body.prompt)
      setAnswer(body.content)
      setSurveyCount(body.payload.surveyCount)
      setAvgRating(body.payload.avgRating)
      setAssetCount(body.payload.assetCount)
      setCost(body.cost)
    } catch (error) {
      customAlert('Cannot connect to network, please contact support', true)
    }

    setGenerating(false)
  }

  const renderAccordion = ({ header, children, defaultExpanded }) => (
    <Accordion defaultExpanded={defaultExpanded || false} sx={{ mt: 4 }}>
      <AccordionSummary><Typography variant="h6">{header}</Typography></AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', m: 2, mt: 8 }}>
      <Typography variant="h4" sx={{ mt: 4 }}>💰Summarizer Nine Thousand 💰</Typography>
      <Typography variant="h6" sx={{ mt: 4 }}>Input:</Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <OrganizationSelectorTwo
            sx={{ mt: 2, w: '300px' }}
            onSelected={(organization) => setOrg(organization)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="topic-label">Topic</InputLabel>
            <Select
              labelId="topic-label"
              id="topic-select"
              label="Topic"
              fullWidth
              value={topic}
              placeholder="Filter on Customer"
              onChange={(e) => setTopic(e.target.value)}
            >
              {TOPICS.map((c) => (
                <MenuItem key={c.value} value={c.value}>{c.text}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            onClick={generate}
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={generating || !org || !topic}
          >
            Summarize
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', height: '100%', mt: 2 }}>
        { generating ? <Spinner /> : null }
        {!generating && answer && prompt ? (
          <>
            <Typography variant="h6" sx={{ mt: 4 }}>Result:</Typography>
            {renderAccordion({
              header: 'Prompt',
              children: <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{prompt}</Typography>,
              defaultExpanded: false
            })}
            {renderAccordion({
              header: `Answer${answer && topic && org ? `: ${topicText} at ${org.name}` : ''}`,
              defaultExpanded: true,
              children: (
                <>
                  {cost !== null && <Typography variant="body1">{`Summarization cost: $${cost.toFixed(2)}`}</Typography>}
                  {assetCount !== null && <Typography variant="body1">{`Transcript count: ${assetCount}`}</Typography>}
                  {surveyCount !== null && <Typography variant="body1">{`Survey count: ${surveyCount}`}</Typography>}
                  {avgRating !== null && <Typography variant="body1">{`Average rating: ${avgRating}`}</Typography>}
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line', mt: 4 }}>{answer}</Typography>
                </>
              )
            })}
          </>
        ) : null }
      </Box>
    </Box>
  )
}

export default SummarizerNineThousand
