import React from 'react'
import { openDialog } from 'utils-em'
import { Skeleton, Box, Button } from '@mui/material'
import { useJsonAPIGetAll } from 'hooks'
import { JsonApiDataGrid } from 'components'
import CreateEditTopicDialog from './CreateEditTopicDialog'
import TopicsTableActions from './TopicsTableActions'

const TopicsTable = () => {
  const { loaded: topicsLoaded } = useJsonAPIGetAll('topics')
  const [reload, setReload] = React.useState({})

  const columns = [
    {
      field: 'id',
      headerName: 'Topic ID',
      valueGetter: ({ row }) => row.id,
      hidden: true
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      valueGetter: ({ row }) => row.name,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      valueGetter: ({ row }) => row.description,
    },
    {
      field: 'parentId',
      headerName: 'Parent ID',
      hidden: true,
      valueGetter: ({ row }) => row.parentId,
    },
    {
      field: 'parent.name',
      headerName: 'Parent Name',
      hidden: true,
      valueGetter: ({ row }) => row.parent?.name,
    },
    {
      field: 'guidanceText',
      headerName: 'Guidance Text',
      flex: 1,
      valueGetter: ({ row }) => row.guidanceText,
    },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      disableExport: true,
      disableSort: true,
      disableFilter: true,
      valueGetter: ({ row }) => row.id,
      renderCell: ({ row }) => <TopicsTableActions topic={row} setReload={setReload} />
    },
  ]

  if (!topicsLoaded) return <Skeleton variant="rounded" height="200px" />

  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => openDialog(<CreateEditTopicDialog setReload={setReload} />)}
        sx={{ mb: 1 }}
      >
        Create Topic
      </Button>
      <JsonApiDataGrid
        disableExport
        type="topics"
        columns={columns}
        orderKey="allTopics"
        jsonApiOptions={{
          include: ['parent'],
          filters: []
        }}
        mode="server"
        dependencies={[reload]}
      />
    </Box>
  )
}

export default TopicsTable
