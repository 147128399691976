import { connect } from 'react-redux'

import { buildJsonApiOne, buildJsonApiAll } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'
import ContractUsageDetailDialog from './ContractUsageDetailDialog'

const mapStateToProps = ({ data }, ownProps) => ({
  contract: buildJsonApiOne(data, 'contracts', ownProps.contractId),
  contractSkuAllotment: buildJsonApiOne(data, 'contractSkuAllotments', ownProps.contractSkuAllotmentId) || {},
  skus: buildJsonApiAll(data, 'skus')
})

const mapDispatchToProps = {
  deleteCharge: (contractSku) => JsonAPI.delete(contractSku),
  loadAllotmentDetail: (skuId, contractId) => JsonAPI.getAll({
    type: 'contractSkus',
    queryStringParams: {
      include: [
        'divisions',
        'account',
        'organization'
      ].join(',')
    },
    filter: [
      { name: 'contractId', op: 'eq', val: contractId },
      { name: 'skuId', op: 'eq', val: skuId }
    ]
  }),
  saveCharge: (contractSku) => JsonAPI.save({
    ...contractSku,
    type: 'contractSkus',
    relationshipNames: ['advisors', 'customerUsers', 'tags'],
    queryStringParams: {
      include: [
        'contract.contractSkus',
        'advisors',
        'divisions',
        'account',
        'customerUsers',
        'tags'
      ].join(',')
    }
  }),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractUsageDetailDialog)
