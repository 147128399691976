import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'

import { Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import {
  httpResponseAlert,
  validatePasswordStrict
} from 'utils-em'

import { TogglablePasswordInput } from 'components'

const ChangePassword = () => {
  const [existingPassword, setExistingPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('')

  const [hasEdited, setHasEdited] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  React.useEffect(() => {
    ValidatorForm.addValidationRule('validatePasswordStrict', (password) => validatePasswordStrict(password).valid)
  }, [])

  const handleSubmit = () => {
    setIsSubmitting(true)
    fetch(
      `${__API_HOST__}/session/password-reset`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          existingPassword,
          newPassword
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((res) => {
      setIsSubmitting(false)
      res.text().then((txt) => {
        httpResponseAlert(res, {
          success: 'Password Changed!',
          failure: txt
        })
      })
      if (res.ok) setHasEdited(false)
    })
  }

  const isFormValid = existingPassword && newPassword && confirmNewPassword &&
    validatePasswordStrict(newPassword).valid && newPassword === confirmNewPassword && hasEdited

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Typography variant="h2" color="neutral.black" sx={{ mb: 3 }}>Change password</Typography>
      <TogglablePasswordInput
        asTextValidator
        label="Existing password"
        value={existingPassword}
        type="password"
        required
        /* Existing password doesn't need to meet password strength validation requirements
        in order to maintain backwards compatibility with existing old passwords that don't
        meet the new requirements */
        validators={['required']}
        errorMessages={['Required Field']}
        onChange={(event) => {
          setExistingPassword(event.target.value)
          setHasEdited(true)
        }}
        fullWidth
        sx={{ mb: 3 }}
      />
      <TogglablePasswordInput
        asTextValidator
        label="New password"
        value={newPassword}
        type="password"
        required
        validators={['required', 'validatePasswordStrict']}
        errorMessages={['Required Field', 'Password must be at least 8 characters long and not easily guessable']}
        onChange={(event) => {
          setNewPassword(event.target.value)
          setHasEdited(true)
        }}
        fullWidth
        sx={{ mb: 3 }}
      />
      <TogglablePasswordInput
        asTextValidator
        label="Confirm new password"
        value={confirmNewPassword}
        type="password"
        required
        validators={['required', 'validatePasswordStrict']}
        errorMessages={['Required Field', 'Password must be at least 8 characters long and not easily guessable']}
        onChange={(event) => {
          setConfirmNewPassword(event.target.value)
          setHasEdited(true)
        }}
        fullWidth
        sx={{ mb: 3 }}
      />
      <LoadingButton
        variant="contained"
        loading={isSubmitting}
        disabled={!isFormValid}
        sx={{ mt: 3, display: 'block' }}
        type="submit"
      >
        SAVE
      </LoadingButton>
    </ValidatorForm>
  )
}

export default ChangePassword
