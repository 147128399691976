import moment from 'moment'
import { map } from 'lodash'

/**
OVERALL Usage

This file attempts (rather successfully, I might add) to provide a dynamic way to
build up JSONApi filters through relationships, unlocking the ability to do something like:
"{ advisor_request.account.customer.name: 'Emissary' }"

Adding "__" to a path specifies the exact operator to use, which is often necessary with "any",
otherwise it defaults to "has"

In addition to path operators, you can also specific 'name__eq' for an exact match
*/

/**
Nests a given filter in a path, jsonapi filters work such that by nested a hash,
you can filter on relationships, this seeks to do that by taking a baseFilter, and iterating back on the path to build up the full filter

@param {string} path - the period separated path to the filter, see the guide above for more info
@param {object} baseFilter - the base filter, to nest snugly in the object
*/
function nestFilterInPath (path, baseFilter) {
  let newFilter = { ...baseFilter }
  path.reverse().forEach((step) => {
    let table = step
    let operation = 'has'
    if (step.includes('__')) {
      table = step.split('__')[0]
      operation = step.split('__')[1]
    }
    newFilter = {
      name: table,
      op: operation,
      val: newFilter
    }
  })
  return newFilter
}

/**
Create a filter, specific for an array of tags, that follows the path nomenclature
@param {string} name - path to the value, see above
@param {array} value - The value to match likeness
@return {object} The raw JSONApi filter, send straight to server
*/
export function buildJsonApiFilter (name, value) {
  const path = name.split('.').slice(0, -1)
  const attribute = name.split('.')[path.length]
  let filter = {
    name: attribute,
    op: 'like',
    val: `%${value}%`
  }
  if (['eq', 'is', 'isnot', 'in'].includes(attribute.split('__')[1])) {
    filter = {
      name: attribute.split('__')[0],
      op: attribute.split('__')[1],
      val: value
    }
  } else if (['nullcheck'].includes(attribute.split('__')[1])) {
    filter = {
      name: attribute.split('__')[0],
      op: value === 'null' ? 'is' : 'isnot',
      val: null
    }
  } else if (['bool'].includes(attribute.split('__')[1])) {
    if (value === 'null') { return null }
    let parsedValue = value
    if (value === 'true') { parsedValue = true }
    if (value === 'false') { parsedValue = false }
    filter = {
      name: attribute.split('__')[0],
      op: 'eq',
      val: parsedValue
    }
  // nest an array in an or block
  } else if (Array.isArray(value)) {
    filter = {
      or: value.map((val) => ({
        name: attribute,
        op: 'like',
        val: `%${val}%`
      }))
    }
  }

  return nestFilterInPath(path, filter)
}

/**
Create a filter, specific for an array of tags, that follows the path nomenclature
@param {string} name - path to the value, see above
@param {array} tags - An array of tag names, i.e. ['EMEA', 'Germany']
@param {string} category - The tag category
@return {object} The raw JSONApi filter, send straight to server
*/
export function buildJsonApiTagFilter (name, tags, category) {
  const path = name.split('.').slice(0, -1)
  const attribute = name.split('.')[path.length]

  const filter = {
    or: tags.map((tag) => ({
      and: [
        {
          name: attribute,
          op: 'like',
          val: `%${tag}%`
        }, {
          name: 'category',
          op: 'eq',
          val: category
        }
      ]
    }
    ))
  }
  return nestFilterInPath(path, filter)
}

/**
Given a filters hash (i.e. "{'advisor.fullName': 'mike sands', 'advisor.phoneNumber': '8675309'}')

turn that into the raw filters using functions from above
*/
export function compileFilters (filters) {
  return map(filters, (value, key) => {
    if ((!value && value !== false) || value.length === 0) {
      return null
    }
    if (key.includes('tags')) {
      const path = key.split('.').slice(0, -1).join('.')
      const category = key.split('.').pop()
      return buildJsonApiTagFilter(path, value.map((tag) => (tag.name)), category)
    }
    return buildJsonApiFilter(key, value)
  }).filter((filter) => filter)
}

/**
Build a simple filter to add user requirement based on role type
designed for advisor requests, probably extendable
*/
export function buildUserFilterByRoleType (userId, roleType) {
  if (roleType === 'program_owner') {
    return null
  } if (roleType === 'manager') {
    return {
      or: [
        {
          name: 'customerUsers',
          op: 'any',
          val: {
            name: 'divisions',
            op: 'any',
            val: {
              name: 'customerUsers',
              op: 'any',
              val: {
                name: 'id',
                op: 'eq',
                val: userId
              }
            }
          }
        }, {
          name: 'customerUsers',
          op: 'any',
          val: {
            name: 'id',
            op: 'eq',
            val: userId
          }
        }
      ]
    }
  }
  return {
    name: 'customerUsers',
    op: 'any',
    val: {
      name: 'id',
      op: 'eq',
      val: userId
    }
  }
}

export function convertDataGridFilterToJsonApiFilter (filterModel) {
  const dateMatcher = /\d{4}-\d{2}-\d{2}/gi
  const filterModelConverter = {
    contains: {
      op: 'ilike',
      val: (v) => `%${v}%`
    },
    equals: {
      op: 'eq',
      val: (v) => v
    },
    startsWith: {
      op: 'ilike',
      val: (v) => `${v}%`
    },
    endsWith: {
      op: 'ilike',
      val: (v) => `%${v}`
    },
    isEmpty: {
      op: 'is',
      val: (v) => 'null'
    },
    isNotEmpty: {
      op: 'isnot',
      val: (v) => 'null'
    },
    isAnyOf: {
      op: 'in',
      val: (vs) => vs // array
    },
    is: {
      op: 'eq',
      val: (v) => {
        // handle types: date, boolean, any
        if (v.match(dateMatcher)) return moment(v).toISOString()
        if (v === true || v === 'true' || v === false || v === 'false') return v === true || v === 'true'
        return v
      }
    },
    not: {
      op: 'ne',
      val: (v) => moment(v).toISOString()
    },
    after: {
      op: 'gt',
      val: (v) => moment(v).toISOString()
    },
    onOrAfter: {
      op: 'gt',
      val: (v) => moment(v).toISOString()
    },
    before: {
      op: 'lt',
      val: (v) => moment(v).toISOString()
    },
    onOrBefore: {
      op: 'lt',
      val: (v) => moment(v).toISOString()
    }
  }
  if (filterModel && filterModel.items?.length) {
    try {
      const [item] = filterModel.items
      if (!item.value && !(['isEmpty', 'isNotEmpty'].includes(item.operatorValue))) return

      const { op, val } = filterModelConverter[item.operatorValue]
      if (item.columnField.includes('.')) { // filter on relationship
        return compileFilters({ [item.columnField]: val(item.value || '') })[0]
      }

      return { // filter on field
        name: item.columnField,
        op,
        val: val(item.value || '')
      }
    } catch (e) {
      console.error(`Error converting filter model to JsonAPI filter: ${e}`)
    }
  }
}
