import PropTypes from 'prop-types'
import React from 'react'
import { useDebounce, buildJsonApiOrdered } from 'utils-em'
import { useDispatch, useSelector } from 'react-redux'
import { JsonAPI } from 'store'

import {
  TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

const UserEmailAutocomplete = ({
  label,
  onChange,
  initUsers,
  multiple,
  helperText
}) => {
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const [selectedUsers, setSelectedUsers] = React.useState(initUsers)
  const dispatch = useDispatch()
  const users = useSelector(({ data }) => buildJsonApiOrdered(data, 'users'))
  const [searchText, setSearchText] = React.useState('')
  const debouncedSearchText = useDebounce(searchText, 150)

  const userSearch = () => dispatch(JsonAPI.getAll({
    type: 'users',
    queryStringParams: {
      filter: [{ name: 'email', op: 'like', val: `%${searchText}%` }],
      limit: 10
    },
    sort: 'email'
  }))

  React.useEffect(() => { setSelectedUsers(initUsers) }, [initUsers])
  React.useEffect(() => { searchText?.length > 2 && userSearch() }, [debouncedSearchText])

  return (
    <Autocomplete
      multiple={multiple}
      id={`${label}-multiselect`}
      open={autocompleteOpen}
      onOpen={() => setAutocompleteOpen(true)}
      onInputChange={(event, text) => {
        setSearchText(text)
      }}
      onChange={onChange}
      onClose={() => setAutocompleteOpen(false)}
      getOptionLabel={(option) => option.email || ''}
      isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}
      options={users}
      value={multiple ? selectedUsers : selectedUsers[0]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
          helperText={helperText}
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

UserEmailAutocomplete.defaultProps = {
  initUsers: [],
  roleType: null,
  multiple: true,
  label: '',
  helperText: ''
}

UserEmailAutocomplete.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initUsers: PropTypes.array,
  roleType: PropTypes.string,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
}

export default UserEmailAutocomplete
