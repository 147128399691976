import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { startCase } from 'lodash'
import moment from 'moment'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Switch,
  Typography
} from '@mui/material'

import { DivisionPickerTwo, PhoneNumberValidator, TimezoneSelector } from 'components'
import { trimObjectStringFields } from 'utils-em'

const EditCustomerUserDialog = ({
  customerUser: initialCustomerUser,
  customerId,
  handleClose,
  handleUserCreateComplete,
  saveCustomerUser,
  showSuccessMessage,
  showErrorMessage
}) => {
  const [customerUser, setCustomerUser] = useState(initialCustomerUser || {
    firstName: '',
    lastName: '',
    email: '',
    location: '',
    timezone: moment.tz.guess(),
    phoneNumber: '',
    permissionToCall: false,
    permissionToText: false,
    divisions: [],
    roleType: 'rep',
    adminNotes: '',
    jobTitle: '',
    customerId,
    isActive: true,
    ...initialCustomerUser
  })

  const setCustomerUserState = (field, value) => {
    setCustomerUser({
      ...customerUser,
      [field]: value
    })
  }

  const handleCustomerUserChange = (name) => (event) => {
    const { value } = event.target
    setCustomerUserState(name, value)
  }

  const handleCheckboxChange = (name) => (event) => {
    const value = event.target.checked
    setCustomerUserState(name, value)
  }

  const handleSubmit = async () => {
    const trimmedState = trimObjectStringFields(customerUser)
    setCustomerUser(trimmedState)

    if (trimmedState.roleType === 'manager' && trimmedState.divisions.length === 0) {
      showErrorMessage({
        text: 'Manager must belong to at least one team',
        timeout: 2000
      })
      return
    }

    try {
      const saveRes = await saveCustomerUser(trimmedState)
      if (saveRes.error) throw new Error(saveRes.error.message)
      showSuccessMessage({
        text: 'Customer User Saved',
        timeout: 2000
      })
      handleUserCreateComplete ? handleUserCreateComplete({ ...trimmedState, id: saveRes.data.data.id })
        : handleClose()
    } catch (error) {
      showErrorMessage({
        text: `Save customer user failed: ${error.message}.`,
        timeout: 2000
      })
    }
  }

  const handleChangeRole = (event) => {
    const { value } = event.target
    setCustomerUser({
      ...customerUser,
      roleType: value
    })
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>{`${customerUser.id ? 'Edit' : 'Create'} Customer User`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="First Name *"
                validators={['required']}
                errorMessages={['Required Field']}
                value={customerUser.firstName}
                onChange={handleCustomerUserChange('firstName')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Last Name *"
                validators={['required']}
                errorMessages={['Required Field']}
                value={customerUser.lastName}
                onChange={handleCustomerUserChange('lastName')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Email *"
                validators={['required']}
                errorMessages={['Required Field']}
                value={customerUser.email}
                onChange={handleCustomerUserChange('email')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Notes"
                value={customerUser.adminNotes || ''}
                onChange={handleCustomerUserChange('adminNotes')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                label="Location"
                value={customerUser.location || ''}
                onChange={handleCustomerUserChange('location')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TimezoneSelector
                initialTimezone={customerUser.timezone || moment.tz.guess()}
                onChange={handleCustomerUserChange('timezone')}
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneNumberValidator
                fullWidth
                disableAreaCodes
                defaultCountry="us"
                label="Phone"
                value={customerUser.phoneNumber || ''}
                onChange={(value) => setCustomerUserState('phoneNumber', value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={customerUser.permissionToText || false}
                    onChange={handleCheckboxChange('permissionToText')}
                    value={customerUser.permissionToText || false}
                  />
                )}
                label="Permission To Text"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={customerUser.permissionToCall || false}
                    onChange={handleCheckboxChange('permissionToCall')}
                    value={customerUser.permissionToCall || false}
                  />
                )}
                label="Permission To Call"
              />
            </Grid>
            <Grid item xs={12}>
              <DivisionPickerTwo
                onSelectCallback={(e) => setCustomerUserState('divisions', e)}
                initialDivisions={customerUser.divisions}
                customerId={customerId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Job Title"
                value={customerUser.jobTitle}
                onChange={handleCustomerUserChange('jobTitle')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel>Role</FormLabel>
                <RadioGroup
                  aria-label="role"
                  name="role"
                  value={customerUser.roleType}
                  onChange={handleChangeRole}
                >
                  {['rep', 'manager', 'program_owner'].map((authRole) => (
                    <FormControlLabel
                      key={authRole}
                      value={authRole}
                      control={<Radio />}
                      label={(
                        <Typography variant="h6">{startCase(authRole)}</Typography>
                      )}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={customerUser.isActive}
                    onChange={handleCheckboxChange('isActive')}
                    name="isActive"
                  />
                )}
                label="Is Active"
              />
            </Grid>
            <FormControlLabel
              control={(
                <Switch
                  checked={customerUser.emailsBlocked}
                  onChange={handleCheckboxChange('emailsBlocked')}
                  name="emailsBlocked"
                />
              )}
              label="Block Emails"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditCustomerUserDialog.defaultProps = {
  customerUser: null,
  handleUserCreateComplete: null
}

EditCustomerUserDialog.propTypes = {
  customerUser: PropTypes.object,
  customerId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUserCreateComplete: PropTypes.func,
  saveCustomerUser: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default EditCustomerUserDialog
