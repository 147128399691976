import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  buildJsonApiOne
} from 'utils-em'
import { JsonAPI } from 'store'

export default function useJsonAPIGetOne (objectId, type, include = [], lazy = {}) {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = React.useState(false)
  const object = useSelector(({ data }) => buildJsonApiOne(data, type, objectId))

  const reload = () => setLoaded(false)

  const getOne = async () => {
    setLoaded(false)
    await dispatch(JsonAPI.getOne({
      type,
      id: objectId,
      queryStringParams: {
        include: include.join(','),
        ...lazy
      }
    }))
    setLoaded(true)
  }

  React.useEffect(() => { objectId && !loaded && getOne(objectId) }, [objectId, loaded])

  return { object, loaded, reload }
}
