import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Redirect } from 'react-router'
import {
  Box,
  Divider,
  Typography
} from '@mui/material'

import { Spinner } from 'components'
import {
  customAlert,
  httpResponseAlert,
  navigateTo
} from 'utils-em'

import { SessionAPI } from 'store'

import EmailStage from '../EmailStage'
import PasswordStage from '../PasswordStage'

import AdvisorExperiencePromo from '../AdvisorExperiencePromo'
import AccoladeFooter from '../AccoladeFooter'

const SignupForm = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const userUuid = queryParams.get('userUuid')?.replace(/-/gi, '') // uuid may contain dashes, remove them since database doesn't

  const statusToStageId = {
    not_invited: 0,
    invited: 0,
    invite_accepted: 1,
    sign_up_incomplete_needs_password_tcs: 2,
    sign_up_incomplete_needs_basic_info: 3,
    sign_up_incomplete_needs_employment_history: 4,
    active: 5,
  }

  const [advisor, setAdvisor] = React.useState({})
  const [currentStage, setCurrentStage] = React.useState(0)

  // during sign up, reference this email over `advisor.email` since the user has an
  // opportunity to change their email (especially with a placeholder email) that's
  // not yet reflected in the updated `advisor` record
  const selectedEmail = useRef(advisor?.email)
  const selectedPassword = useRef(null)

  const getAdvisor = async () => {
    if (!userUuid) return setCurrentStage('error')
    try {
      const res = await fetch(`${__API_HOST__}/onboard/advisor/${userUuid}`, {
        method: 'GET',
        credentials: 'include'
      })
      if (res.ok) {
        const advisorData = await res.json()
        const sessionInit = await dispatch(SessionAPI.initialize())
        advisorData && setAdvisor(advisorData)
        advisorData?.status && setCurrentStage(statusToStageId[advisorData.status])
        if (advisorData.onboarded || advisorData.status === 'active' || advisorData.status === 'idle') {
          // User has already onboarded, but accessed an old invite link - navigate
          // to login page with email already filled out
          if (sessionInit.status === 200) {
            navigateTo(advisorData.assignmentId ? `/a/survey-assignment/${advisorData.assignmentId}` : '/a')
          } else {
            navigateTo(advisorData.assignmentId ? `/?redirect_url=/a/survey-assignment/${advisorData.assignmentId}` : '/', { email: advisorData.email })
          }
        } else if ((sessionInit.status === 401 || sessionInit.error?.message) && statusToStageId[advisorData.status] >= 3) {
          return navigateTo('/')
        } else if (advisorData.status === 'not_invited' || advisorData.status === 'invited' || advisorData.status === 'invite_accepted') {
          // When page is loaded and advisor is visiting their
          // signup link for the first time, record that they did so
          // (backend automatically handles state checks, just send the request)
          if (advisorData.status === 'not_invited' || advisorData.status === 'invited') {
            saveAdvisor({ userUuid })
          }
        }
      } else {
        throw Error()
      }
    } catch {
      setCurrentStage('error')
    }
  }

  const saveAdvisor = async (data) => {
    // Hotfix to avoid 2nd/duplicate call to set advisor as invite accepted, which causes a backend error
    if ((advisor.status === 'invited' || advisor.status === 'not_invited') && advisor.email) {
      return
    }

    try {
      const res = await fetch(`${__API_HOST__}/onboard/advisor`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({
          userUuid,
          ...data
        }),
        headers: { 'Content-Type': 'application/json' }
      })
      httpResponseAlert(res)
      if (res.ok) {
        const advisorData = await res.json()
        setAdvisor(advisorData)
      } else {
        throw Error()
      }
    } catch (e) {
      customAlert(e.error || 'Something went wrong, please contact support', true)
      setCurrentStage('error')
    }
  }

  const stages = {
    error: (
      <>
        <Typography variant="h2">There was an issue with the signup process</Typography>
        <Typography variant="body">
          {'Please try again or contact '}
          <a href="mailto:advisor-support@emissary.io">advisor-support@emissary.io</a>
          {' for assistance'}
        </Typography>
      </>
    ),
    0: (<Spinner />),
    1: (
      <EmailStage
        defaultEmail={advisor.email?.match(/linkedin\+/gi) ? '' : advisor.email}
        userUuid={userUuid}
        onCompleteCallback={(responseJson) => {
          selectedEmail.current = responseJson.email
          if (responseJson.emailAlreadyInUse) {
            navigateTo('/', { email: responseJson.email })
          } else {
            setCurrentStage(currentStage + 1)
          }
        }}
      />
    ),
    2: (
      <PasswordStage
        userUuid={userUuid}
        onCompleteCallback={async (password, assignmentId, cloaked) => {
          selectedPassword.current = password
          const implementRedirect = assignmentId ? `/a/survey-assignment/${assignmentId}` : ''
          if (!cloaked) {
            await dispatch(SessionAPI.login({ email: selectedEmail.current || advisor.email, password: selectedPassword.current, surveyRedirect: implementRedirect }))
          } else {
            getAdvisor()
          }
        }}
      />
    ),
    3: (<Redirect to="/a/onboarding" />),
    4: (<Redirect to="/a/onboarding" />)
  }

  // Whenever advisor status changes, or on page load, get an up-to-date check on advisor data
  React.useEffect(() => { getAdvisor() }, [advisor?.status])

  return (
    <Box id="signup-form">
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'neutral.white',
          flexDirection: { desktop: 'row', tablet: 'column', mobile: 'column' },
          justifyContent: 'space-evenly'
        }}
      >
        <Box sx={{ width: { desktop: '50%', tablet: '100%', mobile: '100%' } }}>
          <Box sx={{ backgroundColor: 'brand.lightNavy', m: 5, p: 10, borderRadius: 2 }}>
            {stages[currentStage]}
          </Box>
        </Box>
        <Box sx={{ width: { desktop: '50%', tablet: '100%', mobile: '100%' } }}>
          <AdvisorExperiencePromo isEmailStage={currentStage === 1} />
        </Box>
      </Box>
      <Divider sx={{ borderBottomWidth: '4px' }} />
      <Box sx={{ backgroundColor: 'neutral.white' }}>
        <AccoladeFooter />
      </Box>
    </Box>
  )
}

export default SignupForm
