import { connect } from 'react-redux'
import { buildJsonApiAll, buildJsonApiOne, buildJsonApiFilter } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'

import CustomerUserAccountsDialog from './CustomerUserAccountsDialog'

const mapStateToProps = ({ data }, ownProps) => ({
  advisorRequests: buildJsonApiAll(data, 'advisorRequests').filter((ar) => ar.customerUsers && ar.customerUsers.some((cu) => cu.id === ownProps.customerUserId)),
  customerUser: buildJsonApiOne(data, 'customerUsers', ownProps.customerUserId)
})

const mapDispatchToProps = {
  closeDialog: () => (dispatch) => dispatch(Dialogs.closeDialog('advisorEngagementHistory')),
  loadAdvisorRequests: (customerUserId) => JsonAPI.getAll({
    type: 'advisorRequests',
    queryStringParams: {
      include: [
        'tags',
        'account',
        'customerUsers',
        'customerUsers.divisions'
      ].join(','),
      filter: [buildJsonApiFilter('customerUsers__any.id__eq', customerUserId)]
    }
  }),
  openDialog: (dialog, dialogName) => (dispatch) => dispatch(Dialogs.openDialog(dialog, dialogName))
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUserAccountsDialog)
