import { connect } from 'react-redux'
import { buildJsonApiAll } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'

import ContractInfoRow from './ContractInfoRow'

const mapStateToProps = ({ data }) => ({
  skus: buildJsonApiAll(data, 'skus')
})

const mapDispatchToProps = {
  deleteAllotment: (allotment) => JsonAPI.delete(allotment),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractInfoRow)
