import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, TextField } from '@mui/material'
import { useDebounce } from 'utils-em'
import { EditAccountPageContext } from '../EditAccountPageContext'

const WeJustHeardItem = ({ idx, item, onChange, onDelete }) => {
  const [value, setValue] = React.useState(item.value)

  React.useEffect(() => {
    setValue(item.value)
  }, [item.value])

  const debouncedValue = useDebounce(value, 500)

  React.useEffect(() => {
    onChange(idx, debouncedValue)
  }, [debouncedValue])

  return (
    <Box sx={{ mb: 3 }}>
      <Box typography="bodyBold">
        {item.type === 'advisorQuote' ? 'Advisor Quote' : 'Summarized Text'}
        <Button onClick={() => onDelete(idx)}>Delete</Button>
      </Box>
      <TextField
        label={item.type === 'advisorQuote' ? 'Advisor Quote' : 'Summarized Text'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        size="small"
        variant="outlined"
        multiline={item.type !== 'advisorQuote'}
        rows={item.type !== 'advisorQuote' ? 2 : 1}
        sx={{ mt: 2 }}
      />
    </Box>
  )
}

WeJustHeardItem.propTypes = {
  idx: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const WeJustHeardForm = () => {
  const { data, setData } = React.useContext(EditAccountPageContext)
  const [weJustHeard, setWeJustHeard] = React.useState(data?.weJustHeard || [])

  const handleItemChange = (idx, value) => {
    const updatedItems = [...weJustHeard]
    updatedItems[idx] = { ...updatedItems[idx], value }
    setWeJustHeard(updatedItems)
    setData({
      ...data,
      weJustHeard: updatedItems,
    })
  }

  const addItem = (type) => {
    setWeJustHeard([...weJustHeard, { type, value: '' }])
  }

  const removeItem = (item) => {
    const updatedItems = weJustHeard.filter((arrItem) => arrItem.value !== item.value)
    setWeJustHeard(updatedItems)
    setData({
      ...data,
      weJustHeard: updatedItems,
    })
  }

  return (
    <>
      {weJustHeard.map((item, idx) => (
        <WeJustHeardItem
          idx={idx}
          item={item}
          onChange={handleItemChange}
          onDelete={() => removeItem(item)}
        />
      ))}
      <Button onClick={() => addItem('advisorQuote')}>Add advisor quote</Button>
      <Button onClick={() => addItem('summarizedText')}>Add summarized text</Button>
    </>
  )
}

export default WeJustHeardForm
