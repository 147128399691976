import React from 'react'
import {
  File,
  Microphone,
  Phone,
  Video
} from 'icons'

const assetTypeIconStyle = { color: 'neutral.darkGrey' }

export default function getAssetTypeIcon (assetType) {
  switch (assetType) {
    case 'webinar':
      return <Video sx={assetTypeIconStyle} />
    case 'podcast':
      return <Microphone sx={assetTypeIconStyle} />
    case 'engagement-recording':
      return <Phone sx={assetTypeIconStyle} />
    default:
      return <File sx={assetTypeIconStyle} />
  }
}
