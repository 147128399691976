import { forEach, omit, omitBy } from 'lodash'
import Jsona from 'jsona'

export const jsonApiFormatter = new Jsona({})

export function buildJsonApiOrdered (store, objectType, orderKey = 'displayOrderIds', metaKey = null) {
  const metaType = metaKey || objectType

  if (!store.meta[metaType]) return []

  if (store.meta[objectType] && store.meta[metaType][orderKey] && !store.meta[metaType][orderKey].length) {
    return []
  }

  if (store.meta[metaType] && store.meta[metaType][orderKey]) {
    return jsonApiFormatter.denormalizeReduxObject({
      reduxObject: store,
      entityType: objectType,
      entityIds: store.meta[metaType][orderKey]
    }) || []
  }
  return jsonApiFormatter.denormalizeReduxObject({
    reduxObject: store,
    entityType: objectType
  }) || []
}

export function buildJsonApiOne (store, objectType, id = undefined) {
  const objects = jsonApiFormatter.denormalizeReduxObject({
    reduxObject: store,
    entityType: objectType,
    entityIds: [id]
  })
  if (!objects) { return undefined }
  return objects[0]
}

export function buildJsonApiAll (store, objectType) {
  return jsonApiFormatter.denormalizeReduxObject({
    reduxObject: store,
    entityType: objectType
  }) || []
}

export function getCountJsonApi (store, objectType, orderKey = undefined) {
  if (orderKey) return store.meta[objectType][`${orderKey}-count`] || 0
  return store.meta[objectType]?.count || 0
}

export function serializeJsonApiList (data) {
  const dataSanitized = Object.values(data.data).map((obj) => {
    const serializedObj = serializeJsonApi(obj).data

    if (data.riders) {
      forEach(data.riders, (rider) => {
        serializedObj[rider] = obj[rider]
      })
    }

    return serializedObj
  })

  return { ...omit(data, ['riders']), data: dataSanitized }
}

export function serializeJsonApi (data) {
  // filter out all objects not explicitly defined in `relationshipNames` or `jsonObjects`
  const dataSanitized = omitBy(data, (value, key) => (
    value !== null &&
    typeof (value) === 'object' &&
    key !== 'relationshipNames' &&
    !(data.jsonObjects || []).includes(key) &&
    !(data.relationshipNames || []).includes(key)
  ))

  const dataSerialized = jsonApiFormatter.serialize({
    stuff: dataSanitized
  })

  /*
    We allow objects to "ride" along with the request, and depend on it
    in some cases in order to simplify jsonapi usage, that's ok
    what's not ok is that some of these riders have circular dependencies, so flatten all riders to one dimensional objects
  */
  if (data.riders) {
    forEach(data.riders, (rider) => {
      dataSerialized[rider] = data[rider]
    })
  }

  // also some default jsonapi riders to interact with old "params" _index.js methods
  dataSerialized.type = data.type
  if (data.id) { dataSerialized.id = data.id }
  if (data.include) { dataSerialized.include = data.include }
  if (data.queryStringParams) { dataSerialized.queryStringParams = data.queryStringParams }

  return dataSerialized
}

export function deserializeJsonApi (data) {
  return jsonApiFormatter.deserialize(data)
}
