import { startCase } from 'lodash'

export const truncateText = (text, size) => (text.length > size ? `${text.substring(0, size)}...` : text)

export function trimObjectStringFields (objectToUpdate, trimOnlyTheseFields = []) {
  const updatedObj = { ...objectToUpdate }
  const fieldsToTrim = trimOnlyTheseFields.length > 0 ? trimOnlyTheseFields : Object.keys(updatedObj).filter((k) => typeof updatedObj[k] === 'string')
  if (fieldsToTrim.length > 0) {
    fieldsToTrim.forEach((field) => {
      if (typeof objectToUpdate[field] === 'string') {
        updatedObj[field] = objectToUpdate[field].trim()
      }
    })
  }
  return updatedObj
}

export function getNameFromEmail (email, index) {
  const noDomain = email.split('@')[0]
  // . is the most common first/last separator
  const name = noDomain.indexOf('.') !== -1 ? noDomain.split('.')[index] : noDomain
  return startCase(name)
}

export function joinWithAnd (items) {
  if (!items || items.length === 0) return ''
  if (items.length <= 1) return items[0]
  if (items.length === 2) return `${items[0]} and ${items[1]}`
  return `${items.slice(0, items.length - 1).join(', ')}, and ${items[items.length - 1]}`
}
