import React, { useState } from 'react'
import { Paper } from '@mui/material'

import CoverageReporterUploadStep from '../CoverageReporterUploadStep'
import CoverageReporterValidateStep from '../CoverageReporterValidateStep'

const UPLOAD_STEP = 'UPLOAD_STEP'
const VALIDATE_STEP = 'VALIDATE_STEP'

const CoverageReporterFlow = () => {
  const [step, setStep] = useState(UPLOAD_STEP)
  const [coverageItemSets, setCoverageItemSets] = useState(null)

  const onCSVParsed = (newCoverageItemSets) => {
    setCoverageItemSets(newCoverageItemSets)
    setStep(VALIDATE_STEP)
  }

  const returnToUploadStep = () => {
    setStep(UPLOAD_STEP)
  }

  const renderActiveStep = () => {
    switch (step) {
      case UPLOAD_STEP: return (
        <CoverageReporterUploadStep
          onCSVParsed={onCSVParsed}
        />
      )
      case VALIDATE_STEP: return (
        <CoverageReporterValidateStep
          defaultCoverageItemSets={coverageItemSets}
          onCancel={returnToUploadStep}
        />
      )
      default:
        return null
    }
  }

  return (
    <Paper sx={{ mt: 8, p: '1em' }}>{ renderActiveStep() }</Paper>
  )
}
export default CoverageReporterFlow
