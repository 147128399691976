import { connect } from 'react-redux'
import { Alerts, JsonAPI } from 'store'
import { trimObjectStringFields } from 'utils-em'

import EditCoverageItemDialog from './EditCoverageItemDialog'

const mapDispatchToProps = {
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  getOrganization: (id) => JsonAPI.getOne({
    type: 'organizations',
    id
  }),
  saveOrg: (org) => {
    const method = org.id ? JsonAPI.save : JsonAPI.create
    return method(Object.assign(trimObjectStringFields(org), {
      type: 'organizations'
    }))
  }
}
export default connect(null, mapDispatchToProps)(EditCoverageItemDialog)
