import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Typography,
  Link
} from '@mui/material'
import {
  useEngagement,
  useJsonAPIUpsert
} from 'hooks'
import {
  constants,
  navigateTo,
} from 'utils-em'

const ProposalExpired = ({ engagementId }) => {
  const { isAdvisor, advisor, dateAdvisorIndicatedAvailable } = useEngagement(engagementId)
  const { upsert } = useJsonAPIUpsert()
  const markAvailable = () => {
    upsert({
      type: 'engagements',
      id: engagementId,
      advisorIndicatedAvailable: true,
      riders: ['advisorIndicatedAvailable']
    })
  }

  if (isAdvisor && !dateAdvisorIndicatedAvailable) {
    return (
      <>
        <Typography variant="h2">
          {`The ${constants.ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS} day timeframe for accepting this opportunity has passed.`}
        </Typography>
        <Typography variant="body1">
          You will be able to view this opportunity in your&nbsp;
          <Link onClick={() => navigateTo('/a/activity')}>Activity History</Link>
          &nbsp;list.
        </Typography>
        <Typography variant="body1">
          Are you available for an engagement with this client? If so, click the button below and we will notify them. If they are able to meet, they will reach out to you with proposed call times.
        </Typography>
        <Box>
          <Button variant="outlined" onClick={markAvailable}>
            I am Available to Engage
          </Button>
        </Box>
      </>
    )
  }

  if (isAdvisor && dateAdvisorIndicatedAvailable) {
    return (
      <>
        <Typography variant="h2">
          You have let the client know that you are available.
        </Typography>
        <Typography variant="body1">
          The client has been notified that you are available. If they are able to meet, they will reach out to you with proposed call times.
        </Typography>
      </>
    )
  }

  return (
    <>
      <Typography variant="h3">
        {'We\'re sorry!'}
      </Typography>
      <Typography variant="body1">
        {`${advisor?.firstName} did not respond to your call proposal within the ${constants.ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS} day window. `}
        <Link onClick={() => navigateTo('/c/search')}>Find another advisor</Link>
        &nbsp;from our network.
      </Typography>
    </>

  )
}

ProposalExpired.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default ProposalExpired
