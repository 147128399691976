import PropTypes from 'prop-types'
import React from 'react'
import { intersection } from 'lodash'
import {
  ADVISOR_DELIVERABLES_DESCRIPTIVE,
  ADVISOR_DELIVERABLES_SHORT
} from 'utils-em'

import { Grid, Typography, Paper } from '@mui/material'

const AdvisorDeliverablesCard = ({ advisor }) => {
  const tags = advisor.tags.filter((tag) => tag.category === 'deliverable').map((tag) => tag.name)
  const knowledgeTags = intersection(tags, ADVISOR_DELIVERABLES_DESCRIPTIVE)
  const serviceTags = intersection(tags, ADVISOR_DELIVERABLES_SHORT)

  if (!tags.length) { return null }

  return (
    <Paper sx={{ mt: '0.5em', p: '1em' }}>
      <Typography variant="h5" align="left">
        Insights & Services (ADMIN ONLY)
      </Typography>
      <Typography variant="body1" align="left">
        Have noted they have knowledge in:
      </Typography>
      <Grid container spacing={2}>
        <ul>
          { knowledgeTags.sort().map((tagName) => (
            <li key={tagName}>
              {' '}
              {tagName}
              {' '}
            </li>
          ))}
        </ul>
      </Grid>
      <Typography variant="body1" align="left">
        Have noted they are willing to participate with:
      </Typography>
      <Grid container spacing={2}>
        <ul>
          { serviceTags.sort().map((tagName) => (
            <li key={tagName}>
              {' '}
              {tagName}
              {' '}
            </li>
          ))}
        </ul>
      </Grid>
    </Paper>
  )
}

AdvisorDeliverablesCard.propTypes = {
  advisor: PropTypes.object.isRequired
}

export default AdvisorDeliverablesCard
