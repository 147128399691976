import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Checkbox,
  InputLabel,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select
} from '@mui/material'

import Face from '@mui/icons-material/Face'

import { StatusCircle } from 'components'
import { USER_STATUS_DATA } from 'utils-em'

const StatusFilter = ({ selectedCallback, ...rest }) => {
  const options = Object.keys(USER_STATUS_DATA).filter((s) => s !== 'deactivated').sort((a, b) => a.order - b.order)

  const [selected, setSelected] = React.useState([])
  const isAllSelected = options.length > 0 && selected.length === options.length

  React.useEffect(() => selectedCallback(selected.filter((s) => s !== 'all')), [selected])

  const handleChange = (event) => {
    const { value } = event.target
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options)
      return
    }
    setSelected(value)
  }

  return (
    <Box {...rest}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          label="Status"
          fullWidth
          multiple
          value={selected}
          variant="outlined"
          onChange={handleChange}
          renderValue={(value) => (
            <Box sx={{ display: 'flex' }}>
              {value.map((v) => <StatusCircle key={v} status={v} />)}
            </Box>
          )}
          size="small"
          startAdornment={(
            <InputAdornment position="start">
              <Face />
            </InputAdornment>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={selected.length > 0 && selected.length < options.length}
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={isAllSelected || selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Box sx={{ display: 'flex' }}>
                    <StatusCircle status={option} sx={{ mr: 1 }} noTooltip />
                    {USER_STATUS_DATA[option].text}
                  </Box>
                )}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>

  )
}

StatusFilter.propTypes = {
  selectedCallback: PropTypes.func.isRequired,
}

export default StatusFilter
