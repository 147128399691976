export const ADVISOR_DELIVERABLES_DESCRIPTIVE = [
  'How to Get Started',
  'The Buying Culture',
  'Decision Makers & Politics',
  'Meeting Prep',
  'Proposals, RFPs, and Negotiation'
]

export const ADVISOR_DELIVERABLES_SHORT = [
  'Surveys',
  'Phone Calls',
  'Long Term Coaching',
  'SKO Appearance',
  'Introductions',
  'Industry Reports',
  'ABM Reports',
  'Podcasts',
  'In-Person',
  'Blog Content',
  'Industry Events'
]
