export const FEEDBACK_QUESTION_TYPE = {
  Satisfaction: 'satisfaction',
  Agreement: 'agreement',
  Binary: 'binary',
  FreeResponse: 'freeResponse',
  ScaleTen: 'scaleTen', // on a scale of 0-10..., specify `lowText`, `highText`
  RadioSelection: 'radioSelection', // specify `options`
  CheckboxSelection: 'checkboxSelection', // specify `options`
  Multiple: 'multiple' // allows multiple of another question type; specify `multipleOfType` and `subQuestions`
}

export const CUSTOMER_FEEDBACK_QUESTIONS = [
  { name: 'interactionRating', text: 'Overall, did the interaction meet your expectations?', type: FEEDBACK_QUESTION_TYPE.Satisfaction },
  { name: 'typeOfPurposeForCall', text: 'Which of the following most accurately describes your purpose for the advisor call?' },
  { name: 'otherDescription', text: 'Let us know the purpose of this call.' },
  { name: 'formData', text: '' },
  { name: 'advisorMadeGoodUseOfTime', text: 'My advisor made good use of our time together', type: FEEDBACK_QUESTION_TYPE.Agreement },
  { name: 'advisorWasWellPrepared', text: 'My advisor was well prepared for our conversation', type: FEEDBACK_QUESTION_TYPE.Agreement },
  { name: 'advisorAppliedTheirKnowledge', text: 'My advisor was able to apply their knowledge to my specific situation', type: FEEDBACK_QUESTION_TYPE.Agreement },
  { name: 'advisorProvidedHelpfulAdvice', text: 'My advisor provided helpful advice on my next steps', type: FEEDBACK_QUESTION_TYPE.Agreement },
  { name: 'anotherCallWithSameAdvisor', text: 'Would you like to schedule another call with this advisor at this time?', type: FEEDBACK_QUESTION_TYPE.Binary },
  { name: 'anotherCallWithSameAdvisorInFuture', text: 'Would you like to consider re-engaging with this advisor in the future?', type: FEEDBACK_QUESTION_TYPE.Binary },
  { name: 'anotherCallWithDifferentAdvisor', text: 'Would you like another call with a different advisor for this account?', type: FEEDBACK_QUESTION_TYPE.Binary },
  { name: 'anotherCallWithDifferentAdvisorDescription', text: 'What experience / expertise will be important for the advisor to have?' },
  { name: 'discussionForOtherAccounts', text: 'Are there other accounts that you would like to discuss with an advisor?', type: FEEDBACK_QUESTION_TYPE.Binary },
  { name: 'discussionForOtherAccountsDescription', text: 'Which accounts are you interested in working on with an Emissary?' },
  { name: 'mostSuccessfulPartOfEmissary', text: 'In 1-2 sentences, please describe the most valuable part of your interaction with your advisor.' },
  { name: 'recommendationForImprovingExperience', text: 'Tell us how to improve your Emissary experience.' },
  { name: 'teamInformation', text: 'Which sales or marketing team are you part of?' },
  { name: 'teamManagerInformation', text: 'Who is your manager?' }
]

export const LIKERT_SCALE_SATISFACTION = {
  1: 'Completely dissatisfied',
  2: 'Mostly dissatisfied',
  3: 'Somewhat dissatisfied',
  4: 'Neutral',
  5: 'Somewhat satisfied',
  6: 'Mostly satisfied',
  7: 'Completely satisfied'
}

export const LIKERT_SCALE_AGREEMENT = {
  1: 'Strongly disagree',
  2: 'Disagree',
  3: 'Somewhat disagree',
  4: 'Neutral',
  5: 'Somewhat agree',
  6: 'Agree',
  7: 'Strongly agree'
}

export const BINARY = {
  true: 'Yes',
  false: 'No'
}
