import PropTypes from 'prop-types'

import React from 'react'
import { Box } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import {
  Header,
  Footer,
  ActiveDialogStack,
  GlobalAlertStack
} from 'components'
import { emissaryTheme } from 'themes'
import { useEmissaryTheme } from 'hooks'

const Layout = ({
  links,
  menuItems,
  hideHeader,
  hideFooter,
  hideMenu,
  hideNotifications,
  hideStealth,
  children
}) => {
  const { pagePaddingX, pagePaddingY } = useEmissaryTheme()
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={emissaryTheme}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          bgcolor: 'neutral.white'
        }}
        >
          <ActiveDialogStack />
          <GlobalAlertStack />
          {!hideHeader && (
            <Header
              relativePosition
              links={links}
              menuItems={menuItems}
              menuItemsVisible={{
                stealth: !hideStealth,
                notifications: !hideNotifications,
                menu: !hideMenu
              }}
            />
          )}
          <Box
            id="layout-box"
            sx={{
              flex: 1,
              px: pagePaddingX,
              py: pagePaddingY,
            }}
          >
            {children}
          </Box>
          {!hideFooter && (<Footer />)}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

Layout.defaultProps = {
  links: [],
  menuItems: [],
  hideHeader: false,
  hideFooter: false,
  hideMenu: false,
  hideNotifications: false,
  hideStealth: false,
}

Layout.propTypes = {
  links: PropTypes.array,
  menuItems: PropTypes.array,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideMenu: PropTypes.bool,
  hideNotifications: PropTypes.bool,
  hideStealth: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Layout
