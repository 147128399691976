import moment from 'moment'

export const getBlocksFromTimeslots = (validHourTimeslots) => {
  const timeRangeBlocks = []
  if (validHourTimeslots.length) {
    let currentStartTime = moment(validHourTimeslots[0].startTime)
    let currentEndTime = moment(validHourTimeslots[0].endTime)
    for (let i = 1; i < validHourTimeslots.length; i++) {
      const newStartTime = moment(validHourTimeslots[i].startTime)
      if (newStartTime.isBefore(currentEndTime) || newStartTime.isSame(currentEndTime)) {
        currentEndTime = validHourTimeslots[i].endTime
      } else {
        const newBlock = {
          startTime: currentStartTime,
          endTime: currentEndTime
        }
        timeRangeBlocks.push(newBlock)
        currentStartTime = validHourTimeslots[i].startTime
        currentEndTime = validHourTimeslots[i].endTime
      }
      if (i === (validHourTimeslots.length - 1)) {
        const newBlock = {
          startTime: currentStartTime,
          endTime: currentEndTime
        }
        timeRangeBlocks.push(newBlock)
      }
    }
    if (validHourTimeslots.length === 1) {
      const newBlock = {
        startTime: currentStartTime,
        endTime: currentEndTime
      }
      timeRangeBlocks.push(newBlock)
    }
  }
  return timeRangeBlocks
}

export const createOneHourTimeslots = (timeslots) => {
  const validHourTimeslots = []
  if (timeslots.length) {
    timeslots.sort((a, b) => moment(a).diff(moment(b)))

    // Start one ahead to prevent out of range indexing
    for (let i = 1; i < timeslots.length; i++) {
      // previous block is the start time
      const prev = timeslots[i - 1]
      const curr = timeslots[i]

      const startTime = moment(prev)
      const hypotheticalEndTime = moment(prev).add(1, 'hour')

      // current block plus thirty minutes would be the actual endtime
      const endTime = moment(curr).add(30, 'minutes')

      if (hypotheticalEndTime.isSame(endTime)) {
        validHourTimeslots.push({ startTime, endTime })
      }
    }
  }
  return validHourTimeslots
}

export const hasStrayThirtyMinuteTimeslot = (timeslots) => {
  // Detect when a "stray" thirty minute timeslot exists in the provided array of timeslots
  // This happens when a timeslot has neither a 30 minute timeslot selected before or after it
  let hasStrayTimeslot = false
  timeslots.forEach((timeMoment) => {
    const thirtyMinutesBehind = timeMoment.clone().subtract(30, 'minutes')
    const thirtyMinutesAhead = timeMoment.clone().add(30, 'minutes')

    if (!timeslots.find((m) => m.isSame(thirtyMinutesBehind)) && !timeslots.find((m) => m.isSame(thirtyMinutesAhead))) {
      hasStrayTimeslot = true
    }
  })
  return hasStrayTimeslot
}
