import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

const MIN_YEAR = 1900
const CURRENT_YEAR = new Date().getFullYear()
const CURRENT_MONTH = new Date().getMonth() + 1 // 1-12

const FormDatePickerTwo = ({ form, name, label, required, disabled, minDate, maxDate, ...rest }) => {
  const { control, getValues, formState: { errors } } = form

  const [month, setMonth] = React.useState(getValues(name) ? moment(getValues(name)).month() + 1 : '')
  const [year, setYear] = React.useState(getValues(name) ? moment(getValues(name)).year() : '')

  const getMonthErrors = () => {
    if (disabled) return
    if (required && !month) return 'Required field'
    if (Number(month) === 0 || Number(month) >= 13) return 'Invalid month'
    if (Number(year) === CURRENT_YEAR && Number(month) > CURRENT_MONTH) return 'Future date disallowed'
    if (minDate && Number(year) === minDate.year() && Number(month) < (minDate.month() + 1)) return `Must be at least ${minDate.format('MMM')}`
    if (maxDate && Number(year) === maxDate.year() && Number(month) > (maxDate.month() + 1)) return `Cannot go beyond ${maxDate.format('MMM')}`
  }

  const getYearErrors = () => {
    if (disabled) return
    if (required && !year) return 'Required field'
    if (Number(year) < MIN_YEAR) return 'Invalid year'
    if (Number(year) > CURRENT_YEAR) return 'Future date disallowed'
    if (minDate && Number(year) < minDate.year()) return `Must be at least ${minDate.year()}`
    if (maxDate && Number(year) > maxDate.year()) return `Must be at most ${maxDate.year()}`
  }

  const constructMomentFromState = () => moment().year(year).month(month - 1)

  const getMonthAsTwoDigits = () => {
    // Enforce two-digit format for month input
    if (disabled) return ''
    if (!month) return ''

    let formattedMonth = String(month)
    if (formattedMonth.length < 2) formattedMonth = formattedMonth.padLeft(2, '0')
    if (formattedMonth.startsWith('0')) formattedMonth.substr(1)
    return formattedMonth
  }

  React.useEffect(() => {
    if (month && !getMonthErrors() && year && !getYearErrors()) {
      form.setValue(name, constructMomentFromState())
    }
    if (!month || !year || getMonthErrors() || getYearErrors()) {
      // if any part of the date is not present, invalidate the previously exposed date
      form.setValue(name, null)
    }
  }, [month, year])

  React.useEffect(() => {
    if (disabled) {
      setMonth('')
      setYear('')
      form.setValue(name, null)
    }
  }, [disabled])

  React.useEffect(() => {
    constructMomentFromState()
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      options={{ required }}
      rules={{
        required,
        validate: () => getMonthErrors() || getYearErrors()
      }}
      render={() => (
        <>
          {label ? <Typography variant="body" sx={{ display: 'block', mb: 2, color: disabled ? 'neutral.disabled' : 'neutral.black' }}>{label}</Typography> : null}
          <Box sx={{ display: 'flex', columnGap: 3 }}>
            <Box sx={{ flex: 1 }}>
              <TextField
                name={name}
                label="Month"
                placeholder="MM"
                value={getMonthAsTwoDigits()}
                disabled={disabled}
                onChange={(e) => setMonth(e.target.value.replace(/\D/gi, '').substr(-2))}
                error={(Boolean(errors[name]) && Boolean(getMonthErrors()))}
                {...rest}
              />
              <Typography variant="tiny" color="error">{(errors[name] && getMonthErrors()) ? `${getMonthErrors()}` : ''}</Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                name={name}
                label="Year"
                placeholder="YYYY"
                value={year}
                disabled={disabled}
                onChange={(e) => setYear(e.target.value.replace(/\D/gi, ''))}
                error={(Boolean(errors[name]) && Boolean(getYearErrors()))}
                {...rest}
              />
              <Typography variant="tiny" color="error">{(errors[name] && getYearErrors()) ? `${getYearErrors()}` : ''}</Typography>
            </Box>
          </Box>
        </>
      )}
    />
  )
}

FormDatePickerTwo.defaultProps = {
  label: '',
  required: false,
  disabled: false,
  minDate: null,
  maxDate: null,
}

FormDatePickerTwo.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired, // This is the React Hook Form form object
  minDate: PropTypes.object, // Moment
  maxDate: PropTypes.object, // Moment
}

export default FormDatePickerTwo
