import React from 'react'
import PropTypes from 'prop-types'
import {
  Box
} from '@mui/material'
import {
  useEmissaryTheme
} from 'hooks'

const ResponsiveContent = ({ children }) => {
  const {
    verticalSpacing
  } = useEmissaryTheme()

  return (
    <Box sx={{ flexDirection: 'column', display: 'flex', rowGap: verticalSpacing }}>
      { children }
    </Box>
  )
}

ResponsiveContent.propTypes = {
  children: PropTypes.node.isRequired
}

export default ResponsiveContent
