import React from 'react'

import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  TextField
} from '@mui/material'

import {
  Plus,
  Trash
} from 'icons'

import { EditAccountPageContext } from '../EditAccountPageContext'

const TechStackForm = () => {
  const { data, setData, loaded } = React.useContext(EditAccountPageContext)
  const [techStackEntry, setTechStackEntry] = React.useState('')

  const setTechStack = (newTechStack) => {
    setData({
      ...data,
      techStack: newTechStack
    })
  }

  const addTechStackEntry = () => {
    if (techStackEntry.length && !entryIsInList) {
      setTechStack([...(data?.techStack || []), techStackEntry])
      setTechStackEntry('')
    }
  }

  const deleteTechStackEntry = (entryText) => {
    const newTechStackWithoutEntry = [...data.techStack]
    newTechStackWithoutEntry.splice(newTechStackWithoutEntry.indexOf(entryText), 1)
    setTechStack(newTechStackWithoutEntry)
  }

  if (!loaded) return <Skeleton variant="rounded" height="200px" />
  const entryIsInList = data?.techStack?.includes(techStackEntry)

  return (
    <List sx={{ width: '50%' }}>
      <ListItem>
        <TextField
          fullWidth
          error={entryIsInList}
          helperText={entryIsInList ? `${techStackEntry} is already in the list` : ''}
          label="Tech alignment entry"
          placeholder="Amazon Web Services"
          value={techStackEntry}
          onChange={(event) => setTechStackEntry(event.target.value)}
          onKeyPress={(event) => (event.key === 'Enter') && addTechStackEntry()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={addTechStackEntry}
                  edge="end"
                >
                  <Plus />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </ListItem>
      {data?.techStack?.map((techStackItem) => (
        <ListItem
          key={techStackItem}
          secondaryAction={(
            <IconButton onClick={() => deleteTechStackEntry(techStackItem)}>
              <Trash />
            </IconButton>
          )}
        >
          <ListItemText primary={techStackItem} />
        </ListItem>
      ))}
    </List>
  )
}

export default TechStackForm
