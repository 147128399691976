import PropTypes from 'prop-types'
import React from 'react'
import { LoadingButton } from '@mui/lab'
import {
  customAlert,
  downloadFile
} from 'utils-em'
import { Download } from 'icons'

const API_HOST = __API_HOST__

const ExportButton = ({ teamIds, searchValue }) => {
  const [isExporting, setIsExporting] = React.useState(false)

  const exportEngagementsAsCSV = async () => {
    setIsExporting(true)
    const searchParams = new URLSearchParams({
      teams: teamIds ? `[${teamIds.join(',')}]` : null,
      searchValue
    })
    try {
      const res = await fetch(
        `${API_HOST}/v1/customerEngagements/export?${searchParams}`,
        { credentials: 'include', method: 'GET' }
      )
      const blob = await res.blob()
      await downloadFile(blob, 'engagements.csv')
      customAlert('Exporting engagements to CSV')
    } catch (error) {
      customAlert('Unable to retrieve all engagements to save. Please contact support.', true)
    }
    setIsExporting(false)
  }

  return (
    <LoadingButton
      startIcon={<Download />}
      onClick={() => exportEngagementsAsCSV()}
      loading={isExporting}
    >
      Export data
    </LoadingButton>
  )
}

ExportButton.defaultProps = {
  teamIds: null,
  searchValue: null
}

ExportButton.propTypes = {
  teamIds: PropTypes.array,
  searchValue: PropTypes.string
}

export default ExportButton
