import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EnumAPI, ENUM_KEY_TO_ENUM_TYPE } from 'store'

export default function useGetEnums (enumKey, options = {}) {
  const { skip } = { skip: false, ...options }
  const dispatch = useDispatch()
  const [loaded, setLoaded] = React.useState(false)
  const enumType = ENUM_KEY_TO_ENUM_TYPE?.[enumKey]
  const listName = enumType && `${enumType}List`
  const enums = useSelector((state) => listName && state.enums?.[listName]) || []
  const objectType = options?.type

  const loadEnums = async () => {
    await dispatch(EnumAPI.get({ type: enumKey, queryStringParams: { object_type: objectType } }))
    setLoaded(true)
  }

  React.useEffect(() => { !skip && loadEnums() }, [])
  return { enums, loaded }
}
