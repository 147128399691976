import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import NavigationSidebarTwo from './NavigationSidebarTwo'
import AppState from '../../store/AppState'

const mapStateToProps = ({ appState }) => ({
  sideNavOpen: appState.sideNavOpen
})

const mapDispatchToProps = {
  navigateTo: (path) => (dispatch) => dispatch(push(path)),
  toggleSideNav: AppState.toggleSideNav
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationSidebarTwo)
