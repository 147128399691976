import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@mui/material'
import AccountsList from '../components/AccountsList'

const AccountsLayout = ({ customerId }) => (
  <Box sx={{ width: 'auto' }}>
    <AccountsList customerId={customerId} />
  </Box>
)

AccountsLayout.propTypes = {
  customerId: PropTypes.number.isRequired
}

export default AccountsLayout
