import { connect } from 'react-redux'

import CustomerLayout from '../layouts/CustomerLayout'

const mapStateToProps = ({ session }, ownProps) => ({
  customerId: parseInt(ownProps.match.params.id, 10),
  userFeatures: session.features
})

export default connect(mapStateToProps)(CustomerLayout)
