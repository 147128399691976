import PropTypes from 'prop-types'
import React from 'react'

import {
  Box,
  IconButton,
  SvgIcon,
  Link,
  Typography
} from '@mui/material'

const IconTextCombo = ({ icon, iconColor, hoverColor, iconBackground, text, typography, typographyColor, iconSize, isLink, ...rest }) => (
  <Box {...rest}>
    <Box sx={{ mr: 2, display: 'flex', flexDirection: 'row', color: iconColor, alignItems: 'center', '&:hover': { color: hoverColor || iconColor } }}>
      <Box sx={{ color: 'inherit', position: 'relative', top: '4px' }}>
        {isLink ? (
          <IconButton
            fontSize={iconSize}
            color="inherit"
            sx={{ bgcolor: iconBackground, p: 0 }}
            disableRipple
          >
            {icon}
          </IconButton>
        ) : (
          <SvgIcon fontSize={iconSize}>
            {icon}
          </SvgIcon>
        )}
      </Box>
      {isLink ? (
        <Link
          variant={typography}
          color="inherit"
          sx={{ ml: 1, mt: 1, position: 'relative', top: '1px' }}
          underline="none"
        >
          {text}
        </Link>
      ) : (
        <Typography
          variant={typography}
          color={typographyColor}
          sx={{ ml: 1, position: 'relative', top: '1px' }}
        >
          {text}
        </Typography>
      )}
    </Box>
  </Box>

)

IconTextCombo.defaultProps = {
  iconColor: 'neutral.disabled',
  iconBackground: 'transparent',
  hoverColor: null,
  typography: 'caption',
  typographyColor: 'neutral.darkGrey',
  iconSize: 'small',
  isLink: false
}

IconTextCombo.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  iconColor: PropTypes.string,
  hoverColor: PropTypes.string,
  iconBackground: PropTypes.string,
  typography: PropTypes.string,
  typographyColor: PropTypes.string,
  iconSize: PropTypes.string,
  isLink: PropTypes.bool
}

export default IconTextCombo
