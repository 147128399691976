import { connect } from 'react-redux'

import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'
import UserSelector from '../UserSelector/UserSelector'

const mapStateToProps = ({ data }) => ({
  users: buildJsonApiOrdered(data, 'customerUsers', 'cuSelectorOrder')
})

const mapDispatchToProps = {
  userLookup: (userId) => JsonAPI.getOne({
    type: 'customerUsers',
    id: userId
  }),
  userSearch: (searchText, customerId, showInactive) => {
    const filter = [
      { name: 'fullName', op: 'like', val: `%${searchText}%` },
      { name: 'customerId', op: 'eq', val: customerId }
    ]
    if (!showInactive) {
      filter.push({ name: 'isActive', op: 'is', val: true })
    }
    return JsonAPI.getAll({
      orderKey: 'cuSelectorOrder',
      type: 'customerUsers',
      queryStringParams: {
        filter
      }
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSelector)
