import { connect } from 'react-redux'
import { Alerts, JsonAPI } from 'store'
import AdvisorAdminNotesCard from './AdvisorAdminNotesCard'

const mapDispatchToProps = {
  saveAdvisor: (advisor) => JsonAPI.save(advisor),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message))
}

export default connect(null, mapDispatchToProps)(AdvisorAdminNotesCard)
