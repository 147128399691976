import React from 'react'
import PropTypes from 'prop-types'

import { useJsonAPIGetOne } from 'hooks'
import { ChipMultiAutocomplete } from 'components'

const QuestionMultiSelector = ({ surveyId, onChange, initialQuestions }) => {
  const { object: survey, loaded, reload } = useJsonAPIGetOne(surveyId, 'surveys', ['questions'])
  const questions = survey?.questions || []
  const [selectedQuestions, setSelectedQuestions] = React.useState(initialQuestions)

  React.useEffect(() => { onChange(selectedQuestions) }, [selectedQuestions])
  React.useEffect(() => {
    // reset on survey change
    setSelectedQuestions([])
    reload()
  }, [surveyId])
  React.useEffect(() => { setSelectedQuestions(initialQuestions) }, [initialQuestions])

  return (
    <ChipMultiAutocomplete
      options={questions}
      initialSelectedOptions={selectedQuestions}
      selectedOptionsCallback={setSelectedQuestions}
      label="Question"
      placeholder="Enter question text"
      getOptionLabel={(question) => `[id:${question.id}, type:${question.questionType}] ${question.questionText}`}
      variant="standard"
      disabled={!surveyId || !loaded}
    />
  )
}

QuestionMultiSelector.defaultProps = {
  initialQuestions: [],
}

QuestionMultiSelector.propTypes = {
  surveyId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initialQuestions: PropTypes.array,
}

export default QuestionMultiSelector
