import { connect } from 'react-redux'
import { EnumAPI, JsonAPI } from 'store'

import AdvisorProfilePreview from './AdvisorProfilePreview'

const mapStateToProps = (_, ownProps) => ({
  advisorId: ownProps.match.params.id
})

const mapDispatchToProps = {
  getOne: JsonAPI.getOne,
  getEnum: EnumAPI.get
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorProfilePreview)
