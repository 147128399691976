import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Box,
} from '@mui/material'
import { JsonAPI } from 'store'
import {
  Spinner
} from 'components'
import {
  buildJsonApiOne,
  getAllScheduledCalls,
  getCustomerPostCallFeedback
} from 'utils-em'

import ActivityItem from './components/ActivityItem'

const EngagementDetailsActivityLogTab = ({ engagementId }) => {
  const [loaded, setLoaded] = React.useState(false)
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const user = useSelector(({ session }) => session)
  const isAdvisorUser = user.userType === 'advisor'

  const dispatch = useDispatch()

  const loadData = () => dispatch(JsonAPI.getOne({
    type: 'engagements',
    id: engagementId,
    queryStringParams: {
      include: [
        'assets',
        'events',
        'feedback',
        'messages',
        'messages.sender',
        'messages.attachments',
        'advisorSingleCallFeedback',
        'timeslotProposals.decliningUser'
      ].join(',')
    }
  })).then(() => setLoaded(true))

  React.useEffect(() => { loadData() }, [])

  if (!loaded) { return <Spinner /> }

  const getActivities = () => {
    const { events, messages } = engagement
    const assetEvents = getAssetEvents(engagement)
    const timeslotEvents = getTimeslotEvents(engagement)
    const feedbackEvents = getFeedbackEvents(engagement)
    const activities = events.concat(messages, assetEvents, timeslotEvents, feedbackEvents)
    activities.sort((a, b) => ((a.dateCreated > b.dateCreated) ? -1 : 1))
    return activities
  }

  const getTimeslotEvents = () => {
    const timeslots = getAllScheduledCalls(engagement)
    const timeslotEvents = []
    let count = 0

    if (!timeslots) return []
    timeslots.forEach((timeslot) => {
      if (timeslot && timeslot.endTime && Date.parse(timeslot.endTime) < Date.parse(new Date())) {
        timeslotEvents.push({
          id: timeslot.id,
          type: 'events',
          eventName: count === 0 ? 'CALL_COMPLETED' : 'MULTI_CALL_COMPLETED',
          actionRequired: false,
          dateCreated: timeslot.endTime
        })
      }
      count += 1
    })

    return timeslotEvents
  }

  const getFeedbackEvents = () => {
    let feedbackForms = []
    if (isAdvisorUser) {
      feedbackForms = engagement.advisorSingleCallFeedback ? [engagement.advisorSingleCallFeedback] : []
    } else {
      const feedback = getCustomerPostCallFeedback(engagement)
      if (feedback) {
        feedbackForms.push(feedback)
      }
    }
    const feedbackEvents = []
    feedbackForms.forEach((feedbackForm, i) => {
      feedbackEvents.push({
        id: `feedback-${i}`,
        type: isAdvisorUser ? 'advisorSingleCallFeedback' : 'customerPostCallFeedbackForm',
        eventName: isAdvisorUser ? 'ADVISOR_FEEDBACK_SUBMITTED' : 'CUSTOMER_FEEDBACK_SUBMITTED',
        dateCreated: feedbackForm.dateCreated
      })
    })
    return feedbackEvents
  }

  const getAssetEvents = () => {
    const { assets } = engagement
    const assetEvents = []
    assets.forEach(({ assetType, dateUpdated }) => {
      const assetObj = { id: assetType, dateCreated: dateUpdated }
      assetType === 'engagement-notes' && assetEvents.push({
        ...assetObj,
        eventName: 'ENGAGEMENT_NOTES_PUBLISHED'
      })
      assetType === 'engagement-transcript' && assetEvents.push({
        ...assetObj,
        eventName: 'ENGAGEMENT_TRANSCRIPT_PUBLISHED',
      })
      assetType === 'engagement-recording' && assetEvents.push({
        ...assetObj,
        eventName: 'ENGAGEMENT_RECORDING_PUBLISHED',
      })
    })
    return assetEvents
  }

  const activities = getActivities()

  return (
    <>
      <Box sx={{ mt: 2 }}>
        {activities.map((activity) => <ActivityItem key={activity.id} activity={activity} engagement={engagement} user={user} loadData={loadData} />)}
      </Box>
    </>
  )
}

EngagementDetailsActivityLogTab.propTypes = {
  engagementId: PropTypes.number.isRequired
}

export default EngagementDetailsActivityLogTab
