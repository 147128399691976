import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@mui/material'

const Toggle = ({ value, onChange, label, ...switchProps }) => (
  <FormControlLabel
    control={(
      <Switch
        checked={value}
        onChange={onChange}
        name="hasPage"
        {...switchProps}
      />
    )}
    label={label}
  />
)

Toggle.defaultProps = {
  label: null,
}

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
}

export default Toggle
