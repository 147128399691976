import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@mui/material'
import APILoaderContainer from '../../../../../../../containers/APILoaderContainer'
import AssetList from '../../../../../../../components/AssetList'

const ContentManagementLayout = ({ getEnum }) => (
  <APILoaderContainer
    onMount={(done) => {
      Promise.all([
        getEnum({ type: 'asset_types' })
      ]).then(() => { done() })
    }}
  >
    <Box sx={{ width: '90%', mt: 0 }}>
      <AssetList />
    </Box>
  </APILoaderContainer>
)

ContentManagementLayout.propTypes = {
  getEnum: PropTypes.func.isRequired
}

export default ContentManagementLayout
