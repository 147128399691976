import { FEEDBACK_QUESTION_TYPE } from './feedbackUtils'

const CUSTOMER_POST_CALL_FEEDBACK_EIGHT_QUESTIONS = [
  {
    name: 'overallCallAgreement',
    text: 'How strongly would you agree with the following statements about the call with your Emissary advisor?',
    type: FEEDBACK_QUESTION_TYPE.Multiple,
    multipleOfType: FEEDBACK_QUESTION_TYPE.Agreement,
    subQuestions: [
      'The call saved me research time',
      'I gained new insights into this account',
      'I\'m better prepared to take my next steps with this account'
    ]
  },
  {
    name: 'accountPosition',
    text: 'How would you describe your position within the account that you discussed with the advisor?',
    type: FEEDBACK_QUESTION_TYPE.RadioSelection,
    options: [
      'Pre-Funnel/Top of Funnel - prospecting, qualifying, discovery',
      'In Funnel - identifying needs, scoping solutions, engaging stakeholders',
      'Bottom of Funnel - final pricing/proposals, negotiating, de-risking deals'
    ]
  },
  {
    name: 'insightUsage',
    text: 'How will you use the insights you gained? Check all the sales tasks where you plan to apply the insights.',
    type: FEEDBACK_QUESTION_TYPE.CheckboxSelection,
    options: [
      [
        'Conduct background research',
        'Qualify or prioritize account for pursuit',
        'Identify prospect contacts',
        'Identify account pain points',
        'Build a penetration strategy (messaging, entry points…)'
      ],
      [
        'Prepare for upcoming calls',
        'Build / refine an account plan',
        'Identify decision stakeholders',
        'Refine solution scope and positioning',
        'Develop action steps to progress opportunity'
      ],
      [
        'Clarify upcoming steps in the buying process (reviews etc.)',
        'Build final pricing and proposals',
        'Anticipate deal blockers, de-risk the deal',
        'Develop a plan to \'un-stick\' a stalled deal',
        'Prepare for negotiations & contracting'
      ]
    ]
  },
  {
    name: 'accountNextStep',
    text: 'What\'s your next step with this account?',
    type: FEEDBACK_QUESTION_TYPE.FreeResponse
  },
  {
    name: 'recommendToColleague',
    text: 'On a scale of 0-10, how likely are you to recommend Emissary to a sales manager or co-worker?',
    type: FEEDBACK_QUESTION_TYPE.ScaleTen,
    lowText: 'Not at all likely',
    highText: 'Extremely likely'
  },
  {
    name: 'mostUsefulPart',
    text: 'What was the most useful part of your Emissary experience so far?',
    type: FEEDBACK_QUESTION_TYPE.FreeResponse
  },
  {
    name: 'recommendationToImproveExperience',
    text: 'What, if anything, would you recommend to improve the experience?',
    type: FEEDBACK_QUESTION_TYPE.FreeResponse
  }
]

export default CUSTOMER_POST_CALL_FEEDBACK_EIGHT_QUESTIONS
