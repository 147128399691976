import React from 'react'
import { Box } from '@mui/material'
import TopicsTable from './components/TopicsTable'

const TopicEditor = () => (
  <Box sx={(theme) => ({ ...theme.pageContentSx, p: 0, width: '100%' })}>
    <TopicsTable />
  </Box>
)

export default TopicEditor
