import { combineReducers } from 'redux'

import * as ACTION_TYPE from './constants'

const INITIAL_STATE = {
  isLoading: false,
  lastCaller: '',
  error: null
}

function requestReducer (state = INITIAL_STATE, action) {
  // switch (action.type) {
  //   case ACTION_TYPE.REQUEST_INIT:
  //     return Object.assign({}, state, { isLoading: action.isLoading, lastCaller: action.caller })
  //   case ACTION_TYPE.REQUEST_SUCCESS:
  //     return Object.assign({}, state, { isLoading: action.isLoading })
  //   case ACTION_TYPE.REQUEST_ERROR:
  //     return Object.assign({}, state, { isLoading: action.isLoading, error: action.err })
  //   default:
  //     return state
  // }
  return state
}

function cacheReducer (state = {}, action) {
  switch (action.type) {
    case ACTION_TYPE.CACHE_REQUEST: {
      return { ...state, [action.key]: true }
    }
    case ACTION_TYPE.CACHE_RESET: {
      const newState = { ...state }
      delete newState[action.key]

      return newState
    }
    default: {
      return state
    }
  }
}

export default combineReducers({ request: requestReducer, cached: cacheReducer })
