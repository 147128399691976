export function getTagsByCategory (tags, category) {
  return tags.filter((tag) => tag.category === category)
}

export function addTag (stateObjectName) {
  return (tag) => {
    const newTags = Object.assign([], this.state[stateObjectName].tags).concat(tag)
    this.setState((prevState) => (
      {
        [stateObjectName]: {

          ...prevState[stateObjectName],
          tags: newTags
        }
      }
    ))
  }
}

export function removeTag (stateObjectName) {
  return (tagToRemove) => {
    const newTags = Object.assign([], this.state[stateObjectName].tags).filter((tag) => tag.id !== tagToRemove.id)
    this.setState((prevState) => (
      {
        [stateObjectName]: {

          ...prevState[stateObjectName],
          tags: newTags
        }
      }
    ))
  }
}

export function getRoleFunction (role) {
  if (role.tags) {
    return getTagsByCategory(role.tags, 'function-of-role').map((tag) => tag.name)
  }
  return []
}

export const getTagsFromAssets = (assets, options) => {
  const { sort, tagsToExclude, categories } = {
    sort: true,
    tagsToExclude: ['All'],
    categories: ['vertical'],
    ...options
  }

  if (!assets) return null
  const result = {}
  assets.forEach((asset) => {
    asset?.tags?.forEach((t) => { result[t.id] = t })
  })

  const tags = Object.values(result)
    ?.filter((t) => !tagsToExclude?.length || !tagsToExclude.includes(t?.name))
    ?.filter((t) => !categories?.length || categories.includes(t?.category))

  if (sort) tags.sort((a, b) => a.name.localeCompare(b.name))
  return tags
}
