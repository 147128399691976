import { connect } from 'react-redux'

import { buildJsonApiOrdered, queryStringParser } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'
import CustomerUsersList from './CustomerUsersList'

const mapStateToProps = ({ data, router }) => ({
  customerUsers: buildJsonApiOrdered(data, 'customerUsers'),
  totalCount: data.meta.customerUsers.count,
  linkedCustomerUserId: parseInt(queryStringParser(router.location).customerUserId, 10),
  linkedDivisionId: queryStringParser(router.location).divisionId
})

const mapDispatchToProps = {
  getAllCustomerUsers: (customerId, size, page, sort, showInactive, filters = []) => {
    const filter = [{
      name: 'customerId',
      op: 'eq',
      val: customerId
    }]

    if (!showInactive) {
      filter.push({
        name: 'isActive',
        op: 'eq',
        val: 1
      })
    }
    return JsonAPI.getAll({
      type: 'customerUsers',
      queryStringParams: {
        // go 5 levels back on parent
        include: [
          'divisions',
          'divisions.parent',
          'divisions.parent.parent',
          'divisions.parent.parent.parent',
          'divisions.parent.parent.parent.parent',
          'divisions.parent.parent.parent.parent.parent',
          'customer'
        ].join(','),
        'page[number]': page,
        'page[size]': size,
        filter: filter.concat(filters),
        relationshipNames: ['divisions'],
        sort
      }
    })
  },
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog)),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message))
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsersList)
