import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  useTheme
} from '@mui/material'

import {
  Badge
} from 'icons'

const ActivityItem = ({ title, subtitle, children }) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', mx: 2, my: 2 }}>
      <Badge fill={theme.palette.brand.paleSlate} />
      <Box>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="tiny" color="neutral.darkGrey">{subtitle}</Typography>
        {children}
      </Box>
    </Box>
  )
}

ActivityItem.defaultProps = {
  children: null
}

ActivityItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ActivityItem
