import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import {
  Document,
  Page,
  pdfjs
} from 'react-pdf'

const PDFViewer = ({ pdfPath, ...rest }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString()
  const [pages, setPages] = React.useState(null)

  const memoizedPages = React.useMemo(() => Array.from(new Array(pages || 0), (el, index) => (
    <Page
      key={`page_${index + 1}`}
      pageNumber={index + 1}
      loading={index === 0 ? 'Loading Page' : null}
    />
  )), [pages])

  return (
    <Box {...rest}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& canvas': {
          width: '100% !important',
          height: 'auto !important',
        }
      }}
      >
        <Document
          file={{ url: pdfPath, withCredentials: true }}
          onLoadSuccess={({ numPages }) => setPages(numPages)}
        >
          {memoizedPages}
        </Document>
      </Box>
    </Box>

  )
}

PDFViewer.propTypes = {
  pdfPath: PropTypes.string.isRequired,
}

export default React.memo(PDFViewer, (prevProps, nextProps) => prevProps.pdfPath === nextProps.pdfPath)
