import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  CardContent,
  Container,
  Card,
  Box,
  Link,
  Typography,
  Button
} from '@mui/material'
import {
  TogglablePasswordInput
} from 'components'

import {
  navigateTo,
  validatePasswordStrict
} from 'utils-em'
import { SessionAPI } from 'store'

const ResetForm = () => {
  const dispatch = useDispatch()

  const [password, setPassword] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [resetDone, setResetDone] = React.useState(false)

  const queryParams = new URLSearchParams(useLocation().search)
  const token = queryParams.get('token')

  const attemptLogin = async () => {
    setIsSubmitting(true)

    const passwordValidationStrict = validatePasswordStrict(password)
    if (!passwordValidationStrict.valid) {
      setError(passwordValidationStrict.messages[0].message)
      setIsSubmitting(false)
      return
    }

    const result = await dispatch(SessionAPI.reset({ password, token }))
    if (result.error) {
      setError(true)
    } else {
      setResetDone(true)
    }
    setIsSubmitting(false)
  }
  return (
    <Container maxWidth="desktop">
      <Card sx={{ mt: 7, mb: 5 }}>
        <CardContent>
          <ValidatorForm
            onSubmit={attemptLogin}
            sx={{
              backgroundColor: 'neutral.white',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ p: 10, width: '100%' }}>
                <Box typography="h2" sx={{ mb: 3 }}>Reset your password</Box>
                {resetDone ? (
                  <>
                    <Box sx={{ mb: 3 }}>
                      <Box typography="body1" sx={{ display: 'inline' }}>An email has been sent to </Box>
                      <Link>
                        {123}
                      </Link>
                    </Box>
                  </>
                )
                  : (
                    <>
                      <Box>
                        <TogglablePasswordInput
                          fullWidth
                          required
                          variant="outlined"
                          name="password"
                          value={password}
                          type="password"
                          onChange={(event) => {
                            setError(false)
                            setPassword(event.target.value)
                          }}
                          label="New Password"
                        />
                      </Box>
                      {error && (<Typography color="error.main" sx={{ mb: 2 }}>{error}</Typography>)}
                      <Box sx={{ mt: 3, display: 'flex', gap: 3 }}>
                        <Button type="submit" disabled={!password || isSubmitting} variant="contained" sx={{ display: 'flex', flexGrow: 0, mt: 3 }}>
                          Reset password
                        </Button>
                        <Button variant="primaryLink" sx={{ mt: 3 }} onClick={() => navigateTo('/')}>
                          Go back
                        </Button>
                      </Box>
                    </>
                    )}
              </Box>
            </Box>
          </ValidatorForm>
        </CardContent>
      </Card>
      <Box sx={{ mb: 5 }}>
        <Typography variant="caption" color="neutral.black">
          {'If you have any questions or need help, email '}
          <Link href="mailto:support@emissary.io" rel="noreferrer" target="_blank">
            support@emissary.io
          </Link>
        </Typography>
      </Box>
    </Container>
  )
}

export default ResetForm
