/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const ErrorBoundary = ({ history, children, errorComponent, onError }) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const unlisten = history.listen((_location, _action) => {
      if (hasError) {
        setHasError(false)
      }
    })

    return () => {
      unlisten()
    }
  }, [hasError, history])

  useEffect(() => {
    const handleError = () => {
      setHasError(true)
    }

    window.addEventListener('error', handleError)

    return () => {
      window.removeEventListener('error', handleError)
    }
  }, [])

  useEffect(() => {
    if (hasError) {
      onError()
    }
  }, [hasError, onError])

  if (hasError) {
    return errorComponent
  }

  return children
}

ErrorBoundary.defaultProps = {
  errorComponent: null,
  onError: () => null,
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  errorComponent: PropTypes.node,
  onError: PropTypes.func,
}

export default withRouter(ErrorBoundary)
