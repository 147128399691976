import PropTypes from 'prop-types'
import React from 'react'

import {
  Building,
  Customer,
  Dollar,
  Grid,
  Network,
  Users
} from 'icons'

import NavigationSidebar from '../../../../../components/NavigationSidebar'
import CustomerRoutes from '../routes'

const DashboardLayout = ({ customerId, userFeatures }) => {
  const links = [{
    text: 'Edit Client',
    icon: <Customer />,
    href: `/admin/customers/${customerId}`
  }, {
    text: 'People',
    icon: <Users />,
    href: `/admin/customers/${customerId}/people`
  }, {
    text: 'Teams',
    icon: <Grid />,
    href: `/admin/customers/${customerId}/teams`
  }, {
    text: 'Team Hierarchy',
    icon: <Network />,
    href: `/admin/customers/${customerId}/team-hierarchy`
  }, {
    text: 'Accounts',
    icon: <Building />,
    href: `/admin/customers/${customerId}/advisor-requests`
  }, {
    text: 'Contracts',
    icon: <Dollar />,
    href: `/admin/customers/${customerId}/contracts`
  }]
  return (
    <>
      <NavigationSidebar links={links} />
      <CustomerRoutes />
    </>
  )
}

DashboardLayout.defaultProps = {
  customerId: null
}

DashboardLayout.propTypes = {
  customerId: PropTypes.number,
  userFeatures: PropTypes.array.isRequired
}

export default DashboardLayout
