
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { JsonAPI } from 'store'

const AdvisorFeedbackForm = ({ advisorId, engagementId, handleComplete }) => {
  const dispatch = useDispatch()
  const [expectationsMet, setExpectationsMet] = React.useState()
  const [discussionTopics, setDiscussionTopics] = React.useState()
  const [clientSalesOpportunity, setClientSalesOpportunity] = React.useState()
  const [advisorSatisfied, setAdvisorSatisfied] = React.useState()
  const [suggestionBox, setSuggestionBox] = React.useState()
  const [participateAgain, setParticipateAgain] = React.useState()
  const [recommendedRecently, setRecommendedRecently] = React.useState()

  const questions = [
    {
      questionText: 'Did this call meet your expectations?*',
      answerComponent: () => (
        <RadioGroup
          sx={{ ml: '12px', color: 'neutral.disabled' }}
          name="expectationsMet"
          onChange={(event) => setExpectationsMet(event.target.value)}
        >
          <FormControlLabel
            value
            label="Yes"
            control={<Radio />}
          />
          <FormControlLabel
            value={false}
            label="No"
            control={<Radio />}
          />
        </RadioGroup>
      )
    },
    {
      questionText: 'What was discussed on the call?*',
      answerComponent: () => (
        <TextField
          label="Your response"
          onChange={(event) => setDiscussionTopics(event.target.value)}
          fullWidth
          multiline
          rows={3}
          maxRows={3}
          sx={{ mt: 2, backgroundColor: '#ffffff' }}
        />
      )
    },
    {
      questionText: 'Based on the call, do you believe the seller could have an opportunity to sell into their target account?*',
      answerComponent: () => (
        <RadioGroup
          sx={{ ml: '12px', color: 'neutral.disabled' }}
          name="clientSalesOpportunity"
          onChange={(event) => setClientSalesOpportunity(event.target.value)}
        >
          <FormControlLabel
            value
            label="Yes"
            control={<Radio />}
          />
          <FormControlLabel
            value={false}
            label="No"
            control={<Radio />}
          />
        </RadioGroup>
      )
    },
    {
      questionText: 'So far, are you satisfied with your experience working with Emissary?*',
      answerComponent: () => (
        <RadioGroup
          sx={{ ml: '12px', color: 'neutral.disabled' }}
          name="advisorSatisfied"
          onChange={(event) => setAdvisorSatisfied(event.target.value)}
        >
          <FormControlLabel
            value
            label="Yes"
            control={<Radio />}
          />
          <FormControlLabel
            value={false}
            label="No"
            control={<Radio />}
          />
        </RadioGroup>
      )
    },
    {
      questionText: 'Tell us what you love about Emissary or what we can do better.',
      answerComponent: () => (
        <TextField
          label="Your response"
          onChange={(event) => setSuggestionBox(event.target.value)}
          fullWidth
          multiline
          rows={3}
          maxRows={3}
          sx={{ mt: 2, backgroundColor: '#ffffff' }}
        />
      )
    },
    {
      questionText: 'Following the conclusion of this opportunity, would you like to participate in another engagement?*',
      answerComponent: () => (
        <RadioGroup
          sx={{ ml: '12px', color: 'neutral.disabled' }}
          name="participateAgain"
          onChange={(event) => setParticipateAgain(event.target.value)}
        >
          <FormControlLabel
            value
            label="Yes"
            control={<Radio />}
          />
          <FormControlLabel
            value={false}
            label="No"
            control={<Radio />}
          />
        </RadioGroup>
      )
    },
    {
      questionText: 'In the past 6 weeks, have you recommended advising with Emissary to a friend or colleague?*',
      answerComponent: () => (
        <RadioGroup
          sx={{ ml: '12px', color: 'neutral.disabled' }}
          name="recommendedRecently"
          onChange={(event) => setRecommendedRecently(event.target.value)}
          defaultValue="no"
        >
          <FormControlLabel
            value
            label="Yes"
            control={<Radio />}
          />
          <FormControlLabel
            value={false}
            label="No"
            control={<Radio />}
          />
        </RadioGroup>
      )
    }
  ]

  const handleSend = () => {
    dispatch(JsonAPI.create({
      type: 'advisorSingleCallFeedbackForms',
      advisorId,
      engagementId,
      expectationsMet,
      discussionTopics,
      clientSalesOpportunity,
      advisorSatisfied,
      suggestionBox,
      participateAgain,
      recommendedRecently
    })).then((resp) => {
      handleComplete()
    })
  }

  const allRequirementsMet = () => (!!expectationsMet &&
    !!advisorSatisfied &&
    !!participateAgain &&
    !!recommendedRecently &&
    !!clientSalesOpportunity &&
    !!discussionTopics)

  return (
    <Grid container spacing={2}>
      {questions.map((questionObj) => (
        <Grid item key={questionObj.questionText} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4">
                {` ${questionObj.questionText} `}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {questionObj.answerComponent(this)}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => handleSend()}
          disabled={!allRequirementsMet()}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}

AdvisorFeedbackForm.propTypes = {
  advisorId: PropTypes.string.isRequired,
  engagementId: PropTypes.string.isRequired,
  handleComplete: PropTypes.func.isRequired
}

export default AdvisorFeedbackForm
