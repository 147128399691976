import React from 'react'
import PropTypes from 'prop-types'

import {
  formatDate
} from 'utils-em'

import ActivityItem from '../ActivityItem'

const EngagementCanceled = ({ activity, ...props }) => (
  <ActivityItem
    title="Engagement canceled"
    subtitle={formatDate(activity.dateCreated)}
    {...props}
  />
)

EngagementCanceled.propTypes = {
  activity: PropTypes.object.isRequired
}

export default EngagementCanceled
