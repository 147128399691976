import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@mui/material'
import TeamTree from '../../../../../../../components/TeamTree'

const DivisionHierarchyLayout = ({ customerId }) => (
  <Box sx={{ width: '100%' }}>
    <TeamTree customerId={customerId} />
  </Box>
)

DivisionHierarchyLayout.propTypes = {
  customerId: PropTypes.number.isRequired
}

export default DivisionHierarchyLayout
