import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller } from 'react-hook-form'

const FormDatePicker = ({ form, name, label, required, views, ...rest }) => {
  const { formState: { errors }, control, onChange } = form
  return (
    <Controller
      name={name}
      control={control}
      options={{ required }}
      rules={{ required }}
      render={({ field }) => {
        const momentValue = field.value ? moment(field.value) : null
        return (
          <DatePicker
            views={views}
            required={required}
            label={label}
            disableFuture
            value={momentValue}
            onChange={onChange}
            slotProps={{
              textField: {
                helperText: errors[name] ? 'Required field' : '',
                variant: 'outlined',
                error: Boolean(errors[name])
              },
              required,
              error: Boolean(errors[name]),
            }}
            {...field}
            {...rest}
          />
        )
      }}
    />
  )
}
FormDatePicker.defaultProps = {
  label: '',
  required: false,
  views: ['year', 'month']
}

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  form: PropTypes.object.isRequired, // This is the React Hook Form form object
  views: PropTypes.arrayOf(PropTypes.string)
}

export default FormDatePicker
