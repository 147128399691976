import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography
} from '@mui/material'
import { JsonAPI } from 'store'
import {
  buildJsonApiOne,
  closeDialog,
  getAdvisorSeniorityShorthand,
  getEnums,
  httpResponseAlert,
} from 'utils-em'
import {
  FormCheckbox,
  FormDatePickerTwo,
  FormSelect,
  FormTextField,
  Spinner,
  TagDropdown
} from 'components'
import { useIsAdmin } from 'hooks'

const EditRole = ({ roleId, companyId, onSave }) => {
  const isAdmin = useIsAdmin()
  const dispatch = useDispatch()
  const [loaded, setLoaded] = React.useState(false)
  const role = useSelector(({ data }) => buildJsonApiOne(data, 'roles', roleId))
  const company = useSelector(({ data }) => buildJsonApiOne(data, 'companies', companyId))
  const form = useForm({
    defaultValues: {
      title: role?.title,
      startDate: (role && role.dateStarted) ? moment(role.dateStarted) : null,
      endDate: (role && role.dateEnded) ? moment(role.dateEnded) : null,
      isCurrent: roleId ? !role?.dateEnded : false,
      seniority: role?.seniority,
      tags: role?.tags || [], // function of role, region
      description: role?.description || ''
    }
  })
  const [isCurrent, tags] = form.watch(['isCurrent', 'tags'])

  const seniorityEnums = useSelector(({ enums }) => enums.seniorityList)

  const onSubmit = async (data) => {
    if (!data.endDate && !isCurrent) return

    const method = roleId ? JsonAPI.save : JsonAPI.create
    await dispatch(method({
      id: roleId || undefined,
      companyId,
      ...data,
      dateStarted: data.startDate.toISOString(),
      dateEnded: !data.endDate && isCurrent ? null : data.endDate.toISOString(),
      isCurrent: !data.endDate && isCurrent,
      type: 'roles',
      relationshipNames: ['tags']
    })).then((roleResponse) => {
      onSave && onSave(data)
      closeDialog()
      httpResponseAlert(roleResponse)
    })
  }

  const setTags = (category, selectedTags) => {
    // Preserves all existing tags for other categories,
    // and replaces the tags of the given category with
    // the provided tags
    const otherTags = tags.filter((tag) => tag.category !== category) || []
    const updatedTags = [...selectedTags, ...otherTags]
    form.setValue('tags', updatedTags)
  }

  const loadEnums = () => (getEnums('seniority'))

  React.useEffect(() => {
    Promise.all([
      loadEnums()
    ]).then(() => setLoaded(true))
  }, [])

  const minStartDate = moment(company.dateStarted)
  const maxStartDate = roleId ? moment.min(moment(company.dateEnded), moment(role.dateEnded)) : moment(company.dateEnded)
  const minEndDate = roleId ? moment.max(moment(company.dateStarted), moment(role.dateStarted)) : moment(company.dateStarted)
  const maxEndDate = moment(company.dateEnded)
  const hasOtherCurrentRole = company.roles.some((r) => r.dateEnded === null && r.id !== roleId)
  return (
    <Dialog
      open
      maxWidth="md"
      onClose={closeDialog}
    >
      <DialogTitle>
        {`${roleId ? 'Edit' : 'Add'} role`}
      </DialogTitle>
      <DialogContent>
        {!loaded ? (<Spinner />) : (
          <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <FormTextField
              form={form}
              required
              name="title"
              label="Title"
            />
            {(company.isCurrent && !hasOtherCurrentRole) && (
              <Box sx={{ ml: 3 }}>
                <FormCheckbox
                  form={form}
                  name="isCurrent"
                  label={isAdmin ? 'Is current' : 'I currently hold this title'}
                />
              </Box>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 3, mt: 3 }}>
              <Box sx={{ width: '50%' }}>
                <FormDatePickerTwo
                  required
                  form={form}
                  name="startDate"
                  label="Role start date"
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <FormDatePickerTwo
                  required={!isCurrent || !company.isCurrent}
                  disabled={isCurrent && company.isCurrent}
                  form={form}
                  name="endDate"
                  label="Role end date"
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                />
              </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
              <TagDropdown
                multiple
                multipleLimit={2}
                multipleLimitError="Please select up to two tags"
                label="Function of role"
                tagCategory="function-of-role"
                initialTags={tags.filter((tag) => tag && tag.category === 'function-of-role') || []}
                onChange={(selectedTags) => setTags('function-of-role', selectedTags)}
              />
              {!isAdmin && (
                <Typography variant="caption" color="neutral.darkGrey">
                  Which 1 or 2 operational departments are most relevant for this role?
                </Typography>
              )}
            </Box>
            <Box sx={{ mt: 3 }}>
              <FormSelect
                form={form}
                name="seniority"
                label="Seniority level"
                items={seniorityEnums?.map((seniorityLevel) => ({
                  key: seniorityLevel,
                  value: seniorityLevel,
                  label: getAdvisorSeniorityShorthand(seniorityLevel)
                }))}
                fullWidth
                required
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <TagDropdown
                label="Regional responsibility"
                tagCategory="region"
                initialTags={tags.filter((tag) => tag && tag.category === 'region') || []}
                onChange={(selectedTags) => setTags('region', selectedTags)}
                displayAsHierarchy
              />
              {!isAdmin && (
                <Typography variant="caption" color="neutral.darkGrey">
                  Your geographical purview in this role
                </Typography>
              )}
            </Box>
            <Box sx={{ mt: 3 }}>
              <FormTextField
                form={form}
                name="description"
                label="Description of role"
                fullWidth
                multiline
                rows={4}
              />
              {!isAdmin && (
                <Typography variant="caption" color="neutral.darkGrey">
                  {
                    `We encourage you to enter a concise description of your former role. Outline accomplishments,
                    key skills, projects you worked on, business units you interacted with, and success stories.`
                  }
                </Typography>
              )}
            </Box>
            <DialogActions>
              <Button variant="text" onClick={closeDialog}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">Save</Button>
            </DialogActions>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

EditRole.propTypes = {
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSave: PropTypes.func.isRequired,
}

export default EditRole
