import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material'
import { useJsonAPIGetOne, useJsonAPIUpsert } from 'hooks'
import { ConfirmDialog, CustomerAutocomplete, UserEmailAutocomplete } from 'components'
import Delete from '@mui/icons-material/Delete'

const FeatureFlagDialog = ({ flagId, onUpdate }) => {
  const { object: flag, loaded } = useJsonAPIGetOne(flagId, 'featureFlags', ['customers', 'users'])
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })

  const [customers, setCustomers] = React.useState([])
  const [users, setUsers] = React.useState([])

  React.useEffect(() => {
    if (loaded) {
      setCustomers(flag?.customers || [])
      setUsers(flag?.users || [])
    }
  }, [loaded])

  const handleSave = async () => {
    await upsert({
      type: 'featureFlags',
      id: flagId,
      customers,
      users,
      include: 'customers,users',
      relationshipNames: ['customers', 'users']
    })
    onUpdate && onUpdate({ flagId })
  }

  const handleAddCustomer = (event, customer) => {
    if (!customer) return
    if (!customers.some((c) => c.id === customer.id)) {
      setCustomers([...customers, customer])
    }
  }

  const handleAddUser = (event, userList) => {
    if (!userList?.length) return
    const user = userList[0]
    if (!users.some((u) => u.id === user.id)) {
      setUsers([...users, user])
    }
  }

  const removeCustomer = (customerId) => {
    setCustomers(customers.filter((c) => c.id !== customerId))
  }

  const removeUser = (userId) => {
    setUsers(users.filter((u) => u.id !== userId))
  }

  const renderCustomerList = () => (
    <List dense>
      {customers.map((c) => (
        <ListItem key={c.id}>
          <ListItemText>{c.name}</ListItemText>
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={() => removeCustomer(c.id)}>
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )

  const renderUserList = () => (
    <List dense>
      {users.map((u) => (
        <ListItem key={u.id}>
          <ListItemText>{u.email}</ListItemText>
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={() => removeUser(u.id)}>
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )

  return (
    <ConfirmDialog
      title={loaded && `Edit ${flag?.feature}`}
      description={(
        <Box sx={{ width: '600px', minWidth: '600px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              <CustomerAutocomplete
                label="Customers"
                onChange={handleAddCustomer}
                helperText="Start Typing to select a customer"
                multiple={false}
              />
              {renderCustomerList()}
            </Box>
            <Box>
              <UserEmailAutocomplete
                label="Users"
                onChange={handleAddUser}
                helperText="Start typing email to select a user"
              />
              {renderUserList()}
            </Box>
          </Box>
        </Box>
      )}
      actions={[
        {
          name: 'Save',
          action: handleSave,
        },
      ]}
      cancelName="Close"
      size="xl"
    />
  )
}

FeatureFlagDialog.defaultProps = {
  onUpdate: null,
}

FeatureFlagDialog.propTypes = {
  flagId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
}

export default FeatureFlagDialog
