import React from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { emissaryTheme } from 'themes'
import { Box } from '@mui/material'
import { Header } from 'components'
import Login from '../components/Login'

const LoginContainer = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={emissaryTheme}>
      <Box sx={{ display: 'flex', bgcolor: 'brand.lightNavy' }}>
        <Box sx={{
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
          gap: 0
        }}
        >
          <Box sx={{
            pl: '0px',
            flexGrow: 1,
            minHeight: (theme) => '100vh'
          }}
          >
            <Header
              relativePosition
              menuItemsVisible={{
                stealth: false,
                notifications: false,
                menu: false
              }}
            />
            <Login />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default LoginContainer
