const FILENAME_MATCHER = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
const ENCLOSING_DOUBLE_QUOTES_MATCHER = /^"+|"+$/g

/**
 * Parse a "Content-Disposition" header and return the filename. Pass in
 * a raw "Content-Disposition" header, e.g. 'attachment; filename="mydata.csv"',
 * run a regex match to extract the filename, and return the extracted filename.
 * Finally, trim leading or trailing double quotes("), if any.
 *
 * For the source of the regex, see https://stackoverflow.com/a/23054920/5843191.
 *
 * @param {string} contentDisposition
 *
 * @return {string}
 */
export function filenameParser (contentDisposition) {
  const filenameMatch = FILENAME_MATCHER.exec(contentDisposition)[1]
  return filenameMatch.replace(ENCLOSING_DOUBLE_QUOTES_MATCHER, '')
}

export function gcsFilenameFormatter (gcsObjectName) {
  // format should be abms/<id>/file_name.pdf. we want file_name.pdf
  return gcsObjectName.split('/').slice(-1)
}

/**
 * This is a function that enables downloading of files that were
 * fetched using the fetch API. It expects a File object
 * (https://developer.mozilla.org/en-US/docs/Web/API/File).
 *
 * @param {File} file
 */
export function downloadFile (file, name = null) {
  const objectURL = URL.createObjectURL(file)

  const tempElement = document.createElement('a')
  document.body.appendChild(tempElement)

  tempElement.style = 'display: none'
  tempElement.href = objectURL
  tempElement.download = name || file.name
  tempElement.click()

  return new Promise((resolve) => {
    const cleanUp = () => {
      URL.revokeObjectURL(objectURL)
      document.body.removeChild(tempElement)
      resolve()
    }
    setTimeout(cleanUp, 100)
  })
}

export async function downloadFromV1Url (event, route, fileName) {
  event.preventDefault()
  const url = `${__API_HOST__}/v1/${route}`

  const resp = await fetch(url,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
  if (!resp.ok) {
    const errorMsg = await resp.text()
    throw new Error(`Download error: ${errorMsg}`)
  }
  const blob = await resp.blob()
  await downloadFile(blob, fileName)
}

export function downloadAsset (e, assetId, assetTitle) {
  return downloadFromV1Url(e, `assets/${assetId}/download`, assetTitle)
}

export function downloadAttachment (e, attachmentId, attachmentName) {
  return downloadFromV1Url(e, `attachments/${attachmentId}/download`, attachmentName)
}

export async function downloadAllEngagementAssets (e, engagementId) {
  e.preventDefault()
  return downloadFromV1Url(
    e,
    `export/${engagementId}/assets`,
    `engagement_${engagementId}.zip`
  )
}

export async function downloadAssetFromGcs (assetId, fileName) {
  const signedUrlRequest = await fetch(`${__API_HOST__}/v1/assets/${assetId}/download?direct=true`, { credentials: 'include' })
  const signedUrl = await signedUrlRequest.text()

  const gcsRequest = await fetch(signedUrl)
  const gcsBlob = await gcsRequest.blob()
  return downloadFile(gcsBlob, fileName)
}

export function downloadExportedResourceAsCSV (e, resourceName, fileName, fields, queryStringParams) {
  const searchParams = new URLSearchParams({
    ...queryStringParams,
    filter: JSON.stringify(queryStringParams.filter),
    fields: JSON.stringify(fields)
  })
  return downloadFromV1Url(
    e,
    `export/${resourceName}?${searchParams.toString()}`,
    `${fileName}.csv`
  )
}

export function convertFileToBase64 (file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onerror = () => reject(fileReader.error)
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.readAsDataURL(file)
  })
}

export async function generateUploadUrl (objectName, options = {}) {
  const { mimeType } = { mimeType: 'application/octet-stream', ...options }
  const res = await fetch(`${__API_HOST__}/v1/generate-upload-url`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ objectName, mimeType }),
  })
  return res.text()
}

export async function uploadFileDirectly (file, bucketPath) {
  const mimeType = file.type
  const signedUrl = await generateUploadUrl(`${bucketPath}/${file.name}`, { mimeType })
  const res = await fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': mimeType
    },
    body: file,
  })
  return res
}
