import { connect } from 'react-redux'

import CoverageReporterUploadStep from './CoverageReporterUploadStep'
import Alerts from '../../../../../../store/Alerts'

const mapDispatchToProps = {
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message))
}

export default connect(null, mapDispatchToProps)(CoverageReporterUploadStep)
