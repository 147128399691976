import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import {
  FormDatePicker,
  FormSelect
} from 'components'

const steps = [
  'UPLOAD_STEP',
  'CONFIRM_STEP'
]

const BulkAdvisorActionDialog = ({
  advisors,
  getAdvisorsByEmail,
  createJsonApiList,
  showErrorMessage,
  showSuccessMessage
}) => {
  const [emailDump, setEmailDump] = React.useState('')
  const [stepName, setStepName] = React.useState(steps[0])
  const [submitting, setSubmitting] = React.useState(false)

  const form = useForm({
    defaultValues: {
      date: null,
      activity: null
    }
  })

  const searchAdvisors = async () => {
    setSubmitting(true)

    try {
      const emails = emailDump.split('\n')
      if (!emailDump || emails.length === 0) return
      const res = await getAdvisorsByEmail(emails)
      if (!res.ok) throw new Error(res.error?.errors?.map((e) => e.detail).join('; '))
      setStepName(steps[1])
    } catch (e) {
      showErrorMessage({
        text: `Something went wrong: ${e.message}`,
        timeout: 2000
      })
    } finally {
      setSubmitting(false)
    }
  }

  const onSubmit = async (data) => {
    const actionDate = moment(data.date).toISOString()
    const operation = data.activity

    setSubmitting(true)
    const actionsToSave = {
      data: advisors.map((adv) => ({
        userId: adv.id,
        action: operation,
        dateOfAction: actionDate,
        type: 'userActions'
      })),
      type: 'userActions'
    }

    try {
      const res = await createJsonApiList(actionsToSave)
      if (!res.ok) throw new Error(res.error.errors.map((e) => e.detail).join('; '))
      showSuccessMessage({
        text: 'Actions noted off platform'
      })
      setEmailDump('')
      setStepName(steps[0])
      form.reset()
    } catch (e) {
      showErrorMessage({
        text: `Something went wrong: ${e.message}`,
        timeout: 2000
      })
    } finally {
      setSubmitting(false)
    }
  }

  const unmatchedEmails = emailDump.split('\n').filter((email) => !advisors.some((advisor) => advisor.email.toLowerCase() === email.toLowerCase()))

  const activityItems = [
    'Account Report',
    'Advisor Forum',
    'Advisor Newsletter',
    'Emissary Day',
    'GTM Session',
    'Industry Report',
    'Internal Event or Content',
    'Podcast',
    'Propensity Survey',
    'Org Buying Survey',
    'Buyer Snapshot Survey',
    'QBR',
    'SKO',
    'Strategic Partnership',
    'Virtual Event',
    'Other',
  ]

  const getStep = () => {
    switch (stepName) {
      case 'UPLOAD_STEP':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Off Platform Activity Recorder</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Dump Advisor Emails (one per line)"
                  variant="outlined"
                  multiline
                  value={emailDump}
                  onChange={(e) => { setEmailDump(e.currentTarget.value) }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                  onClick={searchAdvisors}
                >
                  Parse
                </Button>
              </Grid>
            </Grid>
          </>
        )
      case 'CONFIRM_STEP':
        return (
          <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <List dense>
                  {advisors.map((advisor) => (
                    <ListItem key={advisor.id}>
                      <ListItemText
                        primary={`${advisor.fullName} (${advisor.email})`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Unmatched emails:
                  {' '}
                  {unmatchedEmails.join(', ') || 'None'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormDatePicker
                  form={form}
                  required
                  views={['day', 'month', 'year']}
                  name="date"
                  label="Date"
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  form={form}
                  required
                  name="activity"
                  label="Activity"
                  helperText="Required field"
                  items={activityItems.map((item) => ({
                    key: item,
                    value: item,
                    label: item
                  }))}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  type="submit"
                  onClick={() => { setStepName(steps[0]) }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Paper sx={{ mt: 8, p: '1em' }}>
      { getStep() }
    </Paper>
  )
}

BulkAdvisorActionDialog.propTypes = {
  advisors: PropTypes.array.isRequired,
  getAdvisorsByEmail: PropTypes.func.isRequired,
  createJsonApiList: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired
}

export default BulkAdvisorActionDialog
