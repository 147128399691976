import PropTypes from 'prop-types'
import React from 'react'
import { debounce } from 'lodash'

import {
  TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

const DivisionAutocomplete = ({
  customerId,
  label,
  onChange,
  initDivisions,
  divisions,
  divisionSearch,
  multiple,
  helperText
}) => {
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const [selectedDivisions, setSelectedDivisions] = React.useState(initDivisions)

  React.useEffect(() => {
    setSelectedDivisions(initDivisions)
  }, [initDivisions])

  const debouncedUserSearch = debounce(divisionSearch, 150)
  return (
    <Autocomplete
      multiple={multiple}
      id={`${label}-multiselect`}
      open={autocompleteOpen}
      onOpen={() => setAutocompleteOpen(true)}
      onInputChange={(event, text) => {
        debouncedUserSearch(text, customerId)
      }}
      onChange={(event, newValue) => {
        onChange(event, newValue)
      }}
      onClose={() => setAutocompleteOpen(false)}
      getOptionLabel={(option) => option.displayName}
      isOptionEqualToValue={(option, value) => (option.id === value.id)}
      options={divisions}
      value={multiple ? selectedDivisions : (selectedDivisions[0] || null)}
      filterOptions={(options, state) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
          helperText={helperText}
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

DivisionAutocomplete.defaultProps = {
  customerId: null,
  initDivisions: [],
  multiple: true,
  helperText: ''
}

DivisionAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initDivisions: PropTypes.array,
  customerId: PropTypes.number,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  divisions: PropTypes.array.isRequired,
  divisionSearch: PropTypes.func.isRequired
}

export default DivisionAutocomplete
