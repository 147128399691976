import { connect } from 'react-redux'
import { JsonAPI } from 'store'

import TagEditorLayout from '../layouts/TagEditorLayout'

const mapStateToProps = ({ tags }) => ({
  tags
})

const mapDispatchToProps = {
  getAllTags: (size, page, sort) => JsonAPI.getAll({
    type: 'tags',
    sort,
    queryStringParams: {
      'page[size]': size,
      'page[number]': page
    }
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TagEditorLayout)
