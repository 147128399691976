import React from 'react'
import PropTypes from 'prop-types'

import {
  useEngagement
} from 'hooks'
import {
  formatTimeslotForHumans
} from 'utils-em'

import ActivityItem from '../ActivityItem'

const AcceptSchedulingProposal = ({ activity, engagementId, ...props }) => {
  const { relevantTimeslotProposals } = useEngagement(engagementId)
  const {
    payload
  } = activity

  const timeslotProposalId = JSON.parse(payload).timeslot_proposal_id
  const timeslotProposal = relevantTimeslotProposals?.find((tsp) => parseInt(tsp.id, 10) === timeslotProposalId)
  const timeslot = timeslotProposal?.timeslots?.find((t) => t.isSelected)

  if (!timeslot) return null
  let text = `Call scheduled for ${formatTimeslotForHumans(timeslot)}`
  if (timeslotProposal.status === 'RESCHEDULED') {
    text = `Rescheduled - ${text}`
  } else if (timeslotProposal.status === 'CALL_MISSED') {
    text = `${text} - Marked as missed by ${timeslotProposal.decliningUser.firstName}`
  }

  return (
    <ActivityItem
      title={text}
      {...props}
    />
  )
}

AcceptSchedulingProposal.propTypes = {
  activity: PropTypes.object.isRequired,
  engagementId: PropTypes.number.isRequired
}

export default AcceptSchedulingProposal
