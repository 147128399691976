import PropTypes from 'prop-types'
import React from 'react'
import {
  Box
} from '@mui/material'

import {
  CalendarTimeSelectorContext
} from '../../CalendarTimeSelectorContext'
import {
  TimeCell
} from '../index'

const DayColumn = ({ date }) => {
  const {
    NUMBER_OF_TIMESLOTS
  } = React.useContext(CalendarTimeSelectorContext)

  return (
    <Box sx={{ width: '100%' }}>
      {/* Body: Timeslot cells */}
      {new Array(NUMBER_OF_TIMESLOTS).fill(0).map((_, idx) => (
        <TimeCell
          key={date.clone().add(30 * idx, 'minutes')}
          time={date.clone().add(30 * idx, 'minutes')}
        />
      ))}
    </Box>
  )
}

DayColumn.propTypes = {
  date: PropTypes.object.isRequired
}

export default DayColumn
