import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import {
  Box,
  Typography,
  useTheme
} from '@mui/material'

import {
  CalendarTimeSelectorContext
} from '../../CalendarTimeSelectorContext'

const TIMESLOT_HEIGHT = 30

const TimeCell = ({ time }) => {
  const theme = useTheme()

  // palette colors at 50% opacity, using #rrggbbaa; 50% opacity ~ 127 (out of 255) in hex, hence 0x7F
  const SELECTED_COLOR = `${theme.palette.primary.lighter}7F`
  const HOVER_COLOR = `${theme.palette.primary.lightest}7F`
  const DISABLED_COLOR = `${theme.palette.neutral.offWhite}BF` // 75% opacity

  const {
    selectedTimeslots,
    setSelectedTimeslots,
    MAX_DAYS_IN_FUTURE_ALLOWED
  } = React.useContext(CalendarTimeSelectorContext)

  const isSelected = !!selectedTimeslots.find((timeslotMoment) => timeslotMoment.isSame(time))
  const isDisabled = (
    // do not allow choosing:
    time.isBefore(moment()) || // previous dates
    time.day() === 0 || time.day() === 6 || // Saturday or Sunday
    time.isAfter(moment().add(MAX_DAYS_IN_FUTURE_ALLOWED, 'days')) // dates too far in future
  )

  const selectTimeslot = () => {
    // check against `isSelected` to prevent duplicates being chosen if user drags over a selected cell
    if (!isSelected && !isDisabled) {
      // callback form of setState is used here because mouse enter and mouse leave
      // events will fire at nearly the same time when user is dragging, so the mouse
      // leave set state would be overridden and miss selecting the first time slot
      setSelectedTimeslots((prevValue) => [...prevValue, time])
    }
  }

  const deselectTimeslot = () => {
    setSelectedTimeslots(selectedTimeslots.filter((timeslot) => !timeslot.isSame(time)))
  }

  const getBackgroundColor = () => {
    if (isDisabled) return DISABLED_COLOR
    if (isSelected) return SELECTED_COLOR
    return 'inherit'
  }

  const getHoverColor = () => {
    if (isDisabled) return DISABLED_COLOR
    if (isSelected) return SELECTED_COLOR
    return HOVER_COLOR
  }

  return (
    <Box
      // `onClick` handles both mouse clicks and mobile taps
      onClick={() => (isSelected ? deselectTimeslot() : selectTimeslot())}
      // mouse dragging occurs if enter/leave with left-button pressed
      onMouseEnter={(e) => e.buttons === 1 && selectTimeslot()}
      onMouseLeave={(e) => e.buttons === 1 && selectTimeslot()}
      sx={{
        height: `${TIMESLOT_HEIGHT}px`,
        display: 'flex',
        alignItems: 'center',
        borderTop: `1px SOLID ${theme.palette.neutral.lightGrey}`,
        borderRight: time.day() === 5 ? 'none' : `1px SOLID ${theme.palette.neutral.lightGrey}`,
        '&:nth-child(2n)': { borderTopStyle: 'dashed' },
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
        backgroundColor: getBackgroundColor(),
        '&:hover': {
          backgroundColor: getHoverColor()
        }
      }}
    >
      {isSelected && (
        <Typography
          variant="tiny"
          color="primary.light"
          sx={{
            // custom font styling not in theme for this specific case
            fontSize: '10px',
            fontWeight: '600',
            lineHeight: '12px',
            letterSpacing: '0.4px',
            pl: 0.5,
          }}
        >
          {`${time.format('h:mm a')} - ${time.clone().add(30, 'minutes').format('h:mm a')}`}
        </Typography>
      )}
    </Box>
  )
}

TimeCell.propTypes = {
  time: PropTypes.object.isRequired
}

export default TimeCell
