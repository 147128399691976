import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import React from 'react'
import moment from 'moment'
import {
  Clock
} from 'icons'
import {
  buildJsonApiOne,
  getEngagementMostRelevantTimeslot,
  formatDate
} from 'utils-em'
import IconTextCombo from '../IconTextCombo/IconTextCombo'

const EngagementEndChip = ({ engagementId }) => {
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const isAdvisor = useSelector(({ session }) => session.userType === 'advisor')

  const getLabel = () => {
    const format = (date) => formatDate(date, { includeYear: false })
    const timeslot = engagement && getEngagementMostRelevantTimeslot(engagement)
    const { state, dateUpdated, dateClosed, dateCanceled, dateAdvisorIndicatedAvailable, dateExpired, dateRejected } = engagement && engagement
    // In the case of multi-call engagements, there may be no
    // proposed timeslots. If so, display a generic message.
    let label = 'Ends 30 days after call'
    if (dateClosed) {
    // engagement manually closed by admin
      label = `Ended ${format(dateClosed)}`
    } else if (dateCanceled) {
    // engagement manually cancelled by admin
      label = `Canceled ${format(dateCanceled)}`
    } else if (state === 'expired') {
      label = 'Advisor unavailable'
      if (isAdvisor) label = 'Expired'
      if (!dateAdvisorIndicatedAvailable) label += ` ${format(dateExpired || dateUpdated)}`
    } else if (state === 'rejected') {
      label = 'Advisor unavailable'
      if (isAdvisor) label = 'Declined'
      if (!dateAdvisorIndicatedAvailable) label += ` ${format(dateRejected || dateUpdated)}`
    } else if (timeslot) {
    // engagements end 30 days after the call
      const thirtyDaysAfterCall = moment(timeslot.endTime).add(30, 'days')
      const isEnded = Date.parse(thirtyDaysAfterCall) < Date.parse(new Date())
      label = `${isEnded ? 'Ended' : 'Ends'} ${format(thirtyDaysAfterCall)}`
    }
    return label
  }

  return (
    <IconTextCombo
      icon={<Clock />}
      text={getLabel()}
      typography="body1"
      typographyColor="neutral.black"
      iconSize="medium"
      iconColor="neutral.darkGrey"
      isLink={false}
    />
  )
}

EngagementEndChip.propTypes = {
  engagementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default EngagementEndChip
