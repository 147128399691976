import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Calendar } from 'icons'
import {
  Box,
  Typography
} from '@mui/material'
import { formatDate } from 'utils-em'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const TableDateRangeFilter = ({ onChange, currentState }) => {
  const currentDateValues = currentState?.length && currentState[0]
  const [chosenDates, setChosenDates] = React.useState({
    startDate: currentDateValues?.startDate || '',
    endDate: currentDateValues?.endDate || ''
  })

  const returnSelectedFilters = (selectedDates) => {
    const selected = []
    const dateOptions = { includeYear: true }
    const setDates = `${selectedDates?.startDate ? formatDate(selectedDates.startDate, dateOptions) : ''} - ${selectedDates?.endDate ? formatDate(selectedDates.endDate, dateOptions) : ''}`
    if (selectedDates?.startDate || selectedDates?.endDate) {
      selected.push({
        value: 'dateRanges',
        name: setDates,
        startDate: selectedDates?.startDate,
        endDate: selectedDates?.endDate
      })
    }
    return selected
  }

  const customDateSelector = ({ value, minDate, maxDate, onChangeSelect }) => {
    const [openCalendar, setOpenCalendar] = React.useState(false)
    const onKeyDown = React.useCallback((e, field) => {
      e.target.blur()
      e.preventDefault()
      if (e.key === 'Backspace') {
        onChangeSelect(null)
      }
    })
    return (
      <DatePicker
        views={['year', 'month', 'day']}
        value={value ? moment(value) : null}
        open={openCalendar}
        onClose={() => setOpenCalendar(false)}
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        onChange={(timestamp) => onChangeSelect(timestamp)}
        sx={{ pr: 1, py: 2, flexGrow: 1 }}
        slotProps={{
          openPickerIcon: Calendar,
          textField: {
            variant: 'outlined',
            InputProps: {
              placeholder: 'Date'
            },
            onClick: (e) => {
              e.target.blur()
              setOpenCalendar(true)
            },
            onKeyDown,
          }
        }}
      />
    )
  }
  return (
    <>
      <Typography variant="captionBold">
        Date range
      </Typography>
      <Box sx={{ display: 'flex' }}>
        {customDateSelector({
          value: chosenDates.startDate || null,
          minDate: undefined,
          maxDate: chosenDates.endDate || undefined,
          onChangeSelect: (selectedDate) => {
            const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
            const newSelectedDates = { ...chosenDates, startDate: formattedDate }
            setChosenDates(newSelectedDates)
            onChange('dateRanges', newSelectedDates, returnSelectedFilters(newSelectedDates))
          }
        })}
        <Box sx={{ p: 2, display: 'flex' }}>
          <Typography sx={{ alignSelf: 'center' }} variant="body1">
            to
          </Typography>
        </Box>
        {customDateSelector({
          value: chosenDates.endDate || null,
          minDate: chosenDates.startDate || undefined,
          maxDate: undefined,
          onChangeSelect: (selectedDate) => {
            const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
            const newSelectedDates = { ...chosenDates, endDate: formattedDate }
            setChosenDates(newSelectedDates)
            onChange('dateRanges', newSelectedDates, returnSelectedFilters(newSelectedDates))
          }
        })}
      </Box>
    </>
  )
}

TableDateRangeFilter.defaultProps = {
  currentState: null
}

TableDateRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.array
}

export default TableDateRangeFilter
