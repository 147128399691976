/* eslint-disable no-param-reassign */
import React from 'react'
import { createDivisionHierarchy, customAlert, deserializeJsonApi } from 'utils-em'

export default function useDivisionHierarchy (options = {}) {
  const { includeArchivedTeams, showErrorAlert, dependencies } = {
    includeArchivedTeams: false,
    showErrorAlert: true,
    dependencies: [],
    ...options
  }
  const [loaded, setLoaded] = React.useState(false)
  const [divisionLists, setDivisionLists] = React.useState({
    topLevelDivisions: [],
    flattenedDivisions: [],
    flattenedDivisionIds: []
  })

  React.useEffect(() => { loadDivisionHierarchy() }, [...dependencies])

  const loadDivisionHierarchy = async () => {
    try {
      // hierarchically gets divisions (in tree or flattened format)
      // if excluding archived, this skips past children of archived parents
      const results = await fetch(`${__API_HOST__}/v1/my-divisions`, { credentials: 'include', method: 'GET' })
      const body = await results.json()
      const parsedDivisions = deserializeJsonApi(body)
      const hierarchyData = createDivisionHierarchy(parsedDivisions, options = { includeArchivedTeams })
      setDivisionLists(hierarchyData)
    } catch (error) {
      showErrorAlert && customAlert('Something went wrong, please contact support', true)
    }
    setLoaded(true)
  }

  return { ...divisionLists, loaded }
}
