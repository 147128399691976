import countryData from '../components/MuiPhoneNumber/country_data'

export const convertCountryCode = (countryCode) => {
  const foundCountryData = countryData.rawAllCountries.find((c) => c[2] === countryCode)
  if (foundCountryData) {
    return foundCountryData[0]
  }
  return countryCode
}

export default convertCountryCode
