import * as ACTION_TYPE from './constants'

export function show (alertData, level) {
  return {
    type: ACTION_TYPE.SHOW,
    alert: alertData,
    level
  }
}

export function hideAll () {
  return {
    type: ACTION_TYPE.HIDE_ALL
  }
}
