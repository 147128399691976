import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Prompt } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material'
import { useJsonAPIGetOne } from 'hooks'
import { FormTextField } from 'components'
import { JsonAPI } from 'store'
import {
  formatDate,
  httpResponseAlert
} from 'utils-em'

const AdminSpace = ({ editingCallback, advisorId }) => {
  const { object: advisor, reload } = useJsonAPIGetOne(advisorId, 'advisors')
  const dispatch = useDispatch()
  const form = useForm()
  const defaultValues = {
    adminNotes: advisor.adminNotes || ''
  }
  const [hasEdited, setHasEdited] = React.useState(false)

  const submitForm = (data) => {
    dispatch(JsonAPI.save({
      ...advisor,
      ...data
    })).then((resp) => {
      httpResponseAlert(resp)
      if (resp.ok) {
        setHasEdited(false)
        editingCallback(false)
        reload()
      }
    })
  }

  React.useEffect(() => {
    form.reset(defaultValues)
  }, [])

  return (
    <Box component="form" onSubmit={form.handleSubmit(submitForm)}>
      {hasEdited && <Prompt message="Are you sure you would like to leave this page? You will lose any changes you made." />}
      <Typography variant="h2" sx={{ mb: 3 }}>Admin notes</Typography>
      <Divider sx={{ mb: 3 }} />
      <FormTextField
        form={form}
        name="adminNotes"
        label="Admin notes"
        onChange={() => setHasEdited(true)}
        fullWidth
        multiline
        rows={4}
        sx={{ mb: 3 }}
      />
      {!advisor.isActive && (
        <Typography variant="body1" sx={{ mb: 3, display: 'block' }}>
          Advisor deactivated on
          {' '}
          {advisor.dateDeactivated ? formatDate(advisor.dateDeactivated, { includeYear: true }) : null}
        </Typography>
      )}
      <Button
        variant="text"
        color={advisor.isActive ? 'error' : 'primary'}
        sx={{ mb: 3, ml: 0, display: 'block' }}
        onClick={() => submitForm({ isActive: !advisor.isActive })}
      >
        {advisor.isActive ? 'Deactivate advisor' : 'Re-activate advisor'}
      </Button>
      <Button type="submit" variant="contained" color="primary">Save</Button>
    </Box>
  )
}

AdminSpace.propTypes = {
  editingCallback: PropTypes.func.isRequired,
  advisorId: PropTypes.number.isRequired
}

export default AdminSpace
