import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material'

const UserSelector = ({ selectableUsers, initialUserIds, userCallback }) => {
  const [selectedUsers, setSelectedUsers] = React.useState(
    selectableUsers.filter((u) => initialUserIds.includes(parseInt(u.id, 10)))
  )

  const getStatusLabel = (user) => {
    if (user.status === 'idle') {
      return <Typography color="neutral.disabled"> Idle</Typography>
    }
    if (user.status === 'active') {
      return ''
    }
    return <Typography color="red"> Not onboarded</Typography>
  }

  React.useEffect(() => {
    userCallback(selectedUsers)
  }, [selectedUsers])

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      options={selectableUsers}
      getOptionLabel={(user) => user.fullName}
      value={selectedUsers}
      onChange={(e, newval) => setSelectedUsers(newval)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Team members"
          placeholder="Add team member..."
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1">
              {option.fullName}
              {option.status !== 'active' ? ' - ' : ''}
            </Typography>
            &nbsp;
            {getStatusLabel(option)}
          </Box>
        </li>
      )}
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <Chip
          label={(
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1">
                {option.fullName}
                {option.status !== 'active' ? ' - ' : ''}
              </Typography>
              &nbsp;
              {getStatusLabel(option)}
            </Box>
      )}
          {...getTagProps({ index })}
          sx={{ mr: 1, mb: 1 }}
        />
      ))}
    />
  )
}

UserSelector.propTypes = {
  selectableUsers: PropTypes.array.isRequired,
  initialUserIds: PropTypes.array.isRequired,
  userCallback: PropTypes.func.isRequired,
}

export default UserSelector
