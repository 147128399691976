/* eslint-disable no-param-reassign */
/* eslint-disable react/no-did-update-set-state */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import Autocomplete from '@mui/material/Autocomplete'
import {
  Checkbox,
  InputAdornment,
  TextField
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { MagnifyingGlass } from 'icons'

const API_HOST = __API_HOST__

const OrganizationSelector = ({
  organizationId,
  organizationName,
  preselectedOrgIds,
  currentOrgs: initialCurrentOrgs,
  updateMatches,
  handleChange,
  handleUpdating,
  showErrorMessage,
  loadOrgJsonApi,
  showSearchIcon,
  label,
  placeholder,
  helperText,
  error,
  noOptionsText,
  required,
  multipleSelect,
  disabled,
  textFieldVariant,
  handleTextChange,
  bgColor,
  dependencies,
}) => {
  const [currentOrgs, setCurrentOrgs] = useState(initialCurrentOrgs || [])
  const [searchText, setSearchText] = useState(organizationName || '')
  const [selectedOrgs, setSelectedOrgs] = useState(null)
  const [updating, setUpdating] = useState(false)

  const searchAndUpdate = () => {
    handleUpdating(true)
    setUpdating(true)
    fetch(`${API_HOST}/v1/search/orgSearch?q=${encodeURIComponent(searchText)}`, {
      credentials: 'include',
      method: 'GET'
    }).then((res) => {
      if (!res.ok) {
        showErrorMessage({ text: 'Error contacting search', timeout: 1000 })
      } else {
        res.json().then((body) => {
          setCurrentOrgs(body)
          if (updateMatches) {
            updateMatches(body)
          }
          handleUpdating(false)
          setUpdating(false)
        })
      }
    })
  }

  const debouncedOrgSearch = debounce(searchAndUpdate, 500)

  useEffect(() => {
    searchAndUpdate()
  }, [...dependencies])

  useEffect(() => {
    if (organizationName) {
      searchAndUpdate()
    } else if (organizationId) {
      loadOrgJsonApi(organizationId).then((res) => {
        const { name } = res.data?.data?.attributes
        changeSearchText(name)
        setSearchText(name)
      })
    }
  }, [organizationId, organizationName])

  useEffect(() => {
    setSearchText(organizationName)
  }, [organizationName])

  const changeOrg = (org) => {
    setSearchText(org?.name || '')
    setSelectedOrgs(org)
    handleChange(org, currentOrgs)
  }

  const changeSearchText = (newSearchText) => {
    setSearchText(newSearchText)
    debouncedOrgSearch()
    handleTextChange(newSearchText, currentOrgs)
  }

  return (
    <Autocomplete
      autoHighlight
      onChange={(event, org) => changeOrg(org)}
      getOptionLabel={(option) => option.name || option}
      isOptionEqualToValue={(option, value) => value === option.name}
      options={currentOrgs}
      filterOptions={(options) => options}
      defaultValue={{ name: organizationName }}
      value={searchText}
      noOptionsText={noOptionsText}
      disabled={disabled}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          {multipleSelect && (
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={(selectedOrgs && selectedOrgs.id === option.id) || preselectedOrgIds.includes(option.id)}
            />
          )}
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={textFieldVariant}
          label={label}
          placeholder={placeholder}
          margin="normal"
          disabled={updating || disabled}
          onChange={(event) => changeSearchText(event.currentTarget.value)}
          fullWidth
          error={error}
          helperText={helperText}
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: showSearchIcon ? (
              <InputAdornment position="start">
                <MagnifyingGlass />
              </InputAdornment>
            ) : null,
          }}
          sx={{ mt: 0, backgroundColor: bgColor }}
          value={organizationName}
        />
      )}
    />
  )
}

OrganizationSelector.defaultProps = {
  organizationId: null,
  organizationName: null,
  preselectedOrgIds: [],
  currentOrgs: [],
  updateMatches: null,
  handleUpdating: () => { },
  showSearchIcon: false,
  label: 'Organization',
  placeholder: 'Organization',
  noOptionsText: 'enter organization name',
  helperText: '',
  error: false,
  required: false,
  multipleSelect: true,
  disabled: false,
  textFieldVariant: 'standard',
  handleTextChange: () => { },
  bgColor: null,
  dependencies: [],
}

OrganizationSelector.propTypes = {
  organizationId: PropTypes.number,
  organizationName: PropTypes.string,
  preselectedOrgIds: PropTypes.arrayOf(PropTypes.number),
  currentOrgs: PropTypes.array,
  updateMatches: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleUpdating: PropTypes.func,
  showErrorMessage: PropTypes.func.isRequired,
  loadOrgJsonApi: PropTypes.func.isRequired,
  showSearchIcon: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  noOptionsText: PropTypes.string,
  required: PropTypes.bool,
  multipleSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  textFieldVariant: PropTypes.string,
  handleTextChange: PropTypes.func,
  bgColor: PropTypes.string,
  dependencies: PropTypes.array,
}

export default OrganizationSelector
