import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Link,
  Typography
} from '@mui/material'

import {
  AnnotationDots,
} from 'icons'

import {
  confirmDialog,
  httpResponseAlert,
  navigateTo
} from 'utils-em'

import {
  useEngagement,
  useSessionUser,
  useJsonAPIUpsert
} from 'hooks'
import GreyBox from './GreyBox'

const FeedbackRequiredBox = ({ engagementId }) => {
  const { upsert } = useJsonAPIUpsert()
  const { isCustomerUser } = useSessionUser()
  const { relevantProposal } = useEngagement(engagementId)

  // eslint-disable-next-line react/prop-types
  const IconText = ({ Icon, text }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon sx={{ color: 'neutral.darkGrey', mr: 1 }} />
      <Typography variant="bodyBold">{text}</Typography>
    </Box>
  )

  const markCallMissed = () => {
    upsert({
      type: 'timeslotProposals',
      id: relevantProposal.id,
      markMissed: true,
      riders: ['markMissed'],
      queryStringParams: { 'lazy[engagements]': 'step', include: 'engagement,engagement.relevantTimeslotProposals' }
    }).then((resp) => {
      httpResponseAlert(resp, {
        success: 'Call marked as missed'
      })
    })
  }

  const openMissedCallDialog = (_) => {
    confirmDialog({
      title: 'Mark call as missed',
      description: 'Sorry you weren’t able to connect. Let’s try again.',
      actions: [
        {
          name: 'Reschedule Call',
          action: markCallMissed
        }
      ]
    })
  }

  return (
    <>
      <Typography variant="h3">How did it go?</Typography>
      <GreyBox sx={{ flexDirection: 'row' }}>
        <IconText Icon={AnnotationDots} text="Take two minutes to submit feedback about your call:" />
        <Box>
          <Button
            onClick={() => navigateTo(`/${isCustomerUser ? 'c' : 'a'}/engagements/${engagementId}/post-call-feedback`)}
            variant="contained"
          >
            Start feedback form
          </Button>
        </Box>
        <Box>
          <Link variant="buttonL" onClick={openMissedCallDialog}>
            The call didn't happen
          </Link>
        </Box>
      </GreyBox>
    </>
  )
}

FeedbackRequiredBox.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default FeedbackRequiredBox
