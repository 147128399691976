import { customAlert } from 'utils-em'

export async function copyToClipboard (textToClip, successCb, errorCb) {
  const permissionStatus = await navigator.permissions.query({ name: 'clipboard-write' })
  if (permissionStatus.state !== 'granted') {
    if (errorCb) {
      errorCb({ text: 'Failed to copy to clipboard', timeout: 1000 })
    }
    return
  }
  navigator.clipboard.writeText(textToClip)

  if (successCb) {
    successCb({ text: 'Copied to clipboard!', timeout: 1000 })
  }
}

export function copyToClipboardWithAlert (textToClip, successMessage = 'Copied to clipboard!') {
  copyToClipboard(textToClip, () => customAlert(successMessage))
}
