import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'

import AccountCircle from '@mui/icons-material/AccountCircle'
import AddCircle from '@mui/icons-material/AddCircle'
import Assignment from '@mui/icons-material/Assignment'
import Edit from '@mui/icons-material/Edit'
import Group from '@mui/icons-material/Group'
import HeadsetMic from '@mui/icons-material/HeadsetMic'
import SupervisedUserCircle from '@mui/icons-material/SupervisedUserCircle'
import Stars from '@mui/icons-material/Stars'
import Launch from '@mui/icons-material/Launch'
import HowToReg from '@mui/icons-material/HowToReg'
import Phone from '@mui/icons-material/Phone'
import Mail from '@mui/icons-material/Mail'
import Lightbulb from '@mui/icons-material/Lightbulb'
import { Link } from 'icons'
import { constants, stealth, useDebounce, copyToClipboard, customAlert } from 'utils-em'
import { StatusCircle, UserInviteDialog } from 'components'
import AdvisorProfileDialog from '../AdvisorProfileDialog'
import AdvisorEngagementHistoryDialog from '../AdvisorEngagementHistoryDialog'
import UserDetailDialog from '../UserDetailDialog'
import NinjaIcon from '../CustomIcons/NinjaIcon'
import AdvisorPrefillDialog from '../AdvisorPrefillDialog/AdvisorPrefillDialog'
import PhoneOnboardingDialog from './components/PhoneOnboardDialog'
import FinalExpertiseRatingsDialog from './components/FinalExpertiseRatingsDialog'

const tableHeadings = {
  customerUser: [
    { id: 'fullName', label: 'Name' },
    { id: 'customer.name', label: 'Customer' },
    { id: 'jobTitle', label: 'Title' },
    { id: 'divisions', label: 'Teams' },
    { id: 'roleType', label: 'Role' },
    { id: 'status', label: 'Status' },
    { id: 'stealthMode', label: 'Actions' }
  ],
  admin: [
    { id: 'fullName', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'roleType', label: 'Role' }
  ],
  advisor: [
    { id: 'fullName', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'recruiter', label: 'Recruiter' },
    { id: 'advisorOnboardingStatus', label: 'Onboarded' },
    { id: 'stealthMode', label: 'Actions' }
  ]
}

const cuFields = [
  { prop: 'customer.name', label: 'Customer' },
  { prop: 'jobTitle', label: 'Title' },
  {
    prop: 'divisions',
    label: 'Teams',
    transform: (divs) => {
      if (!divs) { return null }
      return divs.map((d) => (
        <Chip
          key={`table-team-${d.id}`}
          variant="outlined"
          style={{ margin: '0.25rem' }}
          size="small"
          label={d.name}
        />
      ))
    }
  },
  {
    prop: 'roleType',
    label: 'Role',
    transform: (role) => {
      let roleIcon
      let roleLabel

      switch (role) {
        case 'rep':
          roleLabel = 'Rep'
          roleIcon = <AccountCircle />
          break
        case 'manager':
          roleLabel = 'Manager'
          roleIcon = <SupervisedUserCircle />
          break
        case 'program_owner':
          roleLabel = 'Program Owner'
          roleIcon = <Stars />
          break
        default:
          roleIcon = <Stars />
          break
      }

      return (
        <Chip
          size="small"
          icon={roleIcon}
          label={roleLabel}
        />
      )
    }
  }
]

const adminUserFields = [
  { prop: 'email', label: 'Email' },
  {
    prop: 'roleType',
    label: 'Role',
    transform: (role) => {
      let roleIcon
      let roleLabel
      switch (role) {
        case 'admin':
          roleLabel = 'Admin'
          roleIcon = <Stars />
          break
        case 'operator':
          roleLabel = 'Operator'
          roleIcon = <Phone />
          break
        default:
          roleLabel = 'Recruiter'
          roleIcon = <HowToReg />
          break
      }

      return (
        <Chip
          size="small"
          icon={roleIcon}
          label={roleLabel}
        />
      )
    }
  }
]

const advisorUserFields = [
  { prop: 'email', label: 'Email' },
  { prop: 'recruiter.fullName', label: 'Recruiter' }
]

const fields = {
  customerUser: cuFields,
  admin: adminUserFields,
  advisor: advisorUserFields
}

const TableToolbar = ({ addUsers, userType, title, numSelected, openDetailDialog, showInactiveUsers, setShowInactiveUsers }) => (
  <Toolbar>
    {numSelected > 0 ? (
      <Typography style={{ flex: '1 1 100%' }} variant="h6" component="div">
        {title}
        <span style={{ color: '#bbb' }}>
          {' '}
          (
          {numSelected}
          {' '}
          selected)
        </span>
      </Typography>
    ) : (
      <Typography style={{ flex: '1 1 100%' }} variant="h6" component="div">
        {title}
      </Typography>
    )}
    {addUsers ? (
      <>
        <Button
          disabled={numSelected > 0}
          color="primary"
          variant="contained"
          onClick={openDetailDialog}
          startIcon={<AddCircle />}
        >
          New
        </Button>
        &nbsp;&nbsp;
      </>
    ) : null}
    <Button
      disabled={numSelected === 0}
      color="primary"
      variant="contained"
      onClick={openDetailDialog}
      startIcon={<Launch />}
    >
      Details
    </Button>
    <div style={{ marginLeft: '1em' }}>
      <FormControlLabel
        control={(
          <Switch
            checked={showInactiveUsers}
            onChange={() => setShowInactiveUsers(!showInactiveUsers)}
            name="isActive"
          />
        )}
        label="Show deactivated"
      />
    </div>
  </Toolbar>
)

TableToolbar.defaultProps = {
  addUsers: false,
  numSelected: 0
}

TableToolbar.propTypes = {
  addUsers: PropTypes.bool,
  userType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  openDetailDialog: PropTypes.func.isRequired,
  setShowInactiveUsers: PropTypes.func.isRequired,
  showInactiveUsers: PropTypes.bool.isRequired,
  numSelected: PropTypes.number
}

const UserTable = ({
  addUsers,
  compact,
  userType,
  customerUsers,
  customerUsersCount,
  getCustomerUsers,
  adminUsers,
  adminUsersCount,
  getAdminUsers,
  getAdvisorUsers,
  advisorUsersCount,
  advisorUsers,
  openDialog,
  searchFilters,
  navigateTo
}) => {
  const [selected, setSelected] = useState([])
  const [showInactiveUsers, setShowInactiveUsers] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.DEFAULT_ROWS_PER_PAGE)
  const [sortDir, setSortDirection] = useState('asc')
  const [sortCol, setSortColumn] = useState('fullName')
  const [userList, setUserList] = useState([])
  const [userCount, setUserCount] = useState(0)

  const hasOnboardingCompanies = (user) => user?.companies?.filter((c) => !c.deleted).length > 0

  const makeRequest = () => {
    switch (userType) {
      case 'admin':
        getAdminUsers(
          rowsPerPage,
          page + 1,
          `${sortDir === 'desc' ? '-' : ''}${sortCol}`,
          showInactiveUsers ? searchFilters : searchFilters.concat([{ name: 'isActive', op: 'is', val: true }])
        )
        break
      case 'advisor':
        getAdvisorUsers(
          rowsPerPage,
          page + 1,
          `${sortDir === 'desc' ? '-' : ''}${sortCol}`,
          showInactiveUsers ? searchFilters : searchFilters.concat([{ name: 'isActive', op: 'is', val: true }])
        )
        break
      default:
        getCustomerUsers(
          rowsPerPage,
          page + 1,
          `${sortDir === 'desc' ? '-' : ''}${sortCol}`,
          showInactiveUsers ? searchFilters : searchFilters.concat([{ name: 'isActive', op: 'is', val: true }])
        )
    }
  }

  const isSelected = (userEmail) => selected.map((u) => u.email).indexOf(userEmail) !== -1

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const rowsSelected = customerUsers
      return setSelected(rowsSelected)
    }
    return setSelected([])
  }

  const handleClick = (event, user) => {
    const selectedIdx = selected.map((u) => u.email).indexOf(user.email)
    let newSelected = []

    if (selectedIdx === -1) {
      newSelected = newSelected.concat(selected, user)
    } else if (selectedIdx === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIdx === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIdx > 0) {
      selected.slice(0, selectedIdx)
      selected.slice(selectedIdx + 1)
    }

    setSelected(newSelected)
  }

  const sortOnColumn = (columnName) => {
    setSortDirection(sortCol !== columnName || sortDir === 'asc' ? 'desc' : 'asc')
    setSortColumn(columnName)
    makeRequest()
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const displayStatusLabel = (user) => {
    if (userType === 'customerUser') {
      return <StatusCircle status={user.status} />
    }

    return <StatusCircle status={user.status} />
  }

  const styleInactiveUserCell = (user, tableCellText) => {
    if (!user.isActive) {
      return (
        <span style={{ color: user.isActive ? 'inherit' : '#aaa' }}>
          <del>{tableCellText}</del>
        </span>
      )
    }
    return <span>{tableCellText}</span>
  }

  const debouncedFilters = useDebounce(searchFilters, 500)
  useEffect(
    () => { makeRequest() },
    [debouncedFilters, page, rowsPerPage, showInactiveUsers, userType]
  )

  const getInviteLink = (userId) => {
    fetch(
      `${__API_HOST__}/v1/users/${userId}/invite-link`,
      { credentials: 'include', method: 'POST' }
    ).then((res) => {
      res.json().then((body) => {
        copyToClipboard(
          body.tokenLink,
          () => { customAlert('Invite Link copied to clipboard.') }
        )
      })
    })
  }

  useEffect(() => {
    switch (userType) {
      case 'admin':
        setUserList(adminUsers)
        setUserCount(adminUsersCount)
        break
      case 'advisor':
        setUserList(advisorUsers)
        setUserCount(advisorUsersCount)
        break
      default:
        setUserList(customerUsers)
        setUserCount(customerUsersCount)
    }
  }, [adminUsers, customerUsers, userType])

  // reset page when user switches between types or updates a search filter
  useEffect(() => {
    setPage(0)
  }, [userType, searchFilters])

  // clear users on type switch
  useEffect(() => {
    setSelected([])
  }, [userType])

  return (
    <>
      <Paper>
        <TableToolbar
          addUsers={addUsers}
          userType={userType}
          title="People"
          numSelected={selected.length}
          openDetailDialog={
            () => openDialog(
              <UserDetailDialog userType={userType} users={selected} handleUsersUpdated={() => setSelected([])} refreshUserTable={() => makeRequest()} />
            )
          }
          showInactiveUsers={showInactiveUsers}
          setShowInactiveUsers={setShowInactiveUsers}
        />
        <TableContainer sx={{ height: '60vh' }}>
          <Table stickyHeader size={compact ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: '1px solid #ccc' }} padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < customerUsers.length}
                    checked={selected.length > 0 && selected.length === customerUsers.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {tableHeadings[userType].map((cell) => (
                  <TableCell
                    sx={{ border: '1px solid #ccc' }}
                    key={cell.id}
                    padding={compact ? 'none' : 'normal'}
                    style={{ fontWeight: 'bold' }}
                  >
                    {['fullName', 'jobTitle'].includes(cell.id) ? (
                      <TableSortLabel
                        active={sortCol === cell.id}
                        direction={sortDir}
                        onClick={() => sortOnColumn(cell.id)}
                      >
                        <span style={{ color: 'gray' }}>&bull;</span>
                        &nbsp;
                        {cell.label}
                      </TableSortLabel>
                    ) : cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((user, idx) => (
                <TableRow
                  hover
                  role="checkbox"
                  sx={{ cursor: 'pointer' }}
                  aria-checked={isSelected(user.email)}
                  key={user.email}
                  selected={isSelected(user.email)}
                  onClick={(event) => handleClick(event, user)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected(user.email)} />
                  </TableCell>
                  <TableCell component="th" scope="user">
                    <span style={{ color: user.isActive ? 'inherit' : '#aaa' }}>
                      {styleInactiveUserCell(user, `${user.firstName} ${user.lastName}`)}
                    </span>
                  </TableCell>
                  {Object.keys(fields[userType]).map((field) => {
                    const propName = fields[userType][field].prop
                    const transformFunc = fields[userType][field].transform

                    return (
                      <TableCell key={`${user.email}-${propName}`}>
                        {styleInactiveUserCell(user, transformFunc ? transformFunc(get(user, propName)) : get(user, propName))}
                      </TableCell>
                    )
                  })}
                  {userType !== 'admin' ? (
                    <>
                      <TableCell align="center" component="th" scope="user">
                        {displayStatusLabel(user)}
                      </TableCell>
                      <TableCell align="center" component="th" scope="user">
                        {userType === 'advisor' ? (
                          <>
                            {(!user.linkedinUrl && !user.location && user.status === 'not_invited') ? (
                              <Tooltip title="Continue prefill">
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    openDialog(
                                      <AdvisorPrefillDialog
                                        advisorId={user.id}
                                        refreshUserTable={() => makeRequest()}
                                      />
                                    )
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Edit Advisor Info">
                                <IconButton
                                  target="_blank"
                                  href={`/admin/advisors/${user.id}#personal-info`}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                            {user.onboardingCompleted ? (
                              <>
                                <Tooltip title="Engagement History">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      openDialog(
                                        <AdvisorEngagementHistoryDialog
                                          advisorId={parseInt(user.id, 10)}
                                        />
                                      )
                                    }}
                                  >
                                    <Group />
                                  </IconButton>
                                </Tooltip>
                                {user?.finalExpertiseRatings?.length > 0 && (
                                  <Tooltip title="Area of expertise ratings">
                                    <IconButton
                                      onClick={() => openDialog(<FinalExpertiseRatingsDialog advisorId={user.id} />)}
                                    >
                                      <Lightbulb />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Open Profile">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      openDialog(
                                        <AdvisorProfileDialog
                                          advisorId={parseInt(user.id, 10)}
                                        />
                                      )
                                    }}
                                  >
                                    <Assignment />
                                  </IconButton>
                                </Tooltip>

                              </>
                            ) : (
                              <>
                                {(user.linkedinUrl && user.location) && (
                                  <>
                                    <Tooltip title="Copy Invite Link">
                                      <IconButton
                                        onClick={(e) => getInviteLink(user.id)}
                                      >
                                        <Link />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Send invite email">
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          openDialog(
                                            <UserInviteDialog
                                              userType="advisors"
                                              userId={user.id}
                                            />
                                          )
                                        }}
                                      >
                                        <Mail />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                                {
                                  !user.phoneOnboardingCompletedDate && hasOnboardingCompanies(user) && (
                                    <Tooltip title="Onboard over phone">
                                      <IconButton
                                        onClick={(e) => {
                                          openDialog(
                                            <PhoneOnboardingDialog advisorId={user.id} refreshUserTable={() => makeRequest()} />
                                          )
                                        }}
                                      >
                                        <HeadsetMic />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                }

                              </>
                            )}
                          </>
                        ) : null}
                        <Tooltip title="Stealth">
                          <IconButton onClick={() => stealth(user.id, (returnPage) => { window.location.assign(returnPage) })}>
                            <NinjaIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </>
                  ) : (null)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ border: '1px solid #ccc' }}
          rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={userCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

UserTable.defaultProps = {
  addUsers: false,
  userType: 'customer',
  compact: false
}

UserTable.propTypes = {
  addUsers: PropTypes.bool,
  compact: PropTypes.bool,
  userType: PropTypes.string,
  customerUsers: PropTypes.array.isRequired,
  adminUsers: PropTypes.array.isRequired,
  customerUsersCount: PropTypes.number.isRequired,
  adminUsersCount: PropTypes.number.isRequired,
  getCustomerUsers: PropTypes.func.isRequired,
  getAdminUsers: PropTypes.func.isRequired,
  getAdvisorUsers: PropTypes.func.isRequired,
  advisorUsersCount: PropTypes.number.isRequired,
  advisorUsers: PropTypes.array.isRequired,
  openDialog: PropTypes.func.isRequired,
  searchFilters: PropTypes.array.isRequired,
  navigateTo: PropTypes.func.isRequired
}

export default UserTable
