import React from 'react'
import PropTypes from 'prop-types'
import AccountPage from './AccountPage'

import { AccountPageContextProvider } from './components/AccountPageContext'

export default function AccountPageContainer ({ ...props }) {
  return (
    <AccountPageContextProvider accountPageId={props.accountPageId}>
      <AccountPage {...props} />
    </AccountPageContextProvider>
  )
}

AccountPageContainer.propTypes = {
  accountPageId: PropTypes.number.isRequired,
}
