import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { JsonAPI } from 'store'
import { ConfirmDialog } from 'components'
import {
  buildJsonApiOne,
  closeDialog,
  httpResponseAlert
} from 'utils-em'

const InviteEmailDialog = ({ customerUserId, onInviteSent }) => {
  const dispatch = useDispatch()
  const customerUser = useSelector(({ data }) => buildJsonApiOne(data, 'customerUsers', customerUserId))

  let description = `This will send ${customerUser.firstName} an invitation via email to set up their Emissary account.`
  let title = 'Send invitation'
  let successMessage = 'A reminder email has been sent!'
  let sendInvite = true
  let sendReminder = false

  switch (customerUser.status) {
    case 'not_invited':
      successMessage = 'An invite has been sent!'
      break
    case 'invited':
    case 'invite_accepted':
    case 'sign_up_incomplete_needs_contact_info':
    case 'sign_up_incomplete_needs_account_info':
      description = `This will send an email reminding ${customerUser.firstName} to complete setting up their account so that they can access Emissary.`
      title = 'Send a reminder'
      sendInvite = false
      sendReminder = true
      break
    case 'idle':
      description = `This will send an email reminding ${customerUser.firstName} to log into the Emissary platform.`
      break
    default:
      break
  }

  return (
    <ConfirmDialog
      size="sm"
      title={title}
      description={description}
      actions={[
        {
          name: 'Send',
          action: async () => {
            dispatch(JsonAPI.save({
              ...customerUser,
              sendInvite,
              sendReminder,
              riders: ['sendInvite', 'sendReminder']
            })).then((resp) => {
              if (resp.ok) {
                onInviteSent && onInviteSent()
              }
              httpResponseAlert(resp, {
                success: successMessage,
                failure: 'Error sending message'
              })
            }).finally(() => {
              closeDialog()
            })
          }
        }
      ]}
    />
  )
}

InviteEmailDialog.defaultProps = {
  onInviteSent: null
}

InviteEmailDialog.propTypes = {
  customerUserId: PropTypes.number.isRequired,
  onInviteSent: PropTypes.func
}

export default InviteEmailDialog
