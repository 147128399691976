import moment from 'moment'
import momentBusiness from 'moment-business'

export function formatTimeRemainingUntilCall (timeslot) {
  const today = moment()
  const timeRemaining = today.to(timeslot.startTime)

  return `starts ${timeRemaining}`
}

export function computeCallStatus (latestProposal, userId) {
  if (latestProposal === null) {
    return 'no_proposal_exists'
  }

  if (['REJECTED', 'RESCHEDULED'].includes(latestProposal.status)) {
    return 'no_upcoming_call_times'
  }

  if (latestProposal.status === 'PROPOSED') {
    if (latestProposal.canCreateNextProposal) {
      return 'call_times_expired'
    }

    if (latestProposal.respondingUserId === userId) {
      return 'proposed_call_times_for_review'
    }
    return 'sent_call_times_not_reviewed'
  } if (latestProposal.status === 'ACCEPTED') {
    const selectedTimeslot = latestProposal.timeslots.find((t) => t.selected)
    const now = moment()

    if (selectedTimeslot.startTime >= now) {
      return 'call_scheduled'
    }

    if (selectedTimeslot.endTime <= now) {
      return 'call_finished'
    }

    return 'call_happening_now'
  } if (latestProposal.status === 'CALL_MISSED') {
    return 'call_missed'
  }
  return 'unknown'
}

export function generateDateHeaders (existingDateHeaders, numberOfDateHeaders = 5) {
  if (numberOfDateHeaders < existingDateHeaders.length) {
    return existingDateHeaders.slice(0, numberOfDateHeaders)
  }

  const newDateHeaders = Array.from(existingDateHeaders)

  for (let i = existingDateHeaders.length; i < numberOfDateHeaders; i++) {
    const previousDateHeader = newDateHeaders[i - 1]

    const nextDateHeader = previousDateHeader.clone()
    if (momentBusiness.isWeekendDay(previousDateHeader)) {
      nextDateHeader.add(1, 'day')
    } else {
      momentBusiness.addWeekDays(nextDateHeader, 1)
    }

    newDateHeaders.push(nextDateHeader)
  }

  return newDateHeaders
}

export function generateToday (tzName = null) {
  return moment().tz(tzName || moment.tz.guess()).startOf('day')
}
