import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import Downshift from 'downshift'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { TextValidator } from 'react-material-ui-form-validator'

import {
  Grid,
  Paper,
  MenuItem
} from '@mui/material'

import { useDebounce } from 'utils-em'

function renderSuggestion (suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      <Grid container spacing={1}>
        {suggestion.fullName}
      </Grid>

    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}

const UserSelector = ({ customerId, validators, errorMessages, userId, onClear, showInactive, onChange, userSearch, userLookup, users }) => {
  const [textInProgress, setTextInProgress] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const debouncedTextInProgress = useDebounce(textInProgress, 150)

  useEffect(() => {
    if (userId) {
      userLookup(userId).then((res) => {
        const user = res.data.data.attributes
        setSelectedUser(user)
        setTextInProgress(user.fullName)
      })
    }
  }, [userId])

  useEffect(() => {
    if (selectedUser && debouncedTextInProgress === selectedUser.fullName) return

    handleSearch()
    setSelectedUser(null)
    onClear()
  }, [debouncedTextInProgress])

  const handleSearch = () => {
    userSearch(textInProgress, customerId, showInactive)
    setTextInProgress(textInProgress)
    setIsMenuOpen(Boolean(textInProgress))
  }

  const handleChange = (user) => {
    setSelectedUser(user)
    setTextInProgress(user.fullName)
    setIsMenuOpen(false)

    onChange(user)
  }

  return (
    <Downshift
      id="downshift-simple"
      onChange={handleChange}
      itemToString={(item) => (item ? item.primary_name : '')}
      isOpen={isMenuOpen}
      onOuterClick={() => setIsMenuOpen(false)}
    >
      {({
        getItemProps,
        getMenuProps,
        highlightedIndex
      }) => (
        <div>
          <TextValidator
            value={textInProgress}
            onChange={(e) => setTextInProgress(e.target.value)}
            fullWidth
            // disabled={this.state.intentionallyNull}
            InputProps={{
              startAdornment: selectedUser ? (<CheckCircle />) : null
            }}
            validators={validators}
            errorMessages={errorMessages}
          />

          <div {...getMenuProps()}>
            {isMenuOpen
              ? (
                <Paper square>
                  {users.map((suggestion, index) => renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion }),
                    highlightedIndex
                  }))}
                </Paper>
                )
              : null}
          </div>
        </div>
      )}
    </Downshift>
  )
}

UserSelector.defaultProps = {
  customerId: null,
  userId: null,
  validators: [],
  errorMessages: [],
  onClear: () => {},
  showInactive: false
}

UserSelector.propTypes = {
  customerId: PropTypes.number,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  userId: PropTypes.number,
  onClear: PropTypes.func,
  showInactive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  userSearch: PropTypes.func.isRequired,
  userLookup: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
}

export default UserSelector
