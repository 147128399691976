import PropTypes from 'prop-types'
import React from 'react'
import { Link, Paper, Typography } from '@mui/material'
import { queryStringParser } from 'utils-em'

const InvalidRequestLayout = ({ location }) => {
  let message = (
    <>
      Sorry, something went wrong, please contact (
      <Link href="mailto:support@emissary.io" rel="noreferrer" target="_blank">
        support@emissary.io
      </Link>
      ) if the problem persists.
    </>
  )

  if (queryStringParser(location).expired) {
    message = (
      <>
        Sorry, that link is expired.
      </>
    )
  }

  return (
    <Paper sx={{
      width: '40%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '10em',
      height: '20em'
    }}
    >
      <Typography
        variant="h5"
        sx={{ textAlign: 'center', marginTop: '5em', padding: '3em' }}
      >
        { message }
      </Typography>
    </Paper>
  )
}

InvalidRequestLayout.propTypes = {
  location: PropTypes.object.isRequired
}

export default InvalidRequestLayout
