import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { ArrowLeft, SadEmoji } from 'icons'

const ErrorPage = ({ title, message }) => {
  const history = useHistory()
  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Box id="error-page" sx={{ display: 'flex', alignItems: 'center', px: '120px', minHeight: '75vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ typography: 'h1', display: 'flex', alignItems: 'center' }}>
          <SadEmoji
            sx={{
              mr: 1,
              color: 'error.main',
              height: '56px',
              width: '56px',
            }}
          />
          {title}
        </Box>
        <Box sx={{ typography: 'h3', mt: 2 }}>
          {message}
        </Box>
        <Box sx={{ mt: 5 }}>
          <Button variant="outlined" startIcon={<ArrowLeft />} onClick={handleGoBack}>
            Go back
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

ErrorPage.defaultProps = {
  title: 'Oops!',
  message: 'Something went wrong.',
}

ErrorPage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

export default ErrorPage
