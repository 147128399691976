import React, { useState } from 'react'
import { map } from 'lodash'

import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import {
  Button,
  FormControl,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Paper
} from '@mui/material'

import { ConfirmDialog } from 'components'
import {
  buildJsonApiFilter,
  constants,
  openDialog
} from 'utils-em'
import {
  useJsonAPIGetAll,
} from 'hooks'
import { JsonAPI } from 'store'

import TablePaginationActions from '../../../../../../components/TablePaginationActions'

const TagList = () => {
  const [dialogActive, setDialogActive] = useState(false)
  const [activeTag, setActiveTag] = useState({})
  const [sortDirection, setSortDirection] = useState('desc')
  const [sortColumn, setSortColumn] = useState('dateUpdated')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [searchTerms, setSearchTerms] = useState({})
  const [searchFilters, setSearchFilters] = useState({})

  const sort = {
    desc: {
      opposite: 'asc',
      prefix: '-'
    },
    asc: {
      opposite: 'desc',
      prefix: ''
    }
  }

  const handleChange = (name) => (event) => {
    setActiveTag({
      ...activeTag,
      [name]: event.target.value
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const saveTag = () => {
    if (activeTag.id) {
      JsonAPI.save(activeTag)
    } else {
      JsonAPI.create(activeTag)
    }
    setDialogActive(false)
  }

  const sortOnColumn = (column) => {
    const newSortDirection = sortColumn === column ? sort[sortDirection].opposite : 'desc'
    setSortColumn(column)
    setSortDirection(newSortDirection)
  }

  const { objects: tags, totalCount: tagsCount, reload } = useJsonAPIGetAll('tags', {
    pageSize: rowsPerPage,
    pageNumber: page + 1,
    sortBy: `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`,
    queryStringParams: {
      filter: map(searchFilters, (v, k) => v)
    }
  })

  React.useEffect(() => {
    reload()
  }, [sortDirection, sortColumn, rowsPerPage, page])

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    const filter = buildJsonApiFilter(name, value)
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [name]: value
    }))
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [name]: filter
    }))
  }

  const editTag = (tag) => {
    setActiveTag(tag)
    setDialogActive(true)
  }

  const createNewTag = () => {
    setActiveTag({ category: '', name: '', type: 'tags' })
    setDialogActive(true)
  }

  const deleteTag = (tag) => {
    deleteTag(tag)
  }

  const tagCategories = [
    { value: 'function-of-role', label: 'Area of Expertise' },
    { value: 'business-unit', label: 'Business Unit' },
    { value: 'region', label: 'Region' },
    { value: 'focus-industry', label: 'Focus Industry' },
    { value: 'vendor-category', label: 'Vendor Category' },
    { value: 'deliverable', label: 'Deliverable' },
    { value: 'initiative', label: 'Initiative' }
  ]

  return (
    <div>
      <Dialog open={dialogActive} fullWidth>
        <ValidatorForm
          onSubmit={() => openDialog(
            <ConfirmDialog
              description={`Are you sure you want to save: ${activeTag.name}?`}
              actions={[
                {
                  name: 'Save',
                  action: () => { saveTag() }
                }
              ]}
            />
          )}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label="Name"
                  validators={['required']}
                  errorMessages={['Required Field']}
                  value={activeTag.name}
                  onChange={handleChange('name')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <SelectValidator
                    label="Type"
                    validators={['required']}
                    errorMessages={['Required Field']}
                    value={activeTag.category}
                    onChange={handleChange('category')}
                  >
                    {tagCategories.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label="Parent Id"
                  value={activeTag.parentId}
                  onChange={handleChange('parentId')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label="Customer Id"
                  value={activeTag.customerId}
                  onChange={handleChange('customerId')}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  multiline
                  fullWidth
                  label="Description"
                  value={activeTag.description}
                  onChange={handleChange('description')}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button onClick={() => setDialogActive(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <Paper sx={{ mt: 8, p: '1em' }}>
        <ValidatorForm onSubmit={reload}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 1 }}
                onClick={createNewTag}
              >
                Create new tag
              </Button>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={reload}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        column="name"
                        active={sortColumn === 'name'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('name')}
                      >
                        Name
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={searchTerms.name || ''}
                        onChange={handleSearchFilter('name')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        column="category"
                        active={sortColumn === 'category'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('category')}
                      >
                        Type
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={searchTerms.category || ''}
                        onChange={handleSearchFilter('category')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        column="parentId"
                        active={sortColumn === 'parentId'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('parentId')}
                      >
                        Parent ID
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={searchTerms.parentId || ''}
                        onChange={handleSearchFilter('parentId')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableSortLabel
                        column="customerId"
                        active={sortColumn === 'customerId'}
                        direction={sortDirection}
                        onClick={() => sortOnColumn('customerId')}
                      >
                        Customer ID
                      </TableSortLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        fullWidth
                        value={searchTerms.customerId || ''}
                        onChange={handleSearchFilter('customerId')}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => (
                <TableRow key={tag.id}>
                  <TableCell>{tag.name}</TableCell>
                  <TableCell>{tag.category}</TableCell>
                  <TableCell>{tag.parentId}</TableCell>
                  <TableCell>{tag.customerId}</TableCell>
                  <TableCell>
                    <Button onClick={() => editTag(tag)}>Edit</Button>
                    <Button
                      onClick={() => openDialog(
                        <ConfirmDialog
                          description={`Really delete tag: ${tag.name}?`}
                          actions={[
                            {
                              name: 'Delete',
                              action: () => { deleteTag(tag) },
                              isDelete: true
                            }
                          ]}
                        />
                      )}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                  count={tagsCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </ValidatorForm>
      </Paper>
    </div>
  )
}

export default TagList
