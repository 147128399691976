import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'
import { useJsonAPIGetAll } from 'hooks'
import { CloseCircle } from 'icons'

const DivisionPicker = ({ initialDivisions, onSelectCallback, customerId }) => {
  const { objects: selectableDivisions, loaded } = useJsonAPIGetAll('divisions', {
    filters: customerId ? [{ name: 'customerId', op: 'eq', val: customerId }] : []
  })
  const [selectedDivisions, setSelectedDivisions] = React.useState(initialDivisions)
  React.useEffect(() => {
    onSelectCallback(selectedDivisions)
  }, [selectedDivisions])

  if (!loaded) { return null }
  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      options={selectableDivisions}
      getOptionLabel={(division) => division.displayName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedDivisions}
      onChange={(e, newval) => setSelectedDivisions(newval)}
      ChipProps={{ size: 'small', deleteIcon: <CloseCircle /> }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="large"
          variant="outlined"
          label="Team(s) *"
        />
      )}
    />
  )
}

DivisionPicker.defaultProps = {
  customerId: null
}

DivisionPicker.propTypes = {
  initialDivisions: PropTypes.array.isRequired,
  onSelectCallback: PropTypes.func.isRequired,
  customerId: PropTypes.number
}

export default DivisionPicker
