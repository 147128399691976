import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const DateSelector = ({
  label,
  value,
  minDate,
  maxDate,
  onChange,
  backgroundColor,
  isRequired,
  includeDays,
  disableFuture,
  disablePast,
  ...rest
}) => {
  const [openCalendar, setOpenCalendar] = React.useState(false)
  const isValid = Boolean(!isRequired || value)

  const onKeyDown = React.useCallback((e, field) => {
    e.target.blur()
    e.preventDefault()
    if (e.key === 'Backspace') {
      onChange(null)
    }
  })

  return (
    <DatePicker
      {...rest}
      views={includeDays ? ['year', 'month', 'day'] : ['year', 'month']}
      label={label}
      value={moment(value)}
      open={openCalendar}
      onClose={() => setOpenCalendar(false)}
      minDate={minDate ? moment(minDate) : undefined}
      maxDate={maxDate ? moment(maxDate) : undefined}
      onChange={(timestamp) => onChange(timestamp)}
      disableFuture={disableFuture}
      disablePast={disablePast}
      slotProps={{
        textField: {
          required: isRequired,
          variant: 'outlined',
          onClick: (e) => {
            e.target.blur()
            setOpenCalendar(true)
          },
          onKeyDown,
          error: !isValid
        }
      }}
    />
  )
}

DateSelector.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  backgroundColor: 'neutral.white',
  isRequired: false,
  includeDays: true,
  disableFuture: false,
  disablePast: false
}

DateSelector.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.instanceOf(Date)]).isRequired,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  isRequired: PropTypes.bool,
  includeDays: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool
}

export default DateSelector
