import PropTypes from 'prop-types'
import React from 'react'
import { get, map } from 'lodash'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  Typography
} from '@mui/material'

import { buildJsonApiFilter, constants, formatDateTime } from 'utils-em'

import EditDivisionDialog from '../EditDivisionDialog'
import TablePaginationActions from '../../../../../../../../components/TablePaginationActions'

const DivisionsList = ({ getAllDivisions, divisions, totalCount, customerId, openDialog, handleClose }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [sortDirection, setSortDirection] = React.useState('asc')
  const [sortColumn, setSortColumn] = React.useState('name')
  const [filters, setFilters] = React.useState({})

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    setFilters((prevState) => ({
      ...prevState,
      [name]: {
        value,
        filter: value ? buildJsonApiFilter(name, value) : null
      }
    }))
  }

  const sortOnColumn = (columnName) => {
    setSortDirection(sortColumn !== columnName || sortDirection === 'asc' ? 'desc' : 'asc')
    setSortColumn(columnName)
  }

  const makeRequest = () => {
    getAllDivisions(
      customerId,
      rowsPerPage,
      page + 1,
      `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`,
      map(filters, (v, k) => v.filter).filter((f) => f)
    )
  }

  React.useEffect(() => { makeRequest() }, [page, rowsPerPage, sortDirection, sortColumn])

  const handleCloseDialog = () => {
    getAllDivisions(
      customerId,
      rowsPerPage,
      page + 1,
      `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`,
      map(filters, (v, k) => v.filter).filter((f) => f)
    ).then(handleClose)
  }

  return (
    <ValidatorForm onSubmit={makeRequest}>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ m: '1rem' }} variant="h4">Teams</Typography>
          </Grid>
          <Grid item xs={2} sx={{ m: '1rem' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                openDialog(<EditDivisionDialog
                  customerId={customerId}
                  handleClose={handleCloseDialog}
                />)
              }}
            >
              Add Team
            </Button>
          </Grid>
          <Grid item xs={2} sx={{ m: '1rem' }}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={makeRequest}
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        <TableSortLabel
                          active={sortColumn === 'name'}
                          direction={sortDirection}
                          onClick={() => sortOnColumn('name')}
                        >
                          Name
                        </TableSortLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          fullWidth
                          value={get(filters, 'name.value', '')}
                          onChange={handleSearchFilter('name')}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        Parent
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          fullWidth
                          value={get(filters, ['parent.name', 'value'], '')}
                          onChange={handleSearchFilter('parent.name')}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        Sub-teams
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          fullWidth
                          value={get(filters, ['children__any.name', 'value'], '')}
                          onChange={handleSearchFilter('children__any.name')}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        Users
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          fullWidth
                          value={get(filters, ['customerUsers__any.fullName', 'value'], '')}
                          onChange={handleSearchFilter('customerUsers__any.fullName')}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      column="dateUpdated"
                      active={sortColumn === 'dateUpdated'}
                      direction={sortDirection}
                      onClick={() => sortOnColumn('dateUpdated')}
                    >
                      Date Updated
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { divisions.map((d) => (
                  <TableRow key={d.id}>
                    <TableCell component="th">{d.displayName}</TableCell>
                    <TableCell>
                      {d.parent ? d.parent.displayName : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {d.children && d.children.length ? d.children.map((cu) => cu.name).join(', ') : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {d.customerUsers && d.customerUsers.length ? d.customerUsers.map((cu) => cu.fullName).join(', ') : 'N/A'}
                    </TableCell>
                    <TableCell component="th">{formatDateTime(d.dateUpdated)}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          openDialog(<EditDivisionDialog
                            customerId={customerId}
                            division={d}
                            handleClose={handleCloseDialog}
                          />)
                        }}
                      >
                        Edit Team
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true
                    }}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(event.target.value)
                      setPage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    </ValidatorForm>
  )
}

DivisionsList.propTypes = {
  handleClose: PropTypes.func.isRequired,
  getAllDivisions: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  divisions: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired
}

export default DivisionsList
