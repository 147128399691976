import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  useTheme
} from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { JsonAPI } from 'store'
import { TimezoneSelector } from 'components'
import {
  buildJsonApiOne,
  customAlert,
  formatDate,
  formatDayOfWeekForHumans,
  filteredTimezones,
  generateToday,
  generateTimesWithHalfHourInterval,
  generateDateHeaders,
  httpResponseAlert
} from 'utils-em'

const MAX_SLOTS = 30
const MAX_DAYS_AWAY_ALLOWED = 29

const SelectableTableCell = ({ day, time, endTime, tzName, onSelect, selectedSlots }) => {
  const slotString = `${day} ${time}`
  const [isSelected, setIsSelected] = React.useState(selectedSlots.some((slot) => slot === slotString))
  const timeMoment = moment.tz(`${day} ${time}`, 'YYYY-MM-DD hh:mm a', tzName)
  const isSelectable = timeMoment.isAfter(moment()) && timeMoment.isBefore(moment().add(MAX_DAYS_AWAY_ALLOWED, 'days'))

  const selectSlot = () => {
    if (selectedSlots.length === MAX_SLOTS && !isSelected) {
      customAlert(`You can only select ${MAX_SLOTS} times, please deselect some to select more`, true)
      return
    }
    setIsSelected(!isSelected)
    onSelect(day, time, isSelected)
  }

  return (
    <TableCell
      disabled={!isSelectable}
      sx={isSelectable ? {
        cursor: 'pointer',
        backgroundColor: isSelected ? 'primary.lightest' : 'neutral.white',
        color: isSelected ? 'primary.main' : 'neutral.black'
      } : {
        cursor: 'not-allowed',
        color: 'neutral.disabled'
      }}
      key={day}
      onClick={() => { isSelectable && selectSlot(day, time) }}
    >
      <Typography variant={isSelected ? 'captionBold' : 'caption'}>
        {`${time} - ${endTime}`}
      </Typography>
    </TableCell>
  )
}

SelectableTableCell.propTypes = {
  day: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  tzName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedSlots: PropTypes.array.isRequired
}

const convertIsoToTimeslot = (isoTimeslot) => {
  // check if already valid format
  moment(isoTimeslot, 'YYYY-MM-DD hh:mm   a', true).isValid()
  // first parse into moment
  const momentTimeslot = moment(isoTimeslot)

  // convert back into slot format
  return momentTimeslot.format('YYYY-MM-DD hh:mm   a')
}

const CallScheduling = ({
  advisorId,
  engagementId,
  timesReceivedCallback,
  timesSelectedCallback,
  initialTimes,
  onSubmit,
  onCancel
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, 'engagements', engagementId))
  const advisor = useSelector(({ data }) => buildJsonApiOne(data, 'advisors', advisorId))
  const initiatingUserId = useSelector(({ session }) => session.id)

  const guessedTimeZoneName = moment.tz.guess()
  let defaultTimezone = filteredTimezones.find((tz) => tz.name === guessedTimeZoneName)
  if (!defaultTimezone) {
    defaultTimezone = filteredTimezones.find((tz) => tz.offset === moment.tz(guessedTimeZoneName).utcOffset())
  }

  const [dateHeaders, setDateHeaders] = React.useState([])
  const [times, setTimes] = React.useState([])
  const [selectedSlots, setSelectedSlots] = React.useState(initialTimes.map((ts) => convertIsoToTimeslot(ts.startTime)))
  const [anchorDate, setAnchorDate] = React.useState(null)
  const isCustomerUser = useSelector(({ session }) => session.userType === 'customer')
  const isAdvisorUser = useSelector(({ session }) => session.userType === 'advisor')
  const advisorTimezone = engagement ? engagement.advisor.timezone : advisor && advisor.timezone
  const customerTimezone = engagement && engagement.customerUser.timezone
  const [selectedTzName, setSelectedTzName] = React.useState(defaultTimezone ? defaultTimezone.name : '')

  React.useEffect(() => {
    timesSelectedCallback && timesSelectedCallback(selectedSlots.map((timeslot) => {
      const startTimeMoment = moment.tz(timeslot, 'YYYY-MM-DD hh:mm a', selectedTzName)
      return {
        type: 'timeslots',
        startTime: startTimeMoment.format(),
        endTime: startTimeMoment.clone().add(60, 'minute').format(),
        tzName: selectedTzName
      }
    }))
  }, [selectedSlots])

  React.useEffect(() => {
    const startDay = generateToday()
    if (startDay.day() === 0) {
      // sunday
      startDay.add(1, 'days')
    } else if (startDay.day() === 6) {
      // saturday
      startDay.add(2, 'days')
    }
    setAnchorDate(startDay)
    setDateHeaders(generateDateHeaders([startDay]).map((dh) => dh.format('YYYY-MM-DD')))

    // copied from old timeslot proposal form
    setTimes(generateTimesWithHalfHourInterval(
      { hour: 0, minute: 0, isBeforeNoon: true }, 48
    ))
  }, [])

  const outerScrollRef = React.useRef()

  const scrollIntoViewRef = React.useCallback((node) => {
    // used to scroll to top of outer Box and
    // to the 8am table row in the inner Box
    node?.scrollIntoView(true)
    outerScrollRef?.current?.scrollIntoView(true)
  }, [])

  const moveDates = (forward) => {
    const sevenDaysAway = anchorDate.add(forward ? 1 : -1, 'week')
    setAnchorDate(sevenDaysAway)
    setDateHeaders(generateDateHeaders([sevenDaysAway]).map((dh) => dh.format('YYYY-MM-DD')))
  }

  const selectSlot = (day, time, isSelected) => {
    const slotString = `${day} ${time}`
    if (isSelected) {
      setSelectedSlots(selectedSlots.filter((ss) => ss !== slotString))
    } else {
      setSelectedSlots([...selectedSlots, slotString])
    }
  }

  const compileTimesAndSubmit = () => {
    const timeslotsToSubmit = selectedSlots.map((timeslot) => {
      const startTimeMoment = moment.tz(timeslot, 'YYYY-MM-DD hh:mm a', selectedTzName)
      return {
        type: 'timeslots',
        startTime: startTimeMoment.format(),
        endTime: startTimeMoment.clone().add(60, 'minute').format(),
        tzName: selectedTzName
      }
    })

    if (timesReceivedCallback || timesSelectedCallback) {
      timesReceivedCallback && timesReceivedCallback(timeslotsToSubmit)
      timesSelectedCallback && timesSelectedCallback(timeslotsToSubmit)
      onSubmit && onSubmit()
      return
    }

    const respondingUserId = initiatingUserId === engagement.advisorId ? engagement.customerUserId : engagement.advisorId

    const bodyToCreate = {
      engagementId: engagement.id,
      initiatingUserId,
      respondingUserId,
      timeslots: timeslotsToSubmit,
      type: 'timeslotProposals',
      riders: ['timeslots'],
      queryStringParams: { 'lazy[engagements]': 'step' }
    }

    dispatch(JsonAPI.create({
      ...bodyToCreate,
      include: [
        'timeslots',
        'engagement',
        'engagement.timeslotProposals',
        'initiatingUser'
      ].join(',')
    })).then((resp) => {
      httpResponseAlert(resp, {
        success: `${selectedSlots.length} call time proposals sent to all participants`
      })
      if (resp.ok) {
        onSubmit && onSubmit()
      }
    })
  }

  return (
    <Box ref={outerScrollRef} sx={{ height: '90%' }}>
      <DialogTitle>Propose call times</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '95%' }}>
        { /* header box */}
        <Box sx={{ flex: '1 0 auto' }}>
          <Box typography="body1" color="neutral.black" sx={{ mb: 3 }}>
            {`Provide some times you're available for a 60 minute call.
              We recommend selecting 5-10 call times over a period of two weeks.
              Consider choosing slots far enough in advance that your advisor’s schedule can accommodate,
              keeping in mind that your advisor has 7 days to accept or decline the call.`}
          </Box>
          <TimezoneSelector
            initialTimezone={selectedTzName}
            timezoneCallback={(timezone) => setSelectedTzName(timezone)}
          />
          <Box sx={{ mb: 3 }}>
            {isCustomerUser && advisorTimezone && <Typography color="neutral.darkGrey">{`This advisor is in the ${advisorTimezone.replace(/_/g, ' ')} time zone.`}</Typography>}
            {isAdvisorUser && customerTimezone && <Typography color="neutral.darkGrey">{`This client is in the ${customerTimezone.replace(/_/g, ' ')} time zone.`}</Typography>}
          </Box>
          <Link
            variant="body1"
            underline="hover"
            disabled={dateHeaders.includes(generateToday().format('YYYY-MM-DD'))}
            onClick={() => {
              const today = generateToday()
              setAnchorDate(today)
              setDateHeaders(generateDateHeaders([today]).map((dh) => dh.format('YYYY-MM-DD')))
            }}
          >
            Jump to today
          </Link>
        </Box>
        <Box sx={{ mt: 3, width: '97%', marginLeft: 'auto', flex: '0 1 auto', overflowY: 'scroll' }}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <Box>
              <IconButton
                onClick={() => { moveDates(false) }}
                edge="end"
                aria-label="delete"
                size="large"
                disabled={anchorDate && anchorDate.format('YYYY-MM-DD') === generateToday().format('YYYY-MM-DD')}
              >
                <KeyboardArrowLeft />
              </IconButton>
            </Box>

            <TableContainer sx={{ width: '90%' }}>
              <Table
                sx={{
                  textAlign: 'center'
                }}
                size="small"
                stickyHeader
              >
                <TableHead sx={{ th: { borderBottom: 0 } }}>
                  <TableRow>
                    {dateHeaders.map((day) => (
                      <TableCell key={day}>
                        <Typography variant="label" color="neutral.black" sx={{ display: 'block' }}>
                          {formatDate(day, { includeYear: true })}
                        </Typography>
                        <Typography variant="caption" color="neutral.disabled">
                          {formatDayOfWeekForHumans(day)}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{
                  maxHeight: '30%',
                  td: {
                    borderLeft: `1px solid ${theme.palette.neutral.mediumGrey}`,
                    borderBottom: '0'
                  },
                  'td: first-of-type': {
                    borderLeft: 'none'
                  }
                }}
                >
                  {times.map((time, idx) => (
                    <TableRow key={time} ref={idx === 14 ? scrollIntoViewRef : null}>
                      {dateHeaders.map((day) => (
                        <SelectableTableCell
                          key={`${day} ${time}`}
                          day={day}
                          time={time}
                          endTime={times[(idx + 2) % 48]}
                          tzName={selectedTzName}
                          onSelect={selectSlot}
                          selectedSlots={selectedSlots}
                        />
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box>
              <IconButton
                onClick={() => { moveDates(true) }}
                disabled={anchorDate && anchorDate.format('YYYY-MM-DD') > generateToday().add(MAX_DAYS_AWAY_ALLOWED - 7, 'days').format('YYYY-MM-DD')}
                edge="end"
                aria-label="delete"
                size="large"
              >
                <KeyboardArrowRight />
              </IconButton>
            </Box>
          </Box>
        </Box>
        { /* times selected box */}
        <Box sx={{ flex: '1 0 auto' }}>
          <Typography color="neutral.disabled" variant="body1" sx={{ mt: 3 }}>
            {`${selectedSlots.length}/${MAX_SLOTS} times selected`}
          </Typography>
        </Box>
        { /* action buttons box */}
      </DialogContent>
      <DialogActions sx={{ flex: '0 1 auto' }}>
        <Button onClick={() => onCancel && onCancel()}>Cancel</Button>
        <Button
          variant="contained"
          disabled={selectedSlots.length === 0}
          onClick={compileTimesAndSubmit}
        >
          Confirm times
        </Button>
      </DialogActions>
    </Box>
  )
}

CallScheduling.defaultProps = {
  engagementId: null,
  timesReceivedCallback: null,
  timesSelectedCallback: null,
  initialTimes: [],
  advisorId: null,
  onSubmit: null,
  onCancel: null,
}

CallScheduling.propTypes = {
  engagementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  timesReceivedCallback: PropTypes.func,
  timesSelectedCallback: PropTypes.func,
  initialTimes: PropTypes.array,
  advisorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default CallScheduling
