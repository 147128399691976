import React from 'react'
import PropTypes from 'prop-types'

import {
  ArrowLeft,
  ArrowRight,
  Badge
} from 'icons'

import {
  Box, IconButton, Typography
} from '@mui/material'

const QuoteCarousel = ({ quotes, ...rest }) => {
  const [quoteIndex, setQuoteIndex] = React.useState(0)

  const alterQuoteIndex = (increment) => {
    let newQuoteIndex = increment ? quoteIndex + 1 : quoteIndex - 1
    newQuoteIndex = (newQuoteIndex === quotes.length) ? 0 : newQuoteIndex
    newQuoteIndex = (newQuoteIndex < 0) ? quotes.length - 1 : newQuoteIndex
    setQuoteIndex(newQuoteIndex)
  }

  const quoteToFormattedText = (quote) => (
    <Box>
      <Typography variant="bodyBold" sx={{ color: 'brand.navy' }}>
        {quote.text}
      </Typography>
      <Box sx={{ mb: 1, mt: 1 }}>
        <Typography variant="caption" sx={{ color: 'neutral.darkGrey' }}>
          {`- ${quote.quotee}`}
        </Typography>
      </Box>
    </Box>
  )

  React.useEffect(() => { setQuoteIndex(0) }, [quotes])

  return (
    <Box
      sx={{
        p: 3,
        mt: 3,
        mb: 10,
        borderRadius: 1,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'brand.lightNavy'
      }}
    >
      {quoteToFormattedText(quotes[quoteIndex])}
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'start', width: '30%' }}>
          <IconButton
            onClick={() => alterQuoteIndex(false)}
          >
            <ArrowLeft sx={{ color: 'neutral.black' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '40%', justifyContent: 'center', alignItems: 'center' }}>
          {quotes.map((q, idx) => <Badge key={q.text} sx={{ color: (quoteIndex === idx ? 'brand.slate' : 'brand.paleSlate') }} />)}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', width: '30%' }}>
          <IconButton
            onClick={() => alterQuoteIndex(true)}
          >
            <ArrowRight sx={{ color: 'neutral.black' }} />
          </IconButton>
        </Box>
      </Box>

    </Box>
  )
}

QuoteCarousel.propTypes = {
  quotes: PropTypes.array.isRequired
}

export default QuoteCarousel
