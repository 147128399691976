import React from 'react'
import {
  Box,
  Button
} from '@mui/material'

import {
  Spinner
} from 'components'
import {
  openDialog
} from 'utils-em'

import PageEntry from './components/PageEntry'
import PageDialog from './components/PageDialog'

import { KnowledgePageBuilderContext } from './KnowledgePageBuilderContext'

const KnowledgePageBuilder = () => {
  const {
    knowledgePages,
    loaded,
    savePage,
    deletePage
  } = React.useContext(KnowledgePageBuilderContext)

  const openCreateEditDialog = (knowledgePage = null) => {
    openDialog(
      <PageDialog
        knowledgePageId={knowledgePage?.id}
        onSave={savePage}
        onDelete={deletePage}
      />
    )
  }

  return (
    <Box sx={{ width: '50%' }}>
      <Button
        variant="contained"
        onClick={() => openCreateEditDialog()}
        sx={{ mb: 3 }}
      >
        Add new page
      </Button>
      {loaded ? (
        <Box>
          {knowledgePages.map((kp) => (
            <PageEntry
              key={kp.id}
              knowledgePageId={kp.id}
              onEdit={() => openCreateEditDialog(kp)}
            />
          ))}
        </Box>
      ) : (
        <Spinner />
      )}
    </Box>
  )
}

export default KnowledgePageBuilder
