import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'

import {
  ConfirmDialog
} from 'components'
import {
  useJsonAPIGetAll,
  useJsonAPISelectOne
} from 'hooks'
import {
  ArrowLeft
} from 'icons'
import {
  openDialog,
  closeDialog,
} from 'utils-em'

const SectionDialog = ({ sectionId, defaultPageId, onSave, onDelete }) => {
  const section = sectionId ? useJsonAPISelectOne('knowledgePageSections', sectionId) : { name: '', pageId: defaultPageId }
  const { objects: knowledgePages } = useJsonAPIGetAll('knowledgePages', { include: ['sections', 'sections.topics'] })

  const [editSection, setEditSection] = React.useState(section)
  const [editTopics, setEditTopics] = React.useState(section.topics || []) // local copy of topics to allow for re-arranging order

  const onSubmit = () => {
    onSave({
      ...editSection,
      topics: editTopics
    })
    closeDialog()
  }

  const onSectionDelete = () => {
    onDelete(editSection)
    closeDialog()
  }

  const setNewOrder = (topicId, offset) => {
    const topicIdx = editTopics.findIndex((t) => parseInt(t.id, 10) === parseInt(topicId, 10))
    const targetIdx = topicIdx + offset

    // locally swap two topics in the array
    const reorderedTopics = [...editTopics]
    reorderedTopics[topicIdx] = editTopics[targetIdx]
    reorderedTopics[targetIdx] = editTopics[topicIdx]
    setEditTopics(reorderedTopics)
  }

  const iconButtonSx = { '&:hover': { color: 'primary.main' } }

  return (
    <Dialog
      open
      maxWidth="sm"
      scroll="paper"
      onClose={closeDialog}
    >
      <DialogTitle>{`${editSection.id ? 'Edit' : 'Add new'} section`}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={editSection.name}
          onChange={(e) => setEditSection({ ...editSection, name: e.target.value })}
          sx={{ mb: 3 }}
        />
        <FormControl fullWidth>
          <InputLabel id="select-label">Parent page</InputLabel>
          <Select
            labelId="select-label"
            value={editSection.pageId}
            label="Parent page"
            onChange={(e) => setEditSection({
              ...editSection,
              pageId: parseInt(e.target.value, 10)
            })}
          >
            {knowledgePages?.map((kp) => (
              <MenuItem key={kp.id} value={kp.id}>{kp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {editTopics?.length > 0 ? (
          <Box sx={{ my: 3 }}>
            <Typography sx={{ mb: 1 }}>Change order of topics:</Typography>
            {editTopics.map((topic, idx) => (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body">{topic.name}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip title="Move up">
                    <span>
                      <IconButton
                        disabled={idx === 0}
                        onClick={() => setNewOrder(topic.id, -1)}
                        sx={iconButtonSx}
                      >
                        <ArrowLeft sx={{ transform: 'rotate(90deg)' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Move down">
                    <span>
                      <IconButton
                        disabled={idx === editTopics.length - 1}
                        onClick={() => setNewOrder(topic.id, +1)}
                        sx={iconButtonSx}
                      >
                        <ArrowLeft sx={{ transform: 'rotate(-90deg)' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            ))}
          </Box>
        ) : null}

        {editSection.id ? (
          <Link
            variant="buttonL"
            color="error.main"
            onClick={() => openDialog(
              <ConfirmDialog
                size="sm"
                title={`Delete ${editSection.name}?`}
                description="Deleting this section will not delete the topics associated to it, but it will disassociate those topics and they will be homeless."
                actions={[
                  {
                    name: 'Delete',
                    action: onSectionDelete,
                    isDelete: true
                  }
                ]}
              />
            )}
            sx={{ display: 'block', mt: 2 }}
          >
            Delete section
          </Link>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={editSection?.name?.length === 0}
          onClick={onSubmit}
          sx={{ ml: 2 }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SectionDialog.defaultProps = {
  sectionId: null
}

SectionDialog.propTypes = {
  sectionId: PropTypes.number,
  defaultPageId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default SectionDialog
