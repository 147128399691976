import React from 'react'
import { ErrorPage } from 'components'

const NotFound = () => (
  <ErrorPage
    title="404"
    message="Page not found."
  />
)

export default NotFound
