import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  IconButton,
  Typography
} from '@mui/material'

import { HelpCircle, Server } from 'icons'
import { ConfirmDialog, SanitizedHTML } from 'components'

import {
  ACCOUNT_PAGE_SECTION_DATA,
  getItemsInEqualColumns,
  openDialog,
  accountPageSectionHasData
} from 'utils-em'

import { AccountPageContext } from '../AccountPageContext'
import AvatarWithTextCombo from '../AvatarWithTextCombo'
import SectionNotFound from '../SectionNotFound'

const TechStackItem = ({ item }) => {
  const [header, subheader] = item.split('(')
  return (
    <Grid item xs={6} sx={{ px: 2, py: 1 }}>
      <Typography variant="bodyBold">{header.trim()}</Typography>
      {subheader && (<Typography variant="tiny">{subheader.replace(')', '')}</Typography>)}
    </Grid>
  )
}

TechStackItem.propTypes = {
  item: PropTypes.string.isRequired
}

const TechStack = () => {
  const { accountPage } = React.useContext(AccountPageContext)
  const columns = getItemsInEqualColumns(accountPage.data.techStack.sort(), 3)
  const techStackData = ACCOUNT_PAGE_SECTION_DATA.find((data) => data.key === 'techStack')

  if (!accountPageSectionHasData(accountPage?.data?.techStack, 'techStack')) {
    return <SectionNotFound />
  }

  return (
    <>
      <Box sx={{ display: 'flex', mx: 3, my: 1.5, alignItems: 'center' }}>
        <AvatarWithTextCombo
          icon={<Server sx={{ color: 'brand.darkNavy' }} />}
          iconColor="brand.darkNavy"
          iconSize={40}
          variant="body"
          backgroundColor="brand.paleSlate"
          textColor="neutral.black"
          text="Tech Alignment"
          sx={{ pr: 2 }}
        />
        <IconButton
          size="small"
          onClick={() => openDialog(
            <ConfirmDialog
              title="Tech Alignment"
              description={(
                <>
                  <Typography>How to use this section:</Typography>
                  <br />
                  <SanitizedHTML
                    html={techStackData.help}
                    sx={{ mx: 1, '& ul': { listStylePosition: 'outside', pl: 3, my: 0 } }}
                  />
                </>
              )}
              size="sm"
              cancelName="Close"
            />
          )}
        >
          <HelpCircle sx={{ color: 'neutral.darkGrey' }} />
        </IconButton>
      </Box>

      <Grid container>
        {columns.map((col) => (
          <Grid item xs={4}>
            {col.map((stackItem) => (
              <TechStackItem item={stackItem} />
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default TechStack
