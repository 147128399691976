import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Link,
  Tooltip,
} from '@mui/material'
import { useJsonAPISelectOne } from 'hooks'

import {
  Refresh
} from 'icons'
import {
  ConfirmDialog,
} from 'components'
import {
  copyToClipboard,
  customAlert,
  httpResponseAlert,
  openDialog
} from 'utils-em'
import { JsonAPI } from 'store'

const ZoomLink = ({ engagementId }) => {
  const dispatch = useDispatch()
  const {
    zoomMeetingId,
    zoomMeetingJoinLink,
    state,
    step
  } = useJsonAPISelectOne('engagements', engagementId)

  const canBeGenerated = (
    state === 'active' &&
    ['PREP_FOR_CALL', 'COMPLETE_CALL', 'SUBMIT_FEEDBACK'].includes(step)
  )
  const refetch = async () => {
    await dispatch(JsonAPI.getOne({
      type: 'engagements',
      id: engagementId
    }))
  }

  const regenerateZoomLink = async () => {
    const regenerateRes = await fetch(
      `${__API_HOST__}/engagements/${engagementId}/zoom-meeting`,
      { credentials: 'include', method: 'POST' }
    )
    if (!regenerateRes.ok) {
      httpResponseAlert(regenerateRes, { failure: 'Something went wrong with meeting regeneration, contacting engineering.' })
      return
    }
    // refetch the engagement to show the ID
    await refetch()
  }

  const generateZoomLink = async () => {
    // generate the user and meeting
    const generateUserRes = await fetch(
      `${__API_HOST__}/_background/zoom-user/${engagementId}`,
      { credentials: 'include', method: 'POST' }
    )

    if (!generateUserRes.ok) {
      httpResponseAlert(generateUserRes, { failure: 'Something went wrong with user generation, contacting engineering.' })
      return
    }

    const generateMeetingRes = await fetch(
      `${__API_HOST__}/_background/zoom-meeting/${engagementId}`,
      { credentials: 'include', method: 'POST' }
    )

    if (!generateMeetingRes.ok) {
      httpResponseAlert(generateUserRes, { failure: 'Something went wrong with meeting generation, contacting engineering.' })
      return
    }

    // refetch the engagement to show the ID
    await refetch()
  }

  const openConfirmDialog = () => {
    openDialog(
      <ConfirmDialog
        size="sm"
        title={zoomMeetingId ? 'Regenerate Zoom Link' : 'Generate Zoom Link'}
        description="This will generate a new zoom link for this engagement, it will not update any
          previously generated calendar invites but will appear properly in the app and emails going forward."
        actions={[
          {
            name: zoomMeetingId ? 'Regenerate' : 'Generate',
            action: zoomMeetingId ? regenerateZoomLink : generateZoomLink
          }
        ]}
      />
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {zoomMeetingJoinLink && (
          <Tooltip title="Copy Zoom join link to clipboard">
            <Link onClick={() => copyToClipboard(
              zoomMeetingJoinLink,
              () => { customAlert('Zoom join link copied to clipboard.') }
            )}
            >
              {zoomMeetingId}
            </Link>
          </Tooltip>
        )}
        <Button
          onClick={openConfirmDialog}
          variant="outlined"
          size="small"
          disabled={!canBeGenerated}
          startIcon={<Refresh />}
        >
          {zoomMeetingId ? 'Regenerate' : 'Generate'}
        </Button>
      </Box>
      {!canBeGenerated && (
        <Box typography="tiny">
          To generate/regenerate the Zoom meeting ID, the Engagement must be active and have either an upcoming or completed call.
        </Box>
      )}
    </Box>

  )
}

ZoomLink.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default ZoomLink
