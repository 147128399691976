import { ACTIONS } from './actions'

const INITIAL_STATE = {
  sideNavOpen: true,
  notificationDrawerOpen: false,
  searchedOrgResults: {
    orgId: null,
    searchText: null,
    resultCount: null,
    publishedAccountPageId: null,
    engageableAdvisorCount: null,
  },
}

export default function appStateReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_SIDE_NAV: {
      return {
        ...state,
        sideNavOpen: !state.sideNavOpen
      }
    }
    case ACTIONS.TOGGLE_NOTIFICATION_DRAWER: {
      return {
        ...state,
        notificationDrawerOpen: action.open === null ? !state.notificationDrawerOpen : action.open
      }
    }
    case ACTIONS.SET_SEARCHED_ORG_RESULTS: {
      return {
        ...state,
        searchedOrgResults: action.searchedOrgResults,
      }
    }
    default:
      return state
  }
}
