import PropTypes from 'prop-types'
import React from 'react'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Delete from '@mui/icons-material/Delete'

const API_HOST = __API_HOST__
const steps = [
  'UPLOAD_STEP',
  'CONFIRM_STEP'
]

const BulkFindAccountsDialog = ({
  customerId,
  handleClose,
  foundAccountsCallback,
  showErrorMessage
}) => {
  const [accountDump, setAccountDump] = React.useState('')
  const [stepName, setStepName] = React.useState(steps[0])
  const [submitting, setSubmitting] = React.useState(false)
  const [matchedAccounts, setMatchedAccounts] = React.useState([])
  const [unmatchedAccounts, setUnmatchedAccounts] = React.useState([])
  const [operation, setOperation] = React.useState('ADD_TO_GROUP')

  const searchAccounts = () => {
    setSubmitting(true)

    const splitAccounts = accountDump.split('\n')
    const numberRegex = /^[0-9]+$/
    const header = numberRegex.test(splitAccounts[0]) ? 'advisor_request_id' : 'name'
    fetch(
      `${API_HOST}/v1/customers/${customerId}/parse-account-csv`,
      { credentials: 'include', method: 'POST', body: `${header}\n${splitAccounts.map((accountName) => `"${accountName}"`).join('\n')}` }
    ).then((res) => {
      setSubmitting(false)
      res.json().then((body) => {
        setMatchedAccounts(body.accounts.filter((acc) => acc.id))
        setUnmatchedAccounts(body.accounts.filter((acc) => !acc.id))
        setStepName(steps[1])
      })
    }).catch((error) => {
      setSubmitting(false)
      showErrorMessage({
        text: `Something went wrong, please try again, if this error persists, contact Engineering: ${error}`,
        timeout: 2000
      })
    })
  }

  const removeMatchedAccount = (account) => {
    setMatchedAccounts(matchedAccounts.filter((acc) => acc.id !== account.id))
  }

  const submitAccounts = () => {
    // light jsonapi wrapping
    const wrappedAccounts = matchedAccounts.map((acc) => ({
      id: acc.advisorRequestId,
      type: 'advisorRequests',
      account: {
        name: acc.name
      }
    }))

    foundAccountsCallback(wrappedAccounts, operation)
    handleClose()
  }

  const getStep = () => {
    switch (stepName) {
      case 'UPLOAD_STEP':
        return (
          <>
            <DialogTitle>Upload Accounts (one per line)</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Can be all name or all Advisor Request ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Dump Accounts (one per line)"
                    variant="outlined"
                    multiline
                    value={accountDump}
                    onChange={(e) => { setAccountDump(e.currentTarget.value) }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    onClick={searchAccounts}
                  >
                    Parse
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )
      case 'CONFIRM_STEP':
        return (
          <>
            <DialogTitle>Accounts</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List dense>
                    {matchedAccounts.map((account) => (
                      <ListItem key={account.id}>
                        <ListItemText
                          primary={account.name}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => { removeMatchedAccount(account) }}
                            edge="end"
                            aria-label="delete"
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Unmatched accounts:
                    {unmatchedAccounts.map((acc) => acc.name || acc.advisor_request_id).join(', ')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      label="Operation"
                      value={operation}
                      onChange={(e) => { setOperation(e.target.value) }}
                    >
                      <MenuItem value="ADD_TO_GROUP">Add to group</MenuItem>
                      <MenuItem value="REMOVE_FROM_GROUP">Remove from group</MenuItem>
                      <MenuItem value="REPLACE_GROUP">Replace entire group</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    type="submit"
                    onClick={() => { setStepName(steps[0]) }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={submitAccounts}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      { getStep() }
    </Dialog>
  )
}

BulkFindAccountsDialog.propTypes = {
  customerId: PropTypes.number.isRequired,
  foundAccountsCallback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default BulkFindAccountsDialog
