import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Users (props) {
  return (
    <SvgIcon {...props}>
      <rect width="24" height="24" fill="none" fillOpacity="0.01" />
      <path d="M5.78189 19.25H13.2185C13.783 19.25 14.2272 18.7817 14.1147 18.2285C13.8042 16.7012 12.7899 14 9.5002 14C6.21052 14 5.19625 16.7012 4.88569 18.2285C4.77321 18.7817 5.21738 19.25 5.78189 19.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.75 14C17.8288 14 18.6802 16.1479 19.0239 17.696C19.2095 18.532 18.5333 19.25 17.6769 19.25H16.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 7.5C12.25 9.01878 11.0188 10.25 9.5 10.25C7.98122 10.25 6.75 9.01878 6.75 7.5C6.75 5.98122 7.98122 4.75 9.5 4.75C11.0188 4.75 12.25 5.98122 12.25 7.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.75 10.25C16.2688 10.25 17.25 9.01878 17.25 7.5C17.25 5.98122 16.2688 4.75 14.75 4.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}
