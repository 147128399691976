import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Alert, Box, Button, IconButton, Typography } from '@mui/material'

import {
  AdvisorCompanyEditDialog,
  BadgeChip,
  ConfirmDialog,
  IconTextCombo,
  RoleCardTwo
} from 'components'
import { Information, Pencil, Plus, Star, Trash } from 'icons'
import { JsonAPI } from 'store'
import { useIsAdmin, useSessionUser } from 'hooks'
import {
  calculateDecimalYears,
  customAlert,
  formatMonthYearForHumans,
  openDialogWithThemingTwo,
  sortActiveRolesByRecency
} from 'utils-em'

import EditRole from '../AdvisorProfileBuilder/components/EmploymentHistory/components/EditRole'

const CompanyCardTwo = ({ company, onChange, totalCompanies }) => {
  const isAdmin = useIsAdmin()
  const { isCustomerUser } = useSessionUser()
  const dispatch = useDispatch()

  const getLatestRoleEndDate = (c) => {
    const moments = c.roles?.filter((r) => Boolean(r.dateEnded)).map((r) => moment(r.dateEnded))
    return moments?.length > 0 ? moment.max(moments) : null
  }

  const editCompany = () => {
    openDialogWithThemingTwo(<AdvisorCompanyEditDialog
      companyId={company.id}
      advisorId={company.advisorId}
      saveComplete={onChange}
      earliestRoleStartDate={moment.min(company.roles?.map((r) => moment(r.dateStarted)))}
      latestRoleEndDate={getLatestRoleEndDate(company)}
    />)
  }

  const showBlockedFromDeletingLastCompanyDialog = () => openDialogWithThemingTwo(
    <ConfirmDialog
      title="Unable to delete listing"
      description="This listing cannot be deleted. At least one company and role must be listed in your profile."
      size="sm"
    />
  )

  const deleteCompany = () => {
    if (totalCompanies === 1) {
      showBlockedFromDeletingLastCompanyDialog()
      return
    }

    openDialogWithThemingTwo(
      <ConfirmDialog
        title="Delete company?"
        description="This will also delete the associated roles."
        size="sm"
        actions={[
          {
            name: 'Delete',
            isDelete: true,
            action: async () => {
              const res = await dispatch(JsonAPI.save({ ...company, deleted: true }))

              res.ok ? customAlert('Company removed from your profile', false) : customAlert('Error deleting this company', true)
              onChange()
            }
          }
        ]}
      />
    )
  }

  const openEditRoleDialog = (role) => openDialogWithThemingTwo(<EditRole
    roleId={role?.id}
    companyId={company.id}
    hasMultipleRoles={company.roles && company.roles.filter((r) => !r.deleted).length > 1}
    onSave={() => onChange()}
  />)

  const onRoleDeleteClick = (role) => {
    const isLastCompanyRole = company.roles.filter((r) => !r.deleted).length === 1

    if (totalCompanies === 1 && isLastCompanyRole) {
      showBlockedFromDeletingLastCompanyDialog()
      return
    }

    openDialogWithThemingTwo(
      <ConfirmDialog
        title="Delete role"
        description={isLastCompanyRole
          ? `Are you sure you would like to delete this role? This will also remove ${company.orgName} from your experience.`
          : 'Are you sure you want to delete this role?'}
        size="sm"
        actions={[
          {
            name: isLastCompanyRole ? 'Delete role & company' : 'Delete role',
            isDelete: true,
            action: async () => {
              const res = await dispatch(JsonAPI.save({ ...role, deleted: true }))

              let res2 = null
              if (isLastCompanyRole) {
                res2 = await dispatch(JsonAPI.save({ ...company, deleted: true }))
              }

              (res.ok && (res2 === null || res2?.ok)) ? customAlert('Role removed from your profile', false) : customAlert('Error deleting this role', true)
              onChange()
            }
          }
        ]}
      />
    )
  }

  const getCompanyAlertText = () => {
    if (company.isCurrent) return 'Include your current role to contribute to additional surveys. It will be visible on your profile but clients will not be able to engage with you for calls about this experience.'
    if (!company.recentlyDeparted) return 'Since your departure date is over three years ago, you are not eligible for coaching calls for this account. You will not show up on search results for this account, but users will still see this experience on your profile.'
    return 'You are listed as available for coaching calls for this account and will show up on search results.'
  }

  return (
    <Box sx={{ mb: 3 }}>
      {/* Header */}
      <Box sx={{ bgcolor: 'neutral.offWhite', p: 3, borderRadius: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Typography variant="h2">{company.orgName}</Typography>
              {isAdmin ? (
                <>
                  {company.dateEnded === null
                    ? <BadgeChip text="In-seat" color="teal" size="small" textSize="captionBold" />
                    : <BadgeChip text="Departed" color="orange" size="small" textSize="captionBold" />}
                  {company.advisor?.recruitedOrgId && company.organizationId === company.advisor?.recruitedOrgId && (
                    <IconTextCombo
                      icon={<Star />}
                      text="Recruited account"
                      typography="caption"
                      typographyColor="neutral.black"
                      iconSize="small"
                      iconColor="neutral.darkGrey"
                    />
                  )}
                </>
              ) : null}
              {isCustomerUser && company.recentlyDeparted ? (
                <BadgeChip noDot text="Coach" color="orange" textSize="bodyBold" />
              ) : null}
            </Box>
            <Typography variant="body1">
              {formatMonthYearForHumans(company.dateStarted, true)}
              {' - '}
              {company.dateEnded && !company.isCurrent ? formatMonthYearForHumans(company.dateEnded, true) : 'Present'}
              {` (${calculateDecimalYears(company.dateStarted, company.dateEnded || moment())} years)`}
            </Typography>
          </Box>
          {!isCustomerUser && (
            <Box>
              <IconButton onClick={() => editCompany()}>
                <Pencil sx={{ color: 'primary.main' }} />
              </IconButton>
              <IconButton onClick={() => deleteCompany()}>
                <Trash sx={{ color: 'error.main' }} />
              </IconButton>
            </Box>
          )}
        </Box>
        {!isAdmin && !isCustomerUser && (
          <Alert icon={<Information />} severity="info" sx={{ p: 0, mt: 1, bgcolor: 'neutral.offWhite', alignItems: 'flex-start' }}>
            <Typography variant="body1">
              {getCompanyAlertText()}
            </Typography>
          </Alert>
        )}
      </Box>

      {/* Roles */}
      {sortActiveRolesByRecency(company).map((role) => (
        <RoleCardTwo
          key={role.id}
          role={role}
          onEditClick={() => openEditRoleDialog(role)}
          onDeleteClick={() => onRoleDeleteClick(role)}
        />
      ))}

      {/* Actions */}
      {!isCustomerUser && (
        <Button onClick={() => openEditRoleDialog(null)}>
          <Typography variant="buttonL">
            <Plus sx={{ mr: 1, position: 'relative', top: '6px' }} />
            Add Role to
            {' '}
            {company.orgName}
          </Typography>
        </Button>
      )}
    </Box>
  )
}

CompanyCardTwo.propTypes = {
  company: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  totalCompanies: PropTypes.number.isRequired
}

export default CompanyCardTwo
