import React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Redirect } from 'react-router'
import {
  CardContent,
  Container,
  Card,
  Box,
  Link,
  Typography,
  Button
} from '@mui/material'
import { useSessionUser } from 'hooks'
import { ArrowLeft } from 'icons'
import { useDispatch, useSelector } from 'react-redux'

import * as Sentry from '@sentry/react'
import { TogglablePasswordInput } from 'components'
import { navigateTo, validateEmail } from 'utils-em'
import { SessionAPI } from 'store'

const steps = [
  'ENTER_EMAIL',
  'ENTER_PASSWORD',
  'SAML_LOGIN'
]

const Login = () => {
  const location = useSelector(({ router }) => router.location)
  const dispatch = useDispatch()
  const { isLoggedIn, getHomePage } = useSessionUser()
  const [email, setEmail] = React.useState(location?.state?.email)
  const [password, setPassword] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState('')
  const [displayErrors, setDisplayErrors] = React.useState('')
  const [samlUrl, setSamlUrl] = React.useState(null)

  const [step, setStep] = React.useState(location?.state?.email ? steps[1] : steps[0])

  if (isLoggedIn) {
    return <Redirect to={getHomePage()} />
  }

  const attemptLogin = async () => {
    if (step === steps[0]) {
      if (!validateEmail(email).valid) {
        setErrorMessages('Please enter a valid email address')
        setDisplayErrors(true)
        return
      }
      const result = await dispatch(SessionAPI.checkEmail({ email }))
      if (result.status === 500) {
        Sentry.captureMessage(`User encountered 500 on login with email: ${email}`)
      } else if (result.data?.oidcProvider) {
        // login thru oidc
        window.location.href = `${__API_HOST__}/oidc/login/${result.data?.oidcProvider}`
      } else if (result.status === 203 && result.data.saml_url) {
        // login thru saml
        setSamlUrl(result.data.saml_url)
        setStep(steps[2])
      } else {
        setIsSubmitting(false)
        setStep(steps[1])
      }
    } else if (step === steps[1]) {
      setIsSubmitting(true)
      const result = await dispatch(SessionAPI.login({ email, password }))
      if (result.status === 500) {
        setDisplayErrors(true)
        Sentry.captureMessage(`User encountered 500 on login with email: ${email}`)
        setErrorMessages('An unexpected error occurred during login. Please try again later.')
      } else if (result.error) {
        setDisplayErrors(true)
        setErrorMessages(result.error.message)
      }
      setIsSubmitting(false)
    } else if (step === steps[2]) {
      window.location.replace(samlUrl)
    }
  }

  return (
    <Container maxWidth="desktop">
      <Card sx={{ mt: 7, mb: 5 }}>
        <CardContent>
          <ValidatorForm
            onSubmit={attemptLogin}
            sx={{
              backgroundColor: 'neutral.white',
              mt: 7
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '80%', pt: 10, pb: 10 }}>

                <Box sx={{ display: 'inline-block' }}>
                  <Box typography="h1" sx={{ display: 'inline' }}>Sign in to </Box>
                  <Box typography="h1" sx={{ color: 'brand.orange', display: 'inline' }}>Emissary</Box>
                </Box>
                <Typography sx={{ mt: 3 }} variant="h4">
                  { step === steps[0] && 'Enter your email:'}
                  { step === steps[1] && (location?.state?.email ? 'You already have an account with us.' : 'Enter your password')}
                  { step === steps[2] && 'Your organization is set up to authenticate externally, please click below'}
                </Typography>
                <Box sx={{ mt: 3 }}>
                  { step === steps[0] && (
                    <TextValidator
                      fullWidth
                      required
                      variant="outlined"
                      value={email}
                      validators={['required', 'isEmail']}
                      errorMessages={['This field is required', 'Please enter a valid email address']}
                      onChange={(event) => {
                        setDisplayErrors(false)
                        setEmail(event.target.value)
                      }}
                      name="email"
                      autoFocus
                      placeholder="Email"
                      autoComplete="on"
                      id="email"
                    />
                  )}
                  { step === steps[1] && (
                    <TogglablePasswordInput
                      variant="outlined"
                      name="password"
                      required
                      placeholder="Password"
                      fullWidth
                      value={password}
                      disabled={isSubmitting}
                      autoFocus
                      onChange={(event) => {
                        setDisplayErrors(false)
                        setPassword(event.target.value)
                      }}
                    />
                  )}
                </Box>
                {(errorMessages && displayErrors) && (
                  <Typography color="error.main" sx={{ mt: 3 }}>
                    {errorMessages}
                  </Typography>
                )}
                { step === steps[1] ? (
                  <Box sx={{ mt: 3 }}>
                    <Link
                      onClick={() => navigateTo('/forgot')}
                    >
                      Forgot password
                    </Link>
                  </Box>
                ) : null}
                <Button
                  type="submit"
                  disabled={(step === steps[0] && !email) || (step === steps[1] && !password) || isSubmitting}
                  variant="contained"
                  sx={{ display: 'flex', flexGrow: 0, mt: 3 }}
                >
                  { step === steps[0] ? 'Confirm' : 'Sign in'}
                </Button>
                { step !== steps[0] ? (
                  <Box sx={{ mt: 3 }}>
                    <Link
                      variant="bodyBold"
                      color="primary.main"
                      sx={{ textDecoration: 'none' }}
                      onClick={() => setStep(steps[0])}
                    >
                      <ArrowLeft sx={{ position: 'relative', top: '7px', mr: 1 }} />
                      Back to email
                    </Link>
                  </Box>
                ) : null }
              </Box>
            </Box>
          </ValidatorForm>
        </CardContent>
      </Card>
      <Box sx={{ mb: 5 }}>
        <Typography variant="caption" color="neutral.black">
          {'If you have any questions or need help, email our support team. If you\'re an advisor, email '}
          <Link href="mailto:advisor-support@emissary.io" rel="noreferrer" target="_blank">
            advisor-support@emissary.io
          </Link>
          {'. If you\'re a client, email '}
          <Link href="mailto:support@emissary.io" rel="noreferrer" target="_blank">
            support@emissary.io
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  )
}

export default Login
