import { connect } from 'react-redux'

import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'
import CustomerUsersLayout from '../layouts/CustomerUsersLayout'

const mapStateToProps = ({ data }, ownProps) => ({
  customerUsers: buildJsonApiOrdered(data, 'customerUsers'),
  customerId: parseInt(ownProps.match.params.id, 10)
})

const mapDispatchToProps = {
  getAllCustomerUsers: (customerId, size, page) => JsonAPI.getAll({
    type: 'customerUsers',
    queryStringParams: {
      include: 'divisions',
      'filter[customerId]': customerId,
      'filter[isActive]': 1,
      'page[number]': page,
      'page[size]': size,
      sort: 'lastName'
    }
  }),
  getAllDivisions: (customerId, size, page) => JsonAPI.getAll({
    type: 'divisions',
    queryStringParams: {
      'page[size]': size,
      'page[number]': page,
      filter: [{
        name: 'customerId',
        op: 'eq',
        val: customerId
      }],
      sort: 'name'
    }
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsersLayout)
