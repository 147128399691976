import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { JsonAPI } from 'store'
import PropTypes from 'prop-types'
import {
  buildJsonApiAll,
  buildJsonApiOne,
  getAdvisorMostRecentRelevantRole,
  getAdvisorMostRecentRelevantCompany,
  navigateTo
} from 'utils-em'

import { Box, Link, Button } from '@mui/material'

const SavedAdvisorsSection = ({ userId }) => {
  const { id } = useSelector(({ session }) => session)
  const isLoggedInUser = id === userId
  const advisorBookmarks = useSelector(({ data }) => buildJsonApiAll(data, 'bookmarks').filter((bookmark) => bookmark.bookmarkType === 'SAVED_ADVISOR' &&
    bookmark.ownerId === userId))
  const viewedUser = useSelector(({ data }) => buildJsonApiOne(data, 'customerUsers', userId))
  const advisors = useSelector(({ data }) => buildJsonApiAll(data, 'advisors').filter((advisor) => advisorBookmarks.some((b) => b.bookmarkTarget === advisor.id) && advisor.isActive && getAdvisorMostRecentRelevantRole(advisor)))
  const dispatch = useDispatch()
  const [viewAll, setViewAll] = React.useState(false)
  const nullText = (
    isLoggedInUser ? (
      <Box>
        <Link href="/c/search">Search the advisor network </Link>
        <Box typography="body1" color="neutral.black" sx={{ display: 'inline' }}>
          to find advisors. Keep track of the advisors you're interested in by saving their profiles to your account.
        </Box>
      </Box>
    ) : (
      <Box typography="body1" color="neutral.black">{`${viewedUser.firstName} has not saved any advisors.`}</Box>
    )
  )

  const loadAdvisorData = () => dispatch(
    JsonAPI.getAll({
      type: 'advisors',
      queryStringParams: {
        include: 'bookmarksReceived,companies,companies.organization,companies.roles',
        filter: [{
          name: 'bookmarksReceived',
          op: 'any',
          val: {
            name: 'ownerId',
            op: 'eq',
            val: userId
          }
        }]
      }
    })
  )
  React.useEffect(() => { loadAdvisorData() }, [])

  return (
    <Box>
      <Box typography="h4" color="neutral.black" sx={{ mb: 2 }}>Saved advisors</Box>
      <Box sx={{ mb: 2 }}>
        {advisors.length
          ? (
            <Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                {advisors.map((advisor, idx) => (
                  (idx < 9 || viewAll) && (
                  <Box
                    key={advisor.id}
                    sx={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      p: 2,
                      border: 1,
                      borderRadius: 1,
                      m: 2,
                      ml: (idx % 3 === 0 ? 0 : 2),
                      borderColor: 'neutral.lightGrey',
                      ':hover': { bgcolor: 'neutral.offWhite', boxShadow: 4 }
                    }}
                    onClick={() => navigateTo(`/c/advisor/${advisor.id}`)}
                  >
                    {isLoggedInUser && (
                      <Box typography="caption" color="neutral.black">{advisor.firstName}</Box>
                    )}
                    <Box typography="body1" color="neutral.black">
                      {`${getAdvisorMostRecentRelevantRole(advisor).title} at ${getAdvisorMostRecentRelevantCompany(advisor)?.displayOrgName}`}
                    </Box>
                  </Box>
                  )
                ))}
              </Box>
              {advisors.length > 9 && <Button onClick={() => setViewAll(!viewAll)}>{!viewAll ? 'View all' : 'View less' }</Button>}
            </Box>
            ) : (nullText)}
      </Box>
    </Box>
  )
}

SavedAdvisorsSection.propTypes = {
  userId: PropTypes.number.isRequired,
}

export default SavedAdvisorsSection
