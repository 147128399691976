import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles'
import {
  useJsonAPIGetOne,
  useJsonAPIUpsert
} from 'hooks'
import {
  Archive
} from 'icons'
import {
  ConfirmDialog,
  PopoverButton,
  QuestionResponsesTable
} from 'components'
import {
  emissaryTheme
} from 'themes'
import {
  SURVEY_ASSIGNMENT_STATUS_MAP,
  getActionKeysByAssignmentStatus,
  closeDialog
} from 'utils-em'

const SurveyAssignmentDialog = ({ assignmentId, onUpdate }) => {
  const { object: assignment, loaded } = useJsonAPIGetOne(assignmentId, 'surveyAssignments', [
    'survey',
    'advisor',
    'organization'
  ])
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const [updatedAssignmentIds, setUpdatedAssignmentIds] = React.useState([])
  const statusChar = loaded && SURVEY_ASSIGNMENT_STATUS_MAP[assignment.status][0]
  const title = loaded && `${statusChar} ${assignment?.survey.title} - ${assignment?.advisor.fullName}, ${assignment?.organization.name}`

  const handleSave = async (action, quality = null) => {
    const patchData = {
      approve: {
        approvedDate: assignment?.approvedDate || new Date().toISOString(),
        adminDisqualifiedDate: null,
        responseQuality: quality,
        publishedDate: quality !== 'weak' ? new Date().toISOString() : null
      },
      disqualify: { adminDisqualifiedDate: new Date().toISOString(), approvedDate: null, publishedDate: null },
      archive: { archivedDate: new Date().toISOString(), publishedDate: null },
      unarchive: { archivedDate: null, publishedDate: null },
    }
    await upsert({
      type: 'surveyAssignments',
      id: assignmentId,
      ...patchData?.[action]
    })
    onUpdate && onUpdate({ assignmentId })
    setUpdatedAssignmentIds([...updatedAssignmentIds, assignmentId])
    if (action === 'archive' || action === 'unarchive') closeDialog()
  }

  const actionsToShow = getActionKeysByAssignmentStatus(assignment)
  const capitalizedQuality = assignment.responseQuality ? assignment.responseQuality.charAt(0).toUpperCase() + assignment.responseQuality.slice(1) : assignment.responseQuality

  const popoverActions = [
    { key: 'strong', text: 'Strong - Publish', action: () => handleSave('approve', 'strong') },
    { key: 'average', text: 'Average - Publish', action: () => handleSave('approve', 'average') },
    { key: 'weak', text: 'Weak - Approve but not publish', action: () => handleSave('approve', 'weak') }
  ]

  const renderResponseQualitySelector = () => {
    const handleChange = (e) => {
      const selectedKey = e.target.value
      const action = popoverActions.find((a) => a.key === selectedKey)
      action && action.action()
    }

    return (
      <FormControl>
        <InputLabel id="action-select-label">Select quality marker</InputLabel>
        <Select
          defaultValue=""
          onChange={handleChange}
          label="Select Action"
          sx={{ width: 300 }}
        >
          {popoverActions.map((action) => (
            <MenuItem key={action.key} value={action.key}>
              {action.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={emissaryTheme}>
        <ConfirmDialog
          title={title}
          description={(
            <Box sx={{ width: '1000px', minWidth: '1000px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  {assignment?.responseQuality && <Typography sx={{ mt: 1 }}>{`Quality marker: ${capitalizedQuality}`}</Typography>}
                  {!assignment?.responseQuality && assignment.status === 'approved' && renderResponseQualitySelector()}
                  {actionsToShow.includes('approve') && (
                    <PopoverButton
                      buttonText="Approve"
                      actions={popoverActions}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                  )}
                  {actionsToShow.includes('disqualify') && (
                    <Button onClick={() => handleSave('disqualify')} variant="contained" color="error">
                      Disqualify
                    </Button>
                  )}
                  {actionsToShow.includes('archive') && (
                    <Button onClick={() => handleSave('archive')} startIcon={<Archive />}>
                      Archive
                    </Button>
                  )}
                  {actionsToShow.includes('unarchive') && (
                    <Button onClick={() => handleSave('unarchive')}>
                      Unarchive
                    </Button>
                  )}
                </Box>
                <QuestionResponsesTable
                  assignmentId={assignmentId}
                  initialColumns={[
                    'questionId',
                    'questionText',
                    'textAnswer',
                    'actions'
                  ]}
                  initialSortBy="questionId"
                  excludeViewAction
                  dependencies={[updatedAssignmentIds]}
                />
              </Box>
            </Box>
          )}
          cancelName="Close"
          size="xl"
        />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

SurveyAssignmentDialog.defaultProps = {
  onUpdate: null
}

SurveyAssignmentDialog.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
}

export default SurveyAssignmentDialog
