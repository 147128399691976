import React from 'react'
import PropTypes from 'prop-types'
import SanitizeHTML from 'sanitize-html'
import { Typography } from '@mui/material'

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'ol', 'li'],
  allowedAttributes: { a: ['href'] }
}

const sanitize = (dirty, options) => ({
  __html: SanitizeHTML(
    dirty,
    { ...defaultOptions, ...options }
  )
})

const SanitizedHTML = ({ html, options, ...rest }) => (
  <Typography dangerouslySetInnerHTML={sanitize(html, options)} {...rest} />
)

SanitizedHTML.defaultProps = {
  options: defaultOptions
}

SanitizedHTML.propTypes = {
  html: PropTypes.string.isRequired,
  options: PropTypes.object
}

export default SanitizedHTML
