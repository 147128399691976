import { connect } from 'react-redux'
import { Alerts, Dialogs, JsonAPI } from 'store'

import UserDetailDialog from './UserDetailDialog'

const mapDispatchToProps = {
  getCustomerDivisions: (customerId) => JsonAPI.getAll({
    type: 'divisions',
    filter: [{ name: 'customerId', op: 'eq', val: customerId }],
    sort: 'name'
  }),
  createUser: (user, userType) => {
    let orcaUserType = null
    let userTypeIncludes = null
    let userTypeRelations = []

    if (userType === 'customerUser') {
      orcaUserType = 'customerUsers'
      userTypeIncludes = 'divisions,customer'
      userTypeRelations = ['divisions']
    } else if (userType === 'advisor') {
      orcaUserType = 'advisors'
    } else {
      orcaUserType = 'adminUsers'
    }

    return JsonAPI.create(
      {

        ...user,
        sendInvite: true,
        riders: ['sendInvite'],
        type: orcaUserType,
        include: userTypeIncludes,
        relationshipNames: userTypeRelations
      }
    )
  },
  saveUser: (user, userType) => {
    let orcaUserType = null
    let includeModels = null
    let relationshipNames = null

    if (userType === 'customerUser') {
      orcaUserType = 'customerUsers'
      includeModels = 'divisions,customer'
      relationshipNames = ['divisions']
    } else if (userType === 'advisor') {
      orcaUserType = 'advisors'
    } else {
      orcaUserType = 'adminUsers'
    }

    return JsonAPI.save(
      {
        ...user,
        sendInvite: user.sendInvite,
        riders: ['sendInvite'],
        type: orcaUserType,
        include: includeModels,
        relationshipNames
      }
    )
  },
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  closeDialog: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(null, mapDispatchToProps)(UserDetailDialog)
