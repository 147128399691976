import React from 'react'
import PropTypes from 'prop-types'

import { useJsonAPIGetAll } from 'hooks'
import { Selector } from 'components'

const SurveySelector = ({ onChange, initialSurvey, label, error, disabled }) => {
  const { data: surveys, loaded } = useJsonAPIGetAll('surveys', {
    filters: [{ name: 'isArchived', op: 'eq', val: 0 }],
    orderKey: 'selectableSurveys',
    sortBy: '-dateCreated',
  })
  const [selectedSurvey, setSelectedSurvey] = React.useState(null)
  const foundInitialSurvey = surveys?.find((s) => s.id === initialSurvey?.id) || null

  React.useEffect(() => { onChange(selectedSurvey) }, [selectedSurvey])
  React.useEffect(() => { setSelectedSurvey(foundInitialSurvey) }, [foundInitialSurvey])

  return (
    <Selector
      initialOption={selectedSurvey}
      options={surveys}
      onChange={setSelectedSurvey}
      getOptionLabel={(option) => option.title}
      label={label}
      disabled={!loaded || disabled}
      error={error}
    />
  )
}

SurveySelector.defaultProps = {
  initialSurvey: null,
  label: 'Survey',
  error: false,
  disabled: false
}

SurveySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialSurvey: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool
}

export default SurveySelector
