/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useSelector } from 'react-redux'
import { closeAllDialogs, customAlert } from 'utils-em'
import { ErrorBoundary } from 'components'

const ActiveDialogStack = () => {
  const { dialogs } = useSelector((store) => store)

  const onError = () => {
    customAlert('Something went wrong. Please contact support.', true)
    closeAllDialogs()
  }

  return (
    <>
      {dialogs.map((dialog, idx) => (
        <ErrorBoundary key={idx} onError={onError}>
          <div key={idx} style={{ zIndex: idx }}>
            {dialog}
          </div>
        </ErrorBoundary>
      ))}
    </>
  )
}

export default ActiveDialogStack
