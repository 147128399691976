import React from 'react'
import { compose } from 'redux'
import {
  withAuthorization,
  withForcedOnboarding
} from 'enhancers'
import {
  useEmissaryTheme,
  useSessionUser
} from 'hooks'

import {
  Building,
  Globe,
  Heart,
  Phone,
  Settings
} from 'icons'

import { Layout } from 'layouts'
import CustomerRoutes from '../routes'

const CustomerLayout = () => {
  const { isSmOrSmaller } = useEmissaryTheme()
  const { isRep, isOnboarding } = useSessionUser()

  const links = [
    {
      text: isSmOrSmaller ? 'Accounts' : 'Account Insights',
      icon: <Building />,
      path: '/c/search',
      validPaths: ['/c/search', '/c/accounts'],
      visible: true
    },
    {
      text: isSmOrSmaller ? 'Industries' : 'Industry Insights',
      icon: <Globe />,
      path: '/c/industries',
      validPaths: ['/c/industries', '/c/industry'],
      visible: true
    },
    {
      text: 'Call Activity',
      icon: <Phone />,
      path: isRep ? '/c/activity' : '/c/team-activity',
      validPaths: ['/c/activity', '/c/team-activity'],
      visible: isRep
    },
  ]

  const menuItems = [
    { text: 'My Saved Lists', icon: <Heart />, path: '/c/saved-lists' },
    { text: 'My Preferences', icon: <Settings />, path: '/c/preferences' },
  ]

  const visibleLinks = isOnboarding ? [] : links.filter((link) => link.visible)

  return (
    <Layout
      links={visibleLinks}
      menuItems={menuItems}
      hideNotifications={isOnboarding}
      hideMenu={isOnboarding}
      hideFooter={isOnboarding}
    >
      <CustomerRoutes />
    </Layout>
  )
}

const applyHOC = compose(
  withAuthorization,
  withForcedOnboarding
)

export default applyHOC(CustomerLayout)
