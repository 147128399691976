import { lowerCase } from 'lodash'
import parsePhoneNumber from 'libphonenumber-js'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
const linkedInRegex = /linkedin\.com\/(pub|in|profile)\/.+$/i

export function validateEmail (email) {
  return {
    valid: emailRegex.test(email),
    message: 'Please enter a valid email.'
  }
}

export function validateLinkedInURL (linkedInURL) {
  return linkedInRegex.test(linkedInURL)
}

export function validatePassword (password) {
  return {
    valid: password.length > 7,
    message: 'Passwords must be at least 8 characters long'
  }
}

export function validatePasswordStrict (password) {
  const errorMessages = []

  const passwordTooShort = password.length < 8

  if (passwordTooShort) {
    errorMessages.push({
      label: 'length',
      message: 'This password is less than 8 characters long'
    })
  }

  return {
    valid: !passwordTooShort,
    messages: errorMessages
  }
}

/**
 * Validates that form inputs are truthy or pass an alternate validation function
 * @param {object} inputs - key/value pairs of form inputs; if the key is not in
 * alternateValidations or ignoreInputs the value will be tested for truthiness
 * @param {set} ignoreInputs - set of input keys; don't perform any validation on these inputs
 * @param {object} alternateValidations - alternate tests for whether an input is valid
 * the keys should be the input names
 * the values should be functions that take one argument and return a string error
 * message if the value is invalid or falsy if the value is valid
 * Example:
 * const alternateValidations = {
    age: value => !Number.isInteger(value) || value < 0 || value > 125
      ? 'Please enter a number between 0 and 125'
      : null
  }
 * @returns {object} the validation status of the inputs
 * valid - whether all inputs were valid
 * messages - an array of error messages describing what was wrong with invalid inputs
 */
export function validateInputs (inputs, ignoreInputs = new Set([]), alternateValidations = {}) {
  const errorMessages = []
  const emptyFields = []
  Object.entries(inputs).forEach((e) => {
    const key = e[0]
    const val = e[1]
    if (alternateValidations[key]) {
      const errorMessage = alternateValidations[key](val)
      if (errorMessage) {
        errorMessages.push(errorMessage)
      }
    } else if (!ignoreInputs.has(key) && !val) {
      emptyFields.push(lowerCase(key))
    }
  })
  if (emptyFields.length) {
    // don't like this, but it's from here: https://stackoverflow.com/a/16251861
    const joinedEmptyFieldsWithAnd = [
      emptyFields.slice(0, -1).join(', '), // first elements joined with ", "
      emptyFields.slice(-1)[0] // the last element
    ].join(emptyFields.length < 2 ? '' : ' and ') // don't join with and if there were < 2 elements
    errorMessages.push(`Please enter your ${joinedEmptyFieldsWithAnd}.`)
  }
  return {
    valid: errorMessages.length === 0,
    messages: errorMessages
  }
}

export function validateStartDateBeforeEndDate (dateStarted, dateEnded) {
  return {
    valid: dateStarted.isBefore(dateEnded),
    message: 'Please make sure your start date is before your end date.'
  }
}

export function createNumberBetweenValidator (start, end, message) {
  return (val) => (
    !Number.isInteger(val) || val < start || val > end
      ? message
      : null
  )
}

export function validateRequiredPhoneNumber (phoneNumber) {
  if (phoneNumber === '' || phoneNumber === '+') { return true }
  if (!phoneNumber) { return false }
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
  return parsedPhoneNumber && parsedPhoneNumber.isValid()
}
