import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField
} from '@mui/material'

import {
  ConfirmDialog
} from 'components'
import {
  useJsonAPISelectOne
} from 'hooks'
import {
  openDialog,
  closeDialog,
} from 'utils-em'

const PageDialog = ({ knowledgePageId, onSave, onDelete }) => {
  const knowledgePage = knowledgePageId ? useJsonAPISelectOne('knowledgePages', knowledgePageId) : { name: '' }
  const [editKnowledgePage, setEditKnowledgePage] = React.useState(knowledgePage)

  const onSubmit = () => {
    onSave(editKnowledgePage)
    closeDialog()
  }

  const onDeletePage = () => {
    onDelete(editKnowledgePage)
    closeDialog()
  }

  return (
    <Dialog
      open
      maxWidth="sm"
      scroll="paper"
      onClose={closeDialog}
    >
      <DialogTitle>{`${editKnowledgePage.id ? 'Edit' : 'Add new'} page`}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={editKnowledgePage.name}
          onChange={(e) => setEditKnowledgePage({ ...editKnowledgePage, name: e.target.value })}
        />
        {editKnowledgePage.id ? (
          <Link
            variant="buttonL"
            color="error.main"
            onClick={() => openDialog(
              <ConfirmDialog
                size="sm"
                title={`Delete ${editKnowledgePage.name}?`}
                description="Deleting this page will delete all sections it contains. It will not delete the topics within it, but it will disassociate those topics from the sections so they will be homeless."
                actions={[
                  {
                    name: 'Delete',
                    action: onDeletePage,
                    isDelete: true
                  }
                ]}
              />
            )}
            sx={{ display: 'block', mt: 2 }}
          >
            Delete page
          </Link>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={editKnowledgePage?.name?.length === 0}
          onClick={onSubmit}
          sx={{ ml: 2 }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PageDialog.defaultProps = {
  knowledgePageId: null
}

PageDialog.propTypes = {
  knowledgePageId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default PageDialog
