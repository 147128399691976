import React from 'react'
import PropTypes from 'prop-types'

import {
  Building,
  File,
  Headphones,
  Key,
  Microphone,
  Note,
  Video
} from 'icons'

const AssetIcon = ({ assetType, ...rest }) => {
  const getIcon = () => {
    switch (assetType) {
      case 'account-page': return Building
      case 'webinar': return Video
      case 'podcast': return Microphone
      case 'engagement-recording': return Headphones
      case 'engagement-notes': return Note
      case 'engagement-transcript': return Note
      case 'org-buying-profile': return Key
      default: return File
    }
  }

  const Icon = getIcon()

  return <Icon {...rest} />
}

AssetIcon.propTypes = {
  assetType: PropTypes.string.isRequired,
}

export default AssetIcon
