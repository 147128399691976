import { connect } from 'react-redux'
import { push } from 'redux-first-history'
import { buildJsonApiOrdered } from 'utils-em'
import { forEach } from 'lodash'
import { Alerts, Dialogs, JsonAPI } from 'store'

import AccountsList from './AccountsList'

const mapStateToProps = ({ data, router }) => ({
  advisorRequests: buildJsonApiOrdered(data, 'advisorRequests'),
  advisorRequestsCount: data.meta.advisorRequests.count
})

const mapDispatchToProps = {
  loadAdvisorRequests: (customerId, size, page, sort, otherFilters, showArchived) => {
    const filters = [
      {
        name: 'account',
        op: 'has',
        val: {
          name: 'customerId',
          op: 'eq',
          val: customerId
        }
      }
    ]
    if (otherFilters) {
      forEach(otherFilters, (filter, table) => {
        filters.push(filter)
      })
    }

    if (!showArchived) {
      filters.push({
        name: 'status',
        op: 'in_',
        val: ['open', 'pending']
      })
    }

    return JsonAPI.getAll({
      type: 'advisorRequests',
      queryStringParams: {
        include: [
          'account',
          'customerUsers',
          'customerUsers.divisions',
          'tags',
          'account.organization'
        ].join(','),
        'page[number]': page,
        'page[size]': size,
        sort,
        filter: filters
      }
    })
  },
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  goToBulkUploadUnlimited: (customerId) => (dispatch) => dispatch(push(`/admin/customers/${customerId}/upload-accounts-unlimited`)),
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog))
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsList)
