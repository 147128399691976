import React from 'react'
import { SvgIcon } from '@mui/material'

export default function AISection (props) {
  return (
    <SvgIcon {...props}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#FCEEEB" />
        <path d="M15.9981 10.1675C15.9991 9.83421 15.9334 9.50408 15.8049 9.19652C15.6764 8.88897 15.4877 8.61021 15.25 8.37664C15.0122 8.14308 14.7301 7.95942 14.4203 7.83646C14.1105 7.71351 13.7792 7.65375 13.446 7.6607C13.1127 7.66765 12.7843 7.74116 12.4798 7.87691C12.1754 8.01266 11.9012 8.20792 11.6734 8.45119C11.4456 8.69447 11.2687 8.98084 11.1531 9.29348C11.0376 9.60612 10.9857 9.93871 11.0006 10.2717C10.5107 10.3976 10.056 10.6334 9.67076 10.9611C9.28553 11.2888 8.97993 11.6999 8.7771 12.1632C8.57427 12.6265 8.47953 13.1299 8.50006 13.6353C8.52058 14.1406 8.65583 14.6347 8.89557 15.08C8.47405 15.4225 8.14259 15.8627 7.93002 16.3625C7.71744 16.8622 7.6302 17.4063 7.67587 17.9475C7.72154 18.4887 7.89875 19.0105 8.19207 19.4675C8.4854 19.9246 8.88595 20.3031 9.3589 20.57C9.3005 21.0219 9.33535 21.4809 9.46131 21.9188C9.58726 22.3567 9.80165 22.7641 10.0912 23.1158C10.3808 23.4676 10.7394 23.7563 11.1449 23.964C11.5504 24.1717 11.9942 24.2941 12.4489 24.3236C12.9035 24.3531 13.3594 24.2891 13.7884 24.1355C14.2173 23.9819 14.6103 23.742 14.9429 23.4307C15.2755 23.1193 15.5407 22.743 15.7222 22.3251C15.9037 21.9072 15.9976 21.4565 15.9981 21.0009V10.1675Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 10.1675C15.999 9.83421 16.0647 9.50408 16.1932 9.19652C16.3217 8.88897 16.5103 8.61021 16.7481 8.37664C16.9859 8.14308 17.268 7.95942 17.5778 7.83646C17.8876 7.71351 18.2189 7.65375 18.5521 7.6607C18.8853 7.66765 19.2138 7.74116 19.5182 7.87691C19.8226 8.01266 20.0968 8.20792 20.3247 8.45119C20.5525 8.69447 20.7294 8.98084 20.845 9.29348C20.9605 9.60612 21.0124 9.93871 20.9975 10.2717C21.4873 10.3976 21.9421 10.6334 22.3273 10.9611C22.7125 11.2888 23.0181 11.6999 23.221 12.1632C23.4238 12.6265 23.5185 13.1299 23.498 13.6353C23.4775 14.1406 23.3422 14.6347 23.1025 15.08C23.524 15.4225 23.8555 15.8627 24.0681 16.3625C24.2806 16.8622 24.3679 17.4063 24.3222 17.9475C24.2765 18.4887 24.0993 19.0105 23.806 19.4675C23.5127 19.9246 23.1121 20.3031 22.6392 20.57C22.6976 21.0219 22.6627 21.4809 22.5368 21.9188C22.4108 22.3567 22.1964 22.7641 21.9069 23.1158C21.6173 23.4676 21.2587 23.7563 20.8532 23.964C20.4477 24.1717 20.0039 24.2941 19.5492 24.3236C19.0945 24.3531 18.6386 24.2891 18.2097 24.1355C17.7807 23.9819 17.3878 23.742 17.0552 23.4307C16.7226 23.1193 16.4574 22.743 16.2759 22.3251C16.0944 21.9072 16.0005 21.4565 16 21.0009V10.1675Z" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.5 16.8333C17.8004 16.5872 17.1894 16.1392 16.7444 15.5458C16.2994 14.9525 16.0404 14.2406 16 13.5C15.9596 14.2406 15.7006 14.9525 15.2556 15.5458C14.8106 16.1392 14.1996 16.5872 13.5 16.8333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.6641 11.4154C20.8657 11.0659 20.9798 10.6727 20.9966 10.2695" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.0039 10.2695C11.0204 10.6726 11.1342 11.0658 11.3356 11.4154" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.89844 15.08C9.05089 14.9558 9.21402 14.8454 9.38594 14.75" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.6133 14.75C22.7852 14.8454 22.9483 14.9558 23.1008 15.08" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.9985 21.0003C10.4242 21.0006 9.8596 20.8525 9.35938 20.5703" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.6392 20.5703C22.1389 20.8525 21.5743 21.0006 21 21.0003" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
