/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { omit } from 'lodash'

import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Grid,
} from '@mui/material'

import {
  CustomerUserSetEditor,
  OrganizationSelector,
} from 'components'

const sanitizeArForSave = (advisorRequest) => {
  const advisorRequestForSave = { ...omit(advisorRequest, ['account', 'primaryCustomerUserId']) }

  // delete any CU divisions, it's dirty I know I'm not proud
  advisorRequestForSave.customerUsers.forEach((cu) => { delete (cu.divisions) })
  return advisorRequestForSave
}

const EditAccountDialog = (props) => {
  const [advisorRequest, setAdvisorRequest] = useState(props.advisorRequest || {
    customerUsers: [],
    relationshipNames: [],
    account: {
      name: '',
      website: '',
      customerId: props.customerId
    }
  })

  const setStateAccount = (field, value) => {
    setAdvisorRequest((prevAdvisorRequest) => ({
      ...prevAdvisorRequest,
      account: {
        ...prevAdvisorRequest.account,
        [field]: value
      }
    }))
  }

  const setAdvisorRequestState = (field, value) => {
    setAdvisorRequest((prevAdvisorRequest) => ({
      ...prevAdvisorRequest,
      [field]: value
    }))
  }

  const handleAdvisorRequestChange = (name) => (event) => {
    const { value } = event.target
    setAdvisorRequestState(name, value)
  }

  const handleCustomerUsersChange = (customerUsers) => {
    setAdvisorRequest((prevState) => {
      const newAdvisorRequest = {
        ...prevState,
        customerUsers,
        primaryCustomerUserId: customerUsers.find((cu) => cu.isPrimary).id
      }
      return newAdvisorRequest
    })
  }

  const handleSubmit = async () => {
    const accountToSave = { ...advisorRequest.account }
    accountToSave.name = accountToSave.organization.name
    accountToSave.organizationId = accountToSave.organization.id

    try {
      // save account
      const accountRes = await props.saveAccount(accountToSave)
      if (accountRes.error) throw new Error(accountRes.error.message)
      props.showSuccessMessage({ text: 'Account saved!', timeout: 2000 })

      // save advisorRequest
      const advisorRequestForSave = sanitizeArForSave(advisorRequest)
      advisorRequestForSave.accountId = accountRes.data.data.id
      const arRes = await props.saveAdvisorRequest(advisorRequestForSave)
      if (arRes.error) throw new Error(arRes.error.message)
      props.showSuccessMessage({ text: 'Advisor request saved!', timeout: 2000 })
      props.handleClose()
    } catch (error) {
      props.showErrorMessage({ text: `Save account failed. ${error.message}`, timeout: 4000 })
    }
  }

  const { primaryCustomerUserId } = advisorRequest

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm
        onSubmit={handleSubmit}
        onKeyPress={(event) => {
          if (event.key === 'Enter') { event.preventDefault() }
        }}
      >
        <DialogTitle>
          {`${advisorRequest.id ? 'Edit' : 'Create'} Account`}
          {advisorRequest.id ? ` (ID: ${advisorRequest.id})` : null}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrganizationSelector
                organizationId={advisorRequest.account.organizationId}
                handleChange={(newOrg) => setStateAccount('organization', newOrg)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Customer User</FormLabel>
              <CustomerUserSetEditor
                customerUsers={advisorRequest.customerUsers}
                customerId={props.customerId}
                primaryCustomerUserId={parseInt(primaryCustomerUserId, 10)}
                onChange={handleCustomerUsersChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectValidator
                label="Status"
                value={advisorRequest.status || ''}
                onChange={handleAdvisorRequestChange('status')}
                fullWidth
              >
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
              </SelectValidator>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            {advisorRequest.id ? 'Update' : 'Create'}
          </Button>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditAccountDialog.defaultProps = {
  advisorRequest: null,
}

EditAccountDialog.propTypes = {
  advisorRequest: PropTypes.object,
  customerId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveAccount: PropTypes.func.isRequired,
  saveAdvisorRequest: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default EditAccountDialog
