import { connect } from 'react-redux'
import { Alerts, Dialogs, JsonAPI } from 'store'

import EditTeamDialog from './TeamEditDialog'

const mapDispatchToProps = {
  saveDivision: (division) => {
    const method = division.id ? JsonAPI.save : JsonAPI.create

    return method(Object.assign(division, {
      include: 'customerUsers',
      relationshipNames: ['customerUsers'],
      type: 'divisions'
    }))
  },
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  closeDialog: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(null, mapDispatchToProps)(EditTeamDialog)
