import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'

const FormSelect = ({
  form,
  name,
  label,
  disabled,
  required,
  helperText,
  items,
  allowNone,
  ...rest
}) => {
  const { control, register, formState: { errors } } = form

  const formProps = register(name, {
    required,
    validate: (value) => {
      if (required && !value) return 'Required field'
      return true
    }
  })

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <FormControl error={Boolean(errors[name])} {...rest}>
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              value={field.value}
              disabled={disabled}
              inputProps={formProps}
              {...field}
              {...rest}
            >
              {allowNone && <MenuItem value="">None</MenuItem>}
              {items.map(({ key, value, label: itemLabel }) => (
                <MenuItem key={key} value={value}>{itemLabel}</MenuItem>
              ))}
            </Select>
            {Boolean(errors[name]) && <FormHelperText>{errors?.[name]?.message || helperText}</FormHelperText>}
          </FormControl>
        </>
      )}
    />
  )
}

FormSelect.propTypes = {
  form: PropTypes.object.isRequired, // The React Hook Form object
  name: PropTypes.string.isRequired, // The name of the field
  label: PropTypes.string.isRequired, // The label of the field
  disabled: PropTypes.bool, // Whether the field should be disabled
  required: PropTypes.bool, // Whether the field is required
  helperText: PropTypes.string, // Helper text for the field
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string
  })).isRequired,
  allowNone: PropTypes.bool
}

FormSelect.defaultProps = {
  disabled: false,
  required: false,
  helperText: null,
  allowNone: false
}

export default FormSelect
