import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { BadgeChip } from 'components'
import {
  buildJsonApiOne,
  buildJsonApiAll,
  getEnums
} from 'utils-em'
import { JsonAPI } from 'store'

const EngagementNotificationChips = ({ engagementId, loadData, size, type }) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const dispatch = useDispatch()
  const user = useSelector(({ session }) => session)
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, type, engagementId))
  const isValidEngagement = ['proposed', 'active', 'closed'].includes(engagement.state)
  const notifications = useSelector(({ data }) => buildJsonApiAll(data, 'notifications') || [])
  const activeNotifications = notifications.filter((notif) => (
    notif.targetObjectId === engagementId &&
    !notif.isCompleted &&
    notif.userId === user.id
  ))

  const notificationTypes = useSelector(({ enums }) => enums.notificationTypeList)
  const loadNotificationTypes = () => (
    getEnums('notification_types')
  )

  const loadNotifications = () => (
    dispatch(JsonAPI.getAll({
      type: 'notifications',
      queryStringParams: {
        'filter[userId]': user.id,
        'filter[targetObjectId]': engagementId,
        'filter[isCompleted]': false
      }
    }))
  )

  React.useEffect(() => {
    if (loadData) {
      Promise.all([
        loadNotificationTypes(),
        loadNotifications()
      ]).then((_) => setIsLoading(false))
    } else if (isLoading && !notificationTypes) {
      loadNotificationTypes().then(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [])

  if (isLoading || !notificationTypes || !isValidEngagement) {
    return null
  }

  return (
    <>
      {activeNotifications.map((notification) => {
        let color = 'blue'
        if (notification.notificationType === 'proposal-declined') {
          color = 'grey'
        }

        return (
          <BadgeChip
            key={notification.id}
            text={notificationTypes.find((n) => n.value === notification.notificationType).name}
            size={size}
            color={color}
          />
        )
      })}
    </>
  )
}

EngagementNotificationChips.defaultProps = {
  loadData: true,
  size: 'large',
  type: 'engagements'
}

EngagementNotificationChips.propTypes = {
  engagementId: PropTypes.number.isRequired,
  loadData: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string
}

export default EngagementNotificationChips
