import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Share (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M9.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V14.75" stroke="#2B3BFA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.25 9.25V4.75H14.75" stroke="#2B3BFA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 5L11.75 12.25" stroke="#2B3BFA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
