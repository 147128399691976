import React from 'react'
import {
  Box,
  Link,
  Typography
} from '@mui/material'

import { AccountPageContext } from '../AccountPageContext'

const JumpToCoachingSection = () => {
  const { accountPage, setActiveSectionKey, scrollableElement } = React.useContext(AccountPageContext)

  const jumpToCoaching = () => {
    setActiveSectionKey('coaching')
    scrollableElement.current?.scrollTo(0, 0)
    scrollableElement.current?.parentNode?.scrollTo(0, 0)
  }
  if (accountPage.organization.engageableAdvisorCount === 0) return null

  return (
    <Box id="jump-to-coaching-section" sx={{ bgcolor: '#ecf1f3', borderRadius: 2, p: 3, mx: 2.5, position: 'absolute', bottom: 0 }}>
      <Typography variant="bodyBold" sx={{ mb: 1 }}>
        Take full advantage of our advisor network.
      </Typography>
      <Typography variant="caption" sx={{ display: 'block', mb: 1 }}>
        Do you still have questions about this account?
        Get deal coaching with 1:1 calls. Schedule
        time with our expert advisors to gain valuable
        guidance and recommendations for your specific
        selling situation.
      </Typography>
      <Link variant="buttonL" onClick={jumpToCoaching} sx={{ display: 'block' }}>
        View
        {' '}
        {accountPage.organization.name}
        {' '}
        advisors
      </Link>
    </Box>
  )
}

export default JumpToCoachingSection
