import PropTypes from 'prop-types'
import React from 'react'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Switch,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { Spinner } from 'components'
import { Download } from 'icons'

import { useJsonAPIGetAll } from 'hooks'

import {
  closeDialog,
  customAlert,
  downloadFromV1Url,
} from 'utils-em'

const DownloadAccountAssetsDialog = ({ accountName, organizationId }) => {
  const [onlyAcceptedSurveys, setOnlyAcceptedSurveys] = React.useState(true)
  const [isDownloadingFiles, setIsDownloadingFiles] = React.useState(false)
  const [selectedAssetIds, setSelectedAssetIds] = React.useState([])

  const { objects: engagements, loaded: engagementsLoaded } = useJsonAPIGetAll('engagements', {
    include: [
      'advisor',
      'assets',
      'customerUser',
      'customerUser.customer',
      'role'
    ],
    filters: [
      { name: 'organizationId', op: 'eq', val: organizationId || null },
      { name: 'state', op: 'ne', val: 'canceled' },
      { name: 'assets', op: 'any', val: { name: 'engagementId', op: 'ne', val: null } }
    ],
    sortBy: '-startDate'
  })

  const downloadAccountFiles = async (e) => {
    setIsDownloadingFiles(true)
    try {
      await downloadFromV1Url(
        e,
        `export/${organizationId}/all?selectedAssetIds=[${selectedAssetIds.join(',')}]${onlyAcceptedSurveys ? '&onlyAcceptedSurveys' : ''}`,
        `${accountName}.zip`
      )
      closeDialog()
    } catch (error) {
      customAlert(`An error occurred while downloading: ${error.message}. Please contact support.`, true)
    } finally {
      setIsDownloadingFiles(false)
    }
  }

  const handleAssetSelected = (event) => {
    const newSelectedAssetIds = [...selectedAssetIds]
    if (event.target.checked) {
      newSelectedAssetIds.push(event.target.value)
    } else {
      newSelectedAssetIds.splice(newSelectedAssetIds.indexOf(event.target.value), 1)
    }
    setSelectedAssetIds(newSelectedAssetIds)
  }

  const renderEngagementRow = (engagement) => {
    const notesAsset = engagement.assets.find((asset) => asset.assetType === 'engagement-notes')
    const recordingAsset = engagement.assets.find((asset) => asset.assetType === 'engagement-recording')
    const transcriptAsset = engagement.assets.find((asset) => asset.assetType === 'engagement-transcript')

    return (
      <TableRow>
        {/* Engagement, Notes, Recording, Transcript */}
        <TableCell>
          <Typography>{`Engagement #${engagement.id}`}</Typography>
          <Typography variant="caption">{`${engagement.advisor.fullName} on ${engagement.startDate.substring(0, 10)}`}</Typography>
        </TableCell>
        <TableCell>
          {notesAsset && <Checkbox value={notesAsset.id} checked={selectedAssetIds.includes(notesAsset.id)} onChange={handleAssetSelected} />}
        </TableCell>
        <TableCell>
          {recordingAsset && <Checkbox value={recordingAsset.id} checked={selectedAssetIds.includes(recordingAsset.id)} onChange={handleAssetSelected} />}
        </TableCell>
        <TableCell>
          {transcriptAsset && <Checkbox value={transcriptAsset.id} checked={selectedAssetIds.includes(transcriptAsset.id)} onChange={handleAssetSelected} />}
        </TableCell>
      </TableRow>
    )
  }

  const assetIds = {
    'engagement-notes': [],
    'engagement-recording': [],
    'engagement-transcript': []
  }

  engagements.forEach((engagement) => {
    engagement.assets.forEach((asset) => {
      assetIds[asset.assetType]?.push(asset.id)
    })
  })

  const bulkSelectColumn = (assetType, isSelected) => {
    const newSelectedAssetIds = [...selectedAssetIds]
    if (isSelected) {
      assetIds[assetType].forEach((assetId) => {
        if (newSelectedAssetIds.indexOf(assetId) === -1) newSelectedAssetIds.push(assetId)
      })
    } else {
      assetIds[assetType].forEach((assetId) => {
        if (newSelectedAssetIds.indexOf(assetId) !== -1) {
          newSelectedAssetIds.splice(newSelectedAssetIds.indexOf(assetId), 1)
        }
      })
    }
    setSelectedAssetIds(newSelectedAssetIds)
  }

  React.useEffect(() => {
    if (engagementsLoaded) {
      // When dialog is first opened, select all assets for download
      let allAssetIds = []
      engagements.forEach((engagement) => {
        allAssetIds = allAssetIds.concat(engagement.assets.map((asset) => asset.id))
      })
      setSelectedAssetIds(allAssetIds)
    }
  }, [engagementsLoaded])

  if (!organizationId) return null

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        Download account assets
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h6">Surveys</Typography>
          <FormControlLabel
            control={
              <Switch checked={onlyAcceptedSurveys} onChange={(e) => setOnlyAcceptedSurveys(e.target.checked)} />
            }
            label="Include only accepted surveys"
          />
          <Typography variant="body1">
            {`${onlyAcceptedSurveys ? 'Only accepted, non-archived surveys' : 'All surveys (including archived)'} will be included in this download in one CSV file per survey type (account profile, account interview)`}
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Engagement assets</Typography>
          <Typography variant="body1">Select which engagement assets you would like to download below</Typography>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Engagement</TableCell>
                  <TableCell>
                    <Typography>Notes</Typography>
                    <Link href="#" variant="caption" onClick={() => bulkSelectColumn('engagement-notes', true)}>All</Link>
                    {' • '}
                    <Link href="#" variant="caption" onClick={() => bulkSelectColumn('engagement-notes', false)}>None</Link>
                  </TableCell>
                  <TableCell>
                    <Typography>Recording</Typography>
                    <Link href="#" variant="caption" onClick={() => bulkSelectColumn('engagement-recording', true)}>All</Link>
                    {' • '}
                    <Link href="#" variant="caption" onClick={() => bulkSelectColumn('engagement-recording', false)}>None</Link>
                  </TableCell>
                  <TableCell>
                    <Typography>Transcript</Typography>
                    <Link href="#" variant="caption" onClick={() => bulkSelectColumn('engagement-transcript', true)}>All</Link>
                    {' • '}
                    <Link href="#" variant="caption" onClick={() => bulkSelectColumn('engagement-transcript', false)}>None</Link>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {engagementsLoaded ? (engagements.map(renderEngagementRow)) : (<Spinner />)}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          size="large"
          color="primary"
          component="span"
          startIcon={<Download />}
          loading={isDownloadingFiles}
          onClick={downloadAccountFiles}
        >
          Download
        </LoadingButton>
        <Button onClick={closeDialog} color="secondary" sx={{ ml: 1 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DownloadAccountAssetsDialog.propTypes = {
  accountName: PropTypes.string.isRequired,
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default DownloadAccountAssetsDialog
