import React from 'react'
import PropTypes from 'prop-types'

import { InputAdornment, IconButton, TextField } from '@mui/material'
import { Clear, MagnifyingGlass } from 'icons'

const SearchBar = ({ initialValue, valueCallback, onClear, InputProps, ...rest }) => {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => { valueCallback && valueCallback(value) }, [value])
  React.useEffect(() => { initialValue && setValue(initialValue) }, [initialValue])

  return (
    <TextField
      variant="outlined"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => e.stopPropagation()}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlass />
          </InputAdornment>
        ),
        endAdornment: value === '' ? undefined : (
          <InputAdornment position="end">
            <IconButton onClick={() => {
              setValue('')
              onClear && onClear()
            }}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
        style: { padding: '9px', height: '56px' } // default for TextField
      }}
      {...rest}
    />
  )
}

SearchBar.defaultProps = {
  valueCallback: null,
  onClear: null,
  placeholder: 'Search...',
  initialValue: '',
  InputProps: null
}

SearchBar.propTypes = {
  valueCallback: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  InputProps: PropTypes.object,
}

export default SearchBar
