import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Box, Typography } from '@mui/material'
import { Bot, ArrowRight } from 'icons'
import { useFeatureFlag } from 'hooks'
import { navigateTo } from 'utils-em'

const ChatbotBanner = ({ embeddedInKnowledgePage = false, weJustHeardSectionPresent = false }) => {
  const chatbotBeta = useFeatureFlag('chatbot_beta')

  return (
    <>
      {chatbotBeta ? (
        <Box
          onClick={() => navigateTo(embeddedInKnowledgePage ? '#chatbot' : '/c/chatbot')}
          sx={{
            cursor: 'pointer',
            bgcolor: 'brand.paleTeal',
            mt: embeddedInKnowledgePage ? 0 : 5,
            mb: embeddedInKnowledgePage && weJustHeardSectionPresent ? 5 : 0,
            p: 3,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            '&:hover': { bgcolor: 'brand.softTeal' }
          }}
        >
          <Avatar sx={{ width: '40px', height: '40px', bgcolor: 'neutral.white', mr: 2 }}>
            <Bot sx={{ color: 'brand.darkTeal' }} />
          </Avatar>
          <Typography variant="h5">
            Interact with our data.
            {' '}
            <Typography variant="h4" sx={{ display: 'inline' }}>Chat with Emmy</Typography>
            , our Advisor AI (Beta)
          </Typography>
          <Box sx={{ flexGrow: 1 }}>{/* Spacer */}</Box>
          <ArrowRight sx={{ position: 'relative', top: '1px', color: 'primary.main' }} />
        </Box>
      ) : null}
    </>
  )
}

ChatbotBanner.propTypes = {
  embeddedInKnowledgePage: PropTypes.bool,
  weJustHeardSectionPresent: PropTypes.bool
}

export default ChatbotBanner
