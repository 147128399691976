import React from 'react'

import KnowledgePageBuilder from './KnowledgePageBuilder'
import { KnowledgePageBuilderContextProvider } from './KnowledgePageBuilderContext'

const KnowledgePageBuilderContainer = () => (
  <KnowledgePageBuilderContextProvider>
    <KnowledgePageBuilder />
  </KnowledgePageBuilderContextProvider>
)

export default KnowledgePageBuilderContainer
