import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import TreeNode from './TreeNode'

// CSS originally inspired by this example:
// https://www.cssscript.com/clean-tree-diagram/

const styles = {
  treeLevel: {
    display: 'table',
    width: '100%',
    padding: 0,
    position: 'relative',
    margin: '0 0 1em',
    textAlign: 'center',

    '&:before': {
      top: '-1.5em',
      outline: 'solid 0.5px #bbb',
      content: '""',
      height: '1.5em',
      left: '50%',
      position: 'absolute'
    }
  }
}

const TreeLevel = ({ compact, editTeam, divisions, showInactive }) => (
  <Box component="span" sx={{ ...styles.treeLevel }}>
    {divisions.map((rootDiv) => (
      <TreeNode
        key={rootDiv.id}
        compact={compact}
        division={rootDiv}
        editTeam={editTeam}
        showInactive={showInactive}
      />
    ))}
  </Box>
)

TreeLevel.defaultProps = {
  compact: false,
  editTeam: null,
  showInactive: false
}

TreeLevel.propTypes = {
  divisions: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  editTeam: PropTypes.func,
  showInactive: PropTypes.bool
}

export default TreeLevel
