import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'

const StickyTextField = (props) => {
  const { stickyKey, onChange, ...rest } = props
  React.useEffect(() => {
    const storedValue = localStorage.getItem(props.stickyKey)
    if (storedValue) {
      const fakeEvent = {
        currentTarget: {
          value: storedValue
        },
        target: {
          value: storedValue
        }
      }
      props.onChange(fakeEvent, storedValue)
    }
  }, [])

  const newOnChange = (event, value) => {
    localStorage.setItem(props.stickyKey, event.currentTarget.value)
    props.onChange(event, value)
  }
  return (
    <TextField
      {...rest}
      onChange={newOnChange}
    />
  )
}

StickyTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  stickyKey: PropTypes.string.isRequired
}

export default StickyTextField
