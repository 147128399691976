import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  IconButton
} from '@mui/material'

import {
  useEmissaryTheme,
  useSessionUser
} from 'hooks'
import {
  Phone
} from 'icons'
import {
  navigateTo
} from 'utils-em'

const TalkToSomeone = ({ organizationId, isResponsive }) => {
  const { isSmOrSmaller } = useEmissaryTheme()
  const { isUnlimitedByAccount } = useSessionUser()

  const handleClick = (e) => {
    e.currentTarget.blur()
    navigateTo(isUnlimitedByAccount ? `/c/talk-to-someone/${organizationId}` : '#coaching')
  }

  if (isResponsive && isSmOrSmaller) {
    return (
      <IconButton
        onClick={handleClick}
        sx={{ color: 'primary.main', '&:hover': { backgroundColor: 'primary.lightest' } }}
      >
        <Phone />
      </IconButton>
    )
  }

  return (
    <Button variant="contained" onClick={handleClick}>
      Talk to someone
    </Button>
  )
}

TalkToSomeone.defaultProps = {
  isResponsive: false
}

TalkToSomeone.propTypes = {
  organizationId: PropTypes.number.isRequired,
  isResponsive: PropTypes.bool
}

export default TalkToSomeone
