import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Alphabetical (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M4.5 15.332L7.83333 18.6654L11.1667 15.332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.83337 18.6654V5.33203" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.6667 8.66797H14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 10.332V7.41536C14.5 6.86283 14.7195 6.33293 15.1102 5.94223C15.5009 5.55152 16.0308 5.33203 16.5833 5.33203C17.1359 5.33203 17.6658 5.55152 18.0565 5.94223C18.4472 6.33293 18.6667 6.86283 18.6667 7.41536V10.332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 13.668H18.6667L14.5 18.668H18.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
