import PropTypes from 'prop-types'
import React from 'react'
import { startCase } from 'lodash'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography
} from '@mui/material'
import {
  Archive
} from 'icons'
import {
  ConfirmDialog
} from 'components'
import {
  customAlert,
  simpleHttpFetch
} from 'utils-em'

import EditAccountDialog from '../../../Accounts/components/EditAccountDialog'

const CustomerUserAccountsDialog = ({
  loadAdvisorRequests,
  advisorRequests,
  customerUser,
  closeDialog,
  openDialog
}) => {
  const [loaded, setLoaded] = React.useState(false)
  const [archiveDialogOpen, setArchiveDialogOpen] = React.useState(false)
  React.useEffect(() => {
    loadAdvisorRequests(customerUser.id).then((_) => {
      setLoaded(true)
    })
  }, [])
  if (!loaded) { return null }

  const handleArchiveAccounts = async () => {
    const json = await simpleHttpFetch(
      `${__API_HOST__}/v1/advisorRequests/archive`,
      {
        json: true,
        method: 'POST',
        body: JSON.stringify({
          userIds: [customerUser.id]
        }),
        errorMessage: 'Error archiving accounts'
      }
    )
    if (json) {
      customAlert('Accounts archived')
    }
    return json
  }

  const archiveView = (
    <Box>
      <Typography variant="body">
        {`Are you sure you want to archive all accounts saved for ${customerUser.fullName}?`}
      </Typography>
    </Box>
  )

  const tableView = (
    <Box>
      <Button
        onClick={() => setArchiveDialogOpen(true)}
        startIcon={<Archive />}
      >
        Archive all accounts
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {advisorRequests.length === 0 ? <TableRow><TableCell>No Advisor Requests</TableCell></TableRow> : null}
          {advisorRequests.map((advisorRequest) => (
            <TableRow key={advisorRequest.id}>
              <TableCell>
                {advisorRequest.account.name}
              </TableCell>
              <TableCell>
                {startCase(advisorRequest.status)}
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    openDialog(
                      <EditAccountDialog
                        advisorRequestId={advisorRequest.id}
                        customerId={advisorRequest.account.customerId}
                      />, 'editDialog'
                    )
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
  return (
    <ConfirmDialog
      description={archiveDialogOpen ? archiveView : tableView}
      title={archiveDialogOpen ? 'Archive all accounts?' : `${customerUser.fullName}`}
      cancelName={archiveDialogOpen ? 'Cancel' : 'Close'}
      cancelAction={archiveDialogOpen ? () => setArchiveDialogOpen(false) : closeDialog}
      size="lg"
      actions={archiveDialogOpen
        ? [
            {
              name: 'Confirm',
              isDelete: true,
              action: handleArchiveAccounts
            }
          ]
        : []}
    />
  )
}

CustomerUserAccountsDialog.propTypes = {
  advisorRequests: PropTypes.array.isRequired,
  loadAdvisorRequests: PropTypes.func.isRequired,
  customerUser: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default CustomerUserAccountsDialog
