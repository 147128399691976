import React from 'react'
import PropTypes from 'prop-types'

import Autocomplete from '@mui/material/Autocomplete'
import { Checkbox } from '@mui/material'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBox from '@mui/icons-material/CheckBox'

import { SearchBar } from 'components'

import {
  customAlert,
  useDebounce
} from 'utils-em'

const API_HOST = __API_HOST__

const OrganizationSelectorTwo = ({
  textFieldParams,
  noOptionsText,
  allowFreeText,
  multiple,
  disabled,
  clearOnSelected,
  defaultSearchText,
  defaultValue,
  useKnowledgePageOnly,
  onSearchTextUpdated,
  onSearchStatusChange,
  onSearchResults,
  onSelected
}) => {
  const [searchText, setSearchText] = React.useState(defaultSearchText)
  const [availableOrganizations, setAvailableOrganizations] = React.useState([])
  const [selection, setSelection] = React.useState(defaultValue || (multiple ? [] : null))

  const [isSearching, setIsSearching] = React.useState(false)

  const debouncedSearchText = useDebounce(searchText, 500)

  React.useEffect(() => { setSelection(defaultValue || (multiple ? [] : null)) }, [defaultValue])

  React.useEffect(() => {
    onSearchStatusChange(true)
    setIsSearching(true)

    onSearchTextUpdated(debouncedSearchText, availableOrganizations)

    try {
      if (debouncedSearchText && debouncedSearchText.trim()) { // avoid blank text search
        (async () => {
          const params = new URLSearchParams({
            q: encodeURIComponent(debouncedSearchText),
            kp: (useKnowledgePageOnly).toString()
          })
          const res = await fetch(
            `${API_HOST}/v1/search/orgSearch?${params.toString()}`,
            { credentials: 'include', method: 'GET' }
          )
          if (!res.ok) {
            throw new Error('Bad response')
          } else {
            const organizations = await res.json()
            setAvailableOrganizations(organizations)
            onSearchResults(organizations)
          }
        })()
      }
    } catch {
      customAlert('An error occurred searching for organizations', true)
    } finally {
      setIsSearching(false)
      onSearchStatusChange(false)
    }
  }, [debouncedSearchText])

  React.useEffect(() => {
    onSelected(selection)
    if (clearOnSelected && selection) {
      setSelection(null)
      setSearchText('')
    }
  }, [selection])

  React.useEffect(() => {
    // Enforce certain rules that can't be accomplished with prop validation alone
    if (multiple && defaultValue && !Array.isArray(defaultValue)) throw Error('OrganizationSelectorTwo was set to multiple and provided a default value, but the value is not an array')
    if (!multiple && defaultValue && Array.isArray(defaultValue)) throw Error('OrganizationSelectorTwo was provided an array of default values, but is not set to accept multiple values')
    if (allowFreeText && defaultSearchText) throw Error('OrganizationSelectorTwo cannot accept default search text in free text mode. Maybe you meant defaultValue?')
  }, [])

  return (
    <Autocomplete
      freeSolo={allowFreeText}
      multiple={multiple}
      clearIcon={null} // handled in SearchBar
      filterOptions={(item) => item}
      disableCloseOnSelect={multiple}
      options={availableOrganizations}
      getOptionLabel={(organization) => {
        // in `allowFreeText` mode, `organization` can be a string value
        if (typeof organization === 'object') return organization.name
        return organization
      }}
      isOptionEqualToValue={(organization, value) => {
        if (typeof organization === 'object' && typeof value === 'object') return organization.id === value.id
        // We do not have a check for `organization.name === value` when `allowFreeText` mode so if what the user
        // types in perfectly matches an option from the list, it's checked, but per MUI's autocomplete limitations,
        // "Be careful when using the free solo mode with non-string options, as it may cause type mismatch.
        // The value created by typing into the textbox is always a string, regardless of the type of the options."
        return organization === value
      }}
      value={selection}
      onChange={(event, value) => setSelection(value)} // can be an Array or single value, and an Organization or String depending on `multiple` and `allowFreeText`
      inputValue={searchText}
      onInputChange={(event, value) => setSearchText(value)}
      noOptionsText={noOptionsText}
      loading={isSearching}
      disabled={disabled}
      fullWidth
      forcePopupIcon={textFieldParams.variant !== 'outlined'}
      renderOption={(props, organization, { selected }) => (
        <li {...props} key={organization.id}>
          {multiple && (
            <Checkbox
              checked={selected}
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              sx={{ mr: 1 }}
            />
          )}
          {organization.name}
        </li>
      )}
      renderInput={(params) => (
        <SearchBar
          {...params}
          {...textFieldParams}
          initialValue={searchText}
          onClear={() => {
            setSearchText('')
            setSelection(null)
          }}
          disabled={disabled || isSearching}
          fullWidth
          sx={{ mt: 0, bgcolor: 'neutral.white', '&. MuiInputBase-root': { p: 0 } }}
        />
      )}
    />
  )
}

OrganizationSelectorTwo.defaultProps = {
  textFieldParams: {
    variant: 'standard',
    label: 'Organization',
    placeholder: 'Organization',
    helperText: '',
    required: false,
    margin: 'normal'
  },
  noOptionsText: 'Enter organization name',

  allowFreeText: false,
  multiple: false,
  disabled: false,
  clearOnSelected: false,

  defaultSearchText: '',
  defaultValue: null,

  useKnowledgePageOnly: false,

  onSearchTextUpdated: () => {},
  onSearchStatusChange: () => {},
  onSearchResults: () => {},
  onSelected: () => {},
}

OrganizationSelectorTwo.propTypes = {
  // Parameters to override on the <TextField> input box
  // Reference: https://mui.com/material-ui/api/text-field/#props
  textFieldParams: PropTypes.object,

  // Text to display in dropdown when there are no options to select
  noOptionsText: PropTypes.string,

  // Allows user to enter whatever string, without having to select an Organization
  // "... user input is not bound to provided options"
  // Cannot be combined with `defaultSearchText`
  allowFreeText: PropTypes.bool,

  // Whether a user can select multiple organizations
  multiple: PropTypes.bool,

  // Whether to disable the input entirely
  disabled: PropTypes.bool,

  // Allows the search text to be prefilled (i.e. a saved query)
  defaultSearchText: PropTypes.string,

  // clears input on selection
  clearOnSelected: PropTypes.bool,

  // Option(s) already chosen from list
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string), // free text mode
    PropTypes.string // free text mode
  ]),

  // Additional parameters affecting organization search
  useKnowledgePageOnly: PropTypes.bool,

  // Event handlers / listeners
  onSearchTextUpdated: PropTypes.func,
  onSearchStatusChange: PropTypes.func,
  onSearchResults: PropTypes.func,
  onSelected: PropTypes.func,
}

export default OrganizationSelectorTwo
