import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ConfirmDialog } from 'components'
import { customAlert } from 'utils-em'
import { JsonAPI } from 'store'

const ArchiveDivisionDialog = ({ divisionWithHierarchy, onConfirm }) => {
  const dispatch = useDispatch()
  const { isArchived } = divisionWithHierarchy

  const saveDivision = async () => {
    try {
      const res = await dispatch(JsonAPI.save({
        type: 'divisions',
        id: divisionWithHierarchy.id,
        isArchived: !isArchived
      }))
      if (res.error) throw new Error(res.error.message)
    } catch (error) {
      customAlert('Something went wrong. Please contact support.', true)
    }
  }

  const getProps = () => {
    const areAllChildrenArchived = divisionWithHierarchy.children?.every((c) => c.isArchived)
    const isParentArchived = divisionWithHierarchy.parent?.isArchived

    // block archive if children are unarchived
    if (!isArchived && !areAllChildrenArchived) {
      return {
        title: `Cannot archive ${divisionWithHierarchy.name}`,
        description: 'All sub-teams must be archived in order to archive this team.'
      }
    }

    // block unarchive if parent is archived
    if (isArchived && isParentArchived) {
      return {
        title: `Cannot unarchive ${divisionWithHierarchy.name}`,
        description: 'Parent team must be unarchived in order to unarchive this team.'
      }
    }

    return {
      title: `${isArchived ? 'Unarchive' : 'Archive'} ${divisionWithHierarchy.name}`,
      description: `
        ${isArchived ? 'Unarchiving' : 'Archiving'} this team will 
        ${isArchived ? 'add it back to' : 'remove it from'} seller facing drop-downs (including sub-teams). 
        Caution should be exercised for teams under active contracts.  Would you like to continue
        ${isArchived ? 'unarchiving' : 'archiving'}? 
      `,
      actions: [
        {
          name: isArchived ? 'Unarchive' : 'Archive',
          action: onConfirm || saveDivision
        }
      ],
      size: 'sm'
    }
  }
  return (<ConfirmDialog {...getProps()} />
  )
}

ArchiveDivisionDialog.defaultProps = {
  onConfirm: null
}

ArchiveDivisionDialog.propTypes = {
  divisionWithHierarchy: PropTypes.object.isRequired, // must have parent and children props
  onConfirm: PropTypes.func,
}

export default ArchiveDivisionDialog
