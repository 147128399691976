import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography
} from '@mui/material'
import {
  useSessionUser
} from 'hooks'
import {
  Heart
} from 'icons'

const AccountSavedCard = ({ accountSaved, overrideSX = {} }) => {
  const { isUnlimitedByAccount } = useSessionUser()
  return (
    <Box sx={{ bgcolor: '#ecf1f3', borderRadius: 2, p: 3, my: 3, mr: 1.5, ml: 2.5, ...overrideSX }}>
      <Heart />
      <Typography variant="caption" sx={{ display: 'block' }}>
        <Typography variant="captionBold" sx={{ display: 'inline' }}>{accountSaved ? 'This account is saved to your list, so you will' : 'Save this account to' }</Typography>
        &nbsp;get notifications when research is available
        {isUnlimitedByAccount ? '.'
          : (
            <Typography variant="caption">
              &nbsp;or when new advisors come onboard.
              <Typography variant="captionBold" sx={{ display: 'inline' }}> Save an advisor </Typography>
              to your list if you’re not ready to reach out yet.
            </Typography>
            )}
      </Typography>
    </Box>
  )
}
AccountSavedCard.propTypes = {
  accountSaved: PropTypes.string.isRequired,
  overrideSX: PropTypes.object
}

export default AccountSavedCard
