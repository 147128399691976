import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, IconButton } from '@mui/material'
import { openDialog } from 'utils-em'
import { AddTopic } from 'icons'
import QuestionsTopicsDialog from './QuestionsTopicsDialog'
import QuestionExpertiseDialog from './QuestionExpertiseDialog'

const QuestionsTableActions = ({ question, reload, expertiseEnabled }) => {
  const getDialog = () => {
    if (expertiseEnabled) {
      return (
        <QuestionExpertiseDialog reload={reload} question={question} />
      )
    }
    return (
      <QuestionsTopicsDialog reload={reload} question={question} />
    )
  }
  return (
    <Tooltip title={`Edit ${expertiseEnabled ? 'Areas of Expertise' : 'Topics'}`}>
      <IconButton
        color="primary"
        onClick={() => openDialog(getDialog())}
      >
        <AddTopic />
      </IconButton>
    </Tooltip>
  )
}

QuestionsTableActions.defaultProps = {
  expertiseEnabled: false,
}

QuestionsTableActions.propTypes = {
  question: PropTypes.object.isRequired,
  reload: PropTypes.object.isRequired,
  expertiseEnabled: PropTypes.bool,
}

export default QuestionsTableActions
