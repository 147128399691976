import React from 'react'
import { Box } from '@mui/material'

import { AdvisorsList, AdvisorsListSort } from 'components'

import { AccountPageContext } from '../AccountPageContext'

const Coaching = () => {
  const { accountPage } = React.useContext(AccountPageContext)
  const [sort, setSort] = React.useState('-onboarding_completed')

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <AdvisorsListSort defaultSort={sort} onSortChange={setSort} excludedSorts={['company']} />
      </Box>
      <AdvisorsList orgId={accountPage.organization.id} sort={sort} />
    </>
  )
}

export default Coaching
