import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'

const ToggleBox = ({ value, onChange, header, label, switchProps, ...rest }) => (
  <Box {...rest}>
    <Box
      sx={{
        py: 2,
        px: 3,
        bgcolor: 'brand.lightNavy',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: {
          xs: 'flex-start', // left-align in column mode
          sm: 'space-between', // spread items when in row mode
        },
        flexDirection: {
          xs: 'column', // change to column for small screens
          sm: 'row', // row for wider screens
        },
        alignItems: 'center', // center elements in both cases
        gap: 1
      }}
    >
      <Box sx={{ typography: 'bodyBold' }}>
        {header}
      </Box>
      <Box>
        <FormControlLabel
          control={(
            <Switch
              checked={value}
              onChange={onChange}
              color="primary"
              {...switchProps}
            />
          )}
          label={<Typography variant="caption">{label}</Typography>}
          {...switchProps}
        />
      </Box>
    </Box>
  </Box>
)

ToggleBox.defaultProps = {
  switchProps: {},
}

ToggleBox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  switchProps: PropTypes.object,
}

export default ToggleBox
