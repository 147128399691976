import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'

import {
  CardContent,
  Container,
  Card,
  Box,
  Link,
  Typography,
  TextField,
  Button
} from '@mui/material'
import { navigateTo } from 'utils-em'
import { useDispatch } from 'react-redux'

import SessionAPI from '../../../store/SessionAPI'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [resetDone, setResetDone] = React.useState(false)

  const attemptLogin = async () => {
    setIsSubmitting(true)
    const result = await dispatch(SessionAPI.forgot({ email }))
    if (result.error) {
      setError(true)
    } else {
      setResetDone(true)
    }
    setIsSubmitting(false)
  }

  return (
    <Container maxWidth="desktop">
      <Card sx={{ mt: 7, mb: 5 }}>
        <CardContent>
          <ValidatorForm
            onSubmit={attemptLogin}
            sx={{
              backgroundColor: 'neutral.white',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ p: 10, width: '100%' }}>
                <Box typography="h2" sx={{ mb: 3 }}>Request a new password</Box>
                {resetDone
                  ? (
                    <>
                      <Box sx={{ mb: 3 }}>
                        <Box typography="body1" sx={{ display: 'inline' }}>An email has been sent to </Box>
                        <Link>
                          {email}
                        </Link>
                      </Box>
                      <Box>
                        <Button variant="text" onClick={() => navigateTo('/')}>
                          Go back
                        </Button>
                      </Box>
                    </>
                    ) : (
                      <>
                        <Box>
                          <Box typography="body1" sx={{ mb: 3 }}>We will send you an email that contains a link to reset your password</Box>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            name="email"
                            value={email}
                            onChange={(event) => {
                              setError(false)
                              setEmail(event.target.value)
                            }}
                            label="Email"
                          />
                        </Box>
                        {error && (
                          <Typography color="error.main" sx={{ mb: 2 }}>
                            There was an issue sending the email. Please make sure the address is valid and try again.
                          </Typography>
                        )}
                        <Box>
                          <Button type="submit" disabled={!email || isSubmitting} variant="contained" sx={{ display: 'flex', flexGrow: 0, mt: 3 }}>
                            Reset password
                          </Button>
                        </Box>
                      </>
                    )}
              </Box>
            </Box>
          </ValidatorForm>
        </CardContent>
      </Card>
      <Box sx={{ mb: 5 }}>
        <Typography variant="caption" color="neutral.black">
          {'If you have any questions or need help, email '}
          <Link href="mailto:support@emissary.io" rel="noreferrer" target="_blank">
            support@emissary.io
          </Link>
        </Typography>
      </Box>
    </Container>
  )
}

export default ForgotPassword
