import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Skeleton
} from '@mui/material'
import {
  useJsonAPIGetOne
} from 'hooks'
import {
  formatDate,
  formatTime
} from 'utils-em'

const TimeslotProposalRanges = ({ proposalId }) => {
  const { object: proposal, loaded } = useJsonAPIGetOne(
    proposalId,
    'timeslotProposals',
    [],
    { 'lazy[timeslotProposals]': 'timeslotRanges' }
  )

  const formatDayKey = (dayKey) => {
    const dayStr = dayKey.toString()
    const date = new Date(
      dayStr.slice(0, 4),
      dayStr.slice(4, 6) - 1,
      dayStr.slice(6, 8)
    )
    return formatDate(date, { includeDayOfWeek: true, timezone: proposal.tzName })
  }

  if (!loaded) return <Skeleton width="100%" height="200px" display="flex" />

  // eslint-disable-next-line react/prop-types
  const Day = ({ dayKey }) => (
    <Box sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'row' }, gap: 2 }}>
      <Box sx={{ typography: 'bodyBold', minWidth: '180px' }}>
        {formatDayKey(dayKey)}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {proposal.timeslotRanges[dayKey].map((range) => (
          <Box key={range.id} sx={{ typography: 'body1', display: 'flex', gap: 2 }}>
            <Box>{formatTime(range.startTime)}</Box>
            <Box>-</Box>
            <Box>{formatTime(range.endTime)}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {Object.keys(proposal.timeslotRanges).map((dayKey) => (
        <Day key={dayKey} dayKey={dayKey} />
      ))}
    </Box>
  )
}

TimeslotProposalRanges.propTypes = {
  proposalId: PropTypes.string.isRequired
}

export default TimeslotProposalRanges
