/* eslint-disable import/prefer-default-export */
import { customAlert } from './alertUtils'

export const simpleHttpFetch = async (
  url,
  options = {
    json: true,
    errorMessage: 'Something went wrong, please contact support',
  }
) => {
  try {
    const defaultOptions = {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', // You can customize headers based on your needs
      },
    }

    const mergedOptions = {
      ...defaultOptions,
      ...options,
    }

    const res = await fetch(url, mergedOptions)

    if (!res.ok) {
      customAlert(options.errorMessage, true)
    }

    if (options.successMessage) { customAlert(options.successMessage) }
    if (!options.json) return res
    const resp = await res.json()
    return resp
  } catch (error) {
    customAlert(options.errorMessage, true)
  }
}
