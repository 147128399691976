import PropTypes from 'prop-types'
import React from 'react'
import { constants } from 'utils-em'

import APILoaderContainer from '../../../../../containers/APILoaderContainer'
import OrganizationList from '../components/OrganizationList'

const OrganizationEditorLayout = ({ getAllOrgs }) => (
  <APILoaderContainer
    onMount={(done) => {
      getAllOrgs(constants.ADMIN_DEFAULT_ROWS_PER_PAGE, 1, 'name').then(() => { done() })
    }}
  >
    <OrganizationList />
  </APILoaderContainer>
)

OrganizationEditorLayout.propTypes = {
  getAllOrgs: PropTypes.func.isRequired
}

export default OrganizationEditorLayout
