import React from 'react'
import { SvgIcon } from '@mui/material'

export default function AddTopic (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white" fillOpacity="0.01" />
        <path d="M12 10.332V15.332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 12.832H14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.6666 18.6667C19.1087 18.6667 19.5326 18.4911 19.8451 18.1785C20.1577 17.866 20.3333 17.442 20.3333 17V8.66667C20.3333 8.22464 20.1577 7.80072 19.8451 7.48816C19.5326 7.17559 19.1087 7 18.6666 7H12.0833C11.8046 7.00273 11.5296 6.93551 11.2835 6.80448C11.0375 6.67346 10.8283 6.48281 10.675 6.25L9.99996 5.25C9.8482 5.01956 9.6416 4.8304 9.39871 4.6995C9.15581 4.56859 8.88422 4.50005 8.60829 4.5H5.33329C4.89127 4.5 4.46734 4.67559 4.15478 4.98816C3.84222 5.30072 3.66663 5.72464 3.66663 6.16667V17C3.66663 17.442 3.84222 17.866 4.15478 18.1785C4.46734 18.4911 4.89127 18.6667 5.33329 18.6667H18.6666Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
