import PropTypes from 'prop-types'
import React from 'react'
import { get } from 'lodash'

import {
  Box,
  Divider,
  Typography
} from '@mui/material'

const SectionHeaderWithDivider = ({ title, barColor, variant, ...rest }) => (
  <Box {...rest}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Typography variant={variant} color="brand.navy">
        {title}
      </Typography>
      <Divider sx={{
        flex: 1,
        marginBottom: '0px',
        marginLeft: '16px',
        borderColor: (theme) => get(theme.palette, barColor)
      }}
      />
    </Box>
  </Box>
)

SectionHeaderWithDivider.defaultProps = {
  barColor: 'brand.orange',
  variant: 'h4'
}

SectionHeaderWithDivider.propTypes = {
  title: PropTypes.string.isRequired,
  barColor: PropTypes.string,
  variant: PropTypes.string
}

export default SectionHeaderWithDivider
