
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

const UserInviteDialog = ({ user, handleClose, saveAndInvite, showSuccessMessage, showErrorMessage }) => {
  const sendInvite = () => {
    saveAndInvite(user).then((res) => {
      if (!res.ok) {
        showErrorMessage({ text: `Invite failed: ${res.status}`, timeout: 2000 })
      } else {
        handleClose()

        showSuccessMessage({ text: 'Invite Sent!', timeout: 2000 })
      }
    })
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
          Are you sure you want to send an email invite to
          {' '}
          {user.firstName}
          ?
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={sendInvite}>
          Send Invite
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserInviteDialog.propTypes = {
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveAndInvite: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default UserInviteDialog
