import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  TextField
} from '@mui/material'
import {
  useDebounce,
  ACCOUNT_PAGE_DATA_TEMPLATE,
  ACCOUNT_PAGE_TEXT_DATA,
  ACCOUNT_SELLING_TIP_TEMPLATE
} from 'utils-em'
import { merge } from 'lodash'

import { EditAccountPageContext } from '../EditAccountPageContext'

const SellingTip = ({ idx, sellingTip, onChange, onDelete }) => {
  const [title, setTitle] = React.useState(sellingTip.title)
  const [paragraph, setParagraph] = React.useState(sellingTip.paragraph)
  const [quote, setQuote] = React.useState(sellingTip.quote)

  const debouncedTitle = useDebounce(title, 500)
  const debouncedParagraph = useDebounce(paragraph, 500)
  const debouncedQuote = useDebounce(quote, 500)

  const handleChange = (fieldName, value) => {
    if (fieldName === 'title') setTitle(value)
    else if (fieldName === 'paragraph') setParagraph(value)
    else if (fieldName === 'quote') setQuote(value)
  }

  React.useEffect(() => {
    onChange({ title, paragraph, quote })
  }, [debouncedTitle, debouncedParagraph, debouncedQuote])

  return (
    <Box sx={{ mb: 3 }}>
      <Box typography="bodyBold">
        {ACCOUNT_PAGE_TEXT_DATA.sellingTip.title}
        <Button onClick={() => onDelete(idx)}>Delete</Button>
      </Box>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => handleChange('title', e.target.value)}
        fullWidth
        size="small"
        variant="outlined"
        sx={{ mt: 2 }}
      />
      <TextField
        label="Paragraph"
        value={paragraph}
        onChange={(e) => handleChange('paragraph', e.target.value)}
        fullWidth
        size="small"
        variant="outlined"
        multiline
        rows={2}
        sx={{ mt: 2 }}
      />
      <TextField
        label="Quote"
        value={quote}
        onChange={(e) => handleChange('quote', e.target.value)}
        fullWidth
        size="small"
        variant="outlined"
        multiline
        rows={2}
        sx={{ mt: 2 }}
      />
    </Box>
  )
}

SellingTip.propTypes = {
  idx: PropTypes.number.isRequired,
  sellingTip: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

const SellingTipsForm = () => {
  const { data, setData } = React.useContext(EditAccountPageContext)

  // recursive merge existing data with template in case template changes
  const [sellingTips, setSellingTips] = React.useState(merge([], ACCOUNT_PAGE_DATA_TEMPLATE.sellingTips, data?.sellingTips))

  const addSellingTipSection = () => {
    const newSellingTipsArr = []
    if (sellingTips) newSellingTipsArr.push(...sellingTips)
    newSellingTipsArr.push({ ...ACCOUNT_SELLING_TIP_TEMPLATE })
    setSellingTips(newSellingTipsArr)
  }

  const handleTipChange = (idx, { title, paragraph, quote }, deleteMe) => {
    const modifiedSellingTips = [...sellingTips]
    if (deleteMe) {
      modifiedSellingTips.splice(idx, 1)
    } else {
      modifiedSellingTips[idx] = { title, paragraph, quote }
    }
    setSellingTips(modifiedSellingTips)
    setData({
      ...data,
      sellingTips: modifiedSellingTips
    })
  }

  React.useEffect(() => {
    setSellingTips(data?.sellingTips)
  }, [data])

  return (
    <>
      {sellingTips?.map((sellingTip, idx) => (
        <SellingTip
          key={sellingTip.title + sellingTip.paragraph + sellingTip.quote}
          idx={idx}
          sellingTip={sellingTip}
          onChange={({ title, paragraph, quote }) => handleTipChange(idx, { title, paragraph, quote }, false)}
          onDelete={(i) => handleTipChange(i, {}, true)}
        />
      ))}
      <Button onClick={addSellingTipSection}>Add another</Button>
    </>
  )
}

export default SellingTipsForm
