import { connect } from 'react-redux'
import { buildJsonApiOne } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'

import UserInviteDialog from './UserInviteDialog'

const mapStateToProps = ({ data }, ownProps) => ({
  user: buildJsonApiOne(data, ownProps.userType, ownProps.userId)
})

const mapDispatchToProps = {
  saveAndInvite: (user) => JsonAPI.save({
    ...user,
    sendInvite: true,
    riders: ['sendInvite']
  }),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  handleClose: (dialog) => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInviteDialog)
