import React from 'react'
import PropTypes from 'prop-types'
import {
  customAlert,
} from 'utils-em'
import { ChipMultiAutocomplete } from 'components'

const OrganizationMultiSelector = ({ initialSelectedOrgs, selectedOrgsCallback, ...rest }) => {
  const [orgOptions, setOrgOptions] = React.useState([])
  const [searchValue, setSearchValue] = React.useState(null)
  const [selectedOrgs, setSelectedOrgs] = React.useState([...initialSelectedOrgs])

  const selectedOrgIds = selectedOrgs.map((org) => org.id)

  React.useEffect(() => { searchValue && searchOrgs() }, [searchValue])
  React.useEffect(() => { selectedOrgsCallback && selectedOrgsCallback(selectedOrgs) }, [selectedOrgs])

  const searchOrgs = async () => {
    try {
      const res = await fetch(
        `${__API_HOST__}/v1/search/customerOrgSearch?q=${encodeURIComponent(searchValue)}`,
        { credentials: 'include', method: 'GET' }
      )
      if (res.error) throw new Error('Cannot contact network, please contact support')
      const body = await res.json()
      setOrgOptions(body.filter((org) => !selectedOrgIds.includes(org.id)))
    } catch (error) {
      customAlert(error.message, true)
    }
  }
  return (
    <ChipMultiAutocomplete
      options={orgOptions}
      initialSelectedOptions={initialSelectedOrgs}
      selectedOptionsCallback={setSelectedOrgs}
      searchValueCallback={setSearchValue}
      label="Account"
      placeholder="Enter account name"
      getOptionLabel={(org) => org.name}
      variant="standard"
      {...rest}
    />
  )
}

OrganizationMultiSelector.defaultProps = {
  initialSelectedOrgs: []
}

OrganizationMultiSelector.propTypes = {
  selectedOrgsCallback: PropTypes.func.isRequired,
  initialSelectedOrgs: PropTypes.array
}

export default OrganizationMultiSelector
