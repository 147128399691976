import React from 'react'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { emissaryTheme } from 'themes'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box
} from '@mui/material'
import { useJsonAPIUpsert } from 'hooks'
import {
  FormTextField,
  FormCheckbox,
  FormAutocomplete,
  FormDatePickerTwo,
  TagDropdown,
  FormCountrySelector
} from 'components'

import {
  closeDialog
} from 'utils-em'

const AdvisorPrefillDialog = ({ advisorId, refreshUserTable }) => {
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const form = useForm({
    defaultValues: {
      linkedInUrl: '',
      countryOfResidence: '',
      organization: '',
      startDate: null,
      endDate: null,
      isCurrent: false,
      title: '',
      functionOfRole: '',
    }
  })
  const [startDate, endDate, isCurrent] = form.watch(['startDate', 'endDate', 'isCurrent'])
  const onSubmit = async (data) => {
    const { linkedInUrl, countryOfResidence, ...rest } = data
    await upsert({
      type: 'advisors',
      id: advisorId,
      linkedinUrl: linkedInUrl,
      location: data.countryOfResidence,
      onboardingData: rest,
      riders: ['onboardingData']
    })
    refreshUserTable()
    closeDialog()
  }

  const close = () => {
    refreshUserTable()
    closeDialog()
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={emissaryTheme}>
        <Dialog
          open
          onClose={close}
          size="xl"
        >
          <DialogTitle>Pre-fill advisor info</DialogTitle>
          <DialogContent sx={{ minWidth: '600px', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ padding: 2 }} component="form" onSubmit={form.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    name="linkedInUrl"
                    label="LinkedIn URL"
                    form={form}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCountrySelector
                    id="countryOfResidence"
                    name="countryOfResidence"
                    label="Country of residence"
                    onChange={(option) => form.setValue('countryOfResidence', option?.code)}
                    required
                    form={form}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    required
                    form={form}
                    type="organizations"
                    name="organization"
                    label="Organization"
                    queryStringParams={{
                      'fields[organizations]': 'id,name'
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormDatePickerTwo
                    required
                    form={form}
                    name="startDate"
                    label="Start date at company"
                    maxDate={moment(endDate || new Date())}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormDatePickerTwo
                    required={!isCurrent}
                    disabled={isCurrent}
                    form={form}
                    name="endDate"
                    label="End date at company"
                    minDate={moment(startDate)}
                    maxDate={moment(new Date())}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckbox
                    form={form}
                    name="isCurrent"
                    label="Is current"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    required
                    form={form}
                    name="title"
                    label="Title"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TagDropdown
                    multiple
                    multipleLimit={2}
                    multipleLimitError="Please select up to two tags"
                    tagCategory="function-of-role"
                    label="Function of role"
                    onChange={(value) => form.setValue('functionOfRole', value.map((tag) => tag.id))}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button variant="text" onClick={close}>Cancel</Button>
                <Button variant="text" type="submit">Save</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

AdvisorPrefillDialog.propTypes = {
  advisorId: PropTypes.string.isRequired,
  refreshUserTable: PropTypes.func.isRequired
}

export default AdvisorPrefillDialog
