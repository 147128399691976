import React, { lazy } from 'react'
import { Route, Switch } from 'react-router'
import {
  ScrollToTop,
  LazyLoadWithError
} from 'components'
import {
  useTermsDialogInitiator,
  useUpcomingEngagementDialogInitiator,
  useFeatureFlag
} from 'hooks'
import { useLocation } from 'react-router-dom'

const Home = lazy(() => import('./Home'))
const Onboarding = lazy(() => import('./Onboarding'))
const Profile = lazy(() => import('./Profile'))
const EditProfile = lazy(() => import('./EditProfile'))
const NotFound = lazy(() => import('../../../components/NotFound'))
const AdvisorEngagement = lazy(() => import('./AdvisorEngagement'))
const AdvisorFeedback = lazy(() => import('./AdvisorFeedback'))
const EngagementDetail = lazy(() => import('./EngagementDetail'))
const MyActivity = lazy(() => import('./MyActivity'))
const Survey = lazy(() => import('./Survey')) // needed to redirect to SurveyAssignment from old links
const SurveyAssignment = lazy(() => import('./SurveyAssignment'))
const PaymentInfo = lazy(() => import('./PaymentInfo'))
const Opportunity = lazy(() => import('./Opportunity'))

export default () => {
  const { key } = useLocation() // key prop should be used for all routes with params
  useUpcomingEngagementDialogInitiator()
  useTermsDialogInitiator()

  const engagementEnhancementsEnabled = useFeatureFlag('engagement_enhancements')
  const engagementComponent = (() => {
    if (engagementEnhancementsEnabled) {
      return AdvisorEngagement
    }
    return EngagementDetail
  })()

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/a" render={() => LazyLoadWithError(Home)} />
        <Route exact path="/a/onboarding" render={() => LazyLoadWithError(Onboarding)} />
        <Route exact path="/a/profile" render={() => LazyLoadWithError(Profile)} />
        <Route exact path="/a/profile/edit" render={() => LazyLoadWithError(EditProfile)} />
        <Route exact path="/a/activity" render={() => LazyLoadWithError(MyActivity)} />
        <Route exact path="/a/engagements/:engagementId" render={() => LazyLoadWithError(engagementComponent)} key={key} />
        <Route exact path="/a/engagements/:engagementId/post-call-feedback" render={() => LazyLoadWithError(AdvisorFeedback)} key={key} />
        <Route exact path="/a/survey/:surveyId" render={() => LazyLoadWithError(Survey)} key={key} />
        <Route exact path="/a/survey-assignment/:surveyAssignmentId" render={() => LazyLoadWithError(SurveyAssignment)} key={key} />
        <Route exact path="/a/payment-info" render={() => LazyLoadWithError(PaymentInfo)} />
        <Route exact path="/a/opportunity/:opportunityId" render={() => LazyLoadWithError(Opportunity)} key={key} />
        <Route component={() => LazyLoadWithError(NotFound)} />
      </Switch>
    </>
  )
}
