import React from 'react'
import { SvgIcon } from '@mui/material'

export default function Lightbulb (props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2501 18V16.0512C14.2501 15.7081 14.4299 15.3932 14.7037 15.1864C18.9281 11.9945 17.801 4.75 12 4.75C6.19918 4.75 5.0718 11.9945 9.2964 15.1864C9.57017 15.3932 9.74999 15.7081 9.74999 16.0512V18C9.74999 18.6627 10.2872 19.2 10.95 19.2H13.0501C13.7128 19.2 14.2501 18.6627 14.2501 18Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 16.8008H10" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
