import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { buildJsonApiOne } from 'utils-em'

import {
  Box
} from '@mui/material'
import { SidebarText } from 'components'

const CustomerInfoSidebar = ({ customerId }) => {
  const customer = useSelector(({ data }) => buildJsonApiOne(data, 'customers', customerId))

  return (
    <Box sx={{
      width: '31%',
      display: 'block',
      p: 3,
      pr: 6,
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: 'neutral.lightGrey',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: 'neutral.lightGrey',
    }}
    >
      {customer.description && (
        <>
          <SidebarText header sx={{ mt: 0 }}>
            About&nbsp;
            {customer.name}
          </SidebarText>
          <SidebarText>
            {customer.description}
          </SidebarText>
        </>
      )}
    </Box>
  )
}

CustomerInfoSidebar.propTypes = {
  customerId: PropTypes.number.isRequired
}

export default CustomerInfoSidebar
