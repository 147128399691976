// see https://github.com/reduxjs/redux/blob/master/docs/recipes/structuring-reducers/ImmutableUpdatePatterns.md#updating-an-item-in-an-array
// for the inspiration for the below function
export function removeItem (array, index) {
  const newArray = array.slice()
  newArray.splice(index, 1)
  return newArray
}

export function updateItem (array, indexToUpdate, updatedItem) {
  const newArray = array.slice()
  newArray.splice(indexToUpdate, 1, updatedItem)
  return newArray
}

export function insertItem (array, indexToInsert, newItem) {
  const newArray = array.slice()
  newArray.splice(indexToInsert, 0, newItem)
  return newArray
}
