import React from 'react'
import { useSelector } from 'react-redux'
import {
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material'

import {
  buildJsonApiOrdered,
  closeDialog,
  copyToClipboard,
  customAlert,
  formatTime,
  getEngagementMostRelevantTimeslot,
  isEngagementCallToday,
  sortEngagementsByEarliestCallTime,
  navigateTo
} from 'utils-em'

const UpcomingEngagementDialog = () => {
  const userId = useSelector(({ session }) => session.id)
  const engagementsOccurringToday = useSelector(({ data }) => buildJsonApiOrdered(data, 'engagements', 'displayOrderIds', 'engagementsWithCallsOccurringToday'))
  const hasMultipleCallsToday = engagementsOccurringToday.length > 1

  if (!engagementsOccurringToday.length) return null
  const engagements = sortEngagementsByEarliestCallTime(engagementsOccurringToday)

  const callTitleText = (engagement) => {
    const customerName = engagement.customerUser.customer?.name
    const organizationName = engagement.organization.name

    return `Emissary/${customerName} and ${engagement.advisor.firstName} on ${organizationName}`
  }

  const yourCallText = (engagement, includeLink = false) => {
    const otherPartyName = engagement.advisorId === userId ? engagement.customerUser.firstName : engagement.advisor.firstName
    const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
    return (
      <>
        <Typography sx={{ display: 'inline' }}>
          {`Your call with ${otherPartyName} is ${isEngagementCallToday(engagement) ? 'today' : 'tomorrow'} at ${formatTime(relevantTimeslot.startTime, { showTimezoneSuffix: true })}.`}
        </Typography>
        {includeLink && (
          <>
            {' '}
            <Link
              variant="bodyBold"
              onClick={() => {
                navigateTo(getEngagementLink(engagement))
                closeDialog()
              }}
              underline="hover"
            >
              Go to engagement
            </Link>
          </>
        )}
      </>
    )
  }

  const copyZoomText = (engagement) => (
    <>
      <Typography>Copy Zoom meeting link to clipboard:</Typography>
      <Link
        onClick={(e) => {
          e.preventDefault()
          copyToClipboard(engagement.zoomMeetingJoinLink, () => customAlert('Copied Zoom link to clipboard!'))
        }}
        underline="hover"
      >
        {engagement.zoomMeetingJoinLink}
      </Link>
      {` (ID: ${engagement.zoomMeetingId})`}
    </>
  )

  const getEngagementLink = (engagement) => {
    const isAdvisor = engagement.advisorId === userId
    return `/${isAdvisor ? 'a' : 'c'}/engagements/${engagement.id}`
  }

  const title = hasMultipleCallsToday ? 'Upcoming calls' : `Upcoming call: ${callTitleText(engagements[0])}`

  return (
    <Dialog
      open
      onClose={closeDialog}
      minWidth="730px"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {hasMultipleCallsToday && (
          engagements.map((e) => (
            <>
              <Typography variant="h4">{callTitleText(e)}</Typography>
              <Typography variant="bodyBold" sx={{ mt: 2 }}>{yourCallText(e, true)}</Typography>
              <Typography variant="body1" color="neutral.black" sx={{ mt: 3 }} component="span">
                {copyZoomText(e)}
              </Typography>
            </>
          )).reduce((prev, curr) => [prev, (<Divider sx={{ mt: 3, mb: 3 }} />), curr])
        )}
        {!hasMultipleCallsToday && (
          <>
            <Typography variant="bodyBold">{yourCallText(engagements[0])}</Typography>
            <Typography variant="body1" color="neutral.black" sx={{ mt: 3 }} component="span">
              {copyZoomText(engagements[0])}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>Close</Button>
        {!hasMultipleCallsToday && (
          <Button
            variant="contained"
            onClick={() => {
              navigateTo(getEngagementLink(engagements[0]))
              closeDialog()
            }}
          >
            Go to engagement
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default UpcomingEngagementDialog
