import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Link,
  Typography
} from '@mui/material'
import {
  useEngagement
} from 'hooks'
import {
  Lightbulb,
  Mail
} from 'icons'
import {
  copyToClipboardWithAlert,
  navigateTo
} from 'utils-em'

const FinalizeEngagementSection = ({ engagementId }) => {
  const {
    isCustomerUser,
    relayAddress,
    advisorId
  } = useEngagement(engagementId)

  const RelayLink = () => (
    <Link onClick={() => copyToClipboardWithAlert(relayAddress)} sx={{ color: 'neutral.black' }}>
      {relayAddress}
    </Link>
  )

  const bullets = isCustomerUser ? (
    [ // cu bullets
      {
        Icon: Mail,
        text: (
          <>
            Follow up after the call using the custom email address between you and your advisor:&nbsp;
            <RelayLink />
            .
          </>
        )
      },
      {
        Icon: Lightbulb,
        text: (
          <>
            Act on the next steps you established with the advisor - they may have more to offer than what fit into your 60 minute call. If you’re ready,&nbsp;
            <Link onClick={() => navigateTo(`/c/advisor/${advisorId}/propose-call`)}>propose another call</Link>
            .
          </>
        )
      },
    ]
  ) : (
    [ // au bullets
      {
        Icon: Mail,
        text: (
          <>
            Follow up after the call using the custom email address between you and your client:&nbsp;
            <RelayLink />
            .
          </>
        )
      }
    ]
  )

  // eslint-disable-next-line react/prop-types
  const Bullet = ({ Icon, text }) => (
    <Box key={text} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
      <Icon sx={{ color: 'brand.darkTeal' }} />
      <Typography>{text}</Typography>
    </Box>
  )

  return (
    <>
      <Typography variant="h3">Finalize your engagement:</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {bullets.map((bullet) => (
          <Bullet {...bullet} />
        ))}
      </Box>
    </>
  )
}

FinalizeEngagementSection.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default FinalizeEngagementSection
