import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import { FormTextField } from 'components'
import {
  useJsonAPISelectOne,
  useJsonAPIUpsert
} from 'hooks'
import {
  closeDialog,
  validateLinkedInURL
} from 'utils-em'

const SuggestAnAdvisorDialog = ({ organizationId }) => {
  const form = useForm({
    defaultValues: {
      linkedInUrl: '',
      additionalInformation: ''
    }
  })
  const { upsert, isSuccess } = useJsonAPIUpsert({
    successMessage: 'Success! Thank you for submitting.'
  })

  const organization = useJsonAPISelectOne('organizations', organizationId)
  const userId = useSelector(({ session }) => session.id)

  React.useEffect(() => {
    if (!isSuccess) return
    closeDialog()
  }, [isSuccess])

  const suggestAdvisor = async (data) => {
    await upsert({
      type: 'userMessages',
      userId,
      messageType: 'suggest-an-advisor',
      messageText: data.additionalInformation,
      payload: JSON.stringify({ linkedInUrl: data.linkedInUrl })
    })
  }

  return (
    <Dialog
      open
      onClose={closeDialog}
      maxWidth="md"
    >
      <DialogTitle>Suggest an advisor</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="neutral.black">
          Please provide their LinkedIn profile, plus any other information you have about this executive from&nbsp;
          <b>{organization.name}</b>
          .
        </Typography>
        <FormTextField
          form={form}
          label="LinkedIn URL"
          name="linkedInUrl"
          required
          validator={validateLinkedInURL}
          invalidText="Please enter a valid LinkedIn URL"
          sx={{ mt: 2 }}
        />
        <FormTextField
          form={form}
          label="Additional Information"
          name="additionalInformation"
          fullWidth
          multiline
          rows={3}
          sx={{ mt: 2 }}
        />
        <Typography variant="caption" color="neutral.disabled">
          Contact (phone, email) or context about this person.
        </Typography>
        <Typography variant="caption" sx={{ mt: 2, display: 'block' }}>
          If you&#39;ve saved this account to your profile and we successfully recruit this person, you&#39;ll get a notification.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>Cancel</Button>
        <Button variant="contained" onClick={form.handleSubmit(suggestAdvisor)}>Send</Button>
      </DialogActions>
    </Dialog>
  )
}

SuggestAnAdvisorDialog.propTypes = {
  organizationId: PropTypes.string.isRequired
}

export default SuggestAnAdvisorDialog
