import PropTypes from 'prop-types'
import React from 'react'
import { debounce } from 'lodash'

import {
  TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

const UserAutocomplete = ({
  customerId,
  label,
  onChange,
  initUsers,
  users,
  userSearch,
  roleType,
  multiple,
  helperText
}) => {
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false)
  const [selectedUsers, setSelectedUsers] = React.useState(initUsers)

  React.useEffect(() => {
    setSelectedUsers(initUsers)
  }, [initUsers])
  React.useEffect(() => { userSearch('', customerId, roleType) }, [])

  const debouncedUserSearch = debounce(userSearch, 150)

  return (
    <Autocomplete
      multiple={multiple}
      id={`${label}-multiselect`}
      open={autocompleteOpen}
      onOpen={() => setAutocompleteOpen(true)}
      onInputChange={(event, text) => {
        debouncedUserSearch(text, customerId, roleType)
      }}
      onChange={(event, newValue) => {
        onChange(event, newValue)
      }}
      onClose={() => setAutocompleteOpen(false)}
      getOptionLabel={(option) => option.fullName || ''}
      isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}
      options={users}
      value={multiple ? selectedUsers : selectedUsers[0]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
          helperText={helperText}
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

UserAutocomplete.defaultProps = {
  customerId: null,
  initUsers: [],
  roleType: null,
  multiple: true,
  label: '',
  helperText: ''
}

UserAutocomplete.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initUsers: PropTypes.array,
  customerId: PropTypes.number,
  roleType: PropTypes.string,
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  users: PropTypes.array.isRequired,
  userSearch: PropTypes.func.isRequired
}

export default UserAutocomplete
