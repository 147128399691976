import PropTypes from 'prop-types'
import React from 'react'
import {
  getAdvisorMostRecentRelevantRole,
  sortActiveCompaniesByRecency
} from 'utils-em'
import { useJsonAPIGetOne } from 'hooks'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import AdvisorActionsCard from './AdvisorActionsCard'
import AdvisorActivityCard from './AdvisorActivityCard'
import AdvisorAdminNotesCard from './AdvisorAdminNotesCard'
import AdvisorAtAGlance from './AdvisorAtAGlance'
import AdvisorDeliverablesCard from './AdvisorDeliverablesCard'
import AdvisorExperienceInfo from './AdvisorExperienceInfo'
import AdvisorExperienceCard from '../AdvisorEditing/AdvisorExperienceCard'
import AdvisorExpertiseCard from './AdvisorExpertiseCard'
import AdminAccessControl from '../AdminAccessControl'

const AdvisorProfile = ({ advisorId }) => {
  const { object: advisor } = useJsonAPIGetOne(advisorId, 'advisors', ['companies', 'companies.roles', 'companies.roles.tags', 'userActions', 'tags'])

  if (!advisor || !advisor.companies || !advisor.tags) { return null }

  const mostRecentCompany = sortActiveCompaniesByRecency(advisor.companies)[0]
  if (!mostRecentCompany) {
    return (
      <Paper sx={{ mt: '0.5em', p: '1em' }}>
        Must have at least one company and role
      </Paper>
    )
  }

  const companyToShow = mostRecentCompany
  const mostRecentRole = getAdvisorMostRecentRelevantRole(advisor)

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AdvisorExperienceInfo
          advisor={advisor}
          companyProposed={companyToShow}
          role={mostRecentRole}
        />
      </Grid>
      <Grid item xs={6}>
        <AdvisorAtAGlance
          advisor={advisor}
          companyProposed={companyToShow}
          role={mostRecentRole}
        />
      </Grid>
      <AdminAccessControl>
        <Grid item xs={8}>
          <AdvisorActivityCard advisor={advisor} />
        </Grid>
        <Grid item xs={4}>
          <AdvisorAdminNotesCard advisor={advisor} />
        </Grid>
        <Grid item xs={12}>
          <AdvisorDeliverablesCard advisor={advisor} />
        </Grid>
        <Grid item xs={12}>
          <AdvisorActionsCard advisor={advisor} />
        </Grid>
      </AdminAccessControl>
      <Grid item xs={12}>
        <Paper sx={{ mt: '0.5em', p: '1em' }}>
          <AdvisorExperienceCard
            companies={advisor.companies || []}
            advisorId={parseInt(advisor.id, 10)}
            unnestedRoleId={(mostRecentRole || {}).id}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <AdvisorExpertiseCard
          advisor={advisor}
        />
      </Grid>
    </Grid>
  )
}

AdvisorProfile.propTypes = {
  advisorId: PropTypes.number.isRequired
}

export default AdvisorProfile
