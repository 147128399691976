import React from 'react'

import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { JsonApiDataGrid, ConfirmDialog } from 'components'
import { useJsonAPIUpsert } from 'hooks'
import { Archive, Pencil } from 'icons'
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined'
import {
  formatDate,
  openDialog
} from 'utils-em'
import CreateEditPromptDialog from './CreateEditPromptDialog'
import ChatbotDialog from './ChatbotDialog'

const ManagePrompts = () => {
  const [reload, setReload] = React.useState('')
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const columns = [
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      width: 150,
      type: 'date',
      valueFormatter: ({ value }) => formatDate(value, { includeYear: true })
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'model',
      type: 'string',
      headerName: 'Model',
      width: 300,
    },
    {
      field: 'temperature',
      type: 'string',
      headerName: 'Temperature',
      hidden: true,
    },
    {
      field: 'maxTokens',
      type: 'string',
      headerName: 'Max Tokens',
      hidden: true,
    },
    {
      field: 'topP',
      type: 'string',
      headerName: 'Top P',
      hidden: true,
    },
    {
      field: 'frequencyPenalty',
      type: 'string',
      headerName: 'Frequency Penalty',
      hidden: true,
    },
    {
      field: 'presencePenalty',
      type: 'string',
      headerName: 'Presence Penalty',
      hidden: true,
    },
    {
      field: 'promptText',
      type: 'string',
      headerName: 'Prompt Text',
      hidden: true,
    },
    {
      field: 'useForChatbot',
      type: 'string',
      headerName: 'Use for Chatbot',
      width: 200,
    },
    {
      field: 'dataStartDate',
      type: 'date',
      headerName: 'Data Start Date',
      flex: 1,
      hidden: true,
      valueFormatter: ({ value }) => formatDate(value, { includeYear: true })
    },
    {
      field: 'dataEndDate',
      type: 'date',
      headerName: 'Data End Date',
      hidden: true,
      valueFormatter: ({ value }) => formatDate(value, { includeYear: true })
    },
    {
      field: 'archivedDate',
      type: 'date',
      headerName: 'Archived Date',
      hidden: true,
      valueFormatter: ({ value }) => formatDate(value, { includeYear: true })
    },
    {
      fieldName: 'actions',
      field: 'actions',
      headerName: 'Actions',
      actions: true,
      disablesSort: true,
      renderCell: ({ row }) => (
        <Box
          sx={{ display: 'flex' }}
        >
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => openDialog(<CreateEditPromptDialog prompt={row} />)}
            >
              <Pencil />
            </IconButton>
          </Tooltip>
          <Tooltip title={row.archivedDate === null ? 'Archive' : 'Restore'}>
            <IconButton
              aria-label="Archive/Restore"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => openDialog(
                <ConfirmDialog
                  description="Are you sure you want to archive this prompt?"
                  actions={[
                    {
                      name: 'Archive',
                      action: async () => {
                        await upsert({
                          type: 'prompts',
                          id: row.id,
                          archivedDate: new Date().toISOString(),
                          useForChatbot: false,
                        })
                        setReload(new Date())
                      }
                    }
                  ]}
                />
              )}
            >
              <Archive />
            </IconButton>
          </Tooltip>
          <Tooltip title="Test prompt with chatbot">
            <IconButton
              aria-label="Test prompt with chatbot"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => openDialog(<ChatbotDialog prompt={row} />)}
            >
              <SmartToyOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      width: 150,
    },
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Button variant="contained" sx={{ mb: 2 }} onClick={() => openDialog(<CreateEditPromptDialog />)}>
          New prompt
        </Button>
      </Box>
      <Box>
        <JsonApiDataGrid
          disableExport
          type="prompts"
          columns={columns}
          jsonApiOptions={{
            sortBy: '-dateCreated',
            filters: [{ name: 'archivedDate', op: 'is', val: null }]
          }}
          mode="server"
          density="compact"
          dependencies={[reload]}
        />
      </Box>
    </Box>
  )
}

export default ManagePrompts
