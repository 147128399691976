import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Grid } from '@mui/material'

import { TimezoneSelector } from 'components'

import { validatePasswordStrict, validateRequiredPhoneNumber } from 'utils-em'
import PhoneNumberValidator from '../PhoneNumberValidator'

const EditCustomerUser = ({ customerUser, updateCustomerUser, updateCUPhoneNumber, includeSetPassword }) => {
  React.useEffect(() => {
    ValidatorForm.addValidationRule('validPassword', (newValue) => (
      validatePasswordStrict(newValue).valid
    ))
    ValidatorForm.addValidationRule('validRequiredPhoneNumber', validateRequiredPhoneNumber)
  }, [])

  return (
    <Grid container spacing={3} justifyContent="space-around">
      <Grid item xs={5}>
        <TextValidator
          fullWidth
          label="First Name *"
          validators={['required']}
          errorMessages={['Required Field']}
          value={customerUser.firstName || ''}
          onChange={updateCustomerUser('firstName')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={5}>
        <TextValidator
          fullWidth
          label="Last Name *"
          validators={['required']}
          errorMessages={['Required Field']}
          value={customerUser.lastName || ''}
          onChange={updateCustomerUser('lastName')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={5}>
        <TextValidator
          fullWidth
          label="Email *"
          validators={['required']}
          errorMessages={['Required Field']}
          value={customerUser.email || ''}
          onChange={updateCustomerUser('email')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={5}>
        <TextValidator
          fullWidth
          label="Job Title"
          value={customerUser.jobTitle || ''}
          onChange={updateCustomerUser('jobTitle')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={5}>
        <TextValidator
          fullWidth
          label="Location"
          value={customerUser.location || ''}
          onChange={updateCustomerUser('location')}
          margin="normal"
        />
      </Grid>
      <Grid item xs={5}>
        <TimezoneSelector
          initialTimezone={customerUser.timezone || moment.tz.guess()}
          onChange={updateCustomerUser('timezone')}
        />
      </Grid>
      <Grid item xs={5}>
        <PhoneNumberValidator
          fullWidth
          defaultCountry="us"
          label="Mobile Phone *"
          validators={['validRequiredPhoneNumber']}
          errorMessages={['Required Field', 'Enter a valid phone number']}
          helperText="So we can occasionally text helpful engagement related reminders"
          value={customerUser.phoneNumber || ''}
          onChange={updateCUPhoneNumber}
          margin="normal"
        />
      </Grid>
      {includeSetPassword
        ? (
          <Grid item xs={5}>
            <TextValidator
              fullWidth
              type="password"
              label="Create Password *"
              helperText="Must be at least 8 characters and not easy to guess, like password"
              validators={['required', 'validPassword']}
              errorMessages={['Required Field', 'Must be at least 8 characters and not easy to guess, like password']}
              value={customerUser.password || ''}
              onChange={updateCustomerUser('password')}
              margin="normal"
            />
          </Grid>
          )
        : <Grid item xs={5} />}
    </Grid>
  )
}

EditCustomerUser.defaultProps = {
  customerUser: null,
  includeSetPassword: false
}

EditCustomerUser.propTypes = {
  customerUser: PropTypes.object,
  includeSetPassword: PropTypes.bool,
  updateCustomerUser: PropTypes.func.isRequired,
  updateCUPhoneNumber: PropTypes.func.isRequired
}

export default EditCustomerUser
