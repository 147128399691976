import React from 'react'
import PropTypes from 'prop-types'
import { some } from 'lodash'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from '@mui/material'

import {
  useSessionUser,
  useEmissaryTheme
} from 'hooks'
import {
  ChevronDown,
  ChevronUp,
  Dollar,
  Logout,
  Menu as MenuIcon,
  Phone,
  Users
} from 'icons'

import {
  navigateTo,
  unstealth
} from 'utils-em'

import {
  NotificationsDrawer
} from 'components'

const HeaderMenu = ({ menuItems, menuItemsVisible, ...rest }) => {
  const theme = useTheme()
  const menuRef = React.useRef()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const hasMenu = some(Object.values(menuItemsVisible), Boolean)
  const { isSmOrSmaller } = useEmissaryTheme()

  // Menu button for customers and advisors is rendered as initials in a circle
  const {
    id: userId,
    firstName,
    lastName,
    cloaked,
    isPOM,
    logout,
  } = useSessionUser()

  const initials = `${firstName[0]}${lastName[0]}`

  const [hasRelevantContract, setHasRelevantContract] = React.useState(true)
  const loadRelevantContract = () => {
    fetch(
      `${__API_HOST__}/v1/contracts/relevant?userId=${userId}`,
      { credentials: 'include', method: 'GET' }
    ).then((res) => {
      if (!res.ok) {
        // hide subscriptions and purchases menu item if no valid contract
        setHasRelevantContract(false)
      }
    })
  }
  React.useEffect(() => { isPOM && loadRelevantContract() }, [])

  const managerOrPOMenuItems = [
    { text: 'My Team', icon: <Users sx={{ color: 'transparent', '& path': { stroke: ({ palette }) => palette.neutral.black } }} />, path: '/c/my-team', visible: true },
    { text: 'Call activity', icon: <Phone />, path: '/c/team-activity', visible: true },
    { text: 'Subscriptions & Purchases', icon: <Dollar />, path: '/c/subscriptions-and-purchases', visible: hasRelevantContract },
  ]

  const StealthButton = () => (
    <Button
      onClick={() => unstealth((returnPage) => window.location.assign(returnPage))}
      color="secondary"
      variant="contained"
      sx={{ height: '48px', mr: 4 }}
    >
      ((Exit))
    </Button>
  )

  const MenuItems = () => {
    const renderMenuItem = (text, icon, path) => (
      <MenuItem
        key={text}
        onClick={() => {
          navigateTo(path)
          setAnchorEl(null)
        }}
      >
        <ListItemIcon sx={{ minWidth: 5 }}>
          <Icon sx={{ color: 'brand.darkNavy' }}>{icon}</Icon>
        </ListItemIcon>
        <ListItemText
          primary={(
            <Typography color="brand.darkNavy" sx={{ textTransform: 'capitalize' }}>
              {text}
            </Typography>
          )}
        />
      </MenuItem>
    )

    return (
      <>
        {isPOM && (
          <>
            <Typography variant="tinyBold" sx={{ ml: 2 }}>Team</Typography>
            {managerOrPOMenuItems.map(({ text, icon, path, visible }) => visible && renderMenuItem(text, icon, path))}
            <Divider sx={{ borderColor: 'brand.lightTeal' }} />
            <Typography variant="tinyBold" sx={{ ml: 2 }}>{firstName}</Typography>
          </>
        )}
        {menuItems.map(({ text, icon, path }) => renderMenuItem(text, icon, path))}
        <MenuItem onClick={() => logout()}>
          <ListItemIcon sx={{ minWidth: 5 }}>
            <Logout sx={{ color: 'brand.darkNavy' }} />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography sx={{ color: 'brand.darkNavy' }}>
                Log Out
              </Typography>
            )}
          />
        </MenuItem>
      </>
    )
  }

  const getMenuIcon = () => {
    if (!isSmOrSmaller) return <MenuIcon />
    return anchorEl ? <ChevronUp /> : <ChevronDown />
  }

  if (!hasMenu) return null

  return (
    <Box {...rest}>
      <Box sx={{ height: theme.header.height, display: 'flex', alignItems: 'center' }}>
        {cloaked && menuItemsVisible.stealth && (
          <StealthButton />
        )}
        {menuItemsVisible.notifications && (
          <NotificationsDrawer sx={{ mr: 2 }} />
        )}
        {menuItemsVisible.menu && (
          <Box>
            <IconButton
              disableRipple
              ref={menuRef}
              data-link-ref="open-menu"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{ bgcolor: 'transparent' }}
            >
              {!isPOM && (
                <>
                  <Avatar sx={{
                    bgcolor: isSmOrSmaller ? 'neutral.white' : 'brand.lightNavy',
                    width: isSmOrSmaller ? '32px' : '40px',
                    height: isSmOrSmaller ? '32px' : '40px',
                  }}
                  >
                    <Typography variant="captionBold" sx={{ color: 'brand.darkNavy' }}>{initials}</Typography>
                  </Avatar>
                  {anchorEl ? <ChevronUp /> : <ChevronDown />}
                </>
              )}
              {isPOM && (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'brand.darkNavy',
                  '&:hover, &:hover > svg path': {
                    // apply hover color to both typography and icon
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                  }
                }}
                >
                  <Typography variant="buttonL" sx={{ mr: 1 }}>
                    Menu
                  </Typography>
                  {getMenuIcon()}
                </Box>
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorReference={isSmOrSmaller ? 'anchorPosition' : 'anchorEl'}
              anchorEl={anchorEl} // bottom of button for large screens
              anchorPosition={{ top: parseInt(theme.header.topHeight, 10), right: 0, left: 0 }} // bottom of top bar for xs screens
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              marginThreshold={isSmOrSmaller ? 0 : undefined}
              sx={{
                // mt: 6,
                zIndex: 9999,
                '& .MuiPaper-root': {
                  width: isSmOrSmaller ? '100vw' : 'auto',
                  maxWidth: isSmOrSmaller ? '100vh' : 'auto',
                  left: isSmOrSmaller ? '0 !important' : 'auto',
                  right: isSmOrSmaller ? '0 !important' : 'auto',
                  top: isSmOrSmaller ? '100 !important' : 'auto',
                }
              }}
            >
              <MenuItems />
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  )
}

HeaderMenu.defaultProps = {
  menuItems: [],
  menuItemsVisible: {
    stealth: true,
    notifications: true,
    menu: true
  },
}

HeaderMenu.propTypes = {
  menuItemsVisible: PropTypes.shape({
    stealth: PropTypes.bool,
    notifications: PropTypes.bool,
    menu: PropTypes.bool,
  }),
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
  })),
}

export default HeaderMenu
