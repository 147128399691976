import React from 'react'
import { FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import { ArrowRight } from 'icons'
import { simpleHttpFetch, customAlert } from 'utils-em'

const DevApiUrlInput = () => {
  const [url, setUrl] = React.useState('')

  const handleSubmit = async () => {
    await simpleHttpFetch(
      `${__API_HOST__}/v1/surveys/set_all_webhooks`,
      { method: 'POST', body: JSON.stringify({ devApiUrl: url }) }
    )
    customAlert('Dev API URL set')
    setUrl('')
  }

  return (
    <FormControl variant="outlined">
      <OutlinedInput
        type="text"
        size="small"
        endAdornment={(
          <InputAdornment position="end">
            <IconButton onClick={handleSubmit} edge="end">
              <ArrowRight />
            </IconButton>
          </InputAdornment>
        )}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Dev API url"
        sx={{ width: '400px' }}
      />
    </FormControl>
  )
}

export default DevApiUrlInput
