import { connect } from 'react-redux'

import AdvisorProfileDialog from './AdvisorProfileDialog'
import Dialogs from '../../store/Dialogs'

const mapDispatchToProps = {
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog('advisorProfile'))
}

export default connect(null, mapDispatchToProps)(AdvisorProfileDialog)
