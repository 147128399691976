import React from 'react'
import { useSelector } from 'react-redux'
import { useJsonAPIGetAll } from 'hooks'
import moment from 'moment'
import {
  openDialog
} from 'utils-em'
import { UpcomingEngagementDialog } from '../components'

export default function useUpcomingEngagementDialogInitiator () {
  const userId = useSelector(({ session }) => session.id)
  const [dialogTriggered, setDialogTriggered] = React.useState(false)
  const filters = [
    {
      or: [
        { name: 'customerUserId', op: 'eq', val: userId },
        { name: 'advisorId', op: 'eq', val: userId },
        {
          name: 'participants',
          op: 'any',
          val: {
            name: 'userId',
            op: 'eq',
            val: userId
          }
        }
      ]
    },
    { name: 'state', op: 'eq', val: 'active' },
    {
      name: 'timeslotProposals',
      op: 'any',
      val: {
        and: [
          {
            name: 'status',
            op: 'eq',
            val: 3 // accepted
          },
          {
            name: 'timeslots',
            op: 'any',
            val: {
              and: [
                {
                  name: 'isSelected',
                  op: 'eq',
                  val: 1 // is selected
                },
                {
                  name: 'startTime',
                  op: 'lt',
                  val: moment().add(1, 'days').toISOString()
                },
                {
                  name: 'endTime', // not ended
                  op: 'gt',
                  val: moment().toISOString()
                }
              ]
            }
          }
        ]
      }
    }
  ]
  const include = [
    'organization',
    'customerUser',
    'customerUser.customer',
    'relevantTimeslotProposals',
    'relevantTimeslotProposals.acceptedTimeslots',
    'advisor'
  ]
  const { objects: engagementsOccurringToday, loaded } = useJsonAPIGetAll('engagements', { include, filters, orderedType: 'engagementsWithCallsOccurringToday' })

  React.useEffect(() => {
    if (loaded && engagementsOccurringToday.length > 0 && !dialogTriggered) {
      setDialogTriggered(true)
      openDialog(<UpcomingEngagementDialog />)
    }
  }, [loaded, engagementsOccurringToday])
}
