import { connect } from 'react-redux'
import { buildJsonApiAll } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'

import TeamTree from './TeamTree'

const mapStateToProps = ({ data }) => ({
  divisions: buildJsonApiAll(data, 'divisions')
})

const mapDispatchToProps = {
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog)),
  getDivisions: (customerId) => JsonAPI.getAll({
    type: 'divisions',
    sort: 'name',
    queryStringParams: {
      include: 'customerUsers',
      filter: [
        { name: 'customerId', op: 'eq', val: customerId }
      ]
    }
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamTree)
