import PropTypes from 'prop-types'
import React from 'react'
import { map } from 'lodash'

import { Box, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Delete from '@mui/icons-material/Delete'

import CustomerUserSelector from '../CustomerUserSelector'
import CustomerUserTooltips from '../CustomerUserTooltips'

const CustomerUserSetEditor = ({ customerId, customerUsers, primaryCustomerUserId, onChange, hidePrimaryControls, hideDeleteControls }) => {
  const setPrimary = (event) => {
    const customerUserId = event.currentTarget.value
    const newUsers = customerUsers.map((cu) => ({
      ...cu,
      isPrimary: cu.id === customerUserId
    }))

    onChange(newUsers)
  }

  const addUser = (customerUser) => {
    if (customerUsers.find((cu) => cu.id === customerUser.id)) { return }

    const newUsers = customerUsers.concat(customerUser)

    // if there's only one, automatically make it primary
    if (newUsers.length === 1) {
      newUsers[0].isPrimary = true
    } else {
      const primaryIndex = newUsers.findIndex((nu) => nu.id === primaryCustomerUserId)
      if (primaryIndex !== -1) {
        newUsers[primaryIndex].isPrimary = true
      } else { newUsers[0].isPrimary = true }
    }

    onChange(newUsers)
  }

  const removeUser = (customerUserId) => {
    const newUsers = Object.assign([], customerUsers.filter((cu) => cu.id !== customerUserId))

    // ensure primary
    if (!newUsers.find((cu) => cu.isPrimary) && newUsers.length) { newUsers[0].isPrimary = true }

    onChange(newUsers)
  }

  return (
    <>
      <CustomerUserSelector
        customerId={customerId}
        userId={null}
        onChange={addUser}
      />
      <RadioGroup aria-label="gender" name="gender1" value={primaryCustomerUserId} onChange={setPrimary} style={{ display: 'inline' }}>
        {map(customerUsers, (cu) => (
          <div key={cu.id} style={{ width: '100%' }}>
            <FormControlLabel
              value={parseInt(cu.id, 10)}
              control={hidePrimaryControls ? <Box sx={{ ml: 1 }} /> : <Radio />}
              label={(
                <>
                  {cu.fullName}
                  <CustomerUserTooltips
                    customerUser={cu}
                  />
                </>
              )}
            />
            {(customerUsers.length > 1 || hidePrimaryControls) && !hideDeleteControls
              ? (
                <Button onClick={() => removeUser(cu.id)}>
                  <Delete />
                </Button>
                )
              : null}
          </div>
        ))}
      </RadioGroup>
    </>
  )
}

CustomerUserSetEditor.defaultProps = {
  primaryCustomerUserId: null,
  hidePrimaryControls: false,
  hideDeleteControls: false
}

CustomerUserSetEditor.propTypes = {
  customerId: PropTypes.number.isRequired,
  customerUsers: PropTypes.array.isRequired,
  primaryCustomerUserId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  hidePrimaryControls: PropTypes.bool,
  hideDeleteControls: PropTypes.bool
}

export default CustomerUserSetEditor
