import React from 'react'
import {
  Box,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import { Chrome, LinkedIn } from 'icons'
import { AdvisorAccessControl } from 'components'
import { useSessionUser } from 'hooks'

const Footer = () => {
  /*
  Responsive layout:
  - xs to sm: email, links, and socials are in separate rows
  - sm to md: email is in a row, links and socials are in a row
  - md and up: email, links, and socials are in the same row
  See https://app.shortcut.com/emissary/story/31408/responsive-footer
  */
  const theme = useTheme()
  const { getSupportEmailAddress, getTermsUrl, isAdvisor } = useSessionUser()

  const links = [
    { text: 'About', href: 'https://emissary.io/what-we-do/' },
    { text: 'Terms', href: getTermsUrl() },
    { text: 'Confidentiality', href: 'https://emissary.io/confidentiality-policy/' },
    { text: 'System Requirements', href: 'https://emissary.io/system-requirements/' },
  ]

  const socials = [
    { name: 'LinkedIn', IconComponent: LinkedIn, href: 'https://www.linkedin.com/company/emissaryio/' },
    { name: 'Chrome Extension', IconComponent: Chrome, href: 'https://chromewebstore.google.com/detail/emissary/lhlifkcapmgnlmnfanbloknkonkfbadf' }
  ]

  const renderLinks = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: ' row',
      flexWrap: {
        sm: 'wrap',
        md: 'nowrap'
      },
      rowGap: 1,
      columnGap: 2
    }}
    >
      {links.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          target="_blank"
          sx={{
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
            color: 'brand.navy',
          }}
        >
          <Typography variant="caption">{link.text}</Typography>
        </Link>
      ))}
    </Box>
  )

  const renderSocials = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', gap: 3 }}>
      {socials.map((social) => (
        <Tooltip key={social.href} title={social.name}>
          <IconButton key={social.href} sx={{ p: 0 }} target="_blank" href={social.href}>
            <social.IconComponent height="24px" sx={{ color: 'brand.darkNavy' }} />
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  )

  return (
    <Box id="footer">
      <Box
        sx={{
          bgcolor: 'neutral.white',
          minHeight: ({ footerHeight }) => footerHeight,
          pb: 3 // TODO may need to adjust this when we have a margin based on breakpoints
        }}
      >
        <Divider sx={{ ...theme.header.divider }} />
        <Box
          typography="caption"
          sx={{
            mt: 3,
            pl: 6,
            pr: 6,
            display: 'flex',
            flexDirection: {
              sm: 'column',
              md: 'column',
              lg: isAdvisor ? 'column' : 'row'
            },
            gap: 3,
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography variant="captionBold">Need help?&nbsp;</Typography>
            Email&nbsp;
            <Link href={`mailto:${getSupportEmailAddress()}`} rel="noreferrer" target="_blank" underline="hover">
              {getSupportEmailAddress()}
            </Link>
            <AdvisorAccessControl>
              &nbsp;or&nbsp;
              <Link href="https://bit.emissary.io/SupportCall" target="_blank" rel="noreferrer" underline="hover">
                schedule a support call
              </Link>
              &nbsp;with us.
            </AdvisorAccessControl>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: {
              sm: 'column',
              md: 'row',
              lg: 'row'
            },
            gap: 3,
            justifyContent: 'space-between'
          }}
          >
            {renderLinks()}
            {renderSocials()}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
