import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { Notification } from 'components'
import { getActiveNotifications } from 'utils-em'

const NotificationsList = ({ ...rest }) => {
  const notifications = useSelector(({ session, data }) => getActiveNotifications(session, data))

  return (
    <Box {...rest}>
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
        />
      ))}
    </Box>
  )
}

export default NotificationsList
