import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

const FormCheckbox = ({ form, name, label, typography }) => (
  <Controller
    name={name}
    control={form.control}
    render={({ field }) => (
      <FormControlLabel
        control={(
          <Checkbox
            {...field}
            checked={field.value}
          />
        )}
        label={<Typography variant={typography}>{label}</Typography>}
      />
    )}
  />
)

FormCheckbox.defaultProps = {
  label: '',
  typography: 'body1'
}

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  form: PropTypes.object.isRequired, // This is the React Hook Form form object
  typography: PropTypes.string,
}

export default FormCheckbox
