import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { Tabs, QuestionResponsesTable, SurveyAssignmentsTable } from 'components'

const SurveyAssignmentTabs = ({ organizationId }) => (
  <Box sx={{ width: '100%', minHeight: '600px' }}>
    <Tabs
      tabs={[
        {
          name: 'Manage',
          component: organizationId
            ? <SurveyAssignmentsTable organizationId={organizationId} />
            : <Typography>Please select organization</Typography>
        },
        {
          name: 'Responses',
          component: organizationId ? (
            <QuestionResponsesTable
              organizationId={organizationId}
              initialColumns={[
                'surveyAssignment.dateCreated',
                'advisor.fullName',
                'questionId',
                'questionText',
                'textAnswer',
                'surveyAssignment.status',
                'actions'
              ]}
              initialSortBy="dateCreated"
            />
          ) : <Typography>Please select organization</Typography>
        },
      ]}
    />
  </Box>
)

SurveyAssignmentTabs.propTypes = {
  organizationId: PropTypes.number.isRequired
}

export default SurveyAssignmentTabs
