import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orderBy } from 'lodash'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  Typography
} from '@mui/material'
import AlarmOff from '@mui/icons-material/AlarmOff'
import CallEnd from '@mui/icons-material/CallEnd'
import Clear from '@mui/icons-material/Clear'
import Forward from '@mui/icons-material/Forward'
import Group from '@mui/icons-material/Group'
import NotInterested from '@mui/icons-material/NotInterested'
import Schedule from '@mui/icons-material/Schedule'
import {
  buildJsonApiAll,
  buildJsonApiOne,
  formatDate,
  closeDialog
} from 'utils-em'
import { JsonAPI } from 'store'

const AdvisorEngagementHistoryDialog = ({
  advisorId
}) => {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = React.useState(false)

  const advisor = useSelector(({ data }) => buildJsonApiOne(data, 'advisors', advisorId))
  const engagements = useSelector(({ data }) => buildJsonApiAll(data, 'engagements').filter((e) => e.advisorId === advisorId))

  // 2.0 engagements
  const loadEngagements = () => (
    dispatch(JsonAPI.getAll({
      type: 'engagements',
      queryStringParams: {
        include: [
          'advisor',
          'customerUser',
          'customerUser.customer',
          'organization'
        ].join(','),
        filter: [
          { name: 'advisorId', op: 'eq', val: advisorId }
        ]
      }
    }))
  )

  React.useEffect(() => {
    Promise.all([
      loadEngagements()
    ]).then((_) => {
      setLoaded(true)
    })
  }, [])

  const getEngagementIcon = (engagement) => {
    let engagementIcon = null
    switch (engagement.state) {
      case 'proposed': { engagementIcon = <Forward />; break }
      case 'proposal-canceled': { engagementIcon = <NotInterested />; break }
      case 'scheduled': { engagementIcon = <Schedule />; break }
      case 'active': { engagementIcon = <Group />; break }
      case 'canceled': { engagementIcon = <NotInterested />; break }
      case 'closed': { engagementIcon = <CallEnd color="secondary" />; break }
      case 'expired': { engagementIcon = <AlarmOff />; break }
      case 'rejected': { engagementIcon = <Clear />; break }
    }
    return <Tooltip title={engagement.state}>{engagementIcon}</Tooltip>
  }

  if (!loaded) { return null }

  const sortedEngagements = orderBy(engagements, ['desc'])
  return (
    <Dialog
      open
      maxWidth="lg"
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        { advisor.fullName}
      </DialogTitle>
      <DialogContent>

        <Typography variant="h6" sx={{ mt: 1 }}>Engagements</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Engagement Status</TableCell>
              <TableCell>Most Recent Activity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { sortedEngagements.length === 0 ? 'No Engagements Yet' : null}
            { sortedEngagements.map((engagement) => (
              <TableRow key={engagement.id}>
                <TableCell>
                  <Link
                    target="_blank"
                    href={`/admin/customers/${engagement.customerUser.customer.id}`}
                  >
                    {engagement.customerUser.customer.name}
                  </Link>
                </TableCell>
                <TableCell>{engagement.organization && engagement.organization.name}</TableCell>
                <TableCell>{getEngagementIcon(engagement)}</TableCell>
                <TableCell>{formatDate(engagement.dateUpdated)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AdvisorEngagementHistoryDialog.propTypes = {
  advisorId: PropTypes.number.isRequired
}

export default AdvisorEngagementHistoryDialog
