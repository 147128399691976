import { store } from 'store'
import Alerts from '../store/Alerts'

export function httpResponseAlert (resp, messages = {}) {
  if (resp.ok) {
    let message = 'Save successful'
    if (messages.success) {
      message = messages.success
    }
    store.dispatch(Alerts.showSuccess({ text: message }))
  } else {
    let message = 'Save failed, please contact support'
    if (messages.failure) {
      message = messages.failure
    }
    store.dispatch(Alerts.showError({ text: message }))
  }
}

export function showAlert (props) {
  store.dispatch(Alerts.show(props))
}

export function customAlert (text, error) {
  if (!error) {
    store.dispatch(Alerts.showSuccess({ text }))
  } else {
    store.dispatch(Alerts.showError({ text }))
  }
}

export function errorAlert (error) {
  const text = error?.message || 'Something went wrong, please contact support.'
  store.dispatch(Alerts.showError({ text }))
}
