import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardActionArea, Typography } from '@mui/material'

const StatCard = ({ value, caption, onClick, active, ...rest }) => (
  <Card
    elevation={0}
    onClick={onClick}
    sx={{
      bgcolor: active ? 'brand.darkNavy' : 'brand.lightNavy',
      width: '100%',
      minHeight: '100%',
      borderRadius: '8px',
      border: 0,
      p: 2
    }}
    {...rest}
  >
    <CardActionArea disabled={!onClick} sx={{ p: 0 }}>
      <Typography variant="h2" sx={{ color: 'brand.navy' }}>{value}</Typography>
      <Typography variant="caption" sx={{ color: 'neutral.darkGrey' }}>{caption}</Typography>
    </CardActionArea>
  </Card>
)

StatCard.defaultProps = {
  value: null,
  caption: null,
  onClick: null,
  active: false
}

StatCard.propTypes = {
  value: PropTypes.number,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  active: PropTypes.bool
}

export default StatCard
