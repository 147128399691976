import { connect } from 'react-redux'
import { Alerts, Dialogs, JsonAPI } from 'store'

import EditCustomerUserDialog from './EditCustomerUserDialog'

const mapDispatchToProps = {
  saveCustomerUser: (customerUser) => {
    const method = customerUser.id ? JsonAPI.save : JsonAPI.create
    return method(Object.assign(customerUser, {
      type: 'customerUsers',
      include: 'divisions',
      relationshipNames: ['divisions']
    }))
  },
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(null, mapDispatchToProps)(EditCustomerUserDialog)
