import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

const FormToggle = ({ form, name, label, typography, ...switchProps }) => (
  <Controller
    name={name}
    control={form.control}
    render={({ field: { onChange, value } }) => (
      <FormControlLabel
        control={(
          <Switch
            checked={value || false}
            onChange={onChange}
            color="primary"
            {...switchProps}
          />
        )}
        label={<Typography variant={typography}>{label}</Typography>}
      />
    )}
  />
)

FormToggle.defaultProps = {
  label: '',
  typography: 'body1'
}

FormToggle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  form: PropTypes.object.isRequired, // This is the React Hook Form form object
  typography: PropTypes.string,
}

export default FormToggle
