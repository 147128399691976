import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Download
} from 'icons'

import {
  DownloadAccountAssetsDialog,
  TagPicker
} from 'components'
import {
  openDialog,
  closeDialog,
  trimObjectStringFields
} from 'utils-em'

import { JsonAPI } from 'store'

const EditOrganizationDialog = ({ organization }) => {
  const dispatch = useDispatch()
  const [org, setOrg] = React.useState(organization || {
    name: '',
    aliases: '',
    origin: ''
  })
  const [verticals, setVerticals] = React.useState(organization?.tags?.filter((t) => t.category === 'vertical') || [])

  const saveOrg = () => {
    const method = org.id ? JsonAPI.save : JsonAPI.create
    return dispatch(method(trimObjectStringFields({
      ...org,
      tags: verticals,
      type: 'organizations',
      relationshipNames: ['tags'],
      queryStringParams: { include: 'tags' }
    })))
  }

  const updateOrganization = (name) => (event) => {
    setOrg(
      { ...org, [name]: event.target.value }
    )
  }

  const toggleTeal = () => {
    setOrg(
      { ...org, teal: !org.teal }
    )
  }

  const toggleSurveysEnabled = () => {
    setOrg(
      { ...org, surveysEnabled: !org.surveysEnabled }
    )
  }

  const handleSaveOrg = () => {
    saveOrg(org).then(
      (_) => closeDialog()
    )
  }

  const openDownloadAccountAssetsDialog = () => {
    openDialog(<DownloadAccountAssetsDialog accountName={organization.name} organizationId={organization.id} />)
  }

  return (
    <Dialog
      open
      fullWidth
    >
      <ValidatorForm
        onSubmit={handleSaveOrg}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Name"
                validators={['required']}
                errorMessages={['Required Field']}
                value={org.name || ''}
                onChange={updateOrganization('name')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Url"
                value={org.url || ''}
                onChange={updateOrganization('url')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Origin"
                value={org.origin || ''}
                onChange={updateOrganization('origin')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TagPicker
                label="Verticals (Focus Industries)"
                placeholder=""
                hasAllOption
                type="vertical"
                addTag={(tag) => setVerticals((previous) => [...previous, tag])}
                removeTag={(tag) => setVerticals((previous) => [...previous.filter((t) => t.id !== tag.id)])}
                selectedTags={verticals}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label="Aliases"
                multiline
                rows={6}
                value={org.aliases || ''}
                onChange={updateOrganization('aliases')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={org.teal}
                    onClick={toggleTeal}
                  />
                )}
                label="TEAL"
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} />
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={org.surveysEnabled}
                    onClick={toggleSurveysEnabled}
                  />
                )}
                label="Surveys Enabled"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="outlined"
                size="large"
                color="primary"
                component="span"
                startIcon={<Download />}
                disabled={!organization}
                onClick={openDownloadAccountAssetsDialog}
              >
                Download all files
                <small>&nbsp;(Recordings, Transcripts, Notes, Surveys)</small>
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditOrganizationDialog.defaultProps = {
  organization: null
}

EditOrganizationDialog.propTypes = {
  organization: PropTypes.object,
}

export default EditOrganizationDialog
