import React from 'react'
import { Switch, Route } from 'react-router'

import AccountUploadJobsList from './AccountUploadJobsList'
import Accounts from './Accounts'
import CustomerInfo from './CustomerInfo'
import CustomerUsers from './CustomerUsers'
import Contracts from './Contracts'
import Teams from './Divisions'
import DivisionHierarchy from './DivisionHierarchy'

export default () => (
  <Switch>
    <Route exact path="/admin/customers/:id/upload-accounts-unlimited" component={AccountUploadJobsList} />
    <Route exact path="/admin/customers/:id/upload-accounts-unlimited/:jobId" component={AccountUploadJobsList} />
    <Route exact path="/admin/customers/:id/advisor-requests" component={Accounts} />
    <Route exact path="/admin/customers/create" component={CustomerInfo} />
    <Route exact path="/admin/customers/:id" component={CustomerInfo} />
    <Route exact path="/admin/customers/:id/people" component={CustomerUsers} />
    <Route exact path="/admin/customers/:id/contracts" component={Contracts} />
    <Route exact path="/admin/customers/:id/teams" component={Teams} />
    <Route exact path="/admin/customers/:id/team-hierarchy" component={DivisionHierarchy} />
  </Switch>
)
