import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  IconButton,
  Skeleton,
  useTheme
} from '@mui/material'
import { Heart } from 'icons'
import {
  SaveAccountListOrgWrapper
} from 'components'

const BookmarkAccountButton = ({ orgId, accountSaved, savedListLoaded, checkIfAccountSaved, iconOnly }) => {
  const theme = useTheme()

  if (!savedListLoaded) return <Skeleton />

  const HeartIcon = <Heart sx={{ color: 'primary.main', fill: accountSaved ? `${theme.palette.primary.main} !important` : 'none' }} />

  return (
    <SaveAccountListOrgWrapper
      orgId={orgId}
      isCurrentlySaved={accountSaved}
      enableUnsave
      onUpdate={checkIfAccountSaved}
    >
      {iconOnly ? (
        <IconButton sx={{ '&:hover': { backgroundColor: 'primary.lightest' } }}>
          {HeartIcon}
        </IconButton>
      ) : (
        <Button
          variant="text"
          startIcon={HeartIcon}
        >
          {accountSaved ? 'Account saved' : 'Save account'}
        </Button>
      )}
    </SaveAccountListOrgWrapper>
  )
}

BookmarkAccountButton.defaultProps = {
  iconOnly: false
}

BookmarkAccountButton.propTypes = {
  orgId: PropTypes.string.isRequired,
  accountSaved: PropTypes.string.isRequired,
  savedListLoaded: PropTypes.bool.isRequired,
  checkIfAccountSaved: PropTypes.func.isRequired,
  iconOnly: PropTypes.bool
}

export default BookmarkAccountButton
