import { sortBy } from 'lodash'

export const USER_ONBOARDING_STATUS = {
  ONBOARDED: 'onboarded',
  NOT_ONBOARDED: 'not_onboarded',
  DEACTIVATED: 'deactivated'
}

export const USER_STATUS_DATA = {
  // Order defines the order of the statuses based on the stages of signup flow

  // Both advisors and customers
  not_invited: { order: 1, color: 'red', themeColor: 'error.main', text: 'Not Invited' },
  invited: { order: 2, color: 'yellow', themeColor: 'warning.main', text: 'Invited' },
  invite_accepted: { order: 3, color: 'yellow', themeColor: 'warning.main', text: 'Sign-Up Incomplete (Invite Viewed)' },
  active: { order: 9, color: 'green', themeColor: 'success.main', text: 'Active' },
  idle: { order: 10, color: 'grey', themeColor: 'neutral.darkGrey', text: 'Idle' },
  deactivated: { order: 11, color: 'grey', themeColor: 'neutral.darkGrey', text: 'Deactivated' },

  // Advisors (exclusive)
  sign_up_incomplete_needs_password_tcs: { order: 4, color: 'yellow', themeColor: 'warning.main', text: 'Sign-Up Incomplete - Needs Password and T&Cs' },
  sign_up_incomplete_needs_basic_info: { order: 5, color: 'yellow', themeColor: 'warning.main', text: 'Sign-Up Incomplete - Needs Basic Info' },
  sign_up_incomplete_needs_employment_history: { order: 6, color: 'yellow', themeColor: 'warning.main', text: 'Sign-Up Incomplete - Needs Employment History' },

  // Customers (exclusive)
  sign_up_incomplete_needs_contact_info: { order: 7, color: 'yellow', themeColor: 'warning.main', text: 'Sign-Up Incomplete - Needs Contact Info' },
  sign_up_incomplete_needs_account_info: { order: 8, color: 'yellow', themeColor: 'warning.main', text: 'Sign-Up Incomplete - Needs Account Info' }
}

export function getCustomerUserOnboardedStatus (customerUser) {
  if (!customerUser.isActive) return USER_ONBOARDING_STATUS.DEACTIVATED
  if (customerUser.onboardingCompleted) return USER_ONBOARDING_STATUS.ONBOARDED
  return USER_ONBOARDING_STATUS.NOT_ONBOARDED
}

export function getMostRecentStatusUpdate (customerUser, status) {
  const statuses = customerUser.userStatuses?.filter((s) => s.status === status)
  if (!statuses?.length) return
  return sortBy(statuses, ['-statusDate'])[0].statusDate
}

export function canManageUser (viewingUser, viewedUser) {
  if (parseInt(viewingUser.id, 10) === parseInt(viewedUser.id, 10)) {
    return true
  }
  if (viewingUser.roleType === 'program_owner' && viewedUser.roleType !== 'program_owner') {
    return true
  }
  if (viewingUser.roleType === 'manager' && viewedUser.roleType === 'rep') {
    return true
  }
  return false
}
