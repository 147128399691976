import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material'
import { JsonAPI } from 'store'
import {
  buildJsonApiOne,
  closeDialog,
  customAlert
} from 'utils-em'

const EditIdPDialog = ({ samlIdpId }) => {
  const dispatch = useDispatch()
  const samlIdp = useSelector(({ data }) => buildJsonApiOne(data, 'samlIdps', samlIdpId))
  const [editingIdp, setEditingIdp] = React.useState(samlIdp || {
    displayName: '',
    entityId: '',
    ssoUrl: '',
    sloUrl: '',
    certificate: ''
  })

  const formFields = [
    { label: 'Name', name: 'displayName' },
    { label: 'Entity ID', name: 'entityId' },
    { label: 'SSO URL', name: 'ssoUrl' },
    { label: 'SLO URL', name: 'sloUrl' },
    { label: 'Certificate (or public key)', name: 'certificate', textAreaRows: 3 }
  ]

  const setField = (name) => (event) => setEditingIdp({ ...editingIdp, [name]: event.target.value })
  const saveIdp = () => {
    const method = samlIdpId ? JsonAPI.save : JsonAPI.create
    const body = {
      type: 'samlIdps',
      ...editingIdp
    }
    if (samlIdpId) { body.id = samlIdpId }
    dispatch(method(body)).then((resp) => {
      if (resp.ok) {
        customAlert('Survey answers updated', false)
        closeDialog()
      } else {
        customAlert('Something went wrong. Please contact support.', true)
      }
    })
  }

  return (
    <Dialog
      open
      onClose={closeDialog}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={saveIdp}>
        <DialogContent>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <Grid item xs={12} key={field.name}>
                <TextValidator
                  fullWidth
                  label={field.label}
                  validators={['required']}
                  errorMessages={['Required Field']}
                  margin="normal"
                  value={editingIdp[field.name]}
                  onChange={setField(field.name)}
                  rows={field.textAreaRows || 1}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditIdPDialog.defaultProps = {
  samlIdpId: null
}

EditIdPDialog.propTypes = {
  samlIdpId: PropTypes.number
}

export default EditIdPDialog
