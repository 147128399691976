import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Tooltip,
  Typography
} from '@mui/material'
import { useSelector } from 'react-redux'
import {
  EngagementTableActionsButton,
  FilterGroup,
  JsonApiTable,
} from 'components'
import {
  Information,
  WarningTriangle
} from 'icons'
import {
  ACTIVITY_STATUS_MAP,
  constants,
  customAlert,
  downloadFile,
  getEngagementDetails,
  getEngagementOrganizationName,
  formatDate,
  navigateTo,
  buildJsonApiOne,
  getQueryStringFromParams
} from 'utils-em'

const getColumns = (customerUserId, showParticipantIcon) => [
  {
    name: 'STATE',
    header: 'Call status',
    sortKey: 'sellerActivityStatusSort',
    value: (engagement) => {
      const { text, Icon, iconColor } = ACTIVITY_STATUS_MAP[engagement.sellerActivityStatus]

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ mr: 1, color: iconColor }} />
          <Typography variant="tiny">{text}</Typography>
        </Box>
      )
    }
  },
  {
    name: 'CUSTOMER_USER',
    header: 'User name',
    sortKey: 'customerUser.fullName',
    value: (engagement) => engagement.customerUser.fullName
  },
  {
    name: 'TITLE_COMPANY',
    header: 'Advisor account & title',
    sortKey: 'organizationName',
    value: (engagement) => {
      const orgName = getEngagementOrganizationName(engagement)
      const isCustomerUserParticipant = customerUserId && engagement.participants.some((p) => p.userId === parseInt(customerUserId, 10))
      return showParticipantIcon && isCustomerUserParticipant
        ? (
          <Tooltip title={`Engagement owned by ${engagement.customerUser.fullName}`}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'nowrap' }}>
                <Typography variant="caption">{orgName}</Typography>
                <Information sx={{ ml: 2 }} />
              </Box>
              <Typography variant="caption">{engagement.role.title}</Typography>
            </Box>
          </Tooltip>
          )
        : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">{orgName}</Typography>
            <Typography variant="caption">{engagement.role.title}</Typography>
          </Box>
          )
    }
  },
  {
    name: 'DETAILS',
    header: 'Details',
    value: (engagement) => {
      const details = getEngagementDetails(engagement)
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {engagement.sellerActivityActionRequired && <WarningTriangle sx={{ mr: 1, color: 'error.main' }} />}
          <Typography variant="tiny">{details}</Typography>
        </Box>
      )
    }
  },
  {
    name: 'DATE_COMPLETED',
    header: 'Date completed',
    sortKey: 'sellerActivityDateCompleted',
    value: ({ sellerActivityDateCompleted }) => {
      if (!sellerActivityDateCompleted) return <>-</>
      return (
        <Typography variant="body1">
          {formatDate(sellerActivityDateCompleted, { includeYear: true })}
        </Typography>
      )
    }
  },
  {
    name: 'REQUEST_DATE',
    header: 'Request date',
    value: (engagement) => formatDate(engagement.dateCreated, { includeYear: true })
  },
  {
    name: 'ACTIONS',
    header: '',
    value: (engagement) => (<EngagementTableActionsButton engagement={engagement} />)
  }
]

const EngagementsTable = ({
  context,
  columnNames,
  engagementStates,
  customerUserId,
  filterOptions,
  pageSizeOptions,
  noDataText,
  showParticipantIcon,
  showOnlyMine,
  dateFieldToFilterOn,
  ...rest
}) => {
  const customerUser = useSelector(({ data }) => buildJsonApiOne(data, 'customerUsers', customerUserId))
  const columns = getColumns(customerUserId, showParticipantIcon)
  const filteredColumns = !columnNames ? columns : columns.filter((c) => columnNames.includes(c.name))
  const initialQueryStringParams = {
    include: [
      'customerUser',
      'advisor',
      'organization',
      'role',
      'role.company',
      'participants',
      'assets',
      'relevantTimeslotProposals',
      'relevantTimeslotProposals.acceptedTimeslots',
      'relevantTimeslotProposals.acceptedTimeslots.customerPostCallFeedbackForm'
    ].join(','),
    engagementStates,
    customerUserId,
    showOnlyMine,
    dateFieldToFilterOn
  }
  const [queryStringParams, setQueryStringParams] = React.useState(initialQueryStringParams)

  const exportData = async () => {
    const queryString = getQueryStringFromParams(queryStringParams)
    const url = `${__API_HOST__}/v1/customerEngagements/export?${queryString}`
    try {
      const res = await fetch(url, { credentials: 'include', method: 'GET' })
      const blob = await res.blob()
      await downloadFile(blob, 'engagements.csv')
      customAlert('Exporting engagements to CSV')
    } catch (error) {
      customAlert('Unable to retrieve all engagements to save. Please contact support.', true)
    }
  }

  return (
    <>
      {filterOptions && (
      <FilterGroup
        title="All calls"
        filterOptions={filterOptions}
        isRider
        textSearchPlaceholder="Search by account, seller name...."
        textSearchQueryFields={['fullName']}
        initialQueryStringParams={initialQueryStringParams}
        onQueryStringParamsChange={setQueryStringParams}
        onExport={exportData}
      />
      )}
      <JsonApiTable
        columns={filteredColumns}
        type="customerEngagements"
        orderedType="customerEngagements"
        orderKey="customerEngagements"
        queryStringParams={queryStringParams}
        pageSizeOptions={pageSizeOptions}
        noDataText={noDataText}
        noSearchResultsText={customerUser ? `${customerUser.firstName} has no engagements that match this search.` : 'No engagements matching this search'}
        onRowClick={(engagement) => navigateTo(`/c/engagements/${engagement.id}`)}
        {...rest}
      />
    </>
  )
}

EngagementsTable.defaultProps = {
  context: {},
  columnNames: null, // results in all columns/order defined in getColumns
  engagementStates: [], // results in all states
  customerUserId: null, // results in all subordinate customer users
  noDataText: null,
  pageSizeOptions: constants.TWO_OH_ROWS_PER_PAGE_OPTIONS,
  showParticipantIcon: false,
  filterOptions: null,
  showOnlyMine: false,
  dateFieldToFilterOn: 'relevant_timeslot_start'
}

EngagementsTable.propTypes = {
  context: PropTypes.object,
  customerUserId: PropTypes.number,
  columnNames: PropTypes.array,
  engagementStates: PropTypes.array,
  filterOptions: PropTypes.object,
  noDataText: PropTypes.string,
  pageSizeOptions: PropTypes.array,
  showParticipantIcon: PropTypes.bool,
  showOnlyMine: PropTypes.bool,
  dateFieldToFilterOn: PropTypes.string
}
export default EngagementsTable
