import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import NavigationSidebar from './NavigationSidebar'

const mapDispatchToProps = {
  navigateTo: (path) => (dispatch) => dispatch(push(path))
}

export default connect(null, mapDispatchToProps)(NavigationSidebar)
