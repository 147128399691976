import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@mui/material'
import {
  useEngagement,
  useJsonAPIUpsert
} from 'hooks'
import {
  Clear,
  Refresh
} from 'icons'
import {
  Spinner
} from 'components'
import {
  formatDateTime,
  closeDialog,
  navigateTo,
} from 'utils-em'

const EngagementExpertiseRatingsDialog = ({ engagementId }) => {
  const { expertiseRatings } = useEngagement(engagementId)
  const { upsert, isLoading } = useJsonAPIUpsert({ showDefaultAlerts: true })
  expertiseRatings.sort((a, b) => a.expertiseId - b.expertiseId)
  const resultDate = expertiseRatings[0]?.dateCreated

  const extractExpertiseResult = async () => {
    await upsert({
      type: 'engagements',
      id: engagementId,
      saveTranscriptExpertiseResult: true,
      relationshipNames: ['expertiseRatings'],
      queryStringParams: { include: 'expertiseRatings' },
      riders: ['saveTranscriptExpertiseResult'],
    })
  }

  const clearExpertiseResult = async () => {
    await upsert({
      type: 'engagements',
      id: engagementId,
      relationshipNames: ['expertiseRatings'],
      queryStringParams: { include: 'expertiseRatings' },
      clearTranscriptExpertiseResult: true,
      riders: ['clearTranscriptExpertiseResult'],
    })
  }

  const Description = () => (
    <Typography>
      Advisor areas of expertise are extracted from the call transcript using AI and the&nbsp;
      <Link onClick={() => navigateTo('/admin/prompts')}>area of expertise prompt</Link>
      {`. A rating of 0 indicates that the advisor did not mention the area of expertise,
      and a rating of 5 indicates that the advisor has extensive knowledge in the area.`}
    </Typography>
  )

  // eslint-disable-next-line react/prop-types
  const Rating = ({ expertiseId, expertiseName, rating, explanation }) => (
    <Box key={expertiseId}>
      <Box typography="bodyBold">{`${expertiseId}. ${expertiseName}: <${rating}>`}</Box>
      <Box>{explanation}</Box>
    </Box>
  )

  const Content = () => {
    if (isLoading) {
      return <Spinner />
    }
    if (expertiseRatings.length === 0) {
      return <Typography>No ratings extracted yet for this call.</Typography>
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {resultDate && (
          <Typography variant="caption">
            {`Last run: ${formatDateTime(resultDate, { includeYear: true })}`}
          </Typography>
        )}
        {expertiseRatings.map((extractedRating) => <Rating {...extractedRating} />)}
      </Box>
    )
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      onClose={closeDialog}
    >
      <DialogTitle>{`Area of expertise ratings for engagement ${engagementId}`}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              startIcon={<Refresh />}
              variant="contained"
              onClick={extractExpertiseResult}
              disabled={isLoading}
            >
              {expertiseRatings.length > 0 ? 'Re-extract' : 'Extract'}
            </Button>
            {expertiseRatings.length > 0 && (
              <Button
                startIcon={<Clear />}
                variant="contained"
                color="error"
                onClick={clearExpertiseResult}
                disabled={isLoading}
              >
                Clear
              </Button>
            )}
          </Box>
          <Description />
          <Content />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

EngagementExpertiseRatingsDialog.propTypes = {
  engagementId: PropTypes.string.isRequired,
}

export default EngagementExpertiseRatingsDialog
