import React from 'react'

import { Box } from '@mui/material'
import { Users } from 'icons'
import IconTextCombo from '../IconTextCombo'

const STATS_PATH = `${__API_HOST__}/v1/stats`

const SellerCount = () => {
  const [sellerCount, setSellerCount] = React.useState(null)

  const getSellerCount = async () => {
    const results = await fetch(`${STATS_PATH}?requestedStats=["sellerCount"]`, { method: 'GET', credentials: 'include' })
    const body = await results.json()
    setSellerCount(Number(body.totalSellers))
  }

  React.useEffect(() => { getSellerCount() }, [])

  if (sellerCount === null) return null

  return (
    <Box>
      <IconTextCombo
        typography="body"
        iconSize="medium"
        iconColor="neutral.darkGrey"
        color="neutral.black"
        icon={<Users />}
        text={`${sellerCount} sellers`}
      />
    </Box>
  )
}

export default SellerCount
