import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'

const TimeLabels = () => {
  const hoursToDisplay = [
    '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
    '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
    '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
    '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'
  ]

  return (
    <Box
      sx={{
        minWidth: '48px',
        pr: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        rowGap: '44px',
        position: 'relative',
        top: '-6px'
      }}
    >
      {hoursToDisplay.map((time) => (
        <Typography
          key={time}
          id={time}
          variant="tiny"
          sx={{
            color: 'neutral.black',
            fontSize: '10px',
            textAlign: 'right',
          }}
        >
          {time}
        </Typography>
      ))}
    </Box>
  )
}

export default TimeLabels
