import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Link,
  Tooltip,
  Typography
} from '@mui/material'
import {
  EngagementNotificationChips,
  EngagementStateChip,
  EngagementAccessControl
} from 'components'
import {
  Building,
  Clock,
  Information,
  Phone,
  User
} from 'icons'

import {
  ENGAGEMENT_STEPS,
  buildJsonApiOne,
  createFutureDate,
  constants,
  getAdvisorTitle,
  getEngagementEndDate,
  getEngagementMostRelevantTimeslot,
  getMostRecentProposal,
  formatDate,
  formatDateTime,
  formatTime,
  isEngagementCallToday,
  isEngagementEnded,
  navigateByEvent,
  numberOfDaysBetweenDates
} from 'utils-em'
import AddToCalendarButton from '../AddToCalendarButton/AddToCalendarButton'
import IconTextCombo from '../IconTextCombo/IconTextCombo'

const EngagementCard = ({ engagementId, type }) => {
  const user = useSelector(({ session }) => session)
  const engagement = useSelector(({ data }) => buildJsonApiOne(data, type, engagementId))
  const { account, advisor, customerUser, dateCreated, organization, state } = engagement
  const organizationName = organization ? organization.name : account.name
  const isAdvisor = user.userType === 'advisor'
  const engagementStep = engagement.step
  const relevantTimeslot = getEngagementMostRelevantTimeslot(engagement)
  const actionUrl = `/${isAdvisor ? 'a' : 'c'}/engagements/${engagement.id}`

  const proposalExpiryDate = createFutureDate(new Date(dateCreated), 0, 0, constants.ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS)
  const daysUntilProposalExpires = numberOfDaysBetweenDates(new Date(), proposalExpiryDate, { truncateTime: true })
  const daysToString = (days) => `${days} day${days === 1 ? '' : 's'}`
  const isCallToday = isEngagementCallToday(engagement)
  const isCallTimeInThirtyMinutes = isCallToday && moment(relevantTimeslot.startTime).diff(moment(), 'minutes') <= 30

  const renderCardInfo = () => {
    let text = null
    let icon = null
    let informationText = null
    // primary icon and text
    switch (engagementStep) {
      // proposed
      case ENGAGEMENT_STEPS[0]: {
        text = 'Call proposal sent to advisor for review'
        if (isAdvisor) text = `Expires in ${daysUntilProposalExpires} day${daysUntilProposalExpires > 1 ? 's' : ''}`
        icon = <Information />
        break
      }
      // scheduling
      case ENGAGEMENT_STEPS[1]: {
        const activeProposal = getMostRecentProposal(engagement)
        const isSessionUserProposer = activeProposal && activeProposal.initiatingUserId === user.id
        const daysSinceProposal = activeProposal && numberOfDaysBetweenDates(activeProposal.dateCreated, new Date())
        if (activeProposal && isSessionUserProposer && daysSinceProposal >= 10) {
          icon = <Information />
          text = `Call times received ${daysToString(daysSinceProposal)} ago`
        }
        break
      }
      // prep for call, complete call
      case ENGAGEMENT_STEPS[2]:
      case ENGAGEMENT_STEPS[3]: {
        icon = <Phone />
        text = (
          <>
            {`Call scheduled for ${isCallToday ? `today at ${formatTime(relevantTimeslot.startTime, { showTimezoneSuffix: true })}` : formatDateTime(relevantTimeslot.startTime, { showTimezoneSuffix: true })}`}
            {isCallToday
              ? (
                <Link href={engagement.zoomMeetingJoinLink} sx={{ ml: 1 }} disabled={!isCallTimeInThirtyMinutes}>
                  Join Zoom meeting
                </Link>
                )
              : <AddToCalendarButton sx={{ ml: 2 }} timeslotId={relevantTimeslot.id} asLink />}
          </>
        )
        break
      }
      // post call
      case ENGAGEMENT_STEPS[4]:
      case ENGAGEMENT_STEPS[5]: {
        const engagementEndDate = getEngagementEndDate(engagement)
        const engagementDaysRemaining = numberOfDaysBetweenDates(new Date(), engagementEndDate)

        icon = <Clock />
        text = (!isEngagementEnded(engagement))
          ? `${daysToString(engagementDaysRemaining)} remaining in engagement`
          : `Engagement ended ${formatDate(engagementEndDate, { includeYear: true })}`
        break
      }
    }
    if (engagement.state === 'rejected') {
      icon = <Information />
      if (isAdvisor) {
        text = engagement.dateRejected ? `Declined ${formatDate(engagement.dateRejected, { includeYear: true })}`
          : 'Declined'
      } else {
        text = engagement.dateRejected ? `Advisor listed as unavailable on ${formatDate(engagement.dateRejected, { includeYear: true })}`
          : 'Advisor listed as unavailable'
      }
    } else if (engagement.state === 'expired') {
      icon = <Information />
      if (isAdvisor) {
        text = engagement.dateExpired ? `Expired ${formatDate(engagement.dateExpired, { includeYear: true })}`
          : 'Expired'
      } else {
        text = engagement.dateExpired ? `Advisor listed as unavailable on ${formatDate(engagement.dateExpired, { includeYear: true })}`
          : 'Advisor listed as unavailable'
      }
    }

    if (isAdvisor && engagement.dateAdvisorIndicatedAvailable) {
      informationText = `You notified the seller that you’re available on ${formatDate(engagement.dateAdvisorIndicatedAvailable, { includeYear: true })}`
    } else if (isAdvisor && ['expired', 'rejected'].includes(engagement.state)) {
      informationText = 'If you are now available, you can reach back out to this client from the Details page.'
    }
    return (
      <>
        <Box typography="caption" sx={{ display: 'flex', alignItems: 'center' }}>
          {text && <IconTextCombo icon={icon} text={text} typography="body1" iconSize="medium" />}
          {isAdvisor && <IconTextCombo icon={<Building />} text={organizationName} typography="body1" iconSize="medium" />}
          <EngagementAccessControl nonPrimary engagementId={engagementId}>
            <Tooltip title="You are a participant on this engagement">
              <Box sx={{ ml: text ? 2 : null, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <IconTextCombo icon={<User />} text={customerUser.fullName} typography="body1" iconSize="medium" />
              </Box>
            </Tooltip>
          </EngagementAccessControl>
          {informationText && (<IconTextCombo icon={<Information />} text={informationText} typography="body1" iconSize="medium" />)}
        </Box>
      </>
    )
  }

  const renderCardHeader = () => {
    if (isAdvisor) {
      const customerUserFirstName = customerUser.firstName
      const customerName = customerUser.customer && customerUser.customer.name
      const customerTitle = customerUserFirstName + (customerName ? ` - ${customerName}` : '')
      return (
        <Box sx={{ mr: 2 }}>
          <Box typography="bodyBold" color="neutral.black">
            {`Engagement with ${customerTitle}`}
          </Box>
        </Box>
      )
    }

    const advisorFirstName = advisor.firstName
    const advisorFormerTitle = getAdvisorTitle(advisor, { engagement })
    return (
      <Box sx={{ mr: 2 }}>
        <Typography variant="body" color="neutral.black">
          {advisorFirstName}
        </Typography>
        <Typography variant="h4" color="neutral.black">
          {advisorFormerTitle}
        </Typography>
      </Box>
    )
  }

  return (
    <Card sx={{ mb: 3, ':hover': { bgcolor: 'neutral.offWhite', boxShadow: 4 } }}>
      <ButtonBase
        onClick={(e) => {
          // cancel if link is clicked or click outside of link popover menu
          if (e.target.nodeName === 'A') return
          if (String(e.target.className).includes('MuiBackdrop-root')) return
          navigateByEvent(actionUrl, e)
        }}
        sx={{ width: '100%' }}
      >
        <CardContent sx={{ width: '100%', textAlign: 'left' }}>
          <Box sx={{
            mb: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          >
            {renderCardHeader()}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
              <EngagementStateChip engagementState={state} isCallToday={isCallToday} onCard size="large" textSize="body" />
              <EngagementNotificationChips engagementId={engagementId} loadData={false} size="large" textSize="body" type={type} />
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            { renderCardInfo() }
          </Box>
        </CardContent>
      </ButtonBase>
    </Card>
  )
}

EngagementCard.defaultProps = {
  type: 'engagements'
}

EngagementCard.propTypes = {
  engagementId: PropTypes.number.isRequired,
  type: PropTypes.string
}

export default EngagementCard
