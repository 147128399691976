import { push } from 'redux-first-history'

import { queryStringParser } from 'utils-em'

import API from '../_resources'
import * as ACTION from './actions'

const SessionAPI = new API('/session/', {
  initialize: {
    method: 'GET',
    onSuccess: ({ data, status }, dispatch) => {
      if (status === 200) {
        dispatch(ACTION.initializeUser(data))
      }
    }
  },
  update: {
    url: '/users/:userId/',
    method: 'PUT',
    params: { userId: 'id' },
    onSuccess: ({ data }, dispatch, getState) => {
      dispatch(ACTION.initializeUser(data))
      const { location } = getState().router
      const redirectUrl = queryStringParser(location).redirect_url
      if (data.user_type === 'customer') {
        const customerUrl = redirectUrl ? decodeURIComponent(redirectUrl) : '/c'
        location.pathname !== '/c/onboarding' && dispatch(push(customerUrl))
      }
    }
  },
  login: {
    method: 'POST',
    onSuccess: ({ data }, dispatch, getState) => {
      dispatch(ACTION.initializeUser(data))
      if (data.surveyRedirect) {
        return window.location.assign(data.surveyRedirect)
      }
      const { location } = getState().router
      const redirectUrl = queryStringParser(location).redirect_url
      if (location.state && location.state.referrer) {
        dispatch(push(location.state.referrer))
      } else if (data.user_type === 'advisor') {
        const advisorUrl = redirectUrl ? decodeURIComponent(redirectUrl) : '/a'
        // special case for onboarding survey, since hash is not parsed from queryStringParser
        dispatch(push(location.hash === '#onboarding' && redirectUrl.startsWith('/a/survey') ? `${advisorUrl}${location.hash}` : advisorUrl))
      } else if (data.user_type === 'customer') {
        const customerUrl = redirectUrl ? decodeURIComponent(redirectUrl) : '/c'
        dispatch(push(customerUrl))
      } else if (data.user_type === 'admin') {
        const customersUrl = redirectUrl ? decodeURIComponent(redirectUrl) : '/admin/customers/'
        window.location.assign(customersUrl)
      }
    }
  },
  loginWithoutRedirect: {
    method: 'POST',
    onSuccess: ({ data }, dispatch, getState) => {
      dispatch(ACTION.initializeUser(data))
    }
  },
  logout: {
    method: 'DELETE',
    onSuccess: ({ data }, dispatch) => {
      dispatch(ACTION.logoutUser())
      dispatch(push('/'))
      window.localStorage.clear()
    }
  },
  checkEmail: {
    url: '/checkEmail/',
    method: 'POST'
  },
  forgot: {
    url: '/reset/',
    method: 'POST'
  },
  reset: {
    url: '/reset/',
    method: 'PUT',
    onSuccess: ({ data }, dispatch) => {
      dispatch(push('/'))
    }
  }
})

export default SessionAPI
