import { connect } from 'react-redux'
import EnumAPI from '../../../../../store/EnumAPI'

import CustomerListLayout from '../layouts/CustomerListLayout'

const mapDispatchToProps = {
  getEnum: EnumAPI.get
}

export default connect(null, mapDispatchToProps)(CustomerListLayout)
