import PropTypes from 'prop-types'
import React from 'react'
import { ConfirmDialog } from 'components'
import {
  customAlert,
  simpleHttpFetch
} from 'utils-em'

const ArchiveTeamAdvisorRequestsDialog = ({ affectedUsers }) => {
  const archiveAccounts = async () => {
    const json = await simpleHttpFetch(
      `${__API_HOST__}/v1/advisorRequests/archive`,
      {
        json: true,
        method: 'POST',
        body: JSON.stringify({
          userIds: affectedUsers.map((user) => user.id)
        }),
        errorMessage: 'Error archiving accounts'
      }
    )
    if (json) {
      customAlert('Accounts archived')
    }
    return json
  }

  const getProps = () => ({
    title: 'Archive all accounts?',
    description: `
      Are you sure you want to archive all accounts saved for the users on this team and on the sub-teams?
    `,
    actions: [
      {
        name: 'Confirm',
        isDelete: true,
        action: archiveAccounts
      }
    ],
    size: 'sm'
  })
  return (<ConfirmDialog {...getProps()} />
  )
}

ArchiveTeamAdvisorRequestsDialog.propTypes = {
  affectedUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ArchiveTeamAdvisorRequestsDialog
