import * as ACTION_TYPE from './constants'

export function initializeUser (user) {
  let type = ACTION_TYPE.INITIALIZE_USER

  if (user.user_type === 'advisor') {
    type = ACTION_TYPE.INITIALIZE_ADVISOR_USER
  } else if (user.user_type === 'customer') {
    type = ACTION_TYPE.INITIALIZE_CUSTOMER_USER
  }

  return { type, user }
}

export function setAsUnavailable (date) {
  return {
    type: ACTION_TYPE.SET_AS_UNAVAILABLE,
    date
  }
}

export function logoutUser () {
  return {
    type: ACTION_TYPE.LOGOUT_USER
  }
}
