import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  DivisionPickerPersonalized,
  SearchBar
} from 'components'
import { Download } from 'icons'

import {
  customAlert,
  downloadFile
} from 'utils-em'

const API_HOST = __API_HOST__

const EngagementsSearch = ({ context, hideExport, hideTeamFilter, placeholder, ...rest }) => {
  const { allowSearchEntry, searchValue, setSearchValue, teamIds, setTeamIds } = React.useContext(context)
  const [isExporting, setIsExporting] = React.useState(false)

  const exportEngagementsAsCSV = async () => {
    setIsExporting(true)
    const searchParams = new URLSearchParams({
      teams: teamIds ? `[${teamIds.join(',')}]` : null,
      searchValue
    })
    try {
      const res = await fetch(
        `${API_HOST}/v1/customerEngagements/export?${searchParams}`,
        { credentials: 'include', method: 'GET' }
      )
      const blob = await res.blob()
      await downloadFile(blob, 'engagements.csv')
      customAlert('Exporting engagements to CSV')
    } catch (error) {
      customAlert('Unable to retrieve all engagements to save. Please contact support.', true)
    }
    setIsExporting(false)
  }

  return (
    <Box {...rest}>
      <Box sx={{ display: 'flex' }}>
        <SearchBar
          fullWidth={false}
          placeholder={placeholder}
          valueCallback={(value) => allowSearchEntry && setSearchValue(value)}
          sx={{ flexGrow: 5 }}
        />
        {!hideTeamFilter && (
          <DivisionPickerPersonalized onChange={(divisionIds) => setTeamIds(divisionIds)} sx={{ ml: 3, width: '37%' }} />
        )}
        {!hideExport && (
          <LoadingButton
            startIcon={<Download />}
            onClick={() => exportEngagementsAsCSV()}
            sx={{ minWidth: '200px', flex: '0 0 0', ml: 3 }}
            loading={isExporting}
          >
            Export data
          </LoadingButton>
        )}
      </Box>
    </Box>
  )
}

EngagementsSearch.defaultProps = {
  context: {},
  hideExport: false,
  hideTeamFilter: false,
  placeholder: 'Search by seller name or account'
}

EngagementsSearch.propTypes = {
  context: PropTypes.object,
  hideExport: PropTypes.bool,
  hideTeamFilter: PropTypes.bool,
  placeholder: PropTypes.string
}

export default EngagementsSearch
