import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { getComponentDisplayName } from 'utils-em'

/**
 * HOC used to add access control to a component
 * @param {function} accessFn - Callback to determine whether a user has access.
 * accessFn is passed a session as an argument and should return a boolean.
 * @param {function} renderNoAccess - Function that returns what to render when
 * accessFn(session) === False. Default return is null
 * @returns {function} - This returns a functions with the signature
 * (Component) => WrappedComponent
 *
 ************ Example Usage ************
 // create an accessFn that takes a session argument and returns a boolean
 const accessFn = session => session.roleType === 'manager'
 // create a function to return what to display when the user doesn't have access
 // This is optional
 const renderNoAccess = () => (
  <p>Sorry, you can't view this</p>
 )
 // Now CreditsOverview will render normally for mangers and will display
 // <p>Sorry, you can't view this</p> for non-managers
 export default withAccessControl(accessFn, renderNoAccess)(CreditsOverview)
 */
export default function withAccessControl (accessFn, renderNoAccess = () => null) {
  return (Component) => {
    const AccessControlledComponent = (props) => {
      const { accessControlSession } = props

      if (!accessFn(accessControlSession)) {
        return renderNoAccess()
      }

      const otherProps = { ...props }
      delete otherProps.accessControlSession

      return <Component {...otherProps} />
    }

    AccessControlledComponent.propTypes = {
      accessControlSession: PropTypes.object.isRequired
    }

    AccessControlledComponent.displayName = `AccessControlledComponent(${getComponentDisplayName(Component)})`

    const mapStateToProps = ({ session }) => ({ accessControlSession: session })

    return connect(mapStateToProps)(AccessControlledComponent)
  }
}
