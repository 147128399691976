import React from 'react'
import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'

import { ChevronUp } from 'icons'

const SidebarSection = ({ header, children, icon, collapsible, typography, ...rest }) => {
  const [expanded, setExpanded] = React.useState(true)
  return (
    <Box {...rest}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        elevation={0}
        disableGutters
        sx={{
          '& .MuiAccordionSummary-root': { pointerEvents: !collapsible ? 'none' : 'auto' }
        }}
      >
        <AccordionSummary
          expandIcon={collapsible && <ChevronUp />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            p: 0,
            '& path': { stroke: ({ palette }) => palette?.brand?.navy }
          }}
        >
          {icon && (<Box sx={{ pr: 1 }}>{icon}</Box>)}
          <Typography variant="captionBold" color="brand.navy">{header}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Typography variant={typography}>{children}</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>

  )
}

SidebarSection.defaultProps = {
  icon: null,
  collapsible: true,
  typography: 'body1'
}

SidebarSection.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  collapsible: PropTypes.bool,
  typography: PropTypes.string
}

export default SidebarSection
