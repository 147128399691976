import React from 'react'
import { SvgIcon } from '@mui/material'

export default function EmissaryKnowledge (props) {
  return (
    <SvgIcon viewBox="0 0 288 64" {...props}>
      <svg width="288" height="64" viewBox="0 0 288 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M51.3332 22.6664C49.7332 21.1997 47.1999 21.1997 45.5999 22.6664L26.2665 39.9997C24.6665 41.4664 24.6665 43.7331 26.2665 45.1997C27.0665 45.8664 28.1332 46.2664 29.1999 46.2664C30.2665 46.2664 31.3332 45.8664 32.1332 45.1997L39.0665 38.9331C39.5999 40.1331 39.8665 41.3331 39.8665 42.6664C39.8665 47.8664 35.0665 52.2664 29.1999 52.2664C23.5999 52.2664 19.0665 48.5331 18.5332 43.7331V43.5997C18.5332 43.3331 18.5332 43.0664 18.5332 42.7997V42.9331C18.5332 42.9331 18.5332 42.9331 18.5332 42.7997C18.5332 42.6664 18.5332 42.6664 18.5332 42.5331C18.5332 42.2664 18.5332 42.1331 18.3999 41.8664C18.3999 42.1331 18.5332 42.3997 18.5332 42.6664C18.5332 37.4664 23.3332 33.0664 29.1999 33.0664C31.4665 33.0664 33.3332 31.4664 33.3332 29.4664C33.3332 27.4664 31.4665 25.8664 29.1999 25.8664C24.9332 25.8664 21.5999 22.7997 21.5999 19.0664C21.5999 15.3331 25.0665 12.2664 29.1999 12.2664C31.0665 12.2664 32.7999 12.7997 34.1332 13.8664C34.9332 14.7997 36.1332 15.4664 37.4665 15.4664C39.7332 15.4664 41.5999 13.8664 41.5999 11.8664C41.5999 10.9331 41.1999 9.99974 40.3999 9.19974C37.4665 6.53307 33.4665 5.06641 29.1999 5.06641C20.5332 5.06641 13.4665 11.3331 13.4665 19.1997C13.4665 23.0664 15.3332 26.6664 18.2665 29.1997C13.5999 32.2664 10.5332 37.1997 10.5332 42.7997C10.5332 42.5331 10.5332 42.2664 10.6665 41.9997C10.6665 42.2664 10.5332 42.3997 10.5332 42.6664C10.5332 42.9331 10.5332 43.1997 10.6665 43.4664V43.5997C10.5332 43.3331 10.5332 43.0664 10.5332 42.7997C10.5332 52.1331 18.9332 59.5997 29.3332 59.5997C39.7332 59.5997 48.1332 51.9997 48.1332 42.7997C48.1332 39.5997 47.0665 36.3997 45.1999 33.7331L51.5999 28.1331C52.7999 26.3997 52.7999 23.9997 51.3332 22.6664Z" fill="url(#paint0_linear_10544_9649)" />
        <path d="M150.6 23.4984L147.8 26.6984C150.5 28.8984 153.8 29.9984 157 29.9984C161.7 29.9984 165 27.7984 165 23.6984V23.5984C165 20.0984 162.5 18.5984 158.1 17.4984C154.3 16.5984 153.4 16.0984 153.4 14.7984V14.6984C153.4 13.6984 154.4 12.8984 156.2 12.8984C158.1 12.8984 160 13.5984 161.9 14.8984L164.4 11.4984C162.2 9.79844 159.5 8.89844 156.3 8.89844C151.8 8.89844 148.6 11.3984 148.6 15.0984V15.1984C148.6 19.1984 151.5 20.3984 155.9 21.3984C159.5 22.2984 160.3 22.8984 160.3 23.9984V24.0984C160.3 25.2984 159.1 25.9984 157.1 25.9984C154.6 25.9984 152.6 24.9984 150.6 23.4984Z" fill="#01023D" />
        <path d="M88.5 9.19922H72V29.6992H88.7V25.6992H76.8V21.3992H87.1V17.3992H76.8V13.1992H88.5V9.19922Z" fill="#01023D" />
        <path d="M104.4 9.19922H99.2001V29.6992H103.9V16.3992L110 25.0992H110.1L116.3 16.2992V29.6992H121.1V9.19922H115.9L110.1 17.8992L104.4 9.19922Z" fill="#01023D" />
        <path d="M137.6 9.19922H132.8V29.5992H137.6V9.19922Z" fill="#01023D" />
        <path d="M173.8 26.6984L176.6 23.4984C178.6 24.9984 180.6 25.9984 183.1 25.9984C185.1 25.9984 186.3 25.2984 186.3 24.0984V23.9984C186.3 22.8984 185.5 22.2984 181.9 21.3984C177.5 20.3984 174.6 19.1984 174.6 15.1984V15.0984C174.6 11.3984 177.8 8.89844 182.3 8.89844C185.5 8.89844 188.2 9.79844 190.4 11.4984L187.9 14.8984C186 13.5984 184.1 12.8984 182.2 12.8984C180.4 12.8984 179.4 13.6984 179.4 14.6984V14.7984C179.4 16.0984 180.3 16.5984 184.1 17.4984C188.5 18.5984 191 20.0984 191 23.5984V23.6984C191 27.7984 187.7 29.9984 183 29.9984C179.8 29.9984 176.4 28.8984 173.8 26.6984Z" fill="#01023D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M213 9.09766H208.6L199.2 29.6977H204.1L206.1 25.0977H215.4L217.4 29.6977H222.4L213 9.09766ZM210.8 14.4977L213.7 21.0977H207.9L210.8 14.4977Z" fill="#01023D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M232 9.19922H242C244.8 9.19922 246.9 9.89922 248.4 11.2992C249.6 12.3992 250.3 13.9992 250.3 15.9992V16.0992C250.3 19.3992 248.4 21.4992 245.6 22.3992L250.9 29.6992H245.3L240.6 23.1992H236.8V29.6992H232V9.19922ZM241.7 19.1992C244 19.1992 245.4 17.9992 245.4 16.2992V16.1992C245.4 14.2992 244 13.2992 241.6 13.2992H236.8V19.1992H241.7Z" fill="#01023D" />
        <path d="M257.9 9.19922L266.3 21.5992V29.6992H271.1V21.4992L279.5 9.19922H274L268.7 17.3992L263.5 9.19922H257.9Z" fill="#01023D" />
        <path d="M77.208 47.048L73.776 50.528V54.944H72V38.144H73.776V48.296L83.64 38.144H85.68L78.408 45.752L86.184 54.944H84.072L77.208 47.048Z" fill="#01023D" />
        <path d="M108.503 38.144V54.944H107.038L96.2385 41.312V54.944H94.4625V38.144H95.9265L106.75 51.776V38.144H108.503Z" fill="#01023D" />
        <path d="M126.73 55.088C125.066 55.088 123.554 54.72 122.194 53.984C120.85 53.232 119.794 52.208 119.026 50.912C118.274 49.616 117.898 48.16 117.898 46.544C117.898 44.928 118.274 43.472 119.026 42.176C119.794 40.88 120.85 39.864 122.194 39.128C123.554 38.376 125.066 38 126.73 38C128.394 38 129.89 38.368 131.218 39.104C132.562 39.84 133.618 40.864 134.386 42.176C135.154 43.472 135.538 44.928 135.538 46.544C135.538 48.16 135.154 49.624 134.386 50.936C133.618 52.232 132.562 53.248 131.218 53.984C129.89 54.72 128.394 55.088 126.73 55.088ZM126.73 53.504C128.058 53.504 129.258 53.208 130.33 52.616C131.402 52.008 132.242 51.176 132.85 50.12C133.458 49.048 133.762 47.856 133.762 46.544C133.762 45.232 133.458 44.048 132.85 42.992C132.242 41.92 131.402 41.088 130.33 40.496C129.258 39.888 128.058 39.584 126.73 39.584C125.402 39.584 124.194 39.888 123.106 40.496C122.034 41.088 121.186 41.92 120.562 42.992C119.954 44.048 119.65 45.232 119.65 46.544C119.65 47.856 119.954 49.048 120.562 50.12C121.186 51.176 122.034 52.008 123.106 52.616C124.194 53.208 125.402 53.504 126.73 53.504Z" fill="#01023D" />
        <path d="M167.467 38.144L161.803 54.944H159.931L155.011 40.616L150.067 54.944H148.219L142.555 38.144H144.379L149.227 52.592L154.243 38.144H155.899L160.843 52.664L165.763 38.144H167.467Z" fill="#01023D" />
        <path d="M176.123 38.144H177.899V53.408H187.307V54.944H176.123V38.144Z" fill="#01023D" />
        <path d="M207.536 53.408V54.944H195.656V38.144H207.176V39.68H197.432V45.656H206.12V47.168H197.432V53.408H207.536Z" fill="#01023D" />
        <path d="M217.111 38.144H223.951C225.727 38.144 227.295 38.504 228.655 39.224C230.015 39.928 231.071 40.92 231.823 42.2C232.575 43.464 232.951 44.912 232.951 46.544C232.951 48.176 232.575 49.632 231.823 50.912C231.071 52.176 230.015 53.168 228.655 53.888C227.295 54.592 225.727 54.944 223.951 54.944H217.111V38.144ZM223.855 53.408C225.327 53.408 226.615 53.12 227.719 52.544C228.823 51.952 229.679 51.144 230.287 50.12C230.895 49.08 231.199 47.888 231.199 46.544C231.199 45.2 230.895 44.016 230.287 42.992C229.679 41.952 228.823 41.144 227.719 40.568C226.615 39.976 225.327 39.68 223.855 39.68H218.887V53.408H223.855Z" fill="#01023D" />
        <path d="M254.171 46.544H255.875V52.952C255.091 53.64 254.163 54.168 253.091 54.536C252.019 54.904 250.891 55.088 249.707 55.088C248.027 55.088 246.515 54.72 245.171 53.984C243.827 53.248 242.771 52.232 242.003 50.936C241.235 49.624 240.851 48.16 240.851 46.544C240.851 44.928 241.235 43.472 242.003 42.176C242.771 40.864 243.827 39.84 245.171 39.104C246.515 38.368 248.035 38 249.731 38C251.011 38 252.187 38.208 253.259 38.624C254.331 39.024 255.235 39.624 255.971 40.424L254.867 41.552C253.539 40.24 251.851 39.584 249.803 39.584C248.443 39.584 247.211 39.888 246.107 40.496C245.019 41.088 244.163 41.92 243.539 42.992C242.915 44.048 242.603 45.232 242.603 46.544C242.603 47.856 242.915 49.04 243.539 50.096C244.163 51.152 245.019 51.984 246.107 52.592C247.195 53.2 248.419 53.504 249.779 53.504C251.507 53.504 252.971 53.072 254.171 52.208V46.544Z" fill="#01023D" />
        <path d="M278.182 53.408V54.944H266.302V38.144H277.822V39.68H268.078V45.656H276.766V47.168H268.078V53.408H278.182Z" fill="#01023D" />
        <defs>
          <linearGradient id="paint0_linear_10544_9649" x1="10.5332" y1="32.3331" x2="52.469" y2="32.3331" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CE8069" />
            <stop offset="0.487863" stopColor="#C0745B" />
            <stop offset="1" stopColor="#B16749" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
