import PropTypes from 'prop-types'
import React from 'react'
import {
  Autocomplete,
  TextField
} from '@mui/material'

const AssetStatusSelector = ({
  onChange,
}) => {
  const assetStatus = [{ name: 'Featured' }, { name: 'Archived' }]
  const handleChange = (chosenType) => onChange(chosenType)

  return (
    <Autocomplete
      multiple
      options={assetStatus}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}
      onChange={(event, selection) => handleChange(selection)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true
          }}
          label="Status"
          margin="normal"
          variant="standard"
        />
      )}
    />
  )
}

AssetStatusSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default AssetStatusSelector
