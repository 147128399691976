import PropTypes from 'prop-types'
import React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material'
import {
  buildJsonApiOne,
  closeDialog,
  customAlert,
  openDialog,
  httpResponseAlert,
  validateEmail,
  validateRequiredPhoneNumber,
  trimObjectStringFields
} from 'utils-em'
import {
  Trash
} from 'icons'
import {
  DeactivateCustomerUserDialog,
  DivisionPickerTwo,
  PhoneNumberValidator,
  LoadingButton,
  TimezoneSelector
} from 'components'
import { JsonAPI } from 'store'
import moment from 'moment'

const EditCustomerUserDialog = ({
  customerUserId,
  editTeams,
  phoneNumberRequired,
  title
}) => {
  const dispatch = useDispatch()
  const [hasEdited, setHasEdited] = React.useState(false)
  const [deactivateInfoLoading, setDeactivateInfoLoading] = React.useState(false)
  const [loggedInUserId, customerId] = useSelector(({ session }) => [session.id, session.customerId])
  const user = customerUserId
    ? useSelector(({ data }) => buildJsonApiOne(data, 'customerUsers', customerUserId))
    : {
        firstName: '',
        lastName: '',
        email: '',
        location: '',
        timezone: moment.tz.guess(),
        phoneNumber: '',
        permissionToCall: false,
        permissionToText: false,
        divisions: [],
        roleType: 'rep',
        adminNotes: '',
        jobTitle: '',
        customerId,
        isActive: true,
        type: 'customerUsers',
        relationshipNames: ['divisions']
      }
  const [editingUser, setEditingUser] = React.useState(user)

  const handleChange = (fieldName, value) => {
    if (editingUser[fieldName] && editingUser[fieldName].length !== value.length) { setHasEdited(true) }
    setEditingUser({
      ...editingUser,
      [fieldName]: value
    })
  }

  React.useEffect(() => {
    ValidatorForm.addValidationRule('validRequiredPhoneNumber', validateRequiredPhoneNumber)
    ValidatorForm.addValidationRule('validateEmail', (input) => validateEmail(input).valid)
  }, [])

  const handleSave = async () => {
    if (editTeams && editingUser.divisions.length === 0) {
      customAlert('Users must be on at least one team', true)
      return
    }

    const editingUserTrimmedObject = trimObjectStringFields(editingUser)
    const method = editingUser.id ? JsonAPI.save : JsonAPI.create
    try {
      const resp = await dispatch(method({
        ...editingUserTrimmedObject,
        sendInvite: method === JsonAPI.create,
        riders: ['sendInvite'],
        include: 'divisions'
      }))
      httpResponseAlert(resp, { failure: resp.error ? resp.error.detail : null, success: 'User created successfully' })
      if (resp.ok) { closeDialog() }
    } catch (error) {
      if (!error.aborted) customAlert('User cannot be saved. Please contact support.', true)
    }
  }

  return (
    <Dialog
      open
      onClose={closeDialog}
      fullWidth
      maxWidth="md"
    >
      <ValidatorForm onSubmit={handleSave}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextValidator
                  label="First name *"
                  validators={['required']}
                  errorMessages={['Required Field']}
                  value={editingUser.firstName}
                  onChange={(event) => handleChange('firstName', event.target.value)}
                  fullWidth
                  size="large"
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  label="Last name *"
                  validators={['required']}
                  errorMessages={['Required Field']}
                  value={editingUser.lastName}
                  onChange={(event) => handleChange('lastName', event.target.value)}
                  fullWidth
                  size="large"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  label="Email *"
                  validators={['required', 'validateEmail']}
                  errorMessages={['Required Field', 'Enter a valid email address']}
                  value={editingUser.email}
                  onChange={(event) => handleChange('email', event.target.value)}
                  fullWidth
                  size="large"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  label="Job title"
                  value={editingUser.jobTitle || ''}
                  onChange={(event) => handleChange('jobTitle', event.target.value)}
                  fullWidth
                  size="large"
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  label="Location"
                  value={editingUser.location || ''}
                  onChange={(event) => handleChange('location', event.target.value)}
                  fullWidth
                  size="large"
                />
              </Grid>
              <Grid item xs={12}>
                <TimezoneSelector
                  initialTimezone={editingUser.timezone}
                  timezoneCallback={(timezone) => handleChange('timezone', timezone)}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneNumberValidator
                  fullWidth
                  defaultCountry="us"
                  label="Mobile phone"
                  validators={phoneNumberRequired ? ['validRequiredPhoneNumber'] : []}
                  errorMessages={['Required Field', 'Enter a valid phone number']}
                  value={editingUser.phoneNumber || ''}
                  onChange={(value) => handleChange('phoneNumber', value)}
                  margin="normal"
                  variant="outlined"
                  size="large"
                  sx={{ mt: 0 }}
                />
              </Grid>
              { editTeams ? (
                <Grid sx={{ mt: -1 }} item xs={12}>
                  <DivisionPickerTwo
                    initialDivisions={editingUser.divisions}
                    onSelectCallback={(value) => handleChange('divisions', value)}
                  />
                </Grid>
              ) : null}
              { !editingUser.id && (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    This will send an email invitation to the user so they can set up their account.
                  </Typography>
                </Grid>
              )}
              { editingUser.id && parseInt(editingUser.id, 10) !== parseInt(loggedInUserId, 10) ? (
                <Grid item xs={12}>
                  <LoadingButton
                    startIcon={<Trash sx={{ color: 'error.main' }} />}
                    color="error"
                    onClick={() => {
                      openDialog(<DeactivateCustomerUserDialog customerUser={editingUser} setLoading={setDeactivateInfoLoading} />, 'deactivateDialog')
                      setDeactivateInfoLoading(true)
                    }}
                    loading={deactivateInfoLoading}
                    disabled={deactivateInfoLoading}
                  >
                    Deactivate User
                  </LoadingButton>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={closeDialog}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary" disabled={!hasEdited}>
            {editingUser.id ? 'Save' : 'Save & Send Invitation'}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditCustomerUserDialog.defaultProps = {
  customerUserId: null,
  editTeams: false,
  phoneNumberRequired: true,
  title: 'Edit User',
}

EditCustomerUserDialog.propTypes = {
  customerUserId: PropTypes.string,
  editTeams: PropTypes.bool,
  phoneNumberRequired: PropTypes.bool,
  title: PropTypes.string
}

export default EditCustomerUserDialog
