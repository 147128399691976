import React from 'react'
import PropTypes from 'prop-types'
import { JsonApiDataGrid } from 'components'
import QuestionsTableActions from './QuestionsTableActions'

const SurveyQuestionsTable = ({ surveyId, typeformFormId, expertiseEnabled }) => {
  const [reload, setReload] = React.useState(0)
  const columns = [
    {
      field: 'id',
      type: 'string',
      headerName: 'ID',
      width: 150,
      hidden: true,
    },
    {
      field: 'typeformFieldId',
      type: 'string',
      headerName: 'Typeform Field ID',
      width: 150,
    },
    {
      field: 'questionText',
      type: 'string',
      headerName: 'Question Text',
      flex: 1,
    },
    {
      field: 'questionType',
      type: 'string',
      headerName: 'Question Type',
      width: 300,
    },
    {
      field: 'topicNames',
      headerName: 'Topics',
      width: 300,
      hidden: expertiseEnabled,
    },
    {
      field: 'expertiseName',
      headerName: 'Area of Expertise',
      width: 300,
      hidden: !expertiseEnabled,
    },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      disableExport: true,
      disableSort: true,
      disableFilter: true,
      valueGetter: ({ row }) => row.id,
      renderCell: ({ row }) => (
        <QuestionsTableActions
          question={row}
          reload={{ value: reload, setReload }}
          expertiseEnabled={expertiseEnabled}
        />
      )
    },
  ]

  const renderedColumns = columns.map((column) => (
    {
      ...column,
      cellClassName: column.actions ? undefined : (params) => (params.row.archivedDate !== null ? 'discarded' : '')
    })).filter((column) => !column.hidden)

  return (
    <JsonApiDataGrid
      disableExport
      endpoint="questions"
      type="questions"
      columns={renderedColumns}
      density="compact"
      jsonApiOptions={{
        include: ['topics', 'expertise'],
        filters: [
          {
            name: 'surveys',
            op: 'any',
            val: {
              name: 'id',
              op: 'eq',
              val: surveyId,
            },
          },
        ],
      }}
      title="Survey Questions"
      mode="server"
      dependencies={[typeformFormId, reload]}
    />
  )
}

SurveyQuestionsTable.defaultProps = {
  typeformFormId: null,
  expertiseEnabled: false,
}

SurveyQuestionsTable.propTypes = {
  surveyId: PropTypes.string.isRequired,
  typeformFormId: PropTypes.string,
  expertiseEnabled: PropTypes.bool,
}

export default SurveyQuestionsTable
