import PropTypes from 'prop-types'
import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material'

import AdvisorAccessControl from '../AdvisorAccessControl'
import AdminAccessControl from '../AdminAccessControl'
import AdvisorProfile from '../AdvisorProfile'
import CustomerAccessControl from '../CustomerAccessControl'

const AdvisorProfileDialog = ({
  advisorId,
  targetOrganizationId,
  handleClose,
  defaultFeatureCase,
  actions,
}) => (
  <Dialog
    open
    maxWidth="lg"
    fullWidth
    onBackdropClick={handleClose}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
  >
    <DialogContent>
      <CustomerAccessControl>
        <AdvisorProfile
          advisorId={advisorId}
          targetOrganizationId={targetOrganizationId}
        />
      </CustomerAccessControl>
      <AdvisorAccessControl>
        <AdvisorProfile
          advisorId={advisorId}
          targetOrganizationId={targetOrganizationId}
        />
      </AdvisorAccessControl>
      <AdminAccessControl>
        <AdvisorProfile
          advisorId={advisorId}
          targetOrganizationId={targetOrganizationId}
        />
      </AdminAccessControl>
    </DialogContent>
    <DialogActions>
      { actions || (
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      )}
    </DialogActions>
  </Dialog>
)

AdvisorProfileDialog.defaultProps = {
  actions: null,
  targetOrganizationId: null,
  defaultFeatureCase: null
}

AdvisorProfileDialog.propTypes = {
  advisorId: PropTypes.number.isRequired,
  targetOrganizationId: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  defaultFeatureCase: PropTypes.node,
  actions: PropTypes.node
}

export default AdvisorProfileDialog
