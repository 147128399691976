import React from 'react'

import { QuestionResponsesTable } from 'components'

const ViewResponsesTab = () => (
  <>
    <QuestionResponsesTable />
  </>
)

export default ViewResponsesTab
