import React from 'react'
import PropTypes from 'prop-types'

import {
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem
} from '@mui/material'

import AccountCircle from '@mui/icons-material/AccountCircle'
import AllInclusive from '@mui/icons-material/AllInclusive'
import Group from '@mui/icons-material/Group'
import Security from '@mui/icons-material/Security'
import SupervisedUserCircle from '@mui/icons-material/SupervisedUserCircle'
import Stars from '@mui/icons-material/Stars'
import Work from '@mui/icons-material/Work'
import { StickyTextField, StickySelectField } from 'components'

import CustomerAutocomplete from '../../../../../../components/CustomerAutocomplete'
import StatusFilter from './StatusFilter'

const CustomerUserFilters = ({ filters, handleSearchFilter, handleStatusFilter }) => (
  <>
    <StickyTextField
      stickyKey="customerUser.divisions"
      fullWidth
      size="small"
      margin="dense"
      label="Team"
      variant="outlined"
      value={filters['divisions__any.name'].value}
      onChange={(event) => handleSearchFilter('divisions__any.name', event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Group />
          </InputAdornment>
        )
      }}
    />
    <StickyTextField
      stickyKey="customerUser.title"
      fullWidth
      size="small"
      margin="dense"
      label="Title"
      variant="outlined"
      value={filters.jobTitle.value}
      onChange={(event) => handleSearchFilter('jobTitle', event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Work />
          </InputAdornment>
        )
      }}
    />
    {'customerUser' && (
      <StatusFilter
        selectedCallback={handleStatusFilter}
        sx={{ mt: 1, mb: 1 }}
      />
    )}
    <FormControl sx={{ pt: 1 }} fullWidth variant="outlined" size="small">
      <InputLabel id="role-select-label">Role</InputLabel>
      <StickySelectField
        stickyKey="customerUser.role"
        labelId="role-select-label"
        id="role-select"
        label="Role"
        variant="outlined"
        startAdornment={(
          <InputAdornment position="start">
            <Security />
          </InputAdornment>
      )}
        value={filters.roleType.value}
        onChange={(event) => handleSearchFilter('roleType', event.target.value)}
      >
        <MenuItem value="">
          <Chip size="small" label="Any" icon={<AllInclusive />} />
        </MenuItem>
        <MenuItem value="rep">
          <Chip size="small" label="Rep" icon={<AccountCircle />} />
        </MenuItem>
        <MenuItem value="manager">
          <Chip size="small" label="Manager" icon={<SupervisedUserCircle />} />
        </MenuItem>
        <MenuItem value="program_owner">
          <Chip size="small" label="Program owner" icon={<Stars />} />
        </MenuItem>
      </StickySelectField>
    </FormControl>
    <CustomerAutocomplete
      filterName="customer.name"
      size="small"
      margin="dense"
      label="Customer"
      variant="outlined"
      multiple={false}
      onChange={(event, customer) => handleSearchFilter('customer.name', customer ? customer.name : null)}
    />
  </>
)

CustomerUserFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  handleSearchFilter: PropTypes.func.isRequired,
  handleStatusFilter: PropTypes.func.isRequired
}

export default CustomerUserFilters
