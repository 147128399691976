import { connect } from 'react-redux'

import { buildJsonApiOrdered } from 'utils-em'
import { JsonAPI } from 'store'
import DivisionsLayout from '../layouts/DivisionsLayout'

const mapStateToProps = ({ data }, ownProps) => ({
  divisions: buildJsonApiOrdered(data, 'divisions'),
  customerId: parseInt(ownProps.match.params.id, 10)
})

const mapDispatchToProps = {
  getAllDivisions: (customerId, size, page) => JsonAPI.getAll({
    type: 'divisions',
    queryStringParams: {
      include: 'customerUsers',
      'filter[customerId]': customerId,
      'page[number]': page,
      'page[size]': size,
      sort: 'name'
    }
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(DivisionsLayout)
