import { connect } from 'react-redux'

import { buildJsonApiOne } from 'utils-em'
import { Alerts, Dialogs, JsonAPI } from 'store'

import EngagementCloseDialog from './EngagementCloseDialog'

const mapStateToProps = ({ data }, ownProps) => ({
  engagement: buildJsonApiOne(data, 'engagements', ownProps.engagementId)
})

const mapDispatchToProps = {
  saveEngagement: (engagementData) => JsonAPI.save({
    ...engagementData,
    type: 'engagements',
    riders: ['cancelEngagement', 'refundEngagement', 'closeEngagementNow', 'sendAdvisorFeedback', 'sendCustomerFeedback']
  }),
  showSuccessMessage: (message) => (dispatch) => dispatch(Alerts.showSuccess(message)),
  showErrorMessage: (message) => (dispatch) => dispatch(Alerts.showError(message)),
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(mapStateToProps, mapDispatchToProps)(EngagementCloseDialog)
