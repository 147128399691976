import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import {
  ChipMultiAutocomplete
} from 'components'
import {
  useJsonAPIGetAll,
  useJsonAPISelectOne
} from 'hooks'
import {
  closeDialog,
} from 'utils-em'

const TopicsDialog = ({ sectionId, onSave }) => {
  const section = useJsonAPISelectOne('knowledgePageSections', sectionId)
  const { objects: topics, loaded } = useJsonAPIGetAll('topics')

  const [selectedTopics, setSelectedTopics] = React.useState(section.topics || [])

  const onSubmit = () => {
    onSave({ ...section, topics: selectedTopics })
    closeDialog()
  }

  return (
    <Dialog
      open
      maxWidth="sm"
      scroll="paper"
      onClose={closeDialog}
    >
      <DialogTitle>{`Add topics to ${section.name}`}</DialogTitle>
      <DialogContent>
        <ChipMultiAutocomplete
          multiple
          options={loaded ? topics : []}
          initialSelectedOptions={selectedTopics}
          selectedOptionsCallback={(val) => setSelectedTopics(val)}
          label="Select topics"
          getOptionLabel={(topic) => topic?.name || ''}
          getChipLabel={(topic) => topic?.name || ''}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          sx={{ ml: 2 }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TopicsDialog.propTypes = {
  sectionId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired
}

export default TopicsDialog
