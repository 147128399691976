import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  TextField,
  Typography,
  Link
} from '@mui/material'

import { LoadingButton } from '@mui/lab'

import {
  customAlert,
  validateEmail
} from 'utils-em'

const EmailStage = ({ defaultEmail, userUuid, onCompleteCallback }) => {
  const captchaWidget = React.createRef()

  const [displayErrors, setDisplayErrors] = React.useState(false)
  const [email, setEmail] = React.useState(defaultEmail)
  const [captchaWidgetId, setCaptchaWidgetId] = React.useState(null)
  const [captchaCompleted, setCaptchaCompleted] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  React.useEffect(() => {
    // The Captcha library can render on a DOM element automatically,
    // but sometimes the Captcha box fails to render when returning
    // to a page without reloading the browser page (React stuff...)
    // so explicitly render the widget manually when ready.
    // Reference: https://github.com/codeep/react-recaptcha-google/blob/master/src/ReCaptcha.js
    if (!window.grecaptcha || !window.grecaptcha.enterprise || !window.grecaptcha.enterprise.render) return
    setCaptchaWidgetId(window.grecaptcha.enterprise.render(
      captchaWidget.current,
      {
        sitekey: __CAPTCHA_SITE_KEY__,
        action: 'login',
        'error-callback': () => setCaptchaCompleted(false),
        'expired-callback': () => setCaptchaCompleted(false),
        callback: () => setCaptchaCompleted(true)
      }
    ))
  }, [window.grecaptcha])

  const handleSubmit = async () => {
    setDisplayErrors(false)
    setIsSubmitting(true)

    const isValidEmail = validateEmail(email).valid
    const isFormValid = isValidEmail && captchaCompleted
    if (!isFormValid) {
      setDisplayErrors(true)
      setIsSubmitting(false)
      return
    }

    const result = await fetch(
      `${__API_HOST__}/onboard/advisor`,
      {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify({
          userUuid,
          email,
          captcha: window.grecaptcha.enterprise.getResponse(captchaWidgetId)
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const responseJson = await result.json()
    if (result.ok) {
      onCompleteCallback(responseJson)
    } else {
      customAlert('An error occurred. Please contact support for assistance.', true)
    }
    setIsSubmitting(false)
  }

  const isValidEmail = validateEmail(email).valid

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        Welcome to the survey!
      </Typography>
      <Typography variant="body1" color="neutral.black">
        Enter your email to get started.
      </Typography>
      <Box sx={{ mt: 3, mb: 4 }}>
        <TextField
          placeholder="Email"
          variant="outlined"
          required
          fullWidth
          value={email}
          disabled={isSubmitting}
          error={displayErrors && !isValidEmail}
          onChange={(event) => setEmail(event.target.value)}
          sx={{ backgroundColor: 'neutral.white' }}
        />
        {displayErrors && !isValidEmail && (<Typography variant="caption" color="error.main">Please enter a valid email</Typography>)}
      </Box>
      <Box sx={{ mb: 3 }}>
        <div ref={captchaWidget} />
        {displayErrors && !captchaCompleted && (<Typography variant="caption" color="error.main">This field is required</Typography>)}
      </Box>
      <Box sx={{ mb: 3 }}>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit}
          id="continue-email"
        >
          Continue
        </LoadingButton>
      </Box>
      <Typography variant="caption" color="neutral.black">
        {'If you have any questions or need help, email '}
        <Link href="mailto:advisor-support@emissary.io" rel="noreferrer" target="_blank">
          advisor-support@emissary.io
        </Link>
      </Typography>
    </Box>
  )
}

EmailStage.defaultProps = {
  defaultEmail: ''
}

EmailStage.propTypes = {
  defaultEmail: PropTypes.string,
  userUuid: PropTypes.string.isRequired,
  onCompleteCallback: PropTypes.func.isRequired
}

export default EmailStage
