import {
  Typography
} from '@mui/material'

import {
  useEngagement
} from 'hooks'

import PropTypes from 'prop-types'
import React from 'react'

const ProposedAgenda = ({ engagementId }) => {
  const { agenda } = useEngagement(engagementId)
  return (
    <>
      <Typography variant="h3">
        Proposed agenda:
      </Typography>
      <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
        {agenda}
      </Typography>
    </>
  )
}

ProposedAgenda.propTypes = {
  engagementId: PropTypes.number.isRequired
}

export default ProposedAgenda
