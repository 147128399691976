import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { SectionHeaderWithDivider } from 'components'

const Tabs = ({ tabs, activeTab, onChange, panelProps, ...rest }) => {
  // tab: { name, component, hidden (optional), hash (optional), title (optional) }
  const urlHash = useSelector(({ router }) => router.location.hash)
  const hasHash = tabs.every((t) => t.hash !== undefined)
  const hasATitle = tabs.some((t) => t.title !== undefined)

  const [activeTabName, setActiveTabName] = React.useState((
    activeTab ||
    tabs.find((t) => !t.hidden) ||
    tabs[0]
  ).name)

  React.useEffect(() => {
    if (hasHash && urlHash) {
      const newTab = tabs.find((tab) => tab.hash === urlHash)
      if (newTab) setActiveTabName(newTab.name)
    }
  }, [urlHash])

  React.useEffect(() => { activeTab && setActiveTabName(activeTab.name) }, [activeTab])

  const setTab = (newValue) => {
    setActiveTabName(newValue)
    onChange(tabs.find((tab) => tab.name === newValue))
  }

  const visibleTabs = tabs.filter((t) => !t.hidden)

  return (
    <Box {...rest}>
      <TabContext value={activeTabName}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(_e, newValue) => setTab(newValue)}
            sx={{ pl: 6 }}
          >
            {visibleTabs.map((t) => (
              <Tab
                key={t.name}
                label={t.name}
                value={t.name}
                to={hasHash ? t.hash : undefined}
                component={hasHash ? Link : undefined}
                disableRipple
                sx={{
                  '&:hover': { color: 'primary.main', bgcolor: 'transparent' },
                }}
              />
            ))}
          </TabList>
        </Box>
        {visibleTabs.map((t) => (
          <TabPanel key={t.name} value={t.name} {...panelProps}>
            {hasATitle && (
              <SectionHeaderWithDivider
                title={t.title || t.name}
                variant="h2"
                sx={{ mb: 5 }}
              />
            )}
            {t.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  )
}

Tabs.defaultProps = {
  activeTab: null,
  panelProps: { sx: { pl: 6, pr: 6 } },
  onChange: (tab) => {}
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.object,
  panelProps: PropTypes.object,
  onChange: PropTypes.func
}

export default Tabs
