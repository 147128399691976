import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  Typography
} from '@mui/material'
import {
  JsonAPI
} from 'store'
import {
  buildJsonApiOne,
  closeDialog,
  customAlert
} from 'utils-em'

const TermsChangedDialog = () => {
  const dispatch = useDispatch()
  const { id } = useSelector(({ session }) => session)
  const advisor = useSelector(({ data }) => buildJsonApiOne(data, 'advisors', id))

  if (!advisor || advisor.termsDate) return null

  const closeTerms = async () => {
    const resp = await dispatch(JsonAPI.save({ ...advisor, termsDate: moment().toISOString() }, 'advisors'))
    if (resp.error) {
      customAlert('Something went wrong. Please contact support.', true)
    } else {
      closeDialog('terms')
    }
  }
  return (
    <Dialog
      open
      maxWidth="lg"
      sx={{ zIndex: 1305 }} // 1300 is base for dialogs, bump a little higher
    >
      <DialogTitle>We've updated our Terms and Conditions.</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Thanks for being an Emissary. Because we value transparency, we want you to know we made changes to our&nbsp;
          <Link href="https://emissary.io/advisor-terms-and-conditions/" target="_blank" underline="hover">Emissary Advisory Services Agreement</Link>
          &nbsp;and&nbsp;
          <Link href="https://emissary.io/confidentiality-policy/" target="_blank">Privacy Policy</Link>
          . We are providing an overview of certain of the changes below, but encourage you to review the documents in full. These changes will take effect immediately.
        </Typography>

        <Typography variant="h4" sx={{ mb: 2 }}>
          What’s new:
        </Typography>
        <Typography variant="bodyBold">
          Advisory Services Agreement
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <List dense>
            <ListItem>
              • We added information about “Knowledge,” an engagement opportunity that we offer in addition to live calls with clients. We also added information clarifying who owns the work product from our different engagement types.
            </ListItem>
            <ListItem>
              <Box sx={{ display: 'inline' }}>
                • We updated our arbitration agreement, which governs how we resolve any disputes with you. As before, we will resolve most disputes in private, individual arbitration, and not in court, and you waive any class action litigation.&nbsp;
                <Box sx={{ display: 'inline', fontWeight: 600 }}>Please read this section carefully.</Box>
              </Box>
            </ListItem>
            <ListItem>
              • We added language to clarify our non-solicitation policy, which provides that you will not solicit projects from or enter into consulting arrangements with clients introduced to you by us for one year without our written approval.
            </ListItem>
            <ListItem>
              • We added information about automated text messaging services that we may use.
            </ListItem>
            <ListItem>
              • We added information to clarify our rights with respect to suggestions, feedback, or ideas you share with us.
            </ListItem>
            <ListItem>
              • We added language to further clarify restrictions on your use of our platform and services.
            </ListItem>
            <ListItem>
              • We added information to clarify that we are not responsible for certain types of damages you may incur while using our platform and services.
            </ListItem>
          </List>
        </Typography>

        <Typography variant="bodyBold">
          Privacy Policy
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          <List dense>
            <ListItem>
              • We made updates regarding how we collect and process your personal information. These updates are designed to align with changes in applicable law and increase readability and transparency for our users.
            </ListItem>
          </List>
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          To see all changes and the full details, we encourage you to read our updated&nbsp;
          <Link href="https://emissary.io/advisor-terms-and-conditions/" target="_blank" underline="hover">Emissary Advisory Services Agreement</Link>
          &nbsp;and&nbsp;
          <Link href="https://emissary.io/confidentiality-policy/" target="_blank">Privacy Policy</Link>
          . No action is required from you.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          If you have any questions, please contact us at&nbsp;
          <Link href="mailto:advisor-support@emissary.io" rel="noreferrer" target="_blank">
            advisor-support@emissary.io
          </Link>
          .
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={closeTerms}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TermsChangedDialog
