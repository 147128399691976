import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Collapse, Alert, Box, IconButton, Typography } from '@mui/material'
import { SanitizedHTML } from 'components'
import { JsonAPI, AppState } from 'store'
import { navigateTo } from 'utils-em'
import {
  Alert as AlertIcon,
  AnnotationDots,
  Bell,
  Calendar,
  CheckCircle,
  Close,
  CloseCircle,
  Edit,
  File,
  Mail,
  Microphone,
  User
} from 'icons'

const NotificationIconMap = {
  'new-engagement': <Bell />,
  'new-transcript': <File />,
  'new-notes': <File />,
  'new-recording': <Microphone />,
  'new-call-times': <Calendar />,
  'new-message-in-relay': <Mail />,
  'feedback-required': <AnnotationDots />,
  'added-as-participant': <Calendar />,
  'proposed-engagement': <Bell />,
  'proposal-accepted': <Bell />,
  'proposal-declined': <Bell />,
  'personal-information-missing': <Edit />,
  'employment-history-missing': <Edit />,
  'payment-information-missing': <Edit />,
  'new-engagement-approval-request': <Bell />,
  'engagement-approval-request-denied': <CloseCircle />,
  'new-insight': <File />,
  'new-advisor': <User />,
  'feedback-required-count': <AlertIcon />,
  'new-survey': <Bell />,
}

const Notification = ({ notification }) => {
  const { text, targetObjectUrl, notificationType } = notification
  const [isDismissed, setIsDismissed] = React.useState(false)
  const user = useSelector(({ session }) => session)
  const isAdvisor = user.userType === 'advisor'
  const route = targetObjectUrl && `/${isAdvisor ? 'a' : 'c'}/${targetObjectUrl}`
  const dispatch = useDispatch()

  const dismiss = () => {
    dispatch(JsonAPI.save({
      ...notification,
      isDismissed: true,
      type: 'notifications'
    }))
    setIsDismissed(true)
  }

  const view = () => {
    dispatch(AppState.toggleNotificationsDrawer(false))
    dismiss()
    navigateTo(route)
  }

  return (
    <Collapse in={!isDismissed}>
      <Alert
        onClick={(e) => {
          if (!route) return
          // className for IconButton animation and close button
          if (String(e.target.className).includes('SVGAnimatedString')) return
          if (String(e.target.className).includes('MuiButtonBase-root')) return
          view()
        }}
        sx={{
          cursor: route ? 'pointer' : 'default',
          mb: 1,
          pt: 0,
          pb: 0,
          borderRadius: '8px',
          bgcolor: 'primary.lightest',
          // theme arg needed for sx children (icon color)
          path: { stroke: ({ palette }) => palette.primary.dark },
          circle: { stroke: ({ palette }) => palette.primary.dark },
        }}
        icon={NotificationIconMap[notificationType] || (<CheckCircle />)}
        action={(
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={dismiss}
            >
              <Close />
            </IconButton>
          </Box>
        )}
      >
        <Typography sx={{ color: 'primary.dark' }}>
          <SanitizedHTML html={text} />
        </Typography>
      </Alert>
    </Collapse>
  )
}

Notification.propTypes = {
  notification: PropTypes.object.isRequired
}

export default Notification
