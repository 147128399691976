import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Box,
  Link,
  Typography
} from '@mui/material'
import {
  Spinner
} from 'components'
import {
  useEngagement
} from 'hooks'

import AcceptSchedulingProposal from './components/ActivityItems/AcceptSchedulingProposal'
import CallCompleted from './components/ActivityItems/CallCompleted'
import EngagementCanceled from './components/ActivityItems/EngagementCanceled'
import EngagementEnded from './components/ActivityItems/EngagementEnded'
import EngagementStarted from './components/ActivityItems/EngagementStarted'
import FeedbackSubmitted from './components/ActivityItems/FeedbackSubmitted'
import Message from './components/ActivityItems/Message'

const ActivityItemComponents = {
  MESSAGE: Message,
  ADVISOR_FEEDBACK_SUBMITTED: FeedbackSubmitted,
  CUSTOMER_FEEDBACK_SUBMITTED: FeedbackSubmitted,
  CALL_COMPLETED: CallCompleted,
  ENGAGEMENT_STARTED: EngagementStarted,
  ENGAGEMENT_ENDED: EngagementEnded,
  ENGAGEMENT_CANCELED: EngagementCanceled,
  ACCEPT_SCHEDULING_PROPOSAL: AcceptSchedulingProposal,
}

const ActivityLog = ({ engagementId, expandedByDefault }) => {
  const {
    loaded,
    customerFeedback,
    advisorFeedback,
    assets,
    timeslots,
    events,
    messages
  } = useEngagement(engagementId)
  const user = useSelector(({ session }) => session)
  const isAdvisorUser = user.userType === 'advisor'

  const [expanded, setExpanded] = React.useState(expandedByDefault)

  const getActivities = () => {
    const assetEvents = getAssetEvents()
    const timeslotEvents = getTimeslotEvents()
    const feedbackEvents = getFeedbackEvents()
    const messageEvents = messages.map((message) => ({
      ...message,
      eventName: 'MESSAGE'
    }))
    const activities = events.concat(messageEvents, assetEvents, timeslotEvents, feedbackEvents)
    activities.sort((a, b) => ((a.dateCreated > b.dateCreated) ? -1 : 1))
    return activities
  }

  const getTimeslotEvents = () => {
    const timeslotEvents = []

    if (!timeslots) return []
    timeslots.forEach((timeslot) => {
      if (timeslot && timeslot.endTime && Date.parse(timeslot.endTime) < Date.parse(new Date())) {
        timeslotEvents.push({
          id: timeslot.id,
          type: 'events',
          eventName: 'CALL_COMPLETED',
          actionRequired: false,
          dateCreated: timeslot.endTime
        })
      }
    })

    return timeslotEvents
  }

  const getFeedbackEvents = () => {
    let feedbackForms = []
    if (isAdvisorUser) {
      feedbackForms = advisorFeedback ? [advisorFeedback] : []
    } else {
      const feedback = customerFeedback
      if (feedback) {
        feedbackForms.push(feedback)
      }
    }
    const feedbackEvents = []
    feedbackForms.forEach((feedbackForm, i) => {
      feedbackEvents.push({
        id: `feedback-${i}`,
        type: isAdvisorUser ? 'advisorSingleCallFeedback' : 'customerPostCallFeedbackForm',
        eventName: isAdvisorUser ? 'ADVISOR_FEEDBACK_SUBMITTED' : 'CUSTOMER_FEEDBACK_SUBMITTED',
        dateCreated: feedbackForm.dateCreated
      })
    })
    return feedbackEvents
  }

  const getAssetEvents = () => {
    const assetEvents = []
    assets.forEach(({ assetType, dateUpdated }) => {
      const assetObj = { id: assetType, dateCreated: dateUpdated }
      assetType === 'engagement-notes' && assetEvents.push({
        ...assetObj,
        eventName: 'ENGAGEMENT_NOTES_PUBLISHED'
      })
      assetType === 'engagement-transcript' && assetEvents.push({
        ...assetObj,
        eventName: 'ENGAGEMENT_TRANSCRIPT_PUBLISHED',
      })
      assetType === 'engagement-recording' && assetEvents.push({
        ...assetObj,
        eventName: 'ENGAGEMENT_RECORDING_PUBLISHED',
      })
    })
    return assetEvents
  }

  const activities = getActivities()

  const renderActivity = (activity) => {
    if (ActivityItemComponents[activity.eventName]) {
      const Component = ActivityItemComponents[activity.eventName]
      return (
        <Component
          key={`${activity.id}-${activity.eventName}`}
          activity={activity}
          engagementId={engagementId}
          user={user}
        />
      )
    }
    return null
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h3">Activity Log</Typography>
        <Link
          variant="buttonL"
          onClick={() => setExpanded(!expanded)}
        >
          {`${expanded ? 'Hide' : 'Show'} details`}
        </Link>
      </Box>
      {expanded ? (
        <Box>
          {loaded ? activities.map(renderActivity) : <Spinner />}
        </Box>
      ) : null}
    </>
  )
}

ActivityLog.defaultProps = {
  expandedByDefault: false
}

ActivityLog.propTypes = {
  engagementId: PropTypes.number.isRequired,
  expandedByDefault: PropTypes.bool
}

export default ActivityLog
