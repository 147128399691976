import { connect } from 'react-redux'
import { JsonAPI } from 'store'

import OrganizationEditorLayout from '../layouts/OrganizationEditorLayout'

const mapDispatchToProps = {
  getAllOrgs: (size, page, sort) => JsonAPI.getAll({
    type: 'organizations',
    sort,
    queryStringParams: {
      'page[size]': size,
      'page[number]': page
    }
  })
}

export default connect(null, mapDispatchToProps)(OrganizationEditorLayout)
