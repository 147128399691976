import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Chip,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import {
  Badge,
  Bookmark,
  Briefcase,
  Calendar,
  Clock,
  Flag,
  Grid,
  Pin
} from 'icons'
import {
  buildJsonApiAll,
  buildJsonApiOne,
  calculateCompanyTenure,
  getAdvisorCompanyByOrg,
  getAdvisorMostRecentRelevantCompany,
  getAdvisorMostRecentRelevantRole,
  getAdvisorSeniorityShorthand,
  formatMonthYearForHumans,
  getTagsByCategory,
  formatDate,
  navigateByEvent,
  getRoleFunction
} from 'utils-em'
import { SaveAdvisorButton } from 'components'
import IconTextCombo from '../IconTextCombo/IconTextCombo'

const AdvisorCard = ({ advisorId, approvedRequestId, orgId, jsonApiType }) => {
  const theme = useTheme()
  const { pathname, search } = useLocation()
  const advisor = useSelector(({ data }) => buildJsonApiOne(data, jsonApiType, advisorId))

  const approvedRequest = useSelector(({ data }) => buildJsonApiOne(data, 'engagementApprovalRequests', approvedRequestId))
  const advisorBookmarks = useSelector(({ data }) => buildJsonApiAll(data, 'bookmarks'))

  const getRoleRegion = () => {
    if (!role.tags) return null
    const allRegions = getTagsByCategory(role.tags, 'region')
    return allRegions.length ? allRegions[0].name : null
  }

  const getAdvisorCompany = () => {
    let company = null
    const organizationId = orgId
    if (organizationId) company = getAdvisorCompanyByOrg(advisor, parseInt(orgId, 10))
    if (!company) company = getAdvisorMostRecentRelevantCompany(advisor)
    return company
  }

  const company = getAdvisorCompany()
  const role = getAdvisorMostRecentRelevantRole(advisor, company)

  const isBookmarked = !!advisorBookmarks.find((bookmark) => bookmark.bookmarkType === 'SAVED_ADVISOR' &&
    parseInt(bookmark.bookmarkTarget, 10) === advisor.id)
  if (!role) { return null }
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', mb: 3, borderColor: 'neutral.lightGrey', ':hover': { bgcolor: 'primary.lightest', boxShadow: 0 } }}>
      <ButtonBase
        onClick={(e) => {
          // cancel if link is clicked or click outside of link popover menu
          if (e.target.nodeName === 'A') return
          if (String(e.target.className).includes('MuiBackdrop-root')) return
          navigateByEvent(`/c/advisor/${advisor.id}`, e, { from: pathname + search })
        }}
      >
        <CardContent sx={{ p: 0, width: '100%', textAlign: 'left' }}>
          <Box sx={{ m: 3 }}>
            <Box sx={{ display: 'flex', flexBasis: 'column' }}>
              <Typography variant="body1">
                {advisor.firstName}
              </Typography>
              <Box sx={{ ml: 'auto', 'MuiBackdrop-root': true }}>
                <SaveAdvisorButton
                  advisorId={advisor.id}
                  iconOnly
                />
              </Box>
            </Box>
            <Typography variant="h4" display="block">
              {`${role.title} at ${company.displayOrgName}`}
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
              {isBookmarked && (
                <Chip
                  icon={<Bookmark sx={{ width: 16, height: 16, '&.MuiChip-icon': { color: 'brand.purple' } }} />}
                  label="Saved"
                  size="large"
                  sx={{ backgroundColor: 'brand.lightestPurple', mr: 1.5, mt: '2px' }}
                />
              )}
              {approvedRequest && (
                <Chip
                  icon={<Flag sx={{ width: 16, height: 16, '&.MuiChip-icon': { color: 'brand.purple' } }} />}
                  label={`Approved by ${approvedRequest.approverUser.fullName} on ${formatDate(approvedRequest.dateUpdated)}`}
                  size="large"
                  sx={{ backgroundColor: 'brand.lightestPurple', mr: 1.5, mt: '2px' }}
                />
              )}
              {advisor.status === 'Idle' ? (
                <Tooltip
                  title={(
                    <div>
                      This advisor has not
                      <br />
                      logged in recently
                    </div>
                  )}
                  placement="right"
                  arrow
                >
                  <Chip
                    icon={<Badge fill={theme.palette.neutral.darkGrey} />}
                    label="Idle"
                    size="large"
                    sx={{
                      mr: 2,
                      pl: 1,
                      mt: '2px',
                      bgcolor: 'neutral.offWhite',
                      color: 'neutral.darkGrey',
                      ...theme.typography.body1
                    }}
                  />
                </Tooltip>
              ) : null}
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                <IconTextCombo
                  icon={<Clock />}
                  text={`${calculateCompanyTenure(company, true)} years`}
                  typography="body1"
                  typographyColor="neutral.black"
                  iconSize="medium"
                  iconColor="neutral.darkGrey"
                />
                <IconTextCombo
                  icon={<Calendar />}
                  text={`Departed ${formatMonthYearForHumans(company.dateEnded, true)}`}
                  typography="body1"
                  typographyColor="neutral.black"
                  iconSize="medium"
                  iconColor="neutral.darkGrey"
                />
                {role.seniority && (
                  <IconTextCombo
                    icon={<Briefcase />}
                    text={getAdvisorSeniorityShorthand(role.seniority)}
                    typography="body1"
                    typographyColor="neutral.black"
                    iconSize="medium"
                    iconColor="neutral.darkGrey"
                  />
                )}
                {getRoleRegion(role) && (
                  <IconTextCombo
                    icon={<Pin />}
                    text={`Scope: ${getRoleRegion(role)}`}
                    typography="body1"
                    typographyColor="neutral.black"
                    iconSize="medium"
                    iconColor="neutral.darkGrey"
                  />
                )}
                {getRoleFunction(role).map((f) => (
                  <IconTextCombo
                    icon={<Grid />}
                    text={f}
                    key={f}
                    typography="body1"
                    typographyColor="neutral.black"
                    iconSize="medium"
                    iconColor="neutral.darkGrey"
                  />
                ))}
              </Stack>
            </Box>
          </Box>
        </CardContent>
      </ButtonBase>
    </Card>
  )
}
AdvisorCard.defaultProps = {
  approvedRequestId: null,
  orgId: null,
  advisorId: null,
  jsonApiType: 'advisors'
}
AdvisorCard.propTypes = {
  advisorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  approvedRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  jsonApiType: PropTypes.string
}

export default AdvisorCard
