import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useJsonAPIGetOne } from 'hooks'
import { merge } from 'lodash'

import { ACCOUNT_PAGE_DATA_TEMPLATE, getOrgPublishedAccountPage, customAlert } from 'utils-em'

export const EditAccountPageContext = React.createContext({})

export const EditAccountPageContextProvider = ({ children }) => {
  const params = useParams()
  const id = parseInt(params.id, 10) || null
  const { object: accountPage, loaded: accountPageLoaded } = useJsonAPIGetOne(id, 'accountPages', ['organization', 'organization.tags'], { 'lazy[accountPages]': 'logo,surveyResults' })
  const [loaded, setLoaded] = React.useState(!id) // set to true if creating
  const [organization, setOrganization] = React.useState(null)
  const [published, setPublished] = React.useState(null)
  const [data, setData] = React.useState(null)
  const [logoFile, setLogoFile] = React.useState(null)
  const [logoGcsObjectName, setLogoGcsObjectName] = React.useState(null)
  const [surveyResults, setSurveyResults] = React.useState(null)
  const [hasResultsForEverySurveyCategory, setHasResultsForEverySurveyCategory] = React.useState(false)
  const [surveysLoaded, setSurveysLoaded] = React.useState(false)
  const [publishedAccountPageForOrg, setPublishedAccountPageForOrg] = React.useState(null)
  const [deleteLogo, setDeleteLogo] = React.useState(false)

  // Hotfix for data persistence after saving a
  // page and then trying to create a new one.
  React.useEffect(() => {
    if (!id && accountPageLoaded) {
      setData({})
    }
  }, [id])

  React.useEffect(() => {
    if (!accountPageLoaded) return
    setOrganization(accountPage?.organization)
    setPublished(accountPage?.isPublished)
    setLogoGcsObjectName(accountPage?.logoGcsObjectName)

    // recursive merge existing data with template in case template changes
    setData(merge({}, ACCOUNT_PAGE_DATA_TEMPLATE, accountPage?.data))
    setLoaded(true)
  }, [accountPageLoaded])

  React.useEffect(() => { organization && loadSurveyResults() }, [organization?.id])
  React.useEffect(() => { organization && organization.id && checkForPublishedAccountPageForOrg() }, [organization?.id, published])

  const checkForPublishedAccountPageForOrg = async () => {
    try {
      const publishedAccountPage = await getOrgPublishedAccountPage(organization.id)
      setPublishedAccountPageForOrg(publishedAccountPage)
    } catch (error) {
      customAlert('Something went wrong checking for existing published account page, please contact support', true)
    }
  }

  const loadSurveyResults = async () => {
    const url = new URL(`${__API_HOST__}/v1/accountPages/surveyAssignmentResults`)
    url.searchParams.append('surveyTypes', '["account-profile", "in-seat-survey"]')
    url.searchParams.append('orgId', organization.id)
    setSurveysLoaded(false)
    try {
      const res = await fetch(url, { credentials: 'include', method: 'GET' })
      if (res.error) throw new Error(res.error.message)
      const response = await res.json()
      setSurveyResults(response.results)
      setHasResultsForEverySurveyCategory(response.isComplete)
    } catch (error) {
      customAlert('Something went wrong loading surveys, please contact support', true)
    }
    setSurveysLoaded(true)
  }

  return (
    <EditAccountPageContext.Provider value={{
      id,
      loaded,
      MAX_LOGO_SIZE: accountPage?.maxLogoSize,
      accountPage,
      organization,
      setOrganization,
      published,
      setPublished,
      data,
      setData,
      logoFile,
      setLogoFile,
      logoGcsObjectName,
      setLogoGcsObjectName,
      surveyResults,
      surveysLoaded,
      hasNoSurveyResults: surveysLoaded && !surveyResults,
      publishedAccountPageForOrg,
      setLoaded,
      hasResultsForEverySurveyCategory,
      initialData: accountPage?.data,
      logo: accountPage?.logo,
      deleteLogo,
      setDeleteLogo,
    }}
    >
      {children}
    </EditAccountPageContext.Provider>
  )
}

EditAccountPageContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
