import { connect } from 'react-redux'

import EngagementContractUsageDialog from './EngagementContractUsageDialog'
import Dialogs from '../../store/Dialogs'

const mapDispatchToProps = {
  handleClose: () => (dispatch) => dispatch(Dialogs.closeDialog())
}

export default connect(null, mapDispatchToProps)(EngagementContractUsageDialog)
