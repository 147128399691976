import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material'
import {
  ArrowLeft
} from 'icons'
import {
  customAlert,
  simpleHttpFetch
} from 'utils-em'
import {
  useJsonAPIGetOne
} from 'hooks'

import Message from './components/Message'

const cardPrompts = [
  {
    jsx: (
      <>
        Ask about what
        {' '}
        <Typography variant="bodyBold" sx={{ display: 'inline' }}>drives technology purchases</Typography>
        {' '}
        within this company.
      </>
    ),
    text: 'What drives technology purchases within this company?'
  },
  {
    jsx: (
      <>
        <Typography variant="bodyBold" sx={{ display: 'inline' }}>Get expert tips</Typography>
        {' '}
        about selling tech to this company.
      </>
    ),
    text: 'What are expert tips about selling tech to this company?'
  },
  {
    jsx: (
      <>
        Map out the
        {' '}
        <Typography variant="bodyBold" sx={{ display: 'inline' }}>procurement process</Typography>
        {' '}
        for technology purchases.
      </>
    ),
    text: 'Map out the procurement process for technology purchases.'
  },
  {
    jsx: (
      <>
        Talk to me about what
        {' '}
        <Typography variant="bodyBold" sx={{ display: 'inline' }}>kind of technology</Typography>
        {' '}
        this company uses.
      </>
    ),
    text: 'Talk to me about what kind of technology this company uses.'
  }
]

const Chatbot = ({ filters, promptId }) => {
  const sessionId = React.useRef(null)
  const [text, setText] = React.useState('')
  const [messages, setMessages] = React.useState([])
  const [allowScrollToBottom, setAllowScrollToBottom] = React.useState(false)

  const scrollableMessageBoxRef = React.useRef(null)

  const { organizationId } = filters
  const { object: organization, loaded } = useJsonAPIGetOne(organizationId, 'organizations')

  const beginSession = async () => {
    if (sessionId.current) return

    const json = await simpleHttpFetch(
      `${__API_HOST__}/v1/chatbot-session`,
      {
        json: true,
        method: 'POST',
        body: JSON.stringify({
          promptId,
          sessionData: {
            systemPrompt: {},
            filters: { ...filters }
          }
        }),
        errorMessage: 'Error starting chatbot session'
      }
    )
    sessionId.current = json.id
  }

  const submitQuestion = async (question) => {
    if (!question) return

    if (!sessionId.current) await beginSession()

    setText('')
    setAllowScrollToBottom(true)

    // Immediately display the user's message
    setMessages([
      ...messages,
      {
        id: 0,
        role: 'user',
        content: question
      },
      {
        id: 1,
        role: 'assistant',
        content: null,
        isLoading: true
      }
    ])
    try {
      const res = await simpleHttpFetch(
        `${__API_HOST__}/v1/chatbot-session/${sessionId.current}/message`,
        {
          json: true,
          method: 'POST',
          body: JSON.stringify({ question })
        }
      )
      // Display the response message
      // Note: the prior call to `setMessages` with the user message gets
      // overridden below, since the `messages` state doesn't include the
      // prior update
      if (res.user_message && res.system_message) {
        setMessages([...messages, res.user_message, res.system_message])
      } else {
        return customAlert('Something went wrong, please contact support', true)
      }
    } catch (err) {
      return customAlert('Something went wrong, please contact support', true)
    }
  }

  const scrollToBottom = () => {
    scrollableMessageBoxRef?.current?.scrollTo(0, scrollableMessageBoxRef?.current.getBoundingClientRect().bottom)
  }

  const regenerateMessage = async (message) => {
    // Display message as loading
    setMessages([...messages.map((m) => ({
      ...m,
      isLoading: m.id === message.id
    }))])

    try {
      const json = await simpleHttpFetch(
        `${__API_HOST__}/v1/chatbot-session/${sessionId.current}/message/${message.id}/regenerate`,
        {
          json: true,
          method: 'POST',
        }
      )

      // Inline replace the old message with the regenerated one
      setMessages([
        ...messages.map((m) => (m.id === message.id ? (json.regenerated_message) : m))
      ])
    } catch (error) {
      customAlert(`Error regenerating message: ${error}`, true)
    }
  }

  React.useEffect(() => {
    // Messages can change because:
    //   1. a new user message is sent (scroll to bottom)
    //   2. an existing response is regenerated (do not scroll to bottom, so user doesn't lose their place)
    if (allowScrollToBottom) {
      scrollToBottom()
      setAllowScrollToBottom(false)
    }
  }, [messages])

  if (!loaded) {
    return (<div style={{ top: '50%', left: '50%', position: 'absolute' }}><CircularProgress /></div>)
  }

  return (
    <Box id="chatbot" sx={{ display: 'flex', flexDirection: 'column', flex: 1, position: 'relative', height: '100%' }}>
      {/* Content */}
      <Box sx={{ flexGrow: 1, minHeight: '500px' }}>
        {messages.length === 0 ? (
          <Box sx={{ position: 'relative', minHeight: '40vh' }}>
            <Box sx={{
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%'
            }}
            >
              <Typography variant="h2" color="neutral.black">{`Ask Emmy a question ${loaded && `about ${organization.name}`}`}</Typography>

            </Box>
          </Box>
        ) : (
          <Box
            id="message-container"
            ref={scrollableMessageBoxRef}
            sx={{
              display: 'flex',
              flexDirection: 'column-reverse',
              overflowY: 'auto',
              position: 'absolute',
              bottom: '60px',
              height: 'calc(100% - 60px)'
            }}
          >
            {/* Easiest way to display bottom-to-top is to use column-reverse, but that means we need
            to also display the messages in reverse order. Note .reverse() is in-place, so we *must*
            make a copy, otherwise it keeps flipping each render. */}
            {[...messages].reverse().map((message) => (
              <Box>
                {/* Box wrapper necessary for scroll-to-bottom behavior */}
                <Message key={message.id} message={message} onRequestRegenerate={regenerateMessage} />
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Customizable element bar */}
      {messages.length === 0 && organizationId ? (
        <Grid id="prompt-element-tray" container rowSpacing={2} columnSpacing={3} sx={{ mb: 3 }}>
          {cardPrompts.map((cardPrompt) => (
            <Grid item xs={6} key={cardPrompt.text}>
              <Card
                variant="outlined"
                onClick={() => submitQuestion(cardPrompt.text)}
                sx={{ p: 3, height: '100%', cursor: 'pointer', ':hover': { bgcolor: 'primary.lightest' } }}
              >
                <Typography>
                  {cardPrompt.jsx}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : null}

      {/* Input box */}
      <Box id="input-container" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          fullWidth
          placeholder="Ask a question about this account..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && submitQuestion(text)}
          disabled={!organizationId}
        />
        <IconButton disabled={!organizationId} onClick={() => submitQuestion(text)}>
          <ArrowLeft sx={{ transform: 'rotate(90deg)', color: organizationId ? 'primary.main' : 'neutral.disabled' }} />
        </IconButton>
      </Box>
    </Box>
  )
}

Chatbot.propTypes = {
  filters: PropTypes.shape({
    organizationId: PropTypes.number
  }),
  promptId: PropTypes.number
}

Chatbot.defaultProps = {
  filters: {},
  promptId: null
}

export default Chatbot
