import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography
} from '@mui/material'
import {
  useEngagement
} from 'hooks'
import {
  formatDate
} from 'utils-em'

const ProposalCanceled = ({ engagementId }) => {
  const { isAdvisor, dateCanceled } = useEngagement(engagementId)

  if (isAdvisor) {
    return (
      <>
        <Typography variant="h3">
          {'We\'re sorry!'}
        </Typography>
        <Typography variant="body1">
          {`The client canceled their call proposal on ${formatDate(dateCanceled)}.`}
        </Typography>
      </>
    )
  }

  return (
    <Typography variant="body1">
      {`This call proposal was canceled on ${formatDate(dateCanceled)}.`}
    </Typography>
  )
}

ProposalCanceled.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default ProposalCanceled
